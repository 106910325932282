import templateStr from 'text!./pm-vehicle-mta.html';
import moment from 'moment';

export default [() => {
    return {
        restrict: 'A',
        scope: {
            page: '='
        },
        template: templateStr,
        controller: ['$rootScope', '$scope', ($rootScope, $scope) => {
            /**
             * **************** MTA specific *************
             */

            const originalVehicleVM = $scope.page.policyDetailsVM.lobs.personalMotor.policyVehicle;
            const coverablesVM = $scope.page.policyChangeVM.lobData.personalMotor.coverables;
            $scope.vehicle = coverablesVM.pmVehicles.children[0];
            $scope.inReadonlyMode = !$scope.page.hasVehicleChanged();

            $scope.startEditMode = () => {
                if ($scope.inReadonlyMode === false) { // Ignore if already in Edit Mode
                    return;
                }
                // Replace current vehicle with a new one
                const coverables = coverablesVM.value;
                const preFill = {
                    dayTimeParking: originalVehicleVM.dayTimeParking.value.code,
                    nightTimeParking: originalVehicleVM.nightTimeParking.value.code,
                    classOfUse: originalVehicleVM.classOfUse.value.code
                };
                coverables.removeVehicle($scope.vehicle.value);
                coverables.createVehicle(preFill);
                $scope.vehicle = coverablesVM.pmVehicles.children[0];
                // Reset local modifications VM
                $scope.modificationsVM.value = undefined;
                $scope.modificationsVM.aspects = $scope.vehicle.hasBeenModified.aspects;
                // Turn on Edit Mode
                $scope.inReadonlyMode = false;
                // Reset form
                $scope.form.setPristine();
            };

            $scope.goToNext = () => {
                if ($scope.inReadonlyMode === true) { // If no changes were made, there is no need to save anything
                    return $scope.page.goToChangeSelection();
                }
                return $scope.page.saveAndGoToChangeSelection();
            };

            /**
             * **************** From Q&B *****************
             */
            $scope.minDate = moment([$scope.vehicle.firstRegisteredYear.value]);
            $scope.maxDate = moment();

            $scope.$watch('vehicle.firstRegisteredYear.value', () => {
                $scope.minDate = moment([$scope.vehicle.firstRegisteredYear.value]);
            });

            $scope.annualMileageTooltip = {
                content: 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.tooltip.How many miles do you think youll drive over the next 12 months?',
                trigger: 'click'
            };

            $scope.keepAtTooltipDisplayKey = 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.tooltip.Will you be the owner and registered keeper of the car?';
            $scope.classOfUseTooltipDisplayKey = 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.tooltip.How do you use your car?';

            if($rootScope.productCode === 'CommercialVehicle') {
                $scope.keepAtTooltipDisplayKey = 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.tooltip.Will you be the owner and registered keeper of the car?.kin';
                $scope.classOfUseTooltipDisplayKey = 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.tooltip.How do you use your car?.kin';
            }

            $scope.keepAtTooltip = {
                content: $scope.keepAtTooltipDisplayKey,
                trigger: 'click'
            };
            $scope.classOfUseTooltip = {
                content: $scope.classOfUseTooltipDisplayKey,
                trigger: 'click'
            };

            //Filter Typelist to no longer include 'Not available' Using the NonMigrations Filter. 
            const dayTimeParkingTypelist = 'NonMigratingPoliciesDayTimeParking'; 
            const dayTimeParkingFilters = $scope.vehicle.dayTimeParking.aspects.availableValues[0].typelist.filters; 
            $scope.DayTimeParkingPrefixes = dayTimeParkingFilters.find(obj => {
                return obj.name === dayTimeParkingTypelist; 
            }); 

            // Modifications. The 'no' is a separate question in PC so need to add that manually.
            const noModifications = { code: 'no', name: 'No' };
            $scope.modificationsVM = {
                value: null,
                aspects: $scope.vehicle.hasBeenModified.aspects
            };
            // init modifications VM
            if ($scope.vehicle.hasBeenModified === true) {
                $scope.modificationsVM.value = $scope.vehicle.modification.value;
            } else if ($scope.vehicle.hasBeenModified.value === false) {
                $scope.modificationsVM.value = noModifications;
            }
            $scope.modificationsAvailableValues = [noModifications].concat($scope.vehicle.modification.aspects.availableValues);
            $scope.$watch('modificationsVM.value', (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    if (newValue === noModifications) {
                        $scope.vehicle.hasBeenModified.value = false;
                        $scope.vehicle.modification.value = null;
                    } else if (newValue) {
                        $scope.vehicle.hasBeenModified.value = true;
                        $scope.vehicle.modification.value = newValue;
                    } else {
                        $scope.vehicle.hasBeenModified.value = undefined;
                        $scope.vehicle.modification.value = undefined;
                    }
                }
            });

            $scope.$watch(() => $scope.vehicle.abiCode.value, (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    if(newValue != undefined) {
                        $scope.inReadonlyMode = false;
                    }
                }
            });
        }]
    };
}];
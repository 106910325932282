import templateStr from 'text!./pm-important-info-mta.html';

export default [() => {
    return {
        restrict: 'A',
        scope: {
            page: '<'
        },
        template: templateStr,
        controller: ['$scope', ($scope) => {
            if ($scope.page.is0AP === true) {
                $scope.nextBtnLabel = 'and.endorsement.common.directives.wizard-page-actions-mta.Finish';
                $scope.nextBtnCss = 'and-endorsement-buy-now-btn';
            }

            if ($scope.page.isAnnualPaymentPlan !== true && $scope.page.remainingInstalmens > 0) {
                if ($scope.page.isRP === true) {
                    $scope.nextBtnLabel = 'and.endorsement.common.directives.wizard-page-actions-mta.Refund';
                    $scope.nextBtnCss = 'and-endorsement-buy-now-btn';
                } else {
                    $scope.nextBtnLabel = 'and.endorsement.common.directives.wizard-page-actions-mta.BuyNow';
                    $scope.nextBtnCss = 'and-endorsement-buy-now-btn';
                }
            }
        }]
    };
}];
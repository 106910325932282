import templateStr from 'text!./available-miles.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            page: '<'
        }
    };
}];

import templateStr from 'text!./templates/change-box.html';

export default () => {
    return {
        restrict: 'E',
        template: templateStr,
        transclude: true,
        scope: {
            change: '=',
            title: '@',
            icon: '@',
            action: '@'
        },
        controller: ['$scope', ($scope) => {
            if (!$scope.action) {
                $scope.action = $scope.change ? $scope.change.action : null;
            }

            $scope.open = false;
        }]
    };
};

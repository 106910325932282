import BaseCtrl from 'edge/platform/base/BaseCtrl';
import _ from 'underscore';

const fn = function ($scope, StateUtils) {
    const ctrl = Object.create(BaseCtrl);
    const flowModel = StateUtils.activeDataForState(true).flowModel;


    /**
     * Method used by wizard ribbon set styles for active and visited steps in the wizard flow
     */
    function refreshStepsFn() {
        function setStepClasses(wizardSteps) {
            if (wizardSteps.length > 0) {
                // ANDIE Since there are multiple steps that mean the same wizard step, current ID must be an array
                const currentStateIdx = [];
                wizardSteps.forEach((step, index) => {
                    if (step.isSimilarTo_AND(flowModel.currentStep)) {
                        currentStateIdx.push(index);
                    }
                });

                for (let stepIdx = 0; stepIdx < wizardSteps.length; stepIdx++) {
                    const step = wizardSteps[stepIdx];
                    const visited = currentStateIdx.some((id)=> stepIdx <= id);
                    const activeClass = currentStateIdx.includes(stepIdx) ? 'gw-current' : '';
                    const notVisitedClass = (visited || stepIdx === 0) ? '' : 'gw-not-visited';
                    const visitedClass = (visited && currentStateIdx.includes(stepIdx)) ? 'gw-visited' : '';
                    const allowedVisitClass = flowModel.isStepAccessibleFromCurrent(step) ? '' : 'gw-not-allowed';
                    let completed = ''
                    if (activeClass === '' && notVisitedClass === '' && visitedClass === '' && allowedVisitClass === '') {
                        completed = 'completed'
                    }
                    step.styleClass = `${completed} ${activeClass} ${notVisitedClass} ${visitedClass} ${allowedVisitClass}`;
                    const activeClassMobile = currentStateIdx.includes(stepIdx) ? 'and-current' : '';
                    const notVisitedClassMobile = (visited || stepIdx === 0) ? '' : 'and-not-visited';
                    const visitedClassMobile = (visited && currentStateIdx.includes(stepIdx)) ? 'and-visited' : '';
                    const allowedVisitClassMobile = flowModel.isStepAccessibleFromCurrent(step) ? '' : 'and-not-allowed';
                    step.styleClassMobile = `${completed} ${activeClassMobile} ${notVisitedClassMobile} ${visitedClassMobile} ${allowedVisitClassMobile}`;
                }
            }
        }

        if (StateUtils.activeDataForState(true).model) {
            $scope.wizardSteps.length = 0;
            Array.prototype.push.apply($scope.wizardSteps, getWizardSteps_AND(flowModel.steps)); // ANDIE
            $scope.ribbonVisible = $scope.wizardSteps.length > 0;
            // ANDIE Allow all steps to go in to allow hidden steps to be represented in the wizard (as other steps)
            setStepClasses(flowModel.steps);
        }
    }

    /**
     * ANDIE
     * Filter out steps hidden from wizard navigation
     * @param steps
     * @returns {T[]}
     */
    function getWizardSteps_AND(steps) {
        return flowModel.steps.filter((step, index) => {
            if (step.data().hideNavigationStep) {
                return false;
            }
            // Filter out similar states, so that only one of them is in the wizard
            if (step.data().asWizardNavigationSteps) {
                return steps.findIndex((innerStep, innerIndex) => (innerIndex > index) && innerStep.isSimilarTo_AND(step)) === -1;
            }
            return true;
        });
    }

    ctrl.init = function () {
        // ANDIE
        // The if block works on array received from readonlyWizardSteps rather than flowmodel steps
        // The main purpose of the block to facilitate dummy Navigation bar before entering into flowmodel
        if ($scope.readonlyWizardSteps) {
            $scope.wizardSteps = $scope.readonlyWizardSteps;
            $scope.ribbonVisible = true;
        } else {
            if (!flowModel) {
                return;
            }
            $scope.wizardSteps = [];
            $scope.ribbonVisible = false;
            refreshStepsFn();

            flowModel.addStepChangeListener(refreshStepsFn);

            const onStateTransition = (actionHandlerObject, promise) => {
                $scope.stateTransitionPromise = promise;

                try {
                    let messageObject;
                    if (_.isFunction(actionHandlerObject.msgs)) {
                        messageObject = actionHandlerObject.msgs();
                    } else {
                        messageObject = actionHandlerObject.msgs;
                    }
                    $scope.stateTransitionMessage = messageObject.progress.message;
                } catch (ignore) { /* it's ok if there's no message */
                }
            };
            StateUtils.subscribeToStateTransitions(onStateTransition);

            $scope.$on('$destroy', () => {
                flowModel.removeStepChangeListener(refreshStepsFn);
                StateUtils.unsubscribeFromStateTransitions(onStateTransition);
            });
        }
    };

    /**
     * Method used by wizard ribbon to move between steps.
     * @param {Object} step - selected step
     */
    ctrl.selectStep = function (step) {
        StateUtils.jumpToTheStep(step);
    };

    ctrl.stepLabel = function (step) {
        return step.label();
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'StateUtils'];

export default fn;
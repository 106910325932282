import templateStr from 'text!./monthly-trips.html';
import moment from 'moment';

const getDaysLabels = (year, month) => {
    const days = [];
    const date = moment({year, month: month - 1});
    for (let i = 1; i <= date.daysInMonth(); i++) {
        days.push(i);
    }
    return days;
};

const getDataArray = (data, dayLabels) => {
    const dataArray = [];
    for (let i = 0; i < dayLabels.length; i++) {
        const daySummary = data.find((summary) => summary.day === i + 1);
        if (daySummary) {
            dataArray.push(daySummary.totalMiles);
        } else {
            dataArray.push(0); // No miles
        }
    }
    return dataArray;
};

export default ['ChartService_AND', 'ScrollService_AND',  (ChartService_AND, ScrollService_AND) => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            page: '<'
        },
        link: ($scope, element) => {
            let milesPerDayChart;
            const setMilesPerDayChart = (year, month, monthlyMilesPerDayData) => {
                const labels = getDaysLabels(year, month);
                const data = getDataArray(monthlyMilesPerDayData.monthlySummaryPerDay, labels);
                if (milesPerDayChart) {
                    ChartService_AND.updateChart(milesPerDayChart, data, labels);
                } else {
                    milesPerDayChart = ChartService_AND.getChart({
                        id: 'milesPerDayChart',
                        data,
                        labels,
                        yLabel: 'Miles Driven',
                        xLabel: 'Day of the Month'
                    });
                }
            };

            $scope.$watch(() => $scope.page.monthlyMilesPerDay, (newValue) => {
                if (newValue && !angular.equals({}, newValue)) {
                    setMilesPerDayChart($scope.page.selected.year, $scope.page.selected.month, $scope.page.monthlyMilesPerDay);
                }
            });

            let oldPolicyNumber;
            $scope.$watch(() => $scope.page.selected, (newValue, oldValue) => {
                if (oldValue && newValue !== oldValue) { // Don't scroll when initialising
                    // Only scroll if within the same policy
                    if (oldPolicyNumber === $scope.page.policyNumber) {
                        ScrollService_AND.scrollToElement(element, 0);
                    }
                }
                oldPolicyNumber = $scope.page.policyNumber;
            });
        }
    };
}];

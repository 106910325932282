import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, submissionViewModel, StateUtils, paymentType) => {
    const ctrl = Object.create(BaseCtrl);
    const flowModel = StateUtils.activeDataForState(true).flowModel;
    const submissionVM = submissionViewModel();

    ctrl.init = () => {
        $scope.submissionVM = submissionVM;
        if (!submissionVM.bindData.paymentDetails.value) {
            submissionVM.bindData.paymentDetails.value = {};
        }
        // If continuous authority does not exist, bypass card selection and go straight to new card payment.
        if ($scope.submissionVM.bindData.continuousAuthorityGranted_itb.value !== true) {
            flowModel.next(submissionVM);
        } else {
            $scope.wizardNextBtnConfig = {nextCss: 'and-buy-now-btn'};
            // Enable DTO validation on reuseExistingCard_itb
            $scope.submissionVM.bindData.paymentDetails.paymentMethod.value = paymentType.CREDIT_CARD;
            // Scope cleanup
            $scope.$on('$destroy', () => {
                // Clear payment method so it doesn't trigger possibly unnecessary DTO validation
                // (like if we were to land on this screen, and then go back and select installments.)
                $scope.submissionVM.bindData.paymentDetails.paymentMethod.value = undefined;
            });
        }
    };

    ctrl.getNextConfig = () => {
        if ($scope.submissionVM.bindData.paymentDetails.reuseExistingCard_itb.value === true) {
            $scope.wizardNextBtnConfig.nextLabel = 'and.quoteandbind.pm.views.quote.RenewNow';
            return $scope.wizardNextBtnConfig;
        }
    };

    $scope.next = (callback) => {
        callback($scope.submissionVM, {skipToBind: $scope.submissionVM.bindData.paymentDetails.reuseExistingCard_itb.value});
    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', 'submissionViewModel', 'StateUtils', 'paymentType'];
export default fn;
import templateStr from 'text!./wizard-page-actions-mta.html';
import FormUtil from 'edge/platform/modelvalidation/FormUtil';

export default ['$rootScope', '$q', function ($rootScope, $q) {
    return {
        template: templateStr,
        scope: {
            'next': '&',
            'nextLabel': '@?',
            'nextCss': '@?',
            'nextConfig': '<?',
            'disableNext': '<?'
        },
        require: ['?^form', '?^ngForm'],
        link(scope, element, attrs, formCtrls) {
            const formCtrl = formCtrls[0] || {};
            const handleResp = (model) => {
                if (model) {
                    $rootScope.$broadcast('andDisableValidationSummary'); // ANDIE Disable validation summary
                } else {
                    $rootScope.$broadcast('andEnableValidationSummary'); // ANDIE Trigger display of validation summary and scroll to it
                }
            };

            scope.goToNext = () => {
                FormUtil.markFormsSubmitted(formCtrl);
                $q.when(scope.next())
                    .then(handleResp)
                    .catch((error) => {
                        handleResp();
                        throw error;
                    });
            };

            scope.isBuyNowButton = () => {
                return scope.nextLabel === 'and.endorsement.common.directives.wizard-page-actions-mta.BuyNow';
            };
        }
    };
}];

import templateStr from 'text!./templates/pm-policy-changes.html';
import _ from 'underscore';

export default ['endorsement.NumberOfChanges', '$filter', '$state', '$timeout', (numberOfChanges, $filter, $state, $timeout) => {
    return {
        restrict: 'EA',
        scope: {
            nextSteps: '=',
            policyChange: '=',
            canEditState: '=',
            transitionFn: '='
        },
        template: templateStr,
        link: ($scope) => {
            $scope.goToPaState = (action) => {
                let transitionPromise;
                if (angular.isDefined(action)) {
                    switch (action.entityType) {
                        case 'address':
                            transitionPromise = $scope.transitionFn('PmAddress');
                            break;
                        case 'pmDriver':
                            transitionPromise = $scope.transitionFn('PmDriver');
                            break;
                        case 'pmVehicle':
                            transitionPromise = $scope.transitionFn('PmVehicle');
                            break;
                        default:
                            break;
                    }
                } else {
                    transitionPromise = $scope.transitionFn('PmCoverages');
                }

                if (transitionPromise) {
                    $scope.$root.$emit('endorsementShowHistory');
                }
            };

            $scope.getShortVehicleName = (v) => {
                // e.g. 2001 Mazda MPV
                return [v.year, v.make, v.model].join(' ');
            };

            $scope.getVehicleName = (fixedId) => {
                const vehicle = _.findWhere($scope.policyChange.lobData.personalMotor.coverables.pmVehicles, {
                    fixedId: parseInt(fixedId)
                });
                return typeof vehicle !== 'undefined' ? vehicle.getDisplayName() : '';
            };


            $scope.getChangeTitle = (change) => {
                if (change.entityType === 'vehicle') {
                    return change.entity ? $scope.getShortVehicleName(change.entity) : change.itemName;
                }
                return change.entity && typeof change.entity.getDisplayName === 'function' ?
                    change.entity.getDisplayName() :
                    change.itemName;
            };

            $scope.editButtons = {
                'canEditCoverages': true,
                'canEditVehicles': false,
                'canEditDrivers': false
            };

            /**
             * Checks if there are any line coverages
             * and whether they are some displayable coverages
             * @returns {Boolean}
             */
            $scope.hasCoverageChanges = () => {
                const allCoverages = _($scope.lineCoverageChanges).chain()
                    .values().flatten();
                return allCoverages.some(coverageChangeIsDisplayable).value() && !allCoverages.isEmpty().value();
            };

            /**
             * If coverage change doesn't have an itemName,
             * it won't be possible to display it in a change box
             * @param {Object} change
             * @returns {Boolean}
             */
            function coverageChangeIsDisplayable(change) {
                return !!(change.itemName);
            }

            function isLineCoverage(c) {
                return !c.parentId;
            }

            function coverageIsSelected(coverage) {
                return coverage.selected;
            }

            function updateChangesNumber(changeCollections) {
                $timeout(() => {
                    const lineChanges = _.first(changeCollections);
                    const restChanges = _.rest(changeCollections);
                    const lineChangesNum = _.isEmpty(lineChanges) ? 0 : 1;
                    const restChangesNum = restChanges
                        .map(_.property('length'))
                        .reduce((memo, item) => { // sum the items
                            return memo + item;
                        }, 0);
                    numberOfChanges.set(lineChangesNum + restChangesNum);
                });
            }


            function _refreshCoverages() {
                $scope.hasChanges = true;
                $scope.lineCoverageChanges = {};
                $scope.vehicleCoverageChanges = {};
                $scope.vehicleChanges = [];
                $scope.driverChanges = [];
                $scope.addressChanges = [];
                if ($scope.policyChange) {
                    _.each($scope.policyChange.history, (h) => {
                        switch (h.entityType) {
                            case 'coverage': {
                                if (coverageChangeIsDisplayable(h)) {
                                    if (isLineCoverage(h)) {
                                        const action = h.action;
                                        if (!$scope.lineCoverageChanges[action]) {
                                            $scope.lineCoverageChanges[action] = [h];
                                        } else {
                                            $scope.lineCoverageChanges[action].push(h);
                                        }
                                    } else {
                                        if (!$scope.vehicleCoverageChanges[h.parentId]) {
                                            $scope.vehicleCoverageChanges[h.parentId] = [];
                                        }
                                        $scope.vehicleCoverageChanges[h.parentId].push(h);
                                    }
                                }
                                break;
                            }
                            case 'pmVehicle': {
                                $scope.vehicleChanges.push(h);
                                break;
                            }
                            case 'pmDriver': {
                                $scope.driverChanges.push(h);
                                break;
                            }
                            case 'address': {
                                $scope.addressChanges.push(h);
                                break;
                            }
                            default:
                                break;
                        }
                    });
                }
            }

            _refreshCoverages();
            // maps driver ids to assigned vehicles
            $scope.pmVehicleDrivers = {};
            // maps vehicle ids to vehicle coverages
            $scope.selectedPmCoverages = {};

            // Updates an object that maps a vehicle's ID to selected coverages for this vehicle
            $scope.$watchCollection('policyChange.lobData.personalMotor.offerings.children[0].coverages.pmVehicleCoverages', (vehicleCovs) => {
                if (!vehicleCovs) {
                    return;
                }
                const vehicles = $scope.$eval('policyChange.lobData.personalMotor.coverables.pmVehicles');

                _.forEach(vehicleCovs, (cov) => {
                    const vehicle = _.findWhere(vehicles, {
                        fixedId: cov.fixedId
                    });
                    if (!vehicle) {
                        return;
                    }
                    $scope.selectedPmCoverages[vehicle.fixedId] =
                        cov.coverages.filter(coverageIsSelected).map((x) => {
                            // needed to construct an anchor link
                            x.parentPublicID = cov.publicID;
                            return x;
                        });
                });
            });

            // If some vehicle coverage has changed and there is no entry for the vehicle in history,
            // we still should display a change box for that vehicle
            $scope.$watchCollection('vehicleCoverageChanges', (covChanges) => {
                if (!_.isEmpty(covChanges)) {
                    const vehicles = $scope.$eval('policyChange.lobData.personalMotor.coverables.pmVehicles');
                    // coverages are stored in an array-like map with number keys
                    _(covChanges).values().forEach((vehicleChanges) => {
                        if (!_.isEmpty(vehicleChanges)) {
                            // vehicle coverage stores the reference to vehicle in a parentId prop
                            const vehicle = _.findWhere(vehicles, {
                                fixedId: vehicleChanges[0].parentId
                            });
                            const hasVehicleChange =
                                _.pluck($scope.vehicleChanges, 'entity').indexOf(vehicle) !== -1;
                            if (!hasVehicleChange) {
                                $scope.vehicleChanges.push({
                                    entityType: 'pmVehicle',
                                    entity: vehicle,
                                    action: 'changed'
                                });
                            }
                        }
                    });
                }
            });

            // the following function and two watch expressions
            // update a list of assigned vehicles for every driver
            // in a history
            function _updateDriverVehicles(rels, changes) {
                rels.forEach((rel) => {
                    const change = _.findWhere(changes, {
                        entity: rel.driver
                    });
                    if (change) {
                        const driver = change.entity;
                        const assignedVehiclesList = $scope.policyChange.lobData.personalMotor.coverables.getAssignedVehicles(driver);
                        driver.assignedVehiclesList = assignedVehiclesList;
                    }
                });
            }

            $scope.$watchCollection('policyChange.lobData.personalMotor.coverables.pmVehicleDrivers', (rels) => {
                if (Array.isArray(rels)) {
                    $timeout(() => {
                        _updateDriverVehicles(rels, $scope.driverChanges);
                    });
                }
            });
            $scope.$watchCollection('driverChanges', (changes) => {
                if (!_.isEmpty(changes)) {
                    _updateDriverVehicles($scope.policyChange.lobData.personalMotor.coverables.pmVehicleDrivers, changes);
                }
            });

            // find which currency that is in use now and create a zero value
            // which is capable of being formatted according to a current locale
            $scope.$watch('policyChange.transactionCost.currency', (newVal, oldVal) => {
                // take an arbitrary property wich is supposed to have currency
                // and watch if the currency changes
                if (typeof newVal === 'string' && oldVal !== newVal) {
                    $scope.zero = {
                        amount: 0,
                        currency: newVal
                    };
                }
            });

            $scope.$watchCollection('policyChange.history', () => {
                _refreshCoverages();
            });

            // update number of changes when there are
            // changes in the group below.
            const changesGroup = [
                'lineCoverageChanges',
                'vehicleChanges',
                'driverChanges',
                'addressChanges'
            ];
            updateChangesNumber(changesGroup.map(_.propertyOf($scope)));
            $scope.$watchGroup(changesGroup, updateChangesNumber);

            $scope.$watchGroup(['nextSteps.vehicles', 'nextSteps.drivers', 'nextSteps.address'], (val) => {
                if (angular.isDefined(val)) {
                    $scope.editButtons.canEditVehicles = val[0] || false;
                    $scope.editButtons.canEditDrivers = val[1] || false;
                    $scope.editButtons.canEditAddress = val[2] || false;
                }
            });
        }
    };
}];

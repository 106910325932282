import templateStr from 'text!./templates/amp-profilecontact-details.html';

export default () => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            contact: '='
        },
        require: '^form',

        link: (scope, element, attrs, Ctrl) => {
            scope.form = Ctrl;
            // scope.profileEmail = contact.
            // scope.updateCarrier = function(){};
        }
    };
};
import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, $state, $stateParams, supportPhone) => {
    const ctrl = Object.create(BaseCtrl);

    $scope.supportPhone = supportPhone;

    ctrl.init = () => {
        $scope.billingDetails = $stateParams.info;
    };

    ctrl.cancel = () => {
        if ($state.is('accounts.detail.billingAndPayment.setup-autopay-confirmation')) {
            $state.transitionTo('accounts.detail.billingAndPayment.payment', {
                accountNumber: $stateParams.accountNumber
            }, {
                reload: true // forces the controller reload and update all payments
            });
        } else {
            $state.transitionTo($state.$current.data.actions.cancel);
        }
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state', '$stateParams', 'config.supportPhone'];

export default fn;
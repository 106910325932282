import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./AuthLoginModal.html';
import styles from './AuthLoginModal-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(template, styles);

export default ['ModalService', function (ModalService) {
    let modalInstance = null;

    function showModal(data = {}) {
        modalInstance = ModalService.showSimpleDialog(hashedTemplate, data);
    }

    function hideModal() {
        if (!modalInstance) {
            return;
        }

        modalInstance.close();
        modalInstance = null;
    }

    return {
        showModal,
        hideModal
    };
}];
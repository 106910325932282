var PmProductCodes = ['PersonalMotor', 'personalMotor', 'pm'];
var TmProductCodes = ['TraditionalMotor', 'traditionalMotor', 'tm'];
var CvProductCodes = ['CommercialMotor', 'commercialMotor', 'cv'];

export default {
    normaliseProductCode: function normaliseProductCode(productCode) {
        if (PmProductCodes.includes(productCode)) {
            return 'PersonalMotor';
        }
        if (TmProductCodes.includes(productCode)) {
            return 'TraditionalMotor';
        }
        if (CvProductCodes.includes(productCode)) {
            return 'CommercialMotor';
        }
        return productCode;
    },
    getLobSpecificKeyValue: function getLobSpecificKeyValue(key) {
        return key;
    }
};
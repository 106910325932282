import _ from 'underscore';
import StatesConfig from 'edge/policy/your-insurance_AND/config/RouteConfig';
import SavedQuotes from 'edge/policy/your-insurance_AND/directives/saved-quotes/SavedQuotes';
import SavedQuotesTm from 'edge/policy/your-insurance_AND/directives/saved-quotes/saved-quotes-detail/SavedQuotesTm';
import PolicyHolderInfo from './directives/policy-holder-info/PolicyHolderInfo';
import PolicyDriverInfo from './directives/policy-driver-info/PolicyDriverInfo';
import PolicyVehicleInfo from './directives/policy-vehicle-info/PolicyVehicleInfo';
import HelpfulInformation from './directives/helpful-inormation/HelpfulInformation';
import PolicyQuoteExcess from './directives/policy-quote-excess/PolicyQuoteExcess';
import PolicyExtras from './directives/policy-extras/PolicyExtras';
import PolicyDetailWidget from './directives/policy-detail-widget/PolicyDetailWidget';
import RewardPointsBanner_AND from './directives/rewardPointsBanner_AND/RewardPointsBanner';// ANDIE
import AutoRenewalPreference from './directives/auto-renewal-preference/AutoRenewalPreference';

const appModule = angular.module('edge.and.your-insurance.app', [])
    .directive('andSavedQuotes', SavedQuotes)
    .directive('andSavedQuotesTm', SavedQuotesTm)

    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new StatesConfig(RootStateConfig);
        _.values(states).forEach(state => {
            $stateProvider.state(state);
        });
    }])
    .directive('andPolicyHolderInfo', PolicyHolderInfo)
    .directive('andPolicyDriverInfo', PolicyDriverInfo)
    .directive('andPolicyVehicleInfo', PolicyVehicleInfo)
    .directive('andHelpfulInformation', HelpfulInformation)
    .directive('andPolicyExcess', PolicyQuoteExcess)
    .directive('andPolicyExtras', PolicyExtras)
    .directive('andPolicyDetailWidget', PolicyDetailWidget)
    .directive('andRewardPointsBanner', RewardPointsBanner_AND) // ANDIE
    .directive('andAutoRenewalPreference', AutoRenewalPreference);

export default appModule;

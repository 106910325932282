import templateStr from 'text!./rewards-history.html';
import rewardInfo from 'text!./reward-info.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            transactions: '<'
        },
        controller: ['$scope', 'ModalService', ($scope, ModalService) => {
            $scope.showRewardInfo = (transaction) => {
                ModalService.showSimpleDialog(rewardInfo, {
                    transaction            
                }, {}, null, {
                    fullscreen: true,
                    backdrop: true,
                    windowClass: 'and-modal'
                });
            };
        }]
    };
}];

export default ['JsonRPCService', (JsonRPCService) => {
    return {
        getActivitySummaries: (activityStatus) => {
            return JsonRPCService.send('cc', 'claim/activity', 'getActivitySummaries', [activityStatus]);
        },

        completeActivity: (noteDTO) => {
            return JsonRPCService.send('cc', 'claim/activity', 'completeActivity', [noteDTO]);
        },

        getActivity: (activityID) => {
            return JsonRPCService.send('cc', 'claim/activity', 'getActivity', [activityID]);
        },

        createActivityNote: (activityID, noteDTO) => {
            return JsonRPCService.send('cc', 'claim/activity', 'createActivityNote', [activityID, noteDTO]);
        },

        getActivityNotes: (activityId) => {
            return JsonRPCService.send('cc', 'claim/activity', 'getActivityNotes', [activityId]);
        }

    };
}];
import template from 'text!./templates/pm-vehicles-edit.html';
import PolicyChange from 'edge/endorsement/common/models/PolicyChange';
import _ from 'underscore';

export default [() => ({
    template,
    'restrict': 'E',
    'scope': {
        model: '=',
        view: '=',
        next: '&'
    },
    'controller': ['$scope', '$translate', '$filter', 'endorsement.EndorsementService', 'ModalService', 'ViewModelService',
        function ($scope, $translate, $filter, EndorsementService, ModalService, ViewModelService) {
            resetFlags();

            $scope.toggleDriverAssignment = (driver, vehicle) => {
                if ($scope.model.policyChange.lobData.personalMotor.coverables.isDriverAssigned(driver, vehicle)) {
                    $scope.model.policyChange.lobData.personalMotor.coverables.removeDriverAssignment(driver, vehicle);
                } else {
                    $scope.model.policyChange.lobData.personalMotor.coverables.assignDriver(driver, vehicle);
                }
                $scope.model.policyChange.validate();
            };

            $scope.isDriverAssigned = (driver, vehicle) => {
                return $scope.model.policyChange.lobData.personalMotor.coverables.isDriverAssigned(driver, vehicle);
            };

            /**
             * Begin adding a new entry
             */
            $scope.onAddNew = () => {
                cleanAdd();
                setDefaultNewEntry();
                resetFlags();
                $scope.isAddingNew = true;
            };

            /**
             * Cancel the new entry form
             */
            $scope.cancelNewEntry = () => {
                const pmCoverables = $scope.model.policyChange.lobData.personalMotor.coverables;
                const vehicle = pmCoverables.pmVehicles[pmCoverables.pmVehicles.length - 1];
                pmCoverables.removeVehicle(vehicle);
                $scope.isAddingNew = false;
            };

            /**
             * Submit the new entry form
             */
            $scope.submitNewEntry = () => {
                if (vinUnique()) {
                    $scope.isAddingNew = false;
                    $scope.model.policyChange.validate();
                    saveChanges();
                }
            };

            /**
             * Begin editing an entry
             */
            $scope.onEdit = () => {
                cleanAdd();
                resetFlags();
                $scope.isEditing = true;
                $scope.backupAssignments = $scope.model.policyChange.lobData.personalMotor.coverables.pmVehicleDrivers.slice();
            };

            /**
             * Update an entry
             */
            $scope.updateEntry = () => {
                if (vinUnique()) {
                    $scope.isEditing = false;
                    $scope.selectedEntry = -1;
                    $scope.model.policyChange.validate();
                    saveChanges();
                }
            };

            /**
             * Stop editing an entry
             */
            $scope.cancelEdit = () => {
                $scope.isEditing = false;
                // Restore the model to its previous value
                if ($scope.selectedEntry !== -1) {
                    const selectedVehicle = $scope.view.lobData.personalMotor.coverables.pmVehicles.value[$scope.selectedEntry];
                    Object.assign(selectedVehicle, $scope.modelBackup);
                }
                $scope.selectedEntry = -1;
                $scope.model.policyChange.lobData.personalMotor.coverables.pmVehicleDrivers = $scope.backupAssignments;
                $scope.model.policyChange.validate();
            };

            /**
             * Begin removing entries
             */
            $scope.onRemove = () => {
                cleanAdd();
                resetFlags();
                $scope.isRemoving = true;
            };

            /**
             * Delete an entry
             * @param {Number} index
             */
            $scope.deleteEntry = (index) => {
                if (index > -1) {
                    const vehicle = $scope.model.policyChange.lobData.personalMotor.coverables.pmVehicles[index];
                    $scope.model.policyChange.lobData.personalMotor.coverables.removeVehicle(vehicle);
                    $scope.selectedEntry = -1;

                    if ($scope.view.lobData.personalMotor.coverables.pmVehicles.value.length <= 1) {
                        $scope.cancelRemove();
                    }
                }
                $scope.model.policyChange.validate();
                saveChanges();
            };

            /**
             * Stop removing entries
             */
            $scope.cancelRemove = () => {
                $scope.isRemoving = false;
            };

            /**
             * Select an entry
             * @param {Number} index
             */
            $scope.selectEntry = (index) => {
                const pmCoverables = $scope.view.lobData.personalMotor.coverables;
                const vehicleView = pmCoverables.pmVehicles.getElement(index);
                $scope.selectedVehicle = vehicleView.value;
                $scope.selectedEntry = index;
                if (!$scope.isReplacing) {
                    $scope.vehicleView = vehicleView;
                }
                $scope.modelBackup = angular.copy(pmCoverables.pmVehicles.value[$scope.selectedEntry]);
            };

            /**
             * Begin replacing an entry
             */
            $scope.onReplace = () => {
                cleanAdd();
                resetFlags();
                $scope.isReplacing = true;
                setDefaultNewEntry();
            };

            /**
             * Replace an entry by removing one and adding a new one
             */
            $scope.replaceEntry = () => {
                if (vinUnique()) {
                    if ($scope.selectedEntry > -1) {
                        const vehicle = $scope.model.policyChange.lobData.personalMotor.coverables.pmVehicles[$scope.selectedEntry];
                        $scope.model.policyChange.lobData.personalMotor.coverables.removeVehicle(vehicle);
                        $scope.selectedEntry = -1;
                    }
                    $scope.isReplacing = false;
                    $scope.model.policyChange.validate();
                    saveChanges();
                }
            };

            /**
             * Cancel replacing an entry
             */
            $scope.cancelReplace = () => {
                $scope.isReplacing = false;
                const pmCoverables = $scope.model.policyChange.lobData.personalMotor.coverables;
                const vehicle = pmCoverables.pmVehicles[pmCoverables.pmVehicles.length - 1];
                pmCoverables.removeVehicle(vehicle);
            };

            /**
             * Next button is clicked
             */
            $scope.onNext = () => {
                if ($scope.canProgress()) {
                    cleanAdd();
                    // Call next method on parent
                    $scope.next();
                }
            };

            /**
             * Determine whether a vehicle is currently being edited using the replace or add function
             * @param {Object} vehicle
             * @returns {boolean}
             */
            $scope.notBeingCreated = (vehicle) => {
                return (!$scope.isAddingNew && !$scope.isReplacing) || $scope.selectedVehicle !== vehicle;
            };

            /**
             * Check if the user has a vehicle form active but not saved
             * @returns {boolean}
             */
            $scope.inPreCommitStage = () => $scope.isAddingNew || (($scope.isReplacing || $scope.isEditing || $scope.isRemoving) && $scope.selectedEntry > -1);

            /**
             * Check whether vehicles exist
             * @returns {boolean}
             */
            $scope.vehiclesExist = () => $scope.view.lobData.personalMotor.coverables.pmVehicles && !($scope.view.lobData.personalMotor.coverables.pmVehicles.length === 0);

            /**
             * Check whether one vehicles exist
             * @returns {boolean}
             */
            $scope.onlyOneVehiclesExists = () => $scope.view.lobData.personalMotor.coverables.pmVehicles && $scope.view.lobData.personalMotor.coverables.pmVehicles.length === 1;

            /**
             * Check whether the user can progress. Can progress if there are no unassigned vehicles and drivers, or if the next step is drivers
             * @returns {boolean}
             */
            $scope.canProgress = () => {
                const vehiclesAssigned = !$scope.model.policyChange.lobData.personalMotor.coverables.unassignedVehicles || $scope.model.policyChange.lobData.personalMotor.coverables.unassignedVehicles.length === 0;
                const driversAssigned = !$scope.model.policyChange.lobData.personalMotor.coverables.unassignedDrivers || $scope.model.policyChange.lobData.personalMotor.coverables.unassignedDrivers.length === 0;
                const nextStepDrivers = $scope.model.nextSteps.pmDrivers;
                return nextStepDrivers || (vehiclesAssigned && driversAssigned);
            };

            /**
             * Populates a new entry with default values for use in the new entry form
             */
            function setDefaultNewEntry() {
                const vehicle = $scope.model.policyChange.lobData.personalMotor.coverables.createVehicle();
                $scope.vehicleView = $scope.view.lobData.personalMotor.coverables.pmVehicles.findFirstElement(vehicle);
                $scope.selectedVehicle = vehicle;
                $scope.replaceVehicle = vehicle;
            }

            /**
             * Cancels replace or add operation if they are active
             */
            function cleanAdd() {
                if ($scope.isReplacing) {
                    $scope.cancelReplace();
                }
                if ($scope.isAddingNew) {
                    $scope.cancelNewEntry();
                }
            }

            /**
             * Save pending policy changes in PC, shows error dialog on failure
             * @returns {Promise}
             */
            function saveChanges() {
                $scope.savePromise = EndorsementService.saveEndorsement($scope.model.policyChange).then(
                    (pc) => {
                        $scope.model.policyChange = new PolicyChange(pc);
                        $scope.view = ViewModelService.create($scope.model.policyChange, 'pc', 'edge.capabilities.policychange.dto.PolicyChangeDataDTO');
                    },
                    () => {
                        ModalService.showError(
                            'endorsement.pa.config.Unable to save draft policy change',
                            'endorsement.pa.config.An error occurred while attempting to save the policy change.'
                        );
                    }
                );

                return $scope.savePromise;
            }

            /**
             * Determine whether the vehicle is being added is unique. Also displays a message if is is not.
             * @returns {boolean}
             */
            function vinUnique() {
                let vinMatchedVehicle = ensureUniqueVIN($scope.selectedVehicle);
                if ($scope.isReplacing) {
                    const vehicle = $scope.model.policyChange.lobData.personalMotor.coverables.pmVehicles[$scope.selectedEntry];
                    vinMatchedVehicle = vinMatchedVehicle === vehicle;
                }
                if (vinMatchedVehicle) {
                    const msg = $translate('endorsement.pa.controllers.PAPolicyChangeDriverCtrl.VinMatchMsg', {
                        vinMatchedVehicle: vinMatchedVehicle.getDisplayName(undefined, $filter)
                    });
                    ModalService.showError(
                        'endorsement.pa.controllers.PAPolicyChangeDriverCtrl.VIN is not unique',
                        msg
                    );
                    return false;
                }
                return true;
            }

            /**
             * Checks whether a given vehicle's VIN is unique
             * @param {Object} vehicle
             * @returns {boolean}
             */
            function ensureUniqueVIN(vehicle) {
                const vehicleMatch = _.where($scope.model.policyChange.lobData.personalMotor.coverables.pmVehicles, {
                    vin: vehicle.vin
                });

                if (!vehicleMatch) {
                    return undefined;
                } else if (vehicleMatch.length === 1) {
                    return vehicleMatch[0] !== vehicle ? vehicleMatch : undefined;
                }
                return _.find(vehicleMatch, (v) => {
                    return v !== vehicle;
                });
            }

            /**
             * Resets all of the flags to their default false state
             */
            function resetFlags() {
                $scope.isRemoving = false;
                $scope.isReplacing = false;
                $scope.isEditing = false;
                $scope.isAddingNew = false;
                $scope.selectedEntry = -1;
            }
        }
    ]
})];

import templateStr from 'text!./pm-quote-mta.html';

export default [() => {
    return {
        restrict: 'A',
        scope: {
            page: '='
        },
        template: templateStr,
        controller: ['$scope', ($scope) => {
            const vehicle = $scope.page.policyChangeVM.lobData.personalMotor.coverables.pmVehicles.children[0].value;
            $scope.noRegError = false;
            $scope.validateAndGoToNext = () => {
                if (vehicle.registrationNumber) {
                    $scope.page.goToNext();
                } else {
                    $scope.noRegError = true;
                }
            };
        }]
    };
}];
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import Claim from 'edge/fnol/common/models/Claim';

const fn = function (
    $scope, $state, $stateParams, LoadSaveService, ModalService, wizardFlowService,
    fnolCompositeFlowDefn, ViewModelService, originState
) {
    const ctrl = Object.create(BaseCtrl);
    const parentWizardState = 'baseFnolWizard';
    const returnState = ($stateParams.accountNumber) ? ['accounts.detail.claims', {
        accountNumber: $stateParams.accountNumber
    }] : [originState.name, originState.params];

    ctrl.init = function () {
        const stepToStateMapping = {
            returnHome: () => returnState,
            confirmation: (model) => ['fnolconfirmation', {
                claimNumber: model.claimNumber.value,
                returnStateInfo: returnState
            }],
            error: (model) => ['contactAgent', {
                claimNumber: (model) ? model.claimNumber.value : null,
                returnStateInfo: returnState
            }]
        };

        const flowfactory = wizardFlowService(fnolCompositeFlowDefn, parentWizardState, stepToStateMapping);

        if ($stateParams.claimNumber) { // resume draft claim
            const claimLoadPromise = LoadSaveService.getClaim($state.params.claimNumber);
            $scope.claimLoadPromise = claimLoadPromise;

            claimLoadPromise.then((draftClaim) => {
                const claimModel = ViewModelService.create(new Claim(draftClaim), 'cc', 'edge.capabilities.claim.fnol.dto.FnolDTO');
                flowfactory.resume(claimModel, {
                    accountNumber: $stateParams.accountNumber,
                    policyNumber: $stateParams.policyNumber
                });

            }).catch(() => {
                const errorPromise = ModalService.showError(
                    'fnol.common.controllers.FnolEntryCtrl.Error retrieving claim',
                    'fnol.common.controllers.FnolEntryCtrl.An error occurred attempting to retrieve claim.'
                ).result;
                errorPromise.then(() => {
                    // return the originating state
                    $state.go(originState.name, originState.params);
                });
            });
        } else {
            const claimModel = ViewModelService.create(new Claim(), 'cc', 'edge.capabilities.claim.fnol.dto.FnolDTO');
            flowfactory.start(claimModel, {
                accountNumber: $stateParams.accountNumber,
                policyNumber: $stateParams.policyNumber
            });
        }
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state', '$stateParams', 'fnol.LoadSaveService', 'ModalService', 'WizardFlowService', 'fnol.CompositeFlowDefn', 'ViewModelService', 'originState'];

export default fn;
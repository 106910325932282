import templateStr from 'text!./change-docs-pref.html';

export default ['$filter', '$rootScope', '$timeout', 'UserProfileService_AND', 'ViewModelService',
    ($filter, $rootScope, $timeout, UserProfileService_AND, ViewModelService) => {
        const API_ERROR = $filter('translate')('and.directives.change-docs-pref.APIError');

        return {
            restrict: 'A',
            template: templateStr,
            scope: {},
            controller: ['$scope', ($scope) => {
                const setAPIError = (message) => {
                    $scope.apiError = message;
                };
                let initObject = {};
                let savedValue;

                if ($rootScope.accountContext && $rootScope.accountContext.accountHolderVM) {
                    initObject = {
                        documentationPreference_itb: $rootScope.accountContext.accountHolderVM.documentationPreference_itb.value
                    };
                }
                $scope.userProfileVM = ViewModelService.create(initObject, 'pc', 'itb.edge.capabilities.profileinfo.user.dto.UserProfileDTO', null);
                savedValue = $scope.userProfileVM.documentationPreference_itb.value;

                $scope.hasDocsPrefChanged = () => savedValue !== $scope.userProfileVM.documentationPreference_itb.value;
                $scope.clearAPIMessage = () => setAPIError(null);
                $scope.clearSuccessMsg = () => {
                    $scope.isSuccessVisible = false;
                };

                $scope.submit = (form) => {
                    form.submitted = true;
                    $scope.clearAPIMessage();
                    if (form.valid === false) {
                        return;
                    }
                    $scope.inFlight = true;
                    return UserProfileService_AND.updateDocsPreference($scope.userProfileVM.documentationPreference_itb.value.code)
                        .then((data) => {
                            $rootScope.accountContext.accountHolderVM = ViewModelService.create(data, 'pc', 'itb.edge.capabilities.profileinfo.user.dto.UserProfileDTO', null);
                            $scope.isSuccessVisible = true;
                            $timeout(() => { // Clear success message after 10 seconds
                                $scope.clearSuccessMsg();
                            }, 10000);
                        })
                        .catch(() => {
                            setAPIError(API_ERROR);
                        })
                        .finally(() => {
                            $scope.inFlight = false;
                        });
                };

                $scope.$watch(() => {
                    if ($rootScope.accountContext) {
                        return $rootScope.accountContext.accountHolderVM;
                    }
                    return null;
                }, (newValue) => {
                    if (newValue) {
                        $scope.userProfileVM.documentationPreference_itb.value = newValue.documentationPreference_itb.value;
                        savedValue = $scope.userProfileVM.documentationPreference_itb.value;
                    }
                });
            }]
        };
    }];
import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./LockLogin.html';
import styles from './LockLogin-hashed.scss';
import AuthenticationService from 'plugin?auth-type!gw-portals-auth-js/AuthenticationService';
import ComponentUtil from 'gw-portals-util-js/ComponentUtil';
import appFeatures from 'effective-features!';
import moment from 'moment';
angular.module('cookiesExample', ['ngCookies'])

// VARS
const hashedTemplate = cssUtil.hashTemplate(template, styles);

// EXPORT

export default {
    template: hashedTemplate,
    controller: [
        '$scope', '$state', 'state_plugin_config', 'oAuth', '$stateParams', 'ModalService', 'brandingData','$cookies', '$q', 'StaticDocumentsService_AND',
        function ($scope, $state, STATE_PLUGIN_CONFIG, oAuth, $stateParams, ModalService, brandingData, $cookies, $q, StaticDocumentsService_AND ) {
            // VARS
            const authenticationService = AuthenticationService(oAuth);
            const $ctrl = this;
            $scope.brand = brandingData.toLowerCase();
            $ctrl.loginError = '';// errors
            $ctrl.isLoginReqInProgress = false;// animation
            $ctrl.username = $stateParams.username || undefined;

            $scope.getConsetCookie = function() {
                var consentCookie = $cookies.get('consent');
                return consentCookie;
            };

            // Default model attr required by gw-pl-inline-ctrl-group directive
            $ctrl.input = {aspects: {}};

            $ctrl.isDateBeforeAndUk = function () {
                return moment().isBefore(moment('20201201')
                    .startOf('day'));
            };
            $ctrl.isDateBeforeEndOfMaintenance = function () {
                return moment().isBefore(moment('20201213 10:00', 'YYYY-MM-DD HH:mm')
                    .startOf('minute'));
            };
            // METHODS
            const setCtrlParam = ComponentUtil.setCtrlParam($ctrl, $scope);

            function setloginError(value) {
                setCtrlParam('loginError', value);
            }

            $ctrl.login = function () {
                setloginError('');// reset errors

                if (!$ctrl.username || !$ctrl.password) { // check if empty
                    setloginError('fieldEmpty');
                    return;
                }

                setCtrlParam('isLoginReqInProgress', true);// animation

                authenticationService.login({
                    username: $ctrl.username,
                    password: $ctrl.password
                })
                    .then(() => {
                        // logged in
                        setCtrlParam('isLoginReqInProgress', false);// animation
                        // reset errors
                        setloginError('');
                        // redirect user to home view
                        $state.go(STATE_PLUGIN_CONFIG.home);
                    }, (err) => {
                        setCtrlParam('isLoginReqInProgress', false);// animation

                        if (!err || !err.error) {
                            // something went wrong
                            setloginError('invalidAttempt');
                            return;
                        }

                        switch (err.error) {
                            case authenticationService.ERRORS.auth.login_failure: // credentials are wrong
                            case authenticationService.ERRORS.tokens.notLoggedIn: // tokens check problem
                                setloginError('fieldsIncorrect');
                                break;
                            case authenticationService.ERRORS.auth.account_locked: { // account is locked
                                setloginError('accountLocked');
                                break;
                            }
                            default:
                                setloginError('invalidAttempt');
                                break;
                        }
                    });
            };

            $ctrl.forgotPassword = function () {
                $state.go('authForgotPassword');
            };

            $ctrl.forgotPasswordEnabled = function () {
                return appFeatures.auth && appFeatures.auth.forgotPasswordAllowed;
            };

            $q.all([
               StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.IMPORTANT_INFORMATION),
             ]).then((result) => {
               $scope.importantInfoDoc = result[0];
            });
        }]
};
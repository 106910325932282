import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./map.html';
import styles from './LossLocationMap-hashed.scss';
import controller from './LossLocationMapCtrl';

/** Loss Location Map
 * arguments:
 *  mapName - unique name of map (passed as id to map container)
 *  coords - geolocation as object {latitude: xx, longitude: xx}
 *  infoBarHidden - optional, set to true if info bar should be hidden
 */
export default {
    template: cssUtil.hashTemplate(template, styles),
    controller,
    bindings: {
        mapName: '@',
        coords: '<',
        infoBarHidden: '<?',
        onPositionChange: '&'
    }
};

import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import 'conditional?if=fnol-lob&equals=pa!edge/fnol/pa/app';
import 'conditional?if=fnol-lob&equals=ca!edge/fnol/ca/app';
import 'conditional?if=fnol-lob&equals=ho!edge/fnol/ho/app';
import 'conditional?if=fnol-lob&equals=wc!edge/fnol/wc/app';
import 'conditional?if=fnol-lob&equals=cp!edge/fnol/cp/app';
import 'conditional?if=fnol-lob&equals=pm!edge/fnol/pm/app'; // ANDIE
import 'edge/fnol/general/app';
import 'conditional?if=role.vendor&equals=true!gw-portals-tracking-angular';

const lobModules = [];
if (!ApplicationFeatures.hasFeature('role.vendor')) {
    if (ApplicationFeatures.hasFeature('fnol-lob.pa')) {
        lobModules.push('edge.fnol.pa.app');
    }
    if (ApplicationFeatures.hasFeature('fnol-lob.ca')) {
        lobModules.push('edge.fnol.ca.app');
    }
    if (ApplicationFeatures.hasFeature('fnol-lob.ho')) {
        lobModules.push('edge.fnol.ho.app');
    }
    if (ApplicationFeatures.hasFeature('fnol-lob.wc')) {
        lobModules.push('edge.fnol.wc.app');
    }
    if (ApplicationFeatures.hasFeature('fnol-lob.cp')) {
        lobModules.push('edge.fnol.cp.app');
    }
    if (ApplicationFeatures.hasFeature('fnol-lob.pm')) { // ANDIE
        lobModules.push('edge.fnol.pm.app');
    }
    lobModules.push('edge.fnol.general.app');
} else {
    lobModules.push('gw-portals-tracking-angular');
}

export default lobModules;
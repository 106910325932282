import _ from 'underscore';
import templateStr from 'text!./templates/payment-line-info.html';
import InvoiceStatistics from 'edge/billing/util/InvoiceStatistics';

export default () => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            gwBillingPaymentLineInfo: '='
        },
        controller: ['$scope', 'config.supportPhone', 'InsuranceSuiteMetadata', '$state', '$stateParams', ($scope, supportPhone, insuranceSuiteMetadata, $state, $stateParams) => {
            const typelist = insuranceSuiteMetadata.get('bc').types.getTypelist('InvoiceStatus');
            $scope.info = $scope.gwBillingPaymentLineInfo;
            $scope.supportPhone = supportPhone;
            $scope.showSchedule = false;

            $scope.overdueStat = InvoiceStatistics.aggregateBilling(_.filter($scope.info ? $scope.info.invoiceSummary : [], InvoiceStatistics.isOverdue));
            $scope.billedStat = InvoiceStatistics.aggregateBilling(_.filter($scope.info ? $scope.info.invoiceSummary : [], InvoiceStatistics.isBilled));

            if ($scope.overdueStat && $scope.billedStat) {
                $scope.totalDue = {
                    'amount': $scope.overdueStat.amountDue.amount + $scope.billedStat.amountDue.amount,
                    'currency': $scope.overdueStat.amountDue.currency
                };
            } else if ($scope.overdueStat) {
                $scope.totalDue = $scope.overdueStat.amountDue;
            } else if ($scope.billedStat) {
                $scope.totalDue = $scope.billedStat.amountDue;
            }


            const firstUnpaid = _.min(
                _.filter($scope.info ? $scope.info.invoiceSummary : [], (invoice) => {
                    return invoice.invoiceStatus === 'planned';
                }),
                (invoice) => {
                    return new Date(invoice.dueDate).getTime();
                }
            );

            $scope.firstUnpaidBill = firstUnpaid === Infinity ? null : firstUnpaid; // _.min() returns Infinity for empty sets

            $scope.invoiceStatusName = (typeCode) => {
                return typelist.getCode(typeCode).name;
            };

            $scope.getAutoPayState = (streamId) => {
                if ($state.is('accounts.detail.billingAndPayment')) {
                    return $state.href('accounts.detail.billingAndPayment.setup-autopay', {
                        accountNumber: $stateParams.accountNumber,
                        streamId
                    });
                }
                return $state.href('billing-setup-group-autopay', {
                    streamId
                });
            };
        }]
    };
};
import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = function () {
        $scope.fnol.optionalLossLocation = $scope.fnol.optionalLossLocation || {};
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope'];

export default fn;
import templateStr from 'text!./templates/policy-coverages.html';
import _ from 'underscore';

export default [() => {
    return {
        restrict: 'E',
        template: templateStr,
        scope: {
            'policy': '='
        },
        controller: ['$scope', ($scope) => {
            // Use generic coverage directive if none of the lobs objects have data
            $scope.$watch('policy', (policy) => {
                if (policy) {
                    $scope.useGenericCoverages = policy.lobs && _.every(policy.lobs, (lob) => _.isEmpty(lob));
                }
            });
        }]
    };
}];

import _ from 'underscore';
import EntityUtil from 'gw-portals-util-js/EntityUtil';
import Contact from 'edge/fnol/common/models/Contact';
import PolicyLineExtensionConfiguration from 'integration/fnol/PolicyLineExtensionConfiguration';
import DateUtil from 'gw-portals-util-js/DateUtil';

function Claim(data) {
    if (data) {
        data.contacts = _.map(data.contacts, (c) => {
            return new Contact(c);
        });

        if (data.claimReporter) {
            data.claimReporter.reportedBy = this._findContact(data, data.claimReporter.reportedBy);
        }

        data.mainContact = this._findContact(data, data.mainContact);

        _.each(data.relatedContacts, function (rc) {
            rc.contact = this._findContact(data, rc.contact);
        }, this);

        _.extend(this, data);
        // Convert loss date to a date object.
        this.lossDate = new Date(this.lossDate);
    } else {
        this.lossDate = DateUtil.getCurrentTimeTrimmedToHour();
        this.contacts = [];
        this.relatedContacts = [];
    }

    if (this.policy && !this.lossType) {
        PolicyLineExtensionConfiguration.setLossDetails(this);
    }
    PolicyLineExtensionConfiguration.mixinLobDraftDataExtensions(this);
}

Claim.prototype.nextId = () => {
    return EntityUtil.nextId();
};

Claim.prototype.getLossCategory = function () {
    let category = null;
    if (this.lossType && this.lossCause) {
        switch (this.lossType) {
            case 'AUTO':
                switch (this.lossCause) {
                    case 'theftentire':
                    case 'theftparts':
                    case 'Theft_itb': // ANDIE
                        category = 'theft';
                        break;
                    case 'glassbreakage':
                    case 'Windscreen_itb': // ANDIE
                        category = 'glass';
                        break;
                    case 'otherobjcoll':
                    case 'MaliciousDamage_itb': // ANDIE
                        category = 'other';
                        break;
                    default:
                        category = 'collision';
                        break;
                }
                break;

            case 'PR':
                switch (this.lossCause) {
                    case 'fire':
                        category = 'fire';
                        break;
                    case 'waterdamage':
                    case 'mold':
                        category = 'water';
                        break;
                    case 'burglary':
                    case 'vandalism':
                    case 'riotandcivil':
                        category = 'crime';
                        break;
                    default:
                        category = 'other';
                }
                break;
            default:
                break;
        }
    }
    return category;
};

Claim.prototype.createContact = function (data) {
    const contactData = _.extend({}, data, {tempID: this.nextId()});
    const newContact = new Contact(contactData);
    this.contacts.push(newContact);
    return newContact;
};

Claim.prototype.availableContacts = function () {
    return this.contacts.slice(0);
};

Claim.prototype.availableRelatedContacts = function () {
    return _.filter(this.contacts, function (c) {
        return _.findWhere(this.relatedContacts, {
            contact: c
        }) === undefined;
    }, this);
};

Claim.prototype.addRelatedContact = function (c) {
    if (!c) {
        c = this.createContact();
    }
    this.relatedContacts.unshift({
        role: 'witness',
        injured: false,
        contact: c
    });
};

Claim.prototype.removeRelatedContact = function (rc) {
    const idx = _.findIndex(this.relatedContacts, {
        contact: rc
    });
    this.relatedContacts.splice(idx, 1);
};

Claim.prototype.changePolicy = function (policy) {
    if (!this.policy || this.policy.policyNumber !== policy.policyNumber) {
        this.policy = policy;
        this.publicID = null;
        this.lossLocation = null;
        this.contacts = [];
        this.mainContact = null;
        this.relatedContacts = [];
        this.contacts = [];
        // Do not clear the claim number as it is reused!

        PolicyLineExtensionConfiguration.setLossDetails(this);
        PolicyLineExtensionConfiguration.mixinLobDraftDataExtensions(this);
    }
};

Claim.prototype._findContact = function (claim, contact, matchKeysArray) {
    return EntityUtil.findMatchingEntity(claim.contacts, contact, {
        Ctor: Contact,
        keys: matchKeysArray
    });
};

export default Claim;
import PolicyChange from 'edge/endorsement/common/models/PolicyChange';
import _ from 'underscore';

/**
 * Returns a function that transitions to the quote state and show the shopping cart view on mobile
 * @param {scope} $rootScope
 * @param {function} next
 *
 * @returns {Function}
 */
function quote($rootScope, next) {
    return () => {
        $rootScope.$emit('endorsementShowHistory');
        next();
    };
}

/**
 * Add a new history
 * @param {type} type
 * @param {scope} $scope
 * @param {lobName} lobName
 * @param {coverageName} coverageName
 * @private
 */
function _addToHistory(type, $scope, lobName, coverageName) {
    if ($scope.wizard.model.policyChange.lobData[lobName].offerings) {
        $scope.wizard.model.policyChange.addToHistory('changed', type, $scope.wizard.model.policyChange.lobData[lobName].offerings[0].coverages[coverageName]);
    } else {
        $scope.wizard.model.policyChange.addToHistory('changed', type, $scope.wizard.model.policyChange.lobData[lobName].coverages[coverageName]);
    }
}

/**
 * Returns a function that adds a base coverage change to the history on coverage change
 * @param {scope} $scope
 * @param {String} lobName
 * @param {Function} update
 * @param {String} alternativeName
 * @returns {function()}
 */
function onBaseCovChange($scope, lobName, update, alternativeName) {
    const coverageName = alternativeName || 'baseCoverages';
    return () => {
        _addToHistory('baseCoverage', $scope, lobName, coverageName);
        update();
    };
}

/**
 * Returns a function that adds an additional coverage change to the history on coverage change
 * @param {scope} $scope
 * @param {String} lobName
 * @param {Function} update
 * @param {String} alternativeName
 * @returns {function()}
 */
function onAdditionalCovChange($scope, lobName, update, alternativeName) {
    const coverageName = alternativeName || 'additionalCoverages';
    return () => {
        _addToHistory('additionalCoverage', $scope, lobName, coverageName);
        update();
    };
}

/**
 * Returns a function that attempts to update the coverages. Will redirect to contact agent on failure
 * @param {scope} $scope - the angular controller scope
 * @param {Object} $state - the angular $state service
 * @param {Object} EndorsementService - The Endorsement service
 * @returns {function()}
 */
function updateCoverages($scope, $state, EndorsementService) {
    return () => {
        $scope.loading = true;

        const lobCoverages = _.mapObject($scope.wizard.model.policyChange.lobData, lob => {
            return lob.offerings[0].coverages;
        });

        return EndorsementService.updateCoverages($scope.wizard.model.policyChange.jobID, lobCoverages).then(
            pc => {
                $scope.wizard.model.policyChange = new PolicyChange(pc);
                $scope.wizard.viewModel.value = $scope.wizard.model.policyChange;
                $scope.loading = false;
            },
            () => {
                $scope.loading = false;
                $state.go('endorsContactAgent');
            }
        );
    };
}


export default {
    quote: quote,
    onBaseCovChange: onBaseCovChange,
    onAdditionalCovChange: onAdditionalCovChange,
    updateCoverages: updateCoverages,
};
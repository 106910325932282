import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, $state) => {
    const ctrl = Object.create(BaseCtrl);
    $scope.policyNumber = $state.params.policyNumber;
    ctrl.gotBackToHomeState = () => {
        const nextState = $state.$current.data.actions.next;
        $state.transitionTo(nextState, {
            policyNumber: $scope.policyNumber
        });
    };
    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state'];

export default fn;

export default ['$q', 'JsonRPCService', 'CacheService_AND', ($q, JsonRPCService, CacheService_AND) => {

    const endpoint = 'policy/telematics';

    return {
        getAvailableMiles: (policyNumber) => {
            return CacheService_AND.cacheAware(`${endpoint}/getAvailableMiles`, [policyNumber], () => JsonRPCService.send('pc', endpoint, 'getAvailableMiles', [policyNumber]));
        },
        getConsumedMiles: (policyNumber) => {
            return CacheService_AND.cacheAware(`${endpoint}/getConsumedMiles`, [policyNumber], () => JsonRPCService.send('pc', endpoint, 'getConsumedMiles', [policyNumber]));
        },
        getMonthlySummaries: (policyNumber) => {
            return CacheService_AND.cacheAware(`${endpoint}/getMonthlySummaries`, [policyNumber], () => JsonRPCService.send('pc', endpoint, 'getMonthlySummaries', [policyNumber]));
        },
        getMonthlyMilesPerDay: (policyNumber, month, year) => {
            return CacheService_AND.cacheAware(`${endpoint}/getMonthlyMilesPerDay`, [policyNumber, month, year], () => JsonRPCService.send('pc', endpoint, 'getMonthlyMilesPerDay', [policyNumber, month, year]));
        },
        getMonthlyTrips: (policyNumber, month, year, page, pageSize) => {
            return CacheService_AND.cacheAware(`${endpoint}/getMonthlyTrips`, [policyNumber, month, year, page, pageSize], () => JsonRPCService.send('pc', endpoint, 'getMonthlyTrips', [policyNumber, month, year, page, pageSize]));
        },
        getScores: (policyNumber, bonusID) => {
            return CacheService_AND.cacheAware(`${endpoint}/getScores`, [policyNumber, bonusID], () => JsonRPCService.send('pc', endpoint, 'getScores', [policyNumber, bonusID]));
        },
        getBonusStatistics: (policyNumber) => {
            return CacheService_AND.cacheAware(`${endpoint}/getBonusStatistics`, [policyNumber], () => JsonRPCService.send('pc', endpoint, 'getBonusStatistics', [policyNumber]));
        },
        getBonusMiles: (policyNumber) => {
            return CacheService_AND.cacheAware(`${endpoint}/getBonusMiles`, [policyNumber], () => JsonRPCService.send('pc', endpoint, 'getBonusMiles', [policyNumber]));
        }
    };
}];
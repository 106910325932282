import templateStr from 'text!./templates/choice-button.html';

export default [() => {
    return {
        restrict: 'E',
        template: templateStr,
        scope: {
            onConfirm: '&',
            label: '@',
            yes: '@',
            no: '@',
            disabled: '='
        },
        controller: ['$scope', '$timeout', ($scope, $timeout) => {
            let timeout;
            $scope.isOpen = false;
            $scope.open = () => {
                if (!$scope.disabled) {
                    $scope.isOpen = true;
                    timeout = $timeout(() => {
                        $scope.isOpen = false;
                        timeout = null;
                    }, 1000000);
                }
            };

            $scope.close = () => {
                if (timeout) {
                    $timeout.cancel(timeout);
                }
                $scope.isOpen = false;
            };

            $scope.confirm = () => {
                $scope.isOpen = false;
                $scope.onConfirm();
            };
        }]
    };
}];

import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, $state, $stateParams, $window) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.confirmedClaimId = $stateParams.claimNumber;
    };

    ctrl.printConfirmation = () => {
        $window.print();
    };

    ctrl.gotBackToHomeState = () => {
        const [stateName, stateParms] = $stateParams.returnStateInfo;
        $state.go(stateName, stateParms);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state', '$stateParams', '$window'];

export default fn;
import FlowUtil from './../FlowUtil';
/*
 * This component has 2 responsibilities
 * 1) To hook up a flow step to a router state so that step transitions in the flow trigger the correct router transitions
 * 2) Clean-up the flowModel object from the state data when we transition to an exit step or go to a non-flow router state
 */
export default function ($state, StateUtils, parentWizardState, stepToStateMapping, SessionService_AND) {
    // Check if there has been a router transition that is going from a wizard state to non-wizard state as result of
    // a router state change (user clicks on home page link for example) rather than moving to a flow exit.
    // Prompt user that they are exiting wizard and call the cancel action
    const routerExitListenerRemoveFn = StateUtils.beforeStateChangeStart((event, toState, toParams, fromState) => {

        if (ifExitingWizardState(fromState, toState)) {
            if (toState.data && toState.data.isAuthPage) {
                cleanupWizardStateData();
            } else {
                // initially prevent transition until we get confirmation
                event.preventDefault();

                /**
                 * ANDIE START
                 * Remove the popup and 'cancel step' execution when going directly out of the wizard.
                 */
                cleanupWizardStateData();
                $state.go(toState, toParams);
                // const wizardStateData = StateUtils.dataForState(parentWizardState);
                // const visitedSteps = wizardStateData.flowModel.visitedSteps.map(step => step.name);
                // wizardStateData.flowModel.cancel(wizardStateData.model, {visitedSteps})
                //     .then(() => {
                //         cleanupWizardStateData();
                //         $state.go(toState, toParams);
                //     });
                /**
                 * ANDIE END
                 */
            }
        } else if (isGoingToRoot(toState)) {
            // extra check to see if we're going from a non-wizard state to a non-wizard state
            // an example could be the retrieve screen, which is not a wizard state
            cleanupWizardStateData();
        }
    });


    function setModelData(model) {
        const wizardStateData = StateUtils.dataForState(parentWizardState);
        wizardStateData.model = model;
    }
    
    function isGoingToRoot(toState) {
        return toState.parent === 'root';
    }

    function cleanupWizardStateData() {
        const stateData = StateUtils.dataForState(parentWizardState);
        if (stateData.flowModel) {
            stateData.flowModel.removeAllStepChangeListeners();
        }
        StateUtils.removeDataForState(parentWizardState, ['flowModel', 'model'], true);
        routerExitListenerRemoveFn();
    }

    function ifExitingWizardState(fromState, toState) {
        const fromStateInWizard = StateUtils.hasState(parentWizardState, fromState);
        const toStateInWizard = StateUtils.hasState(parentWizardState, toState);
        return fromStateInWizard && !toStateInWizard;
    }


    return function (flowDefn) {
        /*
         * Step change listener callback
         */
        return function (model, fromStep, nextStep, extraArgs) {
            // default routing is to go to state with same name as step
            nextStep = FlowUtil.getStepOrJunctionObject(nextStep);
            let nextState = nextStep.name;
            let nextStateArgs = extraArgs;
            const specificRouterHandler = stepToStateMapping[nextStep.name];
            if (specificRouterHandler) {
                [nextState, nextStateArgs] = specificRouterHandler(model, nextStep, fromStep, extraArgs);
            }

            // if exiting wizard clean up data held in top level wizard state
            if (flowDefn.exits[nextStep.name]) {
                cleanupWizardStateData();
            } else {
                // Ideally we would pass model data as a parameter instead of using the state data but ui-router copies
                // parameter object instead of using the object reference.This causes problems for objects like view model
                // which initialise properties in the object constructor
                setModelData(model);
            }
            SessionService_AND.resetInactivityTimer(); // ANDIE Reset frontend session on every state change
            $state.go(nextState, nextStateArgs);
        };
    };
}

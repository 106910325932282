import templateStr from 'text!./templates/add-new-driver.html';

export default ['LocaleService', (LocaleService) => {
    return {
        restrict: 'A',
        scope: {
            contact: '='
        },
        require: '^form',
        template: templateStr,

        link: (scope, element, attrs, Ctrl) => {
            scope.form = Ctrl;
            scope.phoneMask = LocaleService.getRegionSamplePhoneNumber().replace(/[0-8]/g, '9');
        }
    };
}];
import templateStr from 'text!./templates/pm-edit-coverages.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            model: '=andPcPmEditCoverages',
            onCovChange: '=',
            onAdditionalCovChange: '=',
            loading: '=?'
        }
    };
}];

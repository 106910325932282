import SupportedLobModules from 'integration/fnol/SupportedLobModules';
import CompositeFlowConfig from 'integration/fnol/config/CompositeFlowConfig';

export default angular.module('edge.fnol.app', SupportedLobModules).config(['EventHubProvider', function (EventHubProvider) {
    var fnolMethods = ['createClaim', 'submitClaim'];
    EventHubProvider.registerTransformer({
        source: {
            category: 'tracking',
            eventName: 'serverCall'
        },
        target: {
            category: 'journey',
            eventName: 'fnol'
        },
        transformer: function transformer(payload, send) {
            if (payload && payload.success && payload.method && fnolMethods.includes(payload.method)) {
                var response = payload.response;
                var policy = response.policy;
                send({
                    method: payload.method,
                    path: payload.path,
                    accountNumber: policy.accountNumber,
                    policyNumber: policy.policyNumber,
                    policyType: policy.policyType,
                    claimNumber: response.claimNumber,
                    lossCause: response.lossCause,
                    lossDate: response.lossDate,
                    lossType: response.lossType
                });
            }
        }
    });
}]).factory('fnol.CompositeFlowDefn', CompositeFlowConfig);
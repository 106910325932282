import templateStr from 'text!./templates/add-new-vehicle.html';
import DateUtil from 'gw-portals-util-js/DateUtil';

export default ['LocaleService', (LocaleService) => {
    const OLDEST_CAR_YEARS = 50;

    return {
        restrict: 'A',
        scope: {
            newVehicle: '='
        },
        require: '^form',
        template: templateStr,
        controller: ['$scope', ($scope) => {
            let defaultCountryCode = $scope.newVehicle.country.value || LocaleService.getCountry().code;
            defaultCountryCode = defaultCountryCode || $scope.countries.value[0];

            $scope.years = [];
            const currentYear = DateUtil.currentDate().getUTCFullYear();
            for (let i = currentYear - OLDEST_CAR_YEARS; i <= currentYear; i++) {
                $scope.years.unshift(i);
            }


            function setDefaultCountry() {
                if ($scope.newVehicle.value) {
                    $scope.newVehicle.country.value = defaultCountryCode;
                } else {
                    $scope.newVehicle.value = {
                        country: defaultCountryCode
                    };
                }
            }

            $scope.$watch('newVehicle.value', setDefaultCountry);
        }],

        link: (scope, element, attrs, Ctrl) => {
            scope.form = Ctrl;
        }
    };
}];
import templateStr from 'text!./templates/vehicle-damage-picker.html';

export default ['ModalService', (ModalService) => {
    return {
        scope: {
            damagedPart: '='
        },
        restrict: 'A',
        template: templateStr,
        require: '^gwFnolPaVehicleCollision',
        link: (scope, element, attrs, collisionVehiclesCtrl) => {
            scope.damagedPartCode = null;

            scope.selectDamage = (groupId, partID) => {
                switch (partID) {
                    case 'windshield':
                    case 'rear_windshield':
                        ModalService.showError(
                            'fnol.pa.directives.vehicle-damage-picker.Cannot select the windscreen for Collision claim',
                            'fnol.pa.directives.vehicle-damage-picker.If you wish to select the windscreen for your claim, please select Glass as your claim type.'
                        );
                        return;
                    case 'roof':
                        ModalService.showError(
                            'fnol.pa.directives.vehicle-damage-picker.Cannot select the roof for a Collision claim',
                            'fnol.pa.directives.vehicle-damage-picker.If you wish to select the roof for your claim, please select Other as your claim type.'
                        );
                        return;
                    default:
                        collisionVehiclesCtrl.selectSinglePart(groupId);
                        scope.damagedPartCode = partID;
                }
            };
        }
    };
}];

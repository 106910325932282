import _ from 'underscore';
import PolicyCommonDetails from 'edge/policy/common/directives/PolicyCommonDetails';
import PolicyCommonCoverables from 'edge/policy/common/directives/PolicyCommonCoverables';
import PolicyCommonDocuments from 'edge/policy/common/directives/PolicyCommonDocuments';
import PolicyCommonContacts from 'edge/policy/common/directives/PolicyCommonContacts';
import policyRouteConfig from 'edge/policy/common/config/RouteConfig';
import PolicyService from 'edge/policy/common/services/PolicyService';
import PolicyAccountPoliciesSummary from 'edge/policy/common/directives/PolicyAccountPoliciesSummary';
import PolicyCoverages from 'integration/policy/directives/PolicyCoverages';
import 'portalConfig';
import 'angularRouter';
import 'edge/platform/widgets/documentupload/module';
import 'edge/platform/widgets/fileupload/module';

export default angular.module('edge.policy.common.app', ['portal.config', 'ui.router', 'edge.platform.widgets.documentupload', 'edge.platform.widgets.fileupload'])
    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = policyRouteConfig(RootStateConfig);
        _.values(states).forEach((state) => {
            $stateProvider.state(state);
        });
    }])
    .directive('gwPolicyAccountPoliciesSummary', PolicyAccountPoliciesSummary)
    .directive('gwPolicyCoverages', PolicyCoverages)
    .directive('gwPolicyCommonDetails', PolicyCommonDetails)
    .directive('gwPolicyCommonCoverables', PolicyCommonCoverables)
    .directive('gwPolicyCommonDocuments', PolicyCommonDocuments)
    .directive('gwPolicyCommonContacts', PolicyCommonContacts)
    .factory('policy.PolicyService', PolicyService);

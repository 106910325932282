import _ from 'lodash';
import flowConfigUtil from 'edge/quoteandbind/common/util/FlowConfigUtil';
import commonFlowActions from 'edge/quoteandbind/common/util/CommonFlowActions';


export default (AvailabilityService, RenewalService_AND, $q, TranslateService, ViewModelService, ViewModelContext, ActionMessageUtil) => {
    const translateFn = TranslateService.instant;
    const wrappedLoadSave = flowConfigUtil.wrappedLoadSave(ViewModelService, ViewModelContext);
    const { ACTIONS: COMMON_ACTIONS, ACTIONS_MSG: COMMON_ACTIONS_MSG} = commonFlowActions({
        RenewalService_AND, $q, ViewModelService, ViewModelContext
    });
    const ACTION_MSG = {
        cancelPrompt: {
            noSaveRequired: 'quoteandbind.common.config.FlowConfig.The information you have entered will not be saved',
            saveDraft: 'quoteandbind.common.config.FlowConfig.The information previously entered will be stored as a draft quote submission',
            saveOffering: 'quoteandbind.common.config.FlowConfig.The information previously entered will be stored as a quote submission'
        },
        checkAvailability: {
            error: {
                title: 'quoteandbind.common.config.FlowConfig.Unable to request a quote',
                message: 'quoteandbind.common.config.FlowConfig.Sorry we are unable to quote for this location and start date.'
            },
            progress: {
                title: 'quoteandbind.common.config.FlowConfig.Checking product availability',
                message: 'quoteandbind.common.config.FlowConfig.Checking if a quote can be provided for that location'
            }
        },
        checkPeriodStartDateChanged: {
            error: {
                title: 'quoteandbind.common.config.FlowConfig.Unable to request a quote',
                message: 'quoteandbind.common.config.FlowConfig.Sorry coverage is not available for the effective date specified.'
            },
            progress: {
                title: 'quoteandbind.common.config.FlowConfig.Checking product availability',
                message: 'quoteandbind.common.config.FlowConfig.Checking if coverage is available for that date.'
            }
        },
        saveSubmission: {
            error: {
                title: 'quoteandbind.common.config.FlowConfig.Unable to save submission',
                message: 'quoteandbind.common.config.FlowConfig.An error occurred while attempting to save quote submission.'
            },
            progress: {
                message: 'quoteandbind.common.config.FlowConfig.Saving quote...'
            }
        },
        saveAndQuoteSubmission: {
            error: {
                title: 'quoteandbind.common.config.FlowConfig.Unable to generate a quote',
                message: 'quoteandbind.common.config.FlowConfig.An error occurred while attempting to calculate a quote.'
            },
            progress: {
                message: 'quoteandbind.common.config.FlowConfig.Calculating quote...'
            }
        },
        bindSubmission: {
            error: {
                title: 'quoteandbind.common.config.FlowConfig.Unable to purchase quote',
                message: 'quoteandbind.common.config.FlowConfig.An error occurred while attempting to bind your quote submission'
            },
            progress: {
                message: 'quoteandbind.common.config.FlowConfig.Processing Payment...'
            }
        },
        retrievePaymentPlans: {
            error: {
                title: 'quoteandbind.common.config.FlowConfig.Unable to retrieve payment plans',
                message: 'quoteandbind.common.config.FlowConfig.An error occurred while attempting to retrieve payment plans.'
            },
            progress: {
                message: 'quoteandbind.common.config.FlowConfig.Retrieving payment plans...'
            }
        }
    };
    const ACTIONS = {
        checkAvailability: (model, availabilityRequest) => {
            // wrap the availablity check promise in a promise that returns the model with the policy address set
            return new $q((resolve, reject) => {
                const availabilityPromise = AvailabilityService.checkAvailabilityBasedOnPostalCode(availabilityRequest);
                availabilityPromise.then(
                    availabilityResp => {
                        if (!availabilityResp.isAvailable) {
                            reject();
                        }
                        // Set the policy address using the availability response if no address has been set yet
                        if (!angular.isDefined(model.baseData.policyAddress.value)) {
                            model.baseData.policyAddress.value = availabilityResp.addressCompletion;
                        }
                        resolve(model);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        cancel: (model) => {
            // if (!model.quoteID.value) {
            //     // no submission was created so just exit without saving
            //     return $q.resolve(model);
            // }
            //
            // const offeredQuotes = _.get(model, 'quoteData.offeredQuotes.value', []);
            // // backend expects that if any of the offered quotes is quoted, than the submission is quoted
            // const isSubmissionQuoted = offeredQuotes.some(quote => quote.status === 'Quoted');
            // if (isSubmissionQuoted) {
            //     return LoadSaveService.saveQuotedSubmission(model.value);
            // }
            //
            // // backend expects that if every offered quote is draft, than the submission is draft
            // const isSubmissionDraft = offeredQuotes.length > 0 && offeredQuotes.every(quote => quote.status === 'Draft');
            // if (isSubmissionDraft) {
            //     return LoadSaveService.updateDraftSubmission(model.value);
            // }
            //
            // return LoadSaveService.saveSubmission(model.value);
            // ANDIE Do not save on cancel, simply redirect to homepage
            const url = window.location.href;
            window.location.href = url.substring(0, url.indexOf('#'));
        },
        bindSubmission: ({
            value: submission
        }) => wrappedLoadSave(RenewalService_AND.bind, submission)
    };

    return {
        globalExits(step, junction) {
            junction('error');

            junction('returnHome');

            junction('quoteDeclined_AND'); // ANDIE
            junction('quoteDeclinedCue_AND'); // ANDIE
            junction('sessionTimedOut_AND'); // ANDIE
            junction('callServiceCenter'); // ANDIE
            junction('uwError_AND'); // ANDIE

            junction('cancel')
                .data({
                    confirmationMsg(model) {
                        // if (!model.quoteID.value) {
                        //     return ACTION_MSG.cancelPrompt.noSaveRequired;
                        // }
                        // if (!model.quoteData.value) {
                        //     return ACTION_MSG.cancelPrompt.saveDraft;
                        // }
                        // return ACTION_MSG.cancelPrompt.saveOffering;
                        return {
                            message: ' ' // ANDIE No message
                        };
                    }
                })
                .onNext.doAction(ACTIONS.cancel, ACTION_MSG.saveSubmission, {forceUpdate: true}).thenGoTo('returnHome', 'error');

        },
        availabilityCheck(step, junction) {
            junction('checkAvailability', true)
            // ANDIE Remove availability check, go straight to productAvailable
                // .onNext.doAction(ACTIONS.checkAvailability, ACTION_MSG.checkAvailability, {forceUpdate: true}).thenGoTo('productAvailable', 'error');
                .onNext.goTo('productAvailable');
            junction('productAvailable');

        },
        ensureSubmission: (step, junction) => {
            junction('submissionCreationCheck', true)
                .onNext.goTo('submissionCreationChecked');
            /* ANDIE Bypass this state entirely, starting flow without a persisted submission
                .onNext.branch(() => {
                if (appFeatures.useTempAccountForQNB) {
                    return 'createEmptySubmission';
                }
                return 'submissionCreationChecked';
            });*/

            junction('createEmptySubmission')
                .onNext.doAction(COMMON_ACTIONS.createEmptySubmission, COMMON_ACTIONS_MSG.createEmptySubmission).thenGoTo('submissionCreationChecked', 'error');

            junction('submissionCreationChecked');
        },
        needToSaveCheck(step, junction) {
            junction('checkNewSubmissionOrIfPeriodStartDateHasChanged', true)
                .onNext
                .branch((model, args) => {
                    if (!model.quoteID.value) {
                        return 'newSubmission';
                    }
                    if (args.periodStartDateChanged) {
                        return 'periodStartDateChanged';
                    }
                    return 'needToSaveCheckSuccess';
                });

            junction('newSubmission')
                .onNext.doAction(COMMON_ACTIONS.createSubmission, COMMON_ACTIONS_MSG.createSubmission).thenGoTo('needToSaveCheckSuccess', 'error');


            junction('periodStartDateChanged')
                .onNext.doAction(ACTIONS.checkAvailability, ACTION_MSG.checkPeriodStartDateChanged).thenGoTo('needToSaveCheckSuccess', 'error');

            junction('needToSaveCheckSuccess');
        },
        quote(step, junction) {
            // ANDIE The quote is generated in quote details step because we need control of which error step to go to
            // junction('generateQuote', true)
            //     .onNext.doAction(ACTIONS.saveAndQuoteSubmission, ActionMessageUtil.quoteFailMessage).thenGoTo('quoteDetails', 'error');

            step('quoteDetails', true) // ANDIE
                .onNext.goTo('offeringSelected', 'error')
                .skipOnResumeIf(model => model.bindData.policyNumber && model.bindData.policyNumber.value)
                .label(() => translateFn('quoteandbind.wizard.step.Quote'));

            junction('offeringSelected'); // Go through Legal and Payment pages
        },
        bindAndPay(step, junction) {
            junction('moreInfoComplete', true)
            // ANDIE Binding will take place automatically after the card is successfully processed
            // .onNext.doAction(ACTIONS.retrievePaymentPlans, ACTION_MSG.retrievePaymentPlans).thenGoTo('paymentDetails', 'error');

            // step('paymentDetails')
                .onNext.doAction(ACTIONS.bindSubmission, ACTION_MSG.bindSubmission).thenBranch('confirmation', (error) => {
                    error = error[0];
                    if (error && error.baseError && error.baseError.error && error.baseError.error.data) {
                        switch (error.baseError.error.data.appErrorCode) {
                            case 622:
                                return 'purchaseFailed_AND';
                            default: // Technical error
                                return 'error';
                        }
                    } else { // Technical error
                        return 'error';
                    } 
                });
            // .skipOnResumeIf(model => model.bindData.policyNumber && model.bindData.policyNumber.value)
            // .isValid(model => model.aspects.valid && model.aspects.subtreeValid)
            // .label(() => translateFn('quoteandbind.wizard.step.Payment Details'));

            junction('confirmation');
            junction('purchaseFailed_AND');
        }
    };
};
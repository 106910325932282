import templateStr from 'text!./templates/shopping-cart-icon.html';

export default ['$state', 'endorsement.NumberOfChanges', ($state, numberOfChanges) => {
    function link(scope) {
        scope.$state = $state;
        scope.visible = () => {
            return $state.includes('effective-date') || $state.includes('endorsement') || $state.includes('endorsementWithPolicy');
        };
        scope.changesNo = 0;
        numberOfChanges.subscribe((number) => {
            scope.changesNo = number;
        });
        // works only on mobile
        scope.triggerHistory = () => {
            scope.$root.$emit('endorsementShowHistory');
        };
    }

    return {
        scope: {},
        restrict: 'A',
        template: templateStr,
        link
    };
}];

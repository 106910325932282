import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, brandingData) => {
    const ctrl = Object.create(BaseCtrl);
    $scope.brand = brandingData;
    ctrl.init = () => {
       
    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', 'brandingData'];
export default fn;

import template from 'text!./templates/endorsement-lob-flow.html';

export default [() => ({
    template,
    'scope': {
        'wizard': '=',
        'flow': '=',
        'isStateVisible': '='
    },
    controller: ['$scope', 'endorsement.LobUtil', ($scope, LobUtil) => {
        $scope.isPA = (productCode) => LobUtil.isPA(productCode);
        $scope.isHomeowners = (productCode) => LobUtil.isHomeowners(productCode);
        $scope.isPm = (productCode) => LobUtil.isPm(productCode); // ANDIE
    }]
})];
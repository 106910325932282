export default ['JsonRPCService', (JsonRPCService) => {
    return {
        makeDirectPayment: (invoiceIds, amount, instrument) => {
            /** Trim a payment amount. */
            const roundedAmount = Math.round(amount * 100) / 100;

            const request = {
                invoiceIds,
                amountPaid: roundedAmount,
                paymentInstrument: instrument
            };

            const process = JsonRPCService.send('bc', 'billing/directBillPayment', 'makeDirectBillPayment', [request], []);
            return process.then(() => {
                return 'billing-make-payment-confirmation';
            });
        }
    };
}];

import _SegmentationUtil from './../_SegmentationUtil';
import ProductCodeUtil from '../../../../../../../app/js/portal/util/ProductCodeUtil'

export default [() => {
    return {
        restrict: 'EA',
        controller: ['$element','$q', '$rootScope', 'brandingData', function ($element, $q, $rootScope, brandingData) {
            if($rootScope.productCode == null) {
                var productCode = ProductCodeUtil.getProductCode(brandingData);
                $rootScope.productCode = productCode;
            }
            return _SegmentationUtil($element,$q.resolve($rootScope.productCode));
        }]
    };
}];
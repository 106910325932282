import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, $rootScope, PolicyService, $q, $stateParams, ViewModelService, $state, brandingData, StaticDocumentsService_AND) => {
    $scope.brand = brandingData;
    const ctrl = Object.create(BaseCtrl);
    $scope.hideEditMTAorRenewal = true

    ctrl.init = () => {
        if ($stateParams.policyID) {
            $rootScope.policyContext.selectPolicyByNumber($stateParams.policyID);
        }
        $scope.hideEditMTAorRenewal = $rootScope.policyContext.currentPolicy.canEditRenewalAndMta_AND
        
        const selectPolicy = (policyNumber) => {
            $scope.policyNumber = policyNumber;
            $q.when(PolicyService.getAccountPolicyDetails($scope.policyNumber), (policyData) => {
                $scope.policyDetails = ViewModelService.create(policyData, 'pc', 'edge.capabilities.policy.dto.PolicyDetailsDTO', null);
                $rootScope.policyContext.currentPolicy.periods[0].policyDetailsVM = $scope.policyDetails.currentPeriod;
                $scope.renewal = policyData.renewedPeriod;
                $scope.hideEditMTAorRenewal = $rootScope.policyContext.currentPolicy.canEditRenewalAndMta_AND
            });
        };

        $q.all([
            StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.IMPORTANT_INFORMATION),
        ]).then((result) => {
            $scope.importantInfoDoc = result[0];
        });
        
        $scope.$watch(() => $rootScope.policyContext.currentPolicyNumber, (newValue) => {
            if (newValue) {
                selectPolicy(newValue);
            }
        });

        $scope.accepted = () => {
           return ($scope.renewal.status === 'Scheduled');
        };
    };
    
    const notRejected = () => {
        if ($scope.renewal) {
            return !($scope.renewal.status === 'Not-Taken' || $scope.renewal.status === 'Not-taking');
        }
    };

    // These functions need to be declared here to avoid an infinite digest.
    const goToAmendRenewal = () => $state.go('quoteentry', {policyNumber: $rootScope.policyContext.currentPolicyNumber});
    const goToBuyRenewal = (isBase) => $state.go('quoteentry', {policyNumber: $rootScope.policyContext.currentPolicyNumber, isBuying: true, isBase});
    const goToBuyBaseRenewal = () => goToBuyRenewal(true);
    const goToBuyAlternateRenewal = () => goToBuyRenewal();

    ctrl.amendRenewal = (isBase) => {
        if (notRejected() && !$scope.accepted() && (isBase !== true || (isBase === true && !$scope.policyDetails.alternateRenewedPeriod_itb.value))) {
            return goToAmendRenewal;
        }
    };

    ctrl.buyRenewal = (isBase) => {
        if (notRejected() && !$scope.accepted()) {
            return isBase === true ? goToBuyBaseRenewal : goToBuyAlternateRenewal;
        }
    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$rootScope', 'policy.PolicyService', '$q', '$stateParams', 'ViewModelService', '$state', 'brandingData', 'StaticDocumentsService_AND'];
export default fn;

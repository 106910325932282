import templateStr from 'text!./templates/ho-location-coverages.html';

export default ['$filter', ($filter) => {
    return {
        template: templateStr,
        scope: {
            locations: '='
        },
        link: (scope) => {
            let selectedLocation;

            scope.$watch('locations', (locations) => {
                if (locations && locations.length > 0) {
                    selectedLocation = locations[0];
                }
            });

            scope.setSelectedLocation = (location) => {
                selectedLocation = location;
            };

            scope.selectedLocationClass = (item) => {
                return selectedLocation === item ? 'gw-light-blue' : null;
            };

            scope.selectedLocationCoverages = () => {
                if (selectedLocation && selectedLocation.coverages) {
                    return selectedLocation.coverages;
                }
            };

            scope.getSelectedLocationNumber = () => {
                if (selectedLocation && selectedLocation.locationNumber) {
                    return selectedLocation.locationNumber;
                }
            };

            /**
             * Takes in a address object and concatenates address information into one string
             *
             * @param {Object} item the address object
             * @returns {String} the string with full address information
             */
            scope.addressName = (item) => {
                let address = '';
                if (item.locationAddress.addressLine1) {
                    address += `${item.locationAddress.addressLine1}, `;
                }
                if (item.locationAddress.city) {
                    address += `${item.locationAddress.city}, `;
                }
                if (item.locationAddress.state) {
                    address += `${item.locationAddress.state} `;
                }
                if (item.locationAddress.postalCode) {
                    address += item.locationAddress.postalCode;
                }
                return address;
            };

            scope.hasListedItems = (item) => {
                return $filter('translate')(`claim.directives.HOLocationCoverages.${item.hasListedItems ? 'Yes' : 'No'}`);
            };
        }
    };
}];

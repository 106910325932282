import templateStr from 'text!./pm-driver-summary-card.html';

export default ['$filter', 'StateUtils', ($filter, StateUtils) => {
    return {
        restrict: 'A',
        scope: {
            driverVM: '<driverVm',
            setMainDriver: '<?',
            removeDriver: '<'
        },
        template: templateStr,
        link: (scope) => {
            scope.driver = scope.driverVM.value;
            scope.relationshipPHDisplayName = scope.driver.isPolicyHolder ?
                $filter('translate')('and.quoteandbind.pm.directives.templates.pm-driver-summary-card.Policyholder') :
                scope.driverVM.phRelationship_itb.value.name;
            scope.driverDisplayName = `${scope.driverVM.title_itb.value.name} ${scope.driver.firstName} ${scope.driver.lastName}`;

            scope.editDetails = () => {
                const step = scope.driver.isPolicyHolder ? 'pmEditContactDetails' : 'pmEditDriverDetails';
                const data = StateUtils.activeDataForState(true);
                const flowModel = data.flowModel;
                flowModel.snapshot = data.model;
                flowModel.jumpToStep(step, data.model, {editNamedDriver: scope.driver.publicID || scope.driver.tempID});
            };
        }
    };
}];

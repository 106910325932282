import _ from 'lodash';
import Submission from 'edge/quoteandbind/common/models/Submission';

export default ['$q',
    'JsonRPCService',
    'qnb.ErrorHandlingService',
    'qnb.endpointConfig',
    'qnb.xCenterRouteConfig',
    'brandingData',
    'SessionService_AND', // ANDIE
    '$rootScope', // ANDIE
    ($q, JsonRPCService, ErrorHandlingService, endpointConfig, xCenterRouteConfig, brandingData, SessionService_AND, $rootScope) => { // ANDIE

        const xCenterRoute = xCenterRouteConfig;

        /**
         * @param {Object} params
         * @param {Object} params.submissionDTO
         * @param {String} params.processName
         * @param {Boolean} params.handleErrorBeforeRequest
         * @param {Boolean} params.setDTOBeforeHandling
         * @returns {Promise}
         */
        function processSubmission(params) {
            let submissionDTO = params.submissionDTO;
            const handleErrorBeforeRequest = params.handleErrorBeforeRequest;
            const setDTOBeforeHandling = params.setDTOBeforeHandling;

            const deferred = $q.defer();
            if (handleErrorBeforeRequest) {
                ErrorHandlingService.checkSessionUUID(submissionDTO, deferred);
            }

            const endpoint = endpointConfig.loadSaveEndpoint;
            JsonRPCService.send(xCenterRoute, endpoint, params.processName, [submissionDTO]).then(
                (response) => {
                    if (!handleErrorBeforeRequest) {
                        if (setDTOBeforeHandling) {
                            submissionDTO = new Submission(response);
                        }
                        ErrorHandlingService.checkSessionUUID(submissionDTO, deferred);
                        if (!setDTOBeforeHandling) {
                            submissionDTO = new Submission(response);
                        }
                    } else {
                        submissionDTO = new Submission(response);
                    }

                    if (params.startFrontendSession_AND === true) { // ANDIE Start timeout for frontend session
                        SessionService_AND.init(response.sessionLengthInMin_itb);
                    }

                    return deferred.resolve(submissionDTO);
                },
                handleError(deferred)
            );
            return deferred.promise;
        }

        function setQuoteToDraft(quoteID, sessionUUID) {
            const deferred = $q.defer();
            const endpoint = endpointConfig.loadSaveEndpoint;
            JsonRPCService.send(xCenterRoute, endpoint, 'setQuoteToDraft', [quoteID, sessionUUID]).then(
                (response) => {
                    ErrorHandlingService.checkSessionUUID({
                        sessionUUID
                    }, deferred);
                    const submissionDTO = new Submission(response);
                    return deferred.resolve(submissionDTO);
                },
                handleError(deferred)
            );
            return deferred.promise;
        }

        function handleError(deferred) {
            return (error, status, headers) => {
                return deferred.reject([error, status, headers]);
            };
        }

        function isSubmissionQuoted(submissionDTO) {
            return submissionDTO.quoteData && _.some(submissionDTO.quoteData.offeredQuotes, quote => quote.status === 'Quoted');
        }

        /**
         * Set the submission to draft if in quoted state,
         * it returns the submission otherwise
         * @param {Object} submissionDTO the submission that needs to be draft
         * @returns {Promise} a promise to set the quote to draft
         */
        function ensureIsSetToDraft(submissionDTO) {
            // ANDIE Setting a submission to draft in its own transaction was breaking rollback. Also don't see why
            // we'd need two calls just to save, so removing it. It'll be handled in PC.
            // if (isSubmissionQuoted(submissionDTO)) {
            //     return setQuoteToDraft(submissionDTO.quoteID, submissionDTO.sessionUUID);
            // }
            return $q.resolve(submissionDTO);
        }

        function updateDraftSubmission(submissionDTO) {
            return processSubmission({
                submissionDTO: submissionDTO,
                processName: 'updateDraftSubmission',
                handleErrorBeforeRequest: false,
                setDTOBeforeHandling: false
            });
        }

        return {
            createSubmission: (submissionDTO) => {
                submissionDTO.baseData.accountHolder.brand_itb = brandingData; // ANDIE Add brand when creating a new submission
                return processSubmission({
                    submissionDTO,
                    processName: 'create',
                    handleErrorBeforeRequest: false,
                    setDTOBeforeHandling: true,
                    startFrontendSession_AND: true // ANDIE
                });
            },
            saveAndQuoteSubmission: (submissionDTO) => {
                return processSubmission({
                    submissionDTO,
                    processName: 'saveAndQuote',
                    handleErrorBeforeRequest: false,
                    setDTOBeforeHandling: false
                });
            },
            updateDraftSubmission: updateDraftSubmission,
            saveSubmission: (submissionDTO) => {
                return ensureIsSetToDraft(submissionDTO)
                    .then(updateDraftSubmission);
            },
            updateDraftSubmissionAndPrimaryLocation: (submissionDTO) => {
                const submissionParameters = {
                    submissionDTO: submissionDTO,
                    processName: 'updateDraftSubmissionAndPrimaryLocation',
                    handleErrorBeforeRequest: false,
                    setDTOBeforeHandling: false
                };

                if (submissionDTO.quoteData && _.some(submissionDTO.quoteData.offeredQuotes, quote => quote.status === 'Quoted')) {
                    const deferred = $q.defer();
                    setQuoteToDraft(submissionDTO.quoteID, submissionDTO.sessionUUID).then(() => {
                        processSubmission(submissionParameters).then(updateResponse => {
                            deferred.resolve(updateResponse);
                        }, handleError(deferred));
                    }, handleError(deferred));
                    return deferred.promise;
                }
                return processSubmission(submissionParameters);
            },

            retrievePaymentPlans: (submissionDTO) => {
                const endpoint = endpointConfig.loadSaveEndpoint;
                return JsonRPCService.send(xCenterRoute, endpoint, 'retrievePaymentPlans', [submissionDTO.quoteID, submissionDTO.sessionUUID]);
            },
            quoteSubmission: (quoteID, sessionUUID) => {
                let submissionDTO;
                const deferred = $q.defer();
                const endpoint = endpointConfig.loadSaveEndpoint;
                JsonRPCService.send(xCenterRoute, endpoint, 'quote', [quoteID, sessionUUID]).then(
                    (response) => {
                        ErrorHandlingService.checkSessionUUID({
                            sessionUUID
                        }, deferred);
                        submissionDTO = new Submission(response);
                        return deferred.resolve(submissionDTO);
                    },
                    (error, status, headers) => {
                        return deferred.reject([error, status, headers]);
                    }
                );
                return deferred.promise;
            },
            setQuoteToDraft: setQuoteToDraft,
            updateSubmissionCoverages: (quoteID, lobCoverages, sessionUUID) => {
                const endpoint = endpointConfig.loadSaveEndpoint;
                return JsonRPCService.send(xCenterRoute, endpoint, 'updateCoverages', [quoteID, sessionUUID, lobCoverages]);
            },
            saveQuotedSubmission: (submissionDTO) => {
                return processSubmission({
                    submissionDTO,
                    processName: 'updateQuotedSubmission',
                    handleErrorBeforeRequest: true,
                    setDTOBeforeHandling: false
                });
            },
            preBindSubmission: (submissionDTO) => { // ANDIE
                return processSubmission({
                    submissionDTO,
                    processName: 'preBind',
                    handleErrorBeforeRequest: false,
                    setDTOBeforeHandling: false
                });
            },
            bindSubmission: (submissionDTO) => {
                submissionDTO.baseData.channel_itb = $rootScope.fromAMP_AND ? 'CP' : 'QB';
                return processSubmission({
                    submissionDTO,
                    processName: 'bind',
                    handleErrorBeforeRequest: false,
                    setDTOBeforeHandling: false
                });
            },
            retrieveSubmission: (submissionDTO) => {
                submissionDTO.brand_itb = brandingData.toUpperCase(); // ANDIE Add brand when retrieving a submission
                return processSubmission({
                    submissionDTO,
                    processName: 'retrieve',
                    handleErrorBeforeRequest: false,
                    setDTOBeforeHandling: false,
                    startFrontendSession_AND: true // ANDIE
                });
            },
            retrieveAggsSubmission_AND: (aggsRetrievalDTO) => {
                aggsRetrievalDTO.brand_itb = brandingData.toUpperCase(); // ANDIE Add brand
                return processSubmission({
                    aggsRetrievalDTO,
                    processName: 'retrieveAggs',
                    handleErrorBeforeRequest: false,
                    setDTOBeforeHandling: false,
                    startFrontendSession_AND: true // ANDIE
                });
            },
            createEmptySubmission: (submission) => {
                const deferred = $q.defer();
                JsonRPCService.send(xCenterRoute, endpointConfig.loadSaveEndpoint, 'create', [submission])
                    .then((response) => {
                        const submissionResponseDTO = response;
                        return deferred.resolve(submissionResponseDTO);
                    }, (error, status, headers) => {
                        return deferred.reject([error, status, headers]);
                    });
                return deferred.promise;
            },
            updateQuotedSubmissionAndValidateDDI_AND: (submissionDTO) => {
                return processSubmission({
                    submissionDTO,
                    processName: 'updateQuotedSubmissionAndValidateDDI',
                    handleErrorBeforeRequest: true,
                    setDTOBeforeHandling: false
                });
            }
        };
    }
];

module.exports = {
	"endpoints": {
		"hostRunTime": "{{host-run-time || ''}}"
	},
	"httpSettings": {
		"timeout": 15000,
		"reloadQueryParamName": "goto",
		"reloadURL": "/index.html",
		"serviceBaseURLs": {
			"bc": "{{endpoints.hostRunTime}}/amp/bc/",
			"cc": "{{endpoints.hostRunTime}}/amp/cc/",
			"pc": "{{endpoints.hostRunTime}}/amp/pc/",
			"cm": "{{endpoints.hostRunTime}}/amp/ab/",
			"vinlookup": "{{endpoints.hostRunTime}}/amp/quote/vehicleinfo/",
			"availability": "{{endpoints.hostRunTime}}/pc/service/edge/policychange/",
			"auth": "{{endpoints.hostRunTime}}/quoteandbuy/auth/",
			"journey": "{{endpoints.hostRunTime}}/quoteandbuy/journey/",
			"quote": "{{endpoints.hostRunTime}}/amp/quote/",
			"addressLookup": "{{endpoints.hostRunTime}}/amp/quote/address/"
		},
		"auth": {
			"kind": "basic",
			"username": "upstream",
			"password": "password"
		}
	},
	"userSettings": {
		"sessionConfig": {
			"inactivityIntervalMins": 30,
			"logoutConfirmationIntervalMins": 2
		},
		"defaultLocaleDetails": {
			"countryCode": "GB",
			"mapCenter": {
				"lat": 37.923933,
				"lon": -122.5203
			}
		}
	},
	"appSettings": {
		"fnolConfig": {
			"firstState": "policySelect",
			"multiplePolicies": true
		},
		"statePathConfig": {
			"billing": "enrolled",
			"policy": "policyContextRoot_AND",
			"usersprofile": "enrolled",
			"fnol": "enrolled",
			"claims": "policyContextRoot_AND",
			"endorsement": "enrolled",
			"home": "home",
			"faq": "enrolled",
			"enrollment": "root",
			"bonusMiles_AND": "policyContextRoot_AND",
			"yourInsurance_AND": "policyContextRoot_AND",
			"topUpMiles_AND": "policyContextRoot_AND",
			"contactUs_AND": "policyContextRoot_AND",
			"userAccount_AND": "policyContextRoot_AND",
			"rewardsShop_AND": "policyContextRoot_AND",
			"yourRenewal_AND": "policyContextRoot_AND",
			"quote_bind": "quoteandbindRoot_AND"
		},
		"AgeAllowedToDrive": 16,
		"MaximumAge": 100,
		"maxFileUploadSize": 50000000,
		"checkForEnrollment": {
			"showPolicyLink": "FILE_NOT_FOUND"
		},
		"supportPhone": "800-555-1212",
		"multiaccount": true
	},
	"auth": {
		"auth0": {
			"clientId": "",
			"url": "",
			"requiresRedirectUrl": true,
			"redirectPath": "",
			"audience": "EdgeBackend",
			"endpoints": {
				"authorize": "/authorize",
				"userinfo": "/userinfo",
				"logout": "/v2/logout"
			},
			"scope": "openid profile",
			"logoutMode": "no-cors",
			"validate": "state",
			"silentLoginParam": "prompt=none",
			"usernameProperty": "name"
		},
		"uaa": {
			"url": "",
			"endpoints": {
				"authorize": "/oauth/authorize",
				"userinfo": "/userinfo",
				"logout": "/logout.do"
			},
			"logoutMode": "cors",
			"validate": "nonce",
			"usernameProperty": "user_name"
		}
	}
}
import PmContactDetailsView from 'text!../views/pm-contact-details.html';
import PmDriverDetailsView from 'text!../views/pm-driver-details.html';
import PmDriverSummaryView from 'text!../views/pm-driver-summary.html';
import PmVehicleDetailsView from 'text!../views/pm-vehicle-details.html';
import emailQuoteView from 'text!../views/pm-email-quote.html';
import legalPaymentStep1View from 'text!../views/legal-payment-step1.html';
import legalPaymentStep2View from 'text!../views/legal-payment-step2.html';
import legalPaymentStep3View from 'text!../views/legal-payment-step3.html';
import legalPaymentStep4View from 'text!../views/legal-payment-step4.html';
import CardSelectionView from 'text!../views/card-selection.html';
import CardBillingDetailsView from 'text!../views/card-billing-details.html';
import PmContactDetailsCtrl from '../controllers/PmContactDetailsCtrl';
import PmDriverDetailsCtrl from '../controllers/PmDriverDetailsCtrl';
import PmDriverSummaryCtrl from '../controllers/PmDriverSummaryCtrl';
import PmVehicleDetailsCtrl from '../controllers/PmVehicleDetailsCtrl';
import emailQuoteCtrl from '../controllers/PmEmailQuote';
import legalPaymentStep1Ctrl from '../controllers/LegalPaymentStep1Ctrl';
import legalPaymentStep2Ctrl from '../controllers/LegalPaymentStep2Ctrl';
import legalPaymentStep3Ctrl from '../controllers/LegalPaymentStep3Ctrl';
import legalPaymentStep4Ctrl from '../controllers/LegalPaymentStep4Ctrl';
import CardSelectionCtrl from '../controllers/CardSelectionCtrl';
import CardBillingDetailsCtrl from '../controllers/CardBillingDetailsCtrl';

export default () => {
    const wizardParentState = 'baseTransactionWizard';

    const pmContactDetails = {
        parent: wizardParentState,
        url: '/about-you',
        template: PmContactDetailsView,
        controller: PmContactDetailsCtrl,
        controllerAs: '$ctrl'
    };

    const pmDriverDetails = {
        parent: wizardParentState,
        url: '/driver-details',
        controller: PmDriverDetailsCtrl,
        template: PmDriverDetailsView,
        params: {
            addNamedDriver: null, // Boolean, if adding a new named driver
            editNamedDriver: null // Driver ID, if editing an existing named driver
        }
    };

    return {
        pmContactDetails,
        pmDriverDetails,
        pmDriverSummary: {
            parent: wizardParentState,
            url: '/driver-summary',
            controller: PmDriverSummaryCtrl,
            template: PmDriverSummaryView
        },
        pmVehicleDetails: {
            parent: wizardParentState,
            url: '/vehicle-details',
            controller: PmVehicleDetailsCtrl,
            template: PmVehicleDetailsView,
            params: {
                changeConfirmationCallback: null
            }
        },
        pmEditContactDetails: Object.assign({}, pmContactDetails, {url: '/edit-contact-details'}),
        pmEditDriverDetails: Object.assign({}, pmDriverDetails, {url: '/edit-driver-details'}),
        quoteEmail: {
            name: 'quoteEmail',
            url: '/quote-email',
            parent: wizardParentState,
            controller: emailQuoteCtrl,
            template: emailQuoteView,
            params: {
                submission: null
            },
            controllerAs: '$ctrl'
        },
        legalPaymentStep1: {
            name: 'legalPaymentStep1',
            url: '/legal-and-info-1',
            parent: wizardParentState,
            controller: legalPaymentStep1Ctrl,
            template: legalPaymentStep1View,
            controllerAs: '$ctrl'
        },
        legalPaymentStep2: {
            name: 'legalPaymentStep2',
            url: '/legal-and-info-2',
            parent: wizardParentState,
            controller: legalPaymentStep2Ctrl,
            template: legalPaymentStep2View,
            controllerAs: '$ctrl'
        },
        legalPaymentStep3: {
            name: 'legalPaymentStep3',
            url: '/direct-debit-mandate',
            parent: wizardParentState,
            controller: legalPaymentStep3Ctrl,
            template: legalPaymentStep3View,
            controllerAs: '$ctrl'
        },
        legalPaymentStep4: {
            name: 'legalPaymentStep4',
            url: '/payment-rwl',
            parent: wizardParentState,
            controller: legalPaymentStep4Ctrl,
            template: legalPaymentStep4View,
            controllerAs: '$ctrl'
        },
        cardSelection: {
            name: 'cardSelection',
            url: '/card-selection',
            parent: wizardParentState,
            controller: CardSelectionCtrl,
            template: CardSelectionView,
            controllerAs: '$ctrl'
        },
        cardBillingDetails: {
            name: 'cardBillingDetails',
            url: '/card-billing-details',
            parent: wizardParentState,
            controller: CardBillingDetailsCtrl,
            template: CardBillingDetailsView,
            controllerAs: '$ctrl'
        }
    };
};

import BonusMilesTemplate from 'text!edge/policy/bonus-miles_AND/views/bonus-miles.html';

export default (RootStates) => {
    const parentState = RootStates.bonusMiles_AND;

    return {
        bonusMiles_AND: {
            name: 'bonusMiles_AND',
            parent: parentState,
            url: '/bonus-miles',
            template: BonusMilesTemplate,
            data: {
                restrictedToEditablePolicies: true
            }
        }
    };
};

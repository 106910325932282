import template from 'text!./accordion-content-section.html';

export default [() => ({
    restrict: 'A',
    template: template,
    transclude: true,
    scope: {
        accordionTitle: '<',
        accordionSubTitle: '<',
        accordionDescription: '<',
        accordionID: '@accordionId',
        styleTitle: '@',
        titleImgPath: '@',
        titleImgAlt: '@',
        titleIcon: '@',
        toggleAccordion: '&',
        accordionGroup: '<' // Root control for group this accordion belongs to
    },
    controller: ['$rootScope', '$scope', ($rootScope, $scope) => {
        $scope.control = {open: false};
        if ($scope.accordionID) {
            if ($scope.accordionGroup) {
                $scope.accordionGroup[$scope.accordionID] = $scope.control;
            } else {
                $rootScope.policyContext.frameAccordions[$scope.accordionID] = $scope.control;
            }
        }
    }]
})];
import templateStr from 'text!./templates/billing-summary-actions.html';

export default () => {
    return {
        template: templateStr,
        transclude: true,
        scope: {
            paymentSourceAction: '=',
            paymentAction: '=',
            automaticAction: '=',
            viewAction: '='
        }
    };
};
import template from 'text!./templates/endorsement-policy-icon.html';
import LobUtil from 'integration/endorsement/LobUtil';

export default [() => ({
    'scope': {
        'productCode': '='
    },
    template,
    'controller': ['$scope', 'endorsement.LobUtil', ($scope) => {
        $scope.isHomeowners = LobUtil.isHomeowners($scope.productCode);
        $scope.isPA = LobUtil.isPA($scope.productCode);
    }]
})
];
import TelematicsService from './services/TelematicsService';
import ChartService from './services/ChartService';
import AvailableMiles from './directives/available-miles/AvailableMiles';
import ConsumedMiles from './directives/consumed-miles/ConsumedMiles';
import MonthlySummaries from './directives/monthly-summaries/MonthlySummaries';
import MonthlyTrips from './directives/monthly-trips/MonthlyTrips';
import Scores from './directives/scores/Scores';
import Statistics from './directives/statistics/Statistics';
import BonusMiles from './directives/bonus-miles/BonusMiles';
import DrivingData from './directives/driving-data/DrivingData';
import BonusMilesPrint from './directives/bonus-miles-print/BonusMilesPrint';
import BonusMilesTable from './directives/bonus-miles-table/BonusMilesTable';
import BonusMilesSingle from './directives/bonus-miles-single/BonusMilesSingle';

export default angular.module('edge.and.telematics', [])
    .factory('TelematicsService_AND', TelematicsService)
    .factory('ChartService_AND', ChartService)
    .directive('andTelematicsAvailableMiles', AvailableMiles)
    .directive('andTelematicsConsumedMiles', ConsumedMiles)
    .directive('andTelematicsMonthlySummaries', MonthlySummaries)
    .directive('andTelematicsMonthlyTrips', MonthlyTrips)
    .directive('andDrivingData', DrivingData)
    .directive('andTelematicsScores', Scores)
    .directive('andTelematicsStatistics', Statistics)
    .directive('andTelematicsBonusMilesPrint', BonusMilesPrint)
    .directive('andTelematicsBonusMilesTable', BonusMilesTable)
    .directive('andTelematicsBonusMilesSingle', BonusMilesSingle)
    .directive('andBonusMiles', BonusMiles);
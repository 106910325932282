export default ['$q', 'JsonRPCService', 'policyjob.endpointConfig', 'policyjob.xCenterRouteConfig', ($q, JsonRPCService, endpointConfig, xCenterRouteConfig) => {

    const xCenterRoute = xCenterRouteConfig;
    const endpoint = endpointConfig.vinlookup;

    return {
        autofillBasedOnVIN: (vin) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'lookupBasedOnVIN', [vin]).then((vehicleInfo) => deferred.resolve(vehicleInfo), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        autofillBasedOnRegistrationNumber: (reg) => {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'lookupVehicleByRegNumber', [reg]).then((vehicleInfo) => deferred.resolve(vehicleInfo), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        autofillBasedOnPartialDto(dto) {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'lookupBasedOnPartialDTO', [dto]).then(vehicleInfo => deferred.resolve(vehicleInfo), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        lookupMakes() {
            const deferred = $q.defer();
            JsonRPCService.send(xCenterRoute, endpoint, 'lookupMakes', []).then((vehicleInfo) => deferred.resolve(vehicleInfo), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        }
    };
}];

import template from 'text!./expiration-approaching-banner.html';

const RENEWAL_INVITE_BANNER_TIME = 30;
const RENEWAL_INVITE_LEAD_TIME = 22;

export default [() => ({

    restrict: 'A',
    template: template,
    scope: {},
    controller: ['$rootScope', '$scope', ($rootScope, $scope) => {

        $scope.displayExpirationBanner = () => {

            const daysToRenewal = $rootScope.policyContext.getNumberOfDaysToExpiry();
            if ($rootScope.policyContext.isNotRenewing()) {
                return false;
            }
            return daysToRenewal > RENEWAL_INVITE_LEAD_TIME && daysToRenewal <= RENEWAL_INVITE_BANNER_TIME;
        };
    }]
})];


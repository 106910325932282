import RepairFacilitiesMap from './RepairFacilitiesMap';
import _ from 'lodash';

class RepairFacilitiesMapCtrl {
    constructor($scope, $q, MediaQueriesUtil, TemplateUtil, repairMapInitialZoom, repairMapSearchRadius, repairMapDelay) {
        this.$scope = $scope;
        this.TemplateUtil = TemplateUtil;
        this.validateProps();
        const deferredMap = $q.defer();
        this.mapLoaded = deferredMap.promise;

        /*
         * Initialization of map must be started after mounting DOM node.
         * By deffering action using setTimeout we are sure that DOM node is mounted.
         */
        setTimeout(() => {
            this.initMap()
                .then(res => deferredMap.resolve(res))
                .catch(err => {
                    console.warn(err);
                });
        }, 0);

        /*
         * For mobile view we don't need this listeners when select vendor
         * from list and see map with only selected vendor
         */
        if (!this.selectVendor) {
            this.mapLoaded.then(() => this.initListeners());
        }

        this.latestVendorSearch = {
            center: this.mapCenter,
            radius: null
        };
        this.phoneLandscapeUpQuery = MediaQueriesUtil.getPhoneLandscapeUpQuery();
        this.initialZoom = repairMapInitialZoom;
        this.searchRadius = repairMapSearchRadius;
        this.delay = repairMapDelay;
    }

    validateProps() {
        if (!this.mapName) {
            console.warn('You don\'t provide unique `mapName` property of map component. ' +
                'It may cause problems if you have more than one map component on page.');
        }
    }

    initMap() {
        if (window.google && window.google.maps) {
            this.map = new RepairFacilitiesMap({
                mapsApi: window.google.maps
            });
            return this.map.init(
                document.getElementById(this.mapName || 'googleMapsComponent'),
                this.mapCenter,
                this.initialZoom
            );
        }

        return Promise.reject('Google Maps API not loaded.');
    }

    $onInit() {
        this.$scope.$on('mapZoomReset', () => this.map.setZoomLevel(this.initialZoom));
    }

    reloadVendorsAroundWaypoint(coords) {
        this.latestVendorSearch.center = this.latestVendorSearch.center ? this.latestVendorSearch.center : this.mapCenter;

        let radius = this.map.getViewportSearchRadius();
        radius = radius >= this.latestVendorSearch.radius ? radius : this.latestVendorSearch.radius;
        const changeDistance = this.map.getMilesDistance(this.latestVendorSearch.center, coords);
        const minTriggerDistance = this.farthestRepairFacilityDistance
            ? this.farthestRepairFacilityDistance * 0.75
            : radius / 2.0;

        if (changeDistance > minTriggerDistance) {
            this.onRepairFacilitiesRefresh({coords, radius});
            this.latestVendorSearch = {center: coords, radius};
        }
    }

    reloadVendorsInVisibleArea(coords) {
        this.latestVendorSearch.center = coords;
        let radius = this.map.getViewportSearchRadius();
        // We don't want to load vendors from an area bigger than the one with diameter equal to 70 miles
        // (that particular value corresponds to a square area with an edge of ~80 km)
        if (radius > this.searchRadius && this.latestVendorSearch.radius >= this.searchRadius) {
            return;
        } else if (radius > this.searchRadius && this.latestVendorSearch.radius < this.searchRadius) {
            radius = this.searchRadius;
        }
        this.onRepairFacilitiesRefresh({coords, radius});
        this.latestVendorSearch = {center: coords, radius};
    }

    initListeners() {
        const reloadDebounceOptions = {
            leading: false,
            trailing: true
        };

        const onPositionChange = () => this.reloadVendorsAroundWaypoint(this.map.getCenter());
        this.map.addPositionChangeListener(_.debounce(onPositionChange, this.delay * 2, reloadDebounceOptions));

        const onZoomOutChange = () => this.reloadVendorsInVisibleArea(this.map.getCenter());
        const onZoomInChange = () => this.reloadVendorsAroundWaypoint(this.map.getCenter());
        this.map.addZoomListener(
            _.debounce(onZoomOutChange, this.delay, reloadDebounceOptions),
            _.debounce(onZoomInChange, this.delay, reloadDebounceOptions)
        );

        const onMapCenterRefresh = () => this.onSearchWaypointChange({coords: this.map.getCenter()});
        this.map.addPositionChangeListener(onMapCenterRefresh);

        const onMapFacilitySelect = (facility, closeOnSecondClick) => {
            this.onMapFacilitySelect({facility});
            this.displayInfoWindow(facility, closeOnSecondClick);
        };
        this.map.addMapFacilitySelectListener((facility, closeOnSecondClick) => onMapFacilitySelect(facility, closeOnSecondClick));
    }

    displayInfoWindow(facility, closeOnSecondClick) {
        if (this.phoneLandscapeUpQuery.matches) { // trigger desktop version popup
            this.map.displayInfoWindow(facility, undefined, this.TemplateUtil.getNodeFromTemplate, closeOnSecondClick);
        } else { // trigger mobile map centering
            setTimeout(() => {
                this.map.mapsApi.event.trigger(this.map.map, 'resize');
                this.map.setCenter(facility.primaryAddress.spatialPoint);
            }, 0);
        }
    }

    changeMapCenter(cords) {
        this.map.setCenter(cords);
        this.reloadVendorsAroundWaypoint(cords);
    }

    $onChanges({
        repairFacilities, mapCenter, selectedRepairFacility, policyLocation, lossLocation, myLocation
    }) {
        /*
         * Some state values may change before map would be ready.
         * This protect us against doing actions on uninitialized map.
         */
        this.mapLoaded.then(() => {
            if (this.selectVendor) { // select vendor from list - logic for mobile view
                this.selectVendor.then((facility) => {
                    this.map.setSelectedFacilityMarker(facility);
                });
                this.updateMapForOneVendor(mapCenter, policyLocation, lossLocation, myLocation);
            } else {
                this.updateMapForAllVendors(repairFacilities, mapCenter, selectedRepairFacility, policyLocation, lossLocation, myLocation);
            }
        });
    }

    updateMapForAllVendors(repairFacilities, mapCenter, selectedRepairFacility, policyLocation, lossLocation, myLocation) {
        if (selectedRepairFacility && selectedRepairFacility.currentValue &&
            selectedRepairFacility.currentValue !== selectedRepairFacility.previousValue) {
            this.reloadVendorsAroundWaypoint(selectedRepairFacility.currentValue.primaryAddress.spatialPoint);
            this.displayInfoWindow(selectedRepairFacility.currentValue);
        }

        if (repairFacilities && repairFacilities.currentValue &&
            repairFacilities.currentValue !== repairFacilities.previousValue) {

            this.map.removeRepairFacilityMarkers();
            this.map.setRepairFacilityMarkers(repairFacilities.currentValue);

            if (this.selectedRepairFacility && this.map.hasVisiblePoint(this.selectedRepairFacility.primaryAddress.spatialPoint)) {
                this.displayInfoWindow(this.selectedRepairFacility);
            }
        }

        if (mapCenter && mapCenter.currentValue
            && mapCenter.currentValue !== mapCenter.previousValue) {
            const center = mapCenter.currentValue;
            this.map.setCenter(center);
            this.reloadVendorsAroundWaypoint(center);
        }

        if (policyLocation && policyLocation.currentValue &&
            policyLocation.currentValue !== policyLocation.previousValue) {
            this.map.setPolicyMarker(policyLocation.currentValue);
        }

        if (lossLocation && lossLocation.currentValue &&
            lossLocation.currentValue !== lossLocation.previousValue) {
            this.map.setLossMarker(lossLocation.currentValue);
        }

        if (myLocation && myLocation.currentValue &&
            myLocation.currentValue !== myLocation.previousValue) {
            this.map.setMyLocationMarker(myLocation.currentValue);
        }
    }

    updateMapForOneVendor(mapCenter, policyLocation, lossLocation, myLocation) {
        if (mapCenter && mapCenter.currentValue
            && mapCenter.currentValue !== mapCenter.previousValue) {
            this.map.setCenter(mapCenter.currentValue);
        }

        if (policyLocation && policyLocation.currentValue &&
            policyLocation.currentValue !== policyLocation.previousValue) {
            this.map.setPolicyMarker(policyLocation.currentValue);
        }

        if (lossLocation && lossLocation.currentValue &&
            lossLocation.currentValue !== lossLocation.previousValue) {
            this.map.setLossMarker(lossLocation.currentValue);
        }

        if (myLocation && myLocation.currentValue &&
            myLocation.currentValue !== myLocation.previousValue) {
            this.map.setMyLocationMarker(myLocation.currentValue);
        }
    }

    $onDestroy() {
        this.mapLoaded.then(() => {
            this.map.destroyAllListeners();
        });
    }
}

RepairFacilitiesMapCtrl.$inject = ['$scope', '$q', 'MediaQueriesUtil', 'TemplateUtil', 'repairMapInitialZoom',
    'repairMapSearchRadius', 'repairMapDelay'];
export default RepairFacilitiesMapCtrl;
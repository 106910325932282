import templateStr from 'text!./templates/policy-common-coverables.html';

export default [() => {
    return {
        restrict: 'E',
        template: templateStr,
        scope: {
            'policy': '='
        }
    };
}];

export default ($q, EndorsementService, RenewalService_AND) => {

    const ACTIONS = {
        load: model => $q.resolve(model),
        cancel: (model, args) => {
            if (model.value.baseData.jobType === 'PolicyChange') {
                const notQuoted = model.status.value.code !== 'Quoted';
                if (notQuoted) {
                    return EndorsementService.saveEndorsement(model.value);
                }
            } else if (model.value.baseData.jobType === 'Renewal') {
                const isQuoted = model.value.baseData.periodStatus === 'Quoted';
                if (isQuoted && args.modelEdited) {
                    return RenewalService_AND.updatePaymentPlan(args.modelEdited).then(() => model); // args is receiving the model with the current edited state
                } else if (!isQuoted) {
                    return RenewalService_AND.saveRenewal(model.value);
                }
            }
            return $q.resolve(model.value);
        }
    };

    const ACTION_MSG = {
        load: {
            error: {
                title: 'gw-portals-policytransaction-common-angular.config.flowConfig.Unable to load',
                message: 'gw-portals-policytransaction-common-angular.config.flowConfig.An error occurred while attempting to load.'
            }
        },
        cancelPrompt: {
            message: 'gw-portals-policytransaction-common-angular.config.flowConfig.The information previously entered will be stored'
        },
        save: {
            error: {
                title: 'gw-portals-policytransaction-common-angular.config.flowConfig.Unable to save',
                message: 'gw-portals-policytransaction-common-angular.config.flowConfig.An error occurred while attempting to save.'
            },
            progress: {
                message: 'gw-portals-policytransaction-common-angular.config.flowConfig.Saving...'
            }
        }
    };

    return function (step, junction) {
        junction('entry', true)
            .onNext
            .doAction(ACTIONS.load, ACTION_MSG.load)
            .thenGoTo('Fin');

        junction('transactionError');

        junction('returnHome');

        junction('cancel')
            .data({
                confirmationMsg() {
                    return ACTION_MSG.cancelPrompt.message;
                }
            })
            .onNext.doAction(ACTIONS.cancel, ACTION_MSG.save, { forceUpdate: true }).thenGoTo('returnHome', 'transactionError');

        junction('confirmation');
    };
};

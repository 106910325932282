import templateStr from 'text!./pm-how-to-pay-mta.html';

export default [() => {
    return {
        restrict: 'A',
        scope: {
            page: '<'
        },
        template: templateStr
    };
}];
import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, $state) => {
    const ctrl = Object.create(BaseCtrl);
    ctrl.goBackToHomeState = () => {
        const nextState = $state.$current.data.actions.next;
        $state.transitionTo(nextState);
    };
    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state'];
export default fn;

import templateStr from 'text!./templates/invoice-select.html';
import LineOfBusinessUtil from 'edge/billing/util/LineOfBusinessUtil';

export default () => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            gwBillingInvoiceSelect: '='
        },
        controller: ['$scope', 'InsuranceSuiteMetadata', 'TranslateService', ($scope, insuranceSuiteMetadata, TranslateService) => {
            const typelist = insuranceSuiteMetadata.get('bc').types.getTypelist('InvoiceItemType');
            $scope.invoice = $scope.gwBillingInvoiceSelect;
            $scope.accountCharge = {
                msg: ''
            };
            TranslateService.translate({
                object: $scope.accountCharge,
                propName: 'msg',
                displayKey: 'billing.directives.InvoiceSelect.AccountCharge',
                scope: $scope
            });
            $scope.invoiceItemTypeName = (typeCode) => {
                return typelist.getCode(typeCode).name;
            };
            $scope.lobIconPath = LineOfBusinessUtil.lobIconPath;
        }]
    };
};
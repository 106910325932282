import templateStr from 'text!./reward-points-year.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            points: '<',
            error: '<'
        }
    };
}];


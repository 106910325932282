import templateStr from 'text!./contact-us-info.html';

export default [() => {
    return {
        restrict: 'AE',
        template: templateStr,
        scope: {},
        controller: ['$scope', 'brandingData', '$filter', ($scope, brandingData, $filter) => {
            $scope.helpCentreUrl = $filter('translate')(`and.contactus.link.Help Centre.${brandingData}`);
            $scope.goToPage = (pageUrl) => window.open(pageUrl, '_blank');
        }]
    };
}];
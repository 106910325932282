/**
 * Utilities for the local time DTO.
 * Note that conversions to a local time may give surprising results
 */
/**
 * Converts a dto into a local date at specific Day (year/month/day).
 * @param {Object|undefined} dto dto to convert.
 * @param {Number} year year of the day.
 * @param {Number} month zero-based month of the day.
 * @param {Number} day day of the day.
 * @returns {Date|dto}
 */
function toLocalDateAtDay(dto, year, month, day) {
    return dto ? new Date(year, month, day, dto.hour, dto.minute, dto.second, dto.millisecond) : dto;
}

/**
 * Converts a DTO into a date-time instant of local today.
 * @param {Object} dto
 * @returns {Date|dto}
 */
function toLocalTodayDate(dto) {
    const today = new Date();
    return toLocalDateAtDay(dto, today.year, today.month, today.day);
}

/**
 * Converts a DTO into a date-time instant of local tomorrow.
 * @param {Object} dto
 * @returns {Date|dto}
 */
function toLocalTomorrowDate(dto) {
    const today = new Date();
    return toLocalDateAtDay(dto, today.year, today.month, today.day + 1);
}

export default {
    /**
     * Creates a new "LocalDateDTO" capturing local time in client's (default) time zone. "Date" part (year, month, day)
     * is discarded.
     *
     * @param {Date|undefined} date
     * @returns {Date|date}
     */
    fromJSDate(date) {
        return date ? {
            hour: date.getHours(),
            minute: date.getMinutes(),
            second: date.getSeconds(),
            millisecond: date.getMilliseconds()
        } : date;
    },


    toLocalDateAtDay,
    toLocalTodayDate,
    toLocalTomorrowDate,

    /**
     * Converts a local date to a nearest date-time in the nearest future. It could be a today date (if it is not
     * too late yet) or a tomorrow date.
     *
     * @param {Date|Object} dto
     * @returns {*}
     */
    toLocalNearestFutureDate(dto) {
        if (!dto) {
            return dto;
        }

        const dateToday = toLocalTodayDate(dto);
        return dateToday.getTime() < Date.now() ? toLocalTodayDate(dto) : dateToday;
    }
};
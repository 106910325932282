import template from 'text!./reward-points-banner.html';
const POINTS_CRITERIA_TO_SPEND_AT_LAPSE_AND_CANCELED = 250;
export default [() => ({
    restrict: 'A',
    template: template,
    scope: {
        goToPage: '=?',
        'policyDetails': '<andRewardPointsBanner'
    },
    controller: ['$rootScope', '$scope', 'RewardsService_AND', ($rootScope, $scope, RewardsService_AND) => {
        $scope.$watch('policyDetails', () => {
            $scope.hasPolicyLapsedOrCancelledWithPointLeft = false;
            RewardsService_AND.getPointsHistory($rootScope.policyContext.currentPolicyNumber)
                .then((data) => {
                    $scope.pointsBalance = data;
                    if ($scope.pointsBalance.pointsAvailable >= POINTS_CRITERIA_TO_SPEND_AT_LAPSE_AND_CANCELED && $rootScope.policyContext.currentPolicy.canAccessDGTRewards_AND === true) {
                        $scope.hasPolicyLapsedOrCancelledWithPointLeft = true;
                    }
                });
        });
    }]
})];
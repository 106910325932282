/**
 * Filter to provide a second duration in HH:MM:SS format
 */
export default () => {
    const SECOND = 1;
    const MINUTE = SECOND * 60;
    const HOUR = MINUTE * 60;
    const format = (value) => ((value < 10) ? `0${value}` : value);

    return (timeInSeconds) => {
        if (timeInSeconds) {
            const hours = Math.floor(timeInSeconds / HOUR);
            const minutes = Math.floor((timeInSeconds % HOUR) / MINUTE);
            const seconds = Math.floor((timeInSeconds % MINUTE));
            return `${format(hours)}:${format(minutes)}:${format(seconds)}`;
        }
    };
};

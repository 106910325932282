import BaseCtrl from 'edge/platform/base/BaseCtrl';
import ProductMediaMapUtil from 'gw-portals-util-js/ProductMediaMapUtil';

const fn = (
    $filter, $scope, $q, $state, $stateParams, $location, $anchorScroll, AccountBillingDetailsService, ModalService,
    PaymentInstrumentService, PaymentIntakeService, InsuranceSuiteMetadata
) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.selectedInvoiceStream = null;
        $scope.selectedInvoiceStreamLabel = null;
        $scope.selectedInvoiceStreamHeaderString = null;
        $scope.activeView = '';
        $scope.updateNotifiers = {};

        $scope.loadInvoiceStreamsPromise = loadAccountInvoiceStreams();
    };

    const periodicityTypeList = InsuranceSuiteMetadata.get('bc').types.getTypelist('Periodicity');

    /**
     * Get translated display name for periodicity
     * @param {string} periodicity
     * @returns {string|null}
     */
    function getTranslatedPeriodicity(periodicity) {
        const typeKey = periodicityTypeList.getCode(periodicity);
        return typeKey ? typeKey.name : null;
    }

    /**
     * Wrapper function to retrieve label
     * @param {string} label
     * @returns {string|null}
     */
    ctrl.getTileLabel = (label) => {
        if ($scope.isAccountLevelBilling) {
            return getTranslatedPeriodicity(label);
        }
        return label;
    };

    /**
     * Get the icon for a line of business, if no valid LOB then a default is used
     * @param {String} policyTypeCode - the Lob code associated with a policy
     * @returns {string} - icon name to display
     */
    ctrl.getPolicyTypeIcon = (policyTypeCode) => {
        const lobIcon = ProductMediaMapUtil.getLOBIcon(policyTypeCode);
        return lobIcon === undefined ? 'fa fa-file-text-o' : lobIcon;
    };

    function setInvoiceStreamHeader(isAccLevelStream, invoiceStream, lobCode) {
        let header = '';
        const isAutomaticPaymentEnabled = invoiceStream.paymentInstrumentSummary != null;
        if (isAccLevelStream) {
            header = isAutomaticPaymentEnabled
                ? 'billing.view.billing-details.Invoices (Automatic Payments Enabled)'
                : 'billing.view.billing-details.Invoices';
        } else {
            header = getPolicyLevelInvoiceStreamHeader(lobCode, isAutomaticPaymentEnabled);
        }
        return header;
    }

    function getPolicyLevelInvoiceStreamHeader(lobCode, isAutoPaymentEnabled) {
        const lobHeader = policyLevelHeaderStrings.hasOwnProperty(lobCode) ? policyLevelHeaderStrings[lobCode] : 'default';
        if (isAutoPaymentEnabled) {
            return lobHeader.autoPaymentEnabled;
        }
        return lobHeader.default;
    }

    const policyLevelHeaderStrings = {
        PersonalAuto: {
            autoPaymentEnabled: 'billing.view.billing-details.Auto Policy (Automatic Payments Enabled)',
            default: 'billing.view.billing-details.Auto Policy'
        },
        Homeowners: {
            autoPaymentEnabled: 'billing.view.billing-details.Home Policy (Automatic Payments Enabled)',
            default: 'billing.view.billing-details.Home Policy'
        },
        BusinessOwners: {
            autoPaymentEnabled: 'billing.view.billing-details.Business Policy (Automatic Payments Enabled)',
            default: 'billing.view.billing-details.Business Policy'
        },
        default: {
            autoPaymentEnabled: 'billing.view.billing-details.Policy (Automatic Payments Enabled)',
            default: 'billing.view.billing-details.Policy'
        }
    };

    /**
     * Select an invoice stream to make active. Loads the data associated with the stream and changes the view
     * @param {Object} stream - the stream to make active
     * @param {String} defaultAction - when the invoice stream has loaded then load this action
     */
    ctrl.selectInvoiceStream = (stream, defaultAction = 'default') => {
        $scope.selectedInvoiceStream = stream;
        $scope.selectedInvoiceStreamLabel = stream.label;

        $scope.invoiceStreamDetailsPromise = AccountBillingDetailsService.getInvoiceStreamDetails($scope.selectedInvoiceStream.internalId).then(
            (invoiceStream) => {
                $scope.invoiceStream = invoiceStream;

                // populate header based on LOB or periodicity, see selectPolicy()
                $scope.selectedInvoiceStreamHeaderString = setInvoiceStreamHeader($scope.isAccountLevelBilling, invoiceStream, $scope.selectedInvoiceStream.lOBCode);


                if ($scope.updateNotifiers.dataUpdated) {
                    // Alert child directive that data has updated, if already instantiated
                    $scope.updateNotifiers.dataUpdated();
                }
                // Clear the active filter
                $scope.paymentScheduleFilter = '';

                updateAvailableActions(invoiceStream.paymentInstrumentSummary, $scope.isAccountLevelBilling);

                // Set the default action
                $scope.activeView = defaultAction;
            },
            () => {
                // Redirect to contact agent page if there is an error in BC
                $state.go('billing-contact-agent');
            }
        );
    };

    /**
     * Resets the active view to the default view
     */
    ctrl.cancelAction = () => {
        $scope.activeView = 'default';
    };

    /**
     * Pays a list of invoices associated with the active invoice stream
     * @param {Array} invoiceIds - The list of invoices to be paid
     * @param {Number} amount - The amount to be paid
     * @param {*} instrument - The payment instrument used
     */
    ctrl.payAction = (invoiceIds, amount, instrument) => {
        const invoiceStreamCurrency = $scope.invoiceStream.balance.currency;
        $scope.submitBillPaymentPromise = PaymentIntakeService.makeDirectPayment(invoiceIds, amount, instrument).then(
            () => {
                $scope.paymentData = {
                    amount: amount,
                    currency: invoiceStreamCurrency
                };
                $scope.selectInvoiceStream($scope.selectedInvoiceStream, 'paymentConfirmation');
            },
            (error) => {
                console.error('make payment failed', error);
                ModalService.showError(
                    'billing.controllers.BillingSummaryCtrl.Payment request failed',
                    'billing.controllers.BillingSummaryCtrl.Sorry your payment could not be processed at this time'
                );
            }
        );
    };

    ctrl.getAmount = (paymentAmount) => {
        return $filter('gwCurrency')(paymentAmount);
    };

    /**
     * Sets up automatic payments on the active invoice stream
     * @param {*} instrument - The payment instrument used
     * @param {Date} startDate - The date automatic payments are to start
     */
    ctrl.setupAction = (instrument, startDate) => {
        $scope.paymentDueDate = startDate;
        $scope.submitAutoPayPromise = AccountBillingDetailsService.setPaymentGroupPaymentInformation($scope.invoiceStream.internalId, instrument, []).then(
            () => {
                $scope.selectInvoiceStream($scope.selectedInvoiceStream, 'automaticConfirmation');
            },
            (error) => {
                console.error('Setup payment failed', error);
                ModalService.showError(
                    'billing.controllers.BillingSummaryCtrl.Account update failed',
                    'billing.controllers.BillingSummaryCtrl.Sorry, we were not able to setup automatic payments on your account'
                );
            }
        );
    };

    /**
     * Changes the payment source set up on an automatic payments invoice stream
     * @param {*} instrument - The payment instrument to switch to
     */
    ctrl.changePaymentAction = (instrument) => {
        $scope.changeAutopayPaymentMethodPromise = AccountBillingDetailsService.setPaymentGroupPaymentInformation($scope.invoiceStream.internalId, instrument, []).then(() => {
            $scope.selectInvoiceStream($scope.selectedInvoiceStream, 'paymentSourceConfirmation');
        }, (error) => {
            console.error('Changing autopay payment method failed:', error);
            ModalService.showError(
                'billing.controllers.BillingSummaryCtrl.Changing payment method',
                'billing.controllers.BillingSummaryCtrl.There was a problem changing the payment method'
            );
        });
    };

    return ctrl.create($scope);

    /**
     * Switches to the change payment source view
     */
    function paymentSourceAction() {
        $scope.activeView = 'payment-source';
    }

    /**
     * Switches to the make a payment view
     */
    function paymentAction() {
        $scope.activeView = 'payment';
    }

    /**
     * Switches to the setup automatic payments view
     */
    function automaticAction() {
        $scope.activeView = 'automatic';
    }

    /**
     * Switches to the policy list view for account level billing
     */
    function viewAction() {
        $scope.activeView = 'policy-list';
    }

    /**
     * Begin to load the invoice streams associated with the active account
     * @returns {*|Promise.<TResult>|Promise}
     */
    function loadAccountInvoiceStreams() {
        return AccountBillingDetailsService.getAccountInvoiceStreamInformation()
            .then((result) => {
                $scope.invoiceStreams = result.invoiceStreams;
                $scope.isAccountLevelBilling = result.accountLevelBilling;

                /**
                 * If a stream ID was specified in the URL then try to find that stream in all streams and
                 * then select it with the automatic payments action active
                 */
                if ($stateParams.streamId) {
                    const defaultStream = $scope.invoiceStreams.filter((i) => {
                        return i.internalId === $stateParams.streamId;
                    });

                    if (angular.isDefined(defaultStream[0])) {
                        $scope.selectInvoiceStream(defaultStream[0], 'automatic');
                    }
                } else if ($scope.invoiceStreams.length === 1) {
                    // If there is only one invoice stream then select it
                    $scope.selectInvoiceStream($scope.invoiceStreams[0]);
                }
            });
    }

    /**
     * Set the available actions based on the payment instrument
     * @param {*} paymentInstrument
     * @param {Boolean} isAccountLevelBilling
     */
    function updateAvailableActions(paymentInstrument, isAccountLevelBilling) {
        $scope.viewAction = isAccountLevelBilling ? viewAction : null;

        if (paymentInstrument) {
            // If a payment instrument is in place, the action to change the source is the only available action
            $scope.paymentSourceAction = paymentSourceAction;
            $scope.paymentAction = null;
            $scope.automaticAction = null;
        } else {
            // If no payment instrument is in place, then all actions except change source are available
            $scope.paymentSourceAction = null;
            $scope.paymentAction = paymentAction;
            $scope.automaticAction = automaticAction;
        }
    }
};

fn.$inject = [
    '$filter',
    '$scope',
    '$q',
    '$state',
    '$stateParams',
    '$location',
    '$anchorScroll',
    'billing.AccountBillingDetailsService',
    'ModalService',
    'integration.PaymentInstrumentService',
    'integration.PaymentIntakeService',
    'InsuranceSuiteMetadata'
];

export default fn;

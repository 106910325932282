import q from 'q';
import iframeUtil from './utils/IframeUtil/index';
import OAuthUtil from './utils/OAuthUtil';
import AuthUtil from './utils/AuthenticationUtil';
import queryString from 'query-string';
import _ from 'lodash';
import BrandingData from 'gw-portals-branding-js/BrandingData'; // ANDIE Add branding for Identity Zone

var ERRORS = { // Errors which can be used to detect the reason
    login_failure: 'login_failure',
    account_locked: 'account_locked'
};

// METHODS
/**
 * If user is not logged in and iframe is loaded properly-
 * returns an XUaaCsrf value and sets a "XUaaCsrf" cookie
 *
 * @returns {Promise}
 */
function _getXUaaCsrf() {
    var deferred = q.defer();
    iframeUtil.loadIframe({
        src: '/accountmanagement/' + BrandingData.BRANDING + '/login', // ANDIE Add branding for Identity Zone
        expectedSrcPartOnLoad: '/' + BrandingData.BRANDING + '/login' // detect isn't redirected (not logged in already)
    }).then(function (iframeData) {
        var XUaaCsrfIframeInput = iframeData.iframeDoc.querySelector('[name="X-Uaa-Csrf"]');
        var XUaaCsrf = XUaaCsrfIframeInput.value;
        deferred.resolve({
            res: XUaaCsrf
        });
        iframeData.clearIframeArtifacts(); // cleaning
    }).catch(function () {
        deferred.reject();
    });

    return deferred.promise;
}

function _sendLoginRequest(XUaaCsrf, username, password) {
    return fetch('/accountmanagement/' + BrandingData.BRANDING + '/login.do', { // ANDIE Add branding for Identity Zone
        method: 'POST',
        credentials: 'same-origin', // add cookies
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'username=' + username + '&password=' + password + '&X-Uaa-Csrf=' + XUaaCsrf
    }).then(function (res) {
        var urlQueryString = queryString.extract(res.url);
        var urlQueryParams = queryString.parse(urlQueryString);
        var urlQueryError = urlQueryParams.error;

        if (!urlQueryError) {
            // if there is no errors
            return res;
        }

        // noinspection UnnecessaryLocalVariableJS
        var loginError = {
            error: urlQueryError // reflected in the ERRORS variable
        };

        throw loginError; // trigger the Promise reject
    });
}

// METHODS
function logout(authUtil, oAuthUtil, oAuthConfig) {
    var token = void 0;
    oAuthUtil.waitTokensSet().then(function (tokens) {
        token = tokens.accessToken;
    });
    // ANDIE Add /accountmanagement prefix for proxy routing
    return fetch(oAuthConfig.url + '/accountmanagement' + oAuthConfig.endpoints.logout, { // call logout endpoint to signal that auth session should be destroyed
        mode: oAuthConfig.logoutMode,
        credentials: 'include' // add cookies
    }).then(function (res) {
        var origin = oAuthUtil.removeTokens();
        authUtil.emitLogoutEvent({ token: token, origin: origin });
        return {
            res: res
        };
    });
}

function loginWithGoogle(authUtil) {
    // first try to retrieve a token so that we will be redirected back to the correct page after authentication
    return authUtil.testForOAuthToken().catch(function () {
        // not authenticated so load the login page and get the 'login with google' link
        return iframeUtil.loadIframe({
            src: '/login',
            expectedSrcPartOnLoad: '/login' // detect isn't redirected (not logged in already)
        }).then(function (iframeData) {
            // go to the google authorize url
            var googleLoginLink = iframeData.iframeDoc.querySelector('a[href^="https://accounts.google.com/o/oauth2"]');
            window.top.location.href = googleLoginLink.href;
            iframeData.clearIframeArtifacts(); // cleaning
        });
    });
}

function login(authUtil, _ref) {
    var username = _ref.username,
        password = _ref.password;

    return _getXUaaCsrf().then(function (data) {
        return _sendLoginRequest(data.res, username, password);
    }).then(authUtil.loginWithCurrentCookies);
}

/**
 * ANDIE change the password using UAA's User Password Change endpoint.
 * This does not interact with InsuranceSuite in any way, just with UAA.
 * @returns {*}
 */
function changeUserPassword_AND(authUtil, oAuthUtil, oAuthConfig, _ref2) {
    var userID = _ref2.userID,
        oldPassword = _ref2.oldPassword,
        newPassword = _ref2.newPassword;

    return oAuthUtil.waitTokensSet().then(function (_ref3) {
        var token = _ref3.accessToken;

        // ANDIE Add /accountmanagement prefix for proxy routing
        return fetch(oAuthConfig.url + '/accountmanagement/' + BrandingData.BRANDING + '/Users/' + userID + '/password', {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + token,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                oldPassword: oldPassword,
                password: newPassword
            })
        });
    });
}

// EXPORT
export default (function (oAuthConfig) {
    var authUtil = AuthUtil(oAuthConfig);
    var oAuthUtil = OAuthUtil(oAuthConfig);
    return {
        logout: _.partial(logout, authUtil, oAuthUtil, oAuthConfig),
        login: _.partial(login, authUtil),
        loginWithGoogle: _.partial(loginWithGoogle, authUtil),
        forgotPassword: authUtil.forgotPassword,
        changePassword: authUtil.changePassword,
        changeUserPassword_AND: _.partial(changeUserPassword_AND, authUtil, oAuthUtil, oAuthConfig),
        signUp: authUtil.signUp,
        verifyResetCode: authUtil.verifyResetCode,
        testForOAuthToken: authUtil.testForOAuthToken,
        onLoginStateChange: authUtil.addLoginStateChangeListener,
        loginWithCurrentCookies: authUtil.loginWithCurrentCookies,
        waitForLoginRequestComplete: authUtil.waitForLoginRequestComplete,
        ERRORS: {
            tokens: authUtil.ERRORS,
            auth: ERRORS
        }
    };
});
import templateStr from 'text!./pm-diretdebit-mandate.html';
import mandateTemplate from 'text!./mandate-template.html';

// Hardcoded Bank Data
const BANK_DATA = {
    serviceUserNumber: '942461',
    referenceNumber: '',
};

export default [() => {
    return {
        restrict: 'E',
        scope: {
            bindData: '<'
        },
        template: templateStr,
        controller: ['$scope', 'ModalService', ($scope, ModalService) => {
            $scope.bankData = BANK_DATA;
            $scope.date = new Date();

            $scope.showMandatePopUp = () => {
                ModalService.showSimpleDialog(mandateTemplate, {
                    bankData: $scope.bankData,
                    bindData: $scope.bindData,
                    date: $scope.date,
                    formattedSortCode: $scope.bindData.paymentDetails.bankAccountData.sortCode.value.replace(/-/g, '')
                }, {
                    printMandate: printMandate,
                    formatBoxedNumber: formatBoxedNumber
                }, null, {
                    fullscreen: false,
                    backdrop: true,
                    windowClass: 'and-modal'
                });
            };

            $scope.formatBoxedNumber = (number, noOfBoxes) => {
                return formatBoxedNumber($scope, number, noOfBoxes);
            };

            $scope.formatWithHyphens = (sortCode) => {
                if (sortCode.match(/-/g)) { // value already has hyphens
                    return sortCode;
                }
                return `${sortCode.substr(0, 2)}-${sortCode.substr(2, 2)}-${sortCode.substr(4, 2)}`;
            };

            // Prints the mandate form
            const printMandate = (scope) => {
                // get the content of html element that you want to print
                const printContents = document.getElementById('and-mandate-print-area').innerHTML;
                // open a popup window to draw the html
                const popupWin = window.open('', '_blank', 'width=768,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
                popupWin.document.open();
                // embed css files into head, embed html content into body
                popupWin.document.write(`<html><head><link rel="stylesheet" type="text/css" href="../styles/css/modules.css" /><link rel="stylesheet" href="../styles/css/application.css"/></head><body onload="window.print()">${printContents}</html>`);
                popupWin.document.close();
            };

            // Gets a number and formats it to an array of characters
            // adds spaces if the number.length < charNo
            const formatBoxedNumber = (scope, number, charNo) => {
                let formattedNumber = '';
                if (angular.isDefined(number)) {
                    formattedNumber += number.value ? number.value : number;
                }
                const numberLength = formattedNumber.length;
                // Add spaces to the number to show-up as empty boxes
                if (numberLength < charNo) {
                    for (let i = 0; i < (charNo - numberLength); i++) {
                        formattedNumber = `${formattedNumber} `;
                    }
                }
                return formattedNumber.split('');
            };

        }]
    };
}];
function mapToContactModel(vendor) {
    return {
        addressBookUID: vendor.addressBookUID,
        contactName: vendor.vendorName,
        contactType: 'CompanyVendor',
        subtype: 'AutoRepairShop',
        emailAddress1: vendor.email,
        primaryAddress: Object.assign({
            spatialPoint: vendor.geocode
        }, vendor.primaryAddress),
        primaryPhoneType: 'work',
        workNumber: vendor.phone,
        proximateDistance: vendor.proximateDistance,
        score: vendor.score
    };
}

function normalizeLongitude(longitude) {
    const divisor = 360;
    let result = longitude % divisor;
    if (result <= 0) {
        result += divisor;
    }
    if (result > divisor / 2) {
        result -= divisor;
    }
    return result;
}

function getNormalizedCoords(coords) {
    const {longitude} = coords;
    if (longitude <= -180 || longitude > 180) {
        coords.longitude = normalizeLongitude(longitude);
    }
    return coords;
}

export default ['JsonRPCService', (JsonRPCService) => {
    return {
        getVendorsNearby: (searchParams) => {
            searchParams.searchRadius = searchParams.searchRadius || '6';
            searchParams.searchRadius = parseInt(searchParams.searchRadius) <= 300 ? searchParams.searchRadius : '300';
            searchParams.unitOfDistance = searchParams.unitOfDistance || 'Mile';
            searchParams.maxNumberOfResults = searchParams.maxNumberOfResults || 50;
            searchParams.proximitySearchGeocode = getNormalizedCoords(searchParams.proximitySearchGeocode);

            return JsonRPCService.send('cm', 'vendor/vendor', 'proximitySearch', [searchParams])
                .then(({vendors}) => vendors.map(mapToContactModel));
        }
    };
}];
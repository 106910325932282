/**
 * Gets and populates Producer Contact data
 */

import _ from 'underscore';

export default function ($injector, $scope) {
    const config = $injector.get('MEDIA_SIDEBAR_CONFIGURATION');
    const contactConfig = _.findWhere(config, {
        module: 'Contact'
    });
    const PUBLIC_ID_FIELD = 'publicID';

    /* ----- SCOPE ----- */
    /**
     * Adds property to the scope
     * if such property doesn't exist in the config
     *
     * @param {String} prop
     * @param {*} value
     */
    const extendScope = function (prop, value) {
        if (contactConfig.configuration[prop]) {
            return;
        }

        $scope[prop] = value;
    };

    /**
     * Extends user info in the scope
     * @param {Object} user
     */
    const extendUser = function (user) {
        Object.keys(user).forEach((key) => {
            const value = user[key];
            extendScope(key, value);

            // special case for a user image
            if (key === PUBLIC_ID_FIELD) {
                showImageById(value);
            }
        });
    };

    /* ----- IMAGE ----- */
    const showImageById = function (publicID) {
        if (contactConfig.configuration.image) {
            return;
        }

        publicID = publicID.replace(':', '-'); // to avoid image file names with ":"
        $scope.image = `../styles/images/common/users/${publicID}.png`;
    };

    /* ----- POPULATE PRODUCER CONTACT DATA ----- */
    return $injector.get('ProducerService').getProducersContactSummaries()
        .then(summaries => {
            let data = { singleProducerContactAssociated: false };
            const allProducerSummariesAreEqual = summaries.every(summary => summary[PUBLIC_ID_FIELD] === summaries[0][PUBLIC_ID_FIELD]);
            if (summaries.length > 1 && !allProducerSummariesAreEqual) {
                return;
            }

            data = {...summaries[0], singleProducerContactAssociated: true};
            if (!data[PUBLIC_ID_FIELD]) {
                $scope.errorMsg = 'platformwidgets.mediasidebar.There is no Producer contact summary for the current user';
            } else {
                extendUser(data);
            }
        }, () => {
            $scope.errorMsg = 'platformwidgets.mediasidebar.Error loading Producer contact summary';
        });
}
import templateStr from 'text!./templates/change-payment-source-form.html';

export default () => {
    return {
        template: templateStr,
        scope: {
            invoiceStream: '=',
            cancelAction: '&',
            changeAction: '&'
        },
        controller: ['$scope', 'TranslateService', 'ViewModelService', 'paymentType', ($scope, TranslateService, ViewModelService, paymentType) => {

            init();

            $scope.paymentType = paymentType;

            /**
             * Prepares data for the change automatic payment requests. Delegates to the parent controller
             * @param {String} paymentMethod
             */
            $scope.changeAutopayPaymentMethod = (paymentMethod) => {
                const autoPayPaymentInstrument = angular.copy($scope.paymentInstrument.value);
                autoPayPaymentInstrument.paymentMethod = paymentMethod;

                if (paymentMethod === paymentType.WIRE) {
                    autoPayPaymentInstrument.creditCardData = null;
                } else {
                    autoPayPaymentInstrument.bankAccountData = null;
                }

                $scope.changeAction({
                    instrument: autoPayPaymentInstrument
                });
            };

            /**
             * Determine if the payment method is valid for automatic payments
             * @param {String} paymentMethod
             * @param {Object} paymentInstrument
             * @returns {boolean}
             */
            $scope.autopayPaymentMethodIsValid = (paymentMethod, paymentInstrument) => {
                if (paymentMethod === paymentType.CREDIT_CARD) {
                    return paymentInstrument.creditCardData.aspects.subtreeValid;
                }
                return paymentInstrument.bankAccountData.aspects.subtreeValid;
            };

            function init() {
                // If autopay is enabled, get the current payment instrument
                if (angular.isDefined($scope.invoiceStream.paymentInstrument)) {
                    $scope.paymentInstrument = ViewModelService.create(
                        $scope.invoiceStream.paymentInstrument,
                        'bc', 'edge.capabilities.billing.dto.PaymentInstrumentDTO'
                    );

                    if (angular.isDefined($scope.invoiceStream.paymentInstrument.creditCardData)) {
                        $scope.autopayPaymentMethod = paymentType.CREDIT_CARD;
                    } else {
                        $scope.autopayPaymentMethod = paymentType.WIRE;
                    }
                }
                $scope.autopayPaymentMethodOptions = [{
                    value: paymentType.WIRE,
                    displayKey: 'billing.directives.ChangePaymentSourceForm.Bank Account',
                    displayValue: ''
                }, {
                    value: paymentType.CREDIT_CARD,
                    displayKey: 'billing.directives.ChangePaymentSourceForm.Credit Card',
                    displayValue: ''
                }];

                $scope.autopayPaymentMethodOptions.forEach((paymentMethodOption) => {
                    TranslateService.translate({
                        object: paymentMethodOption,
                        displayKey: paymentMethodOption.displayKey,
                        propName: 'displayValue',
                        scope: $scope
                    });
                });
            }
        }]
    };
};
import template from 'text!./cookie-banner.html';
angular.module('cookiesExample', ['ngCookies'])

export default [() => ({

    restrict: 'A',
    template: template,
    scope: {
        goToPage: '=?'
    },
    controller: ['$rootScope', '$scope', '$cookies','brandingData', 'StaticDocumentsService_AND', '$q', ($rootScope, $scope, $cookies, brandingData, StaticDocumentsService_AND, $q) => {     
      $scope.showSettings = false
      $scope.brand = brandingData
      $q.all([
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.COOKIE_POLICY),
      ]).then((result) => {
        $scope.privacyPolicyDoc = result[0];
      });

      var _consent = $cookies.get('consent');
        if ($scope.brand !== 'tbb') {
            if (_consent !== undefined) {
                $scope.showCookieBanner = false;
            } else {
                $scope.showCookieBanner = true;
            } 
        }

      $scope.setConsent = function () {
        $cookies.put('consent', true);
        $scope.showCookieBanner = false;
        $cookies.put($scope.brand+'-PRIVACY-STRICTCOOKIES', 1);
        $cookies.put($scope.brand+'-PRIVACY-FUNCTIONCOOKIES', 1);
        $cookies.put($scope.brand+'-PRIVACY-ANALYTICSCOOKIES', 1);
        $cookies.put($scope.brand+'-PRIVACY-ADVERTCOOKIES', 1);
      }

      $scope.showSettings = function () {
        if ($scope.cookieExtras){
          $scope.cookieExtras = false
        } else {
          $scope.cookieExtras = true
        }
        
      }

      $scope.dropCookies = function () {
        document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        $cookies.put($scope.brand+'-PRIVACY-STRICTCOOKIES', 0);
        $cookies.put($scope.brand+'-PRIVACY-FUNCTIONCOOKIES', 0);
        $cookies.put($scope.brand+'-PRIVACY-ANALYTICSCOOKIES', 0);
        $cookies.put($scope.brand+'-PRIVACY-ADVERTCOOKIES', 0);
        $scope.showCookieBanner = false
        $cookies.put('consent', false);
      }

      $scope.save = function () {
        $scope.strictCookies = document.getElementById('strictlySwitch').checked
        $scope.function = document.getElementById('functionSwitch').checked
        $scope.analytics = document.getElementById('analyticsSwitch').checked
        $scope.advert = document.getElementById('advertSwitch').checked
        

        if ($scope.strictCookies === true){
          $cookies.put($scope.brand+'-PRIVACY-STRICTCOOKIES', 1);
        } else {
          $cookies.put($scope.brand+'-PRIVACY-STRICTCOOKIES', 0);
        }

        if ($scope.function === true){
          $cookies.put($scope.brand+'-PRIVACY-FUNCTIONCOOKIES', 1);
        } else {
          $cookies.put($scope.brand+'-PRIVACY-FUNCTIONCOOKIES', 0);
        }

        if ($scope.analytics === true){
          $cookies.put($scope.brand+'-PRIVACY-ANALYTICSCOOKIES', 1);
        } else {
          $cookies.put($scope.brand+'-PRIVACY-ANALYTICSCOOKIES', 0);
        }

        if ($scope.advert === true){
          $cookies.put($scope.brand+'-PRIVACY-ADVERTCOOKIES', 1);
        } else {
          $cookies.put($scope.brand+'-PRIVACY-ADVERTCOOKIES', 0);
        }

        if ($scope.strictCookies === true && $scope.function === true && $scope.analytics === true && $scope.advert === true){
          $cookies.put('consent', true);
        }
        if ($scope.strictCookies === false && $scope.function === false && $scope.analytics === false && $scope.advert === false){
            $scope.dropCookies()
          }
        
        if ($scope.strictCookies === true || $scope.function === true || $scope.analytics === true || $scope.advert === true){
            $cookies.put('consent', true)
        }
        $scope.isSuccessVisible = true;
        $scope.showCookieBanner = false;
        $scope.cookieExtras = false;
       
      }

      $scope.clearSuccessMsg = () => {
          $scope.isSuccessVisible = false;
      }

    }]


})];
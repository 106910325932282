import templateStr from 'text!./driving-data.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            policyNumber: '<'
        },
        controller: ['$scope', 'TelematicsService_AND', ($scope, TelematicsService_AND) => {
            const getMonthlyTrips = function (page) {
                TelematicsService_AND.getMonthlyTrips($scope.policyNumber, $scope.page.selected.month, $scope.page.selected.year, page, $scope.page.monthlyTripsPagination.itemsPerPage)
                    .then((data) => {
                        $scope.page.monthlyTrips = data;
                        $scope.page.monthlyTripsPagination.totalItems = data.totalElements;
                    });
            };

            $scope.page = {
                selected: undefined,
                monthlyTripsPagination: {
                    currentPage: 0,
                    itemsPerPage: 25,
                    totalItems: 0 // Comes from the API
                },
                selectMonth: function (drivingSummary) {
                    $scope.page.selected = {
                        year: drivingSummary.year,
                        month: drivingSummary.month
                    };

                    TelematicsService_AND.getMonthlyMilesPerDay($scope.policyNumber, $scope.page.selected.month, $scope.page.selected.year)
                        .then((data) => {
                            $scope.page.monthlyMilesPerDay = data;
                        });

                    $scope.page.monthlyTripsPagination.currentPage = 0; // Reset pagination to first page
                    getMonthlyTrips(0);
                }
            };

            // Pagination control. Update list of trips to show
            $scope.$watch(() => $scope.page.monthlyTripsPagination.currentPage, (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    getMonthlyTrips(newValue - 1); // Subtract 1 to the page number - pages are 0-indexed
                }
            });
            // Selected Policy changed, update scope.
            $scope.$watch('policyNumber', (newValue) => {
                if (!newValue) {
                    return;
                }
                $scope.page.policyNumber = newValue;
                TelematicsService_AND.getMonthlySummaries(newValue)
                    .then((data) => {
                        if (data.constructor === Object && Object.keys(data).length === 0) {
                            return;
                        }
                        $scope.page.monthlySummaries = data;
                    });

                $scope.page.availableMiles = 0; // Default to 0
                TelematicsService_AND.getAvailableMiles(newValue)
                    .then((data) => {
                        if (data.constructor === Object && Object.keys(data).length === 0) {
                            return;
                        }
                        $scope.page.availableMiles = data;
                    });

                $scope.page.consumedMiles = 0; // Default to 0
                TelematicsService_AND.getConsumedMiles(newValue)
                    .then((data) => {
                        if (data.constructor === Object && Object.keys(data).length === 0) {
                            return;
                        }
                        $scope.page.consumedMiles = data;
                    });
            });
        }]
    };
}];

import _ from 'underscore';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import ClaimsRouteConfig from 'edge/claim/config/RouteConfig';
import ClaimService from 'edge/claim/services/ClaimService';
import DocumentService from 'edge/claim/services/DocumentService';
import ActivityService from 'edge/claim/services/ActivityService';
import RepairFacilityService from 'edge/claim/services/RepairFacilityService';
import ClaimSummary from 'edge/claim/directives/ClaimSummary';
import ClaimDocuments from 'edge/claim/directives/ClaimDocuments';
import ClaimActivities from 'edge/claim/directives/ClaimDetailActivities';
import VendorActivities from 'edge/claim/directives/ActivitySummary';
import ClaimNotes from 'edge/claim/directives/ClaimNotes';
import PolicyLevelCoverages from 'edge/claim/directives/PolicyLevelCoverages';
import PAVehicleCoverages from 'edge/claim/directives/PAVehicleCoverages';
import HOLocationCoverages from 'edge/claim/directives/HOLocationCoverages';
import ClaimPaymentDetails from 'edge/claim/directives/ClaimPaymentDetails';
import ClaimServiceRequests from 'edge/claim/directives/ClaimServiceRequests';

import 'edge/platform/widgets/inputs/module';
import 'edge/platform/widgets/table/module';
import 'gw-portals-viewmodel-angular/aspects/module';
import 'edge/platform/widgets/basicinputs/module';
import 'edge/platform/widgets/documentupload/module';
import 'edge/platform/map/module';
import 'edge/platform/widgets/modal/module';
import 'edge/platform/contacts/module';
import 'edge/platform/navigation/module';
import 'edge/platform/widgets/datetimepicker/module';
import 'edge/platform/widgets/accordion/module';
import 'edge/platform/pagination/module';
import 'angularRouter';
import 'portalConfig';

var directiveFeatureDetection = function directiveFeatureDetection() {
    if (ApplicationFeatures.hasFeature('lob.pa')) {
        appModule.directive('gwClaimPaVehicleCoverages', PAVehicleCoverages);
    }
    if (ApplicationFeatures.hasFeature('lob.ho')) {
        appModule.directive('gwClaimHoLocationCoverages', HOLocationCoverages);
    }
};

var appModule = angular.module('edge.claim.app', ['portal.config', 'edge.platform.base', 'gw-portals-viewmodel-angular.aspects', 'edge.platform.widgets.basicinputs', 'edge.platform.widgets.inputs', 'edge.platform.widgets.table', 'ui.router', 'edge.platform.map', 'edge.platform.widgets.documentupload', 'edge.platform.contacts', 'edge.platform.navigation', 'edge.platform.widgets.ui.bootstrap.datetimepicker', 'edge.platform.widgets.ui.bootstrap.accordion', 'edge.platform.pagination']).config(['$stateProvider', 'state_plugin_config', function ($stateProvider, RootStateConfig) {
    var states = new ClaimsRouteConfig(RootStateConfig);
    _.values(states).forEach(function (state) {
        $stateProvider.state(state);
    });
}]).config(['EventHubProvider', function (EventHubProvider) {
    EventHubProvider.registerTransformer({
        source: {
            category: 'tracking',
            eventName: 'serverCall'
        },
        target: {
            category: 'journey',
            eventName: 'claimNote'
        },
        transformer: function transformer(payload, send) {
            if (payload && payload.success && payload.method === 'createClaimNote') {
                var response = payload.response;
                send({
                    method: 'createClaimNote',
                    action: 'create',
                    claimNumber: payload.params[0],
                    subject: payload.params[1].subject,
                    body: payload.params[1].body,
                    author: response.author,
                    publicId: response.publicID
                });
            }
        }
    });
}]).directive('gwClaimClaimSummary', ClaimSummary).directive('gwClaimClaimDocuments', ClaimDocuments).directive('gwClaimClaimDetailActivities', ClaimActivities).directive('gwClaimActivitySummary', VendorActivities).directive('gwClaimClaimNotes', ClaimNotes).directive('gwClaimPolicyLevelCoverages', PolicyLevelCoverages).directive('gwClaimPaymentDetails', ClaimPaymentDetails).component('gwClaimServiceRequests', ClaimServiceRequests).factory('claim.ClaimService', ClaimService).factory('claim.DocumentService', DocumentService).factory('claim.ActivityService', ActivityService).factory('claim.RepairFacilityService', RepairFacilityService).value('claimDetailsLinks', {
    previousPage: 'claimlist'
}).value('claim.endpointConfig', {
    'uploadEndpoint': 'claim/docupload',
    'documentsEndpoint': 'claim/docs',
    'downloadEndpoint': 'document/document/claimDocument',
    'sessionServiceName': 'claim/docs'
}).value('ApplicationFeatures', ApplicationFeatures);

directiveFeatureDetection();

export default appModule;
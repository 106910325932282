import templateStr from 'text!./pm-introduction.html'; 
import moment from 'moment';


export default ['$q', 'StaticDocumentsService_AND',($q, StaticDocumentsService_AND) => {
    return {
        restrict: 'A', 
        scope: {
        }, 
        template: templateStr, 
        controller: ['$scope', ($scope) => {
            $scope.pathname = window.location.origin; 
            $scope.privacyNoticeDoc = ''; 
            $scope.cookiePolicyDoc = ''; 
            $scope.privacyPolicyDoc = ''; 
            $scope.isBeforeANDUKStartDate = () => {
                return moment().isBefore(moment('20201201').startOf('day'));
            };
        }], 
        link: ($scope) => {
            //Docs
            $q.all([
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_POLICY),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_NOTICE),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.COOKIE_POLICY),
            ]).then((result) => {
                $scope.privacyPolicyDoc = result[0];
                $scope.privacyNoticeDoc = result[1];
                $scope.cookiePolicyDoc = result[2];
            });
        }, 
    };
}]; 
import BaseCtrl from 'edge/platform/base/BaseCtrl';

/* The step Covers Billing Address and Account Details */
const fn = ($scope, $state, RenewalService_AND, submissionViewModel, StateUtils) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.submissionVm = submissionViewModel();
    };

    ctrl.next = (callback) => {
        $scope.form.submitted = true;
        if ($scope.form.valid === false) {
            return callback();
        }

        RenewalService_AND.updateQuotedAndValidateDDI($scope.submissionVm.value)
            .then((submission) => {
                $scope.submissionVm.bindData.value = submission.bindData; // Update scope model
                $scope.submissionVm.bindData.chosenQuote.value = StateUtils.activeDataForState(true).chosenQuote;

                if (submission.bindData.paymentDetails.bankAccountData.validationResponse_itb.isCorrect !== true) {
                    callback();
                } else {
                    callback($scope.submissionVm);
                }
            })
            .catch(() => {
                // Flag that something went wrong with the integration
                $scope.submissionVm.bindData.paymentDetails.bankAccountData.validationResponse_itb.value = {
                    integrationOffline: true
                };
                callback();
            });
    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$state', 'RenewalService_AND', 'submissionViewModel', 'StateUtils'];
export default fn;
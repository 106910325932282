import _ from 'underscore';
import * as d3 from 'd3';

export default [
    () => ({
        /**
         *  data:   An array of datapoints for the pie-chart
         *      - Datapoint:   An object describing the data being visualised
         *          - value:     - Required - (Number)      - the data being plotted
         *          - color:     - Optional - (String)      - Hexcode for the datapoint segment fill color
         *          - opacity:   - Optional - (Number 0..1) - Opacity value for datapoint segment
         *          - class:     - Optional - (String)      - CSS class to apply to datapoint segment (CSS rules have
         *                                                    higher precedence than the SVG attributes)
         *  height:     - Optional - (Number) - Height of pie chart
         *  width:      - Optional - (Number) - Width of pie chart
         *  innerRadius - Optional - (Number 0..1) - Percentage of outer radius for the inner radius. Use to get a donut
         *                                          like effect.
         */
        'scope': {
            'data': '=',
            'height': '=',
            'width': '=',
            'innerRadius': '='
        },
        link: (scope, element) => {
            const THROTTLE_TIME = 300;
            const BORDER_PADDING = 1;
            const svgChart = d3.select(element[0])
                .append('svg')
                // Use scope height/width or default to height/width of container
                .attr('width', scope.height || '100%')
                .attr('height', scope.width || '100%');

            // Create a throttled version of the draw function
            scope.draw = _.throttle(_draw, THROTTLE_TIME);
            scope.draw();

            // on window resize, trigger a draw
            window.addEventListener('resize', scope.draw);

            // Redraw when height or width change
            scope.$watchGroup(['width', 'height', 'innerRadius'], scope.draw);

            // Redraw when data changes, not part of the $watchGroup as we do a deep check of the contents
            scope.$watch('data', scope.draw, true);

            /**
             * Function to render a pie chart of the directives data scope variable.
             * This function removes all child elements within the directives SVG and redraws the SVG. Then it calculates
             * height, width, and radius of the graph based on the parent SVG's height/width.
             */
            function _draw() {
                const data = scope.data;
                // remove all previous items before render
                svgChart.selectAll('*').remove();

                //  Get width & height of container and calc radius
                const width = svgChart.node().getBoundingClientRect().width;
                const height = svgChart.node().getBoundingClientRect().height;
                const radius = (Math.min(width, height) / 2) - BORDER_PADDING;

                // If no color is passed in for a slice, the color variable is a collection of 20 colors that will be used
                const color = d3.scaleOrdinal(d3.schemeCategory20b);

                /*  Add point which will be the center of the chart and translate it to
                 the center of the container */
                const svgCenter = svgChart.append('g')
                    .attr('transform', `translate(${width / 2},${(height) / 2})`);

                // generate piechart arc function and set radi
                const arc = d3.arc().outerRadius(radius)
                    .innerRadius(radius * (scope.innerRadius / 100 || 0));
                // Create function that will generate start/end angles for piechart data, null stops the array being sorted
                const pie = d3.pie().value((datapoint) => datapoint.value).sort(null);
                svgCenter.selectAll()
                // Use angle generator function to convert data to their start/end angles
                    .data(pie(data))
                    // Enter is a D3 function that allows data that doesn't already have a DOM element to be added.
                    .enter()
                    // path is the element for each pie chart segment
                    .append('path')
                    // d attribute uses the arc function to calculate co-ordinates of the segments boundary
                    .attr('d', arc)
                    // Sets segment color
                    .attr('fill', (d, i) => d.data.color || color(i))
                    // Sets segment opacity
                    .attr('fill-opacity', (d, i) => {
                        // Default to full opacity, otherwise we use the users which can be 0 (a falsey value)
                        let returnOpacity = 1;
                        const userOpacity = data[i].opacity;
                        // If user has specified an opacity, use that rather than default to 1
                        if (userOpacity !== undefined) {
                            returnOpacity = userOpacity;
                        }
                        return returnOpacity;
                    })
                    .on('click', (d) => {
                        // If there is a callback, wrap the call in an apply to trigger an angular digest
                        if (d.data.callback) {
                            scope.$apply(() => {
                                d.data.callback();
                            });
                        }
                    })

                    // Add optional CSS class. CSS has higher precendence to the attributes added above
                    .attr('class', (d, i) => data[i].class);
            }
        }
    })
];
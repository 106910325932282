import templateStr from 'text!./templates/billing-view-policies.html';
import ProductMediaMapUtil from 'gw-portals-util-js/ProductMediaMapUtil';
import _ from 'underscore';

export default () => {
    return {
        template: templateStr,
        transclude: true,
        scope: {
            cancelAction: '=',
            streamPolicies: '='
        },
        controller: ['$scope', 'ModalService', 'policy.PolicyService', ($scope, ModalService, PolicyService) => {

            initCtrl();
            $scope.getLobIcon = _getLobIcon;

            /**
             * Initialize scope data for the view
             */
            function initCtrl() {
                _loadPolicyInfo();
            }

            /**
             * Find the latest policy period from a list of policy periods
             * @param {*} policyPeriods - List of policy period objects
             * @returns {Object} - Object containing a summary of a policy period
             * @private
             */
            function _findLatestPolicyPeriod(policyPeriods) {
                return _.max(policyPeriods, (period) => {
                    return new Date(period.effective).getTime();
                });
            }

            /**
             * Get the font awesome icon associated with a line of business
             * @param {String} LobString -  PC/BC/CC string for a line of busines
             * @returns {String} - font-awesome class associated with the LobString
             * @private
             */
            function _getLobIcon(LobString) {
                return ProductMediaMapUtil.getPolicyTypeIcons(LobString);
            }

            /**
             *  Load the policy information associated with this account and filter based on the policies
             *  associated with the stream
             * @private
             */
            function _loadPolicyInfo() {
                // Load policy summaries associated with the user
                $scope.policyLoadState = PolicyService.getAccountPolicySummaries().then(
                    (policySummaries) => {
                        /*
                         *  Map over the policies associated with the invoice stream, find the summary
                         *  associated with the policy and then construct a summary object of the policy
                         */
                        let policyList = $scope.streamPolicies.map((policy) => {
                            // Find policy summary associated with the current policy
                            const summaryMatch = policySummaries.find(policySummary => {
                                return policySummary.periods[0].policyId === policy;
                            });
                            // If the summaryMatch is undefined, then this policy is not in force.
                            if (summaryMatch !== undefined) {
                                /*  Create summary object. At this point the policy number is know so that is the
                                 *  only field set.
                                 */
                                let returnObject = {
                                    policyNumber: policy
                                };
                                // Find the latest policy period
                                const latestPolicyPeriod = _findLatestPolicyPeriod(summaryMatch.periods);
                                if (latestPolicyPeriod) {
                                    // If a period was found, merge the summary date with the summary object
                                    returnObject = Object.assign(returnObject, {
                                        lines: latestPolicyPeriod.lines,
                                        premium: latestPolicyPeriod.premium,
                                        effective: latestPolicyPeriod.effective,
                                        expiration: latestPolicyPeriod.expiration
                                    });
                                }
                                return returnObject;
                            }
                            return undefined;
                        });

                        policyList = policyList.filter((policy) => {
                            return policy !== undefined;
                        });

                        if (policyList.length !== 0) {
                            $scope.viewPolicies = policyList;
                        }

                    },
                    () => {
                        /* If the policy summary data cannot be loaded, show the user an error message and
                         * return to the billing screen.
                         */
                        ModalService.showError(
                            'billing.controllers.BillingViewPolicies.Failed to load polices',
                            'billing.controllers.BillingViewPolicies.Sorry we can not load your policy information at this time'
                        )
                            .then(() => $scope.cancelAction());
                    }
                );
            }
        }]
    };
};
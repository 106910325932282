import template from 'text!./policy-docs.html';

const ADOBE_LINK = 'https://get.adobe.com/reader/';

export default [() => ({
    restrict: 'A',
    template: template,
    scope: {
        'documents': '<andPolicyDocs'
    },
    controller: ['$rootScope', '$scope', 'httpSettings', ($rootScope, $scope, httpSettings) => {
        const docDownloadURL = `${httpSettings.serviceBaseURLs.pc}doc/doc/policyDoc`;
        $scope.getDocDownloadURL = (doc) => `${docDownloadURL}/${doc.docUID}?token=${doc.sessionID}&name=${doc.name}&mimeType=${doc.mimeType}&jobId=${doc.jobID}&policyNumber=${doc.policyNumber}`;
        $scope.adobeDownloadURL = ADOBE_LINK;
    }]
})];
import Template from 'text!edge/policy/your-renewal_AND/views/your-renewal.html';
import Controller from 'edge/policy/your-renewal_AND/controllers/YourRenewalCtrl';

export default (RootStates) => {
    const parentState = RootStates.yourRenewal_AND;

    return {
        yourInsurance_AND: {
            name: 'yourRenewal_AND',
            parent: parentState,
            url: '/your-renewal?policyID',
            template: Template,
            controller: Controller
        }
    };
};

export default ['JsonRPCService', (JsonRPCService) => {
    return {
        getAccountBillingSummary: () => {
            return JsonRPCService.send('bc', 'billing/billingData', 'getAccountBillingSummary', []);
        },

        getAccountBillingDetails: () => {
            return JsonRPCService.send('bc', 'billing/billingData', 'getAccountBillingDetails', []);
        },

        getInvoiceStreamDetails: (invoiceStreamId) => {
            return JsonRPCService.send('bc', 'billing/billingData', 'getInvoiceStreamDetails', [invoiceStreamId]);
        },

        getPolicyPaymentSchedule: (policyNumber, months) => {
            return JsonRPCService.send('bc', 'billing/billingData', 'getPolicyPaymentSchedule', [policyNumber, months]);
        },

        getPaymentGroupPaymentInformation: (groupId) => {
            return JsonRPCService.send('bc', 'billing/billingData', 'getPaymentGroupPaymentInformation', [groupId]);
        },

        setPaymentGroupPaymentInformation: (groupId, instrument) => {
            return JsonRPCService.send('bc', 'billing/billingData', 'setPaymentGroupPaymentInformation', [groupId, instrument]);
        },

        getAccountInvoiceStreamInformation: () => {
            return JsonRPCService.send('bc', 'billing/billingData', 'getAccountInvoiceStreamInformation', []);
        }
    };
}];

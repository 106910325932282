import templateStr from 'text!./templates/billing-invoices.html';
import _ from 'underscore';

export default () => {
    return {
        template: templateStr,
        transclude: true,
        scope: {
            invoiceStream: '=',
            selectedInvoiceStream: '=',
            isAccountLevelBilling: '=',
            updateActions: '=',
            invoiceHeader: '=',
            getHeaderLabel: '&'
        },
        controller: ['$scope', 'DONUT_RADIUS_PERCENTAGE', ($scope, DONUT_RADIUS_PERCENTAGE) => {
            let pieChartConfig = getPieMemoize();
            $scope.donutInnerRadius = DONUT_RADIUS_PERCENTAGE;

            /**
             * Getting the translated label
             * @param {string} label
             * @returns {string|null}
             */
            $scope.getLabel = (label) => {
                return $scope.getHeaderLabel({label: label});
            };

            /**
             *  Update the pie chart data when parent controller alerts to a data change
             */
            $scope.updateActions.dataUpdated = () => {
                pieChartConfig = getPieMemoize();
            };

            /**
             * Set the current active payment filter, defaults to none if user selects the active filter again
             * @param {String} filter
             */
            $scope.setPaymentScheduleFilter = (filter) => {
                if (filter !== $scope.paymentScheduleFilter) {
                    $scope.paymentScheduleFilter = filter;
                } else {
                    $scope.paymentScheduleFilter = '';
                }
            };

            /**
             * Get the pie chart config
             * @returns {*} - Pie Chart config
             */
            $scope.getPieChartConfig = () => {
                if (pieChartConfig && $scope.selectedInvoiceStream) {
                    return pieChartConfig($scope.invoiceStream, $scope.paymentScheduleFilter);
                }
                return {};
            };

            /**
             * Get percentage of invoices paid
             * @returns {number}
             */
            $scope.getPaidPercentage = () => {
                return ($scope.invoiceStream.paidAmount.amount / $scope.invoiceStream.totalValue.amount) * 100.0;
            };

            /**
             * Get percentage of invoices that have not been paid
             * @returns {number}
             */
            $scope.getUnpaidPercentage = () => {
                return ($scope.invoiceStream.balance.amount / $scope.invoiceStream.totalValue.amount) * 100.0;
            };

            /**
             * Determine whether a tile is currently selected
             * @param {String} tileType - String - the type of the tile (paid|unpaid)
             * @returns {boolean}
             */
            $scope.isTileActive = (tileType) => {
                return $scope.paymentScheduleFilter === tileType;
            };

            /**
             * Function to generate the data config for the piechart. Caches the result to prevent an infinite digest error
             * in Angular
             * @returns {*} - returns function that caches the data for the pie chart
             */
            function getPieMemoize() {
                return _.memoize((stream, filter) => {
                    const due = 'gw-billing-pie-due';
                    const paid = 'gw-billing-pie-paid';
                    const dueActive = filter === 'unpaid' ? ` ${due}_active` : '';
                    const paidActive = filter === 'paid' ? ` ${paid}_active` : '';
                    return [{
                        value: stream.balance.amount,
                        class: due + dueActive,
                        callback: () => $scope.setPaymentScheduleFilter('unpaid')
                    }, {
                        value: stream.paidAmount.amount,
                        class: paid + paidActive,
                        callback: () => $scope.setPaymentScheduleFilter('paid')
                    }];
                }, _pieMemoizeHash);
            }

            /**
             * Hash function to generate the cache key for the pie chart data. Generated key is the params concatenated
             * @param {Object} streamId - The id for the current policy
             * @param {Object} paymentScheduleFilter - The current filter being applied to the invoices
             * @returns {String} - Unique key for the getPieMemoize cache
             * @private
             */
            function _pieMemoizeHash(streamId, paymentScheduleFilter) {
                return JSON.stringify(streamId) + JSON.stringify(paymentScheduleFilter);
            }

        }]
    };
};
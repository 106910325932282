import templateStr from 'text!./pm-quote-excess.html';

const WINDSCREEN_COVER = 'PMGlassCov';
const WINDSCREEN_REPAIR_EXCESS = 'PMGlassRepairExcess';
const WINDSCREEN_REPLACEMENT_EXCESS = 'PMGlassReplacementExcess';

export default ['$filter', ($filter) => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '='
        },
        template: templateStr,
        controller: ['$scope', ($scope) => {
            $scope.vehicle = $scope.quoteData.submission.lobData.personalMotor.value.coverables.pmVehicles[0];
            // Voluntary Excess
            $scope.volExcessVM = {
                value: $scope.vehicle.voluntaryExcess,
                aspects: {
                    required: true,
                    availableValues: $scope.vehicle.voluntaryExcessOptions,
                    get valid() {
                        return angular.isDefined($scope.volExcessVM.value) && $scope.volExcessVM.value !== null;
                    },
                    get validationMessages() {
                        if (this.valid === false) {
                            return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                        }
                        return [];
                    }
                }
            };
            $scope.$watch('volExcessVM.value', (newValue) => {
                if (angular.isDefined(newValue) && newValue !== $scope.vehicle.voluntaryExcess) {
                    $scope.quoteData.voluntaryExcess = newValue;
                    $scope.quoteData.ncdProtection = false;
                    $scope.quoteData.updateQuote()
                        .then(() => { // Update current scope value
                            $scope.vehicle.voluntaryExcess = $scope.quoteData.voluntaryExcess;
                            $scope.quoteData.setIsVolExcessChanged(true)
                        });
                }
            });

            $scope.driversComparator = ({value: d1}, {value: d2}) => {
                if (d1.isPolicyHolder) {
                    return -Number.MAX_SAFE_INTEGER;
                }
                return d1.fixedId - d2.fixedId;
            };

            $scope.getTotalExcess = (driver) => driver.compulsoryExcess + $scope.vehicle.voluntaryExcess;

            // Windscreen repair and replacement excess
            const glassCoverage = $scope.quoteData.baseOffering.lobs.personalMotor.pmVehicleCoverages[0].coverages
                .find((cov) => cov.codeIdentifier === WINDSCREEN_COVER);
            if (glassCoverage) {
                const repairExcess = glassCoverage.terms.find((term) => term.patternCode === WINDSCREEN_REPAIR_EXCESS);
                const replacementExcess = glassCoverage.terms.find((term) => term.patternCode === WINDSCREEN_REPLACEMENT_EXCESS);
                if (repairExcess) {
                    $scope.glassRepairExcess = repairExcess.chosenTermValue;
                }
                if (replacementExcess) {
                    $scope.glassReplacementExcess = replacementExcess.chosenTermValue;
                }
            }

            // Compulsory and Voluntary descriptions toggle. There should only be one displayed.
            $scope.toggleExcessDescription = (primary, secondary) => {
                $scope[primary] = !$scope[primary];
                if ($scope[primary] === true) {
                    $scope[secondary] = false;
                }
            };
        }]
    };
}];
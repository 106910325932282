import FlowUtil from 'edge/platform/flow/FlowUtil';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import commonFlowDefnFactory from 'edge/endorsement/common/config/FlowConfig';
import hoFlowDefnFactory from 'conditional?if=endorsement-products&equals=Homeowners!edge/endorsement/ho/config/FlowConfig';
import paFlowDefnFactory from 'conditional?if=endorsement-products&equals=PersonalAuto!edge/endorsement/pa/config/FlowConfig';
import pmFlowDefnFactory from 'conditional?if=endorsement-products&equals=PersonalMotor!edge/endorsement/pm/config/FlowConfig';
import LobUtil from 'integration/endorsement/LobUtil';

export default function ($q, EndorsementService, AvailabilityService) {


    const commonFlowDefn = commonFlowDefnFactory($q, EndorsementService);

    return function (step, junction, flow) {
        let hoFlow;
        let paFlow;
        let pmFlow;
        // create the common flow definition
        const startFlow = flow(commonFlowDefn);

        // entry step into endorsements flow
        const defineEntryNode = FlowUtil.isStepObject(startFlow.entry) ? step : junction;
        defineEntryNode(startFlow.entry, true);

        // if the homeowners feature is enabled then create the HO inner flow definition
        if (ApplicationFeatures.hasFeature('endorsement-products.Homeowners')) {
            const hoFlowDefn = hoFlowDefnFactory($q, EndorsementService);
            hoFlow = flow(hoFlowDefn);
        }
        // if the personalauto feature is enabled then create the PA inner flow definition
        if (ApplicationFeatures.hasFeature('endorsement-products.PersonalAuto')) {
            const paFlowDefn = paFlowDefnFactory($q, EndorsementService, AvailabilityService);
            paFlow = flow(paFlowDefn);
        }
        if (ApplicationFeatures.hasFeature('endorsement-products.PersonalMotor')) { // ANDIE
            const pmFlowDefn = pmFlowDefnFactory($q, EndorsementService, AvailabilityService);
            pmFlow = flow(pmFlowDefn);
        }
        junction('Fin').onNext.branch((model) => {
            if (model && model.policyChange) {
                if (hoFlow && LobUtil.isHomeowners(model.policyChange.baseData.productCode)) {
                    return hoFlow.entry;
                } else if (paFlow && LobUtil.isPA(model.policyChange.baseData.productCode)) {
                    return paFlow.entry;
                } else if (pmFlow && LobUtil.isPm(model.policyChange.baseData.productCode)) { // ANDIE
                    return pmFlow.entry;
                }
                return 'endorsementError';
            }
        });
        if (hoFlow) {
            // Hook HO error junction to the common error state
            hoFlow.exits.HOendorsementError.onNext.goTo(startFlow.exits.endorsementError);
        }
        if (paFlow) {
            // Hook PA error junction to the common error state
            paFlow.exits.PAendorsementError.onNext.goTo(startFlow.exits.endorsementError);
        }
        if (pmFlow) { // ANDIE
            // Hook Pm error junction to the common error state
            pmFlow.exits.PmEndorsementError.onNext.goTo(startFlow.exits.endorsementError);
        }
    };
}

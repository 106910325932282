import 'edge/endorsement/common/models/PolicyChange';
import GenericProductCodeUtil from 'integration/endorsement/GenericProductCodeUtil';
import PAPolicyChangeDataExtension from 'conditional?if=endorsement-products&equals=PersonalAuto!edge/endorsement/pa/models/PAPolicyChangeDataExtension';
import HOPolicyChangeDataExtension from 'conditional?if=endorsement-products&equals=Homeowners!edge/endorsement/ho/models/HOPolicyChangeDataExtension';
import BOPPolicyChangeDataExtension from 'conditional?if=endorsement-products&equals=BusinessOwners!edge/gw-portals-policytransaction-pc-bop-js/models/BOPPolicyChangeDataExtension';
import CPPolicyChangeDataExtension from 'conditional?if=endorsement-products&equals=CommercialProperty!edge/gw-portals-policytransaction-pc-cp-js/models/CpPolicyChangeDataExtension';
import PmPolicyChangeDataExtension from 'conditional?if=endorsement-products&equals=PersonalMotor!edge/endorsement/pm/models/PmPolicyChangeDataExtension'; // ANDIE

export default {
    mixinLobDraftDataExtensions: (policyChange) => {
        policyChange.lobData = policyChange.lobData || {};
        switch (GenericProductCodeUtil.getGenericProductCode(policyChange.baseData.productCode)) {
            case 'PersonalAuto':
                policyChange.lobData.personalAuto = new PAPolicyChangeDataExtension(policyChange);
                break;
            case 'Homeowners':
                policyChange.lobData.homeowners = new HOPolicyChangeDataExtension(policyChange);
                break;
            case 'BusinessOwners':
                policyChange.lobData.businessOwners = new BOPPolicyChangeDataExtension(policyChange);
                break;
            case 'CommercialProperty':
                policyChange.lobData.commercialProperty = new CPPolicyChangeDataExtension(policyChange);
                break;
            case 'PersonalMotor': // ANDIE
                policyChange.lobData.personalMotor = new PmPolicyChangeDataExtension(policyChange);
                break;
            case 'TraditionalMotor': // ANDIE
                policyChange.lobData.personalMotor = new PmPolicyChangeDataExtension(policyChange);
                break;
            case 'CommercialVehicle': // ANDIE
                policyChange.lobData.personalMotor = new PmPolicyChangeDataExtension(policyChange);
                break;
            default:
                throw new Error(`Product code ${policyChange.baseData.productCode} does not match list of supported lines`);
        }
    }
};
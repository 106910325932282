import templateStr from 'text!./templates/billing-summary-tiles.html';
import InvoiceStatistics from 'edge/billing/util/InvoiceStatistics';
import _ from 'underscore';

export default [() => {
    function controller($scope) {
        $scope.overdue = () => {
            if ($scope.billingSummary) {
                const res = InvoiceStatistics.aggregateBilling(_.filter(
                    $scope.billingSummary.activeInvoices,
                    InvoiceStatistics.isOverdue
                ));
                $scope.hasOverdue = !!(res);
                return res;
            }
        };

        $scope.current = () => {
            if ($scope.billingSummary) {
                const res = InvoiceStatistics.aggregateBilling(_.filter(
                    $scope.billingSummary.activeInvoices,
                    InvoiceStatistics.isBilled
                ));
                $scope.hasCurrent = !!(res);
                return res;
            }
        };

        $scope.balance = () => {
            const overdue = $scope.overdue();
            const current = $scope.current();
            if (overdue && current) {
                $scope.hasBalance = true;
                return {
                    'amount': current.amountDue.amount + overdue.amountDue.amount,
                    'currency': current.amountDue.currency
                };
            } else if (overdue || current) {
                $scope.hasBalance = true;
                return (overdue || current).amountDue;
            }
            $scope.hasBalance = false;
        };
    }

    return {
        restrict: 'A',
        template: templateStr,
        controller: ['$scope', controller],
        scope: {
            billingSummary: '=',
            isMultiAccount: '='
        }
    };
}];

import ClaimTypeView from 'text!../views/pm-claim-type.html';
import CollisionLossDetailsView from 'text!../views/pm-collision-loss-details.html';
import CollisionVehiclesView from 'text!../views/pm-collision-vehicles.html';
import RepairChoiceView from 'text!../views/pm-repair-choice.html';
import NewRepairFacilityView from 'text!../views/pm-new-repair-facility.html';
import PreferredRepairFacilityView from 'text!../views/pm-preferred-repair-facility.html';
import PmBaseFnolWizardCtrl from 'edge/fnol/pm/controllers/PmBaseFnolWizardCtrl';
import ClaimTypeCtrl from 'edge/fnol/pm/controllers/PmClaimTypeCtrl';
import CollisionDetailsCtrl from 'edge/fnol/pm/controllers/PmCollisionDetailsCtrl';
import CollisionVehiclesCtrl from 'edge/fnol/pm/controllers/PmCollisionVehiclesCtrl';
import RepairChoiceCtrl from 'edge/fnol/pm/controllers/PmRepairChoiceCtrl';
import NewRepairFacilityCtrl from 'edge/fnol/pm/controllers/PmNewRepairFacilityCtrl';
import PreferredRepairFacilityCtrl from 'edge/fnol/pm/controllers/PmPreferredRepairFacilityCtrl';
import loadGoogleMapsAPI from 'gw-portals-google-maps-js';

export default function () {
    const pmBaseFnolWizard = {
        name: 'pmBaseFnolWizard',
        parent: 'baseFnolWizard',
        abstract: true,
        controller: PmBaseFnolWizardCtrl,
        template: '<div ui-view></div>',
        data: {
            trackingUrl: ''
        }
    };

    loadGoogleMapsAPI();

    return {
        pmBaseFnolWizard: pmBaseFnolWizard,
        pmClaimType: {
            name: 'pmClaimType',
            parent: pmBaseFnolWizard,
            template: ClaimTypeView,
            controller: ClaimTypeCtrl
        },
        pmCollisionLossDetails: {
            name: 'pmCollisionLossDetails',
            parent: pmBaseFnolWizard,
            template: CollisionLossDetailsView,
            controller: CollisionDetailsCtrl
        },
        pmCollisionVehicles: {
            name: 'pmCollisionVehicles',
            parent: pmBaseFnolWizard,
            template: CollisionVehiclesView,
            controller: CollisionVehiclesCtrl
        },
        pmServiceChoice: {
            name: 'pmServiceChoice',
            parent: pmBaseFnolWizard,
            template: RepairChoiceView,
            controller: RepairChoiceCtrl
        },
        pmNewRepairFacility: {
            name: 'pmNewRepairFacility',
            parent: pmBaseFnolWizard,
            template: NewRepairFacilityView,
            controller: NewRepairFacilityCtrl
        },
        pmPreferredRepairFacility: {
            name: 'pmPreferredRepairFacility',
            parent: pmBaseFnolWizard,
            template: PreferredRepairFacilityView,
            controller: PreferredRepairFacilityCtrl
        }
    };
}

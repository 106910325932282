import template from 'text!./templates/pm-drivers.html';
import EndorsementStateUtils from 'edge/endorsement/common/utils/EndorsementStateUtils';

export default [() => ({
    template,
    'scope': {
        'mode': '=',
        'onNext': '&'
    },
    'controller': ['$scope', function ($scope) {
        $scope.next = function () {
            $scope.form.submitted = true;
            if ($scope.mode === 'full') {
                $scope.onNext();
            }
        };

        $scope.getState = EndorsementStateUtils.defineState($scope);
    }]
})];

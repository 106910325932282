import _ from 'underscore';
import BillingRouteConfig from 'edge/billing/config/RouteConfig';
import AccountBillingDetailsService from 'plugin?payment-instrument!edge/billing/services/AccountBillingDetailsService';
import AccountBillingSummary from 'edge/billing/directives/AccountBillingSummary';
import InvoiceSelect from 'edge/billing/directives/InvoiceSelect';
import PaymentLineInfo from 'edge/billing/directives/PaymentLineInfo';
import PaymentLinePoliciesSummary from 'edge/billing/directives/BillingLinePoliciesSummary';
import InvoicePaymentForm from 'edge/billing/directives/InvoicePaymentForm';
import PolicyPaymentSchedule from 'edge/billing/directives/PolicyPaymentSchedule';
import BillingSummaryActions from 'edge/billing/directives/BillingSummaryActions';
import MakeAPaymentForm from 'edge/billing/directives/MakeAPaymentForm';
import SetupAutomaticPaymentsForm from 'edge/billing/directives/SetupAutomaticPaymentsForm';
import ChangePaymentSourceForm from 'edge/billing/directives/ChangePaymentSourceForm';
import BillingViewPolicies from 'edge/billing/directives/BillingViewPolicies';
import BillingInvoices from 'edge/billing/directives/BillingInvoices';
import 'edge/platform/widgets/table/module';
import 'edge/platform/base/module';
import 'edge/platform/widgets/inputs/module';
import 'edge/platform/widgets/modal/module';
import 'edge/platform/paymentdetails/module';
import 'edge/platform/widgets/datetimepicker/module';
import 'edge/platform/widgets/accordion/module';
import 'angularRouter';
import 'portalConfig';
import 'plugin?payment-instrument!integration/billing/IntegrationStub';
import 'gw-portals-viewmodel-angular/aspects/module';
import 'edge/platform/widgets/basicinputs/module';
import 'edge/platform/charts/module';

export default angular.module('edge.billing.app', [
    'portal.config',
    'edge.platform.widgets.table',
    'ui.router',
    'edge.platform.base',
    'edge.platform.widgets.modal',
    'edge.platform.paymentdetails',
    'edge.platform.widgets.inputs',
    'integration.billing',
    'edge.platform.widgets.ui.bootstrap.datetimepicker',
    'edge.platform.widgets.ui.bootstrap.accordion',
    'gw-portals-viewmodel-angular.aspects',
    'edge.platform.widgets.basicinputs',
    'edge.platform.charts'
])

    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new BillingRouteConfig(RootStateConfig);
        _.values(states).forEach((state) => {
            $stateProvider.state(state);
        });
    }])

    .config(['EventHubProvider', EventHubProvider => {
        EventHubProvider.registerTransformer({
            source: {
                category: 'tracking',
                eventName: 'serverCall'
            },
            target: {
                category: 'journey',
                eventName: 'payment'
            },
            transformer(payload, send) {
                if (payload && payload.success && payload.path === 'billing/directBillPayment' && payload.method === 'makeDirectBillPayment') {
                    const paymentData = payload.params[0];
                    send({
                        invoiceIds: paymentData.invoiceIds,
                        amountPaid: paymentData.amountPaid,
                        paymentMethod: paymentData.paymentInstrument.paymentMethod
                    });
                }
            }
        });
    }])

    .factory('billing.AccountBillingDetailsService', AccountBillingDetailsService)

    .directive('gwBillingAccountBillingSummary', AccountBillingSummary)
    .directive('gwBillingInvoiceSelect', InvoiceSelect)
    .directive('gwBillingPaymentLineInfo', PaymentLineInfo)
    .directive('gwBillingInvoicePaymentForm', InvoicePaymentForm)
    .directive('gwBillingPaymentLinePoliciesSummary', PaymentLinePoliciesSummary)
    .directive('gwPolicyPaymentSchedule', PolicyPaymentSchedule)
    .directive('gwBillingSummaryActions', BillingSummaryActions)
    .directive('gwMakeAPaymentForm', MakeAPaymentForm)
    .directive('gwBillingViewPolicies', BillingViewPolicies)
    .directive('gwSetupAutomaticPaymentsForm', SetupAutomaticPaymentsForm)
    .directive('gwBillingInvoices', BillingInvoices)
    .directive('gwChangePaymentSourceForm', ChangePaymentSourceForm)
    .value('PREV_MONTHS_SHOWN', 12)
    .value('DONUT_RADIUS_PERCENTAGE', 0.60);

import allowedPolicyTypes from 'gw-portals-featuredetection-js/AllowedPolicyTypes';

const fn = ($q, JsonRPCService) => {
    return {
        searchPolicies: (policySearchCriteria) => {
            const availablePolicies = JsonRPCService.send('cc', 'fnol/policy', 'searchPolicies', [policySearchCriteria]);
            const allowedPolicies = $q.defer();

            availablePolicies.then(
                (policies) => {
                    const filteredPolicies = policies.filter((policy) => allowedPolicyTypes.names.includes(policy.policyType));
                    allowedPolicies.resolve(filteredPolicies);
                },
                (err) => {
                    allowedPolicies.reject(err);
                }
            );
            return allowedPolicies.promise;
        },
        getPolicySummaryRiskUnits: (policyNumber, lossDate) => {
            return JsonRPCService.send('cc', 'fnol/policy', 'getPolicySummaryRiskUnits', [policyNumber, lossDate]);
        }
    };
};
fn.$inject = ['$q', 'JsonRPCService'];

export default fn;

// import defaultModeTemplate from 'text!./templates/name-details-default.html';
// import japanModeTemplate from 'text!./templates/name-details-JP.html';
import ModeTemplate_AND from 'text!./templates/name-details_AND.html'; // ANDIE

export default ['LocaleService', (LocaleService) => {
    const defaultHiddenFields = ['Suffix', 'MiddleName', 'Particle']; // ANDIE
    const nameConfig = LocaleService.getNameConfig();
    return {
        restrict: 'A',
        template: ModeTemplate_AND, // ANDIE
        scope: {
            contact: '=', // if this val is changed in view then it changes in the model
            readonly: '=', // if this val is changed in view it DOES NOT change in the model
            nameLabelPrefix: '@'
        },

        link: (scope) => {
            scope.prefixTypeLists = scope.contact.title_itb.aspects.availableValues[0].typelist.filters
            scope.standardPrefixes = scope.prefixTypeLists.find(obj => {
                return obj.name === 'standardPrefix_itb'
              })
            scope.hiddenFields = defaultHiddenFields;
            scope.nameLabelPrefix = scope.nameLabelPrefix || 'platform.inputs.contact-details.';
            scope.isFieldVisible = (fieldName) => {
                const visible = nameConfig.visibleFields.indexOf(fieldName) >= 0;
                const hidden = scope.hiddenFields.indexOf(fieldName) >= 0;
                return visible && !hidden;
            };
        }

    };
}];
import templateStr from 'text!./regulatory-statement.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {},
        controller: ['$scope', '$rootScope', 'StateUtils', ($scope, $rootScope, StateUtils) => {
            $scope.content = 'and.topupmiles.directives.regulatory-statement.Description';
            if (StateUtils.activeDataForState(true)) {
                const model = StateUtils.activeDataForState(true).model;
                if ((model && model.value.policyEffDateBeforeAndUk() !== true) || $rootScope.policyContext.policyEffDateBeforeAndUk() !== true) {
                    $scope.content = 'and.topupmiles.directives.regulatory-statement.Description.ANDUK';
                }
            }
        }]
    };
}];
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

const fn = ($scope, $state, $filter, EndorsementService, ModalService, ViewModelService, paymentType) => {
    function checkForUnpaidInvoices() {
        EndorsementService.getPolicyBillingSummary($scope.policyChange.policyNumber).then((policyBillingSummary) => {
            if (policyBillingSummary.unpaidInvoices && policyBillingSummary.unpaidInvoices.length > 0) {
                $scope.unpaidInvoicesExist = true;
            }
        }, (err) => {
            console.error('Error checking policy details', err);
        });
    }

    function bind() {
        switch ($scope.paymentDetails.paymentMethod) {
            case paymentType.WIRE:
                delete $scope.paymentDetails.creditCardData;
                break;
            case paymentType.CREDIT_CARD:
                delete $scope.paymentDetails.bankAccountData;
                break;
            case paymentType.REDISTRIBUTE:
                delete $scope.paymentDetails.creditCardData;
                delete $scope.paymentDetails.bankAccountData;
                break;
            default:
                delete $scope.paymentDetails.creditCardData;
                delete $scope.paymentDetails.bankAccountData;
        }

        $scope.bindEndorsementPromise = EndorsementService.bind($scope.policyChange.jobID, $scope.paymentDetails).then((policyBindDTO) => {
            ModalService.showInfo(
                'endorsement.pa.controllers.PAHistoryCtrl.Bound title',
                'endorsement.pa.controllers.PAHistoryCtrl.Bound message'
            );
            $state.go('changeBound', {
                changesAppliedForward: policyBindDTO.changesAppliedForward,
                policyNumber: $scope.policyChange.policyNumber
            });
        }, (err) => {
            console.error('Error binding policy change', err);
            $state.go('endorsContactAgent', {
                policyNumber: $scope.policyChange.policyNumber
            });
        });
    }

    $scope.policyChange = $state.params.policyChange;

    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        const gatewayEndorsementEnabled = ApplicationFeatures.getAllFeatures().gatewayendorsement === 'true';
        $scope.backButtonLabel = 'endorsement.common.views.contact-agent.Back to Home';

        if (gatewayEndorsementEnabled) {
            $scope.backButtonLabel = 'endorsement.common.views.contact-agent.Back to Policy';
        }

        $scope.unpaidInvoicesExist = false;
        checkForUnpaidInvoices();

        $scope.paymentDetailsEnabled = false;
        $scope.paymentDetails = {
            creditCardData: {},
            bankAccountData: {}
        };

        $scope.paymentType = paymentType;

        $scope.paymentView = ViewModelService.create(
            $scope.paymentDetails, 'pc',
            'edge.capabilities.policyjob.binding.dto.PaymentDetailsDTO'
        );
    };

    ctrl.gotBackToHomeState = () => {
        const nextState = $state.$current.data.actions.next;
        $state.go(nextState, {
            policyNumber: $scope.policyChange.policyNumber
        });
    };

    ctrl.canPayInFull = () => {
        return $scope.policyChange.transactionCost.amount > 0;
    };

    ctrl.showPaymentDetails = () => {
        $scope.paymentDetails.paymentMethod = paymentType.WIRE;
        $scope.paymentDetailsEnabled = true;
    };

    ctrl.cancelPaymentDetails = () => {
        $scope.paymentDetailsEnabled = false;
    };

    ctrl.payInFull = (form) => {
        form.submitted = true;
        if (form.invalid) {
            return;
        }
        bind();
    };

    ctrl.canRedistribute = () => {
        return $scope.policyChange.transactionCost.amount > 0 && $scope.unpaidInvoicesExist;
    };

    ctrl.redistribute = () => {
        $scope.paymentDetails.paymentMethod = 'redistribute';
        bind();
    };

    ctrl.canConfirm = () => {
        return $scope.policyChange.transactionCost.amount <= 0;
    };

    ctrl.confirm = () => {
        $scope.paymentDetails.paymentMethod = 'none';
        bind();
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state', '$filter', 'endorsement.EndorsementService', 'ModalService', 'ViewModelService', 'paymentType'];

export default fn;

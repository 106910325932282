import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($filter, $scope, $stateParams, $translate, ModalService, StateUtils, submissionViewModel) => {
    const ctrl = Object.create(BaseCtrl);
    const flowModel = StateUtils.activeDataForState(true).flowModel;
    const submissionVm = submissionViewModel();
    const pmModel = submissionVm.lobData.personalMotor.value;
    const accountHolder = submissionVm.value.getAccountHolderPerson();

    ctrl.init = () => {
        if ($stateParams.addNamedDriver === true) {
            $scope.isAddingNewDriver = true;
        } else if ($stateParams.editNamedDriver) {
            $scope.isEditingDriver = true;
        }

        $scope.page = {
            drivers: submissionVm.lobData.personalMotor.coverables.pmDrivers,
            periodStartDate: submissionVm.baseData.periodStartDate.value,
            convictionCodes: submissionVm.lobData.personalMotor.coverables.convictionCodes.value.sort()
        };

        // Init driver
        let currentDriver;
        if (pmModel.coverables.pmDrivers.length === 0) { // If there are no drivers and this is the first visit, initialise a new driver
            currentDriver = pmModel.coverables.createDriver(accountHolder);
            currentDriver.copyFromAccountHolder(accountHolder); // Copy driver fields from Account Holder object to driver
            currentDriver.isPolicyHolder = true;
            currentDriver.mainDriver = true;
        } else if ($scope.isAddingNewDriver === true) {
            currentDriver = pmModel.coverables.createDriver();
            currentDriver.mainDriver = false;
        } else if ($scope.isEditingDriver === true) {
            currentDriver = pmModel.coverables.pmDrivers.find((driver)=> driver.publicID === $stateParams.editNamedDriver || driver.tempID === $stateParams.editNamedDriver);
        } else {
            currentDriver = pmModel.coverables.pmDrivers.find((driver)=> driver.isPolicyHolder === true);
        }
        $scope.page.currentDriver = submissionVm.lobData.personalMotor.coverables.pmDrivers.findFirstElement(currentDriver);
    };

    ctrl.undo = () => {
        flowModel.jumpToStep('pmDriverSummary', null, null, true);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$filter', '$scope', '$stateParams', '$translate', 'ModalService', 'StateUtils', 'submissionViewModel'];

export default fn;

import _ from 'underscore';

export default {
    /**
     * Checks if invoice is due.
     * @param {Object} invoice invoices
     * @returns {boolean} true iff invoice is due.
     */
    'isOverdue': (invoice) => {
        return invoice.invoiceStatus === 'due';
    },

    /**
     * Checks if invoice is billed.
     * @param {Object} invoice invoice to check.
     * @returns {boolean} true iff invoice is billed.
     */
    'isBilled': (invoice) => {
        return invoice.invoiceStatus === 'billed';
    },

    /**
     * Aggregates a billing information for invoices.
     * @param {Array} invoices invoices to aggregate.
     * @returns {{billingStats}} total aggregate information about that invoices.
     * Returns null if invoices are empty. Returned object have following fields:<ul>
     *     <li><em>amountDue</em> total amount due for all the invoices. (zero if invoices list is empty).
     *     <li><em>earliestDue</em> earliest "due" date for all the invoices. Set to null if invoices are empty
     * </ul>
     */
    'aggregateBilling': (invoices) => {
        if (invoices.length === 0) {
            return null;
        }

        let total = 0;
        let due = new Date(invoices[0].dueDate);

        _.each(invoices, (invoice) => {
            total += invoice.amountDue.amount;
            const newDue = new Date(invoice.dueDate);
            if (newDue.getTime() < due.getTime()) {
                due = newDue;
            }
        });

        return {
            amountDue: {
                amount: total,
                currency: invoices[0].amountDue.currency
            },
            earliestDue: due
        };
    }
};

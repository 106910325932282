import angular from 'angular';
import PersonalizedOffers from './components/PersonalizedOffers';
import RectangleOffer from './components/RectangleOffer';
import LeaderboardOffer from './components/LeaderboardOffer';
import HalfBannerOffer from './components/HalfBannerOffer';
import PersonalizedOffersService from './services/PersonalizedOffersService';
import 'portalConfig';

const modules = [
    'portal.config'
];

export default angular.module('edge.personalizedoffers.app', modules)
    .component('gwPersonalizedOffers', PersonalizedOffers)
    .component('gwRectangleOffer', RectangleOffer)
    .component('gwLeaderboardOffer', LeaderboardOffer)
    .component('gwHalfBannerOffer', HalfBannerOffer)
    .factory('PersonalizedOffersService', PersonalizedOffersService);


import templateStr from 'text!./rewards-confirmation.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            purchases: '<'
        }
    };
}];

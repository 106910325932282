import searchPopupTemplate from 'text!./partials/SearchPopup.html';

class RepairFacilitySelectorCtrl {
    constructor(MediaQueriesUtil, ModalService) {
        this.ModalService = ModalService;
        this.phoneLandscapeDownQuery = MediaQueriesUtil.getPhoneLandscapeDownQuery();
        this.mobileView = this.phoneLandscapeDownQuery.matches;
        this.mobileBreakpointListener = null;
        this.searchPopup = null;
        this.popupModel = {};
    }

    $onInit() {
        if (this.selectedRepairFacility) {
            this.handleMobilePopup(this.selectedRepairFacility);
        }
    }

    selectFacility(facility) {
        this.onFacilitySelect({facility});
        this.handleMobilePopup(facility);

        if (facility) {
            this.onSearchWaypointChange({coords: facility.primaryAddress.spatialPoint});
        }
    }

    handleMobilePopup(facility) {
        if (this.mobileView) {
            this.searchPopup = this.openSearchPopup(facility);
        }

        if (!this.mobileBreakpointListener) {
            this.mobileBreakpointListener = (event) => {
                if (event.matches) {
                    if (!this.searchPopup) {
                        this.searchPopup = this.openSearchPopup(facility);
                    }
                } else {
                    this.searchPopup.close();
                    this.searchPopup = null;
                }
            };
            this.phoneLandscapeDownQuery.addListener(this.mobileBreakpointListener);
        }
    }

    openSearchPopup(facility) {
        if (facility) {
            this.popupModel.selectVendor = Promise.resolve(facility);
            this.popupModel.enableSearch = false;
        } else {
            this.popupModel.selectVendor = null;
            this.popupModel.enableSearch = true;
        }

        this.popupModel.selectedFacility = facility;
        this.popupModel.repairFacilities = this.repairFacilities;
        this.popupModel.searchCenter = facility ?
            facility.primaryAddress.spatialPoint :
            this.searchCenter;
        this.popupModel.onSearchWaypointChange = this.onSearchWaypointChange;
        this.popupModel.onRepairFacilitiesRefresh = this.onRepairFacilitiesRefresh;
        this.popupModel.facilitySelected = this.onFacilitySelect;

        this.popupModel.policyLocation = this.policyLocation;
        this.popupModel.lossLocation = this.lossLocation;
        this.popupModel.myLocation = this.myLocation;
        this.popupModel.selectStaticAddress = this.selectStaticAddress;

        const modal = this.ModalService.showFullScreenPopup(searchPopupTemplate, this.popupModel, {fixedScroll: true});
        modal.result.then(() => { // set null when destroyed
            this.searchPopup = null;
        });
        return modal;
    }

    $onDestroy() {
        if (this.phoneLandscapeDownQuery && this.mobileBreakpointListener) {
            this.phoneLandscapeDownQuery.removeListener(this.mobileBreakpointListener);
        }
    }

    $onChanges({
        selectedRepairFacility, repairFacilities, policyLocation, lossLocation, myLocation, searchCenter
    }) {
        if (selectedRepairFacility && selectedRepairFacility.currentValue
            && selectedRepairFacility.currentValue !== selectedRepairFacility.previousValue) {
            this.popupModel.selectedFacility = selectedRepairFacility.currentValue;
        }

        if (repairFacilities && repairFacilities.currentValue
            && repairFacilities.currentValue !== repairFacilities.previousValue) {
            this.popupModel.repairFacilities = repairFacilities.currentValue;

            const sameAsSelectedRepairFacility = (facility) => facility.addressBookUID === this.selectedRepairFacility.addressBookUID;
            if (this.selectedRepairFacility && !repairFacilities.currentValue.some(sameAsSelectedRepairFacility)) {
                this.onFacilitySelect(null);
            }
        }

        if (policyLocation && policyLocation.currentValue &&
            policyLocation.currentValue !== policyLocation.previousValue) {
            this.popupModel.policyLocation = policyLocation.currentValue;
        }

        if (lossLocation && lossLocation.currentValue &&
            lossLocation.currentValue !== lossLocation.previousValue) {
            this.popupModel.lossLocation = lossLocation.currentValue;
        }

        if (myLocation && myLocation.currentValue &&
            myLocation.currentValue !== myLocation.previousValue) {
            this.popupModel.myLocation = myLocation.currentValue;
        }

        if (searchCenter && searchCenter.currentValue &&
            searchCenter.currentValue !== searchCenter.previousValue) {
            if (this.searchPopup) { // update changes for opened map in popup
                this.popupModel.searchCenter = searchCenter.currentValue;
            } else if (this.mobileView && searchCenter.previousValue) { // call to reload vendors after static location selected on mobile
                this.onRepairFacilitiesRefresh(searchCenter.currentValue, null);
            }
        }
    }
}

RepairFacilitySelectorCtrl.$inject = ['MediaQueriesUtil', 'ModalService'];
export default RepairFacilitySelectorCtrl;
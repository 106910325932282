import template from 'text!./templates/pm-coverages.html';
import EndorsementStateUtils from 'edge/endorsement/common/utils/EndorsementStateUtils';

export default [() => ({
    template,
    'scope': {
        'model': '=',
        'mode': '=',
        'onNext': '&',
        'onGo': '&'
    },
    'controller': ['$scope', 'endorsement.EndorsementService', function ($scope, EndorsementService) {
        $scope.next = $scope.onNext;
        $scope.loading = false;

        $scope.onCovChange = () => {
            $scope.loading = true;
            EndorsementService.updateCoverages($scope.model.jobID, $scope.model)
                .then(succ => {
                    $scope.model.lobData = succ.lobData;
                    $scope.loading = false;
                }, () => {
                    $scope.loading = false;
                });
        };

        $scope.toState = () => {
            if ($scope.mode === 'tile') {
                $scope.onGo();
            }
        };

        $scope.getState = EndorsementStateUtils.defineState($scope);
    }]
})];
import _ from 'lodash';

export default class CPClaimExtension {
    constructor(_claim) {
        Object.defineProperty(this, '_claim', {
            enumerable: false,
            value: _claim
        });

        const commercialProperty = this._claim.lobs.commercialProperty;

        this.incidentReport = commercialProperty && _.isBoolean(commercialProperty.incidentReport)
            ? commercialProperty.incidentReport
            : false;
        this.dateOfNotice = commercialProperty && commercialProperty.dateOfNotice
            ? commercialProperty.dateOfNotice
            : null;
        this.fixedPropertyIncidents = commercialProperty && commercialProperty.fixedPropertyIncidents
            ? commercialProperty.fixedPropertyIncidents
            : null;
    }
}

import BaseCtrl from 'edge/platform/base/BaseCtrl';

export function showInjuredOptions(claimVm) {
    const lobsWithInjuredDetails = ['InlandMarine', 'GeneralLiability', 'BusinessOwners'];
    return !lobsWithInjuredDetails.includes(claimVm.policy.policyType.get());
}

const fn = ($scope, httpSettings, $window, $q, claimViewModel, insuranceSuiteMetadata, ModalService, DocumentService, StateUtils, LocaleService, TranslateService, endpointConfig) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = function () {
        $scope.claimVm = claimViewModel();

        $scope.fnol.showInjuredOptions = showInjuredOptions($scope.claimVm);

        $scope.fnol.injuredOptions = [{
            key: true,
            _value: 'fnol.common.controllers.MoreInfoCtrl.Yes'
        }, {
            key: false,
            _value: 'fnol.common.controllers.MoreInfoCtrl.No'
        }];

        $scope.fnol.injuredOptions.forEach((injuredOption) => {
            TranslateService.translate({
                object: injuredOption,
                displayKey: injuredOption._value,
                propName: 'value',
                scope: $scope
            });
        });

        const typelist = insuranceSuiteMetadata.get('cc').types.getTypelist('ContactRole');
        $scope.fnol.involvementRole = ['witness', 'other'].map((key) => {
            return typelist.getCode(key);
        });

        $scope.claimVm.documents.value = $scope.claimVm.documents.value || [];
        $scope.phoneMask = LocaleService.getRegionSamplePhoneNumber().replace(/[0-8]/g, '9');
        $scope.removeFunction = (documentID) => DocumentService.removeDocument($scope.claimVm.claimNumber.value, documentID);
        $scope.metadataTemplate = {
            'DocUID': '001',
            'DocumentType': 'fnol',
            'SecurityType': 'unrestricted',
            'Status': 'approved',
            'Author': 'Policy Holder',
            'claimNumber': $scope.claimVm.claimNumber.value
        };

        $scope.claimCenterDownloadURI = httpSettings.serviceBaseURLs.cc + endpointConfig.downloadEndpoint;
        $scope.documentNotFoundURL = `${$window.location.pathname.substring(
            0,
            window.location.pathname.lastIndexOf('/')
        )}/ServiceRequestDocumentError`;
        $scope.uploadConfig = {
            metadataTemplate: $scope.metadataTemplate,
            documentNotFoundUrl: $scope.documentNotFoundURL,
            xcenterCenterRetrievalUrl: $scope.claimCenterDownloadURI,
            documentUpload: 'cc',
            serviceName: endpointConfig.uploadEndpoint,
            sessionServiceName: endpointConfig.sessionServiceName,
            // Adding random number at end to make the class unique (multiple uploads on the page)
            uploaderClass: `gw-claim-common-file-upload-button${Math.floor(Math.random() * 9999)}`
        };
        $scope.TranslateService = TranslateService;
    };

    ctrl.defineWatches = () => {
        const flowModel = StateUtils.activeDataForState(true).flowModel;
        $scope.$watch('claimVm.documents.value', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                const snapshot = flowModel.snapshot;
                snapshot.documents.value = newValue;
            }
        });
    };

    ctrl.addRelatedContact = (claimContact, $event) => {
        $scope.claimVm.value.addRelatedContact(claimContact);
        $event.preventDefault();
    };

    ctrl.removeRelatedContact = (contact) => {
        $scope.claimVm.value.removeRelatedContact(contact);
    };

    ctrl.isReadOnly = (contact) => {
        return contact.value && contact.policyRole.value;
    };

    ctrl.getRelatedPersons = () => {
        const availableRelatedContacts = $scope.claimVm.value.availableRelatedContacts();
        return availableRelatedContacts
            ? availableRelatedContacts.filter(contact => contact.subtype === 'Person' && contact.lastName)
            : [];
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'httpSettings', '$window', '$q', 'claimViewModel', 'InsuranceSuiteMetadata', 'ModalService', 'claim.DocumentService', 'StateUtils', 'LocaleService', 'TranslateService', 'claim.endpointConfig'];

export default fn;
import NavBarWizardBaseCtrl from 'edge/platform/widgets/wizard/NavBarWizardBaseCtrl';
import AuthenticationService from 'gw-portals-auth-js/AuthenticationService';

export default [
    '$scope', '$element', 'StateUtils', '$rootScope', '$state', 'oAuth', '$location', 'brandingData',
    function ($scope, $element, StateUtils, $rootScope, $state, oAuth, $location, brandingData) {

        const baseNavbarCtrl = new NavBarWizardBaseCtrl($scope, $element);
        const ctrl = Object.create(baseNavbarCtrl);
        const authenticationService = AuthenticationService(oAuth);

        ctrl.init = () => {
            $scope.isMobileNavigationOpen = false;
            $scope.brandingData = brandingData;

            StateUtils.beforeStateInit(ctrl._setQuoteSummaryData);
            StateUtils.afterStateInit(ctrl._checkIfSideMenuShouldBeClosed);
        };

        $scope.authenticated = () => $rootScope.authData;
        $scope.logout = () => authenticationService.logout();

        $scope.toggleMobileNavigation = () => {
            $scope.isMobileNavigationOpen = !$scope.isMobileNavigationOpen;
        };

        $scope.goToPage = (pageURL) => {
            $location.url(pageURL);
            // When in Mobile close all open accordions
            if ($scope.isMobileNavigationOpen === true) {
                Object.keys($rootScope.policyContext.frameAccordions) // Close all policyContext accordions
                    .forEach((key) => {
                        $rootScope.policyContext.frameAccordions[key].open = false;
                    });
            }
            $scope.isMobileNavigationOpen = false; // Close the mobile menu
        };

        $scope.activeTopTab = () => {
            const currentState = $state.$current.name;
            if (currentState.indexOf('home') === 0) {
                return 'home';
            }
            if ($state.includes('claim')) {
                return 'claims';
            }
            switch (currentState) {
                case 'ampusersprofile': {
                    return 'none';
                }
                case 'journey': {
                    return 'journey';
                }
                default: {
                    return currentState;
                }
            }
        };

        /* --- Quote summary dropdown methods --- */
        ctrl._setQuoteSummaryData = function () {
            if (StateUtils.hasState('baseTransactionWizard')) {
                $scope.submission = StateUtils.dataForState('baseTransactionWizard').flowModel.snapshot.value;
            } else {
                $scope.submission = null;
            }
        };

        ctrl._isQuoteWizardVisible = function () {
            return StateUtils.hasState('baseTransactionWizard');
        };

        ctrl._checkIfSideMenuShouldBeClosed = function () {
            // ANDIE - This is not applicable for andie current design
            /* if (!ctrl._isQuoteWizardVisible() && ctrl.isSideBarMenuVisible()) {
                ctrl.hideSideBarMenu();
            }*/
        };

        $scope.faqSearchQuery = '';
        $scope.goToFAQ = function () {
            const faqURL = `https://yourcommunity.tescobank.com/t5/forums/searchpage?q=${$scope.faqSearchQuery}&search_type=thread&filter=labels,includeTkbs&include_tkbs=true`;
            window.open(faqURL);
        };

        return ctrl.create($scope);
    }
];

import templateStr from 'text!./pm-drivers-mta.html';
import removeDriverConfirmation from 'text!../templates/pm-remove-driver-confirmation.html';
import _ from 'underscore';

const VIEWS = {
    SUMMARY: 'summary',
    PH_DRIVER: 'phDriver',
    NAMED_DRIVER: 'namedDriver',
    AMEND_DRIVER: 'amendDriver'
};

export default [() => {
    return {
        restrict: 'A',
        scope: {
            parentPage: '=page'
        },
        template: templateStr,
        controller: ['$rootScope', '$scope', 'ScrollService_AND', 'ModalService', ($rootScope, $scope, ScrollService_AND, ModalService) => {
            let numberOfDrivers;
            let phDriverSnapshot;
            let modalInstance = undefined;

            function goTo(view) {
                $scope.page.view = view;
                ScrollService_AND.scrollToTop(); // Scroll to top on each page change
            }

            function refreshPage() {
                Object.assign($scope.page, {
                    currentDriver: undefined,
                    driversVM: $scope.parentPage.policyChangeVM.lobData.personalMotor.coverables.pmDrivers,
                    policyholderVM: $scope.parentPage.policyChangeVM.lobData.personalMotor.coverables.pmDrivers
                        .findFirstElement($scope.parentPage.policyChangeVM.lobData.personalMotor.coverables.value.getPHDriver()),
                    periodStartDate: $scope.parentPage.policyChangeVM.baseData.periodStartDate.value
                });
                numberOfDrivers = $scope.page.driversVM.children.length;
            }

            $scope.canAddDriver = () => $scope.page.driversVM.length < 5; // Maximum is PH + 4 drivers

            $scope.addDriver = () => {
                const newDriver = $scope.parentPage.policyChangeVM.lobData.personalMotor.coverables.value.createDriver();
                newDriver.mainDriver = false;
                $scope.page.currentDriver = $scope.parentPage.policyChangeVM.lobData.personalMotor.coverables.pmDrivers.findFirstElement(newDriver);
                goTo(VIEWS.NAMED_DRIVER);
            };

            $scope.removeDriver = (scope) => {
                $scope.parentPage.policyChangeVM.lobData.personalMotor.coverables.value.removeDriver(scope.driver);
                modalInstance.close();
            };

            $scope.removeDriverDGT = (driver) => {
                $scope.parentPage.policyChangeVM.lobData.personalMotor.coverables.value.removeDriver(driver);
            };

            $scope.editPHDriver = () => {
                $scope.page.currentDriver = $scope.page.policyholderVM;
                phDriverSnapshot = angular.copy($scope.page.currentDriver.value);
                goTo(VIEWS.PH_DRIVER);
            };
            $scope.editDriver = (driverVM) => {
                $scope.page.currentDriver = driverVM;
                $scope.namedDriverSnapshot = $scope.parentPage.policyDetailsVM.lobs.personalMotor.drivers.children.find((d) => d.fixedId === driverVM.value.fixedId);
                if (!$scope.namedDriverSnapshot) {
                    $scope.namedDriverSnapshot = angular.copy(driverVM.value);
                }
                goTo(VIEWS.AMEND_DRIVER);
            };
            $scope.isMainDriver = (driverVM) => {
                $scope.page.currentDriver = driverVM;
                return $scope.page.currentDriver.value.mainDriver === true && !$scope.page.currentDriver.value.isPolicyHolder;
            };
            $scope.driverIsPolicyholder = (driverVM) => {
                $scope.page.currentDriver = driverVM;
                return $scope.page.currentDriver.value.isPolicyHolder;
            };

            $scope.showRemoveDriverConfirmation = (driver) => {
                modalInstance = ModalService.showSimpleDialog(removeDriverConfirmation, {
                    driver: driver
                }, {
                    removeDriver: $scope.removeDriver
                }, null, {
                    fullscreen: true,
                    backdrop: true,
                    windowClass: 'and-modal-confirmation'
                });
            };

            $scope.$watch(() => $scope.parentPage.policyChangeVM, (newValue, oldValue) => {
                if (newValue && newValue !== oldValue) {
                    refreshPage();
                }
            });

            // Init
            $scope.page = {
                view: VIEWS.SUMMARY,
                convictionCodes: $scope.parentPage.policyChangeVM.lobData.personalMotor.coverables.convictionCodes.value.sort(),
                goToNext: function () {
                    switch ($scope.page.view) {
                        case VIEWS.SUMMARY:
                            // Only need to save if the number of drivers changed.
                            // Driver details changes are saved in their edit pages.
                            if (numberOfDrivers !== $scope.parentPage.policyChangeVM.lobData.personalMotor.coverables.pmDrivers.children.length) {
                                return $scope.parentPage.saveAndGoToChangeSelection();
                            }
                            return $scope.parentPage.goToChangeSelection();
                        case VIEWS.PH_DRIVER:
                            if (phDriverSnapshot && _.isEqual(phDriverSnapshot, $scope.page.currentDriver.value)) {
                                return goTo(VIEWS.SUMMARY);
                            }
                            return $scope.parentPage.save().then(() => goTo(VIEWS.SUMMARY));
                        case VIEWS.AMEND_DRIVER:
                            if ($scope.namedDriverSnapshot && _.isEqual($scope.namedDriverSnapshot, $scope.page.currentDriver.value)) {
                                return goTo(VIEWS.SUMMARY);
                            }
                            return $scope.parentPage.save().then(() => goTo(VIEWS.SUMMARY));
                        case VIEWS.NAMED_DRIVER:
                            return $scope.parentPage.save().then(() => goTo(VIEWS.SUMMARY));
                        default:
                            return $scope.parentPage.goToCallSC();
                    }
                }
            };
            refreshPage();
            if ($scope.parentPage.initDriversOnPH === true) {
                $scope.editPHDriver();
                $scope.parentPage.initDriversOnPH = false;
            }
        }]
    };
}];
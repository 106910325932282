import BaseCtrl from 'edge/platform/base/BaseCtrl';
import 'text!../views/create-note.html';

const fn = ($filter, $scope, $state, $stateParams, ModalService, ClaimService, NavigationService, ViewModelService) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.noteBanner = $filter('translate')('claim.controllers.CreateNoteCtrl.Add Note');
        $scope.claimNumber = $stateParams.claimNumber;
        const note = {
            'subject': '',
            'body': ''
        };
        $scope.note = ViewModelService.create(note, 'cc', 'edge.capabilities.note.dto.NoteDTO');
    };

    const navHistoryObj = NavigationService.recentHistory();
    const previous = (navHistoryObj) ? navHistoryObj.from : null;

    ctrl.goToPrevious = () => {
        if (previous) {
            if (previous.params) {
                $state.transitionTo(previous.state.name, previous.params);
            } else {
                $state.transitionTo(previous.state.name);
            }
        }
    };

    $scope.canGoBack = previous !== null;


    ctrl.createNote = (claimNoteForm) => {
        claimNoteForm.submitted = true;

        if (claimNoteForm.invalid) {
            return;
        }
        const submitNotePromise = ClaimService.createClaimNote($scope.claimNumber, $scope.note.value);
        $scope.submitNotePromise = submitNotePromise;

        submitNotePromise.then(
            () => {
                $state.transitionTo($state.$current.data.actions.claimDetails, {
                    claimNumber: $scope.claimNumber
                });
            },
            (error) => {
                console.error('note creation failed', error);
                ModalService.showError(
                    'claim.controllers.CreateNoteCtrl.Claim update failed',
                    'claim.controllers.CreateNoteCtrl.Sorry, we were not able to add a new note to your claim.'
                );
            }
        );
    };

    ctrl.cancel = () => {
        $scope.note = {};
        $state.transitionTo($state.$current.data.actions.cancel, {
            claimNumber: $stateParams.claimNumber
        });
    };

    ctrl._cancel = () => {
        $scope.note = {};
        $state.transitionTo($state.$current.data.actions.cancel, {
            claimNumber: $stateParams.claimNumber
        });
    };

    return ctrl.create($scope);
};
fn.$inject = ['$filter', '$scope', '$state', '$stateParams', 'ModalService', 'claim.ClaimService', 'NavigationService', 'ViewModelService'];
export default fn;

import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./RepairFacilityRating.html';
import styles from './RepairFacilityRating-hashed.scss';
import controller from './RepairFacilityRatingCtrl';

export default {
    template: cssUtil.hashTemplate(template, styles),
    controller,
    bindings: {
        rate: '<',
        selected: '<'
    }
};

import _ from 'underscore';
import PaymentInstrument from './directives/PaymentInstrument';
import AutopaySummary from './directives/AutopaySummary';
import FlowStates from 'integration/billing/bc3/config/RouteConfig';
import PaymentMethodService from 'plugin?payment-instrument!integration/billing/bc3/services/PaymentMethodService';
import PaymentInstrumentService from './services/PaymentInstrumentService';

export default angular.module('integration.billing', [])
    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new FlowStates(RootStateConfig);
        _.values(states).forEach((state) => {
            $stateProvider.state(state);
        });
    }])

    .directive('gwIntPaymentInstrument', PaymentInstrument)
    .directive('gwIntAutopaySummary', AutopaySummary)
    .factory('integration.PaymentIntakeService', PaymentMethodService)
    .factory('integration.PaymentInstrumentService', PaymentInstrumentService);
import BaseCtrl from 'edge/platform/base/BaseCtrl';

const OPTIONS = [250, 500, 1000, 2000];

const fn = ($scope, $rootScope, $state, TopUpMilesService, ViewModelService, ScrollService_AND, brandingData) => {
    const ctrl = Object.create(BaseCtrl);
    $scope.brand = brandingData;
    console.log(brandingData)
    /**
     * @param {string} page page ID.
     * @param {boolean} showHeader if the common header should be shown.
     * @param {boolean} showSelectedOption if the copy for selected mileage option should be shown.
     * @param {boolean} turnOnWizard if wizard mode should be turned on.
      */
    const goTo = (page, showHeader = true, showSelectedOption = true, turnOnWizard = true) => {
        $scope.pageView = page;
        $scope.showHeader = showHeader;
        $scope.pageData.showSelectedOption = showSelectedOption;
        $scope.policyContext.isInTopUpWizard = turnOnWizard;
        ScrollService_AND.scrollToTop();
    };
    const goToSelectMileOptionPage = () => goTo('selectMileOptionPage', true, false, false);
    const goToFillCardDetailsPage = () => goTo('fillCardDetailsPage');
    const goToTopUpMilesPaymentPage = () => goTo('topUpMilesPaymentPage');
    const goToConfirmationPage = () => goTo('confirmationPage', false);
    const goToPaymentFailedScreen = () => goTo('paymentFailedPage', false);

    const callAndGoToNext = (promise, goToNextFn) => {
        $scope.pageData.inFlight = true;
        return promise
            .then(() => goToNextFn())
            .catch(() => $state.go('technicalError_AND'))
            .finally(() => { $scope.pageData.inFlight = false; });
    };

    const goToNextScreen = () => {
        switch ($scope.pageView) {
            case 'selectMileOptionPage':
                callAndGoToNext(TopUpMilesService.createTopUpMiles($rootScope.policyContext.currentPolicyNumber, $scope.pageData.selectedOption.number)
                    .then((jobNumber) => {
                        $scope.pageData.jobNumber = jobNumber;
                    }), goToFillCardDetailsPage);
                break;
            case 'fillCardDetailsPage':
                callAndGoToNext(TopUpMilesService.preBindTopUpMiles($scope.pageData.jobNumber, $scope.pageData.bindingData.value)
                    .then((sagePayURL) => {
                        $scope.pageData.sagePayURL = sagePayURL;
                    }), goToTopUpMilesPaymentPage);
                break;
            case 'topUpMilesPaymentPage':
                callAndGoToNext(TopUpMilesService.bindTopUpMiles($scope.pageData.jobNumber, $scope.pageData.bindingData.value), goToConfirmationPage);
                break;
            default:
                break;
        }
    };

    const initTopUpMilesPage = function () {
        $scope.pageData = {
            goToNextScreen, // Go to next screen in Top Up wizard
            goToPaymentFailedScreen, // Payment Failed
            inFlight: false, // Controls loaders
            showSelectedOption: false,
            selectedOption: null, // Selected mileage option
            topUpMileOptions: null,
            bindingData: ViewModelService.create({billingAddress: {}, paymentDetails: {creditCardData: {}}, BoxDeclaration_itb: true}, 'pc', 'edge.capabilities.policychange.binding.dto.BindDataDTO')
        };
    };

    ctrl.init = () => {
        $scope.$watch(() => $rootScope.policyContext.currentPolicyNumber, (newValue) => {
            if (newValue) {
                initTopUpMilesPage();
                callAndGoToNext(TopUpMilesService.calcTopUpMiles($rootScope.policyContext.currentPolicyNumber, OPTIONS)
                    .then((topUpMiles) => {
                        $scope.pageData.topUpMileOptions = topUpMiles.map(item => ({
                            number: item.milesRequested,
                            price: item.costOfMilesRequested
                        }));
                    }), goToSelectMileOptionPage);
            }
        });
    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$rootScope', '$state', 'TopUpMilesService', 'ViewModelService', 'ScrollService_AND', 'brandingData'];

export default fn;
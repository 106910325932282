import _ from 'lodash';

export default class RepairFacilityRatingCtrl {
    constructor() {
        this.MAX_RATE = 5;
        this.starsRange = _.range(1, this.MAX_RATE + 1);
    }

    getScaledRate() {
        return this.rate ? (this.rate / 100.0) * this.MAX_RATE : 0.0;
    }

    getNumericRate() {
        return this.getScaledRate().toFixed(1);
    }

    isActiveStar(starNumber) {
        return starNumber <= Math.round(this.getScaledRate());
    }
}
import PmSelectChanges from './directives/PmSelectChanges';
import PmFlow from './directives/PmFlow';
import PmAddressChange from './directives/PmAddressChange';
import PmAddressChangeEdit from './directives/PmAddressChangeEdit';
import PmDrivers from './directives/PmDrivers';
import PmDriversEdit from './directives/PmDriversEdit';
import PmVehicles from './directives/PmVehicles';
import PmVehiclesEdit from './directives/PmVehiclesEdit';
import PmCoverages from './directives/PmCoverages';
import PmCoveragesEdit from './directives/PmCoveragesEdit';
import PmPolicyChanges from './directives/PmPolicyChanges.js';
import PmEditDriver from './directives/PmPolicyChangeEditDriver';
import PmEditVehicle from './directives/PmPolicyChangeEditVehicle';
import PmVehicleMTA from './directives/pm-vehicle-mta/PmVehicleMTA';
import PmQuoteMTA from './directives/pm-quote-mta/PmQuoteMTA';
import PmCostBreakdownMTA from './directives/pm-cost-breakdown-mta/PmCostBreakdownMTA';
import PmHowToPayMTA from './directives/pm-how-to-pay-mta/PmHowToPayMTA';
import PmImportantInfoMTA from './directives/pm-important-info-mta/PmImportantInfoMTA';
import PmLegalPaymentMTA from './directives/pm-legal-payment-mta/PmLegalPaymentMTA';
import PmChangeOvernightAddressMTA from './directives/pm-change-overnight-address-mta/PmChangeOvernightAddressMTA';
import PmDriversMTA from './directives/pm-drivers-mta/PmDriversMTA';
import PmEditDriverMTA from './directives/pm-edit-driver-mta/PmEditDriverMTA';
import PmEditPHDriverMTA from './directives/pm-edit-ph-driver-mta/PmEditPHDriverMTA';
import PmQuoteExcessMTA from './directives/pm-quote-excess-mta/PmQuoteExcessMTA';
import PmQuoteReviewMTA from './directives/pm-quote-review-mta/PmQuoteReviewMTA';
import PmQuoteReviewCardMTA from './directives/pm-quote-review-mta/pm-quote-review-card-mta/PmQuoteReviewCardMTA';
import PmVehicleLookupMTA from './directives/pm-vehicle-lookup-mta/PmVehicleLookupMTA';
import PmDriverSummaryCardMTA from './directives/pm-driver-summary-card-mta/PmDriverSummaryCardMTA';
import PmAmendDriverMTA from './directives/pm-amend-driver-MTA_AND/PmAmendDriverMTA_AND';

import 'edge/platform/widgets/modal/module';
import 'edge/platform/widgets/inputs/module';
import 'edge/platform/widgets/loading/module';
import 'gw-portals-viewmodel-angular/aspects/module';
import 'edge/platform/widgets/basicinputs/module';
import 'edge/platform/flow/module';
import 'edge/platform/widgets/wizard/module';
import 'portalConfig';

export default angular.module('edge.endorsement.pm.app', ['portal.config', 'edge.platform.base', 'edge.platform.widgets.inputs', 'edge.platform.widgets.loadingMsg', 'edge.platform.widgets.modal', 'gw-portals-viewmodel-angular.aspects', 'edge.platform.widgets.basicinputs', 'edge.platform.flow', 'edge.platform.widgets.wizard']).directive('andEndorsementPmSelectChanges', PmSelectChanges).directive('andEndorsementPmFlow', PmFlow).directive('andEndorsementPmAddressChange', PmAddressChange).directive('andEndorsementPmAddressChangeEdit', PmAddressChangeEdit).directive('andEndorsementPmDrivers', PmDrivers).directive('andEndorsementPmDriversEdit', PmDriversEdit).directive('andEndorsementPmVehicles', PmVehicles).directive('andEndorsementPmVehiclesEdit', PmVehiclesEdit).directive('andEndorsementPmCoverages', PmCoverages).directive('andEndorsementPmCoveragesEdit', PmCoveragesEdit).directive('andEndorsementPmPolicyChanges', PmPolicyChanges).directive('andAmpPmPolicyChangeEditDriver', PmEditDriver).directive('andEndorsementPmEditVehicle', PmEditVehicle).directive('andPmVehicleMta', PmVehicleMTA).directive('andPmQuoteMta', PmQuoteMTA).directive('andPmCostBreakdownMta', PmCostBreakdownMTA).directive('andPmHowToPayMta', PmHowToPayMTA).directive('andPmImportantInfoMta', PmImportantInfoMTA).directive('andPmLegalPaymentMta', PmLegalPaymentMTA).directive('andPmChangeOvernightAddress', PmChangeOvernightAddressMTA).directive('andPmDriversMta', PmDriversMTA).directive('andPmEditDriverMta', PmEditDriverMTA).directive('andPmEditPhDriverMta', PmEditPHDriverMTA).directive('andPmQuoteExcessMta', PmQuoteExcessMTA).directive('andPmQuoteReviewMta', PmQuoteReviewMTA).directive('andPmQuoteReviewCardMta', PmQuoteReviewCardMTA).directive('andPmVehicleLookupMta', PmVehicleLookupMTA).directive('andPmDriverSummaryCardMta', PmDriverSummaryCardMTA).directive('andAmendPmDriversMta', PmAmendDriverMTA);
class MapSearchCtrl {
    constructor(GeoCodeService, $element, repairMapDelay) {
        if (!window.google || !window.google.maps) {
            throw new Error('google map api not found');
        }

        this.mapsApi = window.google.maps;
        this.geocode = GeoCodeService;
        // predefine search input element
        this.input = $element[0].children[0].children[0].children[0];
        this.delay = repairMapDelay;

        this.places = [];
        this.searchParam = '';

        this.showResults = false;
        this.inSearch = false;

        this.autocomplete = new this.mapsApi.places.AutocompleteService();
    }

    onSearchChange() {
        if (this.validForSearch()) {
            this.autocomplete.getPlacePredictions(
                {
                    bounds: this.mapInstance.getBounds(),
                    input: this.searchParam,
                },
                (predictions, status) => {
                    this.setPlaces(predictions, status);
                }
            );
        } else {
            this.places = [];
        }
    }

    validForSearch() {
        return this.searchParam
            && this.searchParam.trim().length > 0;
    }

    setPlaces(predictions, status) {
        this.places = [];

        if (status !== this.mapsApi.places.PlacesServiceStatus.OK) {
            console.warn(`Places search is unavailable. ${status}`);
            return;
        }

        predictions.forEach((prediction) => {
            this.places.push(prediction);
        });

        this.input.blur();
        this.input.focus();
    }

    selectPlace(prediction) {
        this.showResults = false;
        this.inSearch = false;
        this.places = [];
        this.searchParam = prediction.description;

        this.geocode.geocodePlace(prediction.place_id)
            .then((cords) => {
                this.changeMapCenter({cords});
            });
    }

    showList() {
        this.input.selectionEnd = this.input.value.length;
        this.input.selectionStart = this.input.selectionEnd;
        this.showResults = true;
    }

    hideList() {
        if (!this.inSearch) {
            this.showResults = false;
        }
    }

    selectSearchAddress(search) {
        if (search) {
            this.showResults = false;
            this.inSearch = false;

            setTimeout(() => {
                this.selectStaticAddress({search: search});
            }, this.delay);
        }
    }
}

MapSearchCtrl.$inject = ['GeoCodeService', '$element', 'repairMapDelay'];
export default MapSearchCtrl;
import templateStr from 'text!./payment-plans.html';

export default [() =>{
    return {
        restrict: 'AE',
        template: templateStr,
        scope: {
            quoteData: '<?',
        },
        controller: ['$scope', ($scope) => {

            const updatePaymentPlans = function () {
                $scope.annualPlanDetails = $scope.quoteData.submission.value.getAnnualPaymentPlan_AND();
                $scope.installmentPlanDetails = $scope.quoteData.submission.value.getInstallmentsPaymentPlan_AND();
                if ($scope.installmentPlanDetails) {
                    $scope.transactionFee = ($scope.installmentPlanDetails.fees_itb.amount).toFixed(2);
                }
            };

            $scope.$watch('quoteData.submission.value.bindData', updatePaymentPlans); // An add-on was selected
            $scope.$watch('quoteData.baseOffering', updatePaymentPlans); // Mileage option changed
        }]
    };
}];
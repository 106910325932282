import LossLocationMap from './LossLocationMap';

export default class MapCtrl {
    $onInit() {
        this.validateProps();
        setTimeout(() => this.initMap(), 0);
    }

    validateProps() {
        if (!this.mapName) {
            console.warn('You don\'t provide unique `mapName` property of map component. ' +
                'It may cause problems if you have more than one map component on page.');
        }
    }

    initMap() {
        if (window.google && window.google.maps) {
            this.map = new LossLocationMap({
                mapsApi: window.google.maps,
                coords: this.coords,
                onPositionChange: this.onPositionChange
            })
                .init(document.getElementById(this.mapName || 'googleMapsComponent'));
        } else {
            console.warn('Google Maps API not loaded.');
        }
    }
}
import templateStr from 'text!./policy-quote-excess-renewal.html';

export default [() => {
    return {
        restrict: 'AE',
        template: templateStr,
        scope: {
            data: '<'
        }
    };
}];
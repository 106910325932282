import templateStr from 'text!./templates/damage-list.html';

export default () => {
    return {
        restrict: 'A',
        template: templateStr,
        require: '^gwFnolPaVehicleCollision',
        scope: {},
        link: (scope, element, attrs, collisionVehiclesCtrl) => {
            scope.damagedParts = collisionVehiclesCtrl.damagedParts;

            scope.offDamage = (partID) => {
                collisionVehiclesCtrl.removeSelectedPart(partID);
            };
        }
    };
};

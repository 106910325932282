import templateStr from 'text!./pm-validation-summary.html';
import ValidationUtil_AND from 'gw-portals-util-js/ValidationUtil_AND'; // ANDIE

export default ['$timeout', 'StateUtils', 'ScrollService_AND', ($timeout, StateUtils, ScrollService_AND) => {
    return {
        restrict: 'A',
        scope: {},
        template: templateStr,
        link: (scope, element) => {
            scope.isVisible = false;

            scope.$on('andEnableValidationSummary', () => {
                scope.isVisible = true;
                const errors = ValidationUtil_AND.getInvalidFields(StateUtils.activeDataForState(true).model);
                console.debug(errors); // TODO ANDIE Should be removed for production
                scope.numberOfErrors = errors.length;
                $timeout(() => {
                    ScrollService_AND.scrollToElement(element, element.height());
                });
            });

            scope.$on('andDisableValidationSummary', () => {
                scope.isVisible = false;
            });
        }
    };
}];

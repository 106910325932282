import GenericProductCodeUtil from 'integration/endorsement/GenericProductCodeUtil';

export default {
    isHomeowners: (productCode) => {
        return GenericProductCodeUtil.getGenericProductCode(productCode) === 'Homeowners';
    },
    isPA: (productCode) => {
        return GenericProductCodeUtil.getGenericProductCode(productCode) === 'PersonalAuto';
    },
    isBOP: (productCode) => {
        return GenericProductCodeUtil.getGenericProductCode(productCode) === 'BusinessOwners';
    },
    isCP: (productCode) => {
        return GenericProductCodeUtil.getGenericProductCode(productCode) === 'CommercialProperty';
    },
    isPm: (productCode) => { // ANDIE
        return GenericProductCodeUtil.getGenericProductCode(productCode) === 'PersonalMotor';
    },
};
import templateStr from 'text!./templates/policy-vehicle-info.html';
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import _ from 'underscore';

const controllerCtr = ($scope, TranslateService) => {
    const ctrl = Object.create(BaseCtrl);

    _.each($scope.lineCoverages, (coverage) => {
        TranslateService.translate({
            object: coverage,
            displayKey: 'policy.pa.directives.policy-vehicle-info.Policy',
            propName: 'coverageType',
            scope: $scope
        });
    });
    _.each($scope.policyVehicles.coverages, (vehicle) => {
        TranslateService.translate({
            object: vehicle,
            displayKey: 'policy.pa.directives.policy-vehicle-info.Vehicle',
            propName: 'coverageType',
            scope: $scope
        });
    });
    _.each($scope.policyVehicles, (vehicle) => {
        vehicle.coverages = _.uniq($scope.lineCoverages.concat(vehicle.coverages));
    });


    return ctrl.create($scope);
};
controllerCtr.$inject = ['$scope', 'TranslateService'];


export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            'policyVehicles': '=',
            'lineCoverages': '=',
            'policyLob': '='
        },
        controller: controllerCtr
    };
}];

import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./PersonalizedOffers.html';
import controller from './PersonalizedOffersCtrl';
import styles from './PersonalizedOffers.scss';

export default {
    controller,
    template: cssUtil.hashTemplate(template, styles),
    transclude: true,
    bindings: {
        model: '=',
        currentPage: '=?'
    }
};

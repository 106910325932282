import style from './MapTypeControl-hashed.scss';

class MapTypeControlCtrl {
    constructor($element) {
        this.element = $element;

        if (window.google && window.google.maps) {
            this.mapsApi = window.google.maps;
        } else {
            throw new Error('google map api not found');
        }

        this.active = `${style.active}`;
    }

    $onInit() {
        this.mapLoaded.then(() => {
            const buttonContainer = this.element[0].children[0]; // obtain button container div from component
            this.roadBtn = buttonContainer.children[0];
            this.satelliteBtn = buttonContainer.children[1];
            this.mapInstance.controls[this.mapsApi.ControlPosition.BOTTOM_LEFT].push(buttonContainer);
        });
    }

    setRoadMap() {
        this.mapInstance.setMapTypeId(this.mapsApi.MapTypeId.ROADMAP);
        this.satelliteBtn.classList.remove(this.active);
        this.roadBtn.classList.add(this.active);
    }

    setSatelliteMap() {
        this.mapInstance.setMapTypeId(this.mapsApi.MapTypeId.SATELLITE);
        this.roadBtn.classList.remove(this.active);
        this.satelliteBtn.classList.add(this.active);
    }

}

MapTypeControlCtrl.$inject = ['$element'];
export default MapTypeControlCtrl;
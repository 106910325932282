import ApplicationFeatures from './ApplicationFeatures';

const names = {
    PersonalAuto: [], HomeOwners: [], GeneralLiability: [], BusinessOwners: [], InlandMarine: [], CommercialProperty: [], CommercialAuto: [], WorkersComp: [], PersonalMotor: [] // ANDIE
};

if (ApplicationFeatures.hasFeature('fnol-lob.pa')) {
    names.PersonalAuto = ['PersonalAuto', 'PersonalAutoLine', 'auto_per'];
}

if (ApplicationFeatures.hasFeature('fnol-lob.ho')) {
    names.HomeOwners = ['Homeowners', 'homeowners', 'HomeownersLine_HOE', 'HOPHomeowners', 'HOPLine', 'HOLine'];
}

if (ApplicationFeatures.hasFeature('fnol-lob.gl')) {
    names.GeneralLiability = ['GeneralLiability', 'GeneralLiabilityLine', 'GLLine'];
}

if (ApplicationFeatures.hasFeature('fnol-lob.bo')) {
    names.BusinessOwners = ['BusinessOwners', 'BusinessOwnersLine', 'BOPLine'];
}

if (ApplicationFeatures.hasFeature('fnol-lob.im')) {
    names.InlandMarine = ['InlandMarine', 'InlandMarineLine', 'IMLine'];
}

if (ApplicationFeatures.hasFeature('fnol-lob.cp')) {
    names.CommercialProperty = ['CommercialProperty', 'CommercialPropertyLine', 'CPLine'];
}

if (ApplicationFeatures.hasFeature('fnol-lob.ca')) {
    names.CommercialAuto = ['CommercialAuto', 'BusinessAuto', 'BusinessAutoLine'];
}

if (ApplicationFeatures.hasFeature('fnol-lob.wc')) {
    names.WorkersComp = ['WorkersComp', 'WorkersCompLine'];
}

if (ApplicationFeatures.hasFeature('fnol-lob.pm')) { // ANDIE
    names.PersonalMotor = ['PersonalMotor', 'personalMotor'];
}

const getAllowedNamesLiterals = () => Object.keys(names).reduce((namesLiterals, policyType) => namesLiterals.concat(names[policyType]), []);

export const policyTypesWhiteList = names;
export default {
    names: getAllowedNamesLiterals()
};

import template from 'text!./multiple-correspondence-address-banner.html';


export default [() => ({

    restrict: 'A',
    template: template,
    scope: {},
    controller: ['$rootScope', '$scope', ($rootScope, $scope) => {

        $scope.displayMultipleCorrAddBanner = () => {
            if ($rootScope.accountContext.accountHolderVM && $rootScope.accountContext.accountHolderVM.value.multipleCorrespondenceAddresses) {
                return true;
            }

        };
    }]
})];


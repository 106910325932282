import ComponentUtil from 'gw-portals-util-js/ComponentUtil';
import moment from 'moment';

let submissionVm;

class PAContactDetailsCtrl {

    constructor($scope, submissionViewModel, brandingData, PolicyService, $rootScope) {
        submissionVm = submissionViewModel();
        $scope.dealerRecords = null;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this._setCtrlParam = ComponentUtil.setCtrlParam(this, $scope);
        
        $scope.dealerVM = {
            value: null,
            aspects: null,
        };

        const urlParams = new URLSearchParams(window.location.search); 
        if (urlParams.has('referrer') && urlParams.get('referrer') === 'drivehome') {
            submissionVm.baseData.driveHomeEligible_itb.value = true;
        }        
        
        this._init($rootScope);
        $scope.clubCardNumberTooltip = {
            content: 'and.quoteandbind.pm.views.pm-contact-details.tooltip.ClubCard Number',
            trigger: 'click'
        };

        $scope.isBeforeANDUKStartDate = () => {
            return moment().isBefore(moment('20201201').startOf('day'));
        };

        if (submissionVm.bindData.accountNumber && submissionVm.bindData.autoRenew_itb.value === undefined) {
            $scope.autoRenew_itb = submissionVm.bindData.autoRenew_itb;
            $scope.autoRenew_itb.value = false; 
        } 

        $scope.contactPhone = submissionVm.bindData.contactPhone;
        $scope.boxDeclaration = submissionVm.bindData.boxDeclaration_itb; 
        if ($scope.contactPhone !== undefined) {
            if ($scope.contactPhone.value !== null && !$scope.contactPhone.aspects.valid) {
                $scope.contactPhone.value = '07000000000';
            }
        }

        if ($scope.boxDeclaration !== undefined) {
            if ($scope.boxDeclaration.value !== null && !$scope.boxDeclaration.aspects.valid) {
                $scope.boxDeclaration.value = true;
            }
        }

        // Academy ID
        $scope.academyIDTooltip = {
            content: 'and.quoteandbind.views.contact-details.info.AcademyID.tooltip',
            trigger: 'click'
        };
       
        $scope.$watch(() => submissionVm.baseData.marketingAnswer_itb.value, (newValue) => {
            // create a watch function to watch over marketing answer field on change
            if (newValue) {
                // newValue is the option chosen in th forms
                if (newValue.code.toLowerCase().includes('dealer')) {
                    // convert to lowercase so we dont get caught out with capitals
                    $scope.isDealerMarketing = true;
                    $scope.showCenterCode = true;
                    // Only call this function if it has not already been called.
                    const dealerList = [];
                    PolicyService.getActiveDealerRecords(brandingData)
                        .then((records) => {
                            records.forEach(record => {
                                dealerList.push(record);
                            });
                            $scope.dealerRecords = dealerList.sort((a, b) => {
                                return a.dealerName.toLowerCase().localeCompare(b.dealerName.toLowerCase());
                            });
                             $scope.dealerVM.aspects = $scope.dealerRecords;
                            if (submissionVm.baseData.activeDealerCode.value != null) {
                                if($scope.dealerRecords != null) {
                                    var dealerSelected = $scope.dealerRecords.find(dealer => dealer.centerNumber === submissionVm.baseData.activeDealerCode.value);
                                    if(dealerSelected) {
                                        $scope.activeDealerCodeSelected(dealerSelected);
                                    }
                                }
                            }
                        });
                    
                    // create this scope value and set to true, this will be used in the html
                } else {
                    $scope.isDealerMarketing = false;
                    $scope.showCenterCode = false;
                    // make sure we set to false in case they change it from dealer we dont want academy field to be shown
                    // TO DO - clear down academy field so were not sending anything to PC.
                    submissionVm.baseData.activeDealerCode = null;
                    $scope.showDealerRenewalDate = false;
                }
            }
        });

        $scope.$watch(() => submissionVm.baseData.activeDealerCode.value, (newValue) => {
            if (newValue && submissionVm.baseData.driveHomeEligible_itb.value) {
                $scope.showDealerRenewalDate = true; 
            } else {
                $scope.showDealerRenewalDate = false; 
            }
        });

        $scope.$watch(() => $scope.dealerVM.value, (newValue) => {
            if (newValue != undefined) {
                submissionVm.baseData.activeDealerCode.value = newValue.centerNumber;
            }
        });
        
        $scope.$watch(() => submissionVm.baseData.kwikFitStaffMember.value, (newValue) => {
            if (newValue != true) {
                submissionVm.baseData.kfistaffNumber_itb.value = "";
            }
        });
        
        $scope.$watch(() => submissionVm.baseData.kwikFitDiscount.value, (newValue) => {
            if (newValue != true) {
                submissionVm.baseData.kfidiscountCode_itb.value = "";
            }
        });

        /*  Where Did You Head About Us options */
        $scope.WhereDidYouHearAboutUsOptions = () => {
            if ($scope.productCode === 'TraditionalMotor' || $scope.productCode === 'CommercialVehicle') {
                this.$scope.wdyhauTypeLists = submissionVm.baseData.marketingAnswer_itb.aspects.availableValues[0].typelist.filters;

                if (brandingData === 'toy') {
                    return this.$scope.wdyhauTypeLists.find(obj => {
                        return obj.name === 'toyOptions';
                    });
                }
                if (brandingData === 'lex') {
                    return this.$scope.wdyhauTypeLists.find(obj => {
                        return obj.name === 'lexOptions';
                    });
                }
                if (brandingData === 'aut') {
                    return this.$scope.wdyhauTypeLists.find(obj => {
                        return obj.name === 'autOptions';
                    });
                }
                if (brandingData === 'kin') {
                    return this.$scope.wdyhauTypeLists.find(obj => {
                        return obj.name === 'kinOptions';
                    });
                }
                if (brandingData === 'kfi') {
                    return this.$scope.wdyhauTypeLists.find(obj => {
                        return obj.name === 'kfiOptions';
                    });
                }
                else {
                    return this.$scope.wdyhauTypeLists.find(obj => {
                        return obj.name === 'all';
                    });
                }
            }
        };

        $scope.activeDealerCodeSelected = function(code) {
            $scope.dealerVM.value = code;
        };

    }

    _init($rootScope) {
        this.baseDataViewModel = submissionVm.baseData;
        if (!this.baseDataViewModel.policyAddress.value) {
            this.baseDataViewModel.policyAddress.value = {};
        }

        this.minPeriodStartDate = submissionVm.value.getMinPeriodStartDate_AND();
        this.maxPeriodStartDate = submissionVm.value.getMaxPeriodStartDate_AND();
        this.maxRenewalPeriodStartDate = submissionVm.value.getMaxPeriodRenewalStartDate_AND();

        if ($rootScope.fromAMP_AND) {
            if(this.baseDataViewModel.kwikFitStaffMember.value == undefined) {
                this.baseDataViewModel.kwikFitStaffMember.value = false;
            }
        }

        const accountHolder = this.baseDataViewModel.accountHolder;
        this.next = function (callback) {
            // If there are drivers, copy account holder data from this page onto the PH Driver
            if (submissionVm.lobData.personalMotor.coverables.pmDrivers && submissionVm.lobData.personalMotor.coverables.pmDrivers.length > 0) {
                const accountHolderDriver = submissionVm.lobData.personalMotor.coverables.pmDrivers.value.filter((driver) => driver.isAccountHolder === true)[0];
                accountHolderDriver.copyFromAccountHolder(accountHolder.value);
            }
            callback(submissionVm);
        };
    }

}

PAContactDetailsCtrl.$inject = ['$scope', 'submissionViewModel', 'brandingData', 'qnb.PolicyService', '$rootScope'];

export default PAContactDetailsCtrl;
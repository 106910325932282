export default ['$scope', '$stateParams', 'journey.TimelineService', ($scope, $stateParams, TimelineService) => {

    let events = null;
    let timedOut = false;
    let errorMessage = false;
    $scope.loaded = false;

    Object.assign($scope, {
        timelineEvents() {
            return events;
        },
        timelineIsLoading() {
            return events === null;
        },
        hasTimedOut() {
            return timedOut;
        },
        hasError() {
            return errorMessage;
        }
    });

    TimelineService
        .getTimeline($stateParams.accountNumber)
        .then(res => {
            events = res;
        })
        .catch(err => {
            if (!err.isTimeoutAfterRetries) {
                $scope.loaded = true;
                errorMessage = err.message;
                return console.error(err);
            }
            timedOut = true;
        });
}];

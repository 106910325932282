import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./MapSearch.html';
import styles from './MapSearch-hashed.scss';
import controller from './MapSearchCtrl';

export default {
    template: cssUtil.hashTemplate(template, styles),
    bindings: {
        enabled: '<',
        myLocation: '<',
        mapInstance: '<',
        changeMapCenter: '&',
        selectStaticAddress: '&'
    },
    controller
};
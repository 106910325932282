import templateStr from 'text!./templates/pm-policy-change-edit-driver.html';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';
import DateUtil from 'gw-portals-util-js/DateUtil';

/**
 * Generates a list of years from a given year to the present
 * @param {Number} startYear initial year
 * @returns {Array} an array of years from 'startYear' to the current year.
 */
function listAvailableYears(startYear) {
    const years = [];
    const today = DateUtil.currentDate();
    const currentYear = DateUtil.getYear(today);

    for (let i = startYear; i <= currentYear; i++) {
        years.unshift(i);
    }
    return years;
}

export default [() => {
    const AgeAllowedToDrive = 16;
    return {
        restrict: 'A',
        // require: '^form',
        scope: {
            driver: '=',
            effectiveDate: '=',
            isNew: '='
        },
        template: templateStr,
        link: (scope) => {

            // Gender and YearLicensed are account level fields, committed on policy change save
            // They can't be edited for already existing drivers to prevent users tampering
            // currently enforce policies
            scope.genderEditable = scope.isNew || !scope.driver.person.gender.value;

            scope.dateOfBirthLimit = DateUtil.dateYearsAgo(scope.effectiveDate, AgeAllowedToDrive);
            scope.$watch('effectiveDate', (oldEffDate, newEffDate) => {
                if (oldEffDate !== newEffDate) {
                    scope.dateOfBirthLimit = DateUtil.dateYearsAgo(newEffDate, AgeAllowedToDrive);
                }
            });

            // Update allowed years for license when date of birth changes
            function setAllowedYears() {
                let dob = new Date(0);
                if (scope.driver.person.value.dateOfBirth) {
                    if (!angular.isDate(scope.driver.person.value.dateOfBirth)) {
                        dob = LocalDateUtil.toMidnightDate(scope.driver.person.value.dateOfBirth);
                    } else {
                        dob = scope.driver.person.value.dateOfBirth;
                    }
                }
                scope.years = listAvailableYears(dob.getUTCFullYear() + AgeAllowedToDrive);
            }

            setAllowedYears();
            scope.$watch('driver.person.value.dateOfBirth', (oldValue, newValue) => {
                if (oldValue !== newValue) {
                    setAllowedYears();
                }
            });
        },
        controller: ['$scope', ($scope) => {
            const today = DateUtil.currentDateAtMidnight();
            $scope.today = {
                year: DateUtil.getYear(today),
                month: DateUtil.getMonth(today),
                day: DateUtil.getDate(today)
            };
            $scope.maxDateOfBirth = {
                year: DateUtil.getYear(today) - AgeAllowedToDrive,
                month: DateUtil.getMonth(today),
                day: DateUtil.getDate(today)
            };
            $scope.minDateOfBirth = {
                year: DateUtil.getYear(today) - 100,
                month: DateUtil.getMonth(today),
                day: DateUtil.getDate(today)
            };
        }]
    };
}];

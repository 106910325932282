import templateStr from 'text!./contact-us-info-tbb.html';

export default [() => {
    return {
        restrict: 'AE',
        template: templateStr,
        scope: {
        },
        controller: ['$scope', '$location', '$rootScope', ($scope, $location, $rootScope) => {
            $scope.goToPage = (pageURL) => {
                $location.url(pageURL);
                // When in Mobile close all open accordions
                if ($scope.isMobileNavigationOpen === true) {
                    Object.keys($rootScope.policyContext.frameAccordions) // Close all policyContext accordions
                        .forEach((key) => {
                            $rootScope.policyContext.frameAccordions[key].open = false;
                        });
                }
                $scope.isMobileNavigationOpen = false; // Close the mobile menu
            };
            $scope.policyEffDateBeforeAndUk = $rootScope.policyContext.policyEffDateBeforeAndUk();
        }]
    };
}];
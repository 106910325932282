import templateStr from 'text!./templates/setup-automatic-payments-form.html';
import _ from 'underscore';

export default () => {
    return {
        template: templateStr,
        scope: {
            invoiceStream: '=',
            cancelAction: '&',
            setupAction: '&'
        },
        controller: ['$scope', 'integration.PaymentInstrumentService', ($scope, PaymentInstrumentService) => {

            init();

            /**
             * Calls the cancel pay action
             */
            $scope.cancelPay = () => {
                $scope.cancelAction();
            };

            /**
             * Prepares data for the setup automatic payments request. Delegates to parent controller
             * @param {Object} autopayForm
             */
            $scope.setupAutomaticPayment = (autopayForm) => {

                autopayForm.submitted = true;

                if (autopayForm.invalid || !PaymentInstrumentService.isValid($scope.paymentInstrument)) {
                    return;
                }
                const paymentInstrument = PaymentInstrumentService.toDto($scope.paymentInstrument);
                $scope.setupAction({
                    instrument: paymentInstrument,
                    startDate: $scope.firstPayment.dueDate
                });
            };

            function init() {
                if ($scope.invoiceStream !== null) {
                    $scope.paymentInstrument = PaymentInstrumentService.createInvoicePaymentInstrument();

                    const firstPayment = _.min(
                        _.filter($scope.invoiceStream.invoiceSummary, (invoice) => {
                            return invoice.paidStatus !== 'paid';
                        }),
                        (invoice) => {
                            return new Date(invoice.dueDate).getTime();
                        }
                    );

                    $scope.firstPayment = firstPayment === Infinity ? null : firstPayment; // _.min() returns Infinity for empty sets
                }
            }
        }]
    };
};
import templateStr from 'text!./templates/policy-driver-info.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            'policyDrivers': '='
        }
    };
}];

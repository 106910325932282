import _ from 'underscore';
import localeJson from 'app-locale';
import Address from 'edge/fnol/common/models/Address';
import EntityUtil from 'gw-portals-util-js/EntityUtil';

const nameConfig = localeJson.name;

function _append(sb, fieldId, value) {
    if (!_.isEmpty(value) && _.contains(nameConfig.visibleFields, fieldId)) {
        sb.push(value);
    }
}

function _format(person, delimiter) {
    const lastName = person.lastNameKanji || person.lastName;
    const firstName = person.firstNameKanji || person.firstName;
    const result = [];
    switch (nameConfig.formatMode) {
        case 'default':
            _append(result, 'FirstName', firstName);
            _append(result, 'LastName', lastName);

            break;
        case 'France':
            _append(result, 'LastName', lastName);
            _append(result, 'FirstName', firstName);
            break;
        case 'Japan':
            _append(result, 'LastName', lastName);
            _append(result, 'FirstName', firstName);
            break;
        case 'China':
            _append(result, 'LastName', lastName);
            _append(result, 'FirstName', firstName);
            break;
        default:
            break;
    }
    return result.join(delimiter);
}

function Contact(data) {
    this.subtype = 'Person';
    if (data) {
        _.extend(this, data);
    }
}

Contact.prototype.getDisplayName = function (defaultName, TranslateService) {
    return _format(this, ' ') || defaultName || TranslateService.instant('fnol.common.model.Unnamed');
};

Contact.prototype.initializePrimaryAddress = function (data) {
    const addressData = _.extend({}, data, {tempID: EntityUtil.nextId()});
    this.primaryAddress = new Address(addressData);
};

export default Contact;

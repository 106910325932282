import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./ResetPassword.html';
import styles from './ResetPassword-hashed.scss';
import ComponentUtil from 'gw-portals-util-js/ComponentUtil';
import AuthenticationService from 'plugin?auth-type!gw-portals-auth-js/AuthenticationService';

// VARS
const hashedTemplate = cssUtil.hashTemplate(template, styles);
const passRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

// EXPORT

export default {
    template: hashedTemplate,
    controller: [
        '$scope', '$state', 'oAuth', '$location',
        function ($scope, $state, oAuth, $location) {
            // VARS
            const authenticationService = AuthenticationService(oAuth);
            const $ctrl = this;
            const token = $state.params.token;
            let code;
            $ctrl.isResetReqInProgress = false;// animation

            $scope.newPwdTooltip = {
                content: 'and.auth.components.ResetPassword.Password Pattern',
                trigger: 'click'
            };

            // Default model attr required by gw-pl-inline-ctrl-group directive
            $ctrl.newPassword = {aspects: {}};
            $ctrl.confirmPassword = {aspects: {}};

            // METHODS
            const setCtrlParam = ComponentUtil.setCtrlParam($ctrl, $scope);

            function setErrorMessage(value) {
                setCtrlParam('errorMessage', value);
            }

            // Validate if token still valid
            authenticationService.verifyResetCode(token).then(data => {
                code = data.res;
                setCtrlParam('status', 'RESET_PASSWORD');
            }, () => {
                setCtrlParam('status', 'INVALID_TOKEN');
            });

            $ctrl.changePassword = () => {

                if (!$ctrl.newPassword.value) {
                    setErrorMessage('fieldEmpty');
                    return;
                }

                if ($ctrl.newPassword.value !== $ctrl.confirmPassword.value) {
                    setErrorMessage('fieldsIncorrect');
                    return;
                }

                // Check password pattern
                // Must be a minimum of 8 characters, contain at least 1 number, 1 uppercase and 1 lowercase character
                if (!passRegex.test($ctrl.newPassword.value)) {
                    setErrorMessage('passwordPatternMismatch');
                    return;
                }

                setErrorMessage('');
                setCtrlParam('isRequestInProgress', true);
                authenticationService.changePassword({
                    code,
                    new_password: $ctrl.newPassword.value
                }).then(() => {
                    setCtrlParam('status', 'RESET_PASSWORD_SUCCESS');
                }).catch((err) => {
                    switch (err.error) {
                        case authenticationService.ERRORS.tokens.invalidTokenOrEmail:
                            setCtrlParam('status', 'INVALID_TOKEN_OR_PASSWORD');
                            break;
                        default:
                            setCtrlParam('status', 'INVALID_ATTEMPT');
                            break;
                    }
                })
                    .finally(()=>{
                        setCtrlParam('isRequestInProgress', false);
                    });
            };

            $ctrl.backToSignIn = () => {
                const url = $location.absUrl();
                window.location.href = url.substring(0, url.indexOf('#'));
            };
        }
    ]
};
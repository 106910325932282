export default [
    // Any module may be specified any number of times, order here determines order shown
    {
        module: 'Twitter',
        configuration: {
            'dataSource': {
                sourceType: 'profile',
                screenName: 'drivelikeagirl'
            }, // widgetID deprecated https://twittercommunity.com/t/deprecating-widget-settings/102295 
            'tweetLimit': 3 // May be ommited or set to undefined for scrollable timeline
        }
    }
];
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import FormUtil from 'edge/platform/modelvalidation/FormUtil';

const fn = function (
    $scope, $stateParams, ModalService, QuoteEmailService, $q,
    qnbCompositeFlowDefn, returnStateFn, errorStateFn, effectiveDateStateFn,
    wizardFlowService, StateUtils, $filter, $state
) {
    const ctrl = Object.create(BaseCtrl);

    const flowModel = StateUtils.activeDataForState(true).flowModel;
    const submission = StateUtils.activeDataForState(true).model;
    flowModel.isAllStepsDisabled_AND = true;
    ctrl.emailAddress = submission.baseData.accountHolder.emailAddress1;
    ctrl.isEmailed = false;
    ctrl.quoteReference = $filter('translate')('and.quoteandbind.pm.views.QuoteSaved.Your quote reference is', { quoteID: submission.quoteID.value});

    ctrl.emailQuote = function () {
        FormUtil.markFormsSubmitted(ctrl.form);
        if (ctrl.emailAddress.aspects.valid) {
            const submissionValue = submission.value;
            const quoteID = submissionValue.quoteID;
            const sessionID = submissionValue.sessionUUID;
            const emailAddress1 = submissionValue.baseData.accountHolder.emailAddress1;
            const emailInfo = {
                quoteID,
                sessionID,
                emailAddress1
            };
            $q.when(QuoteEmailService.emailQuote(emailInfo), () => {
                ctrl.isEmailed = true;
            }, () => {
                ModalService.showError(
                    'quoteandbind.directives.QnbWizardButtons.Error',
                    'quoteandbind.directives.QnbWizardButtons.Sorry, we were not able to email the quote details at this time.'
                );
            });
        }
    };

    ctrl.return = () => {
        flowModel.isAllStepsDisabled_AND = false;
        $state.go(flowModel.currentStep.name);
    };

    ctrl.cancel = () => {
        const model = StateUtils.activeDataForState(true).model;
        flowModel.exitEmailSession_AND(model);
    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$stateParams', 'ModalService', 'qnb.EmailService', '$q', 'qnb.CompositeFlowDefn', 'qnb.returnStateFn',
    'qnb.errorStateFn', 'qnb.effectiveDateStateFn', 'WizardFlowService', 'StateUtils', '$filter', '$state'];

export default fn;
import templateStr from 'text!./accident-info.html';

export default [() => {
    return {
        restrict: 'A',
        scope: {},
        template: templateStr,
        controller: ['$scope', ($scope) => {
            $scope.isMoreInfoOpen = false;

            $scope.toggleMoreInfo = () => {
                $scope.isMoreInfoOpen = !$scope.isMoreInfoOpen;
            };
        }]
    };
}];
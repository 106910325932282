import templateStr from 'text!./change-marketing-pref.html';

export default ['$filter', '$rootScope', '$timeout', 'UserProfileService_AND','StaticDocumentsService_AND', 'ViewModelService', '$q',
    ($filter, $rootScope, $timeout, UserProfileService_AND, StaticDocumentsService_AND, ViewModelService, $q) => {
        const API_ERROR = $filter('translate')('and.directives.change-phone-numbers.APIError');

        return {
            restrict: 'A',
            template: templateStr,
            scope: {},
            controller: ['$scope', ($scope) => {
                $q.all([
                    StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_POLICY),
                    StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_NOTICE),
                ]).then((result) => {
                    $scope.privacyPolicyDoc = result[0];
                    $scope.privacyNoticeDoc = result[1];
                });
                const persistedValues = {};
                const setAPIError = (message) => {
                    $scope.apiError = message;
                };

                $scope.clearAPIMessage = () => setAPIError(null);
                $scope.clearSuccessMsg = () => {
                    $scope.isSuccessVisible = false;
                };

                $scope.marketingPrefOptIn_itb = {
                    value: undefined,
                };

                let initObject = {};
                if ($rootScope.accountContext && $rootScope.accountContext.accountHolderVM) {
                    initObject = {
                        marketingPrefEmail_itb: $rootScope.accountContext.accountHolderVM.marketingPrefEmail_itb.value,
                        marketingPrefSMS_itb: $rootScope.accountContext.accountHolderVM.marketingPrefSMS_itb.value,
                        marketingPrefVoiceMob_itb: $rootScope.accountContext.accountHolderVM.marketingPrefVoiceMob_itb.value,
                        marketingPrefLandline_itb: $rootScope.accountContext.accountHolderVM.marketingPrefLandline_itb.value,
                        marketingPrefPost_itb: $rootScope.accountContext.accountHolderVM.marketingPrefPost_itb.value,
                        marketingPrefMobileApp_itb: $rootScope.accountContext.accountHolderVM.marketingPrefMobileApp_itb.value,
                        marketingPrefOptOut_itb: $rootScope.accountContext.accountHolderVM.marketingPrefOptOut_itb.value
                    };
                }
                $scope.userProfileVM = ViewModelService.create(initObject, 'pc', 'itb.edge.capabilities.profileinfo.user.dto.UserProfileDTO', null);
                Object.assign(persistedValues, initObject);

                $scope.updateMarketingPrefOptIn = (data) => {
                    if (data.marketingPrefOptOut_itb === true) {
                        $scope.marketingPrefOptIn_itb.value = false;
                    } else {
                        $scope.marketingPrefOptIn_itb.value = true;
                    }
                };
                
                UserProfileService_AND.getAccountInfo().then((data) => {
                    $scope.updateMarketingPrefOptIn(data);
                });

                $scope.$watch('marketingPrefOptIn_itb.value', (oldValue, newValue) => {
                    if (newValue !== undefined && oldValue !== undefined) {
                        if (newValue === false) {
                            $scope.userProfileVM.marketingPrefOptOut_itb = false;
                            $scope.userProfileVM.marketingPrefEmail_itb = true;
                            $scope.userProfileVM.marketingPrefSMS_itb = true;
                            $scope.userProfileVM.marketingPrefVoiceMob_itb = true;
                            $scope.userProfileVM.marketingPrefLandline_itb = true;
                            $scope.userProfileVM.marketingPrefPost_itb = true;
                            $scope.userProfileVM.marketingPrefMobileApp_itb = true;
                        } else {
                            $scope.userProfileVM.marketingPrefOptOut_itb = true;
                            $scope.userProfileVM.marketingPrefEmail_itb = false;
                            $scope.userProfileVM.marketingPrefSMS_itb = false;
                            $scope.userProfileVM.marketingPrefVoiceMob_itb = false;
                            $scope.userProfileVM.marketingPrefLandline_itb = false;
                            $scope.userProfileVM.marketingPrefPost_itb = false;
                            $scope.userProfileVM.marketingPrefMobileApp_itb = false;
                        }
                    }
                });


                $scope.submit = (form) => {
                    form.submitted = true;
                    $scope.clearAPIMessage();
                    if (form.valid === false) {
                        return;
                    }
                    $scope.inFlight = true;
                    
                    return UserProfileService_AND.updateMarketingPreferenceNew($scope.userProfileVM.marketingPrefEmail_itb.value.toString(), $scope.userProfileVM.marketingPrefPost_itb.value.toString(), $scope.userProfileVM.marketingPrefMobileApp_itb.value.toString(), $scope.userProfileVM.marketingPrefLandline_itb.value.toString(), $scope.userProfileVM.marketingPrefVoiceMob_itb.value.toString(), $scope.userProfileVM.marketingPrefSMS_itb.value.toString(), $scope.userProfileVM.marketingPrefOptOut_itb.value.toString())
                        .then((data) => {
                            $rootScope.accountContext.accountHolderVM = ViewModelService.create(data, 'pc', 'itb.edge.capabilities.profileinfo.user.dto.UserProfileDTO', null);
                            $scope.isSuccessVisible = true;
                            $timeout(() => { // Clear success message after 3 seconds
                                $scope.clearSuccessMsg();
                                window.location.reload();
                            }, 3000);
                        })
                        .catch(() => {
                            setAPIError(API_ERROR);
                        })
                        .finally(() => {
                            $scope.inFlight = false;
                        });
                };

                $scope.radioButtonsPopulated = true;
                $scope.errorOccured = false;
                
                const getAccountInfo = async () => {
                    const data = await UserProfileService_AND.getAccountInfo();
                    if (data.marketingPrefOptOut_itb === undefined) {
                        $scope.$apply(() => {
                            $scope.radioButtonsPopulated = false;
                            $scope.errorOccured = true;
                            $scope.errorMessage = 'Please update your marketing preferences';
                        });
                        $scope.$watch('userProfileVM.marketingPrefOptIn_itb.value', (newValue) => { 
                            if (newValue === undefined || newValue === false) {
                                $scope.userProfileVM.marketingPrefOptOut_itb = false;
                                $scope.userProfileVM.marketingPrefEmail_itb = true;
                                $scope.userProfileVM.marketingPrefSMS_itb = true;
                                $scope.userProfileVM.marketingPrefVoiceMob_itb = true;
                                $scope.userProfileVM.marketingPrefLandline_itb = true;
                                $scope.userProfileVM.marketingPrefPost_itb = true;
                                $scope.userProfileVM.marketingPrefMobileApp_itb = true;
                            } else if (newValue === true) {
                                $scope.userProfileVM.marketingPrefOptOut_itb = true;
                                $scope.userProfileVM.marketingPrefEmail_itb = false;
                                $scope.userProfileVM.marketingPrefSMS_itb = false;
                                $scope.userProfileVM.marketingPrefVoiceMob_itb = false;
                                $scope.userProfileVM.marketingPrefLandline_itb = false;
                                $scope.userProfileVM.marketingPrefPost_itb = false;
                                $scope.userProfileVM.marketingPrefMobileApp_itb = false;
                            }
                        }); 
                        $scope.$watch('isSuccessVisible', (newValue) => {
                            if (newValue === true) {
                                $scope.radioButtonsPopulated = true;
                                $scope.errorOccured = false;
                            }
                        });                                  
                    } 
                     
                };
                getAccountInfo();
                
                
            }]
        };
    }];
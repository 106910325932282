import _ from 'lodash';

export default class VehicleIncident {

    constructor(claim, caClaimExtension, vehicleIncident) {
        Object.defineProperty(this, '_claim', {
            enumerable: false,
            value: claim
        });

        Object.defineProperty(this, '_caClaimExtension', {
            enumerable: false,
            value: caClaimExtension
        });

        if (vehicleIncident) {
            _.extend(this, vehicleIncident);
        } else {
            this.driver = null;
            this.vehicle = null;
            this.passengers = [];
        }
    }

    availableVehicles() {
        return this._caClaimExtension.availableVehicles(this.vehicle);
    }

    availableDrivers() {
        return this._caClaimExtension.availableDrivers(this.driver);
    }

    addPassenger(passenger) {
        if (!passenger) {
            passenger = this._claim.createContact();
        }
        this.passengers.unshift(passenger);
    }

    setNewDriver() {
        this.driver = this._claim.createContact();
    }

    setNewVehicle() {
        this.vehicle = this._caClaimExtension.createVehicle();
    }

    removePassenger(p) {
        const idx = this.passengers.indexOf(p);
        this.passengers.splice(idx, 1);
    }

    getAvailablePassengers() {
        return this._caClaimExtension.availablePassengers();
    }
}

import _ from 'lodash';
import EnrollmentStateConfig from './config/RouteConfig';
import EnrollmentService from './services/EnrollmentService';
import 'plugin?auth-type!gw-portals-auth-angular/module';
import SignupEnrollment from './components/SignupEnrollment';
import AdditionalEnrollment from './components/AdditionalEnrollment';
import 'gw-portals-viewmodel-angular/aspects/module';
import 'edge/platform/widgets/basicinputs/module';

export default angular.module('edge.enrollment.app', [
    'portal.config',
    'ui.router',
    'edge.platform.base',
    'gw-portals-viewmodel-angular.aspects',
    'edge.platform.widgets.basicinputs'
])
    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new EnrollmentStateConfig(RootStateConfig);
        _.values(states).forEach((state) => {
            $stateProvider.state(state);
        });
    }])

    .config(['EventHubProvider', EventHubProvider => {
        EventHubProvider.registerTransformer({
            source: {
                category: 'tracking',
                eventName: 'serverCall'
            },
            target: {
                category: 'journey',
                eventName: 'account'
            },
            transformer(payload, send) {
                if (payload && payload.success && payload.method === 'addEnrollmentRecord') {
                    send({
                        accountNumber: payload.params[0].accountNumber,
                        policyNumber: payload.params[0].details.policyNumber,
                        method: payload.method,
                        path: payload.path
                    });
                }
            }
        });
    }])

    .factory('enrollment.EnrollmentService', EnrollmentService)
    .component('gwSignupEnrollment', SignupEnrollment)
    .component('gwAdditionalEnrollment', AdditionalEnrollment)

    .run(['$rootScope', '$state', ($rootScope, $state) => {
        $rootScope.$on('$stateChangeError', (evt, to, toParams, from, fromParams, error) => {
            if (error.goToEnrollmentPage) {
                $state.go(error.goToEnrollmentPage);
            }
        });

        // ANDIE On state change verify if the state is restricted to editable policies and the current policy isn't
        // editable, in which case go to Your Insurance page.
        $rootScope.$on('$stateChangeSuccess', (evt, to) => {
            if (to.data && to.data.restrictedToEditablePolicies === true) {
                $rootScope.policyContext.getPolicies() // Need to ensure this call has finished
                    .finally(() => { // Even if it fails
                        // Restrict and go to YourInsurance if:
                        // 1. Current Policy is not edtable; and
                        // 2. Current Policy is not expired; and
                        // 3. There is an access control flag on the state and its value on Summary DTO is false.
                        if ($rootScope.policyContext.isCurrentPolicyNotEditable() && !$rootScope.policyContext.currentPolicy.expired &&
                        (to.data.summaryAccessControl && $rootScope.policyContext.currentPolicy[to.data.summaryAccessControl] !== true)) {
                            $state.go('yourInsurance_AND');                            
                        }
                    });
            }
        });
    }]);

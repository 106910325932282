const normalisedLobIcons = {
    PA: {
        image: 'personal-auto',
        font: 'fa-car'
    },
    HO: {
        image: 'home-owners',
        font: 'fa-home'
    },
    GL: {
        image: 'general-liability',
        font: 'fa-legal'
    },
    BO: {
        image: 'business-owners',
        font: 'fa-briefcase'
    },
    IM: {
        image: 'inland-marine',
        font: 'fa-ship'
    },
    CP: {
        image: 'commercial-property',
        font: 'fa-industry'
    },
    CA: {
        image: 'commercial-auto',
        font: 'fa-truck'
    },
    WC: {
        image: 'workers-comp',
        font: 'fa-cubes'
    },
    CPKG: {
        image: 'commercial-package',
        font: '<unknown>'
    },
    TRVL: {
        image: 'travel',
        font: 'fa-plane'
    },
    PUL: {
        image: 'no-icon',
        font: 'fa-umbrella'
    },
    PM: { // ANDIE
        image: 'personal-auto',
        font: 'fa-car'
    },
    default: {
        image: 'no-icon', // Using an existing empty image as default to avoid 404s
        font: 'fa-bell-o'
    }
};

const lobKeys = {
    PersonalAuto: 'PA',
    PersonalAutoLine: 'PA',
    auto_per: 'PA',
    Homeowners: 'HO',
    homeowners: 'HO',
    HomeOwners: 'HO',
    HomeownersLine_HOE: 'HO',
    HomeownersLine: 'HO',
    HOPLine: 'HO',
    HOLine: 'HO',
    HOPHomeowners: 'HO',
    GeneralLiability: 'GL',
    GeneralLiabilityLine: 'GL',
    GLLine: 'GL',
    BusinessOwners: 'BO',
    BusinessOwnersLine: 'BO',
    BOPLine: 'BO',
    InlandMarine: 'IM',
    InlandMarineLine: 'IM',
    IMLine: 'IM',
    CommercialProperty: 'CP',
    CommercialPropertyLine: 'CP',
    CPLine: 'CP',
    CommercialAuto: 'CA',
    BusinessAuto: 'CA',
    BusinessAutoLine: 'CA',
    WorkersComp: 'WC',
    WorkersCompLine: 'WC',
    CommercialPackage: 'CPKG',
    CommercialPackageLine: 'CPKG',
    travel_perLine: 'TRVL',
    PersonalUmbrellaLine: 'PUL',
    PersonalMotor: 'PM', // ANDIE
    PersonalMotorLine: 'PM', // ANDIE
    PersonalMotorLine_itb: 'PM' // ANDIE
};

function _getNormalisedObject(lobCode) {
    const normalisedKey = lobKeys[lobCode] || 'default';
    return normalisedLobIcons[normalisedKey];
}

function getImageIcon(lobCode) {
    return _getNormalisedObject(lobCode).image;
}

function getFontIcon(lobCode) {
    return _getNormalisedObject(lobCode).font;
}

export default {
    getImageIcon: getImageIcon,
    getFontIcon: getFontIcon
};
/** Extends aspects based on the original view model.
 * @param {Object} amountVM original view model of the amount.
 * @param {Object} trans translation service.
 * @param {Function} autoAmount function used to evaluate automatic (default) amount.
 * @returns {Object}
 */
function extendAspects(amountVM, trans, autoAmount) {
    const oldAmountAspects = amountVM.aspects;

    /**
     * Parsed value of the manual input.
     * @returns {Number}
     */
    function manualAmountValue() {
        return parseFloat(amountVM.value);
    }

    /**
     * Patched messages for the validation.
     * @returns {*}
     */
    function manualAmountMessages() {
        const baseMessages = oldAmountAspects.validationMessages;
        if (baseMessages.length > 0) {
            return baseMessages;
        }
        if (Number.isNaN(manualAmountValue())) {
            return [trans.instant('billing.controllers.InvoicePaymentCtrl.Please enter numbers only')];
        }
        if (manualAmountValue() <= 0) {
            return [trans.instant('billing.controllers.InvoicePaymentCtrl.Payment amount must be positive')];
        }
        if (!manualAmountValue()) {
            return [trans.instant('billing.controllers.InvoicePaymentCtrl.Payment amount must be positive')];
        }
        if (manualAmountValue() > autoAmount()) {
            return [trans.instant('billing.controllers.InvoicePaymentCtrl.Overpay is not supported')];
        }
        return [];
    }

    return Object.create(oldAmountAspects, {
        'valid': {
            'get': () => {
                return oldAmountAspects.valid && manualAmountValue() > 0 && manualAmountValue() <= autoAmount();
            }
        },
        'required': {
            'get': () => {
                return true;
            }
        },
        'validationMessages': {
            'get': manualAmountMessages
        },
        'validationMessage': {
            'get': () => {
                return manualAmountMessages()[0];
            }
        }
    });
}


/** Model of the invoice amount. */

export default {
    /**
     * Creates a new model for the invoice amount. Returned object have following fields and methods:
     * <dl>
     *     <dt>amountType</dt><dd>Type of the amount.
     *         It could be either <em>selectedInvoices</em> or <em>manual</em>.</dd>
     *     <dt>manualAmount</dt><dd>View model node used for the manual amount input. Modifiable only when
     *         amountType is set to manual.</dd>
     *      <dt>effectiveAmount</dt><dd>Effective selected amount (based on the amount type selected). Returns
     *          -1 if the effective value could not be parsed.</dd>
     *      <dt>valid</dt><dd>Flag indicating that amount could be correctly interpretend and used.
     * </dl>
     * @param {Object} vms view model service.
     * @param {Object} trans translation service.
     * @param {*} defaultCurrency default currency value.
     * @param {Function} autoAmount function used to evaluate "automatic" amount for the input.
     * @returns {Object} model of the amount input for the invoice payment.
     */
    'create': (vms, trans, defaultCurrency, autoAmount) => {
        let amountType = 'selectedInvoices';
        const amountVM = vms.create({
            currency: defaultCurrency
        }, 'bc', 'edge.capabilities.currency.dto.AmountDTO').amount;

        const paymentAmountVM = Object.freeze({
            get value() {
                return amountVM.value;
            },

            set value(nv) {
                amountVM.value = nv;
            },

            'get': () => {
                return amountVM.get();
            },

            'set': (nv) => {
                return amountVM.set(nv);
            },

            'aspects': extendAspects(amountVM, trans, autoAmount)
        });

        /**
         * Converts current automatic amount into its string format.
         * @returns {*}
         */
        function autoAmountAsString() {
            const amount = autoAmount();
            if (Number.isNaN(amount)) {
                return '';
            }
            return (Math.round(amount * 100) / 100).toString();
        }


        return Object.freeze({
            get amountType() {
                return amountType;
            },

            set amountType(newAmountType) {
                switch (newAmountType) {
                    case 'selectedInvoices':
                        amountType = 'selectedInvoices';
                        return;
                    case 'manual':
                        /* Default to the existing amount if no value was entered yet. */
                        if (amountVM.value === undefined) {
                            amountVM.value = autoAmountAsString();
                        }
                        amountType = 'manual';
                        return;
                    default:
                        console.error(`Attempt to set a unsupported payment type ${newAmountType}`);
                }
            },

            'manualAmount': paymentAmountVM,

            get effectiveAmount() {
                const amount = amountType === 'manual' ? amountVM.value : autoAmountAsString();
                return {
                    'currency': defaultCurrency,
                    amount
                };
            },

            get valid() {
                return amountType === 'manual' ? paymentAmountVM.aspects.valid : true;
            }
        });
    }
};

import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import ClaimsTemplate from 'has-text?role!edge/claim/views/claims.html';
import ClaimDetailTemplate from 'has-text?role!edge/claim/views/claim-detail_AND.html'; // ANDIE
import ActivitiesTemplate from 'text!edge/claim/views/activities.html';
import ActivityDetailTemplate from 'text!edge/claim/views/activity-detail.html';
import CreateNoteTemplate from 'text!edge/claim/views/create-note.html';
import PolicyDetailsTemplate from 'text!edge/claim/views/claim-policy-details.html';
import ClaimDetailCtrl from 'edge/claim/controllers/ClaimDetailCtrl';
import ActivityDetailCtrl from 'edge/claim/controllers/ActivityDetailCtrl';
import CreateNoteCtrl from 'edge/claim/controllers/CreateNoteCtrl';
import ClaimPolicyDetailsCtrl from 'edge/claim/controllers/ClaimPolicyDetailsCtrl';

export default (RootStates) => {
    const parentState = RootStates.claims;
    const homeState = 'claims';

    const states = {
        claim: {
            name: 'claim',
            parent: parentState,
            abstract: true,
            url: '/claims',
            template: '<div ui-view></div>'
        },
        activity: {
            name: 'activity',
            parent: parentState,
            abstract: true,
            url: '/activities',
            template: '<div ui-view></div>'
        },
        claimlist: {
            name: 'claimlist',
            parent: 'claim',
            url: '/list',
            template: ClaimsTemplate,
            data: {
                label: 'Claims'
            }
        },
        activitieslist: {
            name: 'activitieslist',
            parent: 'activity',
            url: '/list',
            template: ActivitiesTemplate,
            data: {
                label: 'Activities'
            }
        },
        claimDetails: {
            name: 'claimDetails',
            parent: 'claim',
            url: '/{claimNumber}',
            template: ClaimDetailTemplate,
            controller: ClaimDetailCtrl,
            data: {
                label: 'Claim Details',
                trackingUrl: '/details'
            }
        },
        createNote: {
            name: 'createNote',
            parent: 'claim',
            url: '/create-note/{claimNumber}',
            template: CreateNoteTemplate,
            controller: CreateNoteCtrl,
            data: {
                label: 'Add Note',
                actions: {
                    claimDetails: 'claimDetails',
                    cancel: 'claimDetails'
                },
                trackingUrl: '/create-note'
            }
        },
        policyDetails: {
            name: 'policyDetails',
            parent: 'claim',
            url: '/policy/?claimNumber',
            template: PolicyDetailsTemplate,
            controller: ClaimPolicyDetailsCtrl,
            data: {
                label: 'Policy Details',
                actions: {
                    home: homeState
                }
            },
            trackingUrl: '/policy/detail'
        }
    };


    if (ApplicationFeatures.hasFeature('role.vendor')) {
        states.viewActivity = {
            name: 'viewActivity',
            parent: 'activity',
            url: '/detail/{activity}',
            template: ActivityDetailTemplate,
            controller: ActivityDetailCtrl,
            data: {
                label: 'View Activity',
                actions: {
                    claimDetails: 'claimDetails',
                    cancel: 'claimDetails'
                },
                trackingUrl: '/activity/detail'
            }
        };
    }

    return states;
};

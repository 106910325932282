import templateStr from 'text!./templates/make-a-payment-form.html';
import InvoicePaymentAmount from '../model/_InvoicePaymentAmount';
import _ from 'underscore';

export default () => {
    return {
        template: templateStr,
        scope: {
            invoiceStream: '=',
            cancelAction: '&',
            payAction: '&',
            filterBy: '='
        },
        controller: ['$scope', '$translate', 'integration.PaymentInstrumentService', 'ViewModelService', 'TranslateService', ($scope, $translate, PaymentInstrumentService, ViewModelService, TranslateService) => {
            const TRANSLATION_KEYS = {
                due: 'billing.directives.templates.make-a-payment-form.Due',
                billed: 'billing.directives.templates.make-a-payment-form.Billed',
                carriedforward: 'billing.directives.templates.make-a-payment-form.Carried Forward',
                planned: 'billing.directives.templates.make-a-payment-form.Planned',
                writtenoff: 'billing.directives.templates.make-a-payment-form.Written Off'
            };

            initController();

            /**
             * Creates a memoized version of calculateBalance. Uses memoization to prevent an infinite digest
             */
            $scope.calculateBalance = _.memoize(calculateBalance, _currencyHashFunction);

            /**
             * Filter to filter out paid invoices
             * @param {Object} invoice
             * @returns {*}
             */
            $scope.paidFilter = (invoice) => {
                let filterInvoice;
                if ($scope.filterBy === 'paidStatus') {
                    filterInvoice = invoice.paidStatus === 'unpaid';
                } else if ($scope.filterBy === 'invoiceStatus') {
                    filterInvoice = invoice.invoiceStatus === 'due' || invoice.invoiceStatus === 'billed';
                }
                return filterInvoice;
            };

            /**
             * Set the manual entry flag to indicate that the user has manually entered an amount
             */
            $scope.turnOnManualEntry = () => {
                $scope.manualEntry = true;
            };

            $scope.updateAmount = updateAmount;

            /**
             * Marshals the pay data and calls the pay action
             */
            $scope.payNow = () => {
                $scope.invoiceForm.submitted = true;

                const invoiceIds = _.pluck($scope.invoiceStream.invoiceSummary.filter(isSelected), 'invoiceId');
                const instrument = PaymentInstrumentService.toDto($scope.paymentInstrument);

                if ($scope.invoiceForm.invalid || !$scope.amount.valid || !instrument || invoiceIds.length === 0) {
                    return;
                }
                $scope.payAction({
                    invoiceIds: invoiceIds,
                    amount: $scope.amount.manualAmount.value,
                    instrument: instrument
                });
            };

            /**
             * Calls the cancel pay action
             */
            $scope.cancelPay = () => {
                $scope.cancelAction();
            };

            /**
             * Get the translation for the invoice status
             * @param {String} status
             * @returns {String}
             */
            $scope.getStatusTranslation = (status) => {
                const translationKey = TRANSLATION_KEYS[status];
                let returnString = '';
                if (translationKey) {
                    returnString = $translate.instant(translationKey);
                }
                return returnString;
            };

            $scope.isValid = () => {
                return $scope.amount.manualAmount.aspects.valid && $scope.paymentInstrument.view.aspects.valid;
            };

            /**
             * Initilises the controller variables
             */
            function initController() {
                // By default the amount updates automatically
                $scope.manualEntry = false;
                selectDueInvoices();

                // Initilise the amount viewmodel
                $scope.amount = InvoicePaymentAmount.create(
                    ViewModelService, TranslateService,
                    $scope.invoiceStream.invoiceSummary[0].amountDue.currency, () => {
                        return selectedInvoiceAmount($scope.invoiceStream.invoiceSummary);
                    }
                );
                // Set manual entry and set the initial value to 0
                $scope.amount.amountType = 'manual';
                $scope.amount.manualAmount.value = 0;

                // Create Payment Instrument viewmodel
                $scope.paymentInstrument = PaymentInstrumentService.createInvoicePaymentInstrument();

                // Update amount to calculate selected invoices
                updateAmount();
            }

            /**
             * Updates the running total to be paid if the user had not already manually overwritten the amount
             * field
             */
            function updateAmount() {
                if (!$scope.manualEntry) {
                    $scope.amount.manualAmount.value = selectedInvoiceAmount($scope.invoiceStream.invoiceSummary);
                }
            }

            /**
             * Calculates the balance of an invoice
             * @param {Object} total - Currency DTO for total invoiced
             * @param {Object} paid - Currency DTO for amount paid on invoice
             * @returns {*} - Currency DTO for amount on invoice outstanding
             */
            function calculateBalance(total, paid) {
                if (!total || !paid || total.currency !== paid.currency) {
                    return null;
                }
                return {
                    amount: total.amount - paid.amount,
                    currency: total.currency
                };
            }

            /**
             * Creates a hash for 2 currency DTO's. Used by underscore memoize to save result of computation
             * @param {Object} total - Currency DTO for total invoiced
             * @param {Object} paid - Currency DTO for amount paid on invoice
             * @returns {*} - Params stringified
             */
            function _currencyHashFunction(total, paid) {
                return JSON.stringify(total) + JSON.stringify(paid);
            }

            /**
             * Checks if invoice is selected.
             * @param {Object} invoice
             * @returns {Boolean|*}
             */
            function isSelected(invoice) {
                return invoice.selected;
            }

            /**
             * Returns amount of selected invoices.
             * @param {Object} invoices
             * @returns {Number}
             */
            function selectedInvoiceAmount(invoices) {
                const origAmount = invoices
                    .filter(isSelected)
                    .map((invoice) => {
                        return invoice.amountDue.amount;
                    })
                    .reduce((a, b) => {
                        return a + b;
                    }, 0);
                return Math.round(origAmount * 100) / 100;
            }

            /**
             * Automatically select policies that are due
             */
            function selectDueInvoices() {
                $scope.invoiceStream.invoiceSummary = $scope.invoiceStream.invoiceSummary.map((invoice) => {
                    invoice.selected = invoice.invoiceStatus === 'due';
                    return invoice;
                });
            }
        }]
    };
};
import BaseFnolWizardView from 'text!edge/fnol/common/views/base-fnol-wizard_AND.html'; // ANDIE
import PolicySelectPolicyholderView from 'text!edge/fnol/common/views/policy-select-policyholder_AND.html'; // ANDIE
import PolicySelectProducerView from 'text!edge/fnol/common/views/policy-select-producer.html';
import MoreInfoView from 'text!edge/fnol/common/views/more-info.html';
import MainContactView from 'text!edge/fnol/common/views/main-contact_AND.html';
import SummaryView from 'has-text?role!edge/fnol/common/views/summary.html';
import ConfirmationView from 'text!edge/fnol/common/views/confirmation.html';
import ContactAgentView from 'text!edge/fnol/common/views/contact-agent.html';
import FnolEntryView from 'text!edge/fnol/common/views/fnol-entry.html';
import BaseFnolWizardCtrl from 'edge/fnol/common/controllers/BaseFnolWizardCtrl';
import FnolEntryCtrl from 'edge/fnol/common/controllers/FnolEntryCtrl';
import PolicySelectPolicyholderCtrl from 'edge/fnol/common/controllers/PolicySelectPolicyholderCtrl_AND'; // ANDIE
import PolicySelectProducerCtrl from 'edge/fnol/common/controllers/PolicySelectProducerCtrl';
import MainContactCtrl from 'edge/fnol/common/controllers/MainContactCtrl_AND';
import ConfirmationCtrl from 'edge/fnol/common/controllers/ConfirmationCtrl';
import MoreInfoCtrl from 'edge/fnol/common/controllers/MoreInfoCtrl';
import SummaryCtrl from 'edge/fnol/common/controllers/SummaryCtrl';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

export default function (RootStates, FnolConfig) {
    const parentState = RootStates.fnol;
    const wizardParentState = 'baseFnolWizard';

    const states = {
        fnol: {
            parent: parentState,
            url: '/fnol',
            controller: FnolEntryCtrl,
            template: FnolEntryView,
            params: {
                claimNumber: null, // optional parameter passed when resuming a draft claim
                policyNumber: null, // optional parameter to specify a policy number when creating a claim
                accountNumber: null // optional parameter to specify an account number when creating a claim
            },
            resolve: {
                originState: ['$state', ($state) => {
                    // remember the state we came from so we can return if there is an error loading the claim
                    return {
                        name: $state.current.name,
                        params: $state.params
                    };
                }]
            }
        },
        baseFnolWizard: {
            parent: parentState,
            url: '/fnol-flow?policyNumber&accountNumber',
            controller: BaseFnolWizardCtrl,
            template: BaseFnolWizardView,
            resolve: {
                claimViewModel: ['StateUtils', StateUtils => {
                    return () => StateUtils.dataForState(wizardParentState).model;
                }]
            },
            data: {
                trackingUrl: (state, params, model) => {
                    let url = '/fnol-flow';
                    if (model && model.policy && model.policy.policyType) {
                        url = `${url}/${model.policy.policyType}`;
                    }
                    return url;
                }
            }
        }
    };


    /**
     * In the config.js we specify whether or not the application will support multiple lines of business.
     * If the application is configured to do so, the application provides select policy functionality
     */
    if (FnolConfig.multiplePolicies) {
        if (ApplicationFeatures.hasFeature('role.policyholder')) {
            states.policySelect = {
                parent: wizardParentState,
                template: PolicySelectPolicyholderView,
                controller: PolicySelectPolicyholderCtrl
            };
        } else if (ApplicationFeatures.hasFeature('role.producer')) {
            states.policySelect = {
                parent: wizardParentState,
                template: PolicySelectProducerView,
                controller: PolicySelectProducerCtrl
            };
        }
    }

    /**
     * States that are deemed to be common to multiple lines of business. They have a predefined relationship
     * to each other than can be followed by the 'next' attribute on actions.
     *
     * @type {Array} The states that are common to all lines of business
     */
    states.moreInfo = {
        parent: wizardParentState,
        controller: MoreInfoCtrl,
        template: MoreInfoView
    };
    states.mainContact = {
        parent: wizardParentState,
        template: MainContactView,
        controller: MainContactCtrl
    };
    states.summary = {
        parent: wizardParentState,
        template: SummaryView,
        controller: SummaryCtrl
    };
    states.fnolconfirmation = {
        parent: parentState,
        url: '/confirmation?claimNumber',
        template: ConfirmationView,
        controller: ConfirmationCtrl,
        params: {
            returnStateInfo: null
        },
        data: {
            trackingUrl: '/confirmation'
        }
    };
    states.contactAgent = {
        parent: parentState,
        url: '/contact-agent?claimNumber',
        template: ContactAgentView,
        params: {
            returnStateInfo: null
        },
        data: {
            trackingUrl: '/contact-agent'
        }
    };

    return states;
}

import PersonalizedOffersUtil from '../../PersonalizedOffersUtil';


class PersonalizedOffersCtrl {

    constructor(StateUtils, PersonalizedOffersService, $state, ApplicationFeatures) {

        this.personalizedOffersAreEnabled = ApplicationFeatures.hasFeature('personalizedoffers');
        if (this.personalizedOffersAreEnabled) {
            this.$onInit = () => {
                this.currentPage = StateUtils.getTrackingUrl();
                PersonalizedOffersService.getOfferDataFromServer(this.currentPage, $state.params)
                    .then((data) => {
                        this.model = PersonalizedOffersUtil.pickOffer(data);
                    })
                    .catch(err => console.error(err));
            };
        }
    }

}
PersonalizedOffersCtrl.$inject = ['StateUtils', 'PersonalizedOffersService', '$state', 'ApplicationFeatures'];
export default PersonalizedOffersCtrl;
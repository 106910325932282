import EndorsementEntryCtrl from '../controllers/EndorsementEntryCtrl_AND'; // ANDIE
import endorsementEntryTemplate from 'text!../views/endorsement-entry_AND.html'; // ANDIE
import ContactAgentView from 'plugin?gatewayendorsement!edge/endorsement/common/views/contact-agent.html';
import ContactAgentCtrl from 'edge/endorsement/common/controllers/ContactAgentCtrl';
import ChangeBoundView from 'text!edge/endorsement/common/views/change-bound.html';
import ChangeBoundCtrl from 'edge/endorsement/common/controllers/ChangeBoundCtrl';
import PaymentView from 'text!edge/endorsement/common/views/payment.html';
import PaymentCtrl from 'edge/endorsement/common/controllers/PaymentCtrl';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import EndorsementTransactionEntryCtrl from '../wizardEntry/EndorsementEntryCtrl';
import EndorsementTransactionEntryView from 'text!gw-portals-policytransaction-common-angular/transactionEntry/transactionEntry.html';

export default function (RootStates) {
    const parentState = RootStates.endorsement;
    const gatewayEndorsementEnabled = ApplicationFeatures.getAllFeatures().gatewayendorsement === 'true';
    const homeState = gatewayEndorsementEnabled ? 'policies.detail.summary' : RootStates.home;
    const agentParams = {
        policyNumber: {}
    };
    const agentData = {
        actions: {
            next: homeState
        }
    };
    const changeBoundParams = {
        changesAppliedForward: {},
        policyNumber: {}
    };
    const changeBoundData = {
        actions: {
            next: homeState
        }
    };
    const paymentParams = {
        policyChange: {}
    };
    const paymentData = {
        actions: {
            next: homeState
        }
    };

    const getDefinition = (name, url, controller, view, params = {}, data = {}) => ({
        url: url,
        parent: parentState,
        name: name,
        views: {
            '': {
                'template': view,
                'controller': controller
            }
        },
        params: params,
        data: data
    });

    const endorsementParams = {
        selectedPolicyNumber: null,
        selectedRenewal: null,
        resume: true
    };

    return {
        endorsement: getDefinition('endorsement', '/endorsement', EndorsementEntryCtrl, endorsementEntryTemplate),
        // endorsementWithPolicy: getDefinition('endorsementWithPolicy', '/endorsement/{selectedPolicyNumber}?day&month&year', EndorsementEntryCtrl, endorsementEntryTemplate), // ANDIE Not used
        endorsContactAgent: getDefinition('endorsContactAgent', '/contactAgent', ContactAgentCtrl, ContactAgentView, agentParams, agentData),
        changeBound: getDefinition('changeBound', '/changeBound', ChangeBoundCtrl, ChangeBoundView, changeBoundParams, changeBoundData),
        payment: getDefinition('payment', '/payment', PaymentCtrl, PaymentView, paymentParams, paymentData),
        endorsementTransactionEntry: getDefinition('endorsementTransactionEntry', '/endorsementTransactionEntry/{selectedPolicyNumber}?day&month&year', EndorsementTransactionEntryCtrl, EndorsementTransactionEntryView, endorsementParams)
    };
}
import cssUtil from 'gw-portals-util-js/CssUtil';
import templateString from 'text!./Footer.html';
import templateStyles from './Footer-hashed.scss';
import controller from '../FooterCtrl';

const template = cssUtil.hashTemplate(templateString, templateStyles);

export default {
    controller,
    template,
    restrict: 'E'
};
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

const fn = ($scope, $state) => {
    $scope.changesAppliedForward = $state.params.changesAppliedForward;
    $scope.policyNumber = $state.params.policyNumber;

    const gatewayEndorsementEnabled = ApplicationFeatures.getAllFeatures().gatewayendorsement === 'true';
    $scope.backButtonLabel = 'endorsement.common.views.contact-agent.Back to Home';

    if (gatewayEndorsementEnabled) {
        $scope.backButtonLabel = 'endorsement.common.views.contact-agent.Back to Policy';
        $scope.changesAppliedForwardText = 'endorsement.common.views.change-bound.Changes automatically applied contact underwriter';
    } else {
        $scope.changesAppliedForwardText = 'endorsement.common.views.change-bound.Changes automatically applied.';
    }

    const ctrl = Object.create(BaseCtrl);
    ctrl.gotBackToHomeState = () => {
        const nextState = $state.$current.data.actions.next;
        $state.go(nextState, {
            policyNumber: $scope.policyNumber
        });
    };
    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state'];

export default fn;

import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import Claim from 'edge/fnol/common/models/Claim';
import commonFlowDefnFactory from 'edge/fnol/common/config/FlowConfig';
import paFlowDefnFactory from 'conditional?if=fnol-lob&equals=pa!edge/fnol/pa/config/FlowConfig';
import caFlowDefnFactory from 'conditional?if=fnol-lob&equals=ca!edge/fnol/ca/config/FlowConfig';
import hoFlowDefnFactory from 'conditional?if=fnol-lob&equals=ho!edge/fnol/ho/config/FlowConfig';
import wcFlowDefnFactory from 'conditional?if=fnol-lob&equals=wc!edge/fnol/wc/config/FlowConfig';
import cpFlowDefnFactory from 'conditional?if=fnol-lob&equals=cp!edge/fnol/cp/config/FlowConfig';
import pmFlowDefnFactory from 'conditional?if=fnol-lob&equals=pm!edge/fnol/pm/config/FlowConfig';
import generalFlowDefnFactory from 'edge/fnol/general/config/FlowConfig';

export default ['fnol.LoadSaveService', '$state', 'ModalService', 'TranslateService', '$q', 'StateUtils', 'ViewModelService',
    function (LoadSaveService, $state, ModalService, TranslateService, $q, StateUtils, ViewModelService) {
        const translateFn = TranslateService.instant;

        const ACTION_MSG = {
            changeClaimPolicy: {
                error: {
                    title: 'integration.fnol.CompositeFlowConfig.Error updating claim policy',
                    message: 'integration.fnol.CompositeFlowConfig.Error changing the policy associated with the claim'
                },
                progress: {
                    title: 'integration.fnol.CompositeFlowConfig.Updating claim policy',
                    message: 'integration.fnol.CompositeFlowConfig.Changing the policy associated with the claim'
                }
            }
        };

        const ACTIONS = {
            changeClaimPolicy: (model, args) => {
                if (args.policyChanged) {
                    model.value = new Claim({policy: model.value.policy, lossDate: model.value.lossDate});
                    const flowModel = StateUtils.activeDataForState(true).flowModel;
                    const currentStepIdx = flowModel.steps.indexOf(flowModel.currentStep);
                    const newStepHistory = flowModel.steps.filter((step, stepIdx) => stepIdx <= currentStepIdx);
                    flowModel.resetFlowProgress(flowModel.currentStep, newStepHistory, model);
                } else {
                    // Reinitialize the claim object now that we have the policy type
                    model.value = new Claim(model.value);
                }
                return $q.resolve(model, args);
            }
        };

        const commonFlowDefn = commonFlowDefnFactory(LoadSaveService, $state, $q, ModalService, TranslateService, ViewModelService);

        return function (step, junction, flow) {
            let paFlow;
            let caFlow;
            let hoFlow;
            let wcFlow;
            let cpFlow;
            let pmFlow;
            // if the personal auto feature is enabled then create the PA inner flow definition
            if (ApplicationFeatures.hasFeature('fnol-lob.pa')) {
                const paFlowDefn = paFlowDefnFactory(LoadSaveService, $q, TranslateService);
                paFlow = flow(paFlowDefn);
            }
            // if the personal auto feature is enabled then create the PA inner flow definition
            if (ApplicationFeatures.hasFeature('fnol-lob.ca')) {
                const caFlowDefn = caFlowDefnFactory(LoadSaveService, $q, TranslateService);
                caFlow = flow(caFlowDefn);
            }
            // if the homeowners feature is enabled then create the HO inner flow definition
            if (ApplicationFeatures.hasFeature('fnol-lob.ho')) {
                const hoFlowDefn = hoFlowDefnFactory(LoadSaveService, $q, TranslateService);
                hoFlow = flow(hoFlowDefn);
            }
            // if the workers comp feature is enabled then create the WC inner flow definition
            if (ApplicationFeatures.hasFeature('fnol-lob.wc')) {
                const wcFlowDefn = wcFlowDefnFactory(LoadSaveService, $q, TranslateService);
                wcFlow = flow(wcFlowDefn);
            }
            // if the commercial property feature is enabled then create the CP inner flow definition
            if (ApplicationFeatures.hasFeature('fnol-lob.cp')) {
                const cpFlowDefn = cpFlowDefnFactory(LoadSaveService, $q, TranslateService);
                cpFlow = flow(cpFlowDefn);
            }
            // ANDIE if the personal motor feature is enabled then create the Pm inner flow definition
            if (ApplicationFeatures.hasFeature('fnol-lob.pm')) {
                const pmFlowDefn = pmFlowDefnFactory(LoadSaveService, $q, TranslateService);
                pmFlow = flow(pmFlowDefn);
            }

            const generalFlowDefn = generalFlowDefnFactory(LoadSaveService, $q, TranslateService);
            const generalFlow = flow(generalFlowDefn);

            // entry step into FNOL flow
            step('policySelect', true)
                .onNext.doAction(ACTIONS.changeClaimPolicy, ACTION_MSG.changeClaimPolicy).thenBranch(({
                    value: claim
                }) => {
                    if (claim.policy) {
                        if (claim.policy.policyType === 'PersonalAuto' || claim.policy.policyType === 'auto_per') {
                            return paFlow.entry;
                        } else if (claim.policy.policyType === 'CommercialAuto' || claim.policy.policyType === 'BusinessAuto') {
                            return caFlow.entry;
                        } else if (claim.policy.policyType === 'Homeowners' || claim.policy.policyType === 'homeowners' || claim.policy.policyType === 'HOPHomeowners') {
                            return hoFlow.entry;
                        } else if (claim.policy.policyType === 'WorkersComp' || claim.policy.policyType === 'workerscomp') {
                            return wcFlow.entry;
                        } else if (claim.policy.policyType === 'CommercialProperty' || claim.policy.policyType === 'commercialproperty') {
                            return cpFlow.entry;
                        } else if (claim.policy.policyType === 'PersonalMotor' || claim.policy.policyType === 'personalMotor') {
                            return pmFlow.entry;
                        }
                        return generalFlow.entry;
                    }
                }, () => 'error')
                .skipOnResumeIf(model => model.policy.value)
                .label(() => translateFn('fnol.wizard.steps.Select Policy'))
                .data({
                    hideNavigationStep: true
                });

            // create the common flow definition
            const commonFlow = flow(commonFlowDefn);
            if (paFlow) {
                // join the exits of the PA flow definition to the common flow definition
                paFlow.exits.paSuccess.onNext.goTo(commonFlow.entry);
                paFlow.exits.paError.onNext.goTo(commonFlow.exits.error);
            }
            if (caFlow) {
                // join the exits of the CA flow definition to the common flow definition
                caFlow.exits.caSuccess.onNext.goTo(commonFlow.entry);
                caFlow.exits.caError.onNext.goTo(commonFlow.exits.error);
            }
            if (hoFlow) {
                // join the exits of the HO flow definition to the common flow definition
                hoFlow.exits.hoSuccess.onNext.goTo(commonFlow.entry);
                hoFlow.exits.hoError.onNext.goTo(commonFlow.exits.error);
            }
            if (wcFlow) {
                // join the exits of the WC flow definition to the common flow definition
                wcFlow.exits.wcSuccess.onNext.goTo(commonFlow.entry);
                wcFlow.exits.wcError.onNext.goTo(commonFlow.exits.error);
            }
            if (cpFlow) {
                // join the exits of the CP flow definition to the common flow definition
                cpFlow.exits.cpSuccess.onNext.goTo(commonFlow.entry);
                cpFlow.exits.cpError.onNext.goTo(commonFlow.exits.error);
            }
            if (pmFlow) { // ANDIE
                // join the exits of the PA flow definition to the common flow definition
                pmFlow.exits.pmSuccess.onNext.goTo(commonFlow.entry);
                pmFlow.exits.pmError.onNext.goTo(commonFlow.exits.error);
            }

            // join the exits of the General flow definition to the common flow definition
            generalFlow.exits.generalSuccess.onNext.goTo(commonFlow.entry);
            generalFlow.exits.generalError.onNext.goTo(commonFlow.exits.error);
        };
    }
];

import templateStr from 'text!./pm-driver-summary-card-mta.html';

export default ['$filter', ($filter) => {
    return {
        restrict: 'A',
        scope: {
            driverVM: '<driverVm',
            setMainDriver: '<?',
            removeDriver: '<',
            editDetails: '<?',
            showRemoveDriverConfirmation: '<'
        },
        template: templateStr,
        link: (scope) => {
            scope.driver = scope.driverVM.value;
            if (scope.driver.isPolicyHolder === true) {
                scope.relationshipPHDisplayName = $filter('translate')('and.quoteandbind.pm.directives.templates.pm-driver-summary-card.Policyholder');
            } else if (scope.driverVM.phRelationship_itb.value) {
                scope.relationshipPHDisplayName = scope.driverVM.phRelationship_itb.value.name;
            }
            if (scope.driverVM.title_itb.value) {
                scope.driverDisplayName = `${scope.driverVM.title_itb.value.name} ${scope.driver.firstName} ${scope.driver.lastName}`;
            }
            scope.amendDetails = () => {
                scope.editDetails(scope.driverVM);
            };
        }
    };
}];
import templateStr from 'text!./change-email-address.html';
import AuthenticationService from 'edge/api/gw-portals-auth-js/AuthenticationService';

export default ['$filter', 'UserProfileService_AND', 'ViewModelService', 'oAuth', ($filter, UserProfileService_AND, ViewModelService, oAuth) => {
    const EMAIL_MISMATCH = $filter('translate')('and.directives.change-email-address.EmailMismatch');
    const API_ERROR = $filter('translate')('and.directives.change-email-address.APIError');
    const API_USERNAME_TAKEN = $filter('translate')('and.directives.change-email-address.UsernameAlreadyTaken');
    const JSON_RPC_INVALID_PARAMS = -32602;
    const authenticationService = AuthenticationService(oAuth);

    return {
        restrict: 'A',
        template: templateStr,
        scope: {},
        controller: ['$scope', ($scope) => {
            const setAPIError = (message) => {
                $scope.apiError = message;
            };
            $scope.clearAPIMessage = () => setAPIError(null);

            $scope.newEmailVM = ViewModelService.create({}, 'pc', 'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO', {AccountEmailRequired: true}).emailAddress1;

            // This VM is trickier, we need the normal emailVM validation and additionally check if both emails match.
            const confirmNewEmailVM = ViewModelService.create({}, 'pc', 'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO', {AccountEmailRequired: true}).emailAddress1;
            $scope.confirmNewEmailVM = {
                value: null,
                aspects: {
                    inputCtrlType: 'text',
                    required: true,
                    get valid() {
                        return confirmNewEmailVM.aspects.valid &&
                            $scope.confirmNewEmailVM.value === $scope.newEmailVM.value;
                    },
                    get validationMessages() {
                        if (confirmNewEmailVM.aspects.valid !== true) {
                            return confirmNewEmailVM.aspects.validationMessages;
                        } else if ($scope.confirmNewEmailVM.value !== $scope.newEmailVM.value) {
                            return [EMAIL_MISMATCH];
                        }
                        return [];
                    }
                }
            };
            // Keep both VMs in sync to trigger accurate validation
            $scope.syncConfirmNewEmailVM = () => {
                confirmNewEmailVM.value = $scope.confirmNewEmailVM.value;
            };

            $scope.changeEmail = (form) => {
                form.submitted = true;
                $scope.clearAPIMessage();
                if (form.valid === false) {
                    return;
                }
                $scope.inFlight = true;
                return UserProfileService_AND.updateEmailAddress($scope.confirmNewEmailVM.value)
                    .then(() => authenticationService.logout())
                    .catch((error) => {
                        if (error && error.baseError && error.baseError.error && error.baseError.error.code &&
                            error.baseError.error.code === JSON_RPC_INVALID_PARAMS) {
                            setAPIError(API_USERNAME_TAKEN);
                        } else {
                            setAPIError(API_ERROR);
                        }
                    })
                    .finally(() => {
                        $scope.inFlight = false;
                    });
            };
        }]
    };
}];
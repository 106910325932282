import _ from 'underscore';
import EntityUtil from 'gw-portals-util-js/EntityUtil';
import VehicleIncident from 'edge/fnol/pa/models/VehicleIncident';
import Vehicle from 'edge/fnol/pa/models/Vehicle';
import 'edge/fnol/common/models/Contact';

function PAClaimExtension(_claim) {
    Object.defineProperty(this, '_claim', {
        enumerable: false,
        value: _claim
    });

    if (this._claim.lobs.personalAuto) {
        this.vehicles = this._claim.lobs.personalAuto.vehicles || [];
        this.vehicleIncidents = this._claim.lobs.personalAuto.vehicleIncidents || [];

        this.vehicles = _.map(this._claim.lobs.personalAuto.vehicles, (v) => {
            return new Vehicle(v);
        });

        // correctly link up passengers, drivers with contact objects that already exist in the object hierarchy
        _.each(this._claim.lobs.personalAuto.vehicleIncidents, function (vi) {
            vi.driver = this._claim._findContact(this._claim, vi.driver);
            _.each(vi.passengers, (p, index) => {
                vi.passengers[index] = this._claim._findContact(this._claim, p);
            }, this);
            vi.vehicle = this._findVehicle(vi.vehicle);
        }, this);

        this.fixedPropertyIncident = this._claim.lobs.personalAuto.fixedPropertyIncident
            ? this._claim.lobs.personalAuto.fixedPropertyIncident
            : {propertyDescription: undefined};
        this.vehicleIncidents = _.map(this._claim.lobs.personalAuto.vehicleIncidents, function (vi) {
            return new VehicleIncident(this._claim, this, vi);
        }, this);

        const {repairOption} = this._claim.lobs.personalAuto;
        if (repairOption) {
            const vehicleIncident = this._findVehicleIncident(repairOption.vehicleIncident);
            const matchKeys = repairOption.repairFacility && repairOption.repairFacility.addressBookUID
                ? ['addressBookUID'] : null;
            const repairFacility = this._claim._findContact(this._claim, repairOption.repairFacility, matchKeys);
            this.repairOption = _.extend({}, repairOption, {vehicleIncident, repairFacility});
        }
    } else {
        this.fixedPropertyIncident = {propertyDescription: undefined};
        this.vehicleIncidents = [];
        this.vehicles = [];
    }
}

PAClaimExtension.prototype.createVehicleIncident = function () {
    const vi = new VehicleIncident(this._claim, this);
    vi.tempID = EntityUtil.nextId();
    this.vehicleIncidents.push(vi);
    return vi;
};

PAClaimExtension.prototype.createVehicle = function () {
    return new Vehicle({
        tempID: this._claim.nextId()
    });
};

PAClaimExtension.prototype.removeVehicleIncident = function (incident) {
    const idx = this.vehicleIncidents.indexOf(incident);
    if (idx >= 0) {
        this.vehicleIncidents.splice(idx, 1);
    }
};

PAClaimExtension.prototype.availableVehicles = function (additional) {
    return _.filter(this.vehicles, (v) => {
        if (additional && v === additional) {
            return true;
        }
        const used = _.find(this.vehicleIncidents, (vi) => {
            return v === vi.vehicle;
        });
        return used === undefined;
    }, this);
};

PAClaimExtension.prototype.availableDrivers = function (additional) {
    return this.availablePassengers(additional);
};

PAClaimExtension.prototype.availablePassengers = function (additional) {
    return _.filter(this._claim.contacts, function (c) {
        if (additional && c === additional) {
            return true;
        }
        const used = _.find(this.vehicleIncidents, (vi) => {
            return c === vi.driver ||
                _.some(vi.passengers, (p) => {
                    return c === p;
                });
        });
        return used === undefined;
    }, this);
};

PAClaimExtension.prototype._findVehicle = function (vehicle) {
    return EntityUtil.findMatchingEntity(this.vehicles, vehicle, {
        Ctor: Vehicle
    });
};

PAClaimExtension.prototype._findVehicleIncident = function (vi) {
    return EntityUtil.findMatchingEntity(this.vehicleIncidents, vi, {
        Ctor: VehicleIncident
    });
};

export default PAClaimExtension;
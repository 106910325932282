import templateStr from 'text!./user-account-info.html';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';

export default [() => {

    return {
        restrict: 'A',
        template: templateStr,
        scope: {},
        controller: ['$scope', ($scope) => {
            $scope.dateToString = (value) => {
                if (value) {
                    return LocalDateUtil.toString_AND(value);
                }
            };
        }]
    };
}];
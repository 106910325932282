import templateStr from 'text!./saved-quotes-detail-tm.html';
import moment from 'moment';
import LocalDateUtil from 'gw-portals-util-js/LocalDateUtil';
import DateUtil from 'gw-portals-util-js/DateUtil';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {},
        controller: ['$rootScope', '$scope', 'AMPSubmissionDraftService', 'UserProfileService_AND', '$q', 'brandingData',
            ($rootScope, $scope, AMPSubmissionDraftService, UserProfileService_AND, $q, brandingData) => {
                $scope.brand = brandingData;
                $scope.inFlight = false;
                $scope.createSubmissionAndSwitchToQuoteAndBuy = () => {
                    $scope.inFlight = true;
                    let accountNumber;
                    if ($rootScope.policyContext.currentPolicy) {
                        accountNumber = $rootScope.policyContext.currentPolicy.accountNumber;
                    }
                    AMPSubmissionDraftService.createSubmissionAndSwitchToQuoteAndBuy(accountNumber)
                        .finally(() => {
                            $scope.inFlight = false;
                        });
                };

                $scope.buyOrResume = (savedQuote, amend) => {
                    $scope.inFlight = true;
                    AMPSubmissionDraftService.amend(savedQuote, amend)
                        .finally(() => {
                            $scope.inFlight = false;
                        });
                };

                $q.when(UserProfileService_AND.getSavedQuotes(), (savedQuotes) => {
                    $scope.savedQuotes = savedQuotes;
                    $scope.getDate = (date) => {
                        return moment(date).format('DD/MM/YYYY');
                    };
                });

                $scope.isQuoteOutdated = (savedQuote) => {
                    return DateUtil.currentDateAtMidnight() > LocalDateUtil.toMidnightDate(savedQuote.coverStartDate);
                };

                $scope.showPrice = (savedQuote) => {
                    return savedQuote.premium && !$scope.isQuoteOutdated(savedQuote);
                };
            }]
    };
}];

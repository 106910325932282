import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./RepairFacilityDetails.html';
import styles from './RepairFacilityDetails-hashed.scss';
import controller from './RepairFacilityDetailsCtrl';

export default {
    template: cssUtil.hashTemplate(template, styles),
    bindings: {
        repairFacility: '<',
        onAccepted: '&'
    },
    controller
};

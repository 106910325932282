import templateStr from 'text!./pm-edit-ph-driver-mta.html';
import moment from 'moment';

const DRIVING_LICENCE_TYPE = {
    FullEU: 'FullEU',
    FullUK: 'FullUK',
    ProvisionalUK: 'ProvisionalUK',
    ProvisionalEU: 'ProvisionalEU'
};
const getMonthCode = (monthTypekeyVM) => (monthTypekeyVM.value.priority / 10) - 1;

export default [() => {
    return {
        restrict: 'A',
        scope: {
            page: '=',
            driver: '='
        },
        template: templateStr,
        controller: ['$scope', 'ViewModelService', ($scope, ViewModelService) => {
            const basedOnDriver = $scope.page.policyDetailsVM.lobs.personalMotor.drivers.children.find((d) => d.isPolicyHolder.value === true);
            const parkingVM = ViewModelService.create({}, 'pc', 'itb.edge.capabilities.policyjob.lob.personalmotor.coverables.dto.PmVehicleDTO', null);

            $scope.licenceTypeOptions = $scope.driver.licenseType.aspects.availableValues.filter((type) =>
                (basedOnDriver.licenseType.value.code === DRIVING_LICENCE_TYPE.ProvisionalUK &&
                    (type.code === DRIVING_LICENCE_TYPE.FullUK || type.code === DRIVING_LICENCE_TYPE.ProvisionalUK)) ||
                (basedOnDriver.licenseType.value.code === DRIVING_LICENCE_TYPE.ProvisionalEU &&
                    (type.code === DRIVING_LICENCE_TYPE.FullEU || type.code === DRIVING_LICENCE_TYPE.ProvisionalEU)));
            if ($scope.licenceTypeOptions.length > 0) {
                $scope.minAllowedLicenceDate = moment({
                    month: getMonthCode(basedOnDriver.provisionalLicenseMonth),
                    year: basedOnDriver.provisionalLicenseYear.value
                });
            }

            $scope.prefixTypeLists = $scope.driver.title_itb.aspects.availableValues[0].typelist.filters
            $scope.standardPrefixes = $scope.prefixTypeLists.find(obj => {
                return obj.name === 'standardPrefix_itb'
              })

            $scope.maritalStatusHasChanged = () => basedOnDriver.maritalStatus.value !== $scope.driver.maritalStatus.value;
            $scope.licenceTypeHasChanged = () => basedOnDriver.licenseType.value.code !== $scope.driver.licenseType.value.code;
            $scope.hasOccupationChanged = () => {
                if ($scope.driver.occupation_itb.value != null) {
                    // only create the fake view models when occupation has been changed to avoid validation issues
                    $scope.dayTimeParkingVM = parkingVM.dayTimeParking;
                    $scope.nightTimeParkingVM = parkingVM.nightTimeParking;
                    return basedOnDriver.occupation_itb.value.code !== $scope.driver.occupation_itb.value.code;
                }
            };
            // If Marital Status is set back to its original value, reset Last Name too
            $scope.$watch(() => $scope.driver.maritalStatus.value, (newValue) => {
                if (newValue && newValue === basedOnDriver.maritalStatus.value) {
                    $scope.driver.lastName.value = basedOnDriver.lastName.value;
                }
            });

            // Occupation
            $scope.primaryOccupationTooltip = {
                content: 'and.quoteandbind.pm.directives.templates.pm-edit-person.tooltip.primary occupation',
                trigger: 'click'
            };
            const occupations = $scope.driver.occupation_itb.aspects.availableValues;
            $scope.occupationTypeaheadDef = {
                getMatches: (input) => {
                    if (!input || input.length < 2) return [];
                    const results = occupations.filter((key) =>
                        key.name.toLowerCase().startsWith(input.toLowerCase()));
                    return results.slice(0, 8);
                },
                toString: (typekey) => typekey.name
            };

            // Sync
            $scope.$on('$destroy', () => {
                // Do not update vehicle values unless occupation changed, to guarantee we're syncing not null values.
                if ($scope.hasOccupationChanged() === true) {
                    const coverablesVM = $scope.page.policyChangeVM.lobData.personalMotor.coverables;
                    const vehicle = coverablesVM.pmVehicles.children[0];
                    vehicle.dayTimeParking.value = $scope.dayTimeParkingVM.value;
                    vehicle.nightTimeParking.value = $scope.nightTimeParkingVM.value;
                }
            });

            $scope.checkValidity = () => {
                return $scope.dayTimeParkingVM.aspects.valid && $scope.dayTimeParkingVM.aspects.subtreeValid
                    && $scope.nightTimeParkingVM.aspects.valid && $scope.nightTimeParkingVM.aspects.subtreeValid;
            };

            $scope.goToNext = () => {
                if ($scope.hasOccupationChanged() === true) {
                    if ($scope.checkValidity() === true) {
                        $scope.page.saveAndGoToChangeSelection();
                        // if occupation has been changed but parking is invalid -> throw page validation
                    } else {
                        return false;
                    }

                }
                $scope.page.saveAndGoToChangeSelection();
            };
        }]
    };
}];
import controller from './ClaimServiceRequestsCtrl';
import template from 'text!./claim-service-requests.html';
import styles from './ClaimServiceRequests-hashed.scss';
import cssUtil from 'gw-portals-util-js/CssUtil';

export default {
    template: cssUtil.hashTemplate(template, styles),
    controller,
    bindings: {
        serviceRequests: '=',
        policyType: '='
    }
};
import templateStr from 'text!./policy-driver-info.html';
import InsuranceUtil from 'edge/api/gw-portals-util-js/InsuranceUtil_AND';

export default [() => {
    return {
        restrict: 'AE',
        template: templateStr,
        scope: {
            'policyDriver': '<'
        },
        controller: ['$scope', ($scope) => {
            $scope.dateOfBirth = InsuranceUtil.getDateFromDTO($scope.policyDriver.dateOfBirth.value);
            $scope.getYesNoString = InsuranceUtil.getYesNoString;
        }]
    };
}];
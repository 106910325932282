export default function (subFlows, branchOnProductCodeFn) {
    // this function will get passed step,junction and flow parameters but we here we are not creating any new steps
    // or junctions just to wiring up entry and exits so they are not needed.
    return () => {
        subFlows.submissionExists.exits.submissionCreationChecked
            .onNext.branch(branchOnProductCodeFn(subFlows.paFlows, subFlows.hoFlows, subFlows.cpFlows, subFlows.bopFlows, subFlows.pmFlows, 'contactDetails'));

        if (subFlows.paFlows) {
            subFlows.paFlows.contactDetails.exits.paContactDetailsComplete.onNext.goTo(subFlows.needToSaveCheck.entry);
            subFlows.paFlows.coverables.exits.paCoverablesComplete.onNext.goTo(subFlows.quote.entry);
            subFlows.paFlows.moreInfo.exits.paMoreInfoComplete.onNext.goTo(subFlows.bind.entry);
        }

        if (subFlows.hoFlows) {
            subFlows.hoFlows.contactDetails.exits.hoContactDetailsComplete.onNext.goTo(subFlows.needToSaveCheck.entry);
            subFlows.hoFlows.coverables.exits.hoCoverablesComplete.onNext.goTo(subFlows.quote.entry);
            subFlows.hoFlows.moreInfo.exits.hoMoreInfoComplete.onNext.goTo(subFlows.bind.entry);
        }

        if (subFlows.cpFlows) {
            subFlows.cpFlows.contactDetails.exits.cpContactDetailsComplete.onNext.goTo(subFlows.needToSaveCheck.entry);
            subFlows.cpFlows.coverables.exits.cpCoverablesComplete.onNext.goTo(subFlows.quote.entry);
            subFlows.cpFlows.moreInfo.exits.cpMoreInfoComplete.onNext.goTo(subFlows.bind.entry);
        }

        if (subFlows.bopFlows) {
            subFlows.bopFlows.contactDetails.exits.bopContactDetailsComplete.onNext.goTo(subFlows.needToSaveCheck.entry);
            subFlows.bopFlows.coverables.exits.bopCoverablesComplete.onNext.goTo(subFlows.quote.entry);
            subFlows.bopFlows.moreInfo.exits.bopMoreInfoComplete.onNext.goTo(subFlows.bind.entry);
        }

        // ANDIE Added PM Flows
        if (subFlows.pmFlows) {
            subFlows.pmFlows.contactDetails.exits.pmContactDetailsComplete.onNext.goTo(subFlows.pmFlows.coverables.entry);
            subFlows.pmFlows.coverables.exits.pmCoverablesComplete.onNext.goTo(subFlows.pmFlows.driversSummary.entry);
            subFlows.pmFlows.driversSummary.exits.pmDriversSummaryComplete.onNext.goTo(subFlows.pmFlows.vehicleDetails.entry);
            subFlows.pmFlows.vehicleDetails.exits.pmVehicleDetailsComplete.onNext.goTo(subFlows.quote.entry);
            subFlows.quote.exits.offeringSelected.onNext.goTo(subFlows.pmFlows.legalPaymentDetails.entry);
            subFlows.pmFlows.legalPaymentDetails.exits.cardPayment_AND.onNext.goTo(subFlows.pmFlows.cardPayment.entry);
            subFlows.pmFlows.legalPaymentDetails.exits.buyNow_AND.onNext.goTo(subFlows.bind.entry);
            subFlows.pmFlows.legalPaymentDetails.exits.legalPaymentDetailsComplete.onNext.goTo(subFlows.bind.entry);
            subFlows.pmFlows.cardPayment.exits.cardPaymentComplete.onNext.goTo(subFlows.bind.entry);

            // Edit Flow
            subFlows.pmFlows.editDrivers.exits.pmEditPHComplete.onNext.goTo(subFlows.needToSaveCheck.entry);
            subFlows.pmFlows.editDrivers.exits.pmEditDriverComplete.onNext.goTo(subFlows.pmFlows.driversSummary.entry);
        }

        if (!subFlows.pmFlows) {
            subFlows.quote.exits.offeringSelected
                .onNext.branch(branchOnProductCodeFn(subFlows.paFlows, subFlows.hoFlows, subFlows.cpFlows, subFlows.bopFlows, 'moreInfo'));
        }

        subFlows.needToSaveCheck.exits.needToSaveCheckSuccess
            .onNext.branch(branchOnProductCodeFn(subFlows.paFlows, subFlows.hoFlows, subFlows.cpFlows, subFlows.bopFlows, subFlows.pmFlows, 'coverables'));
    };
}
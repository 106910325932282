import _ from 'underscore';
import StatesConfig from 'edge/policy/your-renewal_AND/config/RouteConfig';

import PolicyHolderInfoRenewal from './directives/policy-holder-info-renewal/PolicyHolderInfoRenewal';
import PolicyDriverInfoRenewal from './directives/policy-driver-info-renewal/PolicyDriverInfoRenewal';
import PolicyVehicleInfoRenewal from './directives/policy-vehicle-info-renewal/PolicyVehicleInfoRenewal';

import PolicyQuoteExcessRenewal from './directives/policy-quote-excess-renewal/PolicyQuoteExcessRenewal';
import PolicyExtrasRenewal from './directives/policy-extras-renewal/PolicyExtrasRenewal';
import PolicyDetailWidgetRenewal from './directives/policy-detail-widget-renewal/PolicyDetailWidgetRenewal';



const appModule = angular.module('edge.and.your-renewal.app', [])


    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new StatesConfig(RootStateConfig);
        _.values(states).forEach(state => {
            $stateProvider.state(state);
        });
    }])
    .directive('andPolicyHolderInfoRenewal', PolicyHolderInfoRenewal)
    .directive('andPolicyDriverInfoRenewal', PolicyDriverInfoRenewal)
    .directive('andPolicyVehicleInfoRenewal', PolicyVehicleInfoRenewal)

    .directive('andPolicyExcessRenewal', PolicyQuoteExcessRenewal)
    .directive('andPolicyExtrasRenewal', PolicyExtrasRenewal)
    .directive('andPolicyDetailWidgetRenewal', PolicyDetailWidgetRenewal)


export default appModule;

import templateStr from 'text!./fill-card-details.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            data: '='
        },
        controller: ['$scope', '$rootScope', ($scope, $rootScope) => {
            // Default the Billing Address to the Policy Address
            $scope.data.bindingData.billingAddress = $rootScope.policyContext.currentPolicy.periods[0].policyAddress;
            // clearing country field as it shouldn't be set
            $scope.data.bindingData.billingAddress.country = undefined;

            $scope.firstNameTooltip = {
                content: 'and.topupmiles.directives.fill-card-details.tooltip.FirstName',
                trigger: 'click'
            };
            $scope.surnameTooltip = {
                content: 'and.topupmiles.directives.fill-card-details.tooltip.Surname',
                trigger: 'click'
            };
            $scope.next = (form) => {
                form.submitted = true;
                if (form.valid === true) {
                    $scope.data.goToNextScreen();
                }
            };
        }]
    };
}];
import billingSummaryTemplate from 'text!edge/billing/views/billing-summary.html';
import invoicePaymentTemplate from 'text!edge/billing/views/invoice-payment.html';
import makePaymentTemplate from 'text!edge/billing/views/make-payment.html';
import setupStreamAutopayTemplate from 'text!edge/billing/views/setup-stream-autopay.html';
import autoPayConfirmationTemplate from 'text!edge/billing/views/setup-autopay-confirmation.html';
import contactGatewayTemplate from 'text!edge/billing/views/contact-agent.html';
import BillingSummaryCtrl from 'edge/billing/controllers/BillingSummaryCtrl';
import InvoicePaymentCtrl from 'edge/billing/controllers/InvoicePaymentCtrl';
import MakePaymentCtrl from 'edge/billing/controllers/MakePaymentCtrl';
import SetupStreamAutopayCtrl from 'edge/billing/controllers/SetupStreamAutopayCtrl';
import SetupAutoPayConfirmationCtrl from 'edge/billing/controllers/SetupAutoPayConfirmationCtrl';
import contactGatewayCtrl from 'edge/billing/controllers/ContactAgentCtrl';

export default (AppConfig) => {
    const parentState = AppConfig.billing;
    const homeState = AppConfig.home;
    const billingState = 'billing-';

    return {
        'billing-summary': {
            name: `${billingState}summary`,
            url: '/billing-summary/{streamId}',
            parent: parentState,
            controller: BillingSummaryCtrl,
            template: billingSummaryTemplate
        },
        'billing-invoice-payment': {
            name: `${billingState}invoice-payment`,
            url: '/pay-invoices/{payMode}',
            parent: parentState,
            template: invoicePaymentTemplate,
            controller: InvoicePaymentCtrl,
            data: {
                actions: {
                    makePayment: `${billingState}make-payment-confirmation`,
                    cancel: homeState
                }
            }
        },
        'billing-invoice-payment-direct': {
            name: `${billingState}invoice-payment-direct`,
            url: '/pay-invoices/stream/{streamId}',
            parent: parentState,
            template: invoicePaymentTemplate,
            controller: InvoicePaymentCtrl,
            data: {
                actions: {
                    makePayment: `${billingState}make-payment-confirmation`,
                    cancel: homeState
                }
            }
        },
        'billing-setup-group-autopay': {
            name: `${billingState}setup-group-autopay`,
            url: '/setup-group-autopay/{streamId}',
            parent: parentState,
            template: setupStreamAutopayTemplate,
            controller: SetupStreamAutopayCtrl,
            data: {
                actions: {
                    setupAutoPay: `${billingState}setup-autopay-confirmation`,
                    cancel: homeState
                }
            }
        },
        'billing-setup-autopay-confirmation': {
            name: `${billingState}setup-autopay-confirmation`,
            url: '/setup-autopay-confirmation',
            parent: parentState,
            template: autoPayConfirmationTemplate,
            controller: SetupAutoPayConfirmationCtrl,
            data: {
                actions: {
                    cancel: homeState
                }
            },
            params: {
                info: {
                    value: null
                }
            }
        },
        'billing-contact-agent': {
            parent: parentState,
            name: 'billing-contact-agent',
            template: contactGatewayTemplate,
            controller: contactGatewayCtrl,
            data: {
                actions: {
                    next: homeState
                }
            }
        },
        'billing-make-payment': {
            parent: parentState,
            name: 'make-payment',
            url: '/make-payment',
            template: makePaymentTemplate,
            controller: MakePaymentCtrl,
            data: {
                actions: {
                    makePayment: `${billingState}make-payment-confirmation`,
                    cancel: homeState
                }
            }
        }
    };
};
import _ from 'underscore';
import StatesConfig from 'edge/policy/contact-us_AND/config/RouteConfig';
import ContactUsInfoTbb from './directives/contact-us-info-tbb/ContactUsTbb';
import ContactUsInfo from './directives/contact-us-info/ContactUs';

const appModule = angular.module('edge.and.contact-us.app', [])

    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new StatesConfig(RootStateConfig);
        _.values(states).forEach(state => {
            $stateProvider.state(state);
        });
    }])
    .directive('andContactUsInfoTbb', ContactUsInfoTbb)
    .directive('andContactUsInfo', ContactUsInfo)
;

export default appModule;

import templateStr from 'text!./contact-us.html';

export default [() => {
    return {
        restrict: 'A',
        scope: {
            title: '@?',
            desc: '@?'
        },
        template: templateStr,
        controller: ['$scope', ($scope) => {
            $scope.isMTAPage = ($scope.desc === 'and.policycommon.contactus.InfoMTA')
        }]
    };
}];
import _ from 'underscore';
import {policyTypesWhiteList} from 'gw-portals-featuredetection-js/AllowedPolicyTypes';
import PAClaimExtension from 'edge/fnol/pa/models/PAClaimExtension';
import CAClaimExtension from 'edge/fnol/ca/models/CAClaimExtension';
import HOClaimExtension from 'edge/fnol/ho/models/HOClaimExtension';
import WCClaimExtension from 'edge/fnol/wc/models/WCClaimExtension';
import CPClaimExtension from 'edge/fnol/cp/models/CPClaimExtension';
import GeneralClaimExtension from 'edge/fnol/general/models/GeneralClaimExtension';
import PmClaimExtension from 'edge/fnol/pm/models/PmClaimExtension'; // ANDIE

export default {
    mixinLobDraftDataExtensions: (claim) => {
        if (!claim.policy) {
            return;
        }
        claim.lobs = (_.isObject(claim.lobs)) ? claim.lobs : {};

        const {policyType} = claim.policy;
        switch (true) {
            case policyTypesWhiteList.PersonalAuto.includes(policyType):
                claim.lobs.personalAuto = new PAClaimExtension(claim);
                break;
            case policyTypesWhiteList.CommercialAuto.includes(policyType):
                claim.lobs.commercialAuto = new CAClaimExtension(claim);
                break;
            case policyTypesWhiteList.HomeOwners.includes(policyType):
                claim.lobs.homeowners = new HOClaimExtension(claim);
                break;
            case policyTypesWhiteList.WorkersComp.includes(policyType):
                claim.lobs.workersComp = new WCClaimExtension(claim);
                break;
            case policyTypesWhiteList.CommercialProperty.includes(policyType):
                claim.lobs.commercialProperty = new CPClaimExtension(claim);
                break;
            case policyTypesWhiteList.GeneralLiability.includes(policyType):
                claim.lobs.generalLiability = new GeneralClaimExtension(claim, 'generalLiability');
                break;
            case policyTypesWhiteList.BusinessOwners.includes(policyType):
                claim.lobs.businessOwners = new GeneralClaimExtension(claim, 'businessOwners');
                break;
            case policyTypesWhiteList.InlandMarine.includes(policyType):
                claim.lobs.inlandMarine = new GeneralClaimExtension(claim, 'inlandMarine');
                break;
            case policyTypesWhiteList.PersonalMotor.includes(policyType): // ANDIE
                claim.lobs.personalMotor = new PmClaimExtension(claim);
                break;
            default:
                break;
        }
    },

    setLossDetails: (claim) => {
        const {policyType} = claim.policy;
        switch (true) {
            case policyTypesWhiteList.PersonalAuto.includes(policyType):
                claim.lossType = 'AUTO';
                claim.lossCause = 'vehcollision';
                break;
            case policyTypesWhiteList.CommercialAuto.includes(policyType):
                claim.lossType = 'AUTO';
                claim.lossCause = 'vehcollision';
                break;
            case policyTypesWhiteList.HomeOwners.includes(policyType):
                claim.lossType = 'PR';
                claim.lossCause = 'fire';
                break;
            case policyTypesWhiteList.GeneralLiability.includes(policyType):
                claim.lossType = 'GL';
                claim.lossCause = undefined;
                break;
            case policyTypesWhiteList.BusinessOwners.includes(policyType):
                claim.lossType = 'GL';
                claim.lossCause = undefined;
                break;
            case policyTypesWhiteList.InlandMarine.includes(policyType):
                claim.lossType = 'PR';
                claim.lossCause = undefined;
                break;
            case policyTypesWhiteList.WorkersComp.includes(policyType):
                claim.lossType = 'WC';
                claim.lossCause = undefined;
                break;
            case policyTypesWhiteList.CommercialProperty.includes(policyType):
                claim.lossType = 'PR';
                claim.lossCause = undefined;
                break;
            case policyTypesWhiteList.PersonalMotor.includes(policyType): // ANDIE
                claim.lossType = 'AUTO';
                claim.lossCause = 'Accident_itb';
                break;
            default:
                break;
        }
    }
};
import template from 'text!./button.html';

export default [() => ({
    restrict: 'E',
    replace: true,
    template: template,
    transclude: true,
    scope: {
        onClick: '&',
        icon: '@',
        disabled: '<',
        flavor: '@',
        customID: '@?customId', // ANDIE
    },
    link: (scope, element, attrs) => {
        scope.primary = 'primary' in attrs;
        scope.isIconSort = () => {
            return scope.icon === 'sort-desc';
        };
    }
})];

import _ from 'underscore';

let numberOfChanges = 0;
let listeners = [];

function unsubscribe(callback) {
    listeners = _.without(listeners, _.find(listeners, callback));
}

/**
 * A service that allowes clients to set, get and subscribe
 * to a number of changes in a shopping cart.
 *
 * Implements a very simple observable pattern
 *
 * @returns {Object}
 */
export default [() => {
    return {
        subscribe: (callback) => {
            listeners.push(callback);
            return unsubscribe.bind(null, callback);
        },
        set: (num) => {
            numberOfChanges = num;
            if (listeners.length) {
                listeners.forEach((listener) => {
                    listener(num);
                });
            }
        },
        get: () => {
            return numberOfChanges;
        }
    };
}];

import _ from 'underscore';
import 'edge/endorsement/common/models/PolicyChange';
import PmDriver from 'edge/endorsement/pm/models/PmDriver';
import PmVehicle from 'edge/endorsement/pm/models/PmVehicle';

var nextId = void 0;

var PmCoverables = function PmCoverables(data) {
    if (data && _.isObject(data)) {
        this.pmDrivers = _.map(data.pmDrivers, function (d) {
            return new PmDriver(d);
        }, this);

        this.pmVehicles = _.map(data.pmVehicles, function (v) {
            return new PmVehicle(v);
        }, this);

        this.convictionCodes = data.convictionCodes;
    } else {
        this.pmDrivers = [];
        this.pmVehicles = [];
    }
};

PmCoverables.prototype.createDriver = function () {
    var driver = new PmDriver();
    // extend
    driver.tempID = nextId();
    driver.isNew = true;
    this.pmDrivers.push(driver);
    return driver;
};

PmCoverables.prototype.createFromAvailableDriver = function (availableDriver) {
    var driver = new PmDriver(availableDriver);
    // extend
    driver.isNew = true;
    this.pmDrivers.push(driver);
    return driver;
};

PmCoverables.prototype.addDriver = function (driver) {
    this.pmDrivers.push(driver);
};

PmCoverables.prototype.removeDriver = function (driver) {
    var idx = _.indexOf(this.pmDrivers, driver);
    if (idx >= 0) {
        _.each(this.pmVehicles, function (v) {
            this.removeDriverAssignment(driver, v);
        }, this);
        this.pmDrivers.splice(idx, 1);
    }
};

PmCoverables.prototype.createVehicle = function (data) {
    var init = {
        tempId: nextId(),
        marketValue: {},
        voluntaryExcess: 0
    };
    if (data) {
        init = Object.assign(init, data);
    }
    var v = new PmVehicle(init);
    this.pmVehicles.push(v);
    return v;
};

PmCoverables.prototype.addVehicle = function (vehicle) {
    this.pmVehicles.push(vehicle);
};

PmCoverables.prototype.removeVehicle = function (vehicle) {
    var idx = _.indexOf(this.pmVehicles, vehicle);
    if (idx >= 0) {
        this.pmVehicles.splice(idx, 1);
    }
};

PmCoverables.prototype.isDriverAssigned = function (driver, vehicle) {
    return !!_.findWhere(this.pmVehicleDrivers, {
        driver: driver, vehicle: vehicle
    });
};

PmCoverables.prototype.getAssignedDrivers = function (vehicle) {
    return _.pluck(_.where(this.pmVehicleDrivers, {
        vehicle: vehicle
    }), 'driver');
};

PmCoverables.prototype.getAssignedVehicles = function (driver) {
    return _.pluck(_.where(this.pmVehicleDrivers, {
        driver: driver
    }), 'vehicle');
};

PmCoverables.prototype.assignDriver = function (driver, vehicle) {
    var assignment = _.findWhere(this.pmVehicleDrivers, {
        driver: driver, vehicle: vehicle
    });
    if (!assignment) {
        this.pmVehicleDrivers.push({
            driver: driver, vehicle: vehicle
        });
    }
};

PmCoverables.prototype.removeDriverAssignment = function (driver, vehicle) {
    var assignment = _.findWhere(this.pmVehicleDrivers, {
        driver: driver, vehicle: vehicle
    });
    if (assignment) {
        this.pmVehicleDrivers.splice(this.pmVehicleDrivers.indexOf(assignment), 1);
    }
};

function _buildHistory(policyChange, lob) {
    for (var i = 0; i < policyChange.history.length; i++) {
        var historyItem = policyChange.history[i];
        if (historyItem.entityType === 'driver') {
            historyItem.entity = _.findWhere(lob.coverables.pmDrivers, {
                fixedId: historyItem.fixedId
            });
        } else if (historyItem.entityType === 'vehicle') {
            historyItem.entity = _.findWhere(lob.coverables.pmVehicles, {
                fixedId: historyItem.fixedId
            });
        }
    }
}

var PmPolicyChangeDataExtension = function PmPolicyChangeDataExtension(policyChange) {
    nextId = policyChange.nextId;

    var pmData = policyChange.lobData.personalMotor || { coverables: {} };
    this.coverables = new PmCoverables(pmData.coverables);

    if (policyChange.history) {
        _buildHistory(policyChange, this);
    } else {
        policyChange.history = [];
    }

    this.offerings = pmData.offerings;
};

PmPolicyChangeDataExtension.prototype.beforeSave = function () {};

PmPolicyChangeDataExtension.prototype.validate = function () {
    return true;
};

PmCoverables.prototype.getPHDriver = function () {
    return this.pmDrivers.find(function (driver) {
        return driver.isPolicyHolder === true;
    });
};

export default PmPolicyChangeDataExtension;
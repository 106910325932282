import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './radioImageTile-hashed.scss';
import controller from './RadioImageTileCtrl';
import templateStr from 'text!./RadioImageTile.html';

export default {
    template: cssUtil.hashTemplate(templateStr, styles),
    bindings: {
        model: '=',
        lossCauseTileType: '@',
        image: '@',
        text: '@',
        customID: '@?customId', // ANDIE
    },
    controller: controller,
};
// import PAProductCodeUtil from 'conditional?if=endorsement-products&equals=PersonalAuto!edge/endorsement/pa/PAProductCodeUtil';
// import HOProductCodeUtil from 'conditional?if=endorsement-products&equals=Homeowners!edge/endorsement/ho/HOProductCodeUtil';
import PmProductCodeUtil from 'conditional?if=endorsement-products&equals=PersonalMotor!edge/endorsement/pm/PmProductCodeUtil';

export default {
        getGenericProductCode: (productCode) => {
        let normalisedProductCode = productCode;
        /* ANDIE
        if (PAProductCodeUtil) {
            normalisedProductCode = PAProductCodeUtil.normaliseProductCode(normalisedProductCode);
        }
        if (HOProductCodeUtil) {
            normalisedProductCode = HOProductCodeUtil.normaliseProductCode(normalisedProductCode);
        }*/
        if (PmProductCodeUtil) {
            normalisedProductCode = PmProductCodeUtil.normaliseProductCode(normalisedProductCode);
        }
        return normalisedProductCode;
    },
    getLobKeyValue: (key, productCode) => {
        let normalisedKey = key;
        /* ANDIE
        if (PAProductCodeUtil) {
            normalisedKey = PAProductCodeUtil.getLobSpecificKeyValue(key, productCode);
        }
        if (HOProductCodeUtil) {
            normalisedKey = HOProductCodeUtil.getLobSpecificKeyValue(key, productCode);
        }*/
        if (PmProductCodeUtil) {
            normalisedKey = PmProductCodeUtil.getLobSpecificKeyValue(key, productCode);
        }
        return normalisedKey;
    }
};
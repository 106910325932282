import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, submissionViewModel) => {
    const ctrl = Object.create(BaseCtrl);
    const submissionVM = submissionViewModel();

    ctrl.init = () => {
        $scope.submissionVM = submissionVM;
        if (!submissionVM.bindData.paymentDetails.value) {
            submissionVM.bindData.paymentDetails.value = {};
        }
        if (!submissionVM.bindData.paymentDetails.creditCardData.value) {
            submissionVM.bindData.paymentDetails.creditCardData.value = {};
        }
    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', 'submissionViewModel'];
export default fn;
import templateStr from 'text!./templates/claim-detail-activities.html';
import 'portalConfig';

export default ['$stateParams', '$filter', 'claim.ClaimService', 'InsuranceSuiteMetadata', ($stateParams, $filter, ClaimService, InsuranceSuiteMetadata) => {
    const activityStatusTypeList = InsuranceSuiteMetadata.get('cc').types.getTypelist('ActivityStatus');
    const priorityTypeList = InsuranceSuiteMetadata.get('cc').types.getTypelist('Priority');

    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            claimNumber: '='
        },

        link: (scope) => {
            scope.activities = [];

            const activitiesPromise = ClaimService.getClaimActivities(scope.claimNumber);
            activitiesPromise.then(
                (activties) => {
                    scope.activities = activties;
                },
                (error) => {
                    console.error('Unable to retrieve activities', error);
                }
            );

            scope.claimNumber = $stateParams.claimNumber;
            scope.tableConfig = {
                paginationSize: 1000
            };
            scope.activitiesQuery = '';
            scope.activities = scope.activities || {};
            scope.filteredActivities = scope.activities;

            scope.$watch('activities', () => {
                scope.filteredActivities = scope.activities;
            });
            scope.$watch('activitiesQuery', () => {
                scope.applyFilter();
            });

            scope.getAssignedByName = (user) => {
                const firstName = (user.firstName) ? user.firstName : null;
                const lastName = (user.lastName) ? user.lastName : null;
                if (firstName === null && lastName !== null) {
                    return lastName;
                } else if (firstName !== null && lastName === null) {
                    return firstName;
                }
                return `${firstName} ${lastName}`;
            };

            scope.applyFilter = () => {
                scope.filteredActivities = $filter('filter')(scope.activities, {
                    subject: scope.activitiesQuery
                });
            };

            scope.getActivityPriority = (type) => {
                return priorityTypeList.getCode(type).name;
            };

            scope.getActivityStatus = (status) => {
                return activityStatusTypeList.getCode(status).name;
            };

            scope.showAllActivities = () => {
                scope.tableConfig.paginationSize = scope.activities.length;
            };

            scope.resetPagination = () => {
                scope.tableConfig.paginationSize = scope.tableConfig.defaultPaginationSize;
            };
        }

    };
}];

import templateStr from 'text!./select-mile-option.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            data: '='
        },
        controller: ['$scope', ($scope) => {
            const init = () => {
                $scope.data.selectedOption = null;
                $scope.next = () => {
                    if ($scope.data.selectedOption) {
                        $scope.data.goToNextScreen();
                    }
                };
                $scope.toggleOption = (option) => {
                    if ($scope.data.selectedOption === option) {
                        $scope.data.selectedOption = null;
                    } else {
                        $scope.data.selectedOption = option;
                    }
                };
            };
            init();
        }]
    };
}];
import templateStr from 'text!./statistics.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {},
        controller: ['$scope', 'TelematicsService_AND', ($scope, TelematicsService_AND) => {
            TelematicsService_AND.getBonusStatistics().then((data) => {
                $scope.data = data;
            });
        }]
    };
}];

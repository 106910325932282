import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = function (
    $scope, $state, $stateParams, wizardFlowService,
    qnbCompositeFlowDefn, ViewModelService, viewModelContext, returnStateFn,
    errorStateFn, effectiveDateStateFn, RenewalService_AND, $rootScope
) {
    const ctrl = Object.create(BaseCtrl);
    const parentWizardState = 'baseTransactionWizard';

    ctrl.init = function () {
        const stepToStateMapping = {
            returnHome: model => returnStateFn(model),
            confirmation: (model) => ['qnbconfirmation', {
                quoteSummary: model.value,
                returnStateInfo: returnStateFn(model.value)
            }],
            error: model => errorStateFn(model),
            effectiveDate: model => effectiveDateStateFn(model)
        };
        const flowfactory = wizardFlowService(qnbCompositeFlowDefn, parentWizardState, stepToStateMapping);
        if ($stateParams.policyNumber) { // Resume existing submission
            const submissionLoadPromise = RenewalService_AND.startRenewalWizard($stateParams.policyNumber, $stateParams.isBuying, $stateParams.isBase);
            $scope.submissionLoadPromise = submissionLoadPromise;
            submissionLoadPromise.then(qdd => {
                const submissionVM = ViewModelService.create(qdd, 'pc', 'itb.edge.capabilities.renewal.dto.RenewalDataDTO', viewModelContext);
                $rootScope.policyContext.isInRenewalWizard = true; // Manually set Renewal wizard
                flowfactory.resume(submissionVM, $stateParams);
            }).catch((error) => {
              error = error[0];
              if (error && error.baseError && error.baseError.error && error.baseError.error.data) {
                  switch (error.baseError.error.data.appErrorCode) {
                      case 602: // GW_UNDERWRITING_ERROR
                      case 631: // AND_HIGH_RISK_VALIDATION_EXCEPTION
                          $state.go('uwError_AND')
                          break;
                      default: // Technical error
                          $state.go('error');
                  }
              } else {
                  $state.go('contactUs');
              }
          });
        }
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state', '$stateParams', 'WizardFlowService',
    'qnb.CompositeFlowDefn', 'ViewModelService', 'qnb.ViewModelCtx', 'qnb.returnStateFn',
    'qnb.errorStateFn', 'qnb.effectiveDateStateFn', 'RenewalService_AND', '$rootScope'
];

export default fn;

import templateStr from 'text!./pm-important-info.html';

const TRANSLATION_KEY = 'and.quoteandbind.pm.directives.templates.pm-important-info';
const BRAND_SECTIONS = {tbb: 9, dgt: 9, itb: 9};
const BRAND_ANDUK = {tbb: 7, dgt: 7, itb: 7};

export default ['brandingData', (brandingData) => {
    return {
        restrict: 'E',
        scope: {},
        template: templateStr,
        controller: ['$scope', '$rootScope', ($scope, $rootScope) => {
            if (!BRAND_SECTIONS[brandingData]) {
                return;
            }

            $scope.accordion = [];
            for (let i = 0; i < BRAND_SECTIONS[brandingData]; i++) {
                if (i === BRAND_ANDUK[brandingData] && $rootScope.policyContext.isTodayBeforeAndUk()) {
                    $scope.accordion.push({
                        title: `${TRANSLATION_KEY}.${i}.Title`,
                        content: `${TRANSLATION_KEY}.${i}.Content.ANDUK`
                    });
                } else {
                    $scope.accordion.push({
                        title: `${TRANSLATION_KEY}.${i}.Title`,
                        content: `${TRANSLATION_KEY}.${i}.Content`
                    });
                }
            }
        }]
    };
}];
import _ from 'underscore';

function VehicleIncident(_claim, _paClaimExtension, _vehicleIncident) {
    Object.defineProperty(this, '_claim', {
        enumerable: false,
        value: _claim
    });

    Object.defineProperty(this, '_paClaimExtension', {
        enumerable: false,
        value: _paClaimExtension
    });

    if (_vehicleIncident) {
        _.extend(this, _vehicleIncident);
    } else {
        this.driver = null;
        this.vehicle = null;
        this.passengers = [];
    }
}


VehicleIncident.prototype.availableVehicles = function () {
    return this._paClaimExtension.availableVehicles(this.vehicle);
};

VehicleIncident.prototype.availableDrivers = function () {
    return this._paClaimExtension.availableDrivers(this.driver);
};

VehicleIncident.prototype.addPassenger = function (passenger) {
    if (!passenger) {
        passenger = this._claim.createContact();
    }
    this.passengers.unshift(passenger);
};

VehicleIncident.prototype.setNewDriver = function () {
    this.driver = this._claim.createContact();
    this.driver.initializePrimaryAddress();
};

VehicleIncident.prototype.setNewVehicle = function () {
    this.vehicle = this._paClaimExtension.createVehicle();
};

VehicleIncident.prototype.removePassenger = function (p) {
    const idx = this.passengers.indexOf(p);
    this.passengers.splice(idx, 1);
};

VehicleIncident.prototype.getAvailablePassengers = function () {
    return this._paClaimExtension.availablePassengers();
};

export default VehicleIncident;

import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./SignUp.html';
import styles from './SignUp-hashed.scss';
import ComponentUtil from 'gw-portals-util-js/ComponentUtil';
import AuthenticationService from 'plugin?auth-type!gw-portals-auth-js/AuthenticationService';

// VARS
const hashedTemplate = cssUtil.hashTemplate(template, styles);

// EXPORT

export default {
    template: hashedTemplate,
    controller: [
        '$scope', '$state', 'oAuth', 'state_plugin_config',
        function ($scope, $state, oAuth, STATE_PLUGIN_CONFIG) {
            // VARS
            const $ctrl = this;
            const authenticationService = AuthenticationService(oAuth);

            // METHODS
            const setCtrlParam = ComponentUtil.setCtrlParam($ctrl, $scope);

            function setErrorMessage(value) {
                setCtrlParam('errorMessage', value);
            }

            // Default model attr required by gw-pl-inline-ctrl-group directive
            $ctrl.input = {aspects: {}};
            $ctrl.email = {
                aspects: {
                    get valid() {
                        const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/;
                        return regex.test($ctrl.email.value);
                    }
                }
            };
            $ctrl.isResetReqInProgress = false;// animation

            $ctrl.signUp = () => {

                if (!$ctrl.firstName || !$ctrl.lastName || !$ctrl.email.value || !$ctrl.password) {
                    setErrorMessage('fieldEmpty');
                    return;
                }

                if (!$ctrl.email.aspects.valid) {
                    setErrorMessage('invalidEmailFormat');
                    return;
                }

                if ($ctrl.password !== $ctrl.confirmPassword) {
                    setErrorMessage('fieldsIncorrect');
                    return;
                }
                setCtrlParam('isRequestInProgress', true);

                authenticationService.signUp({
                    givenName: $ctrl.firstName,
                    familyName: $ctrl.lastName,
                    userName: $ctrl.email.value,
                    email: $ctrl.email.value,
                    password: $ctrl.password
                }).then(() => {
                    authenticationService.login({
                        username: $ctrl.email.value,
                        password: $ctrl.password
                    })
                        .then(() => {
                            // redirect user to home view
                            $state.go(STATE_PLUGIN_CONFIG.home);
                        }, () => {
                            setCtrlParam('status', 'AUTO_LOGIN_FAILURE');
                        });
                }).catch((err) => {
                    switch (err.error) {
                        case authenticationService.ERRORS.tokens.userAlreadyExists:
                            setCtrlParam('status', 'EMAIL_ALREADY_EXISTS');
                            break;
                        default:
                            setCtrlParam('status', 'INVALID_ATTEMPT');
                            break;
                    }
                });
            };

            $ctrl.signIn = () => {
                $state.go('authLogin');
            };
        }
    ]
};
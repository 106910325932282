export default ['$q', 'JsonRPCService', 'brandingData', ($q, JsonRPCService, brandingData) => {
    return {
        updateEmailAddress: (email) => {
            return JsonRPCService.send('pc', 'profileinfo/user', 'updateEmailAddress', [email, brandingData]);
        },
        updatePhoneNumbers: (userProfileDTO) => {
            console.log(userProfileDTO)
            return JsonRPCService.send('pc', 'profileinfo/user', 'updatePhoneNumbers', [userProfileDTO]);
        },
        updateDocsPreference: (docsPref) => {
            return JsonRPCService.send('pc', 'profileinfo/user', 'updateDocsPreference', [docsPref]);
        },
        updateCorrespondenceAddress: (addressDTO) => {
            return JsonRPCService.send('pc', 'profileinfo/user', 'updateCorrespondenceAddress', [addressDTO]);
        },
        updateGamificationPreference: (userProfileDTO) => {
            return JsonRPCService.send('pc', 'profileinfo/user', 'updateGamificationPreference', [userProfileDTO]);
        },
        updateMarketingPreferenceNew: (email, post, mobileApp, landline, voiceMobile, sms, optOut) => {
            return JsonRPCService.send('pc', 'profileinfo/user', 'updateMarketingPreferenceNew', [email, post, mobileApp, landline, voiceMobile, sms, optOut]);
        },
        getAccountInfo: () => {
            return JsonRPCService.send('pc', 'profileinfo/user', 'getAccountInfo', []);
        },
        getSavedQuotes: () => {
            return JsonRPCService.send('pc', 'profileinfo/user', 'getSavedQuotes', []);
        }
    };
}];

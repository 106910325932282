import BaseCtrl from 'edge/platform/base/BaseCtrl';
import WizardUtil_AND from '../util/WizardUtil_AND';
import ProductCodeUtil from '../util/ProductCodeUtil';

const fn = function ($scope, brandingData, $rootScope) {
    const ctrl = Object.create(BaseCtrl);
    $rootScope.productCode = ProductCodeUtil.getProductCode(brandingData)
    ctrl.init = () => {
        $scope.wizardSteps = WizardUtil_AND.getWizardStepsReadonly();
    };

    return ctrl.create($scope);
};

fn.inject = ['$scope', 'brandingData', '$rootScope'];
export default fn;

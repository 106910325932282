import templateStr from 'text!./templates/autopay-summary.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            firstPayment: '='
        }
    };
}];
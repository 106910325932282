import _ from 'underscore';
import DateUtil from 'gw-portals-util-js/DateUtil';
import lobIconUtil from 'integration/platform/lobUtil/lobIconUtil';

/** Get a policy type icon by a policy type.
 * @param {String} policyType policy type code.
 * @returns {string} policy type icon name.
 */
function getPolicyTypeIconName(policyType) {
    return lobIconUtil.getImageIcon(policyType);
}

/**
 * Get a policy icon or a policy type.
 * @param {String} policyType policy type.
 * @returns {String} policy type icon path.
 */
function getPolicyTypeIconPath(policyType) {
    return `../styles/images/common/lob-icons/${getPolicyTypeIconName(policyType)}.png`;
}

export default {
    getPolicyTypeIconName,
    getPolicyTypeIconPath,

    /**
     * Returns a policy icon name for a policy.
     * @param {Object} policy
     *
     * @returns {String}
     */
    getPolicyIconName: (policy) => {
        return getPolicyTypeIconName(policy.policyType);
    },

    /**
     * Returns an icon for the policy.
     *
     * @param {Object} policy
     * @returns {String}
     */
    getPolicyIconPath: (policy) => {
        return getPolicyTypeIconPath(policy.policyType);
    },

    policyAddressAsString: (item) => {
        return item ? item.address : '';
    },

    /**
     * Checks if date in future.
     * @param {Date|undefined} date
     * @returns {boolean}
     */
    dateIsInFuture: (date) => {
        if (!date) {
            return false;
        }

        const currentTime = DateUtil.currentDate().getTime();
        return date.getTime() > currentTime;
    },

    /**
     * Filter policies by features (lob).
     * @param {Object} policies
     * @param {Array} policyTypes
     * @returns {Array}
     */
    filterPoliciesByFeature: (policies, policyTypes) => {
        const filteredPolicies = [];
        for (let i = 0; i < policyTypes.length; i++) {
            const tempPolicies = _.where(policies, {
                policyType: policyTypes[i]
            });
            for (let x = 0; x < tempPolicies.length; x++) {
                filteredPolicies.push(tempPolicies[x]);
            }
        }
        return filteredPolicies;
    },

    /**
     * Converts UI search criteria to dto.
     * @param {*} lossDate
     * @param {Object} uiCriteria
     * @param {String} defaultCountryCode
     * @returns {Object}
     */
    createAdvancedSearchCriteria: (lossDate, uiCriteria, defaultCountryCode) => {
        return {
            policyNumber: null,
            lossDate,
            firstName: uiCriteria.firstName,
            lastName: uiCriteria.lastName,
            policyType: (uiCriteria.policyType) ? uiCriteria.policyType : null,
            city: uiCriteria.city,
            state: (uiCriteria.state) ? uiCriteria.state : null,
            country: defaultCountryCode,
            zip: uiCriteria.zip
        };
    },

    searchIsBlank: (uiCriteria) => {
        if (uiCriteria.firstName) {
            return false;
        } else if (uiCriteria.lastName) {
            return false;
        } else if (uiCriteria.policyType) {
            return false;
        } else if (uiCriteria.city) {
            return false;
        } else if (uiCriteria.state) {
            return false;
        } else if (uiCriteria.zip) {
            return false;
        }
        return true;
    }
};
import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./RepairFacilitiesList.html';
import styles from './RepairFacilitiesList-hashed.scss';
import controller from './RepairFacilitiesListCtrl';

export default {
    template: cssUtil.hashTemplate(template, styles),
    controller,
    bindings: {
        onListFacilitySelect: '&',
        selectedRepairFacility: '<',
        repairFacilities: '<',
        searchCenter: '<'
    }
};

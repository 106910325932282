import EditNoteModal from './EditNoteModal';
import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($filter, $scope, $state, $stateParams, $modal, ModalService, ActivityService, ViewModelService) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        // $scope.activityBanner = 'Edit Activity';
        $scope.activityID = $stateParams.activity;
        $scope.showLoading = false;


        const activityPromise = ActivityService.getActivity($scope.activityID);
        activityPromise.then(
            (activity) => {
                $scope.activity = ViewModelService.create(activity, 'cc', 'edge.capabilities.claim.activity.dto.ActivityDTO');
            },
            (error) => {
                console.error('activity retrieval failed', error);
                ModalService.showError(
                    'claim.controllers.ActivityDetailCtrl.Activity retrieval failed',
                    'claim.controllers.ActivityDetailCtrl.Sorry, we were not able to find the activity related to your claim.'
                );
            }
        );


        const notesPromise = ActivityService.getActivityNotes($scope.activityID);
        notesPromise.then(
            (notes) => {
                $scope.notes = notes;
            },
            (error) => {
                console.error('note retrieval failed', error);
            }
        );
    };

    ctrl.completeActivity = () => {
        $scope.showLoading = true;
        const submitNotePromise = ActivityService.completeActivity($scope.activityID);
        submitNotePromise.then((activity) => {
            $scope.activity = ViewModelService.create(activity, 'cc', 'edge.capabilities.claim.activity.dto.ActivityDTO');
            const notesPromise = ActivityService.getActivityNotes($scope.activityID);
            notesPromise.then(
                (notes) => {
                    $scope.notes = notes;
                    $scope.showLoading = false;
                },
                (error) => {
                    console.error('note retrieval failed', error);
                }
            );
        });
    };

    ctrl.createActivityNote = () => {
        $modal.open(new EditNoteModal($scope.activity)).result.then((newNote) => {
            $scope.notes.push(newNote);
        });
    };


    return ctrl.create($scope);
};
fn.$inject = ['$filter', '$scope', '$state', '$stateParams', '$modal', 'ModalService', 'claim.ActivityService', 'ViewModelService'];

export default fn;

import template from 'text!./templates/effective-date.html';

export default [
    () => ({
        'scope': {
            'model': '=',
            'mode': '=',
            'minDate': '=',
            'maxDate': '=',
            'onNext': '&',
            'wizardState': '@'
        },
        template,
        controller: ['$scope', ($scope) => {
            $scope.editableStates = [
                'PolicyList',
                'EffectiveDate',
                'WhatsNext'
            ];
        }]
    })
];
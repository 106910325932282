import templateStr from 'text!./pm-quote-review-cover.html';
import FormUtil from 'edge/platform/modelvalidation/FormUtil';

export default [() => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '='
        },
        template: templateStr,
        controller: ['$scope', '$filter', 'ViewModelService', 'qnb.ViewModelCtx', '$q', 'StaticDocumentsService_AND', ($scope, $filter, ViewModelService, viewModelContext, $q, StaticDocumentsService_AND) => {
            const submission = $scope.quoteData.submission.value;
            // Period Start Date
            $scope.minPeriodStartDate = submission.getMinPeriodStartDate_AND();
            $scope.maxPeriodStartDate = submission.getMaxPeriodStartDate_AND();
            const retrievalDTO = ViewModelService.create({
                periodStartDate: submission.baseData.periodStartDate
            }, 'pc', 'edge.capabilities.quote.submission.dto.CustomQuoteDTO', viewModelContext);
            $scope.periodStartDateVM = retrievalDTO.periodStartDate;
            Object.defineProperty($scope.periodStartDateVM, 'required', {
                get: function () {
                    return true;
                }
            });
            // Voluntary Excess
            $scope.vehicle = submission.lobData.personalMotor.coverables.pmVehicles[0];
            $scope.volExcessVM = {
                value: $scope.vehicle.voluntaryExcess,
                aspects: {
                    required: true,
                    availableValues: $scope.vehicle.voluntaryExcessOptions,
                    get valid() {
                        return angular.isDefined($scope.volExcessVM.value) && $scope.volExcessVM.value !== null;
                    },
                    get validationMessages() {
                        if (this.valid === false) {
                            return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                        }
                        return [];
                    }
                }
            };
            // Docs
            $q.all([
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_POLICY, submission.baseData.periodStartDate),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_NOTICE, submission.baseData.periodStartDate),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.COOKIE_POLICY, submission.baseData.periodStartDate),
            ]).then((result) => {
                $scope.privacyPolicyDoc = result[0];
                $scope.privacyNoticeDoc = result[1];
                $scope.cookiePolicyDoc = result[2];
            });

            $scope.continue = (form) => {
                FormUtil.markFormsSubmitted(form);
                // form.submitted = true;
                if ($scope.volExcessVM.aspects.valid === true &&
                    $scope.periodStartDateVM.aspects.valid === true && $scope.periodStartDateVM.aspects.subtreeValid === true) {
                    $scope.quoteData.voluntaryExcess = $scope.volExcessVM.value;
                    $scope.quoteData.periodStartDate = $scope.periodStartDateVM.value;
                    $scope.quoteData.goToQuotingPage();
                    $scope.quoteData.updateQuote().then(() => { // Update current scope value
                        $scope.quoteData.submission.baseData.periodStartDate.value = $scope.periodStartDateVM.value;
                        $scope.vehicle.voluntaryExcess = $scope.quoteData.voluntaryExcess;
                        $scope.quoteData.goToQuotePage();
                    });
                }
            };

            $scope.undo = () => $scope.quoteData.goToQuotePage();
        }]
    };
}];
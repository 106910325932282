import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./AuthConfirmationMessage.html';
import styles from './AuthConfirmationMessage-hashed.scss';

// VARS
const hashedTemplate = cssUtil.hashTemplate(template, styles);

// EXPORT
export default {
    template: hashedTemplate,
    bindings: {
        title: '@',
        message: '@'
    },
    controller: [
        '$state',
        function ($state) {
            // VARS
            const $ctrl = this;
            $ctrl.backToSignIn = () => {
                $state.go('authLogin');
            };
        }
    ]
};

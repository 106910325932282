import _ from 'lodash';
import UserProfileRouteConfig from 'edge/usersprofile/config/RouteConfig';
import UsersProfileDetailsService from 'edge/usersprofile/services/UsersProfileDetailsService';
import UserProfileService_AND from 'edge/usersprofile/services/UserProfileService_AND';
import ProducerService from 'edge/usersprofile/services/ProducerService';
import AmpProfileContactDetails from 'edge/usersprofile/directives/AmpProfileContactDetails';
import UserDetails from 'edge/usersprofile/directives/UserDetails';
import UserDetailsApplyToAll from 'edge/usersprofile/directives/UserDetailsApplyToAll';
import BillingDetails from 'edge/usersprofile/directives/BillingDetails';
import BillingSameChoice from 'edge/usersprofile/directives/BillingSameChoice';
import 'angularRouter';
import 'edge/platform/map/module';
import 'edge/platform/widgets/modal/module';
import 'gw-portals-viewmodel-angular/aspects/module';
import 'edge/platform/widgets/basicinputs/module';
import 'portalConfig';

export default angular.module('edge.usersprofile.app', ['portal.config', 'gw-portals-viewmodel-angular.aspects',
    'edge.platform.widgets.basicinputs', 'edge.platform.widgets.inputs', 'ui.router', 'edge.platform.map'
])

    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new UserProfileRouteConfig(RootStateConfig);
        _.forEach(states, state => {
            $stateProvider.state(state);
        });
    }])

    .config(['EventHubProvider', (EventHubProvider) => {
        EventHubProvider.registerTransformer({
            source: {
                category: 'tracking',
                eventName: 'serverCall'
            },
            target: {
                category: 'journey',
                eventName: 'account'
            },
            transformer(payload, send) {
                if (payload && payload.success && payload.method === 'updateAccountContactSummary') {
                    send({
                        accountNumber: payload.params[0].accountNumber,
                        method: payload.method,
                        path: payload.path
                    });
                }
            }
        });
    }])
    .directive('gwAmpProfileContactDetails', AmpProfileContactDetails)
    .directive('gwUserDetails', UserDetails)
    .directive('gwUserDetailsApplyToAll', UserDetailsApplyToAll)
    .directive('gwBillingDetails', BillingDetails)
    .directive('gwBillingSameChoice', BillingSameChoice)
    .factory('UsersProfileDetailsService', UsersProfileDetailsService)
    .factory('UserProfileService_AND', UserProfileService_AND)
    .factory('ProducerService', ProducerService);

import templateStr from 'text!./purchase-product.html';
import rewardsTerms from 'text!./rewards-terms.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            points: '=',
            products: '<',
            purchase: '=',
            error: '=',
            rewardPending: '='
        },
        controller: ['$scope', 'ModalService', ($scope, ModalService) => {
            $scope.purchaseAccordion = {};

            $scope.isRewardPending = function () {
                return $scope.rewardPending;
            };

            $scope.openOrToggle = function (accordionID) {
                for (const key in $scope.purchaseAccordion) {
                    if (key !== accordionID) {
                        $scope.purchaseAccordion[key].open = false;
                    }
                }
            };
            
            $scope.showTerms = (product) => {
                ModalService.showSimpleDialog(rewardsTerms, {
                    product
                }, {}, null, {
                    fullscreen: true,
                    backdrop: true,
                    windowClass: 'and-modal'
                });
            };

        }]
    };
}];

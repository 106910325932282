import template from 'text!./policy-list.html';

export default ['MediaQueriesUtil', '$timeout', (MediaQueriesUtil, $timeout) => ({
    restrict: 'A',
    template: template,
    scope: {},
    controller: ['$rootScope', '$scope', ($rootScope, $scope) => {
        $rootScope.policyContext.getPolicies().then((summaries) => {
            $scope.policies = summaries.map((summary) => {
                const period = summary.periods[0];
                return {
                    id: period.policyId,
                    value: summary,
                    overview: period.overview
                };
            });
        });
        
        $scope.selectPolicy = (option) => {
            $rootScope.policyContext.selectPolicy(option.value);
        };
        
        // Used for paginating the policies
        $scope.currentPage = 0;
        const mediaQuery = MediaQueriesUtil.getTabletPortraitDownQuery();

        const isPhoneWidth = function (_mediaQuery) {
            if (_mediaQuery.matches) {
                $scope.pageSize = 3;
            } else {
                $scope.pageSize = 5;
            }
        };

        $timeout(()=> isPhoneWidth(mediaQuery));

        mediaQuery.addListener(isPhoneWidth);

        $scope.numberOfPages = () => {
            if ($scope.policies != null && $scope.pageSize) {
                return Math.ceil($scope.policies.length / $scope.pageSize);
            }
            else {
                return 0;
            }   
        };

        $scope.$on('$destroy', () => {
            mediaQuery.removeListener(isPhoneWidth);
        });
    }]
})];
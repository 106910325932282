import template from 'text!./templates/policy-list.html';
import allowedPolicyTypes from 'integration/endorsement/AllowedPolicyTypes';
import GenericProductCodeUtil from 'integration/endorsement/GenericProductCodeUtil';

export default [
    'endorsement.EndorsementService', 'ModalService', (EndorsementService, ModalService) => ({
        'scope': {
            'selectedPolicyNumber': '&',
            'model': '=',
            'mode': '=',
            'wizardState': '@',
            'onNext': '&',
            'onError': '&'
        },
        template,
        'controller': ['$scope', ($scope) => {
            const policyListMessages = {
                error: {
                    title: 'endorsement.common.directives.PolicyList.Unable to load policies',
                    message: 'endorsement.common.directives.PolicyList.An error occurred loading list of polices, please contact your agent'
                },
                progress: {
                    message: 'endorsement.common.directives.PolicyList.Loading list of policies...'
                }
            };
            const singlePolicyMessages = {
                error: {
                    title: 'endorsement.common.directives.PolicyList.Unable to load policy',
                    message: 'endorsement.common.directives.PolicyList.An error occurred loading policy'
                },
                progress: {
                    message: 'endorsement.common.directives.PolicyList.Loading policy'
                }
            };

            $scope.policies = [];

            const onError = message => () => {
                // Show error message and call parent error handler
                ModalService.showError(message.title, message.message).result.then(() =>
                    $scope.onError());
            };

            if ($scope.selectedPolicyNumber()) {
                $scope.policiesPromise = EndorsementService
                    .getAvailablePolicy($scope.selectedPolicyNumber())
                    .then(policy => {
                        $scope.policies = [policy];
                    })
                    .catch(onError(singlePolicyMessages.error));
            } else {
                // Get the list of policies and filter based on the LOB specified in the integrations
                $scope.policiesPromise = EndorsementService
                    .getAvailablePolicies()
                    .then(policies => {
                        $scope.policies = policies.filter(p => isPolicyEnabled(p));
                    })
                    .catch(onError(policyListMessages.error));
            }

            $scope.selectPolicy = policy => {
                // Select a policy if it's not disabled and the current state is policyselect
                if (policy.disabled || $scope.mode === 'tile') {
                    return;
                }
                $scope.model.selectedPolicyNumber = policy.policyNumber;
                $scope.goNext();
            };

            $scope.goNext = () => {
                $scope.onNext();
            };

            $scope.getPolicyState = policy => {
                const basicStates = [
                    'PolicyList',
                    'EffectiveDate',
                    'WhatsNext'
                ];

                if (!basicStates.some(state => state === $scope.wizardState)) {
                    return 'disabled';
                }
                if ($scope.model.selectedPolicyNumber === policy.policyNumber && $scope.mode === 'tile') {
                    return 'completed';
                }

                if (policy.disabled) {
                    return 'disabled';
                }

                return 'selectable';
            };

            $scope.getGenericProductIcon = productCode => {
                const productType = GenericProductCodeUtil.getGenericProductCode(productCode);
                let icon = '';
                if (productType === 'Homeowners') {
                    icon = 'fa-home';
                }

                if (productType === 'PersonalAuto') {
                    icon = 'fa-car';
                }

                if (productType === 'PersonalMotor') { // ANDIE
                    icon = 'fa-car';
                }
                return icon;
            };

            /**
             * Determine whether a policy is enabled
             * @param {Object} policy
             * @returns {boolean}
             */
            function isPolicyEnabled(policy) {
                return policy.policyNumber !== undefined && allowedPolicyTypes.names.includes(policy.productCode);
            }
        }]
    })
];
import GeneralWhatView from 'text!../views/general-what.html';
import GeneralWhatCtrl from 'edge/fnol/general/controllers/GeneralWhatCtrl';
import GeneralWhereView from 'text!../views/general-where.html';
import GeneralWhereCtrl from 'edge/fnol/general/controllers/GeneralWhereCtrl';
import GeneralBaseFnolWizardCtrl from 'edge/fnol/general/controllers/GeneralBaseFnolWizardCtrl';

export default function () {
    const generalBaseFnolWizard = {
        name: 'generalBaseFnolWizard',
        parent: 'baseFnolWizard',
        abstract: true,
        controller: GeneralBaseFnolWizardCtrl,
        template: '<div ui-view></div>',
        data: {
            trackingUrl: ''
        }
    };

    return {
        generalBaseFnolWizard: generalBaseFnolWizard,
        generalWhat: {
            name: 'generalWhat',
            parent: generalBaseFnolWizard,
            template: GeneralWhatView,
            controller: GeneralWhatCtrl
        },
        generalWhere: {
            name: 'generalWhere',
            parent: generalBaseFnolWizard,
            template: GeneralWhereView,
            controller: GeneralWhereCtrl
        }
    };
}

import templateStr from 'text!./pm-amend-driver-mta_AND.html';
import moment from 'moment';

const DRIVING_LICENCE_TYPE = {
    FullEU: 'FullEU',
    FullUK: 'FullUK',
    ProvisionalUK: 'ProvisionalUK',
    ProvisionalEU: 'ProvisionalEU'
};
const getMonthCode = (monthTypekeyVM) => {
    (monthTypekeyVM.priority / 10) - 1;
};
export default [() => {
    return {
        restrict: 'A',
        scope: {
            baseDriver: '=',
            driver: '=',
            page: '='
        },
        template: templateStr,
        controller: ['$scope', ($scope) => {
            $scope.licenceTypeOptions = $scope.driver.licenseType.aspects.availableValues.filter((type) =>
                ($scope.baseDriver.licenseType === DRIVING_LICENCE_TYPE.ProvisionalUK &&
                    (type.code === DRIVING_LICENCE_TYPE.FullUK || type.code === DRIVING_LICENCE_TYPE.ProvisionalUK)) ||
                ($scope.baseDriver.licenseType === DRIVING_LICENCE_TYPE.ProvisionalEU &&
                    (type.code === DRIVING_LICENCE_TYPE.FullEU || type.code === DRIVING_LICENCE_TYPE.ProvisionalEU)));
            if ($scope.licenceTypeOptions.length > 0 && $scope.driver.isMigratedDriver.value === false) {
                $scope.minAllowedLicenceDate = moment({
                    month: getMonthCode($scope.baseDriver.provisionalLicenseMonth),
                    year: $scope.baseDriver.provisionalLicenseYear
                });
            }

            $scope.maritalStatusHasChanged = () =>
                $scope.baseDriver.maritalStatus !== $scope.driver.maritalStatus.value.code;

            $scope.licenceTypeHasChanged = () =>
                $scope.baseDriver.licenseType !== $scope.driver.licenseType.value.code;


            $scope.hasOccupationChanged = () => {
                if ($scope.driver.occupation_itb.value.code != null) {
                    return $scope.baseDriver.occupation_itb !== $scope.driver.occupation_itb.value.code;
                }
            };
            // If Marital Status is set back to its original value, reset Last Name too
            $scope.$watch(() => $scope.driver.maritalStatus.value.code, (newValue) => {
                if (newValue && newValue === $scope.baseDriver.maritalStatus) {
                    $scope.driver.lastName.value = $scope.baseDriver.lastName;
                }
            });

            // Occupation
            $scope.primaryOccupationTooltip = {
                content: 'and.quoteandbind.pm.directives.templates.pm-edit-person.tooltip.primary occupation',
                trigger: 'click'
            };
            const occupations = $scope.driver.occupation_itb.aspects.availableValues;
            $scope.occupationTypeaheadDef = {
                getMatches: (input) => {
                    if (!input || input.length < 2) return [];
                    const results = occupations.filter((key) =>
                        key.name.toLowerCase().startsWith(input.toLowerCase()));
                    return results.slice(0, 8);
                },
                toString: (typekey) => typekey.name
            };
        }]
    };
}];
export default ['JsonRPCService', '$rootScope', 'brandingData', (JsonRPCService, $rootScope, brandingData) => {
    
    let dealers = null;
    let modificationOptions = null;
    let defactoDate = null;
    let endOfSalesDates = null;

    
    return {
        getAccountPolicySummaries: () => {
            return JsonRPCService.send('pc', 'policy/policy', 'getAccountPolicySummaries', []);
        },

        getAMPDomain_AND: () => {
            return JsonRPCService.send('quote', 'policy', 'getAMPDomain', [brandingData.toUpperCase()]);
        },

        getEndOfSaleDatesITB_AND: function () {
            if (endOfSalesDates == null) {
                const _endOfSalesDates = JsonRPCService.send('pc', 'quote/quote', 'getEndOfSaleDatesITB', []);
                this.setEndOfSaleDates(_endOfSalesDates);
            }
            return endOfSalesDates;
        },
        setEndOfSaleDates: function (_endOfSalesDates) {
            endOfSalesDates = _endOfSalesDates;
        },

        getDefactoDate_AND: function () {
            if(defactoDate == null) {
                let _defactoDate = JsonRPCService.send('quote', 'quote', 'getDefactoDate', []);
                this.setDefactoDate(_defactoDate);
            }
            return defactoDate;
        },
        setDefactoDate: function (_defactoDate) {
             defactoDate = _defactoDate;
        },

        getActiveDealerRecords: function () {
            if (dealers == null) {
                let _dealers = JsonRPCService.send('quote', 'quote', 'getActiveDealers', [brandingData]);
                this.setActiveDealerRecords(_dealers);
            }
            return dealers;
        },

        setActiveDealerRecords: function (_dealers) {
            dealers = _dealers;
        },

        getModificationLists: function () {
            if (modificationOptions == null) {
                let _modificationOptions = JsonRPCService.send('pc', 'quote/quote', 'getModificationLists', [$rootScope.productCode]);
                this.setModificationLists(_modificationOptions);
            }
            return modificationOptions;
        },
        setModificationLists: function (_modificationOptions) {
            modificationOptions = _modificationOptions;
        }
    };
}];

import CovTerm from './CovTerm';
import _ from 'underscore';
/** Coverage model. */

export default {
    /**
     * Creates a new coverage with the underlying coverage model. Return a new coverage with the following
     * functions and properties:
     *
     * <dl>
     *     <dt>name : String</dt><dd>Name of the coverage term</dd>
     *     <dt>amount : Currency</dt><dd>Coverage amount</dd>
     *     <dt>required : Boolean</dt><dd>Flag indicating that coverage is mandatory.</dd>
     *     <dt>updating : () => Boolean</dt><dd>Flag set when this coverage is updating.</dd>
     *     <dt>terms : CovTerm[]</dt><dd>Array of terms for this coverage</dd>
     *     <dt>selected : Boolean</dt><dd>Flag indicating that property is selected.</dd>
     *     <dt>publicId : String</dt><dd>Identifier of the coverage</dd>
     * </dl>
     *
     * @param {Object} model coverage model.
     * @param {Function} loading to retrieve current loading status.
     * @param {Function} [onChange] function to invoke on the term change.
     * @returns {Object}
     */
    'create': (model, loading, onChange) => {
        onChange = onChange || _.noop;

        const res = {
            'name': model.name,
            'amount': model.amount,
            'required': model.required,
            'publicID': model.publicID,
            'terms': model.terms.map(_.partial(CovTerm.create, _, loading, onChange))
        };

        Object.defineProperty(res, 'updating', {
            'get': loading || _.constant(false)
        });

        if (res.required) {
            res.selected = true;
        } else {
            Object.defineProperty(res, 'selected', {
                'get': () => {
                    return model.selected;
                },
                'set': (nv) => {
                    model.selected = nv;
                    model.updated = true;
                    model.terms.forEach((term) => {
                        term.updated = true;
                    });
                    onChange();
                }
            });
        }

        return Object.freeze(res);
    }
};
import template from 'text!./templates/endorsement-select-changes.html';

export default [() => ({
    template,
    'scope': {
        'model': '=',
        'mode': '=',
        'onNext': '='
    },
    'controller': ['$scope', 'endorsement.LobUtil', ($scope, LobUtil) => {
        $scope.nextSteps = $scope.nextSteps || {};
        $scope.isHomeowners = LobUtil.isHomeowners($scope.model.policyChange.baseData.productCode);
        $scope.isPA = LobUtil.isPA($scope.model.policyChange.baseData.productCode);
        $scope.isPm = LobUtil.isPm($scope.model.policyChange.baseData.productCode); // ANDIE
    }]
})];
import templateStr from 'text!./templates/vehicle-damageable-part.html';

export default [() => {
    return {
        scope: {
            part: '@',
            group: '@',
            damagedPartCode: '=',
            damagedGroup: '=',
            partClick: '='

        },
        restrict: 'A',
        template: templateStr,


        link: (scope) => {
            scope.hovered = false;

            function isSelected() {
                if (scope.damagedGroup && (scope.damagedGroup.code !== scope.group)) {
                    return false;
                } else if (scope.damagedGroup && (scope.damagedGroup.code === scope.group)) {
                    return true;
                }

                if (scope.damagedGroup && (scope.damagedPartCode === scope.part)) {
                    return true;
                }

                return !!(scope.damagedGroup && (scope.damagedPartCode === null));
            }

            scope.getIcon = () => {
                if (isSelected()) {
                    return `onhover/damage_${scope.part}_on`;
                }

                if (scope.hovered) {
                    return `onhover/damage_${scope.part}_hover`;
                }
                return `damage_${scope.part}_off`;
            };
        }
    };
}];

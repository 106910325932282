import BaseCtrl from 'edge/platform/base/BaseCtrl';
import EditNoteModalTemplate from 'text!../views/edit-note-modal.html';

const CreateActivityNoteController = function ($filter, $scope, $modalInstance, ModalService, activity, ActivityService, ViewModelService) {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.showLoading = false;
        const note = {
            subject: '',
            body: ''
        };
        $scope.note = ViewModelService.create(note, 'cc', 'edge.capabilities.note.dto.NoteDTO');
    };

    ctrl.sendNote = (form) => {
        form.submitted = true;
        if (form.invalid) {
            return;
        }
        $scope.showLoading = true;
        ActivityService.createActivityNote(activity.publicID.value, $scope.note.value).then(
            (savedNote) => {
                $modalInstance.close(savedNote);
                $scope.showLoading = false;
            },
            () => {
                ModalService.showError(
                    'claim.controllers.EditNoteModal.Note creation failed',
                    'claim.controllers.EditNoteModal.Sorry, we were not able to add a new note to your activity.'
                );
                $scope.showLoading = false;
            }
        );
    };

    return ctrl.create($scope);
};
CreateActivityNoteController.$inject = ['$filter', '$scope', '$modalInstance', 'ModalService', 'activity', 'claim.ActivityService', 'ViewModelService'];

export default function (activity) {
    this.template = EditNoteModalTemplate;
    this.controller = CreateActivityNoteController;
    this.resolve = {
        activity: () => {
            return activity;
        }
    };
}

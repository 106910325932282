import templateStr from 'text!./scores.html';

export default [() => {

    const SCORE_TRANSLATION = 'and.directives.scores.';
    const SCORE_TRANSLATIONS_MAP = {
        0: 'Speed',
        1: 'TimeOfDay',
        2: 'Alerts',
        3: 'TakingBreaks',
        4: 'Motorway'
    };
    const SEQUENCE = {
        'speed': 0,
        'timeOfDay': 1,
        'alerts': 2,
        'breaks': 3,
        'motorway': 4
    };
    const SCORE_IMG = {
        // With Data
        '-1': 'and-tm-icon fas fa-road and-blue',
        0: 'and-tm-icon fas fa-road',
        1: 'and-tm-icon far fa-smile',
        2: 'and-tm-icon far fa-meh',
        3: 'and-tm-icon far fa-frown',
        // Without Data
        'speed': 'and-tm-icon and-tm-scores-img-speed',
        'timeOfDay': 'and-tm-icon and-tm-scores-img-timeOfDay',
        'alerts': 'and-tm-icon and-tm-scores-img-alerts',
        'breaks': 'and-tm-icon and-tm-scores-img-breaks',
        'motorway': 'and-tm-icon and-tm-scores-img-motorway'
    };

    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            page: '<'
        },
        controller: ['$scope', ($scope) => {
            $scope.SCORE_TRANSLATION = SCORE_TRANSLATION;
            $scope.SCORE_TRANSLATIONS_MAP = SCORE_TRANSLATIONS_MAP;
            $scope.SCORE_IMG = SCORE_IMG;

            $scope.$watch(() => $scope.page.scores, (newValue) => {
                if (newValue) {
                    const scores = $scope.page.scores.driverScores[0];
                    $scope.scores = [];
                    for (const key of Object.keys(scores)) {
                        if (angular.isDefined(SEQUENCE[key])) {
                            $scope.scores.push({
                                sequence: SEQUENCE[key],
                                level: scores[key],
                                iconLevel: ((key.toLowerCase() === 'motorway') && scores[key] !== 0) ? -1 : scores[key]
                            });
                        }
                    }
                } else { // No scores yet
                    $scope.scores = [];
                    for (const key of Object.keys(SEQUENCE)) {
                        $scope.scores.push({
                            iconLevel: key,
                            sequence: SEQUENCE[key]
                        });
                    }
                }
            });
        }]
    };
}];

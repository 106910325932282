import templateStr from 'text!./pm-change-overnight-address-mta.html';

export default [() => {
    return {
        restrict: 'A',
        scope: {
            page: '='
        },
        template: templateStr,
        controller: ['$rootScope', '$scope', 'ViewModelService', ($rootScope, $scope, ViewModelService) => {
            $scope.newAddressVM = ViewModelService.create({}, 'pc', 'edge.capabilities.address.dto.AddressDTO', null);
            $scope.newHomeOwnerVM = ViewModelService.create({}, 'pc', 'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO', null).homeOwner_itb;
            const parkingVM = ViewModelService.create({}, 'pc', 'itb.edge.capabilities.policyjob.lob.personalmotor.coverables.dto.PmVehicleDTO', null);
            $scope.dayTimeParkingVM = parkingVM.dayTimeParking;
            $scope.nightTimeParkingVM = parkingVM.nightTimeParking;

            
            //Filter Typelist to no longer include 'Not available' Using the NonMigrations Filter. 
            const dayTimeParkingTypelist = 'NonMigratingPoliciesDayTimeParking'; 
            const dayTimeParkingFilters = parkingVM.dayTimeParking.aspects.availableValues[0].typelist.filters; 
            $scope.DayTimeParkingPrefixes = dayTimeParkingFilters.find(obj => {
                return obj.name === dayTimeParkingTypelist; 
            }); 
            
            $scope.hasAddressChanged = () => {
                return $scope.newAddressVM.postalCode.value !== undefined &&
                    ($scope.newAddressVM.addressLine1.value + $scope.newAddressVM.postalCode.value) !==
                    ($scope.page.policyDetailsVM.policyAddress.addressLine1.value + $scope.page.policyDetailsVM.policyAddress.postalCode.value);
            };

            $scope.syncData = () => {
                const coverablesVM = $scope.page.policyChangeVM.lobData.personalMotor.coverables;
                const driver = coverablesVM.pmDrivers.children.find((d) => d.isPolicyHolder.value === true);
                const vehicle = coverablesVM.pmVehicles.children[0];
                $scope.page.policyChangeVM.baseData.policyAddress.value = $scope.newAddressVM.value;
                driver.homeOwner_itb.value = $scope.newHomeOwnerVM.value;
                vehicle.dayTimeParking.value = $scope.dayTimeParkingVM.value;
                vehicle.nightTimeParking.value = $scope.nightTimeParkingVM.value;
            };

            $scope.checkValidity = () => {
                return $scope.newAddressVM.aspects.valid && $scope.newAddressVM.aspects.subtreeValid
                    && $scope.newHomeOwnerVM.aspects.valid && $scope.newHomeOwnerVM.aspects.subtreeValid 
                    && $scope.dayTimeParkingVM.aspects.valid && $scope.dayTimeParkingVM.aspects.subtreeValid 
                    && $scope.nightTimeParkingVM.aspects.valid && $scope.nightTimeParkingVM.aspects.subtreeValid;
            };


            $scope.goToNext = () => {
                if ($scope.checkValidity() === true) {
                    $scope.syncData();
                    $scope.page.saveAndGoToChangeSelection();
                }
            };
        }]
    };
}];
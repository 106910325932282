import templateStr from 'text!./pm-quote-review-mta.html';
import moment from 'moment';

export default ['brandingData', '$translate', (brandingData, $translate) => {
    return {
        restrict: 'A',
        scope: {
            modelVM: '<modelVm',
            page: '<'
        },
        template: templateStr,
        controller: ['$scope', ($scope) => {
            $scope.phDriver = $scope.modelVM.lobData.personalMotor.coverables.value.getPHDriver();
            $scope.phDriverVM = $scope.modelVM.lobData.personalMotor.coverables.pmDrivers
                .findFirstElement($scope.phDriver);

            // Vehicle
            $scope.vehicleVM = $scope.modelVM.lobData.personalMotor.coverables.pmVehicles.children[0];
            $scope.vehicle = $scope.vehicleVM.value;
            if ($scope.vehicle.registrationNumber) {
                $scope.vehicleDisplayName = `${$scope.vehicle.registrationNumber} ${$scope.vehicle.make} ${$scope.vehicle.model}`;
            } else {
                $scope.vehicleDisplayName = `${$scope.vehicle.make} ${$scope.vehicle.model}`;
            }
            $scope.vehicleDateOfPurchase = `${$scope.vehicleVM.purchasedMonth.value.name} ${$scope.vehicle.purchasedYear}`;

            // Drivers
            $scope.driversVM = $scope.modelVM.lobData.personalMotor.coverables.pmDrivers;
            $scope.driverSort = (driverVM) => {
                if (driverVM.mainDriver.value === true) {
                    return -1;
                }
                return driverVM.person.firstName.value;
            };

            // Address
            $scope.policyAddress = $scope.modelVM.baseData.policyAddress.value;
            $scope.policyAddressLine1 = $scope.policyAddress.addressLine1;
            if ($scope.policyAddress.organisationName_itb) {
                $scope.policyAddressLine1 = `${$scope.policyAddress.organisationName_itb} ${$scope.policyAddressLine1}`;
            }
            if ($scope.policyAddress.departmentName_itb) {
                $scope.policyAddressLine1 = `${$scope.policyAddress.departmentName_itb} ${$scope.policyAddressLine1}`;
            }

            // Boolean values
            $scope.getBooleanDisplayName = (value) => {
                return value === true ?
                    $translate.instant('and.quoteandbind.pm.directives.templates.pm-quote-review-card.Yes') :
                    $translate.instant('and.quoteandbind.pm.directives.templates.pm-quote-review-card.No');
            };

            // Change effective from
            const effectiveDate = moment(Object.assign({}, $scope.modelVM.baseData.effectiveDate.value, $scope.modelVM.baseData.effectiveTime_itb.value));
            $scope.mtaEffectiveDate = effectiveDate.format('DD MMMM YYYY');

            $scope.getDriverSubtitle = () => {
                let translationKey;
                $scope.driversVM.length > 1 ?
                    translationKey = 'and.quoteandbind.pm.directives.templates.pm-quote-review.Drivers' :
                    translationKey = 'and.quoteandbind.pm.directives.templates.pm-quote-review.Driver';
                return `${$scope.driversVM.length} ${$translate.instant(translationKey)}`;
            };
        }]
    };
}];
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import Submission from 'edge/quoteandbind/common/models/Submission';
import WizardUtil from '../util/WizardUtil_AND';
import queryString from 'query-string';

/**
 *
 * POST https://quotes.insurethebox.com/sites/rq/web/en_GB/itb/?providerName=confused
 * providerName=confused
 * calcRefId=<respective calcrefid>
 * postcode=<respective postcode>
 */

const fn = function (
    $scope, $state, $location, LoadSaveService, wizardFlowService, qnbCompositeFlowDefn, ViewModelService,
    viewModelContext, returnStateFn, errorStateFn, effectiveDateStateFn,
) {
    const AGG_CODE = {
        MSM: 'MSM',
        CTM: 'CTM',
        GC: 'GC',
        US: 'US',
        CONFUSED: 'CONFUSED'
    };

    /**
     * Parses URI Encoded ui-router query string params (i.e. #/state-name?param1=value1)
     * @returns {any} Object with ui-router query string params
     */
    const getParams = () => {
        // 1. Take the location hash and clip it up to ui-router params
        //  e.g. location.hash => '#/agg-retrieval?guid=123&agg=ABC' => 'guid=123&agg=ABC'
        const params = window.location.hash.substring($location.path().length + 2); // +2 takes care of # and ?
        // 2. Decode URI chars and parse into an object
        return queryString.parse(decodeURIComponent(params));
    };

    const ctrl = Object.create(BaseCtrl);
    const parentWizardState = 'baseTransactionWizard';

    ctrl.init = function () {
        const quoteID = window.aggQuoteID_AND;
        const postCode = window.aggPostCode_AND;
        window.aggQuoteID_AND = undefined;
        window.aggPostCode_AND = undefined;
        const params = getParams();
        if (!quoteID || !postCode || !params.agg || !AGG_CODE[params.agg.toUpperCase()]) {
            return $state.go('contactUs'); // Display technical error screen if invalid params are passed
        }

        $scope.aggCode = params.agg.toLowerCase();
        const stepToStateMapping = {
            returnHome: model => returnStateFn(model),
            confirmation: (model) => ['qnbconfirmation', {
                quoteSummary: model.value,
                returnStateInfo: returnStateFn(model.value)
            }],
            error: model => errorStateFn(model),
            effectiveDate: model => effectiveDateStateFn(model)
        };
        const flowfactory = wizardFlowService(qnbCompositeFlowDefn, parentWizardState, stepToStateMapping);

        ctrl.wizardSteps = WizardUtil.getWizardStepsReadonly();
        $scope.wizardSteps = ctrl.wizardSteps;

        /* Populates SubmissionDTO and resumes quote flow*/
        ctrl.populateSubmissionData = function (submissionData) {
            const submissionVM = ViewModelService.create(new Submission(submissionData), 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO', viewModelContext);
            flowfactory.resume(submissionVM);
        };

        LoadSaveService.retrieveAggsSubmission_AND({quoteID, postCode, aggregator: params.agg.toUpperCase()})
            .then(ctrl.populateSubmissionData)
            .catch(() => $state.go('contactUs'));
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state', '$location', 'qnb.LoadSaveService', 'WizardFlowService',
    'qnb.CompositeFlowDefn', 'ViewModelService', 'qnb.ViewModelCtx', 'qnb.returnStateFn',
    'qnb.errorStateFn', 'qnb.effectiveDateStateFn', 'brandingData'];

export default fn;
import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./RepairFacilitiesListItem.html';
import styles from './RepairFacilitiesListItem-hashed.scss';
import controller from './RepairFacilitiesListItemCtrl';

export default {
    template: cssUtil.hashTemplate(template, styles),
    controller,
    bindings: {
        model: '<',
        selected: '<',
        onScrollIntoView: '&'
    }
};

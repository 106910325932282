import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./HalfBannerOffer.html';
import styles from './HalfBannerOffer-hashed.scss';
import controller from './HalfBannerOfferCtrl';


export default {
    controller,
    template: cssUtil.hashTemplate(template, styles),
    bindings: {
        offerType: '<',
        content: '<',
        destinationUrl: '<',
        urlPrefix: '<',
        imagePath: '=?'
    }

};
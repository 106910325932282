import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./AuthBase.html';
import styles from './AuthBase-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(template, styles);

export default {
    template: hashedTemplate,
    controller: [
        '$scope',
        function () {
            // VARS
            const $ctrl = this;

            // INIT
            $ctrl.$onInit = () => {
                document.body.classList.add('and-auth-login-bg-color');
            };

            // DESTROY
            $ctrl.$onDestroy = () => {
                document.body.classList.remove('and-auth-login-bg-color');
            };
        }]
};
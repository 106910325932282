import _ from 'lodash';
import EntityUtil from 'gw-portals-util-js/EntityUtil';


export default class WCClaimExtension {
    constructor(_claim) {
        Object.defineProperty(this, '_claim', {
            enumerable: false,
            value: _claim
        });

        const workersComp = this._claim.lobs.workersComp;

        this.dateReportedToEmployer = workersComp && workersComp.dateReportedToEmployer
            ? workersComp.dateReportedToEmployer
            : null;

        this.incidentReport = workersComp && _.isBoolean(workersComp.incidentReport)
            ? workersComp.incidentReport
            : false;
        this.deathReport = workersComp && _.isBoolean(workersComp.deathReport)
            ? workersComp.deathReport
            : null;
        this.timeLossReport = workersComp && _.isBoolean(workersComp.timeLossReport)
            ? workersComp.timeLossReport
            : null;
        this.employmentInjury = workersComp && _.isBoolean(workersComp.employmentInjury)
            ? workersComp.employmentInjury
            : null;
        this.medicalReport = workersComp && _.isBoolean(workersComp.medicalReport)
            ? workersComp.medicalReport
            : null;
        this.firstIntakeDoctor = workersComp && workersComp.firstIntakeDoctor
            ? workersComp.firstIntakeDoctor
            : null;

        this.examinationDate = workersComp && workersComp.examinationDate
            ? workersComp.examinationDate
            : null;

        this.injuryIncident = workersComp && workersComp.injuryIncident
            ? workersComp.injuryIncident
            : {
                injured: null,
                description: null,
                generalInjuryType: 'specific',
                detailedInjuryType: null,
                bodyParts: null
            };

        this.injuryIncident.bodyParts = workersComp && workersComp.injuryIncident && workersComp.injuryIncident.bodyParts
            ? workersComp.injuryIncident.bodyParts
            : [];

        this.availableContacts = [];

        this.accidentType = workersComp && workersComp.accidentType
            ? workersComp.accidentType
            : null;
    }

    getAvailableContacts() {
        return this.availableContacts.slice(0);
    }

    addBodyPartDetails() {
        this._claim.lobs.workersComp.injuryIncident.bodyParts.push({
            tempID: EntityUtil.nextId(),
            primaryBodyPart: null,
            detailedBodyPart: null,
            detailedBodyPartDesc: null,
            sideOfBody: null,
            impairmentPercentage: null
        });
    }

    removeBodyPartDetails(bodyPartToRemove) {
        const indexToRemove = this._claim.lobs.workersComp.injuryIncident.bodyParts.findIndex(exist => {
            const tempIDMatches = bodyPartToRemove.tempID && exist.tempID === bodyPartToRemove.tempID;
            const publicIDMatches = bodyPartToRemove.publicID && exist.publicID === bodyPartToRemove.publicID;
            return tempIDMatches || publicIDMatches;
        });
        if (indexToRemove !== -1) {
            this._claim.lobs.workersComp.injuryIncident.bodyParts.splice(indexToRemove, 1);
        }
    }
}
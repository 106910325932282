const fn = function ($scope, $stateParams, claimViewModel, $state, RootStates, StateUtils) {

    const claimVM = claimViewModel();

    // work around for ui-router issue abstract base state and child state without url causes infinite loop
    // so we have to make this base state concrete. But if we just refresh the browser here we need to redirect to home page.
    if (!claimVM) {
        $state.go(RootStates.home);
        return;
    }

    $scope.fnol = {
        policyNumber: $stateParams.policyNumber,
        accountNumber: $stateParams.accountNumber
    };

    $scope.claimNumber = () => {
        const claimVm = claimViewModel();
        return (claimVm && claimVm.claimNumber) ? claimVm.claimNumber.value : undefined;
    };

    const onStateTransition = (actionHandlerObject, promise) => {
        $scope.stateTransitionPromise = promise;

        try {
            $scope.stateTransitionMessage = actionHandlerObject.msgs.progress.message;
        } catch (ignore) { /* it's ok if there's no message */
        }
    };

    StateUtils.subscribeToStateTransitions(onStateTransition);
    $scope.$on('$destroy', () => StateUtils.unsubscribeFromStateTransitions(onStateTransition));
};

fn.$inject = ['$scope', '$stateParams', 'claimViewModel', '$state', 'state_plugin_config', 'StateUtils'];

export default fn;

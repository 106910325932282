import Template from 'text!edge/policy/user-account_AND/views/user-account.html';
import Controller from 'edge/policy/user-account_AND/controllers/UserAccountCtrl';

export default (RootStates) => {
    const parentState = RootStates.userAccount_AND;

    return {
        userAccount_AND: {
            name: 'userAccount_AND',
            parent: parentState,
            url: '/account-settings',
            template: Template,
            controller: Controller
        }
    };
};

import moment from 'moment';

export default ['$rootScope', 'policy.PolicyService', '$q', '$state', '$location', 'brandingData', ($rootScope, PolicyService, $q, $state, $location, brandingData) => {
    if ($rootScope.policyContext) { // policyContext must only be initialised once.
        return $rootScope.policyContext;
    }
    let policiesPromise; // Keeping track of the promise to avoid multiple concurrent calls
    // Init policyContext at the root scope
    let datePromise;
    $rootScope.policyContext = {
        currentPolicyNumber: undefined,
        currentPolicy: undefined,
        currentlyCovered: undefined,
        scheduled: undefined,
        frameAccordions: {}, // Accordions on the left/top. Control whether they're open.
        isInTopUpWizard: false, // If inside a Top Up Miles PolicyChange.
        isInFnolWizard: false, // If is inside the fnol flow
        isInPolicyChangeWizard: false, // If is inside the MTA flow
        isInRenewalWizard: false, // If is inside the Renewal flow
        anyVisiblePolicies: false, // If the user has access to any Policies, besides Account access.
        isCurrentPolicyNotEditable: function () {
            return (this.currentPolicy && (this.currentPolicy.isCancelled === true || this.currentPolicy.expired === true)) || this.anyVisiblePolicies === false;
        },
        isInTechnicalError: function () {
            return $state.$current.name === 'technicalError_AND';
        },
        returnToPortal: function () {
            const url = $location.absUrl();
            if (this.isInRenewalWizard){
                $state.go('yourRenewal_AND');
            } else {
                window.location.href = url.substring(0, url.indexOf('#'));
            }
        },
        returnToHomepage: function (productCode) {
            if(productCode === 'TraditionalMotor' || productCode === 'CommercialVehicle') {
                $state.go('yourInsurance_AND')
            } else {
                $state.go('home')
            }
        },
        isInWizardContext: function () {
            return this.isInTopUpWizard === true ||
                this.isInFnolWizard === true ||
                this.isInPolicyChangeWizard === true ||
                this.isInRenewalWizard === true ||
                this.isInTechnicalError();
        },
        getPolicies: function () {
            if (policiesPromise) { // Do not fetch summaries more than once.
                return policiesPromise;
            }
            const thisOuter = this;
            datePromise = PolicyService.getAllowPortalRenewalAmendAndMTAAmendDate()
                .then((result) => {
                    $rootScope.allowPortalRenewalAmendAndMTADate = result
                })
            policiesPromise = PolicyService.getAccountPolicySummaries()
                .then((summaries) => {
                    if (summaries.length === 0) {
                        throw new Error('No Access!');
                    }
                    summaries = summaries.map((summary) => {
                        if (summary.periods) {
                            thisOuter.anyVisiblePolicies = true;
                            // Bring cancelled flag up to Policy for convenience
                            summary.firstTerm_AND = summary.periods[0].firstTerm_AND;
                            summary.isCancelled = summary.periods.some((period) => period.isCancelled === true);
                            summary.expired = summary.periods.some((period) => period.expired === true);
                            summary.canAccessDGTRewards_AND = summary.periods[0].canAccessDGTRewards_AND;
                            summary.renewal = summary.periods.some((period) => period.renewal === true);
                            if (summary.periods[1] !== undefined){
                                summary.canEditRenewalAndMta_AND = moment(summary.periods[1].effective).isBefore($rootScope.allowPortalRenewalAmendAndMTADate)
                            }
                            // Determine if the user is currently covered
                            if (thisOuter.currentlyCovered !== true) {
                                thisOuter.currentlyCovered = summary.periods.some((period) => period.isInForce === true);
                            }
                            // Check if any policies are in scheduled status
                            if (thisOuter.scheduled !== true) {
                                thisOuter.scheduled = summary.periods.some((period) => period.status === 'Scheduled');
                            }
                            
                        }
                        return summary;
                    });
                    // Initialise selection with first option
                    thisOuter.selectPolicy(summaries[0]);
                    return summaries;
                })
                .catch(() => {
                    $state.go('technicalError_AND');
                });
            return policiesPromise;
        },
        selectPolicyByNumber: function (policyNumber) {
            const thisOuter = this;
            this.getPolicies()
                .then((summaries) => {
                    const policy = summaries.find((summary) => summary.periods[0].policyId === policyNumber);
                    if (policy) {
                        thisOuter.selectPolicy(policy);
                    }
                });
        },
        selectPolicy: function (policy) {
            this.currentPolicy = policy;
            this.currentPolicyNumber = policy.periods ? policy.periods[0].policyId : undefined;
            // If this policy is not editable and the current state is restricted to editable policies,
            // navigate to Your Insurance screen.
            if (this.isCurrentPolicyNotEditable() &&
                ($state.current.data && $state.current.data.restrictedToEditablePolicies === true) || $rootScope.productCode !== 'PersonalMotor') {
                $state.go('yourInsurance_AND');
            }
        },
        getNumberOfDaysToRenewal: function () {
            if (this.currentPolicy && this.currentPolicy.periods[1]) {
                return moment(this.currentPolicy.periods[1].effective).diff(this.currentPolicy.policyCenterDate_AND, 'days');
            }
        },
        getNumberOfDaysToExpiry: function () {
            if (this.currentPolicy) {
                return moment(this.currentPolicy.periods[0].expiration).diff(this.currentPolicy.policyCenterDate_AND, 'days');
            }
        },
        getNumberOfDaysSinceEffective: function () {
            if (this.currentPolicy) {
                return moment(this.currentPolicy.policyCenterDate_AND).diff(moment(this.currentPolicy.periods[0].effective), 'days');
            }
        },
        isNotRenewing: function () {
            if (this.currentPolicy && this.currentPolicy.periods[1]) {
                return this.currentPolicy.periods[1].status === 'Non-renewing' || this.currentPolicy.periods[1].status === 'Non-renewed';
            }
        },
        isNotTaken: function () {
            if (this.currentPolicy && this.currentPolicy.periods[1]) {
                return this.currentPolicy.periods[1].status === 'Not-taking' || this.currentPolicy.periods[1].status === 'Not-Taken';
            }
        },
        policyHasExpired: function () {
            if (this.currentPolicy && this.currentPolicy.periods[0]) {
                return this.currentPolicy.periods[0].expired === true;
            }
        },
        isRenewalAccepted: function () {
            if (this.currentPolicy && this.currentPolicy.periods[1]) {
                return this.currentPolicy.periods[1].status === 'Scheduled';
            }
        },
        isDateBeforeAndUk: function (date) {
            return date.isBefore(moment('20201201').startOf('day'));
        },
        policyEffDateBeforeAndUk: function () {
            if (this.currentPolicy && this.currentPolicy.periods[0]) {
                return this.isDateBeforeAndUk(moment(this.currentPolicy.periods[0].effective));
            }
        },
        isTodayBeforeAndUk: function () {
            return this.isDateBeforeAndUk(moment());
        }
    };

    return $rootScope.policyContext.getPolicies();
}];

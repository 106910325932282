import templateStr from 'text!./templates/policy-common-documents.html';

export default [() => {
    return {
        restrict: 'E',
        template: templateStr,
        scope: {
            'readonlyDocuments': '=',
            'writableDocuments': '=',
            'hideDropArea': '='
        },
        controller: ['$scope', '$stateParams', '$window', 'httpSettings', 'policy.PolicyService',
            ($scope, $stateParams, $window, httpSettings, PolicyService) => {
                $scope.removeFunction = (documentID) => PolicyService.removeDocument(documentID);
                $scope.policyCenterRetrievalURI = `${httpSettings.serviceBaseURLs.pc}document/document/policyDocument`;
                $scope.documentNotFoundURL = `${$window.location.pathname.substring(
                    0,
                    window.location.pathname.lastIndexOf('/')
                )}/PolicyDocumentError`;
                $scope.metadataTemplate = {
                    /* ID to overcome DTO validation.
                     * This should be set by Document Management System on the backend but is required in DTO. */
                    docUID: '001',
                    documentType: 'letter_received',
                    securityType: 'unrestricted',
                    status: 'approved',
                    policyNumber: $stateParams.policyNumber
                };
                $scope.uploadConfig = {
                    metadataTemplate: $scope.metadataTemplate,
                    documentNotFoundUrl: $scope.documentNotFoundURL,
                    xcenterCenterRetrievalUrl: $scope.policyCenterRetrievalURI,
                    documentUpload: 'pc',
                    serviceName: 'policy/docupload',
                    sessionServiceName: 'policy/docs',
                    // Adding random number at end to make the class unique (multiple uploads on the page)
                    uploaderClass: `gw-policy-common-file-upload-button${Math.floor(Math.random() * 9999)}`
                };

            }
        ]
    };
}];

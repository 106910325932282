import PolicyChange from 'edge/endorsement/common/models/PolicyChange';

export default ($q, EndorsementService) => {

    const ACTIONS = {
        'load': model => {
            let promise;
            if (model.effectiveDate) {
                promise = EndorsementService.loadEndorsementWithEffectiveDate(model.selectedPolicyNumber, model.effectiveDate);
            } else {
                promise = EndorsementService.loadEndorsement(model.selectedPolicyNumber);
            }
            return promise.then(pc => ({
                'selectedPolicyNumber': model.selectedPolicyNumber,
                'policyChange': new PolicyChange(pc)
            }));
        },
        'checkEffectiveDate': model =>
            EndorsementService.checkEffectiveDateIsValid(model.selectedPolicyNumber, model.policyChange.baseData.effectiveDate).then(res => {
                return res === true ? model : $q.reject(model);
            }),

        'withdrawPolicyChange': model => {
            // If no jobID then policy change has not been saved yet
            if (model.policyChange.jobID) {
                return EndorsementService.withdrawEndorsement(model.policyChange.jobID);
            }
            return $q.resolve();
        },
        'loadWithEffectiveDate': model =>
            EndorsementService
                .loadEndorsementWithEffectiveDate(model.selectedPolicyNumber, model.policyChange.baseData.effectiveDate)
                .then(pc => ({
                    'selectedPolicyNumber': model.selectedPolicyNumber,
                    'policyChange': new PolicyChange(pc)
                }))
    };


    const ACTION_MSG = {
        load: {
            error: {
                title: 'endorsement.common.config.Unable to load draft policy change',
                message: 'endorsement.common.config.An error occurred while attempting to load the policy change.'
            },
            progress: {
                message: 'endorsement.common.config.Loading policy change'
            }
        },
        checkEffectiveDate: {
            error: {
                title: 'endorsement.common.config.Unable to start policy change',
                message: 'endorsement.common.config.An error occurred while attempting to start the policy change.'
            },
            progress: {
                message: 'endorsement.common.config.Checking effective date'
            }
        },
        cancelPrompt: 'endorsement.common.config.Are you sure you want to cancel this policy change?',
        withdrawPolicyChange: {
            error: {
                title: 'endorsement.common.config.Unable to load withdraw policy change',
                message: 'endorsement.common.config.An error occurred while attempting to withdraw the policy change.'
            },
            progress: {
                message: 'endorsement.common.config.Withdrawing policy change'
            }
        }
    };

    return function (step, junction) {
        const hasValidPolicyNumber = model => model.selectedPolicyNumber;

        junction('entry', true) // Skip policy list if we're coming with a valid policy number
            .onNext.branch(model => {
                return hasValidPolicyNumber(model) ? 'PolicyLoad' : 'PolicyList';
            });

        step('PolicyList')
            .isValid(hasValidPolicyNumber)
            .onNext.goTo('PolicyLoad');

        junction('PolicyLoad')
            .onNext
            .doAction(ACTIONS.load, ACTION_MSG.load)
            .thenGoTo('EffectiveDate', 'endorsementError');

        step('EffectiveDate')
            .onNext.doAction(ACTIONS.checkEffectiveDate, ACTION_MSG.checkEffectiveDate, { forceUpdate: true })
            .thenGoTo('LoadUpdatedChange', 'endorsementError');

        junction('LoadUpdatedChange')
            .onNext.doAction(ACTIONS.loadWithEffectiveDate, ACTION_MSG.checkEffectiveDate)
            .thenGoTo('WhatsNext', 'endorsementError');

        step('WhatsNext').onNext.goTo('Fin')
            .isValid((model, extraArgs) => {
                return extraArgs.validFields.some((field) => model.nextSteps[field]);
            });
        // junction Fin declared in integrations compositeFlow
        junction('endorsementError');
        junction('cancel')
            .onNext.doAction(ACTIONS.withdrawPolicyChange, ACTION_MSG.withdrawPolicyChange, { forceUpdate: true })
            .thenGoTo('PolicyList', 'endorsementError')
            .data({
                confirmationMsg() {
                    return ACTION_MSG.cancelPrompt;
                }
            });
    };
};

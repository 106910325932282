import moment from 'moment';
import templateStr from 'text!./monthly-summaries.html';

const CHART_ID = 'milesPerMonthChart';
const getChartAxis = (drivingSummaries) => {
    const axis = {
        labels: [],
        data: [],
        summaries: []
    };
    // Get latest 12 months data
    const end = drivingSummaries.length - 1;
    let start = end - 11;
    if (start < 0) {
        start = 0;
    }
    for (let i = start; i <= end; i++) {
        const summary = drivingSummaries[i];
        axis.summaries.push(summary);
        axis.labels.push(`${moment.monthsShort(summary.month - 1)} ${summary.year}`);
        axis.data.push(summary.totalMiles);
    }
    // Number of months displayed must be 12. Fill remaining future months with 0 miles if needed.
    if (axis.data.length < 12) {
        const fillerStartIndex = axis.data.length;
        const fillerStopIndex = 12 - axis.data.length;
        const date = moment({year: drivingSummaries[end].year, month: drivingSummaries[end].month});
        for (let i = fillerStartIndex; i < fillerStopIndex; i++) {
            if (i === fillerStartIndex) {
                date.add(0, 'M');
            } else {
                date.add(1, 'M');
            }
            axis.labels.push(date.format('MMM YYYY'));
            axis.data.push(0);
        }
    }
    return axis;
};

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            page: '<'
        },
        controller: ['$scope', 'ChartService_AND', ($scope, ChartService_AND) => {
            let drivingSummaries;

            const selectMonthAndApply = function (index) {
                if (!drivingSummaries[index]) {
                    return;
                }
                $scope.$apply(() => { // Selections from the graph need an explicit digest
                    $scope.page.selectMonth(drivingSummaries[index]);
                });
            };

            const initMonthlySummariesChart = (data) => {
                const chartAxis = getChartAxis(data);
                drivingSummaries = chartAxis.summaries;
                $scope.page.selectMonth(drivingSummaries[0]);
                ChartService_AND.getChart({
                    id: CHART_ID,
                    data: chartAxis.data,
                    labels: chartAxis.labels,
                    yLabel: 'Miles Driven',
                    onClick: selectMonthAndApply,
                    selectedIndex: 0
                });
            };

            $scope.getMonthName = (month) => moment.months(month);

            $scope.$watch(() => $scope.page.monthlySummaries, (newValue) => {
                if (newValue) {
                    initMonthlySummariesChart(newValue.drivingSummaries);
                }
            });
        }]
    };
}];

import templateStr from 'text!./templates/wizard-sidebar-additional.html';
import MockUpUtil from 'gw-portals-util-js/MockUpUtil';
import _ from 'lodash';

export default ['ModalService', 'qnb.EmailService', '$q', 'StateUtils', '$state', '$translate', 'EventHub', '$window', 'qnb.PolicyService', (ModalService, QuoteEmailService, $q, StateUtils, $state, $translate, EventHub, $window, PolicyService) => {
    return {
        restrict: 'A',
        template: templateStr,
        link: (scope) => {
            scope.quoteNumber = () => {
                const flowModel = StateUtils.activeDataForState(true).flowModel;
                if (!flowModel) {
                    return null;
                }
                const submission = flowModel.snapshot.value;
                return submission ? submission.quoteID : null;
            };

            scope.printQuote = () => {
                EventHub.publish('wizard', 'print');
                $window.print();
            };

            const mockUpUtil = new MockUpUtil();
            scope.flowModel = StateUtils.activeDataForState(true).flowModel;
            scope.showEmailAddress = () => {
                const emailAddress1 = _.get(StateUtils.activeDataForState(true).flowModel, 'snapshot.value.baseData.accountHolder.emailAddress1');
                const mockEmailAddress1 = mockUpUtil.getMockData('baseData.accountHolder.emailAddress1');
                return emailAddress1 && emailAddress1 !== mockEmailAddress1;
            };

            scope.emailQuote = () => {
            /* const submission = StateUtils.activeDataForState(true).flowModel.snapshot.value;
            const quoteID = submission.quoteID;
            const sessionID = submission.sessionUUID;
            const emailAddress = submission.baseData.accountHolder.emailAddress1;
            const emailInfo = {
                    quoteID,
                    sessionID
                };
                $q.when(QuoteEmailService.emailQuote(emailInfo), () => {
                    ModalService.showInfo(
                        'quoteandbind.directives.QnbWizardButtons.Save for Later?',
                        $translate('quoteandbind.directives.QnbWizardButtons.We will save your quote and send its number', {
                            quoteID,
                            emailAddress
                        })
                    );
                }, () => {
                    ModalService.showError(
                        'quoteandbind.directives.QnbWizardButtons.Error',
                            'quoteandbind.directives.QnbWizardButtons.Sorry, we were not able to email the quote details at this time.'
                        );*/
                // ANDIE - Removed call to EmailService which comes after email confirmation Page
                const submission = StateUtils.activeDataForState(true).flowModel.snapshot;
                $state.go('quoteEmail', {
                    submission: submission
                });
            };

            const _getFlowModelSnapshot = () => {
                const flowModel = StateUtils.activeDataForState(true).flowModel;
                if (flowModel && flowModel.snapshot) {
                    return flowModel.snapshot.value;
                }
            };

            scope.submissionCreated = () => {
                const submission = _getFlowModelSnapshot();
                return (!!submission) && (!!submission.quoteID);
            };

            scope.submissionOnQuoteScreen = () => {
                const flowModel = StateUtils.activeDataForState(true).flowModel;
                return flowModel && flowModel.currentStep.name === 'quoteDetails';
            };

            scope.isDraftSubmission = () => {
                const submission = _getFlowModelSnapshot();
                return (!!submission) && (!!submission.quoteID) && submission.baseData.periodStatus === 'Draft';
            };

            // CBPUAT-1626 Button should not be disabled when dealing with Base renewal
            scope.isPrintQuoteDisabled = () => scope.flowModel && scope.flowModel.isAllStepsDisabled_AND && (scope.flowModel.snapshot && scope.flowModel.snapshot.isBase_itb.value !== true);
        }
        
    };
}];

class RepairFacilitiesListCtrl {
    constructor($element) {
        this.element = $element;
    }

    isSelected(item) {
        return (this.selectedRepairFacility || {}).addressBookUID === item.addressBookUID;
    }

    repairFacilitiesLoaded() {
        return this.repairFacilities;
    }

    onScrollIntoView(top) {
        const container = this.element.find('>:first-child');
        container.scrollTop(top);
    }
}

export default RepairFacilitiesListCtrl;
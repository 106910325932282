import templateStr from 'has-text?ampclaims!./templates/policy-account-policy-summaries.html';
import _ from 'lodash';
import DateUtil from 'gw-portals-util-js/DateUtil';
import lobIconUtil from 'integration/platform/lobUtil/lobIconUtil';

/**
 * Get a policy icon or a policy type.
 * @param {String} policyType policy type.
 * @returns {String} name of a font-awesome icon.
 */
function getPolicyTypeIcon(policyType) {
    return lobIconUtil.getFontIcon(policyType);
}

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            gwPolicyAccountPoliciesSummary: '=',
            gwIdCardUrl: '=',
            gwPortalRoute: '=',
            hasRenewals: '='

        },
        controller: ['$scope', ($scope) => {
            const now = DateUtil.currentDate().getTime();

            $scope.getPolicyTypeIcon = getPolicyTypeIcon;
            $scope.hasRenewals = false;
            $scope.documentNotFoundRedirectUrl = `${window.location.origin}${$scope.gwPortalRoute}`;

            $scope.normalizedPeriods = _.memoize((gwPolicyAccountPoliciesSummary) => {
                $scope.hasRenewals = false;
                const res = [];
                for (let i = 0; i < gwPolicyAccountPoliciesSummary.length; i++) {
                    const policy = gwPolicyAccountPoliciesSummary[i];
                    if (policy.periods.length > 0) {
                        const currentRenewed = policy.periods.length > 1;
                        $scope.hasRenewals = $scope.hasRenewals || currentRenewed;
                        res.push({
                            activePeriod: policy.periods[0],
                            hasRenewal: currentRenewed,
                            producerContact: policy.producerContact
                        });
                    }
                }
                return res;
            });


            $scope.isActive = (line) => {
                return new Date(line.activePeriod.effective).getTime() < now && !line.hasRenewal;
            };

            $scope.presentsAgentInfo = _.memoize((gwPolicyAccountPoliciesSummary) => {
                const agentsContacts = gwPolicyAccountPoliciesSummary.map(policySummary => policySummary.producerContact);
                return agentsContacts.some(contact => contact.publicID !== agentsContacts[0].publicID);
            });
        }]
    };
}];

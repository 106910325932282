export default function (LoadSaveService, $q, TranslateService) {
    const translateFn = TranslateService.instant;

    function whatStepCanBeSkipped(claim) {
        return claim.policy && claim.lossCause && claim.lossDate;
    }

    const ACTION_MSG = {
        createClaim: {
            error: {
                title: 'fnol.general.config.FlowConfig.Error creating claim',
                message: 'fnol.general.config.FlowConfig.An error occurred attempting to create a claim.'
            },
            progress: {
                message: 'fnol.general.config.FlowConfig.Creating draft claim...'
            }
        }
    };

    const ACTIONS = {
        createClaim: ({value: claim}) => {
            if (claim.claimNumber) {
                return LoadSaveService.saveClaim(claim);
            }

            return LoadSaveService.createClaim(claim);
        }
    };

    return function (step, junction) {
        junction('generalSuccess');

        junction('generalError');

        step('generalWhat', true)
            .onNext
            .doAction((model) => ACTIONS.createClaim(model), ACTION_MSG.createClaim)
            .thenBranch(() => 'generalWhere', () => 'generalError')
            .skipOnResumeIf(whatStepCanBeSkipped)
            .isValid((model) => model.aspects.valid && model.aspects.subtreeValid)
            .label(() => translateFn('fnol.wizard.step.general.Type of Incident'));

        step('generalWhere')
            .onNext
            .goTo('generalSuccess')
            .skipOnResumeIf(({value: claim}) => claim.lossLocation && claim.lossLocation.city)
            .isValid((model) => model.aspects.valid && model.aspects.subtreeValid)
            .label(() => translateFn('fnol.wizard.step.general.Where'));
    };
}

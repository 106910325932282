import InactivityTimer from 'gw-portals-auth-js/utils/InactivityTimer';

// EXPORT
const provider = {
    $get: ['oAuth', 'gwLogoutWarningService', 'sessionConfig', (oAuth, gwLogoutWarningService, sessionConfig) => {
        return InactivityTimer({
            oAuthConfig: oAuth,
            logoutWarningCallback: gwLogoutWarningService.showInactivityWarning,
            inactivityIntervalMins: sessionConfig.inactivityIntervalMins,
            logoutConfirmationIntervalMins: sessionConfig.logoutConfirmationIntervalMins
        });
    }]
};

export default provider;
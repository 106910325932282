import _ from 'underscore';
import moment from 'moment';

const PREFIX = { // ANDIE
    'mr': 'Mr',
    'miss': 'Miss',
    'mrs': 'Mrs',
    'ms': 'Ms',
    'dr': 'Dr'
};

/**
 * Format Days and Months to be 0-padded.
 * @param {int} value
 * @returns {string}
 * @private
 */
function _formatDate(value) {
    return (`0${value}`).slice(-2);
}

function copy(source, target) {
    target.occupation_itb = source.occupation_itb;
    target.ukResident5Years_itb = source.ukResident5Years_itb;
    target.ukResidencyYear_itb = source.ukResidencyYear_itb;
    target.ukResidencyMonth_itb = source.ukResidencyMonth_itb;
    target.homeOwner_itb = source.homeOwner_itb;
    target.maritalStatus = source.maritalStatus;
    target.gender_itb = source.gender_itb;
    target.dateOfBirth = source.dateOfBirth;
    target.title_itb = source.title_itb;
    target.firstName = source.firstName;
    target.lastName = source.lastName;
    target.occupationStatus_itb = source.occupationStatus_itb;
    target.employersBusiness_itb = source.employersBusiness_itb;
}

function PmDriver(data) {
    if (data) {
        _.extend(this, data);
        if (!data.dateOfBirth) {
            data.dateOfBirth = null;
        }

    }
}

PmDriver.prototype.getDisplayName = function (accountHolder) {
    let titleName = null;
    if(accountHolder) {
        let title_itb = accountHolder.title_itb.aspects.availableValues;
        titleName = title_itb.find(obj => {
            return obj.code === this.title_itb
        });
    }
    if(titleName) {
        return `${titleName.name} ${this.firstName} ${this.lastName}`;
    }
    else {
        return `${PREFIX[this.title_itb]} ${this.firstName} ${this.lastName}`;
    }
};

PmDriver.prototype.hasFullLicence = function () {
    return ['FullEU', 'FullUK', 'Other'].includes(this.licenseType);
};

PmDriver.prototype.getDOBDisplayName = function () {
    if (!this.dateOfBirth) {
        return '';
    }
    return `${_formatDate(this.dateOfBirth.day)}-${_formatDate(this.dateOfBirth.month + 1)}-${this.dateOfBirth.year}`;
};

PmDriver.prototype.getAgeAt = function (dateDTO) {
    if (!this.dateOfBirth) {
        return 0;
    }
    const date = moment([dateDTO.year, dateDTO.month, dateDTO.day]);
    const dob = moment([this.dateOfBirth.year, this.dateOfBirth.month, this.dateOfBirth.day]);
    return date.diff(dob, 'years');
};

PmDriver.prototype.copyToAccountHolder = function (accountHolder) {
    copy(this, accountHolder);
};

PmDriver.prototype.copyFromAccountHolder = function (accountHolder) {
    copy(accountHolder, this);
};

export default PmDriver;
import AmpUsersProfileTemplate from 'text!edge/usersprofile/views/amp-users-profile.html';
import UsersProfileCtrl from 'edge/usersprofile/controllers/UsersProfileCtrl';

export default (RootStates) => {
    const parentState = RootStates.usersprofile;

    return {
        ampusersprofile: {
            name: 'ampusersprofile',
            parent: parentState,
            url: '/ampusersprofile',
            controller: UsersProfileCtrl,
            template: AmpUsersProfileTemplate,
            data: {}
        }
    };
};
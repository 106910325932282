import templateStr from 'text!./templates/vehicle-collision.html';

export default function () {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            selectedPart: '='
        },

        controller: ['$scope', 'InsuranceSuiteMetadata', function ($scope, insuranceSuiteMetadata) {
            this.damagedParts = [];
            const typelist = insuranceSuiteMetadata.get('cc').types.getTypelist('CollisionPoint');

            this.selectSinglePart = function (partTC) {
                this.damagedParts.length = 0;
                partTC = typelist.getCode(partTC);
                this.damagedParts.push(partTC);
                $scope.selectedPart = partTC;
            };


            //                    /**
            //                     * The addSelectedPart function is unused for now
            //                     * as the model doesn't support multiple collision points OOTB
            //                     */
            //                    this.addSelectedPart = (partTC)=> {
            //                        if (findFn(this.damagedParts, partTC) === -1) {
            //                            this.damagedParts.push(partTC);
            //                        }
            //                    };

            this.removeSelectedPart = function (typeCode) {
                const removeIdx = findFn(this.damagedParts, typeCode);
                if (removeIdx !== -1) {
                    this.damagedParts.splice(removeIdx, 1);
                    $scope.selectedPart = null;
                }
            };

            const findFn = (collection, typeCode) => {
                for (let i = 0; i < collection.length; i++) {
                    if (collection[i].code === typeCode.code) {
                        return i;
                    }
                }
                return -1;
            };

            if ($scope.selectedPart) {
                this.damagedParts.push($scope.selectedPart);
            }
        }]
    };
}

import template from 'text!./templates/pm-coverages-edit.html';

export default [() => ({
    template,
    'scope': {
        'model': '=',
        'onBaseCovChange': '&',
        'onAdditionalCovChange': '&',
        'next': '&',
        'loading': '='
    },
    'controller': ['$scope', 'CoveragesUtil',
        function ($scope, CoveragesUtil) {
            /**
             * Next button is clicked
             *
             * @param {Object} form the coverage form to validate
             */
            $scope.onNext = (form) => {
                if (CoveragesUtil.validateForm(form)) {
                    // Call next method on parent
                    $scope.next();
                }
            };
        }
    ]
})];
import template from 'text!./templates/shopping-cart.html';

export default [
    () => ({
        'scope': {
            'policyChange': '=',
            'bind': '&',
            'withdraw': '&',
            'transitionFn': '=',
            'nextSteps': '=',
            'canEditState': '='
        },
        'controller': ['$scope', '$rootScope', '$state', ($scope, $rootScope, $state) => {


            $scope.shouldEnableBuyButton = () => {
                return !$scope.policyChange.saveRequired && $scope.policyChange.status === 'Quoted' && $scope.canEditState;
            };

            /**
             * Navigate to a ui router state and close
             * shopping card on mobile
             * @param {String} stateName
             */
            $scope.go = (stateName) => {
                $state
                    .go(stateName, undefined, {
                        location: false
                    })
                    .then(() => {
                        $scope.$root.$emit('endorsementShowHistory');
                    });
            };
        }],
        template: template
    })
];
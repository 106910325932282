export default (function (AvailabilityService, RenewalService_AND, $q, TranslateService, ViewModelService, ViewModelContext, ActionMessageUtil, EventHub, $rootScope) {
    var translateFn = TranslateService.instant;

    return {
        contactDetails: function contactDetails(step, junction) {
            step('pmContactDetails', true).onNext.goTo('pmContactDetailsComplete').skipOnResumeIf(function (submissionVM, extraArgs) {
                return extraArgs.isBuying === true;
            }).isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).label(function () {
                return translateFn('quoteandbind.wizard.step.Your Info');
            });

            junction('pmContactDetailsComplete');
        },
        coverables: function coverables(step, junction) {
            step('pmDriverDetails', true).onNext.goTo('pmCoverablesComplete', 'error').skipOnResumeIf(function (submissionVM, extraArgs) {
                return extraArgs.isBuying === true;
            }).isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).label(function () {
                return translateFn('quoteandbind.wizard.step.Drivers');
            }).data({
                asWizardNavigationSteps: ['pmDriverSummary'] // Display in navigation as...
            });

            junction('pmCoverablesComplete');
        },
        driversSummary: function driversSummary(step, junction) {
            step('pmDriverSummary', true).onNext.goTo('pmDriversSummaryComplete', 'error').skipOnResumeIf(function (submissionVM, extraArgs) {
                return extraArgs.isBuying === true;
            }).isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).label(function () {
                return translateFn('quoteandbind.wizard.step.Drivers');
            }).data({
                asWizardNavigationSteps: ['pmDriverDetails'], // Display in navigation as...
                regularNavigation: true
            });
            junction('pmDriversSummaryComplete');
        },
        editDrivers: function editDrivers(step, junction) {
            // Review steps
            step('pmEditContactDetails').onNext.goTo('pmEditPHComplete', 'error').isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).data({
                asWizardNavigationSteps: ['pmContactDetails'] // Display in navigation as...
            });

            step('pmEditDriverDetails').onNext.goTo('pmEditDriverComplete', 'error').isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).data({
                asWizardNavigationSteps: ['pmDriverDetails', 'pmDriverSummary'] // Display in navigation as...
            });

            junction('pmEditDriverComplete');
            junction('pmEditPHComplete');
        },
        legalPaymentDetails: function legalPaymentDetails(step, junction) {
            step('legalPaymentStep1', true).onNext.branch(function (_ref) {
                var model = _ref.value;

                // Renewal on installments
                if (model.isInstallmentsPaymentPlan_AND()) {
                    return model.isBasedOnInstallmentsPaymentPlan_AND() === true ? 'buyNow_AND' : 'legalPaymentStep2';
                } else if (model.baseData.isPolicyMigrated_itb && model.isBasedOnInstallmentsPaymentPlan_AND() && !model.baseData.hasSavedCardDetails_itb) {
                    // check if migrated policy has saved card details
                    // if not direct to input new card
                    return 'cardBillingDetails';
                }
                // Renewal on annual
                return 'cardPayment_AND';
            }).skipOnResumeIf(function (_ref2) {
                var submission = _ref2.value;

                return submission.baseData.periodStatus === 'Bound';
            }).label(function () {
                return translateFn('and.quoteandbind.pm.views.paymentstep.Legal and payments');
            }).data({
                asWizardNavigationSteps: ['legalPaymentStep1'],
                regularNavigation: true
            });

            step('legalPaymentStep2').onNext.goTo('legalPaymentStep3').skipOnResumeIf(function (_ref3) {
                var submission = _ref3.value;

                return submission.baseData.periodStatus === 'Bound';
            }).label(function () {
                return translateFn('and.quoteandbind.pm.views.paymentstep.Legal and payments');
            }).data({
                asWizardNavigationSteps: ['legalPaymentStep1'],
                regularNavigation: true
            });

            step('legalPaymentStep3').onNext.goTo('legalPaymentDetailsComplete').skipOnResumeIf(function (_ref4) {
                var submission = _ref4.value;

                return submission.baseData.periodStatus === 'Bound';
            }).label(function () {
                return translateFn('and.quoteandbind.pm.views.paymentstep.Legal and payments');
            }).data({
                asWizardNavigationSteps: ['legalPaymentStep1'],
                regularNavigation: true
            });

            junction('legalPaymentDetailsComplete');
            junction('buyNow_AND'); // Straight to bind
            junction('cardPayment_AND'); // Card payment (choose card/new card)
        },
        vehicleDetails: function vehicleDetails(step, junction) {
            step('pmVehicleDetails', true).onNext.goTo('pmVehicleDetailsComplete', 'error').skipOnResumeIf(function (submissionVM, extraArgs) {
                return extraArgs.isBuying === true;
            }).isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).label(function () {
                return translateFn('quoteandbind.wizard.step.Vehicles.' + $rootScope.productCode);
            });

            junction('pmVehicleDetailsComplete');
        },
        cardPayment: function cardPayment(step, junction) {
            step('cardSelection', true).onNext.branch(function (model, args) {
                return args.skipToBind === true ? 'cardPaymentComplete' : 'cardBillingDetails';
            }).skipOnResumeIf(function (_ref5) {
                var submission = _ref5.value;
                return submission.baseData.periodStatus === 'Bound';
            }).label(function () {
                return translateFn('and.quoteandbind.pm.views.paymentstep.Legal and payments');
            }).isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).data({
                asWizardNavigationSteps: ['legalPaymentStep1'],
                regularNavigation: true
            });

            step('cardBillingDetails').onNext.goTo('legalPaymentStep4').skipOnResumeIf(function (_ref6) {
                var submission = _ref6.value;
                return submission.baseData.periodStatus === 'Bound';
            }).label(function () {
                return translateFn('and.quoteandbind.pm.views.paymentstep.Legal and payments');
            }).isValid(function (model) {
                return model.aspects.valid && model.aspects.subtreeValid;
            }).data({
                asWizardNavigationSteps: ['legalPaymentStep1'],
                regularNavigation: true
            });

            step('legalPaymentStep4').onNext.goTo('cardPaymentComplete').skipOnResumeIf(function (_ref7) {
                var submission = _ref7.value;
                return submission.baseData.periodStatus === 'Bound';
            }).label(function () {
                return translateFn('and.quoteandbind.pm.views.paymentstep.Legal and payments');
            }).data({
                asWizardNavigationSteps: ['legalPaymentStep1'],
                regularNavigation: true
            });

            junction('cardPaymentComplete');
        }
    };
});
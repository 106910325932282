import template from 'text!./non-renewal-banner.html';


const RENEWAL_BANNER_COUNT_START = 0;
const RENEWAL_BANNER_COUNT_END = 22;

export default [() => ({

    restrict: 'A',
    template: template,
    scope: {},
    controller: ['$rootScope', '$scope', ($rootScope, $scope) => {

        $scope.displayNonRenewalBanner = () => {
            const daysToExpiration = $rootScope.policyContext.getNumberOfDaysToExpiry();
            if ($rootScope.policyContext.isNotRenewing()) {
                return daysToExpiration > RENEWAL_BANNER_COUNT_START && daysToExpiration <= RENEWAL_BANNER_COUNT_END;
            }
            return false;
        };
    }]
})];
module.exports = {
	"@extends": "../config.json5",
	"auth": {
		"auth0": {
			"clientId": "MtCWcVg3JkQIUKrtQF4M2qeSD2i9c3DD",
			"url": "https://gw-test.eu.auth0.com",
			"redirectPath": "/dist/html/common/login-redirect.html"
		}
	},
	"endpoints": {
		"build": {
			"cc": {
				"host": "https://cc.prod.gw10.cbp-uk.co.uk",
				"port": "443"
			},
			"pc": {
				"host": "https://pc.prod.gw10.cbp-uk.co.uk",
				"port": "443"
			}
		}
	}
}
import templateStr from 'text!./change-user-pwd.html';
import AuthenticationService from 'edge/api/gw-portals-auth-js/AuthenticationService';

export default ['$filter', 'oAuth', ($filter, oAuth) => {
    const authenticationService = AuthenticationService(oAuth);
    const NOT_NULL = $filter('translate')('displaykey.Edge.Web.Api.Model.NotNull');
    const PWD_MISMATCH = $filter('translate')('and.directives.change-user-pwd.PwdMismatch');
    const API_ERROR = $filter('translate')('and.directives.change-user-pwd.APIError');
    const API_OLD_PWD_WRONG = $filter('translate')('and.directives.change-user-pwd.OldPwdWrong');
    const API_PWD_MUST_DIFFER = $filter('translate')('and.directives.change-user-pwd.PwdMustDiffer');
    const API_PWD_POLICY = $filter('translate')('and.directives.change-user-pwd.PwdPolicy');
    const PWD_POLICY = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

    return {
        restrict: 'A',
        template: templateStr,
        scope: {},
        controller: ['$rootScope', '$scope', '$location', ($rootScope, $scope, $location) => {
            const setAPIError = (message) => {
                $scope.$applyAsync(() => {
                    $scope.apiError = message;
                });
            };
            $scope.clearAPIMessage = () => setAPIError(null);

            $scope.newPwdTooltip = {
                content: 'and.directives.change-user-pwd.PwdPolicy',
                trigger: 'click'
            };
            $scope.oldPwdVM = {
                value: null,
                aspects: {
                    inputCtrlType: 'text',
                    required: true,
                    get valid() {
                        return !!$scope.oldPwdVM.value;
                    },
                    get validationMessages() {
                        if (this.valid === false) {
                            return [NOT_NULL];
                        }
                        return [];
                    }
                }
            };

            $scope.newPwdVM = {
                value: null,
                aspects: {
                    inputCtrlType: 'text',
                    required: true,
                    get valid() {
                        return !!$scope.newPwdVM.value && PWD_POLICY.test($scope.newPwdVM.value);
                    },
                    get validationMessages() {
                        if (!$scope.newPwdVM.value) {
                            return [NOT_NULL];
                        } else if (PWD_POLICY.test($scope.newPwdVM.value) !== true) {
                            return [API_PWD_POLICY];
                        }
                        return [];
                    }
                }
            };

            $scope.confirmNewPwdVM = {
                value: null,
                aspects: {
                    inputCtrlType: 'text',
                    required: true,
                    get valid() {
                        return !!$scope.confirmNewPwdVM.value &&
                            PWD_POLICY.test($scope.confirmNewPwdVM.value) &&
                            $scope.confirmNewPwdVM.value === $scope.newPwdVM.value;
                    },
                    get validationMessages() {
                        if (!$scope.confirmNewPwdVM.value) {
                            return [NOT_NULL];
                        } else if (PWD_POLICY.test($scope.confirmNewPwdVM.value) !== true) {
                            return [API_PWD_POLICY];
                        } else if ($scope.confirmNewPwdVM.value !== $scope.newPwdVM.value) {
                            return [PWD_MISMATCH];
                        }
                        return [];
                    }
                }
            };

            $scope.changePassword = (form) => {
                form.submitted = true;
                $scope.clearAPIMessage();
                if (form.valid === false) {
                    return;
                }
                $scope.inFlight = true;
                return authenticationService.changeUserPassword_AND({
                    userID: $rootScope.authData.userData.user_id,
                    oldPassword: $scope.oldPwdVM.value,
                    newPassword: $scope.confirmNewPwdVM.value
                })
                    .then((response) => {
                        switch (response.status) {
                            case 200: // Success, reload AMP
                                authenticationService.logout();
                                break;
                            case 401: // Old password is wrong
                                setAPIError(API_OLD_PWD_WRONG);
                                break;
                            case 422: // New password is the same as old password
                                setAPIError(API_PWD_MUST_DIFFER);
                                break;
                            case 400: // Password not valid
                                setAPIError(API_PWD_POLICY);
                                break;
                            default:
                                throw new Error('unknown status');
                        }
                    })
                    .catch(() => {
                        setAPIError(API_ERROR);
                    })
                    .finally(() => {
                        $scope.inFlight = false;
                    });
            };
        }]
    };
}];
const sanFranciscoGeo = {lat: 37.923933, lng: -122.5203};

export default class LossLocationMap {
    constructor({mapsApi, coords, onPositionChange}) {
        this.canChangePos = true;
        this.mapsApi = mapsApi;
        this.coords = coords;
        this.onPositionChange = onPositionChange;
    }

    init(mountNode) {
        this.initMap(mountNode);
        this.setInitialMarkerPosition();
    }

    initMap(mountNode) {
        this.map = new this.mapsApi.Map(mountNode, {
            zoom: 16,
            center: this.getInitialMapCenter(),
            clickableIcons: false,
            streetViewControl: false,
            disableDoubleClickZoom: true,
            gestureHandling: 'cooperative',
            draggableCursor: 'pointer'
        });

        this.map.addListener('click', event => {
            if (this.canChangePos) {
                this.setMarkerPosition({
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng()
                });
            }
        });

        this.mapsApi.event.addListenerOnce(this.map, 'idle', () => {
            this.mapsApi.event.trigger(this.map, 'resize');
        });

        mountNode.addEventListener('touchend', () => {
            clearTimeout(this.touchTimeout);
            this.canChangePos = true;
        });

        mountNode.addEventListener('touchmove', () => {
            this.canChangePos = false;
            clearTimeout(this.touchTimeout);
            this.touchTimeout = setTimeout(() => {
                this.canChangePos = true;
            }, 200);
        });
    }

    setInitialMarkerPosition() {
        if (this.areCoordsProvided()) {
            this.setMarkerPosition({lat: this.coords.latitude, lng: this.coords.longitude});
        } else {
            this.setDeviceGeolocation();
        }
    }

    initMarker() {
        this.marker = new this.mapsApi.Marker({
            map: this.map,
            draggable: true,
            animation: this.mapsApi.Animation.DROP,
            cursor: 'url(//maps.gstatic.com/mapfiles/openhand_8_8.cur) 8 8, default'
        });

        this.marker.addListener('dragend', event =>
            this.setMarkerPosition({
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            }));
    }

    setMarkerPosition(position) {
        this.coords.latitude = position.lat;
        this.coords.longitude = position.lng;
        if (!this.marker) {
            this.initMarker();
        }
        this.marker.setPosition(position);
        this.onPositionChange({ position });
    }

    areCoordsProvided() {
        return this.coords
            && this.coords.latitude
            && this.coords.longitude;
    }

    getInitialMapCenter() {
        return this.coords && this.coords.latitude && this.coords.longitude
            ? {lat: this.coords.latitude, lng: this.coords.longitude}
            : sanFranciscoGeo;
    }

    setDefaultLocation() {
        this.map.setCenter(sanFranciscoGeo);
        this.map.setZoom(5); // Geo location API is not available
    }

    setDeviceGeolocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position) {
                    const latLong = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    this.map.setCenter(latLong);
                    this.setMarkerPosition(latLong);
                } else {
                    this.setDefaultLocation();
                }
            }, () => {
                this.setDefaultLocation();
            });
        } else {
            this.setDefaultLocation();
        }
    }
}
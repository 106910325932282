import _ from 'underscore';
import templateStr from 'text!./templates/vehicle-incident.html';

export default ['TranslateService', 'LocaleService', (TranslateService, LocaleService) => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            vehicleIncident: '=',
            index: '=?'
        },
        controller: ['$scope', ($scope) => {
            $scope.phoneMask = LocaleService.getRegionSamplePhoneNumber().replace(/[0-8]/g, '9');

            $scope.contactName = (contact) => {
                return contact.placeholder ?
                    TranslateService.instant('fnol.pa.directives.vehicle-incident.Other Driver') :
                    contact.getDisplayName(TranslateService.instant('fnol.pa.directives.vehicle-incident.New Driver'));
            };

            $scope.vehicleName = (vehicle) => {
                return vehicle.placeholder ?
                    TranslateService.instant('fnol.pa.directives.vehicle-incident.Other Vehicle') :
                    vehicle.getDisplayName(TranslateService.instant('fnol.pa.directives.vehicle-incident.New Vehicle'));
            };

            $scope.getAvailablePassengers = () => {
                return _.filter($scope.vehicleIncident.value.availablePassengers());
            };

            $scope.addPassenger = (passengerContact, $event) => {
                $scope.vehicleIncident.value.addPassenger(passengerContact);
                $event.preventDefault();
            };

            $scope.removePassenger = (passengerContact) => {
                let removeIdx;
                _.find($scope.vehicleIncident.passengers.value, (tableContact, index) => {
                    const matched = tableContact === passengerContact ||
                        tableContact.publicID === passengerContact.publicID;
                    removeIdx = (matched) ? index : -1;
                    return matched;
                });
                $scope.vehicleIncident.passengers.value.splice(removeIdx, 1);
            };

            $scope.isPolicyHolderVehicle = (vehicle) => {
                if (vehicle && vehicle.policyVehicle) {
                    return vehicle.policyVehicle.value;
                }
                return false;
            };

            $scope.getAboutVehicleTitle = (vehicle) => {
                if ($scope.isPolicyHolderVehicle(vehicle)) {
                    return 'and.fnol.pm.directives.vehicle-incident.AboutYourVehicle';
                }
                return 'and.fnol.pm.directives.vehicle-incident.AboutOtherVehicle';
            };
        }],

        link: (scope /* , element, attrs, Ctrl*/) => {
            scope.TranslateService = TranslateService;
        }
    };
}];
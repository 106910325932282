import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./RepairFacilitiesMap.html';
import styles from './RepairFacilitiesMap-hashed.scss';
import controller from './RepairFacilitiesMapCtrl';

export default {
    template: cssUtil.hashTemplate(template, styles),
    bindings: {
        mapName: '@?',
        selectedRepairFacility: '<',
        repairFacilities: '<',
        farthestRepairFacilityDistance: '<',
        mapCenter: '<',
        onMapFacilitySelect: '&',
        onSearchWaypointChange: '&',
        onRepairFacilitiesRefresh: '&',
        policyLocation: '<',
        lossLocation: '<',
        myLocation: '<',
        selectVendor: '<',
        enableSearch: '<',
        selectStaticAddress: '&'
    },
    controller
};

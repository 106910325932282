import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./RepairFacilitySelector.html';
import styles from './RepairFacilitySelector-hashed.scss';
import controller from './RepairFacilitySelectorCtrl';

export default {
    template: cssUtil.hashTemplate(template, styles),
    controller,
    bindings: {
        onFacilitySelect: '&',
        selectedRepairFacility: '<',
        repairFacilities: '<',
        farthestRepairFacilityDistance: '<',
        searchCenter: '<',
        onSearchWaypointChange: '&',
        onRepairFacilitiesRefresh: '&',
        policyLocation: '<',
        lossLocation: '<',
        myLocation: '<',
        selectStaticAddress: '&'
    }
};

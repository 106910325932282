import templateStr from 'text!./rewards-shop.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            policyNumber: '<',
        },
        controller: ['$scope', '$rootScope', 'RewardsService_AND', 'ScrollService_AND', 'TelematicsService_AND', ($scope, $rootScope, RewardsService_AND, ScrollService_AND, TelematicsService_AND) => {
            $scope.rewardsOn = true;
            $scope.firstTerm_AND = $rootScope.policyContext.currentPolicy.firstTerm_AND;
            const daysSinceEffective = $rootScope.policyContext.getNumberOfDaysSinceEffective();

            // calling telematics service for reward points / scores to check wether the customer has a score, this will determine visiabbility of message
            // on the reward shop screen

            const getScores = (monthlyAddedMiles) => {
                TelematicsService_AND.getScores($scope.policyNumber, monthlyAddedMiles.id);
                $scope.showPointsComingSoon = false;
            };

            if ($scope.rewardsOn) {
                $scope.showPointsComingSoon = false;
                if ($scope.firstTerm_AND === true) {
                    TelematicsService_AND.getBonusMiles($scope.policyNumber)
                        .then((data) => {
                            $scope.bonusMiles = data;
                            if (!!data.yearlyAddedMiles && data.yearlyAddedMiles.length > 0) {
                                getScores(data.yearlyAddedMiles[0].monthlyAddedMiles[0]);
                            } else {
                                $scope.showPointsComingSoon = true;
                            }
                        });
                }
                const getPointsHistory = function () {
                    RewardsService_AND.getPointsHistory($scope.policyNumber)
                        .then((data) => {
                            $scope.pointsBalance = data;
                        })
                        .catch((error) => {
                            if (daysSinceEffective > 1) {
                                $scope.pointsError = error.baseError.error.data.appData;
                            }
                        });
                };

                const getProductList = function () {
                    RewardsService_AND.getProductList($scope.policyNumber)
                        .then((data) => {
                            $scope.products = data;
                        })
                        .catch((error) => {
                            // once the policy has been bought there is a delay before the policy is put into the Miles Engine DB, we do not
                            // want to show an error message to the customer in this 24 hour period.
                            if (daysSinceEffective > 1) {
                                $scope.productsError = error.baseError.error.data.appData;
                            }
                        });
                };

                getPointsHistory();
                getProductList();

                $scope.rewardPending = false;
                $scope.purchaseSuccess = false;
                $scope.purchaseError = false;

                $scope.purchase = function (product) {
                    $scope.rewardPending = true;
                    ScrollService_AND.scrollToTop();
                    RewardsService_AND.purchaseProduct($scope.policyNumber, product)
                        .then((data) => {
                            $scope.isDisabled = true;
                            $scope.purchases = data;
                            $scope.purchaseSuccess = true;
                            getPointsHistory();
                            getProductList();
                            $scope.rewardPending = false;
                        })
                        .catch((error) => {
                            $scope.buyError = error.baseError.error.data.appData;
                            $scope.purchaseSuccess = false;
                            $scope.purchaseError = true;
                            $scope.rewardPending = false;
                        });
                };

                $scope.$watch('policyNumber', (newValue) => {
                    if (!newValue) {
                        return;
                    }

                    // set new policy number when new policy is picked in policy list
                    $scope.policyNumber = newValue;
                    // reset flags from previous policy
                    $scope.rewardPending = false;
                    $scope.purchaseSuccess = false;
                    $scope.purchaseError = false;

                    getPointsHistory($scope.policyNumber);
                    getProductList($scope.policyNumber);
                });
            }
        }]
    };
}];
import templateStr from 'text!./bonus-miles-single.html';

export default ['ScrollService_AND', (ScrollService_AND) => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            page: '<'
        },
        link: ($scope, element) => {
            let oldPolicyNumber;
            $scope.$watch(() => $scope.page.selectedMonth, (newValue, oldValue) => {
                if (oldValue) { // Don't scroll when initialising
                    // Only scroll if within the same policy
                    if (oldPolicyNumber === $scope.page.policyNumber) {
                        ScrollService_AND.scrollToElement(element, 0);
                    }
                }
                oldPolicyNumber = $scope.page.policyNumber;
            });
        }
    };
}];

import cssUtil from 'gw-portals-util-js/CssUtil';
import infoWindowTemplate from 'text!./InfoWindowTemplate.html';
import infoWindowStaticLocationTemplate from 'text!./InfoWindowStaticLocationTemplate.html';
import styles from './RepairFacilitiesMap-hashed.scss';
import CustomMarkerFactory from './CustomMarker/CustomMarkerFactory';
import MarkerStyle from 'edge/fnol/pa/components/RepairFacilitiesMap/MarkerStyle';

const renderDynamicInfoWindowContent = (infoWindow, templateText, templateStyle, templateData, renderFn) => {
    setTimeout(() => {
        const staticData = Object.assign({}, templateStyle, templateData);
        const hashedTemplate = cssUtil.hashTemplate(templateText, staticData);
        const renderedTemplateNode = renderFn(hashedTemplate);
        infoWindow.setContent(renderedTemplateNode.html());
    }, 0);
};

export default class RepairFacilitiesMap {
    constructor({mapsApi}) {
        if (!mapsApi) throw new Error('google map api not found');
        this.mapsApi = mapsApi;
        this.repairFacilities = [];
        this.openedInfoWindow = null;
        this.CustomMarker = CustomMarkerFactory(mapsApi);
        this.listeners = [];
        this.policyLocation = null;
        this.lossLocation = null;
        this.myLocation = null;
        this.selectedVendor = null;
        this.currentZoomLevel = null;
    }

    init(mountNode, geocode, zoomLevel) {
        // maps options - https://developers.google.com/maps/documentation/javascript/reference#MapOptions
        this.map = new this.mapsApi.Map(mountNode, {
            zoom: zoomLevel,
            center: this._getInitialSearchCenter(geocode),
            clickableIcons: false,
            streetViewControl: false,
            gestureHandling: 'greedy',
            zoomControl: true,
            zoomControlOptions: {
                position: this.mapsApi.ControlPosition.RIGHT_BOTTOM
            },
            disableDefaultUI: true
        });
        this.currentZoomLevel = zoomLevel;

        return new Promise((resolve) =>
            this.onceIdle(() => {
                this.mapsApi.event.trigger(this.map, 'resize');
                resolve();
            }));
    }

    onceIdle(action) {
        this.mapsApi.event.addListenerOnce(this.map, 'idle', action);
    }

    _getInitialSearchCenter(geocode) {
        return geocode ?
            new this.mapsApi.LatLng(geocode.latitude, geocode.longitude) :
            new this.mapsApi.LatLng(37.923933, -122.5203); // San Francisco by default
    }

    setRepairFacilityMarkers(facilities) {
        return facilities && facilities.forEach(facility => this.addRepairFacilityMarker(facility));
    }

    addRepairFacilityMarker(facility) {
        this.repairFacilities.push({facility, marker: this.createRepairFacilityMarker(facility)});
    }

    setSelectedFacilityMarker(facility) {
        if (this.selectedVendor) {
            this.selectedVendor.setMap(null);
            this.selectedVendor.remove();
        }

        const {primaryAddress: {spatialPoint}} = facility;
        this.selectedVendor = new this.CustomMarker(
            new this.mapsApi.LatLng(spatialPoint.latitude, spatialPoint.longitude),
            this.map,
            MarkerStyle.wrench
        );
    }

    removeRepairFacilityMarkers() {
        this.repairFacilities.forEach(item => {
            item.marker.setMap(null);
            item.marker.remove();
        });
        this.repairFacilities = [];
    }

    setCenter(geocode) {
        this.map.panTo(new this.mapsApi.LatLng(geocode.latitude, geocode.longitude));
    }

    getCenter() {
        const mapCenter = this.map.getCenter();
        return {
            latitude: mapCenter.lat(),
            longitude: mapCenter.lng()
        };
    }

    createRepairFacilityMarker(facility) {
        const {primaryAddress: {spatialPoint}, addressBookUID} = facility;

        const marker = new this.CustomMarker(
            new this.mapsApi.LatLng(spatialPoint.latitude, spatialPoint.longitude),
            this.map,
            Object.assign({id: addressBookUID}, MarkerStyle.wrench)
        );
        marker.addListener('click', (event) => {
            if (event) { // prevent click propagation to map object
                event.cancelBubble = true;
            }
            this.onMapFacilitySelect(facility, true);
        });

        return marker;
    }

    setPolicyMarker(policyLocation) {
        if (this.policyLocation && this.policyLocation.marker) {
            this.mapsApi.event.clearInstanceListeners(this.policyLocation.marker);
            this.policyLocation.marker.setMap(null);
            this.policyLocation.marker.remove();
        }

        this.policyLocation = this._createStaticLocationMarker(MarkerStyle.home, policyLocation, true);
    }

    setLossMarker(lossLocation) {
        if (this.lossLocation && this.lossLocation.marker) {
            this.mapsApi.event.clearInstanceListeners(this.lossLocation.marker);
            this.lossLocation.marker.setMap(null);
            this.lossLocation.marker.remove();
        }

        this.lossLocation = this._createStaticLocationMarker(MarkerStyle.car, lossLocation, true);
    }

    setMyLocationMarker(myLocation) {
        if (this.myLocation) {
            this.myLocation.marker.setMap(null);
            this.myLocation.marker.remove();
        }

        this.myLocation = this._createStaticLocationMarker(MarkerStyle.male, myLocation, false);
    }

    _createStaticLocationMarker(markerStyle, location, infoWindow) {
        const marker = new this.CustomMarker(
            new this.mapsApi.LatLng(location.geocode.latitude, location.geocode.longitude),
            this.map,
            markerStyle
        );

        if (infoWindow) {
            const templateData = Object.assign({}, styles, {location});
            location.infoWindow = new this.mapsApi.InfoWindow({
                content: cssUtil.hashTemplate(infoWindowStaticLocationTemplate, templateData),
                pixelOffset: new this.mapsApi.Size(0, -15),
                position: new this.mapsApi.LatLng(location.geocode.latitude, location.geocode.longitude)
            });

            marker.addListener('mouseover', () => {
                location.infoWindow.open(this.map, marker);
            });
            marker.addListener('mouseout', () => {
                location.infoWindow.close();
            });
        }

        location.marker = marker;
        return location;
    }

    displayInfoWindow(facility, marker, renderFn, closeOnSecondClick) {
        const candidate = marker ?
            {facility, marker} :
            this.repairFacilities.find(item => facility && item.facility.addressBookUID === facility.addressBookUID);

        if (!candidate) {
            console.warn(`Unable to find ${facility}`, facility);
            return;
        }

        this.mapsApi.event.clearListeners(this.map, 'click');

        if (this.openedInfoWindow) {
            this.openedInfoWindow.close();
            const sameMarkerClick = (candidate.facility.addressBookUID === this.openedInfoWindow.markerID);
            this.openedInfoWindow = null;
            if (sameMarkerClick && closeOnSecondClick) {
                return;
            }
        }

        const infoWindow = new this.mapsApi.InfoWindow();
        infoWindow.open(this.map, candidate.marker);
        this.openedInfoWindow = infoWindow;
        this.openedInfoWindow.markerID = candidate.facility.addressBookUID;
        renderDynamicInfoWindowContent(
            infoWindow,
            infoWindowTemplate,
            styles,
            {facility},
            renderFn
        );

        this.mapsApi.event.addListener(this.map, 'click', () => {
            if (this.openedInfoWindow) {
                this.openedInfoWindow.close();
            }
        });
    }

    addListener(eventName, listener) {
        const listenerHandler = this.mapsApi.event.addListener(this.map, eventName, listener);
        this.listeners.push(listenerHandler);
    }

    addMapFacilitySelectListener(listener) {
        this.onMapFacilitySelect = listener;
    }

    addPositionChangeListener(action) {
        this.addListener('dragend', () => this.onceIdle(action));
    }

    addZoomListener(zoomOutAction, zoomInAction) {
        this.addListener('zoom_changed', () => this.onceIdle(() => {
            const zoomLevel = this.map.getZoom();
            if (this.currentZoomLevel > zoomLevel) {
                zoomOutAction();
            } else {
                zoomInAction();
            }
            this.currentZoomLevel = zoomLevel;
        }));
    }

    destroyAllListeners() {
        this.listeners.forEach(this.mapsApi.event.removeListener);
    }

    getMapsCoords(coords) {
        if (coords.lat && typeof coords.lat === 'function' &&
            coords.lng && typeof coords.lng === 'function'
        ) {
            return coords;
        }
        return new this.mapsApi.LatLng(parseFloat(coords.latitude), parseFloat(coords.longitude));
    }

    getMilesDistance(startPoint, endPoint) {
        const startLatLng = this.getMapsCoords(startPoint);
        const endLatLng = this.getMapsCoords(endPoint);
        const distance = this.mapsApi.geometry.spherical.computeDistanceBetween(startLatLng, endLatLng);

        // convert from meters to miles
        return distance * 0.000621371192;
    }

    hasVisiblePoint(coords) {
        const pointLatLng = this.getMapsCoords(coords);

        return this.map.getBounds().contains(pointLatLng);
    }

    getViewportSearchRadius() {
        const viewportBounds = this.map.getBounds();
        const nePoint = viewportBounds.getNorthEast();
        const swPoint = viewportBounds.getSouthWest();
        const diameterDistance = this.getMilesDistance(swPoint, nePoint);

        return diameterDistance * 0.5;
    }

    setZoomLevel(level) {
        this.map.setZoom(level);
    }
}

import cssUtil from 'gw-portals-util-js/CssUtil';

import BaseWizardCtrl from '../baseTransaction/BaseTransactionWizardCtrl';
import BaseWizardView from 'text!../baseTransaction/baseTransactionWizard.html';

import TransactionConfirmationCtrl from '../TransactionConfirmation/TransactionConfirmationCtrl';
import TransactionConfirmationView from 'text!../TransactionConfirmation/TransactionConfirmation.html';
import TransactionConfirmationStyles from '../TransactionConfirmation/TransactionConfirmation-hashed.scss';

export default function () {

    const parentState = 'quoteandbindRoot_AND'; // ANDIE

    return {
        baseTransactionWizard: {
            parent: parentState,
            // url: '/baseTransactionWizard', ANDIE Remove URL
            controller: BaseWizardCtrl,
            template: BaseWizardView,
            resolve: {
                submissionViewModel: ['StateUtils', StateUtils => {
                    return () => StateUtils.dataForState('baseTransactionWizard').model;
                }]
            },
            data: {
                trackingUrl: (state, params, model) => {
                    const suffix = model && model.baseData ? `/${model.baseData.productCode}` : '';
                    return `/transaction-flow${suffix}`;
                }
            }
        },

        policyTransactionConfirmation: {
            name: 'confirmation',
            url: '/policyTransactionConfirmation',
            parent: parentState,
            controller: TransactionConfirmationCtrl,
            template: cssUtil.hashTemplate(TransactionConfirmationView, TransactionConfirmationStyles),
            params: {
                transactionSummary: null
            },
            data: {
                trackingUrl: (state, params) => {
                    return `/policyTransactionConfirmation/${params.transactionSummary.baseData.productCode}`;
                }
            }
        }
    };
}
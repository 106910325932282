import templateStr from 'text!./policy-vehicle-info-renewal.html';
import InsuranceUtil from 'edge/api/gw-portals-util-js/InsuranceUtil_AND';

export default [() => {
    return {
        restrict: 'AE',
        template: templateStr,
        scope: {
            'policyVehicle': '<'
        },
        controller: ['$scope', ($scope) => {
            $scope.getYesNoString = InsuranceUtil.getYesNoString;
        }]
    };
}];
import templateStr from 'text!./templates/billing-same-choice.html';

export default () => {
    return {
        restrict: 'E',
        template: templateStr,
        scope: {
            model: '=',
            readonly: '='
        }
    };
};

import BaseCtrl from 'edge/platform/base/BaseCtrl';
import moment from 'moment';

const fn = ($scope, StateUtils, submissionViewModel, brandingData) => {
    const ctrl = Object.create(BaseCtrl);
    const flowModel = StateUtils.activeDataForState(true).flowModel;
    const submissionVM = submissionViewModel();
    const pmVM = submissionVM.lobData.personalMotor;
    const periodStartDate = moment(submissionVM.baseData.periodStartDate.value);

    ctrl.init = () => {
        $scope.page = {
            driversVM: pmVM.coverables.pmDrivers,
            policyholderVM: pmVM.coverables.pmDrivers.findFirstElement(pmVM.coverables.pmDrivers.value.find((driver) => driver.isPolicyHolder))
        };
    };

    ctrl.canAddDriver = () => {
        let limit;
        switch (brandingData) {
            // case 'tbb': // Maximum for TBB is PH + 4 drivers
            //    limit = 5;
            //     break;
            default: // For rest of the brands is PH + 4 drivers
                limit = 5;
                break;
        }
        return $scope.page.driversVM.length < limit;
    };

    ctrl.addDriver = () => {
        const data = StateUtils.activeDataForState(true);
        flowModel.snapshot = data.model;
        flowModel.jumpToStep('pmDriverDetails', data.model, {addNamedDriver: true});
    };

    ctrl.removeDriver = (driver) => {
        pmVM.coverables.value.removeDriver(driver);
        if (driver.mainDriver === true) { // If we're removing the main driver, set PH to main driver
            pmVM.coverables.pmDrivers.value.forEach((d) => {
                d.mainDriver = d.isPolicyHolder === true;
            });
        }
    };

    ctrl.setMainDriver = (newMainDriver) => {
        $scope.page.driversVM.forEach(({value: driver}) => {
            driver.mainDriver = driver === newMainDriver;
        });
    };

    ctrl.isMainDriverValid = () => {
        if (brandingData !== 'tbb') {
            return true;
        }
        const mainDriverVM = pmVM.coverables.pmDrivers.findFirstElement(pmVM.coverables.pmDrivers.value.find((driver) => driver.mainDriver));        
        const ageOfMainDriver = periodStartDate.diff(moment(mainDriverVM.dateOfBirth.value), 'years');
        return (ageOfMainDriver >= 17 && ageOfMainDriver <= 35);
    };

    $scope.next = (callback) => {
        $scope.form.submitted = true;
        if (ctrl.isMainDriverValid() !== true) {
            return callback();
        }
        callback(submissionVM);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'StateUtils', 'submissionViewModel', 'brandingData'];

export default fn;

import moment from 'moment';

export default {
    getDateFromDTO: (dateDTO) => {
        const m = moment();
        m.year(dateDTO.year);
        m.month(dateDTO.month);
        m.date(dateDTO.day);

        return m.format('DD/MM/YYYY');
    },
    getYesNoString: (value) => value ? 'Yes' : 'No'
};


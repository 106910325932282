class ContactAgentCtrl {

    constructor($stateParams, $state) {
        this.stateParams = $stateParams;
        this.state = $state;
        this.claimNumber = this.stateParams.claimNumber;
    }

    goBackToHomeState() {
        const [returnState, params] = this.stateParams.returnStateInfo;
        this.state.go(returnState, params);
    }
}

ContactAgentCtrl.$inject = ['$stateParams', '$state'];
export default ContactAgentCtrl;
import RouteConfig from './config/RouteConfig';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import EmailService from './services/EmailService';
import LoadSaveService from './services/LoadSaveService';
import RenewalService_AND from './services/RenewalService_AND';
import ErrorHandlingService from './services/ErrorHandlingService';
import CustomQuoteService from './services/CustomQuoteService';
import PolicyService from './services/PolicyService';
import UserAccountService from './services/UserAccountService';
import PropertyCodeLookupService from './services/PropertyCodeLookupService';
import WizardSidebarAdditional from './directives/WizardSidebarAdditional';
import QuotePolicyBox from './directives/QuotePolicyBox';
import QuoteDetailsBox from './directives/QuoteDetailsBox';
import Money from './directives/Money';
import InfoBar from 'integration/quoteandbind/directives/QnbInfoBar';
import Confirmation from 'integration/quoteandbind/directives/Confirmation';
import QuoteOffering from 'integration/quoteandbind/directives/QuoteOffering';
import CustomQuote from 'integration/quoteandbind/directives/CustomQuote';
import GuidanceLink from 'integration/quoteandbind/guidance/directives/GuidanceLink';
import CompositeFlowConfig from 'integration/quoteandbind/config/CompositeFlowConfig';
import DefaultProductProvider from 'integration/quoteandbind/config/DefaultProductProvider';
import LobOfferingSelectionBase from 'integration/quoteandbind/directives/LobOfferingSelection/BaseCoverages/BaseCoverages';
import LobOfferingSelectionAdditional from 'integration/quoteandbind/directives/LobOfferingSelection/AdditionalCoverages/AdditionalCoverages';
import MobileLobCoverages from 'integration/quoteandbind/directives/LobOfferingSelection/mobileQuoteView/mobileLobCoverages/mobileLobCoverages';
import MobileLobSummary from 'integration/quoteandbind/directives/LobOfferingSelection/mobileQuoteView/mobileLobSummary/mobileLobSummary';
import ActionMessageUtil from './util/ActionMessageUtil';
import AvailableProductsAndFeatures from './directives/AvailableProductsAndFeatures/AvailableProductsAndFeatures';
import ProductZipCodeContainer from './directives/ProductZipCodeContainer/ProductZipCodeContainer';
import MultipleOfferingView from 'edge/quoteandbind/common/directives/CommonOfferingSelection/defaultQuoteView/MultipleOfferingView';
import MultipleOfferingViewInternal from 'edge/quoteandbind/common/directives/CommonOfferingSelection/defaultQuoteView/MultipleOfferingViewInternal/MultipleOfferingViewInternal';
import CommonOfferingSelection from 'edge/quoteandbind/common/directives/CommonOfferingSelection/CommonOfferingSelection';
import QuoteViewCell from 'edge/quoteandbind/common/directives/CommonOfferingSelection/common/quoteViewCell/quoteViewCell';
import MobileQuoteOfferings from 'edge/quoteandbind/common/directives/CommonOfferingSelection/mobileQuoteView/MobileQuoteOfferings';
import MobileCoverageSummary from 'edge/quoteandbind/common/directives/CommonOfferingSelection/mobileQuoteView/mobile-coverage-summary/MobileCoverageSummary';
import MobileQuoteViewInternal from 'edge/quoteandbind/common/directives/CommonOfferingSelection/mobileQuoteView/mobileQuoteInternal/mobileQuoteInternal';
import quoteRetrievalScreen from './directives/QuoteRetrieval_AND/QuoteRetrievalScreen'; // ANDIE
import InfoBox from './directives/InfoBox_AND/InfoBox'; // ANDIE
import navigationStepsMobile from './directives/NavigationMenuMobile_AND/navigationStepsMobile'; // ANDIE
import andPaymentPlans from './directives/PaymentPlans_AND/PaymentPlansCtrl'; // ANDIE
import andRepresentativeExample from './directives/MonthlyPlanRepresentativeEx_AND/MonthlyPlanRepresentativeExCtrl'; // ANDIE
import StaticDocumentsService_AND from './services/StaticDocumentsService_AND'; // ANDIE
import andCardIcons from './directives/CardIcons_AND/CardIcons'; // ANDIE
import andSvgIcon from './directives/SvgIcon_AND/SvgIcon'; /// ANDIE

import 'edge/policyjob/app';
import 'edge/platform/base/module';
import 'gw-portals-questionsets-angular';
import 'edge/platform/widgets/modal/module';
import 'edge/platform/widgets/inputs/module';
import 'edge/platform/widgets/table/module';
import 'edge/platform/widgets/loading/module';
import 'edge/platform/paymentdetails/module';
import 'edge/platform/uimetadata/module';
import 'gw-portals-driven-ui-angular/module';
import 'edge/platform/widgets/datetimepicker/module';
import 'edge/platform/widgets/accordion/module';
import 'edge/platform/widgets/basicinputs/module';
import 'gw-portals-viewmodel-angular/aspects/module';
import 'edge/platform/flow/module';
import 'edge/platform/widgets/wizard/module';
import 'edge/platform/campaign/module';
import 'edge/platform/widgets/facebook/module';
import 'edge/platform/widgets/documentupload/module';
import 'edge/policycommon/common/app';
import 'portalConfig';
import 'angularRouter';

export default angular.module('edge.quoteandbind.common.app', [
    'edge.policyjob.app',
    'portal.config',
    'edge.platform.base',
    'gw-portals-questionsets-angular',
    'ui.router',
    'edge.platform.widgets.loadingMsg',
    'edge.platform.widgets.modal',
    'edge.platform.widgets.inputs',
    'edge.platform.widgets.table',
    'edge.platform.paymentdetails',
    'edge.platform.uimetadata',
    'edge.platform.metadataDrivenUI',
    'edge.platform.widgets.ui.bootstrap.datetimepicker',
    'edge.platform.widgets.ui.bootstrap.accordion',
    'edge.platform.widgets.basicinputs',
    'gw-portals-viewmodel-angular.aspects',
    'edge.platform.flow',
    'edge.platform.widgets.wizard',
    'edge.platform.campaign',
    'edge.platform.widgets.facebooksharing',
    'edge.platform.widgets.documentupload',
    'edge.policycommon.common.app',
])

    .constant('lobConfig', {
        lobs: ApplicationFeatures.getAllFeatures().quoteandbind_lob
    })

    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        let states = new RouteConfig(RootStateConfig);
        states = ApplicationFeatures.getFeatureEnabledStates(states);

        for (const stateName in states) {
            if (states.hasOwnProperty(stateName)) {
                $stateProvider.state(stateName, states[stateName]);
            }
        }
    }])

    .factory('qnb.ErrorHandlingService', ErrorHandlingService)
    .factory('qnb.EmailService', EmailService)
    .factory('qnb.LoadSaveService', LoadSaveService)
    .factory('qnb.CustomQuoteService', CustomQuoteService)
    .factory('qnb.PolicyService', PolicyService)
    .factory('qnb.UserAccountService', UserAccountService)
    .factory('qnb.PropertyCodeLookupService', PropertyCodeLookupService)
    .factory('StaticDocumentsService_AND', StaticDocumentsService_AND)

    .directive('gwQnbInfoBar', InfoBar)
    .directive('gwWizardSidebarAdditional', WizardSidebarAdditional)
    .directive('gwQnbQuotePolicyBox', QuotePolicyBox)
    .directive('gwQnbQuoteOffering', QuoteOffering)
    .directive('gwQnbQuoteDetailsBox', QuoteDetailsBox)

    .directive('gwQnbCustomQuote', CustomQuote)
    .directive('gwQnbConfirmation', Confirmation)
    .directive('gwQnbMoney', Money)
    .directive('gwGuidanceLink', GuidanceLink)
    .directive('gwAvailableProductsAndFeatures', AvailableProductsAndFeatures)
    .directive('gwProductZipCodeContainer', ProductZipCodeContainer)
    .directive('gwQnbCommonOfferingSelection', CommonOfferingSelection)
    .directive('gwQnbMobileCoverageSummary', MobileCoverageSummary)
    .directive('andQuoteRetrievalScreen', quoteRetrievalScreen)
    .directive('andInfoBox', InfoBox)
    .directive('andNavigationStepsMobile', navigationStepsMobile)
    .directive('andPaymentPlans', andPaymentPlans)
    .directive('andRepresentativeExample', andRepresentativeExample)
    .directive('andCardIcons', andCardIcons)
    .directive('andSvgIcon', andSvgIcon)
    
    .component('gwQnbMultipleOfferingView', MultipleOfferingView)
    .component('gwQnbMultipleOfferingViewInternal', MultipleOfferingViewInternal)
    .component('gwQnbMobileQuoteOfferings', MobileQuoteOfferings)
    .component('gwQnbLobOfferingSelectionBase', LobOfferingSelectionBase)
    .component('gwQnbLobOfferingSelectionAdditional', LobOfferingSelectionAdditional)
    .component('gwQnbMobileQuoteInternal', MobileQuoteViewInternal)
    .component('gwQnbMobileLobCoverages', MobileLobCoverages)
    .component('gwQnbMobileLobSummary', MobileLobSummary)
    .component('gwQnbQuoteViewCell', QuoteViewCell)

    .factory('qnb.CompositeFlowDefn', CompositeFlowConfig)
    .factory('qnb.DefaultProductProvider', DefaultProductProvider)
    .factory('qnb.ActionMessageUtil', ActionMessageUtil)

    .factory('RenewalService_AND', RenewalService_AND)

    .value('qnb.ViewModelCtx', {
        'AccountEmailRequired': true,
        'DriverEmailRequired': true,
        'AccountDOBRequired': true
    })

    .value('qnb.endpointConfig', {
        'loadSaveEndpoint': 'quote',
        'emailEndpoint': 'quote',
        'customQuoteEndpoint': 'customquote',
        'bopCoverablesEndpoint': 'bopcoverables',
        'slquote': 'slquote'
    })

    .value('qnb.xCenterRouteConfig', 'quote')

    .value('qnb.returnStateFn', () => ['sessionEnded_AND'])
    .value('qnb.effectiveDateStateFn', () => ['effectiveDate'])
    .value('qnb.errorStateFn', model => ['contactUs', {
        quoteID: (model) ? model.quoteID.value : null
    }]);

export default [() => {

    return {
        require: [
            '?^gwAbExperimentSegmentation',
            '?^gwCampaignSegmentation',
            '?^gwBrandingSegmentation',
            '?^gwQueryParamsSegmentation',
            '?^gwLocationSegmentation',
            '?^andProductSegmentation'
        ], // one of these controllers should be present
        restrict: 'EA',
        scope: {
            is: '@' // {Array} options to be checked if they hit the variant.
        },
        link: function (scope, element, attrs, parentCtrls) {

            const parentCtrl = parentCtrls.find(ctrl => !!ctrl);
            if (!parentCtrl) {
                throw new Error('gw-segmentation-option directive must be wrapped by one of the segmentation directives');
            }
            // initially hide the option
            element.hide();

            const optionModel = {
                isHidden: true, // initially hide the element while promise is being resolved
                isDefault: attrs.defaultOption !== undefined,
                optionValues: scope.is ? JSON.parse(scope.is) : [],
                show: function () {
                    element.show();
                    this.isHidden = false;
                },
                hide: function () {
                    element.hide();
                    this.isHidden = true;
                }
            };

            // inform the parent directive
            parentCtrl.registerSegmentOption(optionModel);
        }
    };
}];
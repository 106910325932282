import AccountPolicyDetailTemplate from 'text!edge/policy/common/views/account-policy-details.html';
import AccountPolicyDetailCtrl from 'edge/policy/common/controllers/AccountPolicyDetailsCtrl';

export default (RootStates) => {
    const parentState = RootStates.policy;

    return {
        'account-policy-details': {
            name: 'account-policy-details',
            url: '/account-policy-details/{policyNumber}',
            parent: parentState,
            controller: AccountPolicyDetailCtrl,
            template: AccountPolicyDetailTemplate
        }
    };
};
import _ from 'lodash';
import AuthBase from './components/AuthBase_AND/'; // ANDIE
import AuthStatus from './components/AuthStatus/';
import AuthLoginModalService from './components/AuthLoginModal/';
import LogoutWarningService from './components/LogoutWarningModal/';
import LockLogin from './components/LockLogin_AND/'; // ANDIE
import ForgotPassword from './components/ForgotPassword_AND/'; // ANDIE
import ResetPassword from './components/ResetPassword_AND/'; // ANDIE
import SignUp from './components/SignUp/';
import SessionLogout from './components/ExternalIDPLoggedOut/';
import appFeatures from 'effective-features!';
import AuthConfirmationMessage from './components/common/AuthConfirmationMessage/';
import AuthConfirmationMessage_AND from './components/common/AuthConfirmationMessage_AND/';
import AuthResetPassConfirmationMessage from './components/common/AuthResetPassConfirmationMessage_AND/'; // ANDIE
import AuthenticationService from 'plugin?auth-type!gw-portals-auth-js/AuthenticationService';
import OAuthService from 'gw-portals-auth-js/OAuthService';
import InactivityTimerService from './InactivityTimerService';
import AuthStateConfig from './config/RouteConfig';
import BaseTransportService from 'gw-portals-transport-js';

export default angular.module('edge.platform.auth', ['portal.config', 'ui.router', 'edge.platform.base']).provider('gwInactivityTimerService', InactivityTimerService).provider('gwAuthTokenService', OAuthService).component('andAuthBase', AuthBase) // ANDIE
.component('gwAuthStatus', AuthStatus).component('andLockLogin', LockLogin) // ANDIE
.component('andForgotPassword', ForgotPassword) // ANDIE
.component('andResetPassword', ResetPassword) // ANDIE
.component('gwAuthConfirmationMessage', AuthConfirmationMessage).component('andAuthConfirmationMessage', AuthConfirmationMessage_AND).component('andAuthResetPassConfirmationMessage', AuthResetPassConfirmationMessage) // ANDIE
.component('gwSignUp', SignUp).component('gwSessionLogout', SessionLogout).factory('auth.AuthLoginModal', AuthLoginModalService).factory('gwLogoutWarningService', LogoutWarningService).config(['$stateProvider', function ($stateProvider) {
    var states = new AuthStateConfig();
    _.values(states).forEach(function (state) {
        $stateProvider.state(state);
    });
}]).run(['$rootScope', 'auth.AuthLoginModal', '$window', '$state', 'gwAuthTokenService', 'oAuth', 'state_plugin_config', '$location', function ($rootScope, AuthLoginModal, $window, $state, AuthTokenService, oAuth, stateConfig, $location) {

    var authHome = 'authLogin';
    var authenticationService = AuthenticationService(oAuth);
    var authenticatedFlag = false;

    // update authData for the app on change
    authenticationService.onLoginStateChange(function (authData) {
        authenticatedFlag = authData.isLoggedIn;
        // populate authData to the app
        $rootScope.$applyAsync(function () {
            $rootScope.authData = authData;
        });
        // handle logout by navigating to login page
        if (!authenticatedFlag) {
            if (authData.evtData) {
                var headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authData.evtData.token
                };
                // ANDIE Add /accountmanagement prefix for proxy routing
                BaseTransportService.send('/accountmanagement/singlelogout', headers, 'logout'); // destroy all user's tokens (if enabled in backend).
            }
            var authFeatures = appFeatures.auth;
            if (authFeatures && authFeatures.reloadOnLogout) {
                window.location.hash = ''; // clear the state
                window.location.reload();
            } else if (authData.evtData && authData.evtData.origin !== 'uaa') {
                $state.go('authSessionLogout', { origin: authData.evtData.origin });
            } else {
                $state.go(authHome);
            }
        }
    });

    if (!/(resetpassword)/.test($window.location.hash)) {
        // trigger check if logged in
        authenticationService.loginWithCurrentCookies().then(function () {
            if (/(login)/.test($window.location.hash)) {
                // go to the home page if we have had to login
                $state.go(stateConfig.home);
            } else {
                // otherwise if the user is already authenticated and has just refreshed the page
                // just load the state as determined by the url
            }
        }).catch(function () {
            // user is not logged in
            $state.go(authHome, { username: $location.search().username }); // ANDIE
        });
    }

    $rootScope.$on('$stateChangeStart', function (evt, to, toParams, from) {
        // prevent going to an authenticated page when user is not authenticated
        // but still allow going to the another auth page
        if (!authenticatedFlag && from.data && from.data.isAuthPage && !(to.data && to.data.isAuthPage)) {
            evt.preventDefault();
        }

        // prevent going to the login or auth pages if the user is authenticated
        if (authenticatedFlag && to.data && to.data.isAuthPage) {
            evt.preventDefault();
        }
    });
}]);
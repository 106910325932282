import templateStr from 'text!./pm-quote-excess-mta.html';

const WINDSCREEN_COVER = 'PMGlassCov';
const WINDSCREEN_REPAIR_EXCESS = 'PMGlassRepairExcess';
const WINDSCREEN_REPLACEMENT_EXCESS = 'PMGlassReplacementExcess';

export default [() => {
    return {
        restrict: 'A',
        scope: {
            lob: '<'
        },
        template: templateStr,
        controller: ['$scope', ($scope) => {
            $scope.vehicle = $scope.lob.coverables.pmVehicles[0];

            $scope.driversComparator = ({value: d1}, {value: d2}) => {
                if (d1.isPolicyHolder) {
                    return -Number.MAX_SAFE_INTEGER;
                }
                return d1.fixedId - d2.fixedId;
            };

            $scope.getTotalExcess = (driver) => driver.compulsoryExcess + $scope.vehicle.voluntaryExcess;

            // Windscreen repair and replacement excess
            const glassCoverage = $scope.lob.offerings[0].coverages.pmVehicleCoverages[0].coverages
                .find((cov) => cov.codeIdentifier === WINDSCREEN_COVER);
            if (glassCoverage) {
                const repairExcess = glassCoverage.terms.find((term) => term.patternCode === WINDSCREEN_REPAIR_EXCESS);
                const replacementExcess = glassCoverage.terms.find((term) => term.patternCode === WINDSCREEN_REPLACEMENT_EXCESS);
                if (repairExcess) {
                    $scope.glassRepairExcess = repairExcess.chosenTermValue;
                }
                if (replacementExcess) {
                    $scope.glassReplacementExcess = replacementExcess.chosenTermValue;
                }
            }

            // Compulsory and Voluntary descriptions toggle. There should only be one displayed.
            $scope.toggleExcessDescription = (primary, secondary) => {
                $scope[primary] = !$scope[primary];
                if ($scope[primary] === true) {
                    $scope[secondary] = false;
                }
            };
        }]
    };
}];
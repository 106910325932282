import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = function (
    $scope, $state, PaymentIntakeService, AccountBillingDetailsService,
    ModalService, $window
) {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        ctrl.getBillingSummary().then((summary) => {
            $scope.policyInvoiceStream = _normaliseData(summary);
        });
    };


    ctrl.getBillingSummary = () => {
        return AccountBillingDetailsService.getAccountBillingSummary();
    };


    ctrl.cancel = () => {
        $state.transitionTo($state.$current.data.actions.cancel);
    };


    ctrl.makePaymentRequest = (invoiceIds, amount, instrument) => {
        return PaymentIntakeService.makeDirectPayment(invoiceIds, amount, instrument);
    };


    ctrl.transitAfterPayment = (stateSpec) => {
        $state.transitionTo(stateSpec);
    };


    ctrl.pay = (invoiceIds, amount, instrument) => {
        $scope.submitBillPaymentPromise = ctrl.makePaymentRequest(invoiceIds, amount, instrument).then(ctrl.transitAfterPayment, () => {
            ModalService.showError(
                'billing.controllers.MakePaymentCtrl.Payment request failed',
                'billing.controllers.MakePaymentCtrl.Sorry your payment could not be processed at this time'
            );
        });
    };


    ctrl.goBack = () => {
        $window.history.back();
    };


    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$state', 'integration.PaymentIntakeService', 'billing.AccountBillingDetailsService',
    'ModalService', '$window'
];

export default fn;

/**
 * Wrangle the date to be useable by the payment for directive
 * @param {Object} data
 * @returns {{invoiceSummary: *}}
 * @private
 */
function _normaliseData(data) {
    const normalisedData = {
        invoiceSummary: data.activeInvoices
    };

    normalisedData.invoiceSummary = normalisedData.invoiceSummary.map((invoice) => {
        invoice.invoiceNumber = invoice.number;
        invoice.invoiceId = invoice.id;
        invoice.totalValue = invoice.amountTotal;
        invoice.totalPaid = invoice.amountPaid;
        return invoice;
    });

    return normalisedData;
}
import routeConfig from 'edge/fnol/general/config/RouteConfig';
import 'edge/fnol/common/app';

export default angular.module('edge.fnol.general.app', ['edge.fnol.common.app'])
    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = routeConfig(RootStateConfig);
        for (const stateName in states) {
            if (states.hasOwnProperty(stateName)) {
                $stateProvider.state(stateName, states[stateName]);
            }
        }
    }]);
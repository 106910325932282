import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, claimViewModel) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.claimVm = claimViewModel();
        $scope.repairFacility = $scope.claimVm.lobs.personalMotor.repairOption.repairFacility;

        if (!$scope.repairFacility.value) {
            const repairFacilityContact = $scope.claimVm.value.createContact({
                subtype: 'AutoRepairShop'
            });
            repairFacilityContact.initializePrimaryAddress();
            $scope.repairFacility.value = repairFacilityContact;
        }
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'claimViewModel'];

export default fn;
import _ from 'underscore';

function createChoiceTerm(model, onChange) {
    const res = {
        'hasOptions': true,
        'options': model.options
    };

    Object.defineProperty(res, 'value', {
        'get': () => {
            return model.chosenTerm;
        },
        'set': (term) => {
            model.chosenTerm = term;
            model.updated = true;
            onChange();
        }
    });

    return res;
}


function createInputTerm(model, onChange) {
    let newValue = model.directValue;
    const res = {
        'hasOptions': false,
        'applyNewValue': () => {
            if (newValue === model.directValue) {
                return;
            }

            model.directValue = newValue;
            model.updated = true;
            onChange();
        }
    };

    Object.defineProperty(res, 'newValue', {
        'get': () => {
            return newValue;
        },
        'set': (nv) => {
            newValue = nv;
        }
    });

    Object.defineProperty(res, 'value', {
        'get': () => {
            return model.directValue;
        },
        'set': (nv) => {
            newValue = nv;
            model.directValue = nv;
            model.updated = true;
            onChange();
        }
    });

    return res;
}

/** Model of the coverage term. */
export default {
    /**  Creates a new coverage term with the underlying coverage model. Return a new term with the following
     * functions and properties:
     *
     * <dl>
     *     <dt>name : String</dt><dd>Name of the coverage term</dd>
     *     <dt>options : Array</dt><dd>Optional array of the term options.</dd>
     *     <dt>hasOptions : Boolean</dt>
     *          <dd>Flag indicating that options are present and should be used for the term.</dd>
     *     <dt>value : String</dt><dd>Read-write property of the coverage's value.</dd>
     *     <dt>updating : () => Boolean</dt><dd>Flag set when this coverage is updating.</dd>
     * </dl>
     *
     * @param {Object} model coverage model.
     * @param {Function} loading to retrieve current loading status.
     * @param {Function} [onChange] function to invoke on the term change.
     * @returns {Object}
     */
    'create': (model, loading, onChange) => {
        onChange = onChange || _.noop;

        const base = !model.valueType ? createChoiceTerm(model, onChange) : createInputTerm(model, onChange);
        base.name = model.name;

        Object.defineProperty(base, 'updating', {
            'get': loading || _.constant(false)
        });

        return Object.freeze(base);
    }
};
import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, claimViewModel, ViewModelService) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.claimVm = ViewModelService.create(
            claimViewModel().value,
            'cc', 'edge.capabilities.claim.fnol.dto.FnolDTO', {
                'RepairChoiceValidation': true
            }
        );

        $scope.repairOption = () => {
            return $scope.claimVm.lobs.personalMotor.repairOption;
        };

        $scope.availableServiceOptions = [
            {
                id: 'PreferredVendor',
                value: 'PreferredVendor',
                label: 'fnol.pa.views.pa-service-choice.I want to use a recommended repair facility',
                description: {
                    mainText: 'fnol.pa.views.pa-service-choice.With a recommended repair facility you get:',
                    additionalPoints: [
                        'fnol.pa.views.pa-service-choice.Lifetime guarantee on all parts and labour',
                        'fnol.pa.views.pa-service-choice.10% discount on other repairs',
                        'fnol.pa.views.pa-service-choice.Prompt, high quality repair service'
                    ]
                }
            },
            {
                id: 'NewVendor',
                value: 'NewVendor',
                label: 'fnol.pa.views.pa-service-choice.I’ll provide repair facility details myself',
                description: {
                    mainText: 'fnol.pa.views.pa-service-choice.Select this option if you have your own preferred repair facility.'
                }
            },
            {
                id: 'NoRepair',
                value: 'NoRepair',
                label: 'fnol.pa.views.pa-service-choice.I don’t need a repair facility',
                description: {
                    mainText: 'fnol.pa.views.pa-service-choice.Don’t worry, you can request service later from an adjuster.'
                }
            }
        ];

        const repairOptionVm = $scope.repairOption();
        if (!repairOptionVm.value) {
            repairOptionVm.value = {
                repairOptionChoice: null,
                repairFacility: null,
                vehicleIncident: null
            };
        }

        if (!repairOptionVm.vehicleIncident.value && $scope.claimVm.lobs.personalMotor.vehicleIncidents.value.length === 1) {
            repairOptionVm.vehicleIncident.value = $scope.claimVm.lobs.personalMotor.vehicleIncidents.getElement(0);
        }

        $scope.$watch('claimVm.lobs.personalMotor.repairOption.repairOptionChoice.value', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                $scope.repairOption().repairFacility.value = null;
            }
        });
    };

    ctrl.renderVehicleIncident = (vehicleIncident) => {
        const {vehicle} = vehicleIncident;
        return `${vehicle.make} ${vehicle.model}`;
    };

    ctrl.shouldSelectVehicleIncident = () => {
        const isMoreThanOneVehicleIncident = $scope.claimVm.lobs.personalMotor.vehicleIncidents.value.length > 1;
        const lossCauseCondition = $scope.fnol.collisionTypeCodes.some((collisionType) => collisionType.code === $scope.claimVm.lossCause.value.code);
        const isNoRepairSelected = $scope.repairOption().repairOptionChoice.value != null && $scope.repairOption().repairOptionChoice.value.code === 'NoRepair';
        return isMoreThanOneVehicleIncident && lossCauseCondition && !isNoRepairSelected;
    };

    ctrl.next = (nextFn) => {
        const {repairOptionChoice} = $scope.repairOption();
        nextFn($scope.claimVm, repairOptionChoice.value ? repairOptionChoice.value.code : null);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'claimViewModel', 'ViewModelService'];

export default fn;
import policyContextRootTemplate_AND from 'has-text?ampclaims!portal/views/policyContextRoot_AND.html'; // ANDIE
import homePageLayoutTemplate from 'text!portal/views/home-page-layout_AND.html'; // ANDIE
import technicalErrorTemplate from 'text!portal/views/technicalError_AND.html'; // ANDIE
import rootTemplate from 'text!portal/views/root_AND.html'; // ANDIE
import LandingPageCtrl from 'portal/controllers/LandingPageCtrl_AND'; // ANDIE
import PolicyContext_AND from 'portal/controllers/PolicyContext_AND'; // ANDIE
import mediaSidebarConfig from 'portal/config/media-sidebar-config';
// ANDIE import AuthenticationService from 'plugin?auth-type!gw-portals-auth-js/AuthenticationService';
import faqConfig from 'portal/config/faq.json';
import 'plugin?auth-type!gw-portals-auth-angular';
import 'angularRouter';
import 'gw-portals-tracking-angular';
import 'gw-portals-tracking-angular/adapters/module';
import JourneyPageTemplate from 'text!edge/journey/views/journey-page.html';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import JourneyPageCtrl from 'edge/journey/controllers/JourneyPageCtrl';
import AuthConfigUtil from 'gw-portals-auth-js/utils/AuthConfigUtil';
import {getConfigValue} from 'gw-portals-config';
import appFeatures from 'effective-features!';

// Flag to separate /app (Dev) and distribution builds
const IS_DISTRIBUTION_BUILD = /* DISTRIBUTION_BUILD_START*/false/* DISTRIBUTION_BUILD_END*/;

const eventAdapterModules = ['gw-portals-tracking-angular.adapters'];

/**
 * Module used to define application level configuration parameters that should be
 * passed to capability modules
 */

export default angular.module('portal.config', ['ui.router', 'gw-portals-tracking-angular', 'edge.platform.auth', ...eventAdapterModules])
    .config(['adapters.googleAnalyticsProvider', 'CredentialsProvider', (googleAnalyticsProvider, credentialsProvider) => {
        googleAnalyticsProvider.configure(credentialsProvider.getCredentials('googleAnalytics'));
    }])
    .config(['adapters.segmentIOProvider', 'CredentialsProvider', (segmentIOProvider, credentialsProvider) => {
        segmentIOProvider.configure(credentialsProvider.getCredentials('segmentIO'));
    }])
    .config(['EventHubProvider', EventAdapterProvider => {
        EventAdapterProvider
        /* Sends every journey event to the console. For debugging purposes only */
            .register({
                serviceName: 'console',
                handler: 'log'
            })
            .register({
                serviceName: 'googleAnalytics',
                handler: 'trackPageView',
                category: 'tracking',
                eventName: 'pageView'
            })
            .register({
                serviceName: 'googleAnalytics',
                handler: 'trackServerCall',
                category: 'tracking',
                eventName: 'serverCall'
            })
            .register({
                serviceName: 'googleAnalytics',
                handler: 'trackServerCallError',
                category: 'tracking',
                eventName: 'serverCallError'
            })
            /* Stores every journey event in the browser local storage. For debugging purposes only */
            .register({
                serviceName: 'localstorage',
                handler: 'onEvent'
            })
            .register({
                serviceName: 'segmentIO',
                handler: 'trackJourney',
                category: 'tracking',
                eventName: 'journey'
            })
            .register({
                serviceName: 'segmentIO',
                handler: 'trackPageView',
                category: 'tracking',
                eventName: 'pageView'
            })
            .register({
                serviceName: 'segmentIO',
                handler: 'identify',
                category: 'tracking',
                eventName: 'identify'
            })
            .register({
                serviceName: 'segmentIO',
                handler: 'trackServerCall',
                category: 'tracking',
                eventName: 'serverCall'
            })
            .register({
                serviceName: 'segmentIO',
                handler: 'trackServerCallError',
                category: 'tracking',
                eventName: 'serverCallError'
            });

        EventAdapterProvider.registerTransformer({
            source: {
                category: 'tracking',
                eventName: 'serverCall'
            },
            target: {
                category: 'tracking',
                eventName: 'identify'
            },
            transformer(payload, send) {
                if (payload.path === 'enrollment/status' && payload.method === 'getEnrolledStatus') {
                    if (payload.success) {
                        if (payload.response.enrolled) {
                            send({
                                userId: payload.response.username
                            });
                        }
                    } else {
                        // Uncomment next line to allow testing with no authz server
                        // send({userId: 'rnewton'})
                    }
                } else if (payload.path === 'profileinfo/user' && payload.method === 'getAccountContactSummary' && payload.success) {
                    send({
                        traits: payload.response
                    });
                }
            }
        });
    }])
    /**
     * Defines the prefix url where the set of states for a sub module should
     * plug into the portal application
     */
    .constant('state_plugin_config', getConfigValue('appSettings.statePathConfig'))

    .constant('defaultLocaleDetails', getConfigValue('userSettings.defaultLocaleDetails'))
    .constant('fnolConfig', getConfigValue('appSettings.fnolConfig'))
    .constant('config.supportPhone', getConfigValue('appSettings.supportPhone'))
    .constant('httpSettings', getConfigValue('httpSettings'))
    .constant('checkForEnrollment', getConfigValue('appSettings.checkForEnrollment'))
    // Used for date of birth dropdowns.
    .constant('AgeAllowedToDrive', getConfigValue('appSettings.AgeAllowedToDrive', 16))
    .constant('MaximumAge', getConfigValue('appSettings.MaximumAge', 100))
    // 50MB. Used to specify the maximum file size for document upload.
    .constant('maxFileUploadSize', getConfigValue('appSettings.maxFileUploadSize', 50000000))

    .config(['$stateProvider', '$urlRouterProvider', ($stateProvider, $urlRouterProvider) => {
        const states = [
            {
                name: 'root',
                url: '',
                template: rootTemplate,
                abstract: true
            },
            { // ANDIE This OOTB state should be the parent to all states that do not require a policy selection.
                name: 'enrolled',
                url: '',
                parent: 'root',
                template: '<div ui-view></div>',
                abstract: true,
                controller: ['$rootScope', '$state', 'UserProfileService_AND', 'ViewModelService',
                    ($rootScope, $state, UserProfileService_AND, ViewModelService) => {
                        // Set account data here, to be available across the entire Portal
                        UserProfileService_AND.getAccountInfo()
                            .then((data) => {
                                $rootScope.accountContext = {
                                    accountHolderVM: ViewModelService.create(data, 'pc', 'itb.edge.capabilities.profileinfo.user.dto.UserProfileDTO', null)
                                };
                            })
                            .catch(() => $state.go('technicalError_AND'));
                    }],
                resolve: {
                    // ANDIE Do not allow users to sign up manually
                    // proceedToHomePage: ['gwAuthTokenService', 'oAuth', '$q', (AuthTokenService, oAuth, $q) => {
                    //     const authenticationService = AuthenticationService(oAuth);
                    //     const authoritiesPromise = AuthTokenService.grantedAuthorities;
                    //     return $q.all([authenticationService.waitForLoginRequestComplete, authoritiesPromise]).then(([, authorities]) => {
                    //         const accountAuthorities = authorities.filter(authority => authority.authorityType === 'ACCOUNT');
                    //
                    //         // if user doesn't have ACCOUNT authorities it is supposed to be enrolled
                    //         if (accountAuthorities.length === 0) {
                    //             return $q.reject({
                    //                 goToEnrollmentPage: 'signupEnrollment'
                    //             });
                    //         }
                    //         return authorities;
                    //     });
                    // }]
                }
            },
            { // ANDIE This state should be the parent to all states whose context is dependent on a policy selection.
                name: 'policyContextRoot_AND',
                url: '',
                abstract: true,
                parent: 'enrolled',
                template: policyContextRootTemplate_AND,
                resolve: {
                    policyContext: PolicyContext_AND
                }
            },
            {
                name: 'home',
                url: '/home',
                parent: 'policyContextRoot_AND',
                controller: LandingPageCtrl,
                template: homePageLayoutTemplate,
                data: {
                    restrictedToEditablePolicies: true
                }
            },
            { // ANDIE Technical Error page
                name: 'technicalError_AND',
                url: '/tech-error',
                parent: 'policyContextRoot_AND',
                template: technicalErrorTemplate
            },
            { // ANDIE This state should be the parent to all quoteandbind states, used for renewal
                name: 'quoteandbindRoot_AND',
                url: '',
                abstract: true,
                parent: 'enrolled',
                template: '<div ui-view class="and-qnb and-renewal"></div>',
                controller: ['$scope', '$rootScope', ($scope, $rootScope) => {
                    // Scope cleanup
                    $scope.$on('$destroy', () => {
                        $rootScope.policyContext.isInRenewalWizard = false; // Manually unset Renewal wizard
                    });
                }]
            },
        ];

        if (ApplicationFeatures.hasFeature('journey')) {
            states.push({
                name: 'journey',
                url: '/journey',
                parent: 'root',
                controller: JourneyPageCtrl,
                template: JourneyPageTemplate
            });
        }

        for (const state in states) {
            if (states.hasOwnProperty(state)) {
                $stateProvider.state(states[state]);
            }
        }

        $urlRouterProvider.when('', '/home');
        $urlRouterProvider.otherwise('/home');
    }])

    .constant('ASYNC_TRANSLATIONS', true) // Constant that allows us to load translations synchronously (tests/Components Examples)
    .constant('ANGULARI18N_PRESET', '') // Constant that allows us to load Angular Locale from different path (Components Examples)
    .constant('TRANSLATE_PROVIDER_PREFIX', '') // Constant that allows us to load translations from different path (Components Examples)
    .constant('FAQ_CONFIG', faqConfig)
    .constant('MEDIA_SIDEBAR_CONFIGURATION', mediaSidebarConfig)
    .constant('oAuth', (() => {
        const authServer = appFeatures.auth.authServer;
        const authConfig = getConfigValue('auth')[authServer];

        const clientId = AuthConfigUtil.getPortalClientId('CustomerEngage-Account_Management', IS_DISTRIBUTION_BUILD);
        if (!authConfig.clientId) {
            authConfig.clientId = clientId;
        }

        return authConfig;
    })())
    .constant('sessionConfig', getConfigValue('userSettings.sessionConfig', {
        inactivityIntervalMins: 5,
        logoutConfirmationIntervalMins: 1
    }))
    // ANDIE
    .constant('GENDERED_PREFIXES', ['mr', 'mrs', 'ms', 'miss'])
    .constant('MALE_PREFIXES', ['mr'])
    .constant('FEMALE_PREFIXES', ['mrs', 'ms', 'miss']);

import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($filter, $scope, $translate, $stateParams, ModalService, StateUtils, submissionViewModel, PolicyService, $rootScope) => {
    const ctrl = Object.create(BaseCtrl);
    const submissionVm = submissionViewModel();
    const pmModel = submissionVm.lobData.personalMotor.value;
    const accountHolder = submissionVm.value.getAccountHolderPerson();

    ctrl.init = () => {
        $scope.page = {
            readonly: false,
            ncdYearsOptions: submissionVm.lobData.personalMotor.coverables.ncdYearsOptions.value
        };
        let selectedVehicle;
        /**
         *  If there are no vehicles, initialise an empty vehicle to be populated,
         *  otherwise on first enter, when there are no vehicles, pick the first one from the array.
         */
        if (pmModel.coverables.pmVehicles.length === 0) {
            selectedVehicle = pmModel.coverables.createVehicle(accountHolder);
        } else {
            selectedVehicle = pmModel.coverables.pmVehicles[0];
        }
        $scope.page.selectedVehicle = submissionVm.lobData.personalMotor.coverables.pmVehicles.findFirstElement(selectedVehicle);
        PolicyService.getModificationLists($rootScope.productCode)
            .then((data) => {

                $scope.page.afterMarketOptions = data[0];
                $scope.page.bodyworkModifications = data[1];
                $scope.page.engineModifications = data[2];
                $scope.page.wheelsOrTyresModifications = data[3];
                $scope.page.conversionModifications = data[4];
                $scope.page.otherModifications = data[5];
            });
        $scope.phDriver = submissionVm.lobData.personalMotor.coverables.value.getPHDriver();
        $scope.page.driver = submissionVm.lobData.personalMotor.coverables.pmDrivers.findFirstElement($scope.phDriver);

        if ($stateParams.changeConfirmationCallback) {
            $stateParams.changeConfirmationCallback(
                $scope.page.selectedVehicle,
                $scope.page.driver,
                $scope.page.afterMarketOptions,
                $scope.page.bodyworkModifications,
                $scope.page.engineModifications,
                $scope.page.wheelsOrTyresModifications,
                $scope.page.conversionModifications,
                $scope.page.otherModifications
            );
        }

        // Renewals specific - if we're on the vehicle page set the submission status to draft to force a quote.
        // That assumes the vehicle page is the only page preceding the quote page, and every time the vehicle page
        // loads it means we need to quote to see the quote page again.
        submissionVm.baseData.periodStatus.value = submissionVm.baseData.periodStatus.aspects.availableValues.find((status) => status.code === 'Draft');
    };

    return ctrl.create($scope);
};
fn.$inject = ['$filter', '$scope', '$translate', '$stateParams', 'ModalService', 'StateUtils', 'submissionViewModel', 'qnb.PolicyService', '$rootScope'];

export default fn;

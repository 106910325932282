import noteDialogTemplate from 'text!../views/note-dialog.html';
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import {PhoneNumberUtil} from 'libphonenumber';

const fn = function (
    $scope, claimViewModel, ModalService, ClaimService,
    ContactUtils, LocaleService, ViewModelService, ApplicationFeatures, AddressUtil
) {
    const noteErrorHead = 'fnol.common.controllers.SummaryCtrl.Note Error Head'; // POR-2061
    const noteErrorMsg = 'fnol.common.controllers.SummaryCtrl.Note Error Message';
    const phoneUtils = PhoneNumberUtil.getInstance();

    let currentNote;

    const newNoteViewModelObj = (initialData) => {
        return {
            note: ViewModelService.create(initialData.note, 'cc', 'edge.capabilities.note.dto.NoteDTO')
        };
    };

    const isVendorRepairOption = () => {
        return $scope.claimVm.lobs.value.personalAuto
            && ($scope.claimVm.lossCause.value.code !== 'theftentire')
            && ApplicationFeatures.hasFeature('repairOption');
    };

    const ctrl = Object.create(BaseCtrl);

    ctrl.init = function () {
        $scope.claimVm = claimViewModel();
        const primaryPhoneNumber = ContactUtils.getPrimaryPhone($scope.claimVm.mainContact.value);

        if (primaryPhoneNumber && $scope.fnol) {
            $scope.fnol.primaryContactPrimaryPhone = phoneUtils.format(phoneUtils.parse(primaryPhoneNumber, LocaleService.getConfiguredPhoneRegion()));
        }

        if (angular.isDefined($scope.claimVm.adjusterNote)) {
            currentNote = $scope.claimVm.adjusterNote.value;
        }

        if (isVendorRepairOption()) {
            const {repairOptionChoice, repairFacility} = $scope.claimVm.lobs.personalAuto.repairOption.value;
            $scope.repairChoice = repairOptionChoice;
            $scope.vendor = repairFacility;
            $scope.vendorAddress = repairFacility ? AddressUtil.format(repairFacility.primaryAddress) : null;
        }
    };

    /**
     * Saves a note.
     * @param {Object} dialogScope scope of the dialog.
     * @param {Object} note note DTO.
     */
    ctrl._saveNote = function (dialogScope, note) {
        dialogScope.noteError = null;
        dialogScope.saveNotePromise = ClaimService
            .createClaimNote($scope.claimVm.claimNumber.value, note.value)
            .then(
                newNote => {
                    ctrl._updateAdjusterNoteOnClaim(newNote);
                    dialogScope.$dismiss();
                },
                error => {
                    console.error('submission of note failed', error);
                    dialogScope.noteError = noteErrorMsg;
                }
            );
    };

    /**
     * Updates a note.
     * @param {Object} dialogScope scope of the dialog.
     * @param {Object} note note DTO.
     */
    ctrl._updateNote = function (dialogScope, note) {
        dialogScope.noteError = null;
        dialogScope.saveNotePromise = ClaimService
            .updateClaimNote($scope.claimVm.claimNumber.value, note.value)
            .then((newNote) => {
                ctrl._updateAdjusterNoteOnClaim(newNote);
                dialogScope.$dismiss();
            }, (error) => {
                console.error('submission of note failed', error);
                dialogScope.noteError = noteErrorMsg;
            });
    };

    /**
     * Deletes a note.
     */
    ctrl.deleteNote = function () {
        $scope.deleteNotePromise = ClaimService
            .deleteClaimNote($scope.claimVm.claimNumber.value, currentNote.publicID)
            .then(() => {
                ctrl._updateAdjusterNoteOnClaim(null);
            }, (error) => {
                console.error('submission of note failed', error);
                ModalService.showError(noteErrorHead, noteErrorMsg);
            });
    };

    ctrl.editNote = function () {
        const note = newNoteViewModelObj({
            note: {
                claimNumber: $scope.claimVm.claimNumber.value,
                publicID: currentNote.publicID,
                subject: currentNote.subject,
                body: currentNote.body
            }
        });
        ModalService.showSimpleDialog(noteDialogTemplate, note, {
            saveNote: ctrl._updateNote
        }, null);
    };

    ctrl.beginCreateNote = function () {
        const note = newNoteViewModelObj({
            note: {
                claimNumber: $scope.claimVm.claimNumber.value,
                subject: '',
                body: ''
            }
        });
        ModalService.showSimpleDialog(noteDialogTemplate, note, {
            saveNote: ctrl._saveNote
        }, null);
    };

    ctrl._updateAdjusterNoteOnClaim = function (note) {
        $scope.claimVm.adjusterNote.value = note;
        currentNote = note;
    };


    return ctrl.create($scope);
};

fn.$inject = ['$scope', 'claimViewModel', 'ModalService', 'claim.ClaimService',
    'ContactUtils', 'LocaleService', 'ViewModelService', 'ApplicationFeatures',
    'AddressUtil'];

export default fn;

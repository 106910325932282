import Submission from 'edge/quoteandbind/common/models/Submission';

export default ['$q',
    'JsonRPCService',
    'qnb.ErrorHandlingService',
    'SessionService_AND', // ANDIE
    ($q, JsonRPCService, ErrorHandlingService, SessionService_AND) => { // ANDIE

        /**
         * @param {Object} params
         * @param {Object} params.submissionDTO
         * @param {String} params.processName
         * @param {String} params.processParams
         * @param {Boolean} params.handleErrorBeforeRequest
         * @param {Boolean} params.setDTOBeforeHandling
         * @returns {Promise}
         */
        function processSubmission(params) {
            let submissionDTO = params.submissionDTO;
            const handleErrorBeforeRequest = params.handleErrorBeforeRequest;
            const setDTOBeforeHandling = params.setDTOBeforeHandling;

            const deferred = $q.defer();
            if (handleErrorBeforeRequest) {
                ErrorHandlingService.checkSessionUUID(submissionDTO, deferred);
            }

            JsonRPCService.send('pc', 'renewal/renewal', params.processName, params.processParams).then(
                (response) => {
                    if (!handleErrorBeforeRequest) {
                        if (setDTOBeforeHandling) {
                            submissionDTO = new Submission(response);
                        }
                        ErrorHandlingService.checkSessionUUID(submissionDTO, deferred);
                        if (!setDTOBeforeHandling) {
                            submissionDTO = new Submission(response);
                        }
                    } else {
                        submissionDTO = new Submission(response);
                    }

                    if (params.startFrontendSession_AND === true) { // ANDIE Start timeout for frontend session
                        SessionService_AND.init(response.sessionLengthInMin_itb);
                    }

                    return deferred.resolve(submissionDTO);
                },
                handleError(deferred)
            );
            return deferred.promise;
        }

        function handleError(deferred) {
            return (error, status, headers) => {
                return deferred.reject([error, status, headers]);
            };
        }

        return {
            startRenewalWizard: (policyNumber, mapBindingData, baseVersion) => {
                return processSubmission({
                    submissionDTO: null,
                    processName: 'start',
                    processParams: [policyNumber, mapBindingData, baseVersion],
                    handleErrorBeforeRequest: false,
                    setDTOBeforeHandling: true,
                    startFrontendSession_AND: true // ANDIE
                });
            },
            quote: (submissionDTO) => {
                return processSubmission({
                    submissionDTO: submissionDTO,
                    processName: 'quote',
                    processParams: [submissionDTO],
                    handleErrorBeforeRequest: false,
                    setDTOBeforeHandling: false
                });
            },
            updateQuoted: (submissionDTO) => {
                return processSubmission({
                    submissionDTO: submissionDTO,
                    processName: 'updateQuoted',
                    processParams: [submissionDTO],
                    handleErrorBeforeRequest: false,
                    setDTOBeforeHandling: false
                });
            },
            updateQuotedAndValidateDDI: (submissionDTO) => {
                return processSubmission({
                    submissionDTO: submissionDTO,
                    processName: 'updateQuotedAndValidateDDI',
                    processParams: [submissionDTO],
                    handleErrorBeforeRequest: false,
                    setDTOBeforeHandling: false
                });
            },
            updateCoverages: (customQuoteDTO) => {
                return JsonRPCService.send('pc', 'renewal/renewal', 'updateCoverages', [customQuoteDTO]);
            },
            preBind: (submissionDTO) => {
                if (!submissionDTO.bindData.chosenQuote) {
                    submissionDTO.bindData.chosenQuote = submissionDTO.quoteData.offeredQuotes
                        .find((quote) => quote.isCustom === true)
                        .publicID;
                }
                return processSubmission({
                    submissionDTO: submissionDTO,
                    processName: 'preBind',
                    processParams: [submissionDTO],
                    handleErrorBeforeRequest: false,
                    setDTOBeforeHandling: false
                });
            },
            bind: (submissionDTO) => {
                return processSubmission({
                    submissionDTO: submissionDTO,
                    processName: 'bind',
                    processParams: [submissionDTO],
                    handleErrorBeforeRequest: false,
                    setDTOBeforeHandling: false
                });
            }
        };
    }
];

import templateStr from 'text!./QuoteRetrievalScreen.html';
import DateUtil from 'gw-portals-util-js/DateUtil';

export default ['AgeAllowedToDrive', 'MaximumAge',
    (AgeAllowedToDrive, MaximumAge) => {
        return {
            restrict: 'E',
            scope: {
                quoteRetrieval: '=?',
                showWarning: '=?',
                disableAll: '=?',
                warnMessage: '@',
                title: '@'
            },
            template: templateStr,
            controller: ['$scope', '$filter', ($scope, $filter) => {
                // DOB limits
                const today = DateUtil.currentDateAtMidnight();
                $scope.maxDateOfBirth = {
                    year: today.getFullYear() - AgeAllowedToDrive,
                    month: today.getMonth(),
                    day: today.getDate()
                };
                $scope.minDateOfBirth = {
                    year: today.getFullYear() - MaximumAge,
                    month: today.getMonth(),
                    day: today.getDate()
                };
                $scope.warnMessage = $scope.warnMessage || [$filter('translate')('and.quoteandbind.wizard.quoteretrieval.WarningMessage.Coverage Start Date update')];
                $scope.title = $scope.title || [$filter('translate')('and.quoteandbind.wizard.quoteretrieval.Retrieve Your Quote')];
                $scope.quoteReferenceTooltip = {
                    content: 'and.quoteandbind.wizard.quoteretrieval.tooltip.Quote Reference',
                    trigger: 'click'
                };
                $scope.postalCodeTooltip = {
                    content: 'and.quoteandbind.wizard.quoteretrieval.tooltip.Postal Code',
                    trigger: 'click'
                };
                $scope.dateOfBirthTooltip = {
                    content: 'and.quoteandbind.wizard.quoteretrieval.tooltip.Date Of Birth',
                    trigger: 'click'
                };
            }]
        };
    }
];
import _ from 'underscore';
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

const fn = function ($filter, $scope, ClaimService, $state, $stateParams, InsuranceSuiteMetadata, claimDetailsLinks, NavigationService) {
    const claimStateTypelist = InsuranceSuiteMetadata.get('cc').types.getTypelist('ClaimState');
    const contactRoleTypelist = InsuranceSuiteMetadata.get('cc').types.getTypelist('ContactRole');
    const coverageTypeTypelist = InsuranceSuiteMetadata.get('cc').types.getTypelist('CoverageType');

    const ctrl = Object.create(BaseCtrl);

    ctrl.init = function () {
        $scope.claimNumber = $stateParams.claimNumber;
        $scope.details = {
            claim: null,
            notes: [],
            activities: null,
            reporter: null,
            claimLosslocation: null,
            claimPrimaryContact: null,
            selectedVehicleIncident: null,
            vehicles: null,
            claimContactsVendors: null,
            claimHospitalsDays: null
        };
        $scope.pageLinks = claimDetailsLinks;


        const claimDetailsPromise = ClaimService.getClaimDetail($stateParams.claimNumber);
        $scope.claimDetailsPromise = claimDetailsPromise;

        claimDetailsPromise.then(
            (claimDetails) => {
                if (typeof ClaimService.addRecentlyViewedClaim === 'function') {
                    ClaimService.addRecentlyViewedClaim($stateParams.claimNumber); // Error handling? Who cares, user already sees the data.
                }

                const pa = claimDetails.lobs.personalAuto;
                if (pa && pa.vehicleIncidents[0]) {
                    pa.vehicleIncidents[0].selected = true;
                    $scope.details.selectedVehicleIncident = pa.vehicleIncidents[0];
                }
                $scope.details.claim = claimDetails;
                $scope.details.activities = claimDetails.activities || {};
                $scope.details.reporter = ctrl._getReporter(claimDetails);
                $scope.details.claimLosslocation = ctrl._getLossLocation(claimDetails);
                $scope.details.claimPrimaryContact = ctrl._getPrimaryContact(claimDetails);
                $scope.details.vehicles = ctrl._getInvolvedVehicles(claimDetails);
                $scope.details.claimContactsVendors = claimDetails.vendors;
                $scope.details.claimHospitalDays = claimDetails.hospitalDays;

                $scope.details.claimContactsWithoutAgent = []; // Get all the contacts apart from the agent
                for (let i = 0; i < claimDetails.claimContacts.length; i++) {
                    if (claimDetails.claimContacts[i].contactRoles.indexOf('agent') === -1) {
                        const con = {
                            contactLink: claimDetails.claimContacts[i],
                            role: $scope.getRoleInvolvement(claimDetails.claimContacts[i])
                        };
                        $scope.details.claimContactsWithoutAgent.push(con);
                    }
                }

                // These are part of the documents directive, but the view doesn't load until now and so it won't work otherwise
                angular.element('#uploadButton2').hide();
                angular.element('#uploadButton').show();
            },
            (error) => {
                console.error('error retrieving claim details', error);
            }
        );

        const notesPromise = ClaimService.getClaimNotes($scope.claimNumber);
        notesPromise.then(
            (notes) => {
                $scope.details.notes = notes;
            },
            (error) => {
                console.error('note retrieval failed', error);
            }
        );

        return this;
    };

    ctrl.defineWatches = () => {
        $scope.$watch('selectedVehicleIncident', (vehicleIncident) => {
            if (vehicleIncident) {
                $scope.details.selectedVehicleCoverages = ctrl._getSelectedVehicleCoverage($scope.details.claim, vehicleIncident.publicID);
            }
        });
    };

    ctrl.selectVehicle = (selectedVehicleIncident) => {
        const pa = $scope.details.claim.lobs.personalAuto;
        if (!pa) {
            return;
        }
        const incident = _.find(pa.vehicleIncidents, (vehIncident) => {
            return vehIncident.publicID === selectedVehicleIncident.publicID;
        });
        if (incident) {
            incident.selected = true;
            $scope.details.selectedVehicleIncident = incident;
            for (const i in pa.vehicleIncidents) {
                if (pa.vehicleIncidents[i].publicID !== incident.publicID) {
                    pa.vehicleIncidents[i].selected = false;
                }
            }
        }
    };

    ctrl.getSelectedVehicleClass = (isActive) => {
        if (isActive) {
            return 'activeVehicle';
        }
        return '';
    };

    ctrl.getStateLabel = () => {
        if ($scope.details.claim && $scope.details.claim.claimState) {
            const typeKey = claimStateTypelist.getCode($scope.details.claim.claimState);
            return typeKey ? typeKey.name : null;
        }
        return null;
    };

    ctrl.getRoleInvolvement = (claimContact) => {
        const roles = [];
        if (claimContact && claimContact.contactRoles && claimContact.contactRoles.length > 0) {
            claimContact.contactRoles.forEach((contactRoleCode) => {
                const contactRole = contactRoleTypelist.getCode(contactRoleCode);
                if (contactRole) {
                    roles.push(contactRole.name);
                }
            });
            return roles.join(', ');
        }
    };

    ctrl.getDisplayName = (contact) => {
        return contact ? contact.displayName : $filter('translate')('claim.controllers.ClaimDetailsCtrl.Unknown');
    };

    ctrl.createNote = () => {
        $state.go('createNote', {
            claimNumber: $stateParams.claimNumber
        });
    };

    ctrl._getSelectedVehicleCoverage = (claimDetails, selectedPublicID) => {
        const selectedCovs = [];
        for (const i in claimDetails.exposures) {
            if (claimDetails.exposures.hasOwnProperty(i)) {
                const pa = claimDetails.exposures[i].lobs.personalAuto;
                if (pa && pa.vehicleIncident.publicID === selectedPublicID) {
                    const coverage = {
                        'Type': '',
                        'Status': ''
                    };
                    if (claimDetails.exposures[i].coverageType) {
                        coverage.Type = coverageTypeTypelist.getCode(claimDetails.exposures[i].coverageType).name;
                    } else {
                        coverage.Type = '-';
                    }
                    coverage.Status = claimDetails.exposures[i].exposureState;
                    selectedCovs.push(coverage);
                }
            }
        }
        return selectedCovs;
    };

    ctrl._getPrimaryContact = (claimDetails) => {
        return claimDetails.mainContact ? claimDetails.mainContact.displayName : null;
    };

    ctrl._getReporter = (details) => {
        if (details.claimReporter.reportedBy) {
            return details.claimReporter.reportedBy.displayName;
        }
        return $filter('translate')('claim.controllers.ClaimDetailsCtrl.Unknown');
    };

    ctrl._getLossLocation = (claimDetails) => {
        const location = claimDetails.lossLocation ? claimDetails.lossLocation.displayName : '';
        location.replace('\n', ', ');
        return location;
    };

    ctrl._getInvolvedVehicles = (claimDetails) => {
        /**
         * For vehicles that exist on the policy, add the vehicle and its coverage
         * to a collection. The collection should only contain vehicles that
         * are involved in the claim.
         */

        // create a list of the VIN's involved on the claim
        const involvedVINarray = [];
        const involvedVehicles = [];
        const pa = claimDetails.lobs.personalAuto;
        const papolicy = claimDetails.policy.lobs.personalAuto;
        if (!pa) {
            return [];
        }
        if ((pa.vehicleIncidents === null) || (pa.vehicleIncidents.length < 1) || (claimDetails.policy === null) || (papolicy.vehicles === null)) {
            return involvedVehicles;
        }
        for (let i = 0; i < pa.vehicleIncidents.length; i++) {
            involvedVINarray.push(pa.vehicleIncidents[i].vehicle.vIN);
        }
        for (let x = 0; x < papolicy.vehicles.length; x++) {
            if (involvedVINarray.indexOf(papolicy.vehicles[x].vIN) !== -1) {
                involvedVehicles.push(papolicy.vehicles[x]);
            }
        }
        return involvedVehicles;
    };

    ctrl.canAccessFinancials = () => {
        return ApplicationFeatures.hasFeature('role.producer') && ApplicationFeatures.hasFeature('financials');
    };

    ctrl.isVendor = () => {
        return ApplicationFeatures.hasFeature('role.vendor');
    };

    ctrl.getBackToPreviousState = () => {
        let previousState = $scope.pageLinks.previousPage;
        let previousParams = null;

        const navigationHistory = NavigationService.recentHistory();
        if (navigationHistory) {
            const historyFrom = navigationHistory.from;
            previousState = historyFrom.state.name;
            previousParams = historyFrom.params;
        }

        $state.go(previousState, previousParams);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$filter', '$scope', 'claim.ClaimService', '$state', '$stateParams', 'InsuranceSuiteMetadata', 'claimDetailsLinks', 'NavigationService'];

export default fn;

import templateStr from 'text!./templates/policy-level-coverages.html';

export default [() => {
    return {
        template: templateStr,
        scope: {
            policy: '='
        },
        link: (/* scope, element, attrs*/) => {
        }
    };
}];
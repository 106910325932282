import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = (
    $rootScope, $scope, $q, $timeout, claimViewModel, ViewModelService, MediaQueriesUtil,
    RepairFacilityService, GeoCodeService, LocaleService, TranslateService, defaultLocaleDetails
) => {
    const ctrl = Object.create(BaseCtrl);
    const phoneLandscapeUpQuery = MediaQueriesUtil.getPhoneLandscapeUpQuery();

    const getSortedRepairFacilities = (isAlphabeticalOrder, repairFacilities) => {
        if (!repairFacilities) {
            return [];
        }

        const locales = `${LocaleService.getCurrentLocale().replace('_', '-')}-u-kn-true`;
        const sortProperty = isAlphabeticalOrder ? 'contactName' : 'proximateDistance';
        return repairFacilities.slice().sort((x, y) => x[sortProperty].localeCompare(y[sortProperty], locales));
    };

    ctrl.init = () => {
        $scope.claimVm = ViewModelService.create(
            claimViewModel().value,
            'cc', 'edge.capabilities.claim.fnol.dto.FnolDTO', {
                'RepairFacilityValidation': true
            }
        );

        $scope.policyLocation = null;
        $scope.lossLocation = null;
        $scope.myLocation = null;

        $scope.gpsOn = false;
        $scope.repairFacilities = null;

        $scope.nextButtonAvailable = phoneLandscapeUpQuery.matches;
        const mobileQueryChanged = (event) => {
            $scope.nextButtonAvailable = event.matches;
            $scope.repairFacilities = getSortedRepairFacilities(event.matches, $scope.repairFacilities);
            $scope.$apply();
        };
        phoneLandscapeUpQuery.addListener(mobileQueryChanged);
        $scope.$on('$destroy', () => phoneLandscapeUpQuery.removeListener(mobileQueryChanged));

        ctrl.translateMessages();
        $scope.repairOption = ctrl.repairOptionVm().value;
        $scope.selectedRepairFacility = ctrl.repairFacilityHasGeo(ctrl.repairFacilityVm().value) ?
            ctrl.repairFacilityVm().value :
            null;

        if ($scope.selectedRepairFacility) {
            $scope.searchCenter = $scope.selectedRepairFacility.primaryAddress &&
                $scope.selectedRepairFacility.primaryAddress.spatialPoint;
            ctrl.onSearchWaypointChange($scope.searchCenter);
            ctrl.repairFacilitiesRefresh();
        }
        ctrl.geocodeStaticLocations()
            .then(() => {
                if (!$scope.selectedRepairFacility) {
                    ctrl.selectSearchAddress('policy');
                }
            })
            .catch(() => {
                console.warn('Cannot resolve geocode for policy address');
                if (!$scope.selectedRepairFacility) {
                    ctrl.selectSearchAddress('sanFrancisco');
                }
            })
            .finally(() => ctrl.repairFacilitiesRefresh());
        ctrl.navigatorLocation();
    };

    ctrl.repairOptionVm = () => {
        return $scope.claimVm.lobs.personalMotor.repairOption;
    };

    ctrl.repairFacilityVm = () => {
        return ctrl.repairOptionVm().repairFacility;
    };

    ctrl.repairFacilityHasGeo = (facility) => {
        return facility && facility.primaryAddress &&
            facility.primaryAddress.spatialPoint &&
            facility.primaryAddress.spatialPoint.latitude &&
            facility.primaryAddress.spatialPoint.longitude;
    };

    /*
     * Retrieve required translation messages
     */
    ctrl.translateMessages = () => {
        $scope.translation = {
            policyTitle: '',
            lossTitle: ''
        };
        TranslateService.translate({
            object: $scope.translation,
            displayKey: 'fnol.pa.components.repair-facilities-search-option.Policy address',
            propName: 'policyTitle',
            scope: $scope
        });
        TranslateService.translate({
            object: $scope.translation,
            displayKey: 'fnol.pa.components.repair-facilities-search-option.Incident address',
            propName: 'lossTitle',
            scope: $scope
        });
    };
    /*
     * Geocode policy & incident locations
     */
    ctrl.geocodeStaticLocations = () => {
        /*
         * Predefine Loss Location address geocode
         */
        GeoCodeService.geocodeAddress($scope.claimVm.value.lossLocation).then(cords => {
            $scope.lossLocation = {
                address: $scope.claimVm.value.lossLocation,
                geocode: cords,
                title: $scope.translation.lossTitle
            };
        });
        return GeoCodeService.geocodeAddress($scope.claimVm.value.mainContact.primaryAddress).then(cords => {
            $scope.policyLocation = {
                address: $scope.claimVm.value.mainContact.primaryAddress,
                geocode: cords,
                title: $scope.translation.policyTitle
            };
        });
    };
    /*
     * RepairFacilitiesSearchOption component logic
     */
    ctrl.selectSearchAddress = (search) => {
        if (!search || search === $scope.searchOption) {
            return;
        }

        $scope.searchOption = search;
        switch (search) {
            case 'incident':
                $scope.searchCenter = $scope.lossLocation.geocode;
                break;
            case 'navigator':
                $scope.searchCenter = $scope.myLocation.geocode;
                break;
            case 'sanFrancisco':
                $scope.searchCenter = {
                    latitude: defaultLocaleDetails.mapCenter.lat,
                    longitude: defaultLocaleDetails.mapCenter.lon
                };
                break;
            default: // policy
                $scope.searchCenter = $scope.policyLocation.geocode;
                break;
        }

        if (!$scope.$$phase) {
            $scope.$apply();
        }

        $rootScope.$broadcast('mapZoomReset');
    };
    /*
     * Eval user navigator access
     */
    ctrl.navigatorLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                $scope.myLocation = {
                    geocode: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }
                };
                $scope.gpsOn = true;
                $scope.$apply();

            }, handleError);
        } else {
            console.warn('Geolocation is not supported for this browser/OS.');
        }
    };
    const handleError = (error) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                console.warn(`User denied the request for Geolocation. ${error.message}`);
                break;
            case error.POSITION_UNAVAILABLE:
                console.warn(`Location information is unavailable. ${error.message}`);
                break;
            case error.TIMEOUT:
                console.warn(`The request to get user location timed out. ${error.message}`);
                break;
            default:
                console.warn(`Navigator error unknown occurred. ${error.message}`);
                break;
        }
    };

    ctrl.setRepairFacility = (item) => {
        if (item && (!$scope.selectedRepairFacility ||
            $scope.selectedRepairFacility.addressBookUID !== item.addressBookUID)) {

            $scope.selectedRepairFacility = item;
            ctrl.repairFacilityVm().value = item;
            $timeout(() => {
                $scope.$apply();
            });
        }
    };

    ctrl.repairFacilitiesRefresh = (coords, radius) => {
        $scope.searchCenter = coords || $scope.searchCenter;

        RepairFacilityService.getVendorsNearby({
            specialistServiceCodes: [getServiceRelatedToLossCause()],
            proximitySearchGeocode: $scope.searchCenter,
            searchRadius: radius ? Math.ceil(radius).toString() : null
        }).then(vendors => {
            $scope.farthestRepairFacilityDistance = vendors.length
                ? getFarthestRepairfacilityDistance(vendors)
                : null;
            $scope.repairFacilities = getSortedRepairFacilities(phoneLandscapeUpQuery.matches, vendors);
        });

        if ($scope.repairFacilities) {
            $scope.repairFacilities = null;
        }
    };

    const getFarthestRepairfacilityDistance = (vendors) => {
        return getSortedRepairFacilities(false, vendors)[vendors.length - 1].proximateDistance.split(' ')[0];
    };

    const getServiceRelatedToLossCause = () => {
        switch ($scope.claimVm.value.lossCause) {
            case 'glassbreakage':
                return 'autoinsprepairglass';
            case 'theftparts':
                return 'autoinsprepairaudio';
            default:
                return 'autoinsprepairbody';
        }
    };

    ctrl.onSearchWaypointChange = (coords) => {
        $scope.searchOption = 'custom';
        $scope.searchCenter = coords || $scope.searchCenter;
    };

    ctrl.next = (nextFn) => {
        const model = ctrl.repairFacilityVm();
        if (model.value !== $scope.selectedRepairFacility) {
            model.value = $scope.selectedRepairFacility;
        }
        nextFn($scope.claimVm);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$rootScope', '$scope', '$q', '$timeout', 'claimViewModel', 'ViewModelService', 'MediaQueriesUtil',
    'claim.RepairFacilityService', 'GeoCodeService', 'LocaleService', 'TranslateService', 'defaultLocaleDetails'];

export default fn;
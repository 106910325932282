import templateStr from 'text!./pm-payment-billing-details.html';
import moment from 'moment';

export default [() => {
    return {
        restrict: 'E',
        scope: {
            submissionVM: '=submissionVm'
        },
        template: templateStr,
        controller: ['$scope', '$filter', ($scope, $filter) => {
            $scope.bindData = $scope.submissionVM.bindData;
            $scope.baseData = $scope.submissionVM.baseData;
            const pclDate = '20220404';
            $scope.bindData.billingAddress.value = $scope.bindData.billingAddress.value || {};
            $scope.bindData.paymentDetails.value = $scope.bindData.paymentDetails.value || {};
            $scope.dateBeforePCLPriceChanges = function () {
                if ($scope.baseData) {
                    return moment($scope.baseData.periodStartDate.value).isBefore(moment(pclDate).startOf('day'));
                }
            };
            $scope.beforePCLDate = $scope.dateBeforePCLPriceChanges();

            $scope.isInstallment = $scope.submissionVM.value.isInstallmentsPaymentPlan_AND() === true;
            if ($scope.isInstallment === true) {
                $scope.bindData.paymentDetails.value.bankAccountData = {};
            }
            $scope.TcMonthlyBeforePCLChanges = [
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.We use Premium Credit Limited (PCL) to provide finance exclusively. We may receive a commission for introducing customers to them'),
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.Your personal information and the bank details you provide will be passed to PCL and they wit contact you via email or text and send PDF or Word documents.'),
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.By proceeding, an application will be made to PCL for credit. Credit is subject to status, and is not guaranteed'),
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.PCL may use a credit reference agency together with any information that leaves a record of the search or other information about you to carry out credit and anti-money laundering checks.'),
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.If credit is granted, PCL wit contact you by email and direct you to their online portal where you will be provided with PCL\'s fun terms and conditions and asked to sign your credit agreement.'),
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.PCL\'s credit agreements are on a running account basis. If you renew your policy, you wit not need to sign your agreement again. We\'ll continue to pass your information to Premium Credit unless you instruct us otherwise'),
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.PCL charge the following fees for certain actions against the credit agreement:'),
                [
                    $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.£10 for reminding you to sign your credit agreement'),
                    $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.MissPaymentCharge'),
                    $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.£5 each time you ask for PCL to change the monthly payment date'),
                ],
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.If you have any questions about your instalments, contact Premium Credit on.')
            ];

            $scope.TcMonthly = [
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.We use Premium Credit Limited (PCL) to provide finance exclusively. We may receive a commission for introducing customers to them'),
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.Your personal information and the bank details you provide will be passed to PCL and they wit contact you via email or text and send PDF or Word documents.'),
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.By proceeding, an application will be made to PCL for credit. Credit is subject to status, and is not guaranteed'),
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.PCL may use a credit reference agency together with any information that leaves a record of the search or other information about you to carry out credit and anti-money laundering checks.'),
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.If credit is granted, PCL wit contact you by email and direct you to their online portal where you will be provided with PCL\'s fun terms and conditions and asked to sign your credit agreement.'),
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.PCL\'s credit agreements are on a running account basis. If you renew your policy, you wit not need to sign your agreement again. We\'ll continue to pass your information to Premium Credit unless you instruct us otherwise'),
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.PCL charge the following fees for certain actions against the credit agreement:'),
                [
                    $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.£25 for reminding you to sign your credit agreement'),
                    $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.MissPaymentChargePostPCLChange'),
                    $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.No fee from PCL to change the monthly payment date'),
                ],
                $filter('translate')('and.quoteandbind.pm.views.paymentstep.2.monthly.If you have any questions about your instalments, contact Premium Credit on.')
            ];

            $scope.IsArray = (ele) => {
                return Array.isArray(ele);
            };

            $scope.confirmBox = {
                aspects: {
                    required: true,
                    inputCtrlType: 'boolean',
                    get valid() {
                        return angular.isDefined($scope.confirmBox.value) && $scope.confirmBox.value !== null
                            && $scope.confirmBox.value === true;
                    },
                    get validationMessages() {
                        if (this.valid === false) {
                            return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                        }
                        return [];
                    }
                }
            };
        }]
    };
}];
import templateStr from 'text!./pm-legal-payment-mta.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            page: '='
        },
        controller: ['$scope', '$rootScope', ($scope, $rootScope) => {
            // Init
            if (!$scope.page.bindDataVM.paymentDetails.value) {
                $scope.page.bindDataVM.paymentDetails.value = {};
            }
            $scope.page.bindDataVM.paymentDetails.creditCardData.value = {};
            // Default the Billing Address to the Policy Address
            $scope.page.bindDataVM.billingAddress.value = $rootScope.policyContext.currentPolicy.periods[0].policyAddress;
            // clearing country field as it shouldn't be set
            $scope.page.bindDataVM.billingAddress.country.value = undefined;

            $scope.firstNameTooltip = {
                content: 'and.topupmiles.directives.fill-card-details.tooltip.FirstName',
                trigger: 'click'
            };
            $scope.surnameTooltip = {
                content: 'and.topupmiles.directives.fill-card-details.tooltip.Surname',
                trigger: 'click'
            };
            $scope.next = (form) => {
                form.submitted = true;
                if (form.valid === true) {
                    $scope.page.goToNext();
                }
            };
        }]
    };
}];
import ComponentUtil from 'gw-portals-util-js/ComponentUtil';
import appFeatures from 'effective-features!';

class FooterCtrl {

    constructor($scope, PresentationMetadataHeaderAndFooter) {
        this.setCtrlParam = ComponentUtil.setCtrlParam(this, $scope);
        this.PresentationMetadataHeaderAndFooter = PresentationMetadataHeaderAndFooter;
        this.isMetadataEnabled = appFeatures.footerEnabled;
        this.setFooterItemsUsingMetadata();
    }

    setFooterItemsUsingMetadata() {
        if (this.isMetadataEnabled) {
            this.PresentationMetadataHeaderAndFooter.getFooterMetadata().then(footerMetadata => {
                if (footerMetadata) {
                    this.setCtrlParam('leftItems', footerMetadata.leftItems);
                    this.setCtrlParam('rightItems', footerMetadata.rightItems);
                    this.setCtrlParam('containsFooterMetadata', true);
                }
            });
        }
    }

    concatFooterItems() {
        if (this.containsFooterMetadata && this.leftItems && this.rightItems) {
            return this.leftItems.concat(this.rightItems);
        }
    }
}

FooterCtrl.$inject = ['$scope', 'PresentationMetadataHeaderAndFooter'];
export default FooterCtrl;
import templateStr from 'text!./templates/user-details-apply-to-all.html';

export default () => {
    return {
        restrict: 'E',
        template: templateStr,
        scope: {
            state: '=',
        }
    };
};

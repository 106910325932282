import _ from 'underscore';
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import PolicySearchUtil from 'gw-portals-util-angular/PolicySearchUtil';
import allowedPolicyTypes from 'gw-portals-featuredetection-js/AllowedPolicyTypes';

const fn = function ($scope, $q, claimViewModel, PolicyService, ModalService, LocaleService, TranslateService) {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = function () {
        $scope.claimVm = claimViewModel();
        $scope.fnol.policySearch = $scope.fnol.policySearch || {};
        $scope.fnol.policyNumber = $scope.fnol.policyNumber || null;
        $scope.fnol.selectedPolicy = $scope.fnol.selectedPolicy || null;
        if (!$scope.fnol.policySearchType && !$scope.fnol.policyNumber && $scope.claimVm.value && $scope.claimVm.policy.value) {
            $scope.fnol.policyNumber = $scope.claimVm.policy.policyNumber.value;
        }
        $scope.fnol.policySearchType = $scope.fnol.policySearchType || 'policyNumber';
        $scope.fnol.policies = $scope.fnol.policies || [];

        /*
         * States:
         * loading - search is in progress.
         * ready - ready to search. No values. No policy. Nothing.
         * noPolicies - search completed, no policies was found.
         * policiesFound - search completed, policies found.
         * direct - direct policy input is enabled.
         */
        $scope.fnol.policySearchState = $scope.fnol.policySearchState || 'direct';

        $scope.policyTypeChange = null;
        $scope.errorMsg = null;

        $scope.allowedPolicyTypes = allowedPolicyTypes.names;
    };

    ctrl.defineWatches = function () {
        /**
         * Watches the policy number field to see if the value changes in order to
         * set the next enable flag and default field values.
         */
        $scope.$watch('fnol.policyNumber', (policyNumber, oldPolicyNumber) => {
            if (policyNumber === oldPolicyNumber) {
                return;
            }
            $scope.errorMsg = null;
        });

        /**
         * Watches the loss date to re-run the search when the loss date changes
         */
        $scope.$watch('claimVm.lossDate.value', (newDate, oldDate) => {
            if (!newDate || (oldDate && newDate && oldDate.getTime() === newDate.getTime())) {
                return;
            }
            ctrl.updateSearch();
        });
    };

    /** Sets a "search by policy number" mode. */
    ctrl.setSearchByNumber = function () {
        /* No modality, just prevent state change during load operation. */
        if ($scope.fnol.policySearchState === 'loading') {
            return;
        }

        $scope.errorMsg = null;
        $scope.fnol.policies = [];
        $scope.fnol.policySearchState = 'direct';
        $scope.fnol.policySearchType = 'policyNumber';
    };

    /**
     * Sets a "search by policy details" mode.
     */
    ctrl.setSearchByQuery = function () {
        /* No modality, just prevent state reset during load operation. */
        if ($scope.fnol.policySearchState === 'direct') {
            $scope.fnol.policySearchState = 'ready';
        }
        $scope.fnol.policySearchType = 'policyDetails';
    };

    /**
     * Set selected policy.
     * @param {Object} item
     */
    ctrl.setSelected = function (item) {
        if ($scope.fnol.selectedPolicy && item && $scope.fnol.selectedPolicy.policyType !== item.policyType) {
            $scope.policyTypeChange = true;
        }
        $scope.fnol.selectedPolicy = item;
    };

    /**
     * Reset the search criteria for the "advanced search" state.
     */
    ctrl.resetSearchCriteria = function () {
        $scope.fnol.policies.length = 0;
        $scope.fnol.policySearchState = 'ready';
    };

    /**
     * Called when the user clicks the next button to progress to the next page.
     *
     * Searching by - Policy number
     *
     * When the user is searching by policy number, there may be a scenario, depending on
     * how much of the policy number is provided, more than one policy is returned from the
     * external system. This function validates that only one policy can be selected, if there
     * is more than one policy returned from the external system then the user will be presented
     * with an error message.
     *
     * Searching by - Policy details
     *
     * When the user is searching by policy details, they can only select one policy so we set
     * the currently selected policy on the claim object and traverse to the next state.
     *
     * @param {Function} flowNextFn - next function to proceed in fnol flow.
     */
    ctrl.validatePolicyAndProceed = function (flowNextFn) {
        let modalPromise;
        let policyChanged;

        if ($scope.claimVm.policy.value) { // policy already set on claim object, check if it has been changed
            switch ($scope.fnol.policySearchState) {
                case 'direct':
                    policyChanged = $scope.claimVm.policy.policyNumber.value !== $scope.fnol.policyNumber;
                    break;
                case 'policiesFound':
                    policyChanged = $scope.claimVm.policy.policyNumber.value !== $scope.fnol.selectedPolicy.policyNumber;
                    break;
                default:
                    break;
            }
        }

        if (policyChanged) {
            modalPromise = ModalService.showConfirm(
                'fnol.common.controllers.PolicySelectProducerCtrl.Modal Title',
                'fnol.common.controllers.PolicySelectProducerCtrl.Modal Content', undefined,
                TranslateService.instant('fnol.common.controllers.PolicySelectProducerCtrl.Modal Option')
            ).result;
        } else {
            modalPromise = $q.resolve();
        }

        modalPromise.then(() => {
            switch ($scope.fnol.policySearchState) {
                case 'direct':
                    ctrl._goToNextByPolicyNumber(policyChanged, flowNextFn);
                    break;
                case 'noPolicies':
                    ctrl._goToNextByPolicyNumber(policyChanged, flowNextFn);
                    break;
                case 'policiesFound':
                    $scope.claimVm.value.changePolicy($scope.fnol.selectedPolicy);
                    if (policyChanged) {
                        flowNextFn($scope.claimVm, {
                            policyChanged: true
                        });
                    } else {
                        flowNextFn($scope.claimVm);
                    }
                    break;
                default:
                    console.info(`Unsupported search state ${$scope.fnol.policySearchState}`);
            }
        }).catch(() => {
            flowNextFn();
        });
    };

    /**
     * Check for next button to be enabled.
     * @returns {Boolean}
     */
    ctrl.isNextEnabled = function () {
        if (!$scope.claimVm.lossDate.aspects.valid) {
            return false;
        }

        /* Search by number handler. */
        if (($scope.fnol.policySearchState === 'direct' || $scope.fnol.policySearchState === 'noPolicies') &&
            $scope.fnol.policyNumber) {
            return true;
        }

        /* Search is active or found no results. */
        if ($scope.fnol.policySearchState !== 'policiesFound') {
            return false;
        }

        if (!$scope.fnol.selectedPolicy) {
            return false;
        }

        /* Ensure that policy was selected from a search result and it is not a some old policy. */
        return _.findWhere($scope.fnol.policies, {
            policyNumber: $scope.fnol.selectedPolicy.policyNumber
        });
    };


    /**
     * Updates policies based on the search criteria.
     */
    ctrl.updateSearch = function () {
        switch ($scope.fnol.policySearchType) {
            case 'policyDetails':
                if (!PolicySearchUtil.searchIsBlank($scope.fnol.policySearch)) {
                    ctrl._updatePoliciesBy(ctrl._getAdvancedCriteria());
                }
                break;
            default:
                break;
            /* We do not support search by a part of policy number. */
        }
    };

    ctrl._getAdvancedCriteria = function () {
        const defaultCountry = LocaleService.getCountry();
        const defaultCountryCode = defaultCountry.code || null;
        return PolicySearchUtil.createAdvancedSearchCriteria($scope.claimVm.lossDate.value, $scope.fnol.policySearch, defaultCountryCode);
    };

    /**
     * Get a simple (policy id) search criteria.
     * @returns {Object}
     */
    ctrl._getSimpleCriteria = function () {
        return {
            lossDate: $scope.claimVm.lossDate.value,
            policyNumber: $scope.fnol.policyNumber
        };
    };

    /**
     * Updates a policy search based on the criteria.
     * @param {*} criteria
     */
    ctrl._updatePoliciesBy = function (criteria) {
        $scope.fnol.loadingMessage = TranslateService.instant('fnol.common.controllers.PolicySelectProducerCtrl.Loading Message');
        $scope.fnol.policies = [];
        $scope.fnol.policySearchState = 'loading';
        const state = PolicyService.searchPolicies(criteria);

        $scope.policySearchPromise = state.then(policies => {
            if (!policies) {
                policies = [];
            }
            const filteredPolicies = PolicySearchUtil.filterPoliciesByFeature(policies, allowedPolicyTypes.names);
            $scope.fnol.policies = filteredPolicies;
            $scope.fnol.policySearchState = filteredPolicies.length > 0 ? 'policiesFound' : 'noPolicies';
        }, error => {
            $scope.errorMsg = TranslateService.instant('fnol.common.controllers.PolicySelectProducerCtrl.Error Loading Message');
            console.error(error);
            $scope.fnol.searchState = 'ready';
        });
    };

    /**
     * Validate a policy number and advance.
     * @param {Boolean} policyChanged
     * @param {Function} flowNextFn
     */
    ctrl._goToNextByPolicyNumber = function (policyChanged, flowNextFn) {
        $scope.fnol.loadingMessage = TranslateService.instant('fnol.common.controllers.PolicySelectProducerCtrl.Validation Loading Message');
        const state = PolicyService.searchPolicies(ctrl._getSimpleCriteria());
        state.then(policies => {
            // if policies has been returned undefined or has no elements in its array
            if (typeof policies === 'undefined' || policies.length === 0) {
                $scope.fnol.policySearchState = 'noPolicies';
                flowNextFn();
            } else if (policies.length > 1) { // if there's more than one policy
                $scope.errorMsg = TranslateService.instant('fnol.common.controllers.PolicySelectProducerCtrl.Greater Than One Found');
                flowNextFn();
            } else { // finally if there is exactly one policy
                $scope.claimVm.policy.value = policies[0];
                if (policyChanged) {
                    flowNextFn($scope.claimVm, {
                        policyChanged: true
                    });
                } else {
                    flowNextFn($scope.claimVm);
                }
            }
        }, error => {
            $scope.errorMsg = TranslateService.instant('fnol.common.controllers.PolicySelectProducerCtrl.Error Loading Message');
            console.error(error);
        });
    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$q', 'claimViewModel', 'fnol.PolicyService', 'ModalService', 'LocaleService', 'TranslateService'];
export default fn;

import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./LogoutWarningModal.html';
import styles from './LogoutWarningModal-hashed.scss';

const hashedTemplate = cssUtil.hashTemplate(template, styles);

export default ['ModalService', function (ModalService) {

    function showInactivityWarning(resetInactivityCallback) {
        const continueSession = (modalScope) => {
            resetInactivityCallback();
            modalScope.$dismiss();
        };

        const modalInstance = ModalService.showSimpleDialog(hashedTemplate, null, {
            continueSession: continueSession
        });

        return modalInstance;
    }

    return {
        showInactivityWarning
    };
}];
import DefaultErrorHandlerService from './DefaultErrorHandlerService';
import CacheService_AND from './CacheService_AND'; // ANDIE
import 'l10nModule';
import 'angular-animate';
import 'angular-aria';
import 'portalConfig';
import 'gw-portals-util-angular';
import 'edge/platform/widgets/loading/module';
import 'edge/platform/widgets/modal/module';
import 'gw-portals-transport-angular';
import 'edge/platform/decorator/module';
import 'edge/platform/atomic_design/module';
import 'edge/platform/widgets/dropdown/module';
import 'edge/platform/widgets/pagestructure/module';
import 'edge/platform/widgets/tabs/module';
import 'gw-portals-branding-angular';
import 'edge/platform/widgets/layouts/module';
import 'edge/platform/widgets/tooltip/module';
import 'edge/platform/widgets/table/module';
import 'edge/platform/widgets/titles/module';
import 'edge/platform/widgets/badge/module';
import 'edge/platform/widgets/actionicon/module';
import 'edge/platform/widgets/typeahead/module';
import 'edge/platform/widgets/findaddress/module';
import 'edge/platform/widgets/findaddress_AND/module'; // ANDIE
import 'edge/platform/widgets/pagination_AND/ui-bootstrap-custom-tpls-2.5.0.min'; // ANDIE
import 'edge/platform/widgets/googleplaceslookup/module';
import 'edge/platform/widgets/tiles/module';
import 'edge/platform/widgets/radioselect/module';
import 'edge/platform/widgets/multiselect/module';
import 'edge/platform/widgets/componentizer/module';
import 'edge/platform/widgets/propertiespagination/module';
import 'edge/platform/widgets/documentupload/module';
import 'gw-portals-tracking-angular';
import 'edge/platform/userinfo/module';
import 'edge/platform/jsonrpc/module';
import 'edge/platform/widgets/navigation/module';
import 'edge/platform/widgets/fileupload/module';
import 'gw-portals-platform-js/versionName/SetVersionHtmlClass';
import 'ng-combo-date-picker'; // ANDIE
import 'and-portals-session-angular'; // ANDIE

export default angular.module('edge.platform.base', [
    'ngAnimate',
    'ngAria',
    'portal.config',
    'edge.platform.util',
    'gw-portals-transport-angular',
    'edge.platform.jsonrpc',
    'edge.platform.widgets.loadingMsg',
    'edge.platform.widgets.modal',
    'edge.platform.decorator',
    // start l10n
    'edge.platform.l10n',
    // end l10n
    'edge.platform.atomic_design',
    'edge.platform.widgets.dropdown',
    'edge.platform.widgets.pagestructure',
    'edge.platform.widgets.tabs',
    'gw-portals-branding-angular',
    'edge.platform.widgets.layouts',
    'edge.platform.widgets.tooltip',
    'edge.platform.widgets.table',
    'edge.platform.widgets.titles',
    'edge.platform.widgets.badge',
    'edge.platform.widgets.actionicon',
    'edge.platform.widgets.typeahead',
    'edge.platform.widgets.findaddress',
    'edge.platform.widgets.findaddress_AND', // ANDIE
    'edge.platform.widgets.googleplaceslookup',
    'edge.platform.widgets.radioselect',
    'edge.platform.widgets.multiselect',
    'edge.platform.widgets.propertiespagination',
    'edge.platform.widgets.tiles',
    'edge.platform.widgets.basicinputs',
    'edge.platform.widgets.componentizer',
    'edge.platform.widgets.documentupload',
    'gw-portals-tracking-angular',
    'edge.platform.userinfo',
    'edge.platform.widgets.navigation',
    'edge.platform.widgets.fileupload',
    'ngComboDatePicker', // ANDIE
    'and-portals-session-angular', // ANDIE
    'ui.bootstrap'
]) // -32768 to -32000 are reserved for pre-defined errors, The remainder of the space is available for application defined errors.
    .constant('ERROR_CODES', {
        PARSE_ERROR: -32700,
        INVALID_REQUEST: -32600,
        METHOD_NOT_FOUND: -32601,
        INVALID_PARAMS: -32602,
        INTERNAL_ERROR: -32603,
        GW_MNP_SECURITY_ERROR: -33000,
        GW_MNP_VALIDATION_ERROR: -33001,
        GW_MNP_QUOTE_SESSION_TIMEOUT: -33004

    })
    .constant('uiConstants', {
        SUBMITTED: 'submitted'
    })

    .provider('DefaultErrorHandlerService', DefaultErrorHandlerService)
    .factory('CacheService_AND', CacheService_AND); // ANDIE

import templateStr from 'text!./templates/policy-common-contacts.html';

export default [() => {
    return {
        restrict: 'E',
        template: templateStr,
        scope: {
            'contacts': '='
        }
    };
}];

import template from 'text!./renewal-banner.html';


const RENEWAL_BANNER_COUNT_START = 0;
const RENEWAL_BANNER_COUNT_END = 22;

export default [() => ({

    restrict: 'A',
    template: template,
    scope: {
        goToPage: '=?',
        renewalAccepted: '<'
    },
    controller: ['$rootScope', '$scope', ($rootScope, $scope) => {

        const hasRenewal = () => $rootScope.policyContext.currentPolicy &&
            $rootScope.policyContext.currentPolicy.periods.length > 1;

        $scope.displayRenewalBanner = () => {
           if (!hasRenewal() || $rootScope.policyContext.isNotRenewing() || $scope.renewalAccepted()) {
              return false;
           }

           const daysToRenewal = $rootScope.policyContext.getNumberOfDaysToRenewal();
           return daysToRenewal > RENEWAL_BANNER_COUNT_START && daysToRenewal <= RENEWAL_BANNER_COUNT_END;
        };
    }]
})];
import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, ClaimService, $q, $state, $stateParams) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.policyPromise = ClaimService.getClaimPolicyDetail($stateParams.claimNumber).then(
            (policy) => {
                $scope.policy = policy;
            },
            (error) => {
                console.error('retrieving policy details failed', error);
            }
        );
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'claim.ClaimService', '$q', '$state', '$stateParams'];

export default fn;
/**
 * Cleans this payment instrument and resets all invalid/unused values.
 *
 * @param {Object} instrument
 * @param {Object} paymentType
 */
function cleanup(instrument, paymentType) {
    if (instrument.view.value.paymentMethod === paymentType.WIRE) {
        instrument.view.creditCardData.value = null;
    } else {
        instrument.view.bankAccountData.value = null;
    }
}

/** Payment instrument service. This service is used to create payment instruments and operate on them. */

export default ['ViewModelService', 'TranslateService', 'paymentType', (ViewModelService, TranslateService, paymentType) => {
    /** Payment instrument service. Provides essential operations and functions for the payment instruments.
     * Core application code does not know exact structure of the objects returned by this service and
     * operates on them in a "generic" way.
     */
    return {
        /** Creates a payment instrument used for the invoice.
         * @returns {{PaymentInstrument}} model of the payment instrument.
         */
        'createInvoicePaymentInstrument': () => {
            const view = ViewModelService.create(
                {
                    'paymentMethod': paymentType.WIRE
                },
                'bc', 'edge.capabilities.billing.dto.PaymentInstrumentDTO'
            );
            return {
                view,
                'fixMethod': false
            };
        },

        /** Creates a payment instrument used for the automatic payments.
         * //@param [initialInstrument] initial value of the payment instrument to edit.
         * @returns {{PaymentInstrument}} model of the payment instrument.
         */
        'createAutopayPaymentInstrument': (/* initialInstrument*/) => {
            const view = ViewModelService.create(
                {
                    'paymentMethod': paymentType.WIRE
                },
                'bc', 'edge.capabilities.billing.dto.PaymentInstrumentDTO'
            );
            return {
                view,
                'fixMethod': true
            };
        },


        /** Checks if a payment instrument is valid.
         * @param {{PaymentInstrument}} instrument instrument to validate.
         * @returns {boolean} true iff instrument is valid.
         */
        'isValid': (instrument) => {
            cleanup(instrument, paymentType);
            return instrument.view.aspects.subtreeValid;
        },

        /** Converts a payment instrument into the DTO. This method is called before the submission and could
         * "clean up" unused/extra data on the payment instrument itself.
         * @param {{PaymentInstrument}} instrument instrument to convert.
         * @returns {*} DTO representation of the instrument or <code>undefined</code> if an instrument is not valid.
         */
        'toDto': (instrument) => {
            cleanup(instrument, paymentType);
            return instrument.view.aspects.subtreeValid ? instrument.view.value : undefined;
        },

        /** Returns a string summary describing a payment instrument.
         * @param {{PaymentInstrument}} instrument instrument to get a summary for.
         * @returns {String} short summary describing the payment instrument (for example, 'you bank account').
         */
        'getInstrumentSummary': (instrument) => {
            switch (instrument.view.value.paymentMethod) {
                case paymentType.CREDIT_CARD:
                    return TranslateService.instant('billing.view.invoice-payment.Payment Source Credit Card');
                case paymentType.WIRE:
                    if (!instrument.view.value.bankAccountData) {
                        return '';
                    }
                    switch (instrument.view.value.bankAccountData.bankAccountType) {
                        case 'savings':
                            return TranslateService.instant('billing.view.invoice-payment.Payment Source Bank Account Savings');
                        case 'checking':
                            return TranslateService.instant('billing.view.invoice-payment.Payment Source Bank Account Checking');
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
            return '';
        }
    };
}];
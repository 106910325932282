import _WizardStepsCtrlAND from 'edge/platform/widgets/wizard/_WizardStepsCtrl_AND';
import templateStr from 'text!./navigation-steps-mobile.html';
import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './navigationStepsMobile-hashed.scss';

export default [
    () => {
        return {
            restrict: 'A',
            replace: true,
            template: cssUtil.hashTemplate(templateStr, styles),
            require: '^gwNavBarWizardBase',
            controller: _WizardStepsCtrlAND,
            link(scope) {
                scope.accordionDisplay = false;
                scope.FAQDisplay = false;
                scope.toggleAccordionDisplay = () => {
                    scope.accordionDisplay = !scope.accordionDisplay;
                    scope.hideFAQ();
                };
                scope.toggleFAQDisplay = () => {
                    scope.FAQDisplay = !scope.FAQDisplay;
                    scope.hideAccordion();
                };
                scope.hideAccordion = () => {
                    scope.accordionDisplay = false;
                };
                scope.hideFAQ = () => {
                    scope.FAQDisplay = false;
                };
            }
        };
    }
];
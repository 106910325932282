export default ['JsonRPCService', 'brandingData', (JsonRPCService, brandingData) => {
    return {
        getAccountPolicySummaries: () => {
            return JsonRPCService.send('pc', 'policy/policy', 'getAccountPolicySummaries', []);
        },

        getAccountPolicyDetails: (policyNumber) => {
            return JsonRPCService.send('pc', 'policy/policy', 'getAccountPolicyDetails', [policyNumber]);
        },

        removeDocument: (publicID) => {
            return JsonRPCService.send('pc', 'policy/docs', 'removeDocument', [publicID]);
        },

        getQNBDomain_AND: () => {
            return JsonRPCService.send('pc', 'policy/policy', 'getQNBDomain', [brandingData.toUpperCase()]);
        },

        newAutoRenewalChoice: (autoRenewal, policyNumber) => {
            return JsonRPCService.send('pc', 'policy/policy', 'newAutoRenewalChoice', [autoRenewal, policyNumber]);
        },
        getAllowPortalRenewalAmendAndMTAAmendDate: () => {
            return JsonRPCService.send('pc', 'policy/policy', 'getAllowPortalRenewalAmendAndMTAAmendDate', []);
        },
    };
}];

import _WizardStepsCtrlAND from 'edge/platform/widgets/wizard/_WizardStepsCtrl_AND';
import templateStr from 'text!./navigation-menu-mobile.html';
import cssUtil from 'gw-portals-util-js/CssUtil';
import styles from './navigationMenuMobile-hashed.scss';

export default ['$rootScope',
    ($rootScope) => {
        return {
            restrict: 'A',
            replace: true,
            template: cssUtil.hashTemplate(templateStr, styles),
            require: '^gwNavBarWizardBase',
            controller: _WizardStepsCtrlAND,
            link(scope) {
                scope.accordionDisplay = false;
                scope.FAQDisplay = false;
                scope.toggleAccordionDisplay = () => {
                    scope.accordionDisplay = !scope.accordionDisplay;
                    scope.hideFAQ();
                };
                scope.toggleFAQDisplay = () => {
                    scope.FAQDisplay = !scope.FAQDisplay;
                    scope.hideAccordion();
                };
                scope.hideAccordion = () => {
                    scope.accordionDisplay = false;
                };
                scope.hideFAQ = () => {
                    scope.FAQDisplay = false;
                };
                scope.showFnolInfo = () => {
                    return $rootScope.policyContext.isInFnolWizard;
                };
                scope.showImportantInfoSection = () => {
                    return scope.showFnolInfo();
                };
            }
        };
    }
];
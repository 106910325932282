import templateStr from 'text!./top-up-miles-payment.html';

export default ['$sce', 'ScrollService_AND', ($sce, ScrollService_AND) => {
    /**
     * Escapes <code>url</code>.
     * https://docs.angularjs.org/api/ng/service/$sce
     * @param {String} url URL to escape
     * @returns {*} Wrapped URL
     */
    function trustSrc(url) {
        return $sce.trustAsResourceUrl(url);
    }

    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            data: '<'
        },
        link: ($scope) => {
            $scope.gatewayURL = trustSrc($scope.data.sagePayURL);
            $scope.scrollToTop = () => ScrollService_AND.scrollToTop();

            window.ackPaymentSuccess_AND = function () {
                $scope.data.goToNextScreen();
            };

            window.ackPaymentFailure_AND = function () {
                // This call does not go through any AngularJs code so needs an explicit digest.
                $scope.$apply(() => $scope.data.goToPaymentFailedScreen());
            };
        }
    };
}];
import FlowUtil from 'edge/platform/flow/FlowUtil';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import commonFlowDefnFactory from 'edge/gw-portals-policytransaction-common-angular/config/FlowConfig';
import pcBopFlowConfig from 'conditional?if=endorsement-products&equals=BusinessOwners!edge/gw-portals-policytransaction-pc-bop-angular/config/FlowConfig';
import pcCpFlowConfig from 'conditional?if=endorsement-products&equals=CommercialProperty!edge/gw-portals-policytransaction-pc-cp-angular/config/FlowConfig';

export default function ($q, EndorsementService, AvailabilityService, TranslateService, ViewModelService, UserInfoService) {

    const POLICY_CHANGE = 'PolicyChange';
    const commonFlowDefn = commonFlowDefnFactory($q, EndorsementService);

    return function (step, junction, flow) {
        let pcBopFlow;
        let pcCpFlow;
        const commonFlowDefinition = flow(commonFlowDefn);

        // entry step into transaction flow
        const defineEntryNode = FlowUtil.isStepObject(commonFlowDefinition.entry) ? step : junction;
        defineEntryNode(commonFlowDefinition.entry, true);

        if (ApplicationFeatures.hasFeature('endorsement-products.BusinessOwners')) {
            const flowDefinition = pcBopFlowConfig($q, EndorsementService, AvailabilityService, TranslateService, ViewModelService, UserInfoService);
            pcBopFlow = flow(flowDefinition);
        }
        if (ApplicationFeatures.hasFeature('endorsement-products.CommercialProperty')) {
            const flowDefinition = pcCpFlowConfig($q, EndorsementService, AvailabilityService, TranslateService, ViewModelService, UserInfoService);
            pcCpFlow = flow(flowDefinition);
        }

        junction('Fin').onNext.branch((model) => {
            const jobType = model.value.baseData.jobType;

            if (pcBopFlow && jobType === POLICY_CHANGE && model.baseData.productCode.value === 'BusinessOwners') {
                return pcBopFlow.entry;
            }
            if (pcCpFlow && jobType === POLICY_CHANGE && model.baseData.productCode.value === 'CommercialProperty') {
                return pcCpFlow.entry;
            }
            return 'transactionError';
        });
    };
}

import templateStr from 'text!./templates/claim-payment-details.html';

export default [() => {
    return {
        template: templateStr,
        scope: {
            payments: '='
        },
        controller: ['$scope', 'InsuranceSuiteMetadata', ($scope, InsuranceSuiteMetadata) => {
            const transactionStatusTypeList = InsuranceSuiteMetadata.get('cc').types.getTypelist('TransactionStatus');

            $scope.getStatusLabel = (code) => {
                const typeKey = transactionStatusTypeList.getCode(code);
                return typeKey ? typeKey.name : null;
            };
        }]
    };
}];

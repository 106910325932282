/** Icons for policy types. */
const policyTypeIcons = {
    'BusinessOwners': 'business-owners',
    'BusinessAuto': 'commercial-auto',
    'CommercialProperty': 'commercial-property',
    'GeneralLiability': 'general-liability',
    'HomeOwners': 'home-owners',
    'Homeowners': 'home-owners',
    'InlandMarine': 'inland-marine',
    'PersonalAuto': 'personal-auto',
    'travel_per': 'travel',
    'PersonalUmbrella': 'umbrella',
    'WorkersCompLine': 'workers-comp',
    'WorkersComp': 'workers-comp',
    'CommercialPackage': '<unknown>',
    'farmowners': '<unknown>',
    'prof_liability': '<unknown>'
};

/**
 * Get a policy type icon by a policy type.
 * @param {String} [policyType] policy type code.
 * @returns {String} policy type icon name.
 */
function getPolicyTypeIconName(policyType) {
    if (policyType && policyTypeIcons.hasOwnProperty(policyType)) {
        return policyTypeIcons[policyType];
    }
    return '<unknown>';
}

/**
 * Get a policy icon or a policy type.
 * @param {String} policyType policy type.
 * @returns {String} policy type icon path.
 */
function getPolicyTypeIcon(policyType) {
    return `../styles/images/common/lob-icons/${getPolicyTypeIconName(policyType)}.png`;
}

export default {
    /**
     * Returns a LOB icon path.
     * @param {string} line of business code.
     * @returns {string} lob icon path.
     */
    'lobIconPath': getPolicyTypeIcon
};

import Template from 'text!edge/policy/rewards-shop_AND/views/rewards-shop.html';

export default (RootStates) => {
    const parentState = RootStates.rewardsShop_AND;

    return {
        rewardsShop_AND: {
            name: 'rewardsShop_AND',
            parent: parentState,
            url: '/rewards-shop',
            template: Template,
            data: {
                restrictedToEditablePolicies: true,
                summaryAccessControl: 'canAccessDGTRewards_AND' // Access controlled by PolicyPeriodSummaryDTO flag
            }
        }
    };
};

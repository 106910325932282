import templateStr from 'text!./bonus-miles.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            policyNumber: '<',
            hideTitle: '<'
        },
        controller: ['$scope', 'TelematicsService_AND', 'brandingData', ($scope, TelematicsService_AND, brandingData) => {
            $scope.brand = brandingData;
            
            const setScores = (policyNumber, monthlyAddedMiles) => {
                TelematicsService_AND.getScores(policyNumber, monthlyAddedMiles.id).then((data) => {
                    $scope.page.scores = data;
                });
            };

            $scope.page = {};
            $scope.page.selectMonth = (monthlyAddedMiles) => {
                $scope.page.selectedMonth = monthlyAddedMiles;
                setScores($scope.policyNumber, monthlyAddedMiles);
            };

            $scope.$watch('policyNumber', (newValue) => {
                if (!newValue) {
                    return;
                }
                $scope.page.policyNumber = newValue;
                TelematicsService_AND.getBonusMiles(newValue).then((data) => {
                    $scope.page.bonusMiles = data;
                    if (!!data.yearlyAddedMiles && data.yearlyAddedMiles.length > 0) {
                        $scope.page.selectMonth(data.yearlyAddedMiles[0].monthlyAddedMiles[0]);
                    }
                });
            });
        }]
    };
}];

import _ from 'underscore';
import BonusMilesConfig from 'edge/policy/bonus-miles_AND/config/RouteConfig';

const appModule = angular.module('edge.and.bonus-miles.app', [])

    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new BonusMilesConfig(RootStateConfig);
        _.values(states).forEach(state => {
            $stateProvider.state(state);
        });
    }]);

export default appModule;

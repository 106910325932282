import PolicyChange from 'edge/endorsement/common/models/PolicyChange';
import compositeFlowConfig from 'integration/endorsement/wizard/config/CompositeFlowConfig';
import TransactionEntryCtrl from 'gw-portals-policytransaction-common-angular/transactionEntry/TransactionEntryCtrl';

class EndorsementEntryCtrl extends TransactionEntryCtrl {

    static get $inject() {
        return ['$scope', '$stateParams', 'endorsement.EndorsementService', '$q', 'ViewModelService', 'StateUtils', 'AvailabilityService', 'WizardFlowService', 'TranslateService', 'UserInfoService'];
    }

    constructor($scope, $stateParams, EndorsementService, $q, ViewModelService, StateUtils, AvailabilityService, wizardFlowService, TranslateService, UserInfoService) {
        super($scope, wizardFlowService, StateUtils);

        this.$stateParams = $stateParams;
        this.$q = $q;
        this.EndorsementService = EndorsementService;
        this.AvailabilityService = AvailabilityService;
        this.TranslateService = TranslateService;
        this.ViewModelService = ViewModelService;
        this.UserInfoService = UserInfoService;
        this.wizardFlowService = wizardFlowService;

        this.startWizardFlow();
    }

    getCompositeFlow() {
        return compositeFlowConfig(this.$q, this.EndorsementService, this.AvailabilityService, this.TranslateService, this.ViewModelService, this.UserInfoService);
    }

    buildModel() {
        const createViewModel = (modelDTO) => this.ViewModelService.create(new PolicyChange(modelDTO), 'pc', 'edge.capabilities.policychange.dto.PolicyChangeDataDTO');

        return this.EndorsementService.loadEndorsement(this.$stateParams.selectedPolicyNumber)
            .then(modelDTO => {
                const resume = modelDTO.jobID != null;

                if (resume) {
                    const model = createViewModel(modelDTO);
                    return {resume, model};
                }

                return this.EndorsementService.saveEndorsement(new PolicyChange(modelDTO)).then((modelSaved) => {
                    const model = createViewModel(modelSaved);
                    return {resume, model};
                });
            });
    }

    static returnToDetailsPage(jobID) {
        return ['endorsements.detail.summary', {policyChangeNumber: jobID}];
    }

}

export default EndorsementEntryCtrl;
import _ from 'underscore';
import StatesConfig from 'edge/policy/top-up-miles_AND/config/RouteConfig';
import TopUpMilesService from './services/TopUpMilesService';
import SelectMileOption from './directives/select-mile-option/SelectMileOption';
import FillCardDetails from './directives/fill-card-details/FillCardDetails';
import TopUpMilesNotes from './directives/top-up-miles-notes/TopUpMilesNotes';
import RegulatoryStatement from './directives/regulatory-statement/RegulatoryStatement.Js';
import TopUpMilesPayment from './directives/top-up-miles-payment/TopUpMilesPayment';
import Confirmation from './directives/confirmation/Confirmation';
import PaymentFailed from './directives/payment-failed/PaymentFailed';

const appModule = angular.module('edge.and.top-up-miles.app', [])
    .directive('andSelectMileOption', SelectMileOption)
    .directive('andFillCardDetails', FillCardDetails)
    .directive('andTopUpMilesNotes', TopUpMilesNotes)
    .directive('andRegulatoryStatement', RegulatoryStatement)
    .directive('andTopUpMilesPayment', TopUpMilesPayment)
    .directive('andTopUpMilesConfirmation', Confirmation)
    .directive('andTopUpMilesPaymentFailed', PaymentFailed)

    .factory('TopUpMilesService', TopUpMilesService)
    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new StatesConfig(RootStateConfig);
        _.values(states).forEach(state => {
            $stateProvider.state(state);
        });
    }]);

export default appModule;

import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./MapTypeControl.html';
import styles from './MapTypeControl-hashed.scss';
import controller from './MapTypeControlCtrl';

export default {
    template: cssUtil.hashTemplate(template, styles),
    bindings: {
        mapInstance: '<',
        mapLoaded: '<'
    },
    controller
};

export default ['JsonRPCService', (JsonRPCService) => {
    return {
        getClaimSummaries: (query, offset) => {
            return JsonRPCService.send('cc', 'claim/claim', 'getClaimSummaries', [query, offset]);
        },

        getClaimDetail: (claimNumber) => {
            return JsonRPCService.send('cc', 'claim/claim', 'getClaimDetail', [claimNumber]);
        },

        getClaimPolicyDetail: (claimNumber) => {
            return JsonRPCService.send('cc', 'claim/claim', 'getClaimPolicyDetail', [claimNumber]);
        },

        getClaimActivities: (claimNumber) => {
            return JsonRPCService.send('cc', 'claim/claim', 'getClaimActivities', [claimNumber]);
        },

        updateClaimNote: (claimId, note) => {
            return JsonRPCService.send('cc', 'claim/claim', 'updateClaimNote', [claimId, note]);
        },

        deleteClaimNote: (claimId, noteId) => {
            return JsonRPCService.send('cc', 'claim/claim', 'deleteClaimNote', [claimId, noteId]);
        },

        createClaimNote: (claimNumber, noteDTO) => {
            return JsonRPCService.send('cc', 'claim/claim', 'createClaimNote', [claimNumber, noteDTO]);
        },

        getClaimNotes: (claimNumber) => {
            return JsonRPCService.send('cc', 'claim/claim', 'getClaimNotes', [claimNumber]);
        }
    };
}];
import has from 'has';
import 'edge/endorsement/common/app';
import 'conditional?if=endorsement-products&equals=Homeowners!edge/endorsement/ho/app';
import 'conditional?if=endorsement-products&equals=PersonalAuto!edge/endorsement/pa/app';
import 'conditional?if=endorsement-products&equals=PersonalMotor!edge/endorsement/pm/app'; // ANDIE
import 'edge/platform/widgets/time/module';

const lobModules = ['edge.endorsement.common.app', 'edge.platform.widgets.ui.time'];

if (has('endorsement-products.Homeowners')) {
    lobModules.push('edge.endorsement.ho.app');
}
if (has('endorsement-products.PersonalAuto')) {
    lobModules.push('edge.endorsement.pa.app');
}
if (has('endorsement-products.PersonalMotor')) {
    lobModules.push('edge.endorsement.pm.app');
}

export default lobModules;
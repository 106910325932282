import _ from 'underscore';
import templateStr from 'text!./templates/invoice-payment-form.html';
import InvoicePaymentAmount from '../model/_InvoicePaymentAmount';

/**
 * Checks if invoice is selected.
 * @param {Object} invoice
 * @returns {*}
 */
function isSelected(invoice) {
    return invoice.selected;
}

/**
 * Returns amount of selected invoices.
 * @param {Object} invoices
 * @returns {Number}
 */
function selectedInvoiceAmount(invoices) {
    const origAmount = invoices
        .filter(isSelected)
        .map((invoice) => {
            return invoice.amountDue.amount;
        })
        .reduce((a, b) => {
            return a + b;
        }, 0);
    return Math.round(origAmount * 100) / 100;
}

/**
 * Simple form for the invoice payment.
 * @returns {Object}
 */
export default () => {
    return {
        restrict: 'A',
        template: templateStr,

        scope: {
            /* List of invoices to select from. */
            invoices: '=',
            /* Payment function. Receives array of invoice IDs, payment amount and payment instrument description. */
            pay: '=',
            /* No-arg function to invoke when user cancels a form. */
            cancel: '='
        },

        controller: ['$scope', 'ViewModelService', 'TranslateService', 'integration.PaymentInstrumentService', ($scope, ViewModelService, TranslateService, PaymentInstrumentService) => {
            $scope.amount = InvoicePaymentAmount.create(
                ViewModelService, TranslateService,
                $scope.invoices[0].amountDue.currency, () => {
                    return selectedInvoiceAmount($scope.invoices);
                }
            );
            $scope.paymentInstrument = PaymentInstrumentService.createInvoicePaymentInstrument();

            $scope.getPaymentInstrumentSummary = () => {
                return PaymentInstrumentService.getInstrumentSummary($scope.paymentInstrument);
            };

            $scope.numberOfSelectedInvoices = () => {
                return $scope.invoices.filter(isSelected).length;
            };

            $scope.payNow = () => {
                $scope.invoiceForm.submitted = true;

                const invoiceIds = _.pluck($scope.invoices.filter(isSelected), 'id');
                const instrument = PaymentInstrumentService.toDto($scope.paymentInstrument);

                if ($scope.invoiceForm.invalid || !$scope.amount.valid || !instrument || invoiceIds.length === 0) {
                    return;
                }

                $scope.pay(invoiceIds, $scope.amount.effectiveAmount.amount, instrument);
            };
        }]
    };
};
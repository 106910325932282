import SegmentationOptionDirective from './SegmentationOptionDirective';
import './queryparams/module';
import './campaign/module';
import './branding/module';
import './abexperiment/module';
import './location/module';
import './demolandingpage/module';
import './product/module'
import TranslateBrandFilter_AND from 'edge/platform/segmentation/branding/TranslateBrandFilter_AND'; // ANDIE

export default angular.module('edge.platform.segmentation', [
    'edge.platform.segmentation.queryparams',
    'edge.platform.segmentation.branding',
    'edge.platform.segmentation.campaign',
    'edge.platform.segmentation.abexperiment',
    'edge.platform.segmentation.location',
    'edge.platform.segmentation.product'
    // ANDIE This module causes the quoteentry state to load twice when navigating to it directly on app load, causing
    // all sorts of indirect issues, one being the wizard steps stop listening to state changes.
    // If this functionality is required that issue has to be resolved.
    // 'edge.platform.segmentation.demolandingpage'
])
    .directive('gwSegmentationOption', SegmentationOptionDirective)
    .filter('andBrand', TranslateBrandFilter_AND); // ANDIE
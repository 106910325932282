import templateStr from 'text!./templates/pa-vehicle-coverages.html';

export default [() => {
    return {
        template: templateStr,
        scope: {
            vehicles: '=',
            headLabel: '@headLabel'
        },
        link: (scope) => {
            let selectedVehicle;

            scope.$watch('vehicles', (vehicles) => {
                if (vehicles && vehicles.length > 0) {
                    selectedVehicle = vehicles[0];
                }
            });

            scope.headLabel = scope.headLabel || {};

            scope.selectedVehicleName = () => {
                if (selectedVehicle) {
                    return `${selectedVehicle.make} ${selectedVehicle.model}`;
                }
            };

            scope.setSelectedVehicle = (vehicle) => {
                selectedVehicle = vehicle;
            };

            scope.selectedVehicleClass = (item) => {
                return selectedVehicle === item ? 'gw-light-blue' : null;
            };

            scope.selectedVehicleCoverages = () => {
                if (selectedVehicle && selectedVehicle.coverages) {
                    return selectedVehicle.coverages;
                }
            };
        }
    };
}];

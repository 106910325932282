import templateStr from 'text!./pm-payment-contact-details.html';
import moment from 'moment';

export default ['StateUtils', (StateUtils) => {
    return {
        restrict: 'E',
        scope: {
            bindData: '=',
            baseData: '=',
            submission: '=',
            lobData: '='
        },
        template: templateStr,
        controller: ['$scope', '$filter', '$q', 'StaticDocumentsService_AND', '$rootScope', ($scope, $filter, $q, StaticDocumentsService_AND, $rootScope) => {
            $scope.contactPhone = {
                value: $scope.bindData.contactPhone.value ? $scope.bindData.contactPhone.value : null,
                aspects: {
                    required: true,
                    inputCtrlType: 'text',
                    get valid() {
                        return angular.isDefined($scope.contactPhone.value) && $scope.contactPhone.value !== null
                            && $scope.bindData.contactPhone.aspects.valid;
                    },
                    get validationMessages() {
                        if (this.valid === false) {
                            return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                        }
                        return [];
                    }
                }
            };
            $scope.policyEffDateBeforeAndUk = $scope.submission.value.policyEffDateBeforeAndUk();
            $q.all([
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_POLICY, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_NOTICE, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.TERMS_AND_CONDITIONS, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.CORE_IPID, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVATE_CAR_POLICY_DOCUMENT, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.COOKIE_POLICY, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.IMPORTANT_INFORMATION, $scope.baseData.periodStartDate.value),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.KEYS_IPID,$scope.baseData.periodStartDate.value)
            ])
                .then((result) => {
                    $scope.privacyPolicyDocUrl = result[0];
                    $scope.privcyNoticeDocUrl = result[1];
                    $scope.termsDocUrl = result[2];
                    $scope.coreIpidDocUrl = result[3];
                    $scope.policyDocUrl = result[4];
                    $scope.cookiePolicyDocUrl = result[5];
                    $scope.importantInformationDocUrl = result[6];
                    $scope.keysIPID_URL = result[7];
                });

            $scope.coverageSelection = StateUtils.activeDataForState(true).coverageSelection;
            $scope.declarationOptions = [
                {
                    id: 'DeclarationAccept',
                    value: true,
                    label: 'and.quoteandbind.pm.views.paymentstep.1.Accept'
                },
                {
                    id: 'DeclarationDecline',
                    value: false,
                    label: 'and.quoteandbind.pm.views.paymentstep.1.Decline'
                }
            ];
            if ($scope.submission.baseData.productCode.value === 'TraditionalMotor' || $scope.submission.baseData.productCode.value === 'CommercialVehicle') {
                $scope.bindData.boxDeclaration_itb = true;
            } else if ($scope.submission.baseData.productCode.value === 'PersonalMotor' && !$scope.lobData.personalMotor.coverables.pmVehicles.value[0].hasChanged_itb) {
                $scope.bindData.boxDeclaration_itb = true;
            }
        }]
    };
}];
import ProductCodeUtil from '../util/ProductCodeUtil';

export default ['$rootScope', '$scope', '$state', '$q', 'StaticDocumentsService_AND', 'brandingData', 'UserProfileService_AND', 'ModalService', 'policy.PolicyService',
    ($rootScope, $scope, $state, $q, StaticDocumentsService_AND, brandingData, UserProfileService_AND, ModalService, PolicyService) => {
    $scope.hasRenewal = () => $rootScope.policyContext.currentPolicy &&
        $rootScope.policyContext.currentPolicy.periods.length > 1;
    const selectPolicy = (policyNumber) => {
       $q.when(PolicyService.getAccountPolicyDetails(policyNumber), (policyData) => {
          $scope.accepted = () => {
              return policyData.renewedPeriod.status === 'Scheduled';
          };
       });
    }


    $q.all([
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_POLICY),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_NOTICE)
    ]).then((result) => {
        $scope.privacyPolicyDoc = result[0];
        $scope.privacyNoticeDoc = result[1];
    });

    $scope.$watch(() => $rootScope.policyContext.currentPolicyNumber, (newValue) => {
       if (newValue) {
           selectPolicy(newValue);
       }
    });

    $scope.brand = brandingData.toLowerCase();
    $rootScope.productCode = ProductCodeUtil.getProductCode(brandingData)

    const defaultshowMarketingMessage = {
        title: 'Platform.flow.angularhelpers.showMarketing.Action Required',
        message: 'Platform.flow.angularhelpers.showMarketing.In order..',
    };
    const messageKeys = defaultshowMarketingMessage;
        const getAccountInfo = () => {
            UserProfileService_AND.getAccountInfo().then((data) => {
                if (data.marketingPrefOptOut_itb === undefined) {
                    ModalService.showMarketing(messageKeys.title, messageKeys.message, null, 'Continue', 'gw-btn-primary').result.then(() => {
                        $state.go('userAccount_AND');
                    });
                }
            });
        };
        getAccountInfo();

}];

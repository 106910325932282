import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import commonSubFlowDefnFactories from 'edge/quoteandbind/common/config/FlowConfig';
import paFlowDefnFactory from 'conditional?if=quoteandbind_lob&equals=pa!edge/quoteandbind/pa/config/FlowConfig';
import hoFlowDefnFactory from 'conditional?if=quoteandbind_lob&equals=ho!edge/quoteandbind/ho/config/FlowConfig';
import cpFlowDefnFactory from 'conditional?if=quoteandbind_lob&equals=cp!edge/quoteandbind/cp/config/FlowConfig';
import bopFlowDefnFactory from 'conditional?if=quoteandbind_lob&equals=bop!edge/quoteandbind/bop/config/FlowConfig';
import pmFlowDefnFactory from 'conditional?if=quoteandbind_lob&equals=pm!edge/quoteandbind/pm/config/FlowConfig';

export default function (flow, AvailabilityService, RenewalService_AND, $q, TranslateService, ViewModelService, ViewModelCtx, ActionMessageUtil, EventHub, $rootScope) {

    /**
     * Convert object containing flow definitions into object containing sub flows
     * @param {Object} subFlowDefns containing flow definitions
     * @returns {Object}
     */
    function createSubFlowsFromFlowDefns(subFlowDefns) {
        const subFlowsContainer = {};
        Object.keys(subFlowDefns).reduce((container, flowDefnKey) => {
            container[flowDefnKey] = flow(subFlowDefns[flowDefnKey]);
            return container;
        }, subFlowsContainer);
        return subFlowsContainer;
    }

    const commonSubFlowDefns = commonSubFlowDefnFactories(AvailabilityService, RenewalService_AND, $q, TranslateService, ViewModelService, ViewModelCtx, ActionMessageUtil, EventHub);

    const globalExits = flow(commonSubFlowDefns.globalExits);
    const availabilityFlow = flow(commonSubFlowDefns.availabilityCheck);
    const needToSaveCheckFlow = flow(commonSubFlowDefns.needToSaveCheck);
    const submissionExistsFlow = flow(commonSubFlowDefns.ensureSubmission);
    let paFlows;
    let hoFlows;
    let cpFlows;
    let bopFlows;
    let pmFlows; // ANDIE
    // if the personal auto feature is enabled then create the PA flow
    if (ApplicationFeatures.hasFeature('quoteandbind_lob.pa')) {
        const paFlowDefns = paFlowDefnFactory(AvailabilityService, RenewalService_AND, $q, TranslateService, ViewModelService, ViewModelCtx, EventHub);
        paFlows = createSubFlowsFromFlowDefns(paFlowDefns);
    }
    // if the homeowners feature is enabled then create the HO flow
    if (ApplicationFeatures.hasFeature('quoteandbind_lob.ho')) {
        const hoFlowDefns = hoFlowDefnFactory(AvailabilityService, RenewalService_AND, $q, TranslateService, ViewModelService, ViewModelCtx);
        hoFlows = createSubFlowsFromFlowDefns(hoFlowDefns);
    }
    // if the commercial property feature is enabled then create the CP flow
    if (ApplicationFeatures.hasFeature('quoteandbind_lob.cp')) {
        const cpFlowDefns = cpFlowDefnFactory(AvailabilityService, RenewalService_AND, $q, TranslateService, ViewModelService, ViewModelCtx, EventHub);
        cpFlows = createSubFlowsFromFlowDefns(cpFlowDefns);
    }
    // if the BOP feature is enabled then create the BOP flow
    if (ApplicationFeatures.hasFeature('quoteandbind_lob.bop')) {
        const bopFlowDefns = bopFlowDefnFactory(AvailabilityService, RenewalService_AND, $q, TranslateService, ViewModelService, ViewModelCtx, EventHub);
        bopFlows = createSubFlowsFromFlowDefns(bopFlowDefns);
    }
    // ANDIE
    if (ApplicationFeatures.hasFeature('quoteandbind_lob.pm')) {
        const pmFlowDefns = pmFlowDefnFactory(AvailabilityService, RenewalService_AND, $q, TranslateService, ViewModelService, ViewModelCtx, ActionMessageUtil, EventHub, $rootScope);
        pmFlows = createSubFlowsFromFlowDefns(pmFlowDefns);
    }
    const quoteFlow = flow(commonSubFlowDefns.quote);
    const bindFlow = flow(commonSubFlowDefns.bindAndPay);

    return {
        exit: globalExits,
        availability: availabilityFlow,
        needToSaveCheck: needToSaveCheckFlow,
        submissionExists: submissionExistsFlow,
        paFlows,
        hoFlows,
        cpFlows,
        bopFlows,
        pmFlows,
        quote: quoteFlow,
        bind: bindFlow
    };
}

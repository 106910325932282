import templateStr from 'text!./pm-marketing-preferences.html';

export default [() => {
    return {
        restrict: 'A',
        scope: {
            contact: '=',
        },
        template: templateStr
    };
}];
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import Address from 'edge/fnol/common/models/Address';

const fn = ($scope, claimViewModel, TranslateService, ViewModelService) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        // create separate vm for this page as we want to add the external context to enforce
        // that at least one contact number is set via setting an external context.
        const claimVm = claimViewModel();
        $scope.claimSubmitCtxModel = ViewModelService.create(
            claimVm.value,
            'cc', 'edge.capabilities.claim.fnol.dto.FnolDTO', {
                'SubmittingClaim': true
            }
        );
    };

    ctrl.defineWatches = () => {
        $scope.$watch('claimSubmitCtxModel.mainContact.value', (newValue, oldValue) => {
            if (newValue !== oldValue && newValue && newValue.placeholder) {
                $scope.claimSubmitCtxModel.mainContact.value = $scope.claimSubmitCtxModel.value.createContact();
                $scope.claimSubmitCtxModel.mainContact.primaryPhoneType.value = 'work';
                $scope.claimSubmitCtxModel.mainContact.primaryAddress.value = new Address();
            }
            // check that we haven't left a contact invalid with an empty address
            const previouslySelectedContact = $scope.claimSubmitCtxModel.contacts.findFirstElement(oldValue);
            if (previouslySelectedContact && !previouslySelectedContact.aspects.subtreeValid) {
                previouslySelectedContact.primaryAddress.value = new Address();
            }
        });
    };

    ctrl.refreshContactForm = (form) => {
        /*  Set pristine on change of contact. In the DOM the form is the same but from the users perspective,
         *  it is a new form and should not start with validation messages.
         */
        form.setPristine();
        form.$setUntouched();
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'claimViewModel', 'TranslateService', 'ViewModelService'];

export default fn;

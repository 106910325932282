import appFeatures from 'effective-features!';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

const applicationFeatures = ApplicationFeatures.getAllFeatures();
export default ['JsonRPCService', (JsonRPCService) => {
    return {
        addEnrollmentRecord: (enrollmentInfo) => {
            const xCenterDestination = applicationFeatures['self-enrollment'].xCenterToUseForValidation;
            return JsonRPCService.send(xCenterDestination, 'enrollment/selfenrollment', 'addEnrollmentRecord', [enrollmentInfo]);
        },
        isMultiaccount: () => {
            const checkForEnrollment = appFeatures['self-enrollment'];
            if (!checkForEnrollment || !checkForEnrollment.multiaccountEnrollment) {
                return false;
            }
            return checkForEnrollment.multiaccountEnrollment;
        }
    };
}];

import makePaymentConfirmationTemplate from 'text!../views/make-payment-confirmation.html';
import MakePaymentConfirmationCtrl from 'integration/billing/bc3/controllers/MakePaymentConfirmationCtrl';

export default (AppConfig) => {
    const parentState = AppConfig.billing;
    const homeState = AppConfig.home;
    return {
        'billing-make-payment-confirmation': {
            name: 'billing-make-payment-confirmation',
            url: '/make-payment-confirmation',
            parent: parentState,
            controller: MakePaymentConfirmationCtrl,
            template: makePaymentConfirmationTemplate,
            data: {
                actions: {
                    cancel: homeState
                }
            }
        },
        'gateway-billing-make-payment-confirmation': {
            name: 'gateway-billing-make-payment-confirmation',
            url: '/make-payment-confirmation/{accountNumber}',
            parent: parentState,
            controller: MakePaymentConfirmationCtrl,
            template: makePaymentConfirmationTemplate,
            data: {
                actions: {
                    cancel: 'accounts.detail.summary'
                }
            }
        }
    };
};
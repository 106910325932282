import _ from 'underscore';
import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, claimViewModel) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        const claimVm = claimViewModel();
        $scope.fnol.collisionTypeCodes = _.filter(claimVm.lossCause.aspects.availableValues, (typeCode) => {
            // for purposes of portal 'otherobjcoll' is not a collision code
            const nonCollisionAutoCodes = ['theftentire', 'glassbreakage', 'theftparts', 'otherobjcoll'];
            return nonCollisionAutoCodes.indexOf(typeCode.code) === -1;
        });
        $scope.policyContext.isInFnolWizard = true;
    };

    ctrl.isCollision = (lossCause) => {
        return _.findWhere($scope.fnol.collisionTypeCodes, {
            value: lossCause
        });
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'claimViewModel'];

export default fn;
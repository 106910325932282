import template from 'text!./policy-has-expired-banner.html';

export default [() => ({
    restrict: 'A',
    template: template,
    scope: {},
    controller: ['$rootScope', '$scope', ($rootScope, $scope) => {

        $scope.displayPolicyHasExpiredBanner = () => $rootScope.policyContext.policyHasExpired();
    }]
})];
import templateStr from 'text!./cookie-settings.html';
angular.module('cookiesExample', ['ngCookies'])

export default ['$filter','$cookies','brandingData',
    ($filter, $cookies) => {
        const API_ERROR = $filter('translate')('and.directives.change-phone-numbers.APIError');

        return {
            restrict: 'A',
            template: templateStr,
            scope: {},
            controller: ['$scope','brandingData', '$timeout','StaticDocumentsService_AND', '$q', ($scope, brandingData, $timeout,StaticDocumentsService_AND, $q,) => {

                // VARS

                $scope.brand = brandingData;
                $scope.isSuccessVisible = false
                $scope.functionCookies = $cookies.get($scope.brand+'-PRIVACY-FUNCTIONCOOKIES')
                $scope.analyticsCookies = $cookies.get($scope.brand+'-PRIVACY-ANALYTICSCOOKIES')
                $scope.advertCookies = $cookies.get($scope.brand+-'PRIVACYADVERTCOOKIES')
                console.log($scope.functionCookies)

                if ($scope.functionCookies == 1){
                  $timeout(() => { 
                    document.getElementById('functionSwitch').checked = true
                }, 1000);
                }
                if ($cookies.analyticsCookies == 1){
                  $timeout(() => {
                    document.getElementById('analyticsSwitch').checked = true
                }, 1000);
                }
                if ($scope.advertCookies == 1){
                  $timeout(() => { 
                    document.getElementById('advertSwitch').checked = true
                }, 1000);
                }

                $q.all([
                  StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.COOKIE_POLICY),
                ]).then((result) => {
                  $scope.cookieDoc = result[0];
                });

                $scope.dropCookies = function () {
                    document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                    $cookies.put($scope.brand+'-PRIVACY-STRICTCOOKIES', 0);
                    $cookies.put($scope.brand+'-PRIVACY-FUNCTIONCOOKIES', 0);
                    $cookies.put($scope.brand+'-PRIVACY-ANALYTICSCOOKIES', 0);
                    $cookies.put($scope.brand+'-PRIVACY-ADVERTCOOKIES', 0);
                }

                $scope.save = function () {
                    $scope.strictCookies = document.getElementById('strictlySwitch').checked
                    $scope.function = document.getElementById('functionSwitch').checked
                    $scope.analytics = document.getElementById('analyticsSwitch').checked
                    $scope.advert = document.getElementById('advertSwitch').checked
                    
    
                    if ($scope.strictCookies === true){
                      $cookies.put($scope.brand+'-PRIVACY-STRICTCOOKIES', 1);
                    } else {
                      $cookies.put($scope.brand+'-PRIVACY-STRICTCOOKIES', 0);
                    }
    
                    if ($scope.function === true){
                      $cookies.put($scope.brand+'-PRIVACY-FUNCTIONCOOKIES', 1);
                    } else {
                      $cookies.put($scope.brand+'-PRIVACY-FUNCTIONCOOKIES', 0);
                    }
    
                    if ($scope.analytics === true){
                      $cookies.put($scope.brand+'-PRIVACY-ANALYTICSCOOKIES', 1);
                    } else {
                      $cookies.put($scope.brand+'-PRIVACY-ANALYTICSCOOKIES', 0);
                    }
    
                    if ($scope.advert === true){
                      $cookies.put($scope.brand+'-PRIVACY-ADVERTCOOKIES', 1);
                    } else {
                      $cookies.put($scope.brand+'-PRIVACY-ADVERTCOOKIES', 0);
                    }
    
                    if ($scope.strictCookies === true && $scope.function === true && $scope.analytics === true && $scope.advert === true){
                      $cookies.put('consent', true);
                    }
                    if ($scope.strictCookies === false && $scope.function === false && $scope.analytics === false && $scope.advert === false){
                        $scope.dropCookies()
                      }
                    
                    if ($scope.strictCookies === true || $scope.function === true || $scope.analytics === true || $scope.advert === true){
                        $cookies.put('consent', true)
                    }
                    $scope.isSuccessVisible = true;
                   
                  }

                  $scope.clearSuccessMsg = () => {
                      $scope.isSuccessVisible = false;
                  }

            }]
        };
    }];
import _ from 'underscore';
import templateStr from 'text!./templates/activity-summary.html';

export default ['$filter', ($filter) => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {},
        controller: ['$scope', 'claim.ActivityService', 'InsuranceSuiteMetadata', ($scope, ActivityService, InsuranceSuiteMetadata) => {
            const activityStatusTypeList = InsuranceSuiteMetadata.get('cc').types.getTypelist('ActivityStatus');
            const priorityTypeList = InsuranceSuiteMetadata.get('cc').types.getTypelist('Priority');
            $scope.$filter = $filter; // for l10n
            $scope.activeAct = [];
            $scope.completeActs = [];
            $scope.combinedAct = [];

            let watchesApplied = false;

            $scope.actStatusMap = {
                'PersonalAutoLine': 'Personal Auto',
                'AUTO': 'Personal Auto',
                'HomeownersLine_HOE': 'Homeowners',
                'PR': 'Homeowners'
            };

            $scope.tableConfig = {
                paginationSize: 1000,
                defaultActStatus: 'Open Activities',
                actStatus: 'Open Activities',
                actStatusOptions: ['Open Activities', 'All Activities']
            };

            $scope.completedFetched = false;
            $scope.activityQuery = '';
            $scope.failToGetActivities = false;
            $scope.processedActivities = [];

            $scope.resolvePromise = (actStates) => {
                if (($scope.tableConfig.actStatus === 'All Activities') && ($scope.completedFetched === false)) {
                    $scope.completedFetched = true;
                }

                const actPromise = ActivityService.getActivitySummaries({statuses: actStates});
                $scope.actPromise = actPromise;

                actPromise.then(
                    (actSummaries) => {
                        if ($scope.tableConfig.actStatus === 'Open Activities') {
                            $scope.activeAct = actSummaries;
                            $scope.combinedAct = actSummaries;
                        }
                        if ($scope.tableConfig.actStatus === 'All Activities') {
                            $scope.completeActs = actSummaries;
                            for (let completeActNo = 0; completeActNo < $scope.completeActs.length; completeActNo++) {
                                $scope.combinedAct.push($scope.completeActs[completeActNo]);
                            }
                        }
                        // we apply the filter to the fetched activities
                        $scope.applySearchFilter();
                        if (watchesApplied === false) {
                            $scope.applyWatches();
                        }
                    },
                    () => {
                        $scope.failToGetActivities = true;
                    }
                );
            };

            $scope.resolvePromise(['open']);

            $scope.getStatusLabel = (code) => {
                const typeKey = activityStatusTypeList.getCode(code);
                return typeKey ? typeKey.name : null;
            };

            $scope.getPriorityLabel = (code) => {
                const typeKey = priorityTypeList.getCode(code);
                return typeKey ? typeKey.name : null;
            };

            $scope.applyWatches = () => {
                $scope.$watch('activityQuery', () => {
                    $scope.applySearchFilter();
                });
                $scope.$watch('tableConfig.actStatus', () => {
                    if ($scope.tableConfig.actStatus === 'All Activities' && $scope.completedFetched === false) {
                        $scope.resolvePromise(['canceled', 'complete', 'skipped']);
                    } else {
                        $scope.applySearchFilter();
                    }
                });
                watchesApplied = true;
            };

            $scope.applySearchFilter = () => {
                if ($scope.combinedAct.length > 0 && $scope.tableConfig.actStatus) {
                    $scope.processedActivities = _.filter($scope.combinedAct, (activity) => {
                        // if its status is not valid its not a match
                        if (activity.status !== 'open' && $scope.tableConfig.actStatus === 'Open Activities') {
                            return false;
                        }
                        // no LOB specified
                        if ($scope.activityQuery === null || $scope.activityQuery.length < 1) {
                            // search field is empty
                            return true;
                        }
                        // search field has a term
                        if (activity.claimNumber.toLowerCase().indexOf($scope.activityQuery.toLowerCase()) !== -1 || activity.insured.displayName.toLowerCase().indexOf($scope.activityQuery.toLowerCase()) !== -1 || activity.subject.toLowerCase().indexOf($scope.activityQuery.toLowerCase()) !== -1) {
                            return true;
                        }
                        return false;
                    });
                }
            };
        }]
    };
}];

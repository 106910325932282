import templateStr from 'text!./pm-quote-breakdown.html';

export default ['$filter', 'StateUtils', ($filter, StateUtils) => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '=',
            coverageCode: '<',
            coverageTermCode: '<',
            coverageTermOptions: '<'
        },
        template: templateStr,
        controller: ['$scope', 'brandingData', '$q', 'StaticDocumentsService_AND', 'qnb.PolicyService', '$rootScope', ($scope, brandingData, $q, StaticDocumentsService_AND, PolicyService, $rootScope) => {
            const submission = $scope.quoteData.submission.value;
            const NONE_SELECTED = 'none';
            $scope.brand = brandingData.toLowerCase();
            let BREAKDOWN_SHOWN = ' ';
            if ($scope.brand === 'tbb') {
                BREAKDOWN_SHOWN = $scope.coverageTermOptions[0];
            } else {
                BREAKDOWN_SHOWN = $scope.coverageTermOptions[1];
            }

            const retrieveDocs = () => $q.all([
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.BREAKDOWN_IPID_BASIC, submission.baseData.periodStartDate),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.BREAKDOWN_IPID_LVL2, submission.baseData.periodStartDate),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.BREAKDOWN_IPID_EURO, submission.baseData.periodStartDate),
                StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.BREAKDOWN_POLICY, submission.baseData.periodStartDate)
            ])
                .then((result) => {
                    $scope.breakdownBasicInsuranceProductInformationDocument = result[0];
                    $scope.breakdownFinestInsuranceProductInformationDocument = result[1];
                    $scope.breakdownEuroInsuranceProductInformationDocument = result[2];
                    $scope.breakdownPolicyDocument = result[3];
                });
            const retrieveDocsPromise = retrieveDocs();

            const update = (qdd = $scope.quoteData) => {
                $scope.coverageTerm = $scope.quoteData.getCoverageTerm($scope.coverageCode, $scope.coverageTermCode, qdd.baseOffering);
                if($rootScope.productCode === 'TraditionalMotor' || $rootScope.productCode === 'CommercialVehicle') {
                    if ($scope.coverageTerm) { 
                        var tempCoverageOptions = [];
                        tempCoverageOptions.push($scope.coverageTerm.options[1]);
                        angular.forEach($scope.coverageTerm.options, function (value, key) {
                            if(key !== 1) {
                                tempCoverageOptions.push(value);
                            }
                        });
                        $scope.coverageTerm.options = tempCoverageOptions;
                    }
                }
            };

            $scope.optionsConfig = {};
            $scope.coverageTermOptions.forEach((option, index) => {
                $scope.optionsConfig[option] = {order: index, listItems: []};
                // Set list items
                if ($scope.brand === 'tbb') {
                    // eslint-disable-next-line no-unused-vars
                    let totalItems = 2;
                    if (index === 1) {
                        totalItems = 4;
                    }
                    for (let i = 0; i < totalItems; i++) {
                        $scope.optionsConfig[option].listItems
                            .push(`and.quoteandbind.pm.views.quote.AddOns.Breakdown.${option}.List.${i}`);
                    }
                }
                else {
                    // eslint-disable-next-line no-unused-vars
                    let totalItems = 3;
                    if (index === 1) {
                        totalItems = 4;
                    }
                    if (index === 2) {
                        totalItems = 2;
                    }
                    for (let i = 0; i < totalItems; i++) {
                        $scope.optionsConfig[option].listItems
                            .push(`and.quoteandbind.pm.views.quote.AddOns.Breakdown.${option}.List.${i}`);
                    }
                }

            });

            update(); // Init
            if (!$scope.coverageTerm) { // Abort initialisation if coverage is not available
                return;
            }
            // Coverage Term VM
            $scope.coverageTermVM = {
                value: null,
                aspects: {
                    required: true,
                    availableValues: $scope.coverageTerm.options.map((term) => {
                        term.monthlyCost = $scope.quoteData.getMonthlyAmount(term);
                        if (term.code !== BREAKDOWN_SHOWN && term.code !== $scope.coverageTerm.chosenTerm) {
                            term.isHidden = true;
                        }
                        return term;
                    }).concat([{code: NONE_SELECTED}]),
                    get valid() {
                        return angular.isDefined($scope.coverageTermVM.value) && $scope.coverageTermVM.value !== null;
                    },
                    get validationMessages() {
                        if (this.valid === false) {
                            return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                        }
                        return [];
                    }
                }
            };
            // Init coverageVM value
            // It's selected if it comes from PC; if it's not selected in PC, pre-populate only if the selection has been
            // recorded previously (so it'll only be pre-populated with false).
            if ($scope.quoteData.submission.isBase_itb.value === true) {
                if ($scope.coverageTerm.chosenTerm) {
                    $scope.coverageTermVM.value = $scope.coverageTerm.chosenTerm;
                } else {
                    $scope.coverageTermVM.value = NONE_SELECTED;
                }
            } else if ($scope.coverageTerm.chosenTerm) {
                $scope.coverageTermVM.value = $scope.coverageTerm.chosenTerm;
            } else {
                const coverageSelection = StateUtils.activeDataForState(true).coverageSelection;
                if (coverageSelection && coverageSelection[$scope.coverageCode]
                    && coverageSelection[$scope.coverageCode].selected === false) {
                    $scope.coverageTermVM.value = NONE_SELECTED;
                }
            }
            $scope.$watch('coverageTermVM.value', (newValue) => {
                if (newValue !== null) {
                    if ($scope.coverageTerm.chosenTerm !== newValue) {
                        if (newValue === NONE_SELECTED && !$scope.coverageTerm.chosenTerm) {
                            // just update cache and return
                            updateCoverageTermSelectionCache(null, $scope.coverageCode);
                            return;
                        }
                        $scope.quoteData.updateCoverageTermSelection((newValue === NONE_SELECTED ? null : newValue), $scope.coverageCode, $scope.coverageTermCode)
                            .then((qdd) => {
                                update(qdd);
                                // For UI Feedback if the operation was successful or not
                                $scope.coverageTermVM.value = $scope.coverageTerm.chosenTerm ? $scope.coverageTerm.chosenTerm : NONE_SELECTED;
                                // update Cache
                                updateCoverageTermSelectionCache((newValue === NONE_SELECTED ? null : newValue), $scope.coverageCode);
                            });
                    } else {
                        updateCoverageTermSelectionCache((newValue === NONE_SELECTED ? null : newValue), $scope.coverageCode);
                    }
                }
            });

            const updateCoverageTermSelectionCache = function (chosenTerm, covPatternCode) {
                const coverageSelection = StateUtils.activeDataForState(true).coverageSelection;
                // Initialise selection object if doesn't exist
                if (!coverageSelection) {
                    StateUtils.activeDataForState(true).coverageSelection = {};
                } else {
                    // If selection with the same value exists already -> no need to update
                    const cov = coverageSelection[covPatternCode];
                    if (cov && cov.covTerm === chosenTerm) {
                        return;
                    }
                }
                retrieveDocsPromise.then(() => {
                    const doc = getDocForCoverageTerm(chosenTerm);
                    StateUtils.activeDataForState(true).coverageSelection[covPatternCode] = {
                        selected: chosenTerm !== null,
                        covTerm: chosenTerm,
                        docLink: doc.link,
                        docLabel: doc.label
                    };
                });
            };

            const getDocForCoverageTerm = (chosenTerm) => {
                const label = $filter('andBrand')('and.quoteandbind.pm.views.quote.AddOns.BreakdownTitle');
                switch (chosenTerm) {
                    case 'Level1':
                    case 'Level2':
                    case 'Value':
                        return {
                            link: $scope.breakdownBasicInsuranceProductInformationDocument,
                            label: label
                        };
                    case 'Finest':
                        return {
                            link: $scope.breakdownFinestInsuranceProductInformationDocument,
                            label: label
                        };
                    case 'Level3':
                    case 'EuroPlus':
                        return {
                            link: $scope.breakdownEuroInsuranceProductInformationDocument,
                            label: label
                        };
                    default:
                        return {};
                }
            };

            // Accordion open flag. Accordion is supposed to go away once opened.
            $scope.page = {hiddenOptionTriggered: $scope.coverageTermVM.aspects.availableValues.every((option) => option.isHidden !== true)};
            // Sort options
            $scope.optionComparator = function ({value: o1}, {value: o2}) {
                return $scope.optionsConfig[o1].order - $scope.optionsConfig[o2].order;
            };
            $scope.selectOption = (option, $event) => {
                if ($event.target.tagName === 'A') { // Ignore clicks to the document anchor links
                    return;
                }
                if (!$scope.quoteData.controlsDisabled()) {
                    $scope.coverageTermVM.value = option.code;
                }
            };
        }]
    };
}];
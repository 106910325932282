export default ['$q', 'JsonRPCService', 'CacheService_AND', ($q, JsonRPCService, CacheService_AND) => {

    const endpoint = 'policychange/policychange';

    return {
        calcTopUpMiles: (policyNumber, options) => {
            return CacheService_AND.cacheAware(
                `${endpoint}/calcTopUpMiles`,
                [policyNumber, options],
                () => JsonRPCService.send('pc', endpoint, 'calcTopUpMiles', [policyNumber, options])
            );
        },
        createTopUpMiles: (policyNumber, requestedMiles) => {
            const deferred = $q.defer();
            JsonRPCService.send('pc', endpoint, 'createTopUpMiles', [policyNumber, requestedMiles])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        preBindTopUpMiles: (jobNumber, dto) => {
            const deferred = $q.defer();
            JsonRPCService.send('pc', endpoint, 'preBindTopUpMiles', [jobNumber, dto])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        },
        bindTopUpMiles: (jobNumber) => {
            const deferred = $q.defer();
            JsonRPCService.send('pc', endpoint, 'bindTopUpMiles', [jobNumber])
                .then((result) => deferred.resolve(result), (error, status, headers) => deferred.reject([error, status, headers]));
            return deferred.promise;
        }
    };
}];
import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./ForgotPassword.html';
import styles from './ForgotPassword-hashed.scss';
import AuthenticationService from 'plugin?auth-type!gw-portals-auth-js/AuthenticationService';
import ComponentUtil from 'gw-portals-util-js/ComponentUtil';

// VARS
const hashedTemplate = cssUtil.hashTemplate(template, styles);

// EXPORT
export default {
    template: hashedTemplate,
    controller: [
        'auth.AuthLoginModal', '$scope', '$state', 'oAuth', 'ViewModelService', '$location',
        function (AuthLoginModal, $scope, $state, oAuth, ViewModelService, $location) {
            // VARS
            const $ctrl = this;
            const authenticationService = AuthenticationService(oAuth);

            $ctrl.isForgotReqInProgress = false;// animation

            $scope.forgotEmailVM = ViewModelService.create({}, 'pc', 'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO', {AccountEmailRequired: true}).emailAddress1;

            // METHODS
            const setCtrlParam = ComponentUtil.setCtrlParam($ctrl, $scope);

            function setError(value) {
                setCtrlParam('forgotPasswordError', value);
            }

            $ctrl.send = (form) => {
                form.submitted = true;
                if (form.valid === false) {
                    return;
                }
                setCtrlParam('isForgotReqInProgress', true);// animation
                authenticationService.forgotPassword($scope.forgotEmailVM.value)
                    .then(() => {
                        setCtrlParam('status', 'EMAIL_SENT');
                    })
                    .catch(err => {
                        switch (err.error) {
                            case authenticationService.ERRORS.tokens.emailNotFound:
                                // if the email used is not valid then set the same response as with a valid one
                                setCtrlParam('status', 'EMAIL_SENT');
                                break;
                            default:
                                setError('invalidAttempt');
                                break;
                        }
                    })
                    .finally(()=>{
                        setCtrlParam('isForgotReqInProgress', false);// animation
                    });
            };

            $ctrl.backToSignIn = () => {
                const url = $location.absUrl();
                window.location.href = url.substring(0, url.indexOf('#'));
            };
        }
    ]
};
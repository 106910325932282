import _ from 'lodash';
/**
 * Sets Endorsements tile states
 *
 * @param {scope} $scope - the angular scope
 *
 * Usage:
 * $scope.getState = EndorsementStateUtils.defineState( $scope );
 *
 * @returns {Function}
 */
function defineState($scope) {
    return () => {
        if ($scope.mode === 'full') {
            return 'active';
        }
        return 'completed';
    };
}

/**
 * Returns a function that transitions to a named state in the state flow.
 * @param {scope} $scope - the angular controller scope
 * @param {String} stateMappings - the name of the state to transition to
 * Usage:
 * $scope.toState = EndorsementStateUtils.toState( $scope, stateMappings );
 * @returns {Function}
 */
function toState($scope, stateMappings) {
    return (state) => {
        const stateValue = stateMappings[$scope.wizard.state.name];
        state = $scope.flow.steps.find(step => step.name === state);
        if (_.has($scope.wizard.model.nextSteps, stateValue)) {
            // Here we are tranisition as normal
            $scope.flow.jumpToStep(state, $scope.wizard.model);
        } else {
            /*  Here we have jumped to a normally unreachable state (from shopping cart and need to force our
             way out
             */
            $scope.flow.jumpToStep(state, $scope.wizard.model, {}, true);
        }
    };
}

/**
 * Returns a function that determines whether a state is in the state flow
 *
 * @param {scope} $scope - the angular controller scope
 * @returns {function}
 *
 * Usage:
 * $scope.hasState = EndorsementStateUtils.hasState( $scope );
 *
 * @returns {Function}
 */
function hasState($scope) {
    /**
     * determines whether a state is in the state flow
     * @param {String} state
     * @returns {boolean}
     */
    return (state) => {
        return $scope.flow.steps.some(s => s.name === state);
    };
}


/**
 * Returns a function that determine the tile state of a state in the flow state
 * @param {scope} $scope - the angular controller scope
 * @returns {function}
 *
 * Usage:
 * $scope.tileState = EndorsementStateUtils.tileState( $scope );
 *
 * @returns {Function}
 */
function tileState($scope) {
    /**
     * Determine the tile state of a state in the flow state
     * @param {String} state
     * @returns {String} - 'full-visited' if state has been visited, 'full' if state is active, otherwise 'hidden'
     */
    return (state) => {
        if (showOnState($scope)(state)) {
            return 'full';
        } else if ($scope.isStateVisible(state) && $scope.flow.visitedSteps.find((visited) => state === visited.name)) {

            return 'full-visited';
        }
        return 'hidden';
    };
}

/**
 * Returns a function that determine whether a state should be displayed
 * @param {scope} $scope - the angular controller scope
 * @returns {function}
 *
 * Usage:
 * $scope.showOnState = EndorsementStateUtils.showOnState( $scope );
 */
function showOnState($scope) {
    /**
     * Determine whether a state should be displayed
     * @param {Object} state
     * @returns {boolean}
     */
    return (state) => {
        return $scope.wizard.state.name === state;
    };
}

/**
 * Returns a function that transitions to the next state
 * @param {scope} $scope - the angular controller scope
 * @returns {function}
 */
function next($scope) {
    /**
     * Transitions to the next state
     */
    return () => {
        $scope.flow.next($scope.wizard.model);
    };
}

export default {
    defineState: defineState,
    toState: toState,
    hasState: hasState,
    tileState: tileState,
    showOnState: showOnState,
    next: next
};
import Claim from 'edge/fnol/common/models/Claim';

export default ['$q', 'JsonRPCService', 'ViewModelService', 'fnol.endpointConfig', ($q, JsonRPCService, ViewModelService, endpointConfig) => {

    /**
     *
     * @param {Object} params
     * @param {String} params.rpcMethod
     * @param params.rpcParams
     * @param {Object} [params.claimDTOtoSet]
     * @returns Promise
     */

    function processClaim(params) {
        return JsonRPCService.send('cc', endpointConfig.loadSaveEndpoint, params.rpcMethod, params.rpcParams).then(response => {
            return ViewModelService.create(new Claim(response), 'cc', 'edge.capabilities.claim.fnol.dto.FnolDTO');
        });
    }


    return {
        getClaim: (claimNumber/* , loadingMsg*/) => {
            return processClaim({
                rpcMethod: 'getClaim',
                rpcParams: [claimNumber]
            }).then(model => model.value); // return plain object for read operation
        },

        createClaim: (claimDTO) => {
            return processClaim({
                rpcMethod: 'createClaim',
                rpcParams: [claimDTO.policy.policyNumber, claimDTO],
                claimDTOtoSet: claimDTO
            });
        },

        saveClaim: (claimDTO) => {
            const dto = angular.copy(claimDTO);
            dto.documents = [];
            dto.contacts = [];

            return processClaim({
                rpcMethod: 'saveClaim',
                rpcParams: [dto],
                claimDTOtoSet: claimDTO
            });
        },

        submitClaim: (claimDTO) => {
            const dto = angular.copy(claimDTO);
            dto.documents = [];
            dto.contacts = [];
            dto.policy = null;

            return processClaim({
                rpcMethod: 'submitClaim',
                rpcParams: [dto],
                claimDTOtoSet: claimDTO
            });
        }
    };
}];
import moment from 'moment';
import PolicySearchUtil from 'gw-portals-util-angular/PolicySearchUtil';
import simpleTemplate from 'text!./templates/policy-search-result-simple.html';
import detailedTemplate from 'text!./templates/policy-search-result-detailed.html';

/** Search result controller.
 * Scope arguments:
 *  <ul>
 *      <li><strong>policies</strong> - list of policies to display.
 *      <li><strong>selectPolicy</strong> - function with type (Policy) => Unit. Sets a selected policy. May
 *        be called with a null argument to deselect a policy.
 *      <li><strong>selectedPolicy</strong> - current selected policy.
 *  </ul>
 */

const fn = ($compile) => {
    return {
        restrict: 'A',
        scope: {
            policies: '=',
            selectPolicy: '=',
            selectedPolicy: '=',
            iconNameSelector: '=?',
            iconPathSelector: '=?',
            iconAltSelector: '=?',
            insuredSelector: '=?',
            addressSelector: '=?',
            effectiveDateSelector: '=?',
            expirationDateSelector: '=?'
        },

        link: (scope, element, attrs) => {
            scope.$watch('policies', () => {
                if (!scope.policies) {
                    return;
                }
                scope.inForcePolicies = scope.policies.filter((tmpPolicy) => {
                    return !tmpPolicy.isCancelled && !(moment()
                        .isBefore(moment(tmpPolicy.effective)));
                });
            });

            let templateStr = simpleTemplate;
            if ('showInsuredDetails' in attrs) {
                templateStr = detailedTemplate;
            }

            element.html(templateStr).show();
            $compile(element.contents())(scope);
        },

        controller: ['$scope', 'InsuranceSuiteMetadata', (scope, InsuranceSuiteMetadata) => {
            const policyTypeTypelist = InsuranceSuiteMetadata.get('cc').types.getTypelist('PolicyType');
            scope.PolicySearchUtil = PolicySearchUtil;
            scope.isSelected = (item) => {
                return scope.selectedPolicy && scope.selectedPolicy.policyNumber === item.policyNumber;
            };
            scope.policyTypeName = (policyTypeCode) => {
                return policyTypeTypelist.getCode(policyTypeCode).name;
            };

            scope._selectPolicy = (item) => {
                scope.selectPolicy(item);
            };
        }]
    };
};
fn.$inject = ['$compile'];

export default fn;
import templateStr from 'text!./pm-important-info-ulr.html';

const TRANSLATION_KEY = 'and.quoteandbind.pm.directives.templates.pm-important-info';
const BRAND_SECTIONS_ULR = {tbb: 9, dgt: 10, itb: 10};
const BRAND_ANDUK_ULR = {tbb: 7, dgt: 8, itb: 8};


export default ['brandingData', (brandingData) => {
    return {
        restrict: 'E',
        scope: {},
        template: templateStr,
        controller: ['$scope', '$rootScope', ($scope, $rootScope) => {
            if (!BRAND_SECTIONS_ULR[brandingData]) {
                return;
            }

            $scope.accordion = [];
            for (let i = 0; i < BRAND_SECTIONS_ULR[brandingData]; i++) {
                if (i === BRAND_ANDUK_ULR[brandingData] && $rootScope.policyContext.isTodayBeforeAndUk()) {
                    $scope.accordion.push({
                        title: `${TRANSLATION_KEY}.${i}.Title.ULR`,
                        content: `${TRANSLATION_KEY}.${i}.Content.ANDUK`
                    });
                } else {
                    $scope.accordion.push({
                        title: `${TRANSLATION_KEY}.${i}.Title.ULR`,
                        content: `${TRANSLATION_KEY}.${i}.Content.ULR`
                    });
                }
            }
        }]
    };
}];
import _ from 'underscore';

function PmVehicle(data) {
    if (data) {
        _.extend(this, data);
    }

    if (!angular.isDefined(this.costNew)) {
        this.costNew = {};
    }
}

PmVehicle.prototype.getDisplayName = function () {
    if (this.abiCode) {
        return `${this.firstRegisteredYear} ${this.make} ${this.enhancedVehicleDescription} ${this.engineCapacity} CC`;
    }
    return `${this.firstRegisteredYear} ${this.make} ${this.model} ${this.description} ${this.engineCapacity || ''} ${this.engineCapacity ? 'CC' : ''}  ${this.numberOfDoors} ${this.transmission} ${this.bodyType || ''} ${this.fuelType}`;
};

export default PmVehicle;
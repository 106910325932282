import templateStr from 'text!./change-phone-numbers.html';

export default ['$filter', '$rootScope', '$timeout', 'UserProfileService_AND', 'ViewModelService',
    ($filter, $rootScope, $timeout, UserProfileService_AND, ViewModelService) => {
        const API_ERROR = $filter('translate')('and.directives.change-phone-numbers.APIError');

        return {
            restrict: 'A',
            template: templateStr,
            scope: {},
            controller: ['$scope', ($scope) => {
                const persistedValues = {};
                const setAPIError = (message) => {
                    $scope.apiError = message;
                };
                $scope.clearAPIMessage = () => setAPIError(null);
                $scope.clearSuccessMsg = () => {
                    $scope.isSuccessVisible = false;
                };
                $scope.haveNumbersChanged = () => {
                    return persistedValues.mobilePhone !== $scope.userProfileVM.mobilePhone.value ||
                    persistedValues.homePhone !== $scope.userProfileVM.homePhone.value ||
                    persistedValues.workPhone !== $scope.userProfileVM.workPhone.value;
                };

                let initObject = {};
                if ($rootScope.accountContext && $rootScope.accountContext.accountHolderVM) {
                    initObject = {
                        mobilePhone: $rootScope.accountContext.accountHolderVM.mobilePhone.value,
                        homePhone: $rootScope.accountContext.accountHolderVM.homePhone.value,
                        workPhone: $rootScope.accountContext.accountHolderVM.workPhone.value
                    };
                }
                $scope.userProfileVM = ViewModelService.create(initObject, 'pc', 'itb.edge.capabilities.profileinfo.user.dto.UserProfileDTO', null);
                Object.assign(persistedValues, initObject);

                $scope.submit = (form) => {
                    form.submitted = true;
                    $scope.clearAPIMessage();
                    if (form.valid === false) {
                        return;
                    }
                    $scope.inFlight = true;
                    return UserProfileService_AND.updatePhoneNumbers($scope.userProfileVM.value)
                        .then((data) => {
                            $rootScope.accountContext.accountHolderVM = ViewModelService.create(data, 'pc', 'itb.edge.capabilities.profileinfo.user.dto.UserProfileDTO', null);
                            $scope.isSuccessVisible = true;
                            $timeout(() => { // Clear success message after 10 seconds
                                $scope.clearSuccessMsg();
                            }, 10000);
                        })
                        .catch(() => {
                            setAPIError(API_ERROR);
                        })
                        .finally(() => {
                            $scope.inFlight = false;
                        });
                };

                $scope.$watch(() => {
                    if ($rootScope.accountContext) {
                        return $rootScope.accountContext.accountHolderVM;
                    }
                    return null;
                }, (newValue) => {
                    if (newValue) {
                        $scope.userProfileVM.mobilePhone.value = newValue.mobilePhone.value;
                        $scope.userProfileVM.homePhone = newValue.homePhone.value;
                        $scope.userProfileVM.workPhone = newValue.workPhone.value;
                        Object.assign(persistedValues, newValue.value);
                    }
                });
            }]
        };
    }];
import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./AuthStatus.html';
import styles from './AuthStatus-hashed.scss';
import AuthenticationService from 'plugin?auth-type!gw-portals-auth-js/AuthenticationService';
import appFeatures from 'effective-features!';
import ComponentUtil from 'gw-portals-util-js/ComponentUtil';

const hashedTemplate = cssUtil.hashTemplate(template, styles);

export default {
    template: hashedTemplate,
    bindings: {
        showPreferences: '='
    },
    controller: [
        '$state', 'oAuth', 'gwAuthTokenService', '$scope',
        function ($state, oAuth, gwAuthTokenService, $scope) {
            // VARS
            const $ctrl = this;
            const homeLogin = 'authLogin';
            const authenticationService = AuthenticationService(oAuth);

            // HELPERS
            function getUsersprofileRoute() {
                // check if userprofile is provided
                const checkForEnrollment = appFeatures['self-enrollment'];
                if (!checkForEnrollment || !checkForEnrollment.usersprofileRoute) {
                    return undefined;
                }

                return checkForEnrollment.usersprofileRoute;
            }

            const setCtrlParam = ComponentUtil.setCtrlParam($ctrl, $scope);

            // METHODS
            $ctrl.login = function () {
                $state.go(homeLogin);
            };

            $ctrl.logout = function () {
                authenticationService.logout();
            };

            // INIT
            $ctrl.$onInit = () => {
                $ctrl.usersprofileRoute = getUsersprofileRoute();

                $ctrl.userHasAuthoritites = false;
                // wait for the user's authorities
                gwAuthTokenService.grantedAuthorities.then((grantedAuthorities) => {
                    if (grantedAuthorities.length) {
                        setCtrlParam('userHasAuthoritites', true);
                    } else {
                        setCtrlParam('userHasAuthoritites', false);
                    }
                });
            };
        }]
};
import _ from 'lodash';
import FlowUtil from './FlowUtil';
import defaultJumpToCheck from './modelhelpers/JumpToStepCheck';
import snapshotBuilder from './modelhelpers/SnapshotBuilder';
import Diff from '../diff/Diff';
import ValidationUtil_AND from 'gw-portals-util-js/ValidationUtil_AND'; // ANDIE
/**
 * A factory function that returns an object for interacting with a wizard flow
 *
 * @param {Object} flow                  - The definition of the entry, exit and steps of a flow
 * @param {Object} Q                     - ES6 Promise API implementation
 * @param {Function} publishWizardEvent  - Function to generate wizard events
 * @param {Function} stepChangeFns  - Function to get called on flow step transitions to trigger router transition
 * @param {Object} modalBehavior         - Provides promises resulting from displaying information dialogs
 *                     actionStatusInfo - (modals for displaying progress & error messages of calls to the server)
 *                     snapshotResetConfirmation - (confirmation message to prompt that data may be lost when reset to snapshot)
 *                     cancelConfirmation - (confirmation message to cancel flow)
 *
 * @returns {Object} Wizard Model
 **/
export default function (flow, Q, publishWizardEvent, stepChangeFns, modalBehavior, $rootScope) {
    let currentSnapshot;
    let currentServerSnapshot;
    let _jumpToStepCheck = defaultJumpToCheck;
    let stepHistory = (FlowUtil.isStepObject(flow.entry)) ? [flow.entry] : [];
    const stepChangeListeners = stepChangeFns.concat();
    const transitionStartListeners = [];
    const transitionFinishListeners = [];
    let _disableAllStepsAND = false; // ANDIE - Make Disabled steps inside FlowModel

    /*
     * Function that takes in a step and executes the transition in response to an event.
     * If the result of that transition is an Junction then it will keep calling the next transition
     * until a step or flow exit is reached. At that point the step change listeners and route change handler will be
     * called.
     */
    function goToStepForEvent(flowModel, eventName, _model, startStep, _extraArgs = {}) {
        function executeBeforeTransitionListeners(actionHandlerObject) {
            transitionStartListeners.forEach(listenerFn => listenerFn(actionHandlerObject));
        }

        function executeAfterTransitionListeners(resolution) {
            transitionFinishListeners.forEach(listenerFn => listenerFn(resolution));

            return resolution; // Pass resolution forward
        }


        /*
         * Return the promise of a single transition to a step or junction
         */
        function getTransitionPromise(model, step, args, isPristine) {
            const actionHandlerObject = step[eventName].doAction();
            executeBeforeTransitionListeners(actionHandlerObject);

            if (actionHandlerObject) {
                // promise that resolves to an object - {model, extraArgs}
                const actionPromise = actionHandlerObject.action(model, args, isPristine);
                const actionStatusHandler = modalBehavior.actionStatusInfo;
                // return promise that resolves to an object - {model, step, extraArgs}
                return actionStatusHandler(
                    actionPromise.then(executeAfterTransitionListeners),
                    actionHandlerObject.msgs
                );
            }

            // if just a branch or goTo - convert sync into promise that is immediately resolved
            const nextStepOrJct = FlowUtil.getNextSyncStepOrJunction(flow, step, model, args);
            // promise resolving to model and next step
            return Q.resolve({model: model, step: nextStepOrJct, extraArgs: args})
                .then(executeAfterTransitionListeners);
        }

        /*
         * If the result of a next transition is a junction instead of a step or flow exit then we recursively
         * go to the next transition until a step is reached.
         */
        function finalTransitionPromise(stepTransitionPromise) {
            function successAndErrorCb({
                model, step, extraArgs, serverReqSent
            }) {
                step = FlowUtil.getStepOrJunctionObject(step, flow);
                if (step._type === 'STEP' || flow.exits[step.name]) {
                    return {
                        model: model,
                        step: step,
                        extraArgs: extraArgs,
                        serverReqSent: serverReqSent
                    };
                }
                const transitionPromise = getTransitionPromise(model, step, extraArgs);
                return finalTransitionPromise(transitionPromise);
            }

            return stepTransitionPromise.then(successAndErrorCb, successAndErrorCb);
        }

        /*
         * If the final reached point in the flow is a Step then set the current step and fire stepChange and route listeners
         * If the final point reached in the flow is a Junction and an exit point then fire the route listener
         */
        function fireListenersForFinalTransition({
            model, step, extraArgs
        }) {
            const nextStep = FlowUtil.getStepOrJunctionObject(step, flow);
            flow.currentStep = nextStep;
            if (FlowUtil.isStepObject(nextStep, flow)) {
                const previouslySeenIndex = getStepIndexStepArray(flow.currentStep.name, stepHistory);
                if (previouslySeenIndex !== -1) {
                    // set the history back to where the step was first encountered
                    stepHistory = stepHistory.slice(0, previouslySeenIndex + 1);
                } else {
                    stepHistory.push(flow.currentStep);
                }
                executeStepChangeListeners(model, startStep, nextStep, extraArgs);
            } else if (flow.exits[nextStep.name]) {
                // junction as flow exit
                executeStepChangeListeners(model, startStep, nextStep, extraArgs);
            }
        }

        if (flowModel.serverSnapshot && _model) {
            const diff = Diff.diffDeep(_model.value || _model, flowModel.serverSnapshot, ['tempId', '$$hashKey', 'branch']);
            flowModel.isPristine = diff.length === 0;
        }

        flowModel.snapshot = _model;

        const finalPromise = finalTransitionPromise(getTransitionPromise(_model, startStep, _extraArgs, flowModel.isPristine));

        return finalPromise.then(result => {
            if (startStep.name === 'cancel' && result.model) {
                result.model = _.cloneDeep(result.model.value || result.model);
            }

            // if the transition succeeded then set the snapshot with the model that may have been updated following actions
            flowModel.snapshot = result.model;

            if (result.serverReqSent) {
                flowModel.serverSnapshot = result.model;
                flowModel.isPristine = true;
            }

            fireListenersForFinalTransition(result);
            return result;
        })
            .catch(error => {
                fireListenersForFinalTransition(error);
                throw error;
            });
    }

    /**
     * @param {String} stepName
     * @param {Object} steps
     * @returns {number} the index of a step from an array of step based on step name
     */
    function getStepIndexStepArray(stepName, steps) {
        const previousStepNames = _.pluck(steps, 'name');
        return previousStepNames.indexOf(stepName);
    }

    /*
     * Call each of the stepchange listeners with the model
     */
    function executeStepChangeListeners(model, fromStep, toStep, extraArgs) {
        const listeners = stepChangeListeners.concat();
        listeners.forEach(listenerFn => listenerFn(model, fromStep, toStep, extraArgs));
    }

    function _removeFromArray(arr, item) {
        const removeIndex = arr.indexOf(item);
        if (removeIndex !== -1) {
            arr.splice(removeIndex, 1);
        }
    }

    return {
        /**
         * Register callback function to be called when next and previous transitions are initiated
         * Callback function is passed flow, step and model parameters
         *
         * @param {Function} callback
         */
        addTransitionStartListener(callback) {
            transitionStartListeners.push(callback);
        },
        addTransitionFinishListener(callback) {
            transitionFinishListeners.push(callback);
        },

        removeTransitionStartListener(callback) {
            _removeFromArray(transitionStartListeners, callback);
        },
        removeTransitionFinishListener(callback) {
            _removeFromArray(transitionFinishListeners, callback);
        },

        /**
         * Register callback function to be called on next and previous transitions
         * Callback function is passed flow, step and model parameters
         *
         * @param {Function} callback
         */
        addStepChangeListener(callback) {
            stepChangeListeners.push(callback);
        },

        /**
         * Remove a listener.
         *
         * @param {Function} callback
         * @throws {Error} if there is no matching listener function
         */
        removeStepChangeListener(callback) {
            _removeFromArray(stepChangeListeners, callback);
        },

        /**
         * Remove all listeners.
         */
        removeAllStepChangeListeners() {
            stepChangeListeners.length = 0;
        },

        /**
         * Provides an alternative implementation to the default function that determines if a user can directly
         * transition to another step.
         * The default implementation only allows a user to transition to a previous step as long as there are no
         * gate points between the current step and the destination see @ modelhelpers/JumpToStepCheck
         *
         * @param {Function} jumpCheckImplementation
         */
        set alternativeJumpToStepCheck(jumpCheckImplementation) {
            _jumpToStepCheck = jumpCheckImplementation;
        },

        /**
         * Reset the current step and step history of the flow
         *
         * @param {Object} currentStep - New current step in flow
         * @param {Array} steps        - New step history, array of step names or step objects
         * @param {Object} snapShot    - New snapshot
         *
         * @throws Error if currentStep does not exist as a step in the flow
         * @throws Error if any of the steps elements do not exist as steps in the flow
         *
         */
        resetFlowProgress(currentStep, steps, snapShot) {
            steps = steps.map(step => FlowUtil.getStepObject(step, flow));
            stepHistory.length = 0;
            stepHistory = stepHistory.concat(steps);
            flow.currentStep = FlowUtil.getStepObject(currentStep, flow);
            this.snapshot = snapShot;
            this.serverSnapshot = snapShot;
            this.isAllStepsDisabled_AND = false;
        },

        /**
         * Check to see if it is possible to transition to this step from the current step
         *
         * @param {Object} targetStep - Step to transition to.
         * @param {Object} [model] - Model to use for transition check function
         *
         * @returns {Boolean}
         */
        isStepAccessibleFromCurrent(targetStep, model) {
            if (!_disableAllStepsAND) {
                return _jumpToStepCheck(this, this.currentStep, targetStep, model);
            }
            return false;// ANDIE - Deny access to all steps if _disbleAllSteps_AND is true
        },

        /**
         * ANDIE - Disable all steps while performing common actions like Email Quote which shows Screens inside FlowModel
         *
         * @param {Object} disable - boolean to set disableAllSteps.
         *
         */
        set isAllStepsDisabled_AND(disable) {
            _disableAllStepsAND = disable;
        },

        /**
         * ANDIE - Disable all steps while performing common actions like Email Quote which shows Screens inside FlowModel
         *
         * @returns {Object} disable - boolean to set disableAllSteps.
         *
         */
        get isAllStepsDisabled_AND() {
            return _disableAllStepsAND;
        },

        /**
         * A deep clone of the model is stored on every next transition
         *
         * @returns {Object} - the latest clone of the model
         */
        get snapshot() {
            return currentSnapshot;
        },

        /**
         * Take a clone of the model parameter to store as the latest snapshot
         *
         * @param {Object} model - object to be cloned
         */
        set snapshot(model) {
            currentSnapshot = snapshotBuilder(model);
        },

        /**
         * A deep clone of the model is stored on every server request
         *
         * @returns {Object} - the latest clone of the model in sync with the server
         */
        get serverSnapshot() {
            return currentServerSnapshot;
        },

        /**
         * Take a clone of the model parameter to store as the latest snapshot in sync with the server
         *
         * @param {Object} model - object to be cloned
         */
        set serverSnapshot(model) {
            currentServerSnapshot = model && model.value ? _.cloneDeep(model.value) : _.cloneDeep(model);
        },

        /**
         * The name of the current step in the flow
         *
         * @returns {Object} The current step object in the flow
         */
        get currentStep() {
            return flow.currentStep || flow.entry;
        },

        /**
         * Ordered list of steps based on the history of steps we have passed through and the
         * future steps that can be reached based on the model snapshot.
         * (Future steps will be included until an action transition is reached or a branch function returns undefined)
         *
         * @returns {Array}
         */
        get steps() {
            // add the steps that we have passed through already
            const steps = stepHistory.concat();
            if (steps.length === 0) {
                // Getting steps when nothing is in the history and entry is a junction
                return [];
            }

            let nextStep;

            // add forward steps based on the model snapshot
            do {
                nextStep = FlowUtil.getNextStep(flow, steps[steps.length - 1], this.snapshot);
                if (nextStep) {
                    const previouslySeenIndex = getStepIndexStepArray(nextStep.name, steps);
                    if (previouslySeenIndex !== -1) {
                        // return the steps up until the cycle is encountered
                        return steps.slice(0, previouslySeenIndex + 1);
                    }
                    steps.push(nextStep);
                }
            } while (nextStep);


            return steps;
        },

        /**
         * Ordered list of steps based on the history of steps we have passed through. Does not include future steps
         *
         * @returns {Array}
         */
        get visitedSteps() {
            return stepHistory.concat();
        },


        /**
         * Progress to the next step in the flow based on the model parameter. If the current step is invalid, remain on this step.
         *
         * @param {Object} model - object passed to transition functions
         * @param {Object} [extraArgs]   - Map of arguments passed to branch functions and step listeners
         *
         * @returns {Promise} - promise that resolves to next step
         */
        next(model, extraArgs) {
            if (this.currentStep.isValid && !this.currentStep.isValid()(model, extraArgs)) {
                console.log('Remaining on current step, as it is invalid.');
                $rootScope.$broadcast('andEnableValidationSummary'); // ANDIE Trigger display of validation summary and scroll to it
                // current step is invalid, do not proceed to next step
                return Q.resolve({
                    model: model,
                    step: this.currentStep,
                    extraArgs: extraArgs
                });
            }

            const self = this;
            const prevStep = this.currentStep;
            return goToStepForEvent(this, 'onNext', model, this.currentStep, extraArgs).then(result => {
                publishWizardEvent('next', result.model, prevStep, self.currentStep);
                return result;
            });
        },

        /**
         * Transition to a step in the flow in response to a named event
         * Similar in behavior to onNext behavior where we first check that the model is valid.
         *
         * @param {String} eventName - name of event
         * @param {Object} model - object passed to transition functions
         * @param {Object} [extraArgs]   - Map of arguments passed to branch functions and step listeners
         *
         * @returns {Promise} - promise that resolves to next step
         */
        event(eventName, model, extraArgs = {}) {
            if (this.currentStep.isValid && !this.currentStep.isValid()(model, extraArgs)) {
                // current step is invalid, do not proceed to next step
                return Q.resolve({
                    model: model,
                    step: this.currentStep,
                    extraArgs: extraArgs
                });
            }

            const self = this;
            const previousStep = this.currentStep;
            return goToStepForEvent(this, eventName, model, this.currentStep, extraArgs).then(result => {
                publishWizardEvent('event', model, previousStep, self.currentStep);
                return result;
            });
        },


        /**
         * Move back to a previous step in the flow. If there is a onPrevious handler function
         * defined then that function will be used to determine the previous step. If no function
         * has been entered then the default implementation will return the previous
         * step in the list of ordered steps.
         *
         * @param {*} [_model] - if a model is not provided then the snapshot will be used
         * @param {Object} [_extraArgs]   - Map of arguments passed to branch functions and step listeners
         * @returns {Promise} - promise that resolves to the previous step
         *
         */
        previous(_model, _extraArgs) {
            let prevStep;
            let prevStepIndex;
            const fromStep = this.currentStep;
            const hasPreviousHandler = this.currentStep.onPrevious();

            function transitionToPrevious(transitionModel, currentStep) {
                if (!hasPreviousHandler) {
                    // no onPrevious handler defined in flow definition so provide default behavior
                    const currentStepIndex = _.findIndex(stepHistory, step => step.isSimilarTo_AND(currentStep)); // ANDIE
                    prevStepIndex = currentStepIndex - 1;
                    if (prevStepIndex < 0) {
                        throw new Error('Could not find previous step from current step', currentStep);
                    }
                    prevStep = stepHistory[prevStepIndex];
                    flow.currentStep = prevStep;
                } else {
                    // custom handler for previous, may return undefined to act as a gate point in the flow
                    prevStep = hasPreviousHandler(transitionModel, _extraArgs);
                    if (!prevStep) {
                        prevStepIndex = _.findIndex(stepHistory, step => step.name === currentStep.name);
                    } else {
                        prevStep = FlowUtil.getStepObject(prevStep, flow);
                        flow.currentStep = prevStep;
                        prevStepIndex = _.findIndex(stepHistory, step => step.name === prevStep.name);
                    }
                }
                stepHistory = stepHistory.slice(0, prevStepIndex + 1);
                if (fromStep.name !== flow.currentStep.name) {
                    executeStepChangeListeners(transitionModel, fromStep, flow.currentStep, _extraArgs);
                    publishWizardEvent('previous', transitionModel, fromStep, flow.currentStep);
                }
            }

            if (_model) {
                // check that model is valid, if not then we need to give the user a warning that they might lose information
                return modalBehavior.snapshotResetConfirmation(_model)
                // model is valid so proceed without resetting model to snapshot
                    .then(model => transitionToPrevious(model, this.currentStep))
                    .catch(error => {
                        if (error === 'cancelled') {
                            // navigation cancelled
                            return false;
                        }
                        // confirmed reset to snapshot and proceed with navigation
                        transitionToPrevious(this.snapshot, this.currentStep);
                    });
            }
            const deferred = Q.defer();
            deferred.promise.then(() => {
                return transitionToPrevious(this.snapshot, this.currentStep);
            });
            deferred.resolve();
            return deferred.promise;
        },

        /**
         * Move directly to a step or junction. Step must be in the list of flow steps.
         * A check is performed to determine if the target step is accessible
         *
         * @param {String|Object} step - target step
         * @param {Object} [_model] - object passed to transition functions, if undefined then the snapshot is used.
         * @param {Object} [_extraArgs]   - Map of arguments passed to branch functions and step listeners
         * @param {Boolean} [force] - perform transition bypassing any checks and execute step listener functions
         *                              even in the case where the target step is the same as the current step.
         *                              Defaults to false.
         *
         * @throws {Error} if step does not reference a step in the list of ordered steps
         * @throws {Error} if transition to step is not allowed
         *
         * @returns {Promise} Returned promise is resolved when cancel confirmed or rejected if cancel is dismissed
         *
         */
        jumpToStep(step, _model, _extraArgs, force) {
            const targetStep = FlowUtil.getStepObject(step, flow);
            if (!targetStep) {
                throw new Error('Target step does not exist in the flow', targetStep);
            }
            const fromStep = this.currentStep;

            function transitionToStep(modelOrSnapshot) {
                const targetStepIndex = _.findIndex(stepHistory, aStep => aStep.isSimilarTo_AND(targetStep)); // ANDIE
                stepHistory = stepHistory.slice(0, targetStepIndex + 1);
                flow.currentStep = targetStep;
                executeStepChangeListeners(modelOrSnapshot, fromStep, targetStep, _extraArgs);
                publishWizardEvent('jumpToStep', modelOrSnapshot, fromStep, flow.currentStep);
            }

            function checkForIntermediateGateFns(modelOrSnapshot) {
                const currentStepIndex = _.findIndex(stepHistory, aStep => aStep.name === fromStep.name);
                const targetStepIndex = _.findIndex(stepHistory, aStep => aStep.name === targetStep.name);
                if (targetStepIndex < currentStepIndex) {
                    const intermediateSteps = stepHistory.slice(targetStepIndex, currentStepIndex + 1);
                    // return true if there are any gate steps that evaluate to falsey between the current step and the target
                    return intermediateSteps.some(interStep => {
                        const hasPreviousHandler = interStep.onPrevious();
                        return (hasPreviousHandler) ? !hasPreviousHandler(modelOrSnapshot) : false;
                    });
                }
                return false;
            }

            _model = _model || this.snapshot;

            if (force !== true && (!_jumpToStepCheck(this, this.currentStep, targetStep, _model) || checkForIntermediateGateFns(_model))) {
                // transition not allowed
                return false;
            }

            if (targetStep.name === this.currentStep.name && !force) {
                // nothing to do
                return;
            } else if (!modalBehavior.snapshotResetConfirmation || (targetStep.name === this.currentStep.name && force)) {
                transitionToStep(_model);
                return;
            }

            return modalBehavior.snapshotResetConfirmation(_model)
            // model is valid so proceed without resetting to snapshot
                .then(model => transitionToStep(model))
                .catch(error => {
                    if (error === 'cancelled') {
                        // navigation cancelled
                        return false;
                    }
                    // confirmed reset to snapshot and proceed with navigation
                    transitionToStep(this.snapshot);
                });
        },


        /**
         * Transition to exit step or junction, calling event listeners
         * A check is performed to determine if the target is an exit point (has not onNext handler)
         *
         * @param {String|Object} exitStepOrJunction - target
         * @param {Object} [_model] - object passed to transition functions, if undefined then the snapshot is used.
         * @param {Object} [_extraArgs]   - Map of arguments passed to branch functions and step listeners
         *
         * @throws {Error} if stepOrJunction does not reference a step or junction in the flow exit point
         * @throws {Error} if transition to step or junction step is not allowed
         *
         */
        jumpToExit(exitStepOrJunction, _model, _extraArgs = {}) {
            exitStepOrJunction = FlowUtil.getStepOrJunctionObject(exitStepOrJunction, flow);

            if (!exitStepOrJunction) {
                throw new Error('Target step does not exist in the flow', exitStepOrJunction);
            }
            if (!FlowUtil.isExitPoint(exitStepOrJunction, flow)) {
                throw new Error('Target step does is not an exit point, contains a onNext handler', exitStepOrJunction);
            }
            flow.currentStep = exitStepOrJunction;
            executeStepChangeListeners(_model, exitStepOrJunction, exitStepOrJunction, _extraArgs);
        },

        /**
         * Prompts the user to cancel the flow. Assumes that the flow has a 'cancel' junction or step.
         *
         * @param {Object} [_model] - object passed to transition functions, if undefined then the snapshot is used.
         * @param {Object} [_extraArgs]   - Map of alternative cancel messages title: message: description:
         *
         * @throws {Error} if flow has no cancel step or junction
         *
         * @returns {Promise}
         *
         */
        cancel(_model, _extraArgs = {}) {
            const cancelPoint = FlowUtil.getStepOrJunctionObject('cancel', flow);
            if (!cancelPoint) {
                throw new Error('Flow requires a cancel exit junction to be defined');
            }
            if (typeof cancelPoint.data().confirmationMsg === 'function') {
                _extraArgs.title = cancelPoint.data().confirmationMsg(_model).title;
                _extraArgs.message = cancelPoint.data().confirmationMsg(_model).message;
                _extraArgs.type = cancelPoint.data().confirmationMsg(_model).type;
            }
            if (typeof cancelPoint.data().confirmationModalArgs === 'function') {
                Object.assign(_extraArgs, cancelPoint.data().confirmationModalArgs());
            }
            const previousStep = flow.currentStep;
            return modalBehavior.cancelConfirmation(_model, _extraArgs)
                .then(snapshotOrModel => {
                    const transactionPromise = goToStepForEvent(this, 'onNext', snapshotOrModel, cancelPoint, _extraArgs);
                    publishWizardEvent('cancel', _model, previousStep);
                    return transactionPromise;
                })
                .catch(() => {
                    // cancel transition declined so don't perform transition
                    return Q.reject();
                });
        },

        // ANDIE - End Session without Confirmation Dialog
        exitEmailSession_AND(_model) {
            this.jumpToExit('returnHome', _model);
        }
    };
}
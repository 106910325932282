import RouteConfig from './config/RouteConfig';
import PolicyList from './directives/PolicyList';
import EffectiveDate from './directives/EffectiveDate';
import shoppingCart from './directives/ShoppingCart';
import endorsementLobFlow from 'integration/endorsement/directives/EndorsementLobFlow';
import endorsementSelectChanges from 'integration/endorsement/directives/EndorsementSelectChanges';
import EndorsementHistory from 'integration/endorsement/directives/EndorsementHistory';
import EndorsementPolicyIcon from 'integration/endorsement/directives/EndorsementPolicyIcon';
import LobUtil from 'integration/endorsement/LobUtil';
import GenericProductCodeUtil from 'integration/endorsement/GenericProductCodeUtil';
import PolicyChange from 'edge/endorsement/common/models/PolicyChange';
import EditableCoverages from './directives/EditableCoverages';
import EndorsementService from 'edge/endorsement/common/services/EndorsementService';
import EndorsementNumberOfChanges from 'edge/endorsement/common/services/EndorsementNumberOfChanges';
import ShoppingCartIcon from './directives/ShoppingCartIcon';
import ChangeBox from './directives/ChangeBox';
import ChoiceButton from './directives/ChoiceButton';
import WizardPageActionsMTA from './directives/wizard-page-actions-mta_AND/WizardPageActionsMTA'; // ANDIE
import 'angularRouter';
import 'edge/platform/map/module';
import 'edge/platform/base/module';
import 'edge/platform/widgets/modal/module';
import 'edge/platform/widgets/inputs/module';
import 'edge/platform/widgets/loading/module';
import 'edge/platform/widgets/documentupload/module';
import 'edge/platform/contacts/module';
import 'gw-portals-viewmodel-angular/aspects/module';
import 'edge/platform/widgets/basicinputs/module';
import 'edge/platform/flow/module';
import 'edge/platform/widgets/wizard/module';
import 'edge/platform/widgets/datetile/module';
import 'portalConfig';
import 'edge/policyjob/app';

export default angular.module('edge.endorsement.common.app', [
    'portal.config',
    'edge.platform.base',
    'edge.platform.widgets.inputs',
    'ui.router',
    'edge.platform.map',
    'edge.platform.widgets.loadingMsg',
    'edge.platform.widgets.modal',
    'edge.platform.widgets.documentupload',
    'edge.platform.contacts',
    'gw-portals-viewmodel-angular.aspects',
    'edge.platform.widgets.basicinputs',
    'edge.platform.flow',
    'edge.platform.widgets.wizard',
    'edge.platform.widgets.datetile',
    'edge.policyjob.app'
])
    .factory('endorsement.EndorsementService', EndorsementService)
    .factory('endorsement.NumberOfChanges', EndorsementNumberOfChanges)
    .factory('endorsement.GenericProductCodeUtil', [() => GenericProductCodeUtil])
    .factory('endorsement.LobUtil', [() => LobUtil])
    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new RouteConfig(RootStateConfig);
        for (const stateName in states) {
            if (states.hasOwnProperty(stateName)) {
                $stateProvider.state(stateName, states[stateName]);
            }
        }
    }])
    .directive('gwEndorsementPolicyList', PolicyList)
    .directive('gwEndorsementEffectiveDate', EffectiveDate)
    .directive('gwShoppingCart', shoppingCart)
    .directive('gwEndorsementLobFlow', endorsementLobFlow)
    .directive('gwEndorsementSelectChanges', endorsementSelectChanges)
    .directive('gwAmpEditableCoverages', EditableCoverages)
    .directive('gwEndorsementLobHistory', EndorsementHistory)
    .directive('endorsementShoppingCartIcon', ShoppingCartIcon)
    .directive('endorsementChangeBox', ChangeBox)
    .directive('gwEndorsementPolicyIcon', EndorsementPolicyIcon)
    .directive('choiceButton', ChoiceButton)
    .directive('andWizardPageActionsMta', WizardPageActionsMTA)
    .config(['EventHubProvider', EventHubProvider => {
        EventHubProvider.registerTransformer({
            source: {
                category: 'tracking',
                eventName: 'serverCall'
            },
            target: {
                category: 'tracking',
                eventName: 'journey'
            },
            transformer(payload, send) {
                if (!payload.success) return;
                if (payload.path === 'policychange/policychange' && payload.method === 'bind') {
                    const jsonResponse = payload.response;
                    const change = new PolicyChange(jsonResponse.policyChange);
                    const baseEvent = {
                        eventName: 'Policy Changed',
                        accountNumber: change.accountNumber,
                        jobID: change.jobID,
                        policyNumber: change.policyNumber,
                        productCode: change.productCode,
                        transactionCost: change.transactionCost,
                        totalCost: change.totalCost,
                        previousTotalCost: change.previousTotalCost,
                        effectiveDate: change.effectiveDate
                    };
                    send(baseEvent);
                }
            }
        });
    }]);
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import _ from 'lodash';

const fn = ($scope, $stateParams, UsersProfileDetailsService, $state, ModalService, $filter, ViewModelService, $q) => {
    const ctrl = Object.create(BaseCtrl);
    let backUpAccountInfo;
    let backupBillingInfo;

    ctrl.init = () => {
        $scope.accountsDetails;
        $scope.state = {
            applyToAllAccounts: false
        };
        const sortByAccountNumber = (a, b) => {
            return a.accountNumber > b.accountNumber;
        };

        const accInfoRequest = UsersProfileDetailsService.getAccountsContactSummaries();
        const billingDeliveryRequest = UsersProfileDetailsService.getInvoiceDeliveryMethods();
        $scope.showProfilePromise = $q.all([accInfoRequest, billingDeliveryRequest]);

        $scope.showProfilePromise.then(
            (result) => {
                const accountsInfo = result[0].sort(sortByAccountNumber);
                const billingTypeInfo = result[1].sort(sortByAccountNumber);
                $scope.accountsDetails = new Array(accountsInfo.length);
                $scope.isMultiAccount = accountsInfo.length > 1;
                $scope.accountsInfo = accountsInfo.map(accountInfo => ViewModelService.create(accountInfo, 'pc', 'edge.capabilities.profileinfo.user.dto.AccountSummaryDTO'));

                accountsInfo.forEach((accountInfo, index) => {
                    $scope.accountsDetails[index] = {
                        billingPreference: billingTypeInfo[index].invoiceDeliveryType,
                        originalBillingAddress: angular.copy(accountInfo.billingContact),
                        originalAccountAddress: angular.copy(accountInfo.accountContact),
                        originalBillingSame: accountInfo.isBillingAddressSame
                    };
                });
            },
            () => {
                $scope.errorLoading = true;
            }
        );

        resetState();

    };

    ctrl.billingPreferenceValid = (index) => {
        if ($scope.accountsDetails[index].billingPreference === 'mail') {
            return true;
        }

        let billingEmailAddress;
        const isBillingAddressSame = $scope.accountsInfo[index].isBillingAddressSame.value;

        if (isBillingAddressSame) {
            billingEmailAddress = $scope.accountsInfo[index].accountContact.emailAddress1.value;
        } else {
            billingEmailAddress = $scope.accountsInfo[index].billingContact.emailAddress1.value;
        }

        return $scope.accountsDetails[index].billingPreference === 'paperless' && billingEmailAddress;
    };

    const copyContactProperties = (index, accountContact, sourceAccountContact, billingPreference) => {
        Object.assign(sourceAccountContact, {
            cellNumber: accountContact.cellNumber,
            primaryPhoneType: accountContact.primaryPhoneType,
            emailAddress1: accountContact.emailAddress1,
            workNumber: accountContact.workNumber,
            homeNumber: accountContact.homeNumber,
            primaryAddress: {
                addressLine1: accountContact.primaryAddress.addressLine1,
                addressLine2: accountContact.primaryAddress.addressLine2,
                addressLine3: accountContact.primaryAddress.addressLine3,
                city: accountContact.primaryAddress.city,
                postalCode: accountContact.primaryAddress.postalCode,
                state: accountContact.primaryAddress.state,
                country: accountContact.primaryAddress.country,
            },
            billingPreference: billingPreference
        });
    };

    ctrl.updateAllAccounts = (accountContact, billingContact, billingPreference, isBillingAddressSame) => {
        const updateCalls = [];
        $scope.accountsInfo.forEach((account, index) => {
            copyContactProperties(index, accountContact, $scope.accountsInfo[index].value.accountContact, billingPreference);
            $scope.accountsInfo[index].value.isBillingAddressSame = isBillingAddressSame;
            $scope.accountsDetails[index].billingPreference = billingPreference;

            if (!isBillingAddressSame) {
                copyContactProperties(index, billingContact, $scope.accountsInfo[index].value.billingContact, billingPreference);
            } else {
                copyContactProperties(index, accountContact, $scope.accountsInfo[index].value.billingContact, billingPreference);
            }

            updateCalls.push(UsersProfileDetailsService.updateAccountContactSummary($scope.accountsInfo[index].value));
            updateCalls.push(UsersProfileDetailsService.setInvoiceDeliveryMethodForAccount(billingPreference, $scope.accountsInfo[index].value.accountNumber));
        });

        $q.all(updateCalls).then(
            () => {
                resetState();
            },
            () => {
                console.error('PC Update Failed');
                $scope.errorSaving = true;
            }
        );
    };


    ctrl.updateContactDetails = (form, index) => {
        form.submitted = true;
        const updateCalls = [];

        if (!$scope.billingPreferenceValid(index)) {
            const title = 'usersprofile.views.amp-users-profile.Invalid Paperless Billing Settings';
            const msg = 'usersprofile.views.amp-users-profile.A valid email address is required for paperless billing';

            ModalService.showError(
                title,
                msg,
                null,
                $filter('translate')('userprofile.controllers.usersprofilectrl.Ok', 'gw-btn-primary')
            );
            return;
        }

        if (form.invalid || !$scope.accountsInfo[index].aspects.valid) {
            return;
        }

        if ($scope.state.applyToAllAccounts) {
            ctrl.updateAllAccounts($scope.accountsInfo[index].value.accountContact, $scope.accountsInfo[index].value.billingContact, $scope.accountsDetails[index].billingPreference, $scope.accountsInfo[index].value.isBillingAddressSame);
            return;
        }

        if (ctrl.addressChangedInForm(index)) {
            const updateAddress = UsersProfileDetailsService.updateAccountContactSummary($scope.accountsInfo[index].value);
            updateCalls.push(updateAddress);
        }

        if (ctrl.billingMethodChangedInForm(index)) {
            const updateBillingMethod = UsersProfileDetailsService.setInvoiceDeliveryMethodForAccount($scope.accountsDetails[index].billingPreference, $scope.accountsInfo[index].value.accountNumber);
            updateCalls.push(updateBillingMethod);
        }

        $scope.showProfilePromise = $q.all(updateCalls);

        $q.all(updateCalls).then(
            () => {
                resetState();
            },
            () => {
                console.error('PC Update Failed');
                $scope.errorSaving = true;
            }
        );
    };

    ctrl.billingMethodChangedInForm = (index) => {
        return $scope.accountsDetails[index].originalBillingAddress !== $scope.accountsDetails[index].billingPreference;
    };

    ctrl.addressChangedInForm = (index) => {
        const accountAddressSame = _.isEqual($scope.accountsDetails[index].originalAccountAddress, $scope.accountsInfo[index].accountContact.value);
        const billingAddressSame = _.isEqual($scope.accountsDetails[index].originalBillingAddress, $scope.accountsInfo[index].billingContact.value);
        const billingStatusSame = _.isEqual($scope.accountsDetails[index].originalBillingSame, $scope.accountsInfo[index].isBillingAddressSame.value);
        return !(accountAddressSame && billingAddressSame && billingStatusSame);
    };

    ctrl.cancel = () => {
        const title = 'userprofile.controllers.usersprofilectrl.Cancel contact information update';
        const msg = 'userprofile.controllers.usersprofilectrl.Are you sure you want to cancel your account contact profile changes';

        const warningPromise = ModalService.showWarning(
            title, msg, null,
            $filter('translate')('userprofile.controllers.usersprofilectrl.Yes'), 'gw-btn-primary',
            $filter('translate')('userprofile.controllers.usersprofilectrl.No')
        ).result;
        warningPromise.then(() => {
            if ($scope.state.accountInEditStateIndex >= 0 && backUpAccountInfo) {
                $scope.accountsInfo[$scope.state.accountInEditStateIndex].value = backUpAccountInfo;
                $scope.accountsDetails[$scope.state.accountInEditStateIndex] = backupBillingInfo;
            }

            resetState();
        });
    };

    ctrl.isReadOnly = (index) => {
        return $scope.state.accountInEditStateIndex !== index;
    };

    ctrl.isReadOnlyBlocked = (index) => {
        return $scope.state.accountInEditStateIndex >= 0 && ctrl.isReadOnly(index);
    };

    ctrl.hasPoliciesInForce = (accountInfo) => {
        return accountInfo.relatedPolicies.length > 0;
    };

    ctrl.changeToAccountEditState = function (index) {
        $scope.state.applyToAllAccounts = false;
        $scope.state.inViewMode = false;
        $scope.state.accountInEditStateIndex = index;
        backUpAccountInfo = angular.copy($scope.accountsInfo[index].value);
        backupBillingInfo = angular.copy($scope.accountsDetails[index]);
    };

    return ctrl.create($scope);

    function resetState() {
        $scope.state.inViewMode = true;
        $scope.state.accountInEditStateIndex = -1;

        backUpAccountInfo = undefined;
        backupBillingInfo = undefined;
    }
};
fn.$inject = ['$scope', '$stateParams', 'UsersProfileDetailsService', '$state', 'ModalService', '$filter', 'ViewModelService', '$q'];

export default fn;

export default ['$document', ($document) => {
    const SCROLL_SPEED = 800; // (ms)
    return {
        /**
         * Sets scroll position to the top of the window.
         */
        scrollToTop: () => {
            $document[0].body.scrollTop = 0;
            $document[0].documentElement.scrollTop = 0;
        },
        /**
         * Scrolls to an element.
         * @param {Object} element jqLite Element
         * @param {int} [yOffset] Optional y-axis offset.
         * @param {String} [speed] Scroll speed. Defaults to slow.
         * @returns {*|jQuery}
         */
        scrollToElement: (element, yOffset, speed)=> {
            const scrollSpeed = speed || SCROLL_SPEED;
            yOffset = angular.isDefined(yOffset) ? yOffset : 0;
            const scrollTop = element.offset().top - yOffset;
            return $('html,body').animate({scrollTop}, scrollSpeed);
        },
    };
}];
import LanguageSelectDirective from 'edge/platform/l10n/directives/LanguageSelectDirective';
import LanguageExpandosDirective from 'edge/platform/l10n/directives/LanguageExpandosDirective';
import DisplaykeyFormatterDirective from 'edge/platform/l10n/directives/DisplaykeyFormatterDirective';
import CurrencyFilter from 'edge/platform/l10n/CurrencyFilter';
import AbsCurrencyFilter_AND from 'edge/platform/l10n/AbsCurrencyFilter_AND';
import LocaleService from 'edge/platform/l10n/LocaleService';
import 'gw-portals-featuredetection-js/ApplicationFeatures';
import TranslateService from 'edge/platform/l10n/TranslateService';
import DateFormatter from 'edge/platform/l10n/DateFormatter';
import localeJSON from 'app-locale';
import 'portalConfig';
import 'angular-cookies';
import 'angular-translate';
import 'angular-translate-storage-cookie';
import 'angular-translate-storage-local';
import 'angular-translate-loader-static-files';
import 'angular-translate-handler-log';
import 'angular-dynamic-locale';
import 'angular-sanitize';

export default angular.module('edge.platform.l10n', [
    // start l10n
    'ngCookies',
    'pascalprecht.translate',
    'portal.config',
    'tmh.dynamicLocale',
    'ngSanitize'
])
    .directive('gwPlLanguageSelect', LanguageSelectDirective)
    .directive('gwPlLanguageExpandos', LanguageExpandosDirective)
    .directive('gwDisplaykeyFormat', DisplaykeyFormatterDirective)
    .service('LocaleService', LocaleService)
    .service('DateFormatter', DateFormatter)
    .filter('gwCurrency', CurrencyFilter)
    .filter('andAbsCurrency', AbsCurrencyFilter_AND)
    .constant('LOCALE_CHANGE_EVENT_NAME', 'gw-locale-change-event')
    .constant('DEBUG_MODE', /* DEBUG_MODE_START*/ true /* DEBUG_MODE_END*/)
    .constant('ANGULAR-I18N', /* ANGULAR-I18N_START*/ '../js/i18n/angular-i18n' /* ANGULAR-I18N_END*/)
    .constant('LOCALE_INFO', localeJSON)
    // Angular debug info
    .config(['$compileProvider', 'DEBUG_MODE', ($compileProvider, DEBUG_MODE) => {
        if (!DEBUG_MODE) {
            $compileProvider.debugInfoEnabled(false); // disables AngularJS debug info
        }
    }])
    .config([
        '$translateProvider', 'ASYNC_TRANSLATIONS', 'LOCALE_INFO', 'TRANSLATE_PROVIDER_PREFIX', ($translateProvider, ASYNC_TRANSLATIONS, LOCALE_INFO, TRANSLATE_PROVIDER_PREFIX) => {
            const DEFAULT_FALLBACK_LOCALE = 'en_US';
            if (__DEV__ || __TEST__) {
                $translateProvider.useMissingTranslationHandlerLog(); // warns about missing translates
            }

            if (ASYNC_TRANSLATIONS) {
                $translateProvider.useStaticFilesLoader({
                    prefix: TRANSLATE_PROVIDER_PREFIX || '../js/i18n/translations/',
                    suffix: '.json'
                });
            }

            $translateProvider.useSanitizeValueStrategy(null); // http://angular-translate.github.io/docs/#/guide/19_security
            // fallback language needs to be set before the preferred language due to $translateProvider implementation
            $translateProvider.fallbackLanguage(LOCALE_INFO.fallbackLocale || DEFAULT_FALLBACK_LOCALE);
            $translateProvider.preferredLanguage(LOCALE_INFO.preferredLocale);
            $translateProvider.useLocalStorage();
        }
    ])
    // Angular Dynamic Locale
    .config(['tmhDynamicLocaleProvider', 'ANGULARI18N_PRESET', 'ANGULAR-I18N', (tmhDynamicLocaleProvider, ANGULARI18N_PRESET, ANGULARI18N) => {
        // ANGULARI18N_PRESET is added to reset angular locales path from 3rd party "Portals" (e.g. Components Examples)
        tmhDynamicLocaleProvider.localeLocationPattern(`${ANGULARI18N_PRESET || ANGULARI18N}/angular-locale_{{locale}}.js`);
    }])
    .service('TranslateService', TranslateService);

import _ from 'underscore';
import BaseCtrl from 'edge/platform/base/BaseCtrl';
import PolicySearchUtil from 'gw-portals-util-angular/PolicySearchUtil';

const fn = (TranslateService, $scope, $q, claimViewModel, PolicyService, ModalService) => {
    const ctrl = Object.create(BaseCtrl);
    let lossCauseTypesArray;

    ctrl.init = function () {
        $scope.claimVm = claimViewModel();
        if (!$scope.claimVm.lossCause.value) { // if there is no lossCause, lossDate is set to null
            $scope.claimVm.lossDate.value = null;
        }
        lossCauseTypesArray = $scope.claimVm.lossCause.aspects.availableValues;

        if ($scope.claimVm.claimNumber.value) {
            $scope.fnol.selectedPolicy = $scope.claimVm.policy.value;
            $scope.fnol.policyNumber = $scope.fnol.selectedPolicy.policyNumber.value;
        } else {
            $scope.fnol.policyNumber = $scope.fnol.policyNumber || null;
            $scope.fnol.selectedPolicy = $scope.fnol.selectedPolicy || null;
        }
        $scope.fnol.policySearch = $scope.fnol.policySearch || {};

        if ($scope.claimVm.lossDate.value !== null && $scope.fnol.selectedPolicy === null) {
            $scope.searchState = 'loading'; // if LossDate is not null and selectedPolicy is equal to null, loading state
        }
        $scope.fnol.policySearchType = $scope.fnol.policySearchType || 'policyNumber';

        $scope.policyTypeChange = null;

        $scope.policyContext.isInFnolWizard = true; // ANDIE
        $scope.claimVm.lossType = 'AUTO';
        $scope.claimVm.lossCause = 'Accident_itb';
    };

    ctrl.defineWatches = function () {
        /**
         * Watches the loss date to re-run the search when the loss date changes
         */
        $scope.$watch('claimVm.lossDate.value', (newDate, oldDate) => {
            if (!newDate || (oldDate && newDate && oldDate.getTime() === newDate.getTime())) {
                return;
            }
            ctrl._getPolicyholderPolicies();
        });
    };

    ctrl.setSelected = function (item) {
        if ($scope.fnol.selectedPolicy && item && $scope.fnol.selectedPolicy.policyType !== item.policyType) {
            $scope.policyTypeChange = true;
        }
        $scope.fnol.selectedPolicy = item;
    };

    ctrl.selectLossCause = (clickedTileCode) => {
        $scope.claimVm.lossCause.value = _.find(lossCauseTypesArray, (cause) => {
            return cause.code === clickedTileCode;
        });
    };
    ctrl.isCollisionSelected = () => {
        return !!_.findWhere($scope.fnol.collisionTypeCodes, {
            'code': $scope.claimVm.lossCause.value.code
        });
    };

    /**
     * POLICYHOLDER
     * @param {Function} flowModelNext
     *
     * Called when the user clicks the next button to progress to the next page
     */
    ctrl.validatePolicyAndProceed = function (flowModelNext) {
        let modalPromise;
        const policyChanged = $scope.claimVm.policy.policyNumber && $scope.claimVm.policy.policyNumber.value !== $scope.fnol.selectedPolicy.policyNumber;

        if (policyChanged) {
            modalPromise = ModalService.showConfirm(
                'fnol.common.controllers.PolicySelectPolicyholderCtrl.Modal Title',
                'fnol.common.controllers.PolicySelectPolicyholderCtrl.Modal Content', undefined,
                TranslateService.instant('fnol.common.controllers.PolicySelectPolicyholderCtrl.Modal Option')
            ).result;
        } else {
            modalPromise = $q.resolve($scope.claimVm);
        }

        modalPromise.then(() => {
            $scope.claimVm.value.changePolicy($scope.fnol.selectedPolicy);
            flowModelNext($scope.claimVm, {
                policyChanged: policyChanged
            });
        }).catch(() => {
            flowModelNext();
        });
    };

    ctrl.isNextEnabled = function () {
        /* Search is active or found no results. */
        if ($scope.searchState !== 'policiesFound') {
            return false;
        }

        if (!$scope.fnol.selectedPolicy) {
            return false;
        }
        if (PolicySearchUtil.dateIsInFuture($scope.claimVm.lossDate.value)) {
            return false;
        }
        if (!$scope.claimVm.lossDate.aspects.valid) {
            return false;
        }

        /* Ensure that policy was selected from a search result and it is not a some old policy. */
        return _.findWhere($scope.fnol.policies, {
            policyNumber: $scope.fnol.selectedPolicy.policyNumber
        });
    };

    ctrl._getSearchCriteria = function () {
        return {
            lossDate: $scope.claimVm.lossDate.value
        };
    };

    ctrl._getPolicyholderPolicies = function () {
        const searchCriteria = ctrl._getSearchCriteria();
        const progress = PolicyService.searchPolicies(searchCriteria);
        progress.then((policies) => {
            if (!policies) {
                policies = [];
            }
            $scope.fnol.policies = [];

            /* Are we sure to filter policyholder's policies by his LOB??? */
            const filteredPolicies = policies;

            $scope.fnol.policies = filteredPolicies;
            $scope.searchState = filteredPolicies.length > 0 ? 'policiesFound' : 'noPolicies';

            if ($scope.fnol.policyNumber) {
                $scope.fnol.selectedPolicy = _.findWhere(filteredPolicies, {
                    policyNumber: $scope.fnol.policyNumber
                });
            } else if (filteredPolicies.length === 1) {
                $scope.fnol.selectedPolicy = filteredPolicies[0];
            }
        }, () => {
            $scope.errorMsg = TranslateService.instant('fnol.common.controllers.PolicySelectPolicyholderCtrl.Policy Search Error');
        });
    };

    return ctrl.create($scope);
};

fn.$inject = ['TranslateService', '$scope', '$q', 'claimViewModel', 'fnol.PolicyService', 'ModalService'];
export default fn;

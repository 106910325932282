const CACHE = {};

/**
 * ANDIE
 * A simple cache service meant for JSON-RPC requests.
 */

export default ['$q', ($q) => {
    return {
        /**
         * @param {string} method JSON-RPC method.
         * @param {array} keys Array of arguments to the JSON-RPC method.
         * @param {function} promise JSON-RPC call.
         * @returns {*} A promise with either the cached result if it's a hit, or the server result.
         */
        cacheAware: (method, keys, promise) => {
            if (!CACHE[method]) {
                CACHE[method] = {};
            }
            const deferred = $q.defer(); // $q is required to trigger a digest
            const key = keys.reduce((acc, value) => `${acc}.${value}`); // Reduce arguments into '.' separated key
            if (angular.isDefined(CACHE[method][key])) {
                deferred.resolve(CACHE[method][key]);
            } else {
                promise()
                    .then((response) => {
                        CACHE[method][key] = response;
                        deferred.resolve(response);
                    })
                    .catch((error, status, headers) => deferred.reject([error, status, headers]));
            }
            return deferred.promise;
        }
    };
}];
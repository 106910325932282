import templateStr from 'text!./pm-contact-us-mobile.html';

export default [() => {
    return {
        restrict: 'A',
        scope: {},
        template: templateStr,
        controller: ['$rootScope', ($rootScope) => {
            $rootScope.$broadcast('andMoveLiveChat', 'andLiveChatMobile');
        }]
    };
}];
import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./AdditionalEnrollment.html';
import styles from './AdditionalEnrollment-hashed.scss';
// VARS
const hashedTemplate = cssUtil.hashTemplate(template, styles);

// EXPORT
export default {
    template: hashedTemplate,
    controller: [
        '$scope', '$q', '$state', 'state_plugin_config', 'enrollment.EnrollmentService', 'gwAuthTokenService',

        function ($scope, $q, $state, STATE_PLUGIN_CONFIG, EnrollmentService, AuthTokenService) {
            // VARS
            const $ctrl = this;
            $ctrl.isMultiaccount = EnrollmentService.isMultiaccount();
            $ctrl.isEnrollmentReqInProgress = false;// animation
            $ctrl.input = {aspects: {}};
            $ctrl.enrollmentResponse = {};
            $ctrl.enrollmentStatus = '';
            $ctrl.existingAuthorities = [];
            AuthTokenService.grantedAuthorities.then((grantedAuthorities) => {
                $ctrl.existingAuthorities = grantedAuthorities;
            });

            // METHODS
            const setCtrlParam = (paramName, value) => {
                $scope.$applyAsync(() => {
                    $ctrl[paramName] = value;
                });
            };

            function setEnrollmentStatus(value) {
                setCtrlParam('enrollmentStatus', value);
            }

            $ctrl.enrolUser = () => {
                if (!$ctrl.policyNumber || !$ctrl.addressLine1) { // check if empty
                    setEnrollmentStatus('fieldEmpty');
                    return;
                }

                setCtrlParam('isEnrollmentReqInProgress', true);// animation

                EnrollmentService.addEnrollmentRecord({
                    details: {
                        policyNumber: $ctrl.policyNumber,
                        addressLine1: $ctrl.addressLine1
                    },
                    type: $ctrl.isMultiaccount ? 'ACCOUNT' : 'POLICY'
                }).then((resp) => {
                    return AuthTokenService.refreshAccessToken(true).then(() => {
                        setCtrlParam('isEnrollmentReqInProgress', false);// animation
                        $ctrl.policyNumber = null;
                        $ctrl.addressLine1 = null;
                        $ctrl.enrollmentResponse.response = resp;
                        setEnrollmentStatus('enrollmentRequestSucceeded', true);
                    });
                }).catch((error) => {
                    setCtrlParam('isEnrollmentReqInProgress', false);// animation
                    if (error && error.baseError && error.baseError.error.message === '616') {
                        setEnrollmentStatus('enrollmentInvalid');
                    } else {
                        setEnrollmentStatus('enrollmentFailed');
                    }
                });
            };

            $ctrl.goBackToLandingPage = () => {
                const nextState = $state.$current.data.actions.home;
                $state.transitionTo(nextState);
            };

            $ctrl.accessGrantedLabelKey = () => {
                if ($ctrl.enrollmentResponse.response.length > 1) {
                    return 'enrollment.components.additionalenrollment.You now have access to your policies:';
                }
                return 'enrollment.components.additionalenrollment.You now have access to your policy:';
            };

            $ctrl.enrollmentInstructionsLabelKey = () => {
                if ($ctrl.isMultiaccount) {
                    return 'enrollment.components.additionalenrollment.Related policies will be added automatically';
                }
                return 'enrollment.components.additionalenrollment.Simply complete all fields for online access to your policy information';

            };

        }]
};
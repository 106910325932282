class PaginatioNavigationCtrl {
    constructor() {
        this.pageNum = 0;
        this.totalPages = 0;
        this.showElement = false;
    }

    hasNext() {
        return this.pageNum < this.totalPages - 1;
    }

    hasPrevious() {
        return this.pageNum > 0;
    }

    previous() {
        this.pageNum -= 1;
        this.load();
    }

    next() {
        this.pageNum += 1;
        this.load();
    }

    $onChanges({reset, sharedPageNum, externalDisplayPagination}) {
        if (reset && reset.currentValue !== reset.previousValue) {
            this.pageNum = 0;
            this.totalPages = 0;
            this.load();
        }

        if (sharedPageNum != null
            && sharedPageNum.currentValue != null
            && sharedPageNum.currentValue !== sharedPageNum.previousValue
            && sharedPageNum.currentValue !== this.pageNum) {

            this.pageNum = sharedPageNum.currentValue;
            this.load();
        }

        if (externalDisplayPagination
            && typeof externalDisplayPagination.previousValue === 'boolean'
            && externalDisplayPagination.currentValue !== externalDisplayPagination.previousValue) {

            this.showElement = externalDisplayPagination.currentValue;
        }
    }

    load() {
        const currentPage = this.pageNum;
        const indexStart = currentPage * this.pageSize;

        // pagination is one based
        const startPos = indexStart + 1;
        const endPos = indexStart + this.pageSize;

        return this.loadByOffset({offsetStart: startPos - 1, offsetEnd: endPos - 1})
            .then(paginatedData => {
                this.startPos = startPos;
                this.endPos = indexStart + paginatedData.items.length;
                this.totalPages = Math.ceil(paginatedData.maxNumberOfResults / this.pageSize);
                this.totalItems = paginatedData.maxNumberOfResults;
                this.onPaginationNavigation({paginatedData: paginatedData, pageNum: this.pageNum, totalPages: this.totalPages});

                if (this.externalDisplayPagination === undefined) {
                    this.showElement = this.totalPages > 1;
                } else {
                    this.showElement = this.externalDisplayPagination;
                }
            });
    }
}

export default PaginatioNavigationCtrl;

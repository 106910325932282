import _ from 'underscore';
import RewardsShopConfig from 'edge/policy/rewards-shop_AND/config/RouteConfig';
import RewardsService from './services/RewardsService';
import RewardsShop from './directives/rewards-shop/RewardsShop';
import RewardsShopOff from './directives/rewards-shop-off/RewardsShopOff';
import RewardsConfirmation from './directives/rewards-confirmation/RewardsConfirmation';
import RewardsPointsYear from './directives/reward-points-year/RewardPointsYear';
import RewardsHistory from './directives/rewards-history/RewardsHistory';
import PurchaseProduct from './directives/purchase-product/PurchaseProduct';
import RewardsPurchaseError from './directives/rewards-purchase-error/RewardsPurchaseError';


const appModule = angular.module('edge.and.rewards-shop.app', [])
    .factory('RewardsService_AND', RewardsService)

    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new RewardsShopConfig(RootStateConfig);
        _.values(states).forEach(state => {
            $stateProvider.state(state);
        });
    }])
    .directive('andRewardsShop', RewardsShop)
    .directive('andRewardsShopOff', RewardsShopOff)
    .directive('andRewardsConfirmation', RewardsConfirmation)
    .directive('andRewardsPointsYear', RewardsPointsYear)
    .directive('andRewardsHistory', RewardsHistory)
    .directive('andPurchaseProduct', PurchaseProduct)
    .directive('andRewardsPurchaseError', RewardsPurchaseError);

export default appModule;

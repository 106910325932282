import templateStr from 'text!./change-correspondence-address.html';

export default ['$filter', '$rootScope', '$timeout', 'UserProfileService_AND', 'ViewModelService',
    ($filter, $rootScope, $timeout, UserProfileService_AND, ViewModelService) => {
        const API_ERROR = $filter('translate')('and.directives.change-correspondence-address.APIError');

        return {
            restrict: 'A',
            template: templateStr,
            scope: {},
            controller: ['$scope', ($scope) => {
                let init = {};
                const setAPIError = (message) => {
                    $scope.apiError = message;
                };
                $scope.clearAPIMessage = () => setAPIError(null);
                
                $scope.checkForMultipleCorrAddresses = () => {
                    if ($rootScope.accountContext.accountHolderVM && $rootScope.accountContext.accountHolderVM.value.multipleCorrespondenceAddresses) {
                        $scope.mutlipleCorrAddresses = $rootScope.accountContext.accountHolderVM.value.multipleCorrespondenceAddresses;
                        $scope.displayAddress();
                    }
                };

                $scope.displayAddress = () => {
                    $scope.policyAddress = $rootScope.accountContext.accountHolderVM.correspondenceAddress.value;
                    $scope.policyAddressLine1 = $rootScope.accountContext.accountHolderVM.correspondenceAddress.addressLine1.value;
                    if ($scope.policyAddress.organisationName_itb) {
                        $scope.policyAddressLine1 = `${$scope.policyAddress.organisationName_itb} ${$scope.policyAddressLine1}`;
                    }
                    if ($rootScope.accountContext.accountHolderVM.correspondenceAddress.addressLine2.value) {
                        $scope.policyAddressLine2 = $rootScope.accountContext.accountHolderVM.correspondenceAddress.addressLine2.value;
                    }
                    $scope.displayConcatAddressLine = `${$scope.policyAddressLine1} ${$scope.policyAddressLine2}`;
                    $scope.displayCityAndCounty = `${$rootScope.accountContext.accountHolderVM.correspondenceAddress.city.value}, ${$rootScope.accountContext.accountHolderVM.correspondenceAddress.county.value}`;
                    $scope.displayPostalCode = $rootScope.accountContext.accountHolderVM.correspondenceAddress.postalCode.value;
                };

                $scope.clearSuccessMsg = () => {
                    $scope.isSuccessVisible = false;
                };

                $scope.hasPostalCodeChanged = () => {
                    // 1. AccountHolder's address is not defined, but directive's address is;
                    // 2. AccountHolder's address is defined, directive's address fields differ.
                    return $rootScope.accountContext &&
                        ((!$rootScope.accountContext.accountHolderVM.correspondenceAddress.value &&
                        !!$scope.correspondenceAddressVM.addressLine1.value &&
                        !!$scope.correspondenceAddressVM.postalCode.value) ||
                        ($rootScope.accountContext.accountHolderVM.correspondenceAddress.value &&
                            ($rootScope.accountContext.accountHolderVM.correspondenceAddress.addressLine1.value !== $scope.correspondenceAddressVM.addressLine1.value ||
                                $rootScope.accountContext.accountHolderVM.correspondenceAddress.postalCode.value !== $scope.correspondenceAddressVM.postalCode.value)));

                };
                if ($rootScope.accountContext && $rootScope.accountContext.accountHolderVM) {
                    init = $rootScope.accountContext.accountHolderVM.value.correspondenceAddress || {};
                }
                $scope.correspondenceAddressVM = ViewModelService.create(Object.assign({}, init), 'pc', 'edge.capabilities.address.dto.AddressDTO', null);

                $scope.submit = (form) => {
                    form.submitted = true;
                    $scope.clearAPIMessage();
                    if (form.valid === false) {
                        return;
                    }
                    $scope.inFlight = true;
                    return UserProfileService_AND.updateCorrespondenceAddress($scope.correspondenceAddressVM.value)
                        .then((data) => {
                            $rootScope.accountContext.accountHolderVM = ViewModelService.create(data, 'pc', 'itb.edge.capabilities.profileinfo.user.dto.UserProfileDTO', null);
                            $scope.isSuccessVisible = true;
                            $scope.correspondenceChanged = true;
                            $scope.displayAddress();
                            $timeout(() => { // Clear success message after 10 seconds
                                $scope.clearSuccessMsg();
                            }, 10000);
                        })
                        .catch(() => {
                            setAPIError(API_ERROR);
                        })
                        .finally(() => {
                            $scope.inFlight = false;
                        });
                };
                $scope.$watch(() => {
                    if ($rootScope.accountContext) {
                        return $rootScope.accountContext.accountHolderVM;
                    }
                    return null;
                }, (newValue) => {
                    if (newValue) {
                        $scope.correspondenceAddressVM.addressLine1.value = newValue.correspondenceAddress.addressLine1.value;
                        $scope.correspondenceAddressVM.addressLine2.value = newValue.correspondenceAddress.addressLine2.value;
                        $scope.correspondenceAddressVM.addressLine3.value = newValue.correspondenceAddress.addressLine3.value;
                        $scope.correspondenceAddressVM.city.value = newValue.correspondenceAddress.city.value;
                        $scope.correspondenceAddressVM.county.value = newValue.correspondenceAddress.county.value;
                        $scope.correspondenceAddressVM.postalCode.value = newValue.correspondenceAddress.postalCode.value;
                    }
                });
            }]
        };
    }];
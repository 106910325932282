import template from 'text!./templates/pm-vehicles.html';
import EndorsementStateUtils from 'edge/endorsement/common/utils/EndorsementStateUtils';

export default [() => ({
    template,
    'scope': {
        'model': '=',
        'mode': '=',
        'onNext': '&',
        'onGo': '&'
    },
    'controller': ['$scope', function ($scope) {
        $scope.next = $scope.onNext;

        $scope.toState = () => {
            if ($scope.mode === 'tile') {
                $scope.onGo();
            }
        };

        $scope.getState = EndorsementStateUtils.defineState($scope);
    }]
})];
class RepairFacilitiesListItemCtrl {
    constructor($element) {
        this.element = $element;
    }

    $onChanges({selected}) {
        if (selected && selected.currentValue
            && selected.currentValue !== selected.previousValue
            && selected.currentValue === true
        ) {
            const targetTop = this.element.position().top;
            this.onScrollIntoView({top: targetTop});
        }
    }
}

export default RepairFacilitiesListItemCtrl;
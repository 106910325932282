export default ['JsonRPCService', 'CacheService_AND', (JsonRPCService) => {

    const endpoint = 'policy/rewards';
    return {
        getPointsHistory: (policyNumber) => {
            return JsonRPCService.send('pc', endpoint, 'getPointsHistory', [policyNumber]);
        },
        getProductList: (policyNumber) => {
            return JsonRPCService.send('pc', endpoint, 'getProductList', [policyNumber]);
        },
        purchaseProduct: (policyNumber, product) => {
            return JsonRPCService.send('pc', endpoint, 'purchaseProduct', [policyNumber, product]);
        }
    };
}];
import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./ExternalIDPLoggedOut.html';
import styles from './ExternalIDPLoggedOut-hashed.scss';
import AuthenticationService from 'plugin?auth-type!gw-portals-auth-js/AuthenticationService';
import ComponentUtil from 'gw-portals-util-js/ComponentUtil';

// VARS
const hashedTemplate = cssUtil.hashTemplate(template, styles);

// EXPORT

export default {
    template: hashedTemplate,
    controller: [
        '$scope', '$state', 'state_plugin_config', 'oAuth',
        function ($scope, $state, STATE_PLUGIN_CONFIG, oAuth) {
            // VARS
            const authenticationService = AuthenticationService(oAuth);
            const $ctrl = this;
            $ctrl.isLoginReqInProgress = false;// animation

            // METHODS
            const setCtrlParam = ComponentUtil.setCtrlParam($ctrl, $scope);

            function setloginError(value) {
                setCtrlParam('loginError', value);
            }

            $ctrl.login = function () {
                setCtrlParam('isLoginReqInProgress', true);// animation
                let loginFn = authenticationService.loginWithCurrentCookies;
                if ($state.params.origin === 'google') {
                    loginFn = authenticationService.loginWithGoogle;
                }

                loginFn().then(() => {
                    // logged in
                    setCtrlParam('isLoginReqInProgress', false);// animation
                    // reset errors
                    setloginError('');
                    // redirect user to home view
                    $state.go(STATE_PLUGIN_CONFIG.home);
                }, () => {
                    setCtrlParam('isLoginReqInProgress', false);// animation
                    window.location.reload();
                });
            };
        }]
};
import templateStr from 'text!edge/claim/directives/templates/claim-documents.html';
import _ from 'underscore';

export default () => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            documents: '=',
            uploadDocument: '='
        },

        controller: ['$scope', 'httpSettings', '$window', 'ModalService', '$stateParams', 'claim.DocumentService', '$q', '$filter', '$translate', 'claim.endpointConfig', ($scope, httpSettings, $window, ModalService, $stateParams, DocumentService, $q, $filter, $translate, endpointConfig) => {
            $scope.alerts = [];
            $scope.documentQuery = '';

            $scope.uploadEndpoint = endpointConfig.uploadEndpoint;
            $scope.documentsEndpoint = endpointConfig.documentsEndpoint;

            $scope.claimCenterDownloadURI = httpSettings.serviceBaseURLs.cc + endpointConfig.downloadEndpoint;
            $scope.documentNotFoundURL = `${$window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'))}/ClaimDocumentError`;
            $scope.documents = $scope.documents || [];
            $scope.filteredDocuments = $scope.documents;
            $scope.recentlyAddedIDs = [];
            $scope.metadataTemplate = {
                docUID: '001',
                documentType: 'letter_received',
                securityType: 'unrestricted',
                status: 'approved',
                claimNumber: $stateParams.claimNumber
            };

            $scope.$watch('documents', () => {
                $scope.filteredDocuments = $scope.documents;
            });
            $scope.$watch('documentQuery', () => {
                $scope.applyFilter();
            });

            $scope.isRecentlyAdded = (id) => {
                return _.contains($scope.recentlyAddedIDs, id);
            };

            $scope.$on('fileuploaddone', (e, data) => {
                $scope.recentlyAddedIDs.push(data.result.result.publicID);
            });

            $scope.$on('fileuploadstart', () => {
                $scope.recentlyAddedIDs = [];
            });

            $scope.applyFilter = () => {
                $scope.filteredDocuments = $filter('filter')($scope.documents, {
                    name: $scope.documentQuery
                });
            };

            $scope.closeAlert = (index) => {
                $scope.alerts.splice(index, 1);
            };

            $scope.removeDocument = (document) => {
                if (!document.canDelete) {
                    // only add an alert if there is not already one added
                    if (!(
                        _.findWhere($scope.alerts, {
                            documentName: document.name
                        }))) {
                        const alertMsg = $filter('translate')('claim.directives.ClaimDocuments.Unable to delete that document', {
                            documentName: document.name
                        });
                        $scope.alerts = [];
                        $scope.alerts.push({
                            type: 'info',
                            msg: alertMsg,
                            documentName: document.name
                        });
                    }
                    return;
                }

                const title = 'claim.directives.ClaimDocuments.Remove Document';
                const msg = $translate('claim.directives.ClaimDocuments.Are you sure you want to remove', {
                    documentName: document.name
                });

                ModalService.showWarning(title, msg, null, $filter('translate')('claim.directives.ClaimDocuments.Yes'), null, $filter('translate')('claim.directives.ClaimDocuments.No'))
                    .result.then(() => {
                        $q.when(DocumentService.removeDocument($stateParams.claimNumber, document.publicID), (deleted) => {
                            if (deleted === true) {
                                for (let i = 0; i < $scope.documents.length; i++) {
                                    if (document.publicID === $scope.documents[i].publicID) {
                                        $scope.documents.splice(i, 1);
                                    }
                                }
                            } else {
                                ModalService.showWarning(
                                    'claim.directives.ClaimDocuments.Document deletion may have failed',
                                    'claim.directives.ClaimDocuments.Something may have gone wrong trying to delete your document.'
                                );
                            }
                        }, () => {
                            ModalService.showError(
                                'claim.directives.ClaimDocuments.Document deletion failed',
                                'claim.directives.ClaimDocuments.Sorry your document could not be deleted at this time'
                            );
                        });
                    });
            };
        }]
    };
};

import ViewModelUtil from 'gw-portals-viewmodel-js/ViewModelUtil';

/**
 * This class is a common parent to policy transactions wizard entry, this way we don't need replicate this logic
 * every time that we create a new entry for a policy transaction wizard.
 * A sample of how to implement the sub class in: "edge/gw-portals-renewal-angular/entry/RenewalEntryCtrl.js"
 */
class TransactionEntryCtrl {

    constructor($scope, wizardFlowService, StateUtils) {
        this.$scope = $scope;
        this.StateUtils = StateUtils;
        this.wizardFlowService = wizardFlowService;
    }

    startWizardFlow() {
        const parentWizardState = 'baseTransactionWizard';

        const getViewModelID = (vm) => {
            const model = ViewModelUtil.cleanse(vm);
            return model.jobID ? model.jobID : model.quoteID;
        };

        const stepToStateMapping = {
            returnHome: (model) => {
                return this.constructor.returnToDetailsPage(getViewModelID(model));
            },
            confirmation: (model) =>['policyTransactionConfirmation', {
                transactionSummary: ViewModelUtil.cleanse(model)
            }],
            transactionError: (modelParam) => {
                return this.constructor.returnToDetailsPage(getViewModelID(modelParam));
            }
        };

        const flowFactory = this.wizardFlowService(this.getCompositeFlow(), parentWizardState, stepToStateMapping);

        this.buildModel().then(({model, resume}) => {
            if (resume) {
                this.$scope.flow = flowFactory.resume(model);
            } else {
                this.$scope.flow = flowFactory.start(model);
            }
            this.StateUtils.activeDataForState().flowModel = this.$scope.flow;
        });
    }
}

export default TransactionEntryCtrl;
export default (AppConfig) => {
    const parentState = AppConfig.enrollment;
    const homeState = AppConfig.home;

    return {
        'additionalEnrollment': {
            name: 'additionalEnrollment',
            url: '/additionalEnrollment',
            parent: parentState,
            template: '<gw-additional-enrollment gw-auth-component/>',
            data: {
                actions: {
                    home: homeState
                }
            }
        },
        'signupEnrollment': {
            name: 'signupEnrollment',
            parent: 'auth',
            url: '/signupEnrollment',
            template: '<gw-signup-enrollment gw-auth-component/>',
            data: {
                isAuthPage: false
            }
        }
    };
};
import Template from 'text!edge/policy/top-up-miles_AND/views/top-up-miles.html';
import Controller from 'edge/policy/top-up-miles_AND/controllers/TopUpMilesCtrl';

export default (RootStates) => {
    const parentState = RootStates.topUpMiles_AND;

    return {
        topUpMiles_AND: {
            name: 'topUpMiles_AND',
            parent: parentState,
            url: '/top-up-miles',
            template: Template,
            controller: Controller,
            data: {
                restrictedToEditablePolicies: true
            }
        }
    };
};

import templateStr from 'text!./templates/loss-location_AND.html';

const fn = () => {
    return {
        restrict: 'A',
        scope: {
            lossLocationType: '=',
            lossLocation: '='
        },
        template: templateStr,
        controller: ['$scope', function ($scope) {
            $scope.addressVerificationVm = {
                value: '',
                aspects: {
                    required: false,
                    get valid() {
                        return $scope.lossLocation.aspects.subtreeValid;
                    }
                }
            };
        }],
        link: (scope) => {
            scope.$watch('lossLocationType', () => {
                scope.lossLocation.value = scope.lossLocation.value || {};
            }, true);

            if (!scope.lossLocationType) {
                scope.lossLocationType = 'location_desc'; // default value
                // If AddressLine1 has a string > 0 then locationLocationType is exact_address
                if ((scope.lossLocation.addressLine1) &&
                    (scope.lossLocation.addressLine1.length > 0)) {
                    scope.lossLocationType = 'exact_address';
                }
            }

            scope.hideFields = ['AddressLine1', 'AddressLine2', 'AddressLine3', 'PostalCode', 'City'];
            scope.hideDescription = ['Description'];
        }
    };
};

fn.$inject = [];
export default fn;

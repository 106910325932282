import routeConfig from 'edge/fnol/common/config/RouteConfig';
import LoadSaveService from 'edge/fnol/common/services/LoadSaveService';
import PolicyService from 'edge/fnol/common/services/PolicyService';
import PolicySearchResultDirective from 'edge/fnol/common/directives/PolicySearchResultDirective';
import PolicySearchCriteria from 'edge/fnol/common/directives/PolicySearchCriteria';
import LossLocation_AND from 'edge/fnol/common/directives/LossLocation_AND'; // ANDIE
import LossLocationMap from 'edge/fnol/common/directives/LossLocationMap';
import ImportantInfo_AND from 'edge/fnol/common/directives/ImportantInfo_AND'; // ANDIE
import ContactAgent from 'edge/fnol/common/components/ContactAgent';
import ConfirmationBackButton from 'edge/fnol/common/components/confirmationBackButton';
import RadioImageTile from 'edge/fnol/common/components/radioImageTile';
import 'angularRouter';
import 'edge/platform/map/module';
import 'edge/platform/base/module';
import 'edge/platform/widgets/modal/module';
import 'edge/platform/widgets/inputs/module';
import 'edge/platform/widgets/loading/module';
import 'edge/platform/widgets/documentupload/module';
import 'edge/platform/contacts/module';
import 'gw-portals-viewmodel-angular/aspects/module';
import 'edge/platform/widgets/basicinputs/module';
import 'edge/platform/flow/module';
import 'edge/platform/widgets/wizard/module';
import 'portalConfig';

export default angular.module('edge.fnol.common.app', [
    'portal.config',
    'edge.platform.base',
    'edge.platform.widgets.inputs',
    'ui.router',
    'edge.platform.map',
    'edge.platform.widgets.loadingMsg',
    'edge.platform.widgets.modal',
    'edge.platform.widgets.documentupload',
    'edge.platform.contacts',
    'gw-portals-viewmodel-angular.aspects',
    'edge.platform.widgets.basicinputs',
    'edge.platform.flow',
    'edge.platform.widgets.wizard'
])

    .config(['$stateProvider', 'state_plugin_config', 'fnolConfig', ($stateProvider, RootStateConfig, fnolConfig) => {
        const states = routeConfig(RootStateConfig, fnolConfig);
        for (const stateName in states) {
            if (states.hasOwnProperty(stateName)) {
                $stateProvider.state(stateName, states[stateName]);
            }
        }
    }])

    .directive('gwFnolPolicySearchResult', PolicySearchResultDirective)
    .directive('gwFnolPolicySearchCriteria', PolicySearchCriteria)
    .directive('andFnolLossLocation', LossLocation_AND) // ANDIE
    .directive('andFnolImportantInfo', ImportantInfo_AND) // ANDIE

    .component('gwFnolLossLocationMap', LossLocationMap)
    .component('gwFnolContactAgent', ContactAgent)
    .component('gwFnolConfirmationBackButton', ConfirmationBackButton)
    .component('gwFnolRadioImageTile', RadioImageTile)

    .factory('fnol.LoadSaveService', LoadSaveService)
    .factory('fnol.PolicyService', PolicyService)

    .value('fnol.endpointConfig', {
        'loadSaveEndpoint': 'fnol/fnol'
    });

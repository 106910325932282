import templateStr from 'text!./templates/claim-notes.html';
import 'portalConfig';

export default ['$stateParams', '$filter', ($stateParams, $filter) => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            notes: '=',
            onNewNote: '&',
            emptyListMessage: '@emptyMessage',
            createLabel: '@createLabel',
            showAddNoteBtn: '@showAddNoteBtn'
        },

        link: (scope) => {
            scope.claimNumber = $stateParams.claimNumber;
            scope.tableConfig = {
                paginationSize: 10000
            };
            scope.notesQuery = '';
            scope.notes = scope.notes || [];
            scope.filteredNotes = scope.notes || [];
            scope.$watch('notes', () => {
                scope.filteredNotes = scope.notes;
            });
            scope.$watch('notesQuery', () => {
                scope.applyFilter();
            });
            scope.getNoteAuthor = (item) => {
                if (item.firstName === undefined) {
                    item.firstName = '';
                }
                if (item.lastName === undefined) {
                    item.lastName = '';
                }
                return `${item.firstName} ${item.lastName}`;
            };

            scope.applyFilter = () => {
                scope.filteredNotes = $filter('filter')(scope.notes, scope.notesQuery);
            };
        }

    };
}];

import templateStr from 'text!./templates/pm-policy-change-edit-vehicle.html';

export default ['VinLookupService', '$q', '$filter', (VinLookupService, $q, $filter) => {
    return {
        restrict: 'A',
        scope: {
            vehicle: '='
        },
        template: templateStr,

        link: (scope) => {
            scope.years = [];
            scope.readonly = false;
            scope.errorMsg = '';
            scope.showLoading = false;
            scope.invalidVIN = null;

            const currentYear = new Date().getUTCFullYear();
            const OLDEST_CAR_YEAR = 1908;

            for (let i = currentYear; i > OLDEST_CAR_YEAR; i--) {
                scope.years.push(i);
            }

            scope.$watch('invalidVIN', (newValue) => {
                scope.readonly = newValue === false;
                scope.errorMsg = '';
            });

            scope.lookupVIN = () => {
                scope.invalidVIN = null;
                if (scope.vehicle.vin.value && scope.vehicle.vin.value.length > 0) {
                    scope.readonly = true;
                    scope.showLoading = true;
                    $q.when(VinLookupService.autofillBasedOnVIN(scope.vehicle.vin.value), (vehicleInfo) => {
                        if (vehicleInfo && vehicleInfo.make && vehicleInfo.model && vehicleInfo.year) {
                            // Autofill with the returned data
                            scope.invalidVIN = false;
                            scope.vehicle.make.value = vehicleInfo.make;
                            scope.vehicle.model.value = vehicleInfo.model;
                            scope.vehicle.year.value = vehicleInfo.year;
                            scope.errorMsg = '';
                        } else {
                            // The VIN does not return a match, VIN is invalid
                            scope.invalidVIN = true;
                            scope.vehicle.make.value = '';
                            scope.vehicle.model.value = '';
                            scope.vehicle.year.value = undefined;
                        }
                        scope.showLoading = false;
                    }, () => {
                        // Something has gone legitimately wrong, let the user input the data manually
                        scope.errorMsg = $filter('translate')('endorsement.pa.directives.PAPolicyChangeEditVehicle.errorMsg'); // POR-2061
                        scope.readonly = false;
                        scope.showLoading = false;
                        scope.invalidVIN = null;
                    });
                } else {
                    scope.readonly = false;
                    scope.errorMsg = '';
                    scope.vehicle.make.value = '';
                    scope.vehicle.model.value = '';
                    scope.vehicle.year.value = undefined;
                }
            };
        }
    };
}];
import Template from 'text!edge/policy/contact-us_AND/views/contact-us.html';

export default (RootStates) => {
    const parentState = RootStates.contactUs_AND;

    return {
        contactUs_AND: {
            name: 'contactUs_AND',
            parent: parentState,
            url: '/contact-us',
            template: Template
        }
    };
};

import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

import 'conditional?if=policy-lob&equals=pa!edge/policy/pa/app';
import 'conditional?if=lob&equals=pa!edge/policy/pa/app';

import 'conditional?if=policy-lob&equals=ho!edge/policy/ho/app';
import 'conditional?if=lob&equals=ho!edge/policy/ho/app';

import 'conditional?if=policy-lob&equals=pm!edge/policy/pm/app';
import 'conditional?if=lob&equals=pm!edge/policy/pm/app';

const lobModules = [];
if (ApplicationFeatures.hasFeature('policy-lob.pa') || ApplicationFeatures.hasFeature('lob.pa')) {
    lobModules.push('edge.policy.pa.app');
}
if (ApplicationFeatures.hasFeature('policy-lob.ho') || ApplicationFeatures.hasFeature('lob.ho')) {
    lobModules.push('edge.policy.ho.app');
}
if (ApplicationFeatures.hasFeature('policy-lob.pm') || ApplicationFeatures.hasFeature('lob.pm')) {
    lobModules.push('edge.policy.pm.app');
}
export default angular.module('edge.policy.app', lobModules);

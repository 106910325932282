import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

function showServiceChoiceStep(claimVm) {
    var lossCauseCode = claimVm.lossCause.value.code;

    return (ApplicationFeatures.hasFeature('role.policyholder') || ApplicationFeatures.hasFeature('role.producer')) && ApplicationFeatures.hasFeature('repairOption') && lossCauseCode !== 'theftentire';
}

function getRepairOptionValue(claimVm) {
    if (!claimVm.lobs.personalMotor) {
        return;
    }
    return claimVm.lobs.personalMotor.repairOption.value;
}

function getRepairOptionChoice(claimVm) {
    var repairOption = getRepairOptionValue(claimVm);
    return repairOption ? repairOption.repairOptionChoice : undefined;
}

export default (function (LoadSaveService, $q, TranslateService) {
    var translateFn = TranslateService.instant;

    var ACTION_MSG = {
        createClaim: {
            error: {
                title: 'fnol.pa.config.FlowConfig.Error creating claim',
                message: 'fnol.pa.config.FlowConfig.An error occurred attempting to create personal auto claim.'
            },
            progress: {
                message: 'fnol.pa.config.FlowConfig.Creating personal motor draft claim'
            }
        }
    };

    var ACTIONS = {
        createClaim: function createClaim(model) {
            if (model.claimNumber.value) {
                return $q.resolve(model);
            }
            return LoadSaveService.createClaim(model.value);
        }
    };

    return function (step, junction) {
        junction('pmError');
        junction('pmSuccess');

        step('pmCollisionLossDetails', true).onNext.doAction(ACTIONS.createClaim, ACTION_MSG.createClaim, { forceUpdate: true }).thenBranch(function (_ref) {
            var claim = _ref.value;

            var nextStep = 'pmCollisionVehicles';
            switch (claim.getLossCategory()) {
                case 'collision':
                    // When user select pedestrian, get them to ring in immediately.
                    if (claim.cause_itb === 'Pedestrian') {
                        nextStep = 'pmError';
                    }
                    break;
                case 'theft':
                case 'glass':
                default:
                    nextStep = 'pmError';
                    break;
            }
            return nextStep;
        }, function () {
            return 'pmError';
        }).skipOnResumeIf(function (_ref2) {
            var claim = _ref2.value;
            return claim.lossCause === 'vehcollision' && claim.lossLocation && claim.lossLocation.city;
        }).isValid(function (model) {
            return model.aspects.valid && model.aspects.subtreeValid;
        }).label(function () {
            return translateFn('fnol.wizard.steps.pa.CollisionDetails');
        });

        step('pmCollisionVehicles').onNext.branch(function (claimVm) {
            return showServiceChoiceStep(claimVm) ? 'pmServiceChoice' : 'pmSuccess';
        }).skipOnResumeIf(function (_ref3) {
            var claim = _ref3.value;
            return claim.lobs.personalMotor.vehicleIncidents[0];
        }).isValid(function (model) {
            return model.aspects.valid && model.aspects.subtreeValid;
        }).label(function () {
            return translateFn('fnol.wizard.steps.pa.Vehicles');
        });

        step('pmServiceChoice').onNext.branch(function (claimVm) {
            switch (getRepairOptionChoice(claimVm)) {
                case 'NewVendor':
                    return 'pmNewRepairFacility';
                case 'PreferredVendor':
                    return 'pmPreferredRepairFacility';
                case 'NoRepair':
                    return 'pmSuccess';
                default:
                    return 'pmSuccess'; // Not a valid case Allows the wizard to display subsequent steps
            }
        }).skipOnResumeIf(function (claimVm) {
            return getRepairOptionChoice(claimVm);
        }).isValid(function (model) {
            return model.aspects.valid && model.aspects.subtreeValid;
        }).label(function () {
            return translateFn('fnol.wizard.steps.pa.Repair');
        });

        step('pmNewRepairFacility').onNext.goTo('pmSuccess').skipOnResumeIf(function (claimVm) {
            return getRepairOptionValue(claimVm).repairFacility;
        }).isValid(function (model) {
            return model.aspects.valid && model.aspects.subtreeValid;
        }).label(function () {
            return translateFn('fnol.wizard.steps.pa.Repair Facility');
        });

        step('pmPreferredRepairFacility').onNext.goTo('pmSuccess').skipOnResumeIf(function (claimVm) {
            return getRepairOptionValue(claimVm).repairFacility;
        }).isValid(function (model) {
            return model.aspects.valid && model.aspects.subtreeValid;
        }).label(function () {
            return translateFn('fnol.wizard.steps.pa.Repair Facility');
        });
    };
});
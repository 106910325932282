import _ from 'underscore';
import StatesConfig from 'edge/policy/user-account_AND/config/RouteConfig';
import ChangeUserPwd from './directives/change-user-pwd/ChangeUserPwd';
import ChangeEmailAddress from './directives/change-email-address/ChangeEmailAddress';
import ChangePhoneNumbers from './directives/change-phone-numbers/ChangePhoneNumbers';
import ChangeCorrespondenceAddress from './directives/change-correspondence-address/ChangeCorrespondenceAddress';
import ChangeDocsPref from './directives/change-docs-pref/ChangeDocsPref';
import ChangeGamificationPref from './directives/change-gamification-pref/ChangeGamificationPref'
import UserAccountInfo from './directives/user-account-info/UserAccountInfo';
import MultipleCorrespondenceAddressBanner from './directives/MultipleCorrespondenceAddressBanner/MultipleCorrespondenceAddressBanner';
import ChangeMarketingPref from './directives/change-marketing-pref/ChangeMarketingPref';
import CookieSettings from './directives/cookie-settings/CookieSettings'

const appModule = angular.module('edge.and.user-account.app', [])
    .directive('andChangeUserPwd', ChangeUserPwd)
    .directive('andChangeEmailAddress', ChangeEmailAddress)
    .directive('andChangePhoneNumbers', ChangePhoneNumbers)
    .directive('andChangeCorrespondenceAddress', ChangeCorrespondenceAddress)
    .directive('andChangeDocsPref', ChangeDocsPref)
    .directive('andUserAccountInfo', UserAccountInfo)
    .directive('andMultipleCorrespondenceAddressBanner', MultipleCorrespondenceAddressBanner)
    .directive('andChangeGamificationPref', ChangeGamificationPref)
    .directive('andChangeMarketingPref', ChangeMarketingPref)
    .directive('andCookieSettings', CookieSettings)

    .config(['$stateProvider', 'state_plugin_config', ($stateProvider, RootStateConfig) => {
        const states = new StatesConfig(RootStateConfig);
        _.values(states).forEach(state => {
            $stateProvider.state(state);
        });
    }]);

export default appModule;

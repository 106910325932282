import QuoteDetailsView from 'text!edge/quoteandbind/common/views/quote-details_AND.html'; // ANDIE
import PaymentDetailsView from 'text!edge/quoteandbind/common/views/payment-details.html';
import ContactUsView from 'text!edge/quoteandbind/common/views/technical-error_AND.html'; // ANDIE
import ConfirmationView from 'text!edge/quoteandbind/common/views/confirmation_AND.html';// ANDIE
import ZipCodeView from 'text!edge/quoteandbind/common/views/zip-code.html';
import SessionEndedView_AND from 'text!edge/quoteandbind/common/views/session-ended_AND.html'; // ANDIE
import quoteRetrievalView from 'text!edge/quoteandbind/common/views/quote-retrieval_AND.html';
import AggRetrievalView from 'text!edge/quoteandbind/common/views/agg-retrieval_AND.html'; // ANDIE
import QuoteEntryView from 'text!edge/quoteandbind/common/views/quote-entry.html';
import QuoteDeclinedView_AND from 'text!edge/quoteandbind/common/views/quote-declined_AND.html'; // ANDIE
import ContactUsMissingVRNView_AND from 'text!edge/quoteandbind/common/views/contact-us-missing-vrn_AND.html'; // ANDIE
import QuoteDeclinedCueView_AND from 'text!edge/quoteandbind/common/views/quote-declined-cue_AND.html'; // ANDIE
import UwErrorView_AND from 'text!edge/quoteandbind/common/views/uw-error_AND.html'; //ANDIE
import SessionTimedOutView_AND from 'text!edge/quoteandbind/common/views/session-timed-out_AND.html'; // ANDIE
import PurchaseFailedView_AND from 'text!edge/quoteandbind/common/views/purchase-failed_AND.html'; // ANDIE
import QuoteEntryCtrl from 'edge/quoteandbind/common/controllers/QuoteEntryCtrl_AND'; // ANDIE
import QuoteDetailsCtrl from 'edge/quoteandbind/common/controllers/QuoteDetailsCtrl_AND'; // ANDIE
import PaymentDetailsCtrl from 'edge/quoteandbind/common/controllers/PaymentDetailsCtrl';
import CallServiceCenter_AND from 'text!edge/quoteandbind/common/views/call-service-center_AND.html'; // ANDIE
import ConfirmationCtrl from 'edge/quoteandbind/common/controllers/ConfirmationCtrl_AND'; // ANDIE
import ZipCodeCtrl from 'edge/quoteandbind/common/controllers/ZipCodeCtrl';
import QuoteRetrievalCtrl from 'edge/quoteandbind/common/controllers/QuoteRetrievalCtrl_AND'; // ANDIE
import AggRetrievalCtrl from 'edge/quoteandbind/common/controllers/AggRetrievalCtrl_AND'; // ANDIE
import GetNewQuoteCtrl_AND from 'edge/quoteandbind/common/controllers/GetNewQuoteCtrl_AND'; // ANDIE
import 'gw-portals-featuredetection-js/ApplicationFeatures';

export default (RootStates) => {

    const parentState = RootStates.quote_bind;
    const wizardParentState = 'baseTransactionWizard';

    return {
        zipCode: {
            name: 'zipCode',
            parent: parentState,
            url: '/zipCode?account',
            controller: ZipCodeCtrl,
            template: ZipCodeView,
            data: {
                trackingUrl: '^/'
            }
        },

        ampquoteentry: {
            parent: parentState,
            url: '/ampquoteentry?quoteID&postalCode&dateOfBirth', // ANDIE Add PH's Date of Birth
            controller: ['$state', '$stateParams', ($state, $stateParams) => {
                const dob = $stateParams.dateOfBirth.split('-');
                $state.go('quoteentry', {
                    quoteID: $stateParams.quoteID,
                    postalCode: $stateParams.postalCode,
                    displayYourInfoStep: true,
                    // ANDIE PH's Date of Birth
                    dateOfBirth: {day: dob[0], month: dob[1], year: dob[2]}
                });
            }]
        },

        quoteentry: {
            parent: parentState,
            url: '/quoteentry',
            controller: QuoteEntryCtrl,
            template: QuoteEntryView,
            params: {
                policyNumber: null,
                isBuying: null, // ANDIE If buying, go straight to quote page
                isBase: null // ANDIE If going for the base version (as opposed to renewal versions)
            },
            resolve: {
                originState: ['$state', ($state) => {
                    // remember the state we came from so we can return if there is an error loading the claim
                    return {
                        name: $state.current,
                        params: $state.params
                    };
                }]
            }
        },

        quoteDetails: {
            name: 'quoteDetails',
            url: '/quote', // ANDIE
            parent: wizardParentState,
            controller: QuoteDetailsCtrl,
            template: QuoteDetailsView
        },

        paymentDetails: {
            name: 'paymentDetails',
            url: '/payment-details', // ANDIE
            parent: wizardParentState,
            template: PaymentDetailsView,
            controller: PaymentDetailsCtrl
        },

        contactUs: {
            name: 'contactUs',
            url: '/error', // ANDIE
            parent: parentState,
            controller: GetNewQuoteCtrl_AND, // ANDIE
            template: ContactUsView,
            params: {
                returnStateInfo: null,
                quoteID: null
            },
            data: {
                trackingUrl: '^'
            }
        },

        qnbconfirmation: {
            name: 'confirmation',
            url: '/success-payment?quoteID&postalCode&dateOfBirth', // ANDIE
            parent: parentState,
            controller: ConfirmationCtrl,
            template: ConfirmationView,
            params: {
                returnStateInfo: null,
                quoteSummary: null
            }/* ANDIE,
            data: {
                trackingUrl: (state, params) => {
                    return `/success-payment/${params.quoteSummary.baseData.productCode}`;
                }
            }*/
        },

        /**
         * ANDIE
         */

        sessionEnded_AND: { // From Email quote screen
            name: 'sessionEnded_AND',
            parent: parentState,
            url: '/session-ended',
            controller: GetNewQuoteCtrl_AND,
            template: SessionEndedView_AND,
        },

        sessionTimedOut_AND: { // Frontend session timed out
            name: 'sessionTimedOut_AND',
            parent: parentState,
            url: '/timeout',
            controller: GetNewQuoteCtrl_AND,
            template: SessionTimedOutView_AND,
        },

        quoteRetrieval_AND: {
            name: 'quoteRetrieval',
            parent: parentState,
            url: '/quote-retrieval?quoteID', // ANDIE Add quoteID param
            controller: QuoteRetrievalCtrl,
            template: quoteRetrievalView,
            controllerAs: '$ctrl'
        },

        aggRetrieval_AND: {
            name: 'aggRetrieval',
            parent: parentState,
            url: '/agg-retrieval?guid&agg',
            controller: AggRetrievalCtrl,
            template: AggRetrievalView,
            controllerAs: '$ctrl'
        },

        quoteDeclined_AND: {
            name: 'quoteDeclined_AND',
            url: '/declined',
            parent: parentState,
            controller: GetNewQuoteCtrl_AND,
            template: QuoteDeclinedView_AND
        },

        uwError_AND: {
            name: 'uwError_AND',
            url: '/declined-uw',
            parent: parentState,
            controller: GetNewQuoteCtrl_AND,
            template: UwErrorView_AND
        },

        contactUsMissingVRNView_AND: {
            name: 'contactUsMissingVRNView_AND',
            url: '/error-missing-data',
            parent: parentState,
            controller: GetNewQuoteCtrl_AND,
            template: ContactUsMissingVRNView_AND
        },

        quoteDeclinedCue_AND: {
            name: 'quoteDeclinedCue_AND',
            url: '/declined-cue',
            parent: parentState,
            controller: GetNewQuoteCtrl_AND,
            template: QuoteDeclinedCueView_AND
        },

        purchaseFailed_AND: { // Payment gateway purchase failed
            name: 'purchaseFailed_AND',
            parent: parentState,
            url: '/purchase-failed',
            template: PurchaseFailedView_AND,
        },

        callServiceCenter: {
            name: 'callServiceCenter',
            parent: parentState,
            url: '/call-service-center',
            template: CallServiceCenter_AND,
        },
    };
};

import _ from 'underscore';
import templateStr from 'text!./templates/account-billing-summary.html';
import InvoiceStatistics from 'edge/billing/util/InvoiceStatistics';

export default () => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            gwBillingAccountBillingSummary: '='
        },
        controller: ['$scope', ($scope) => {
            const summary = $scope.gwBillingAccountBillingSummary;
            $scope.summary = summary;

            $scope.overdueStat = InvoiceStatistics.aggregateBilling(_.filter(summary.activeInvoices, InvoiceStatistics.isOverdue));
            $scope.billedStat = InvoiceStatistics.aggregateBilling(_.filter(summary.activeInvoices, InvoiceStatistics.isBilled));

            if ($scope.overdueStat && $scope.billedStat) {
                $scope.totalDue = {
                    'amount': $scope.overdueStat.amountDue.amount + $scope.billedStat.amountDue.amount,
                    'currency': $scope.overdueStat.amountDue.currency
                };
            }
        }]
    };
};
import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./RepairFacilitiesSearchOption.html';
import styles from './RepairFacilitiesSearchOption-hashed.scss';

export default {
    template: cssUtil.hashTemplate(template, styles),
    bindings: {
        navigation: '<',
        searchOption: '<',
        selectSearchAddress: '&'
    }
};

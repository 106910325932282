import templateStr from 'text!./templates/policy-common-details.html';
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import moment from 'moment';
import _ from 'lodash';

export default [() => {
    return {
        restrict: 'E',
        template: templateStr,
        scope: {
            'policy': '=',
            'buttonText': '@'
        },
        controller: ['$scope', ($scope) => {
            const doesEndorsementsSupportLob = _supportedLobInitialise();
            $scope.getPeriodStartDateString = _.memoize((effectiveDate) => {
                const effDate = moment(effectiveDate);
                if (effDate.isValid()) {
                    return `?day=${effDate.date()}&month=${effDate.month()}&year=${effDate.year()}`;
                }
                return '';
            });


            $scope.canChangePolicy = (policy) => {
                return _.some(policy.lobs, (lob, key) => !_.isEmpty(lob) && doesEndorsementsSupportLob(key));
            };

            function _supportedLobInitialise() {
                // We don't care about casing differences so we will compare on lowercase
                const supportedLobs = _.map(
                    ApplicationFeatures.getAllFeatures()['endorsement-products'],
                    (lob) => lob.toLowerCase()
                );
                // Caching result to save lookup time
                return _.memoize((lob) => {
                    return _.some(supportedLobs, (supportedLob) => supportedLob === lob.toLowerCase());

                });
            }
        }]
    };
}];

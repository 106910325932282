import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

export default () => ({
    mapStatus(status) {
        switch (status) {
            case 'workcomplete':
                return 'completed';
            case 'specialistwaiting':
            case 'requested':
            case 'inprogress':
            case 'draft':
                return 'inProgress';
            case 'expired':
            case 'declined':
            case 'canceled':
                return 'offTrack';
            default:
                return 'unknown';
        }
    },

    displayList(values) {
        return values && values.join ?
            values.join(', ') :
            '';
    },

    hasServiceRequests() {
        return this.serviceRequests && this.serviceRequests.length > 0;
    },

    canShowPrimaryContact() {
        return ApplicationFeatures.hasFeature('role.producer') || this.policyType === 'WorkersComp';
    },

    isInProgress(status) {
        return this.mapStatus(status) === 'inProgress';
    }
});
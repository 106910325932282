import BaseCtrl from 'edge/platform/base/BaseCtrl';
import _ from 'underscore';

const fn = function (
    $scope, $state, AccountBillingDetailsService, ModalService, $stateParams, supportPhone,
    $window, InsuranceSuiteMetadata, PaymentInstrumentService
) {
    const ctrl = Object.create(BaseCtrl);
    const invoiceStatusTypelist = InsuranceSuiteMetadata.get('bc').types.getTypelist('InvoiceStatus');
    const invoicePaidStatusTypelist = InsuranceSuiteMetadata.get('bc').types.getTypelist('InvoicePaidStatus');
    const periodicityTypelist = InsuranceSuiteMetadata.get('bc').types.getTypelist('Periodicity');

    ctrl.init = () => {
        $scope.showDetailedSchedule = false;
        $scope.outstandingAmount = null;
        $scope.supportPhone = supportPhone;

        let groupState;

        if ($state.is('accounts.detail.billingAndPayment.setup-autopay')) {
            groupState = AccountBillingDetailsService.getPaymentGroupPaymentInformation($stateParams.accountNumber, $stateParams.streamId);
        } else {
            groupState = AccountBillingDetailsService.getPaymentGroupPaymentInformation($stateParams.streamId);
        }

        $scope.groupStatePromise = groupState;

        groupState.then((info) => {
            $scope.info = info;
            $scope.paymentInstrument = PaymentInstrumentService.createAutopayPaymentInstrument(info.paymentInstrument);
            $scope.outstandingAmount = {
                amount: _.reduce(info.invoiceSummary, (sum, v) => {
                    return sum + v.amountDue.amount;
                }, 0),
                currency: info.invoiceSummary.length > 0 ? info.invoiceSummary[0].amountDue.currency : 'unknown'
            };

            const firstPayment = _.min(
                _.filter(
                    info.invoiceSummary,
                    (invoice) => {
                        return invoice.status !== 'paid';
                    }
                ),
                (invoice) => {
                    return new Date(invoice.dueDate).getTime();
                }
            );

            $scope.firstPayment = firstPayment === Infinity ? null : firstPayment; // _.min() returns Infinity for empty sets
        });
    };

    ctrl.cancel = () => {
        $state.transitionTo($state.$current.data.actions.cancel);
    };

    ctrl.invoiceStatusName = (typeCode) => {
        return (typeCode) ? invoiceStatusTypelist.getCode(typeCode).name : '';
    };

    ctrl.invoicePaidStatusName = (typeCode) => {
        return (typeCode) ? invoicePaidStatusTypelist.getCode(typeCode).name : '';
    };

    ctrl.periodicityName = (typeCode) => {
        return (typeCode) ? periodicityTypelist.getCode(typeCode).name : '';
    };

    ctrl.chargesOfInvoice = (invoice) => {
        return {
            amount: invoice.breakdown.taxes.amount + invoice.breakdown.charges.amount,
            currency: invoice.breakdown.taxes.currency || invoice.breakdown.charges.currency
        };
    };

    ctrl.goBack = () => {
        $window.history.back();
    };

    ctrl.setup = () => {
        $scope.autopayForm.submitted = true;

        if ($scope.autopayForm.invalid || !PaymentInstrumentService.isValid($scope.paymentInstrument)) {
            return;
        }
        const paymentInstrument = PaymentInstrumentService.toDto($scope.paymentInstrument);

        let submitAutoPayPromise;
        if ($state.is('accounts.detail.billingAndPayment.setup-autopay')) {
            submitAutoPayPromise = AccountBillingDetailsService.setPaymentGroupPaymentInformation($stateParams.accountNumber, $stateParams.streamId, paymentInstrument);
        } else {
            submitAutoPayPromise = AccountBillingDetailsService.setPaymentGroupPaymentInformation($stateParams.streamId, paymentInstrument);
        }

        $scope.submitAutoPayPromise = submitAutoPayPromise;

        submitAutoPayPromise.then(
            () => {
                if ($state.is('accounts.detail.billingAndPayment.setup-autopay')) {
                    $state.transitionTo($state.$current.data.actions.setupAutoPay, {
                        accountNumber: $stateParams.accountNumber,
                        info: {
                            invoices: $scope.info.invoiceSummary,
                            firstPayment: $scope.firstPayment,
                            paymentInstrument
                        }
                    });
                } else {
                    $state.transitionTo($state.$current.data.actions.setupAutoPay, {
                        info: {
                            invoices: $scope.info.invoiceSummary,
                            firstPayment: $scope.firstPayment,
                            paymentInstrument
                        }
                    });
                }
            },
            (error) => {
                console.error('Setup payment failed', error);
                ModalService.showError(
                    'billing.controllers.SetupStreamAutopayCtrl.Account update failed',
                    'billing.controllers.SetupStreamAutopayCtrl.Sorry, we were not able to setup automatic payments on your account'
                );
            }
        );
    };
    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$state', 'billing.AccountBillingDetailsService', 'ModalService', '$stateParams',
    'config.supportPhone', '$window', 'InsuranceSuiteMetadata', 'integration.PaymentInstrumentService'
];

export default fn;
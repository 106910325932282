import _ from 'lodash';
import EntityUtil from 'gw-portals-util-js/EntityUtil';
import VehicleIncident from 'edge/fnol/ca/models/VehicleIncident';
import Vehicle from 'edge/fnol/ca/models/Vehicle';
import 'edge/fnol/common/models/Contact';

export default class CAClaimExtension {
    constructor(claim) {
        Object.defineProperty(this, '_claim', {
            enumerable: false,
            value: claim
        });
        if (this._claim.lobs.commercialAuto) {
            this.vehicles = _.map(this._claim.lobs.commercialAuto.vehicles, (v) => new Vehicle(v));

            // correctly link up passengers, drivers with contact objects that already exist in the object hierarchy
            _.each(this._claim.lobs.commercialAuto.vehicleIncidents, function (vi) {
                vi.driver = this._claim._findContact(this._claim, vi.driver);
                _.each(vi.passengers, (p, index) => {
                    vi.passengers[index] = this._claim._findContact(this._claim, p);
                }, this);
                vi.vehicle = this._findVehicle(vi.vehicle);
            }, this);

            this.vehicleIncidents = _.map(
                this._claim.lobs.commercialAuto.vehicleIncidents || [],
                (v) => new VehicleIncident(this._claim, this, v)
            );

            this.fixedPropertyIncident = this._claim.lobs.commercialAuto.fixedPropertyIncident
                ? this._claim.lobs.commercialAuto.fixedPropertyIncident
                : {propertyDescription: undefined};

        } else {
            this.fixedPropertyIncident = {propertyDescription: undefined};
            this.vehicleIncidents = [];
            this.vehicles = [];
        }
    }

    generateVehicleIncidents(vehicles) {
        this.vehicleIncidents = vehicles.map((vehicle) => {
            const vi = new VehicleIncident(this._claim, this);
            vi.tempID = EntityUtil.nextId();
            vi.vehicle = vehicle;
            return vi;
        });
    }

    createVehicleIncident() {
        const vi = new VehicleIncident(this._claim, this);
        vi.tempID = EntityUtil.nextId();
        this.vehicleIncidents.push(vi);
        return vi;
    }

    createVehicle() {
        return new Vehicle({
            tempID: this._claim.nextId()
        });
    }

    removeVehicleIncident(incident) {
        const idx = this.vehicleIncidents.indexOf(incident);
        if (idx >= 0) {
            this.vehicleIncidents.splice(idx, 1);
        }
    }

    availableVehicles(additional) {
        return _.filter(this.vehicles, (v) => {
            if (additional && v === additional) {
                return true;
            }
            const used = _.find(this.vehicleIncidents, (vi) => {
                return v === vi.vehicle;
            });
            return used === undefined;
        }, this);
    }

    availableDrivers(additional) {
        return this.availablePassengers(additional);
    }

    availablePassengers(additional) {
        return _.filter(this._claim.contacts, function (c) {
            if (additional && c === additional) {
                return true;
            }
            const used = _.find(this.vehicleIncidents, (vi) => {
                return c === vi.driver || _.some(vi.passengers, (p) => {
                    return c === p;
                });
            });
            return used === undefined;
        }, this);
    }

    _findVehicle(vehicle) {
        return EntityUtil.findMatchingEntity(this.vehicles, vehicle, {
            Ctor: Vehicle
        });
    }
}

import template from 'text!./navigation-menu.html';

export default [() => ({
    restrict: 'A',
    template: template,
    scope: false,
    controller: ['ModalService', (ModalService) => {
        const defaultShowWelcomeMessage = {
            title: 'Platform.flow.angularhelpers.showWelcomeMessage.Welcome',
            message: 'Platform.flow.angularhelpers.showWelcomeMessage.We have',
            description: 'Platform.flow.angularhelpers.showWelcomeMessage.Have fun'
        };
        const messageKeys = defaultShowWelcomeMessage;
        
        if (sessionStorage.getItem('previousLogonTime') === 'null' && sessionStorage.getItem('welcomeShown') === null) {
            ModalService.showWelcome(messageKeys.title, messageKeys.message, messageKeys.description);
            sessionStorage.setItem('welcomeShown', true);
        } 

    }]
})];
import has from 'has';

const allowedPolicyTypes = {
    names: []
};

if (has('endorsement-products.Homeowners') || has('endorsement-products.HOPHomeowners')) {
    allowedPolicyTypes.names.push('Homeowners', 'homeowners', 'HomeownersLine_HOE', 'HOPHomeowners');
}
if (has('endorsement-products.PersonalAuto')) {
    allowedPolicyTypes.names.push('PersonalAuto', 'personalAuto', 'auto_per');
}
if (has('endorsement-products.PersonalMotor')) { // ANDIE
    allowedPolicyTypes.names.push('PersonalMotor', 'personalMotor');
}

export default allowedPolicyTypes;

class RepairFacilityDetailsCtrl {
    constructor(StateUtils) {
        this.StateUtils = StateUtils;
    }

    $onInit() {
        this.flowModel = this.StateUtils.activeDataForState(true).flowModel;
        if (!this.flowModel) {
            throw new Error('Missing flowModel property for current step in the wizard');
        }
    }

    getFirstAddressLine() {
        return this.repairFacility.primaryAddress.addressLine1;
    }

    getSecondAddressLine() {
        const {primaryAddress} = this.repairFacility;
        return `${primaryAddress.city}, ${primaryAddress.state} ${primaryAddress.postalCode}`;
    }

    acceptRepair() {
        const {model} = this.StateUtils.activeDataForState(true);
        this.flowModel.next(model).then(this.onAccepted);
    }
}

RepairFacilityDetailsCtrl.$inject = ['StateUtils'];
export default RepairFacilityDetailsCtrl;
import templateStr from 'text!./policy-detail-widget-renewal.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            'policyData': '<andPolicyDetailWidgetRenewal',
            'amend': '=?',
            'buy': '=?',
            'widgetTitle': '@?',
            'displayTerms': '='
        },
        controller: ['$scope', '$filter', '$rootScope', ($scope, $filter, $rootScope) => {
            $scope.canEditRenewalAndMta_AND = $rootScope.policyContext.currentPolicy.canEditRenewalAndMta_AND;
            $scope.$watch('policyData', () => {
                $scope.policyHolder = $scope.policyData.lobs.personalMotor.drivers.children.find((driver) => driver.value.isPolicyHolder === true);
                $scope.driverTitle = $scope.policyData.lobs.personalMotor.drivers.children.length === 1 ?
                    $filter('translate')('and.yourinsurance.Your policy.Driver', {param: $scope.policyData.lobs.personalMotor.drivers.children.length}) :
                    $filter('translate')('and.yourinsurance.Your policy.Drivers', {param: $scope.policyData.lobs.personalMotor.drivers.children.length});
                $scope.mainDrivers = $scope.policyData.lobs.personalMotor.drivers.children.filter((mainDriver) => mainDriver.mainDriver.value === true);
                $scope.otherDrivers = $scope.policyData.lobs.personalMotor.drivers.children.filter((otherDrivers) => otherDrivers.mainDriver.value !== true);
                $scope.premiumAmount = $scope.policyData.premium.amount.value.toFixed(2);
            });
            $scope.todayBeforeANDUK = $rootScope.policyContext.isTodayBeforeAndUk();
        }]
    };
}];
export default (LoadSaveService, $state, $q, ModalService, TranslateService, ViewModelService) => {
    const translateFn = TranslateService.instant;

    const ACTION_MSG = {
        cancelPrompt: {
            noSaveRequired: {
                message: 'fnol.common.config.FlowConfig.The information you have entered will not be saved.'
            },
            saveDraft: {
                title: 'fnol.common.config.FlowConfig.Save and Exit?',
                message: 'fnol.common.config.FlowConfig.We will save the information you have entered up to this point as a draft claim.',
                type: 'info'
            }
        },
        saveDraftClaim: {
            error: {
                title: 'fnol.common.config.FlowConfig.Unable to save draft claim information',
                message: 'fnol.common.config.FlowConfig.An error occurred while attempting to save the claim submission.'
            },
            progress: {
                message: 'fnol.common.config.FlowConfig.Saving draft claim details...'
            }
        },
        submitClaim: {
            error: {
                title: 'fnol.common.config.FlowConfig.Error submitting claim',
                message: 'fnol.common.config.FlowConfig.An error occurred attempting to submit the claim.'
            },
            progress: {
                message: 'fnol.common.config.FlowConfig.Submitting claim details...'
            }
        }
    };
    const ACTIONS = {
        cancelClaim: model => {
            if (!model.claimNumber.value) {
                // no claim was created so just exit without saving
                return $q.resolve(model);
            }
            return ACTIONS.saveDraftClaim(model);
        },
        saveDraftClaim: ({
            value: claim
        }) => LoadSaveService.saveClaim(claim),
        submitClaim: ({
            value: claim
        }) => LoadSaveService.submitClaim(claim)
    };


    return function (step, junction) {
        // start step
        step('moreInfo', true)
            .onNext.goTo('mainContact')
            .skipOnResumeIf(() => false)
            .isValid(model => model.aspects.valid && model.aspects.subtreeValid)
            .label(() => translateFn('fnol.wizard.step.Additional Information'));

        step('mainContact')
            .onNext.goTo('summary')
            .skipOnResumeIf(model => {
                const modelWithContext = ViewModelService.create(
                    model.value,
                    'cc', 'edge.capabilities.claim.fnol.dto.FnolDTO', {
                        'SubmittingClaim': true
                    }
                );
                return modelWithContext.mainContact.aspects.valid && modelWithContext.mainContact.aspects.subtreeValid;
            })
            .isValid(model => model.aspects.valid && model.aspects.subtreeValid)
            .label(() => translateFn('fnol.wizard.step.Contact Details'));

        step('summary')
            .onNext.doAction(ACTIONS.submitClaim, ACTION_MSG.submitClaim).thenGoTo('confirmation', 'error')
            .skipOnResumeIf(() => false)
            .label(() => translateFn('fnol.wizard.step.Summary'));

        junction('confirmation');

        junction('error');

        junction('returnHome');

        junction('cancel')
            .data({
                confirmationMsg(model) {
                    if (!model.claimNumber.value) {
                        return ACTION_MSG.cancelPrompt.noSaveRequired;
                    }
                    return ACTION_MSG.cancelPrompt.saveDraft;
                }
            })
            .onNext.doAction(ACTIONS.cancelClaim, ACTION_MSG.saveDraftClaim, { forceUpdate: true }).thenGoTo('returnHome', 'error');
    };
};

/**
 * A service used in case "ampquote" feature is enabled
 */

import Submission from 'edge/quoteandbind/common/models/Submission';

export default ['JsonRPCService', '$window', '$translate', '$rootScope', '$state', 'policy.PolicyService',
    (JsonRPCService, $window, $translate, $rootScope, $state, PolicyService) => {

        const createForAccount = (submissionDTO) => JsonRPCService.send('pc', 'quote/quote', 'createForAccount', [submissionDTO])
            .then((response) => new Submission(response));

        return {
            createSubmissionAndSwitchToQuoteAndBuy: (accountNumber) => {
                const today = new Date();
                const submission = {
                    baseData: {
                        productCode: $rootScope.productCode,
                        accountNumber,
                        periodStartDate: {
                            year: today.getFullYear(),
                            month: today.getMonth(),
                            day: today.getDate()
                        }
                    }
                };
                const newTab = $window.open('', '_blank');
                return PolicyService.getQNBDomain_AND()
                    .then((url) => createForAccount(submission)
                        .then(savedSubmission => {
                            const dob = savedSubmission.baseData.accountHolder.dateOfBirth;
                            newTab.location.href = `${url}#/ampquoteentry?quoteID=${savedSubmission.quoteID}&postalCode=${savedSubmission.baseData.policyAddress.postalCode}&dateOfBirth=${dob.day}-${dob.month}-${dob.year}&c=${$rootScope.policyContext.currentlyCovered || $rootScope.policyContext.scheduled}`;
                        }))
                    .catch(() => {
                        newTab.close();
                        $state.go('technicalError_AND');
                    });
            },

            amend: (savedQuote, amend) => {
                const newTab = $window.open('', '_blank');
                return PolicyService.getQNBDomain_AND()
                    .then(url => {
                        const dob = savedQuote.dateOfBirth;
                        newTab.location.href = `${url}#/ampquoteentry?quoteID=${savedQuote.quoteNumber}&postalCode=${savedQuote.postalCode}&dateOfBirth=${dob.day}-${dob.month}-${dob.year}&c=${$rootScope.policyContext.currentlyCovered || $rootScope.policyContext.scheduled}&amend=${amend}`;
                    })
                    .catch(() => {
                        newTab.close();
                        $state.go('technicalError_AND');
                    });
            }
        };
    }
];
function daysInMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
}

function today(increment = 0) {
    const date = new Date();
    if (increment) {
        date.setDate(date.getDate() + increment);
    }
    date.setHours(0, 0, 0, 0);
    return {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate()
    };
}

function now_AND(increment = 0) {
    let date = new Date();
    date.setSeconds(0);
    date.setMilliseconds(0);
    if (increment) {
        date = new Date(date.getTime() + (increment * 60000));
    }
    return date;
}

function addDays_AND(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

/**
 * Format Days and Months to be 0-padded.
 * @param {int} value
 * @returns {string}
 * @private
 */
function _formatDate_AND(value) {
    return (`0${value}`).slice(-2);
}

function toString_AND(dto) {
    return `${_formatDate_AND(dto.day)}/${_formatDate_AND(dto.month + 1)}/${dto.year}`;
}

/**
 * This class is used in conjunction with the Expression Language only. Do not call directly.
 */
export default {
    daysInMonth: daysInMonth,
    daysInLocalDate: ld => daysInMonth(ld.year, ld.month),
    toMidnightDate: localDate => {
        if (localDate) {
            // ANDIE - supports moment objects as well
            if (localDate._isAMomentObject) {
                return new Date(Date.UTC(localDate.year(), localDate.month(), localDate.date()));
            }
            return new Date(Date.UTC(localDate.year, localDate.month, localDate.day));
        }
        return null;
    },
    today,
    toString_AND,
    now_AND,
    addDays_AND
};

import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, TranslateService, StateUtils, claimViewModel, ScrollService_AND, LoadSaveService) => {
    const ctrl = Object.create(BaseCtrl);
    const flowModel = StateUtils.activeDataForState(true).flowModel;

    ctrl.init = function () {
        $scope.claimVm = claimViewModel();

        $scope.isActive = {};
        if ($scope.claimVm.lobs.personalMotor.vehicleIncidents.value.length === 0) {
            $scope.claimVm.lobs.personalMotor.value.createVehicleIncident(true);
            $scope.activeIncident = $scope.claimVm.lobs.personalMotor.vehicleIncidents.getLastElement();
            $scope.activeIncident.collision.value = true;
        }
        if (!$scope.activeIncident) {
            $scope.activeIncident = $scope.claimVm.lobs.personalMotor.vehicleIncidents.getElement(0);
        }
    };

    ctrl.addVehicleIncident = function (vehiclesForm) {
        vehiclesForm.submitted = true;
        if (vehiclesForm.valid) {
            // Save the information before attempting to add another vehicle incident
            LoadSaveService.saveClaim($scope.claimVm.value)
                .then((claim) => {
                    $scope.claimVm = claim;
                    // User should be only able to add 2 more vehicles except their own
                    if ($scope.claimVm.lobs.personalMotor.vehicleIncidents.length < 3) {
                        $scope.claimVm.lobs.personalMotor.value.createVehicleIncident();
                        $scope.activeIncident = $scope.claimVm.lobs.personalMotor.vehicleIncidents.getLastElement();
                        $scope.activeIncident.collision.value = true;
                        $scope.activeIncident.active = true;
                        $scope.activeIncident.value.setNewVehicle();
                        $scope.activeIncident.value.setNewDriver();
                        vehiclesForm.submitted = false;
                        vehiclesForm.setPristine();
                        ScrollService_AND.scrollToTop();
                    } else {
                        flowModel.jumpToExit('error');
                    }
                })
                .catch(() => {
                    flowModel.jumpToExit('error');
                });
        }
    };

    ctrl.removeVehicleIncident = function (vehicleIncident) {
        if ($scope.claimVm.lobs.personalMotor.vehicleIncidents.length > 1) {
            $scope.claimVm.lobs.personalMotor.value.removeVehicleIncident(vehicleIncident.value);
        }
        $scope.activeIncident = $scope.claimVm.lobs.personalMotor.vehicleIncidents.getLastElement();
    };

    ctrl.getVehicleTabHeading = function (vehicleIncident) {
        const vehicle = vehicleIncident.vehicle.value;
        if (vehicle && vehicle.policyVehicle) {
            return TranslateService.instant('and.fnol.pm.controllers.PmCollisionVehicleCtrl.YourVehicle');
        }
        const tabHeading = TranslateService.instant('and.fnol.pm.controllers.PmCollisionVehicleCtrl.OtherVehicle');
        if (vehicle && vehicle.make && vehicle.model) {
            return `${tabHeading}: ${vehicle.make} ${vehicle.model}`;
        }
        return tabHeading;
    };

    ctrl.isTabValid = function (vehicleIncident) {
        const isValid = vehicleIncident.aspects.valid;
        return (typeof isValid !== 'undefined' && !isValid) ? 'error' : '';
    };

    ctrl.next = function (form) {
        form.submitted = true;
        flowModel.next($scope.claimVm);
    };

    ctrl.previous = function () {
        flowModel.previous($scope.claimVm);
    };
    ctrl.cancel = function () {
        flowModel.cancel($scope.claimVm);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'TranslateService', 'StateUtils', 'claimViewModel', 'ScrollService_AND', 'fnol.LoadSaveService'];

export default fn;
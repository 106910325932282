import routeConfig from 'edge/fnol/pm/config/RouteConfig';
import VehicleDamagePicker from 'edge/fnol/pm/directives/VehicleDamagePicker';
import VehicleDamageablePart from 'edge/fnol/pm/directives/VehicleDamageablePart';
import DamageList from 'edge/fnol/pm/directives/DamageList';
import VehicleCollision from 'edge/fnol/pm/directives/VehicleCollision';
import AddNewVehicle from 'edge/fnol/pm/directives/AddNewVehicle';
import AddNewDriver from 'edge/fnol/pm/directives/AddNewDriver';
import VehicleIncident from 'edge/fnol/pm/directives/VehicleIncident';
import RepairFacilitiesMap from 'edge/fnol/pm/components/RepairFacilitiesMap';
import RepairFacilitiesList from 'edge/fnol/pm/components/RepairFacilitiesList';
import RepairFacilitiesListItem from 'edge/fnol/pm/components/RepairFacilitiesListItem';
import RepairFacilitySelector from 'edge/fnol/pm/components/RepairFacilitySelector';
import RepairFacilitiesSearch from 'edge/fnol/pm/components/RepairFacilitiesSearch';
import RepairFacilityDetails from 'edge/fnol/pm/components/RepairFacilityDetails';
import RepairFacilitiesSearchOption from 'edge/fnol/pm/components/RepairFacilitiesSearchOption';
import RepairFacilityRating from 'edge/fnol/pm/components/RepairFacilityRating';
import MapTypeControl from 'edge/fnol/pm/components/MapTypeControl';
import MapSearch from 'edge/fnol/pm/components/MapSearch';
import 'edge/fnol/common/app';

export default angular.module('edge.fnol.pm.app', ['edge.fnol.common.app']).config(['$stateProvider', 'state_plugin_config', function ($stateProvider, RootStateConfig) {
    var states = routeConfig(RootStateConfig);
    for (var stateName in states) {
        if (states.hasOwnProperty(stateName)) {
            $stateProvider.state(stateName, states[stateName]);
        }
    }
}]).constant('repairMapInitialZoom', 13).constant('repairMapSearchRadius', 70.0).constant('repairMapDelay', 200).directive('gwFnolPaVehicleDamagePicker', VehicleDamagePicker).directive('gwFnolPaDamageList', DamageList).directive('gwFnolPaVehicleCollision', VehicleCollision).directive('gwFnolPaAddNewVehicle', AddNewVehicle).directive('gwFnolPaAddNewDriver', AddNewDriver).directive('gwFnolPaVehicleIncident', VehicleIncident).directive('gwFnolPaVehicleDamageablePart', VehicleDamageablePart).component('gwFnolPaRepairFacilitiesMap', RepairFacilitiesMap).component('gwFnolPaRepairFacilitiesList', RepairFacilitiesList).component('gwFnolPaRepairFacilitiesListItem', RepairFacilitiesListItem).component('gwFnolPaRepairFacilitySelector', RepairFacilitySelector).component('gwFnolPaRepairFacilitiesSearch', RepairFacilitiesSearch).component('gwFnolPaRepairFacilityDetails', RepairFacilityDetails).component('gwFnolPaRepairFacilitiesSearchOption', RepairFacilitiesSearchOption).component('gwFnolPaRepairFacilityRating', RepairFacilityRating).component('gwFnolPaMapTypeControl', MapTypeControl).component('gwFnolPaMapSearch', MapSearch);
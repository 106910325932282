import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, claimViewModel) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.claimVm = claimViewModel();
    };

    ctrl.next = (nextFn) => {
        nextFn($scope.claimVm);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'claimViewModel'];

export default fn;

import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./SignupEnrollment.html';
import styles from './SignupEnrollment-hashed.scss';
import appFeatures from 'effective-features!';
import AuthenticationService from 'plugin?auth-type!gw-portals-auth-js/AuthenticationService';
// VARS
const hashedTemplate = cssUtil.hashTemplate(template, styles);

// EXPORT
export default {
    template: hashedTemplate,
    controller: [
        '$scope', '$state', 'state_plugin_config', 'enrollment.EnrollmentService', 'gwAuthTokenService', 'oAuth',
        function ($scope, $state, STATE_PLUGIN_CONFIG, EnrollmentService, AuthTokenService, oAuth) {
            // VARS
            const $ctrl = this;
            $ctrl.enrollmentError = '';// errors
            $ctrl.isEnrollmentReqInProgress = false;// animation
            $ctrl.isMultiaccount = EnrollmentService.isMultiaccount();
            $ctrl.input = {aspects: {}};
            const authenticationService = AuthenticationService(oAuth);

            // HELPERS
            function setEnrollmentType() {
                // check if userprofile is provided
                const checkForEnrollment = appFeatures['self-enrollment'];
                if (!checkForEnrollment || !checkForEnrollment.type) {
                    return undefined;
                }
                if (checkForEnrollment.type === 'account') {
                    return 'ACCOUNT';
                }
                if (checkForEnrollment.type === 'policy') {
                    return 'POLICY';
                }
                return undefined;
            }

            $ctrl.enrollmentType = setEnrollmentType();

            // METHODS
            const setCtrlParam = (paramName, value) => {
                $scope.$applyAsync(() => {
                    $ctrl[paramName] = value;
                });
            };

            function setEnrollmentError(value) {
                setCtrlParam('enrollmentError', value);
            }

            $ctrl.enrolUser = function () {
                setEnrollmentError('');// reset errors

                if (!$ctrl.policyNumber || !$ctrl.addressLine1) { // check if empty
                    setEnrollmentError('fieldEmpty');
                    return;
                }

                setCtrlParam('isEnrollmentReqInProgress', true);// animation

                EnrollmentService.addEnrollmentRecord({
                    details: {
                        policyNumber: $ctrl.policyNumber,
                        addressLine1: $ctrl.addressLine1
                    },
                    type: $ctrl.enrollmentType
                }).then(() => {
                    return AuthTokenService.refreshAccessToken(true).then(() => {
                        setEnrollmentError('');
                        setCtrlParam('isEnrollmentReqInProgress', false);// animation
                        $state.go(STATE_PLUGIN_CONFIG.home); // redirect user to home view
                    });
                }).catch((error) => {
                    setCtrlParam('isEnrollmentReqInProgress', false);// animation
                    if (error && error.baseError && error.baseError.error.message === '616') {
                        setEnrollmentError('enrollmentInvalid');
                    } else {
                        setEnrollmentError('enrollmentFailed');
                    }
                });
            };

            $ctrl.enrollLabelKey = () => {
                if ($ctrl.enrollmentType === 'POLICY') {
                    return 'enrollment.components.signupenrollment.Add Policy';
                }
                return 'enrollment.components.signupenrollment.Add Account';
            };

            $ctrl.enrollmentInstructionsLabelKey = () => {
                if ($ctrl.isMultiaccount) {
                    return 'enrollment.components.signupenrollment.You will be able to add access to other policies once you log in';
                }
                return 'enrollment.components.signupenrollment.Simply complete all fields for online access to your policy information';
            };

            $ctrl.formHeaderLabelKey = () => {
                if ($ctrl.enrollmentType === 'POLICY') {
                    return 'enrollment.components.signupenrollment.Please provide verification information for the policy you wish to access';
                }
                return 'enrollment.components.signupenrollment.Please provide verification information for the account you wish to access';
            };

            $ctrl.logout = function () {
                authenticationService.logout();
            };
        }]
};
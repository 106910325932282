import template from 'text!./templates/pm-address-change-edit.html';

export default [() => ({
    template,
    'restrict': 'E',
    'scope': {
        policyChange: '=',
        view: '=',
        next: '&'
    },
    'controller': ['$scope', 'ModalService',
        function ($scope) {
            $scope.policyChange.lobData.personalMotor.coverables.updateGarageLocation = true;

            /**
             * Next button is clicked
             */
            $scope.onNext = () => {
                // Call next method on parent
                $scope.next();
            };
        }
    ]
})];

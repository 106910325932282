import Template from 'text!edge/policy/your-insurance_AND/views/your-insurance.html';
import Controller from 'edge/policy/your-insurance_AND/controllers/YourInsuranceCtrl';

export default (RootStates) => {
    const parentState = RootStates.yourInsurance_AND;

    return {
        yourInsurance_AND: {
            name: 'yourInsurance_AND',
            parent: parentState,
            url: '/your-insurance?policyID',
            template: Template,
            controller: Controller
        }
    };
};

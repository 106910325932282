import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, claimViewModel, $filter) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.claimVm = claimViewModel();
        $scope.lossDescription = {
            value: $scope.claimVm.description.value,
            aspects: {
                inputCtrlType: 'text',
                required: true,
                get valid() {
                    return !!$scope.lossDescription.value && $scope.claimVm.description.aspects.valid === true;
                },
                get validationMessages() {
                    if (!$scope.lossDescription.value) {
                        return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                    } else if ($scope.claimVm.description.aspects.valid !== true) {
                        return $scope.claimVm.description.aspects.validationMessages;
                    }
                    return [];
                }
            }
        };
    };

    ctrl.next = (flowNextFn, form) => {
        if (form.valid) {
            $scope.claimVm.description.value = $scope.lossDescription.value;
            return flowNextFn($scope.claimVm);
        }
        if ($scope.fnol.lossLocationType === 'map') {
            $scope.fnol.lossLocationType = 'city_only';
        }
        return flowNextFn();
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'claimViewModel', '$filter'];

export default fn;

import {get} from 'lodash';
import PersonalizedOffersUtil from '../../PersonalizedOffersUtil';

class RectangleAdCtrl {
    constructor() {
        this.$onChanges = () => {
            const relativeUrl = get(this, 'content.value.url', '');
            this.imagePath = PersonalizedOffersUtil.concatUrl(this.urlPrefix, relativeUrl);
        };

    }

}
export default RectangleAdCtrl;
import _ from 'underscore';

function Address(data) {
    this.country = null;
    this.addressLine1 = null;
    this.addressLine2 = null;
    this.addressLine3 = null;
    this.city = null;
    this.state = null;
    this.postalCode = null;
    this.addressType = 'home';
    this.description = null; // ANDIE

    if (data) {
        _.extend(this, data);
    }
}

export default Address;

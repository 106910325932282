import templateStr from 'text!./templates/policy-payment-schedule.html';
import moment from 'moment';

export default () => {
    return {
        restrict: 'E',
        template: templateStr,
        scope: {
            policyInvoiceStream: '=',
            scheduleFilter: '='
        },
        controller: ['$scope', 'InsuranceSuiteMetadata', ($scope, InsuranceSuiteMetadata) => {
            const invoiceStatusTypeList = InsuranceSuiteMetadata.get('bc').types.getTypelist('InvoiceStatus');

            /**
             * Get translated display name for invoice status
             * @param {Object} invoiceStatus
             * @returns {string|null}
             */
            $scope.getInvoiceStatus = (invoiceStatus) => {
                const typeKey = invoiceStatusTypeList.getCode(invoiceStatus);
                return typeKey ? typeKey.name : null;
            };

            /**
             * Filter used by the ng-repeat to show only paid or unpaid invoice items
             * @param {Object} invoiceItem
             * @returns {Boolean}
             */
            $scope.paymentScheduleFilter = (invoiceItem) => {
                if ($scope.scheduleFilter === 'paid') {
                    return invoiceItem.paidStatus === 'fullypaid' || invoiceItem.totalPaid.amount !== 0;
                }

                if ($scope.scheduleFilter === 'unpaid') {
                    return invoiceItem.paidStatus === 'unpaid';
                }

                return true;
            };

            /**
             * Returns true if an invoice is not settled and the due date has expired
             * @param {Object} invoiceItem
             * @returns {boolean}
             */
            $scope.isOverDue = (invoiceItem) => {
                return invoiceItem.paidStatus === 'unpaid' && moment(invoiceItem.dueDate).isBefore(Date.now(), 'day');
            };

        }]
    };
};
import templateStr from 'text!./templates/billing-line-policies-summary.html';

export default () => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            gwBillingPaymentLinePoliciesSummary: '='
        },
        controller: [() => {
        }]
    };
};
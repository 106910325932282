import templateStr from 'text!./pm-quote-review-card-mta.html';

export default [() => {
    return {
        restrict: 'E',
        transclude: true,
        scope: {
            cardTitle: '@',
            cardSubtitle: '<',
            cardDescription: '<',
            isDisabled: '<?',
            hideViewEditHeader: '<?'
        },
        template: templateStr
    };
}];
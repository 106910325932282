import _ from 'underscore';
import PolicySearchUtil from 'gw-portals-util-angular/PolicySearchUtil';
import templateStr from 'text!./templates/policy-search-criteria.html';

export default ['LocaleService', (LocaleService) => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            'criteria': '=',
            'search': '=',
            'resetSearch': '=',
            'allowedPolicyTypes': '=',
            'policySearchPromise': '&'
        },
        controller: ['$scope', 'ViewModelService', ($scope, ViewModelService) => {
            $scope.criteriaVM = ViewModelService.create($scope.criteria, 'cc', 'edge.capabilities.claim.fnol.policy.dto.PolicySearchCriteriaDTO');
            $scope.criteriaVM.country.value = $scope.criteriaVM.country.value || LocaleService.getCountry().code;

            $scope.policyTypes = _.filter($scope.criteriaVM.policyType.aspects.availableValues, (type) => {
                return _.contains($scope.allowedPolicyTypes, type.code);
            });

            $scope.searchIsBlank = PolicySearchUtil.searchIsBlank;

            /**
             * Reset the search criteria form
             */
            $scope.resetSearchCriteria = () => {
                $scope.criteria.firstName = null;
                $scope.criteria.lastName = null;
                $scope.criteria.policyType = null;
                $scope.criteria.city = null;
                $scope.criteria.state = null;
                $scope.criteria.zip = null;

                if ($scope.resetSearch) {
                    $scope.resetSearch();
                }
            };
        }]
    };
}];
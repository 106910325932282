export default ['JsonRPCService', (JsonRPCService) => {
    return {
        getAccountContactSummary: () => {
            return JsonRPCService.send('pc', 'profileinfo/user', 'getAccountContactSummary', []);
        },

        getAccountsContactSummaries: () => {
            return JsonRPCService.send('pc', 'profileinfo/user', 'getAccountsContactSummaries', []);
        },

        updateAccountContactSummary: (activitySummaryDTO) => {
            return JsonRPCService.send('pc', 'profileinfo/user', 'updateAccountContactSummary', [activitySummaryDTO]);
        },

        getInvoiceDeliveryMethod: () => {
            return JsonRPCService.send('bc', 'billing/billingData', 'getInvoiceDeliveryMethod', []);
        },

        getInvoiceDeliveryMethods: () => {
            return JsonRPCService.send('bc', 'billing/billingData', 'getInvoiceDeliveryMethods', []);
        },

        setInvoiceDeliveryMethod: (newStatus) => {
            return JsonRPCService.send('bc', 'billing/billingData', 'setInvoiceDeliveryMethod', [newStatus]);
        },

        setInvoiceDeliveryMethodForAccount: (newStatus, accountNumber) => {
            return JsonRPCService.send('bc', 'billing/billingData', 'setInvoiceDeliveryMethodForAccount', [newStatus, accountNumber]);
        }
    };
}];

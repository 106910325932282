import templateStr from 'text!./pm-hear-about-us.html';

export default [() => {
    return {
        restrict: 'A',
        scope: {
            contact: '=',
        },
        template: templateStr,
        controller: [
            '$scope', '$q', 'StaticDocumentsService_AND', '$rootScope', ($scope, $q, StaticDocumentsService_AND, $rootScope) => {
                $q.all([
                    StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_POLICY),
                    StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_NOTICE),
                ]).then((result) => {
                    $scope.privacyPolicyDoc = result[0];
                    $scope.privacyNoticeDoc = result[1];
                });

                if ($scope.contact.serviceGamification.value === undefined) {
                    $scope.contact.serviceGamification = false;
                }

                $scope.$watch('contact.marketingPrefOptOut_itb.value', (newValue) => { 
                    if (newValue === undefined || newValue === false) {
                        $scope.contact.marketingPrefOptOut_itb.value = false;
                        $scope.contact.marketingPrefEmail_itb.value = true;
                        $scope.contact.marketingPrefSMS_itb.value = true;
                        $scope.contact.marketingPrefVoiceMob_itb.value = true;
                        $scope.contact.marketingPrefLandline_itb.value = true;
                        $scope.contact.marketingPrefPost_itb.value = true;
                        $scope.contact.marketingPrefMobileApp_itb.value = true;
                    } else if (newValue === true) {
                        $scope.contact.marketingPrefOptOut_itb.value = true;
                        $scope.contact.marketingPrefEmail_itb.value = false; 
                        $scope.contact.marketingPrefSMS_itb.value = false;
                        $scope.contact.marketingPrefVoiceMob_itb.value = false;
                        $scope.contact.marketingPrefLandline_itb.value = false;
                        $scope.contact.marketingPrefPost_itb.value = false;
                        $scope.contact.marketingPrefMobileApp_itb.value = false;
                    }
                });
            }

            
        ]
    };
}];
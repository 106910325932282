import templateStr from 'text!./templates/payment-instrument-bc3.html';

export default ['TranslateService', 'paymentType', (TranslateService, paymentType) => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            model: '=gwIntPaymentInstrument'
        },
        link: ($scope) => {
            $scope.paymentType = paymentType;
            $scope.paymentMethodOptions = [{
                value: paymentType.WIRE,
                displayKey: 'amp.bc3.directives.templates.payment-instrument-bc3.Bank Account',
                displayValue: ''
            }, {
                value: paymentType.CREDIT_CARD,
                displayKey: 'amp.bc3.directives.templates.payment-instrument-bc3.CreditCard',
                displayValue: ''
            }];

            $scope.paymentMethodOptions.forEach((paymentMethodOption) => {
                TranslateService.translate({
                    object: paymentMethodOption,
                    displayKey: paymentMethodOption.displayKey,
                    propName: 'displayValue',
                    scope: $scope
                });
            });
        }
    };
}];
import templateStr from 'text!./templates/editable-coverages.html';
import _ from 'underscore';
import Coverage from '../models/Coverage';

let guid = 0;

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            coverages: '=gwAmpEditableCoverages',
            loading: '=',
            onCovChange: '&'
        },
        controller: ['$scope', ($scope) => {
            $scope.uid = `covs${guid++}`;

            function isLoading() {
                return $scope.loading;
            }

            function onChange() {
                if ($scope.onCovChange) {
                    $scope.onCovChange();
                }
            }

            function setNewModel() {
                $scope.model = $scope.coverages.map(_.partial(Coverage.create, _, isLoading, onChange));
            }

            setNewModel();
            $scope.$watch('coverages', setNewModel);
        }]
    };
}];

import PolicyChange from 'edge/endorsement/common/models/PolicyChange';
import moment from 'moment';
import _ from 'underscore';

export default (function ($q, EndorsementService, AvailabilityService) {

    var ACTIONS = {
        saveEndorsement: function saveEndorsement(model) {
            return EndorsementService.saveEndorsement(model.policyChange).then(function (pc) {
                model.policyChange = new PolicyChange(pc);
                return model;
            });
        },
        NoOp: function NoOp(model) {
            return $q.resolve(model);
        }, // No async operation occurs when transitioning from vehicles/drivers
        quote: function quote(model) {
            return EndorsementService.quoteEndorsement(model.policyChange.jobID).then(function (pc) {
                model.policyChange = new PolicyChange(pc);
                return model;
            });
        },
        saveCoverages: function saveCoverages(model) {
            var lobCoverages = _.mapObject(model.policyChange.lobData, function (lob) {
                return lob.offerings[0].coverages;
            });

            return EndorsementService.updateCoverages(model.policyChange.jobID, lobCoverages).then(function (pc) {
                model.policyChange = new PolicyChange(pc);
                return model;
            });
        },
        startEndorsement: function startEndorsement(model) {
            return ACTIONS.saveEndorsement(model);
        },
        checkAvailability: function checkAvailability(model) {
            // This checks that the product is available and then saves the policy change if it is.
            var availabilityDTO = {
                address: model.policyChange.baseData.policyAddress,
                effectiveDate: moment(model.policyChange.baseData.effectiveDate).format(),
                productCode: model.policyChange.baseData.productCode
            };
            var defered = $q.defer();
            AvailabilityService.checkAvailabilityBasedOnAddress(availabilityDTO).then(function (response) {
                // If available we then we update the historyand save the policy change, otherwise we reject
                if (response.isAvailable) {
                    model.policyChange.addToHistory('changed', 'address', model.policyChange.baseData.policyAddress);
                    ACTIONS.saveEndorsement(model).then(function (savedModel) {
                        defered.resolve(savedModel);
                    }, function (error) {
                        defered.reject(error);
                    });
                } else {
                    defered.reject(model);
                }
            }, function (error) {
                return defered.reject(error);
            });
            return defered.promise;
        }
    };

    var ACTION_MESSAGES = {
        saveCoverages: {
            error: {
                title: 'endorsement.pa.config.Unable to save coverages change',
                message: 'endorsement.pa.config.An error occurred while attempting to save coverage changes'
            },
            progress: {
                message: 'endorsement.pa.config.Saving coverage changes'
            }
        },
        NoOp: { // No async operation occurs when transitioning from vehicles/drivers
            error: {
                title: 'endorsement.common.config.Unable to save draft policy change',
                message: 'endorsement.common.config.An error occurred while attempting to save the policy change.'
            },
            progress: {
                message: 'endorsement.common.config.Saving policy change'
            }
        },
        saveEndorsement: {
            error: {
                title: 'endorsement.common.config.Unable to save draft policy change',
                message: 'endorsement.common.config.An error occurred while attempting to save the policy change.'
            },
            progress: {
                message: 'endorsement.common.config.Saving policy change'
            }
        },
        startEndorsement: {
            error: {
                title: 'endorsement.common.config.Unable to start policy change',
                message: 'endorsement.common.config.An error occurred while attempting to start the policy change.'
            },
            progress: {
                message: 'endorsement.common.config.Starting policy change'
            }
        },
        quote: {
            error: {
                title: 'endorsement.common.config.Unable to quote policy change',
                message: 'endorsement.common.config.An error occurred while attempting to quote the policy change.'
            },
            progress: {
                message: 'endorsement.common.config.Quoting policy change'
            }
        },
        checkAvailability: {
            error: {
                title: 'endorsement.pa.config.Not Available',
                message: 'endorsement.pa.config.The product is not available for your address'
            },
            progress: {
                message: 'endorsement.pa.config.Checking product availability'
            }
        }
    };

    return function (step, junction) {

        junction('PmEntry', true).onNext.doAction(ACTIONS.startEndorsement, ACTION_MESSAGES.startEndorsement).thenBranch(function (model) {
            if (model.nextSteps) {
                if (model.nextSteps.address) {
                    return 'PmAddress';
                }
                if (model.nextSteps.vehicles) {
                    return 'PmVehicle';
                }
                if (model.nextSteps.drivers) {
                    return 'PmDriver';
                }
                return 'PmCoverages';
            }
        }, function () {
            return 'PmEndorsementError';
        });

        step('PmAddress').onNext.doAction(ACTIONS.checkAvailability, ACTION_MESSAGES.checkAvailability).thenBranch(function (model) {
            if (model.nextSteps.vehicles) {
                return 'PmVehicle';
            }
            if (model.nextSteps.drivers) {
                return 'PmDriver';
            }
            return 'PmCoverages';
        }, function () {
            return 'PmEndorsementError';
        });

        step('PmVehicle').onNext.doAction(ACTIONS.NoOp, ACTION_MESSAGES.saveEndorsement).thenBranch(function (model) {
            return model.nextSteps.drivers ? 'PmDriver' : 'PmCoverages';
        }, function () {
            return 'PmEndorsementError';
        }).isValid(function (model) {
            return model.nextSteps.drivers || model.policyChange.validate();
        });

        step('PmDriver').onNext.doAction(ACTIONS.NoOp, ACTION_MESSAGES.saveEndorsement).thenGoTo('PmCoverages', 'PmEndorsementError').isValid(function (model) {
            return model.policyChange.validate();
        });

        step('PmCoverages').onNext.doAction(ACTIONS.saveCoverages, ACTION_MESSAGES.saveCoverages, { forceUpdate: true }).thenGoTo('PmPreQuote', 'PmEndorsementError');

        step('PmPreQuote').onNext.doAction(ACTIONS.quote, ACTION_MESSAGES.quote, { forceUpdate: true }).thenGoTo('PmDone', 'PmEndorsementError');

        junction('PmEndorsementError');
        step('PmDone');
    };
});
import template from 'text!./templates/pm-select-changes.html';

export default [() => ({
    template,
    'scope': {
        'model': '=',
        'mode': '=',
        'onNext': '='
    },
    'controller': ['$scope', ($scope) => {
        const VALID_FIELDS = ['address', 'drivers', 'vehicles', 'coverages'];
        $scope.model = $scope.model || {};

        $scope.isValid = () => {
            return VALID_FIELDS.some((field) => $scope.model[field]);
        };

        $scope.next = () => {
            $scope.onNext({
                'validFields': VALID_FIELDS
            });
        };

        $scope.getState = () => {
            if ($scope.mode !== 'full') {
                return 'disabled';
            }
            return '';
        };

    }]
})];
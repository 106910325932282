import template from 'text!./templates/endorsement-history.html';

export default [() => ({
    template,
    'scope': {
        'policyChange': '=',
        'transitionFn': '=',
        'nextSteps': '=',
        'canEditState': '='
    },
    'controller': ['$scope', 'endorsement.GenericProductCodeUtil', ($scope, GenericProductCodeUtil) => {
        $scope.getProductCode = (productCode) => {
            return GenericProductCodeUtil.getGenericProductCode(productCode);
        };
    }]
})];
import * as d3 from 'd3';
import './lineChart.scss';

class LineChart {

    constructor() {
        this.scope = {
            'data': '=',
            'width': '@'
        };
    }

    link(scope, element) {
        this.container = d3.select(element[0])
            .append('div')
            .attr('class', 'gw-line-chart-container');

        const draw = () => {
            // remove all previous items before render
            this.container.selectAll('*').remove();

            const data = scope.data;

            if (!data || data.length !== 2) {
                throw new Error('Invalid data param');
            }

            const firstValue = data[0];
            const secondValue = data[1];
            const total = data.reduce((v1, v2)=> v1 + v2.value, 0);

            const colors = ['#50C7A7', '#DDDDDD'];
            const space = 2; // space between two rects
            const chartHeight = 10;
            const chartWidth = scope.width || 102;

            const calcRectWidth = ({value}) => (value * chartWidth) / total;

            let xAxis = 0;

            const addText = (container, text, className) => {
                return container.append('div')
                    .text(text)
                    .style('width', `${chartWidth}px`)
                    .attr('class', `text ${className}`);
            };

            this.container.style('width', chartWidth);

            addText(this.container, firstValue.hint || '', 'left');

            this.container.append('svg')
                .attr('style', `height: ${chartHeight}px; width: ${chartWidth}px;`)
                .selectAll('rect')
                .data(data)
                .enter()
                .append('rect')
                .style('fill', (d, i) => d.color || colors[i])
                .attr('class', 'rect')
                .attr('width', calcRectWidth)
                .attr('height', chartHeight)
                .attr('y', chartHeight / 2)
                .attr('x', (d, i) => {
                    if (i > 0) {
                        xAxis += space;
                    }
                    const result = xAxis;
                    xAxis += calcRectWidth(d);
                    return result;
                });

            addText(this.container, secondValue.hint || '', 'right');
        };

        scope.$watch('data', draw);
        draw();
    }

}

export default () => new LineChart();
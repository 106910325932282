import templateStr from 'text!./templates/user-details.html';

export default () => {
    return {
        restrict: 'E',
        template: templateStr,
        scope: {
            contact: '=',
            readonly: '=',
            hideFields: '=',
            nameReadonly: '='
        }
    };
};

import Chart from 'chart.js';

export default ['brandingData', (brandingData) => {
    const BRAND_COLOR = {
        tbb: {
            default: '#1c315f',
            selected: '#191D3B'
        },
        dgt: {
            default: '#F6C8DE',
            selected: '#F97AB1'
        },
        itb: {
            default: '#448E00',
            selected: '#2D5A00'
        }
    };

    const tooltip = function (tooltipModel) {
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
            tooltipEl.classList.add('no-transform');
        }

        // Set Text
        if (tooltipModel.body) {
            const miles = tooltipModel.body.map((bodyItem) => bodyItem.lines)[0];
            tooltipEl.innerHTML = `<strong>${miles} miles</strong>`;
        }

        // `this` will be the overall tooltip
        const position = this._chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
        tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.backgroundColor = 'white';
        tooltipEl.style.border = '1px solid #373737';
    };

    const getXAxisDataset = function (chart, event, items) {
        if (items[0]) { // If on a dataset
            return items[0]._index;
        }
        const dataset = chart.getElementsAtEventForMode(event, 'x', 1)[0];
        if (angular.isDefined(dataset)) { // If on a label
            return dataset._index;
        }
    };

    const getColor = function (data, selectedIndex) {
        const colorArray = [];
        for (let i = 0; i < data.length; i++) {
            if (i === selectedIndex) {
                colorArray.push(BRAND_COLOR[brandingData].selected);
            } else {
                colorArray.push(BRAND_COLOR[brandingData].default);
            }

        }
        return colorArray;
    };

    return {
        getChart: function ({id, data, labels, xLabel, yLabel, onClick, selectedIndex}) {
            const colorArray = getColor(data, selectedIndex);
            const options = {
                scales: {
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: yLabel
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                legend: { display: false },
                tooltips: {
                    enabled: false,
                    custom: tooltip
                }
            };
            if (onClick) {
                options.onClick = function (event, items) {
                    const index = getXAxisDataset(this, event, items);
                    if (data[index]) {
                        for (let i = 0; i < colorArray.length; i++) {
                            if (i === index) {
                                colorArray[i] = BRAND_COLOR[brandingData].selected;
                            } else {
                                colorArray[i] = BRAND_COLOR[brandingData].default;
                            }
                        }
                    }
                    this.update(); // Needs an update to refresh the graph
                    onClick.call(this, index);
                };
                options.onHover = function (e, items) {
                    let cursor = 'default';
                    const index = getXAxisDataset(this, e, items);
                    if (angular.isDefined(index) && !!data[index]) { // If on a label and there is data
                        cursor = 'pointer';
                    }

                    $(`#${id}`).css('cursor', cursor);
                };
            }
            if (xLabel) {
                options.scales.xAxes = [{
                    scaleLabel: {
                        display: true,
                        labelString: xLabel
                    },
                }];
            }

            return new Chart(id, {
                options,
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [{
                        data: data,
                        backgroundColor: colorArray,
                        borderWidth: 0
                    }]
                }
            });
        },
        updateChart: function (chart, data, labels) {
            // Remove data
            const labelsLength = chart.data.labels.length;
            for (let i = 0; i < labelsLength; i++) {
                chart.data.labels.pop();
            }
            chart.data.datasets.forEach((dataset) => {
                const dataLength = dataset.data.length;
                for (let i = 0; i < dataLength; i++) {
                    dataset.data.pop();
                }
            });
            // Add new data
            for (let i = 0; i < labels.length; i++) {
                chart.data.labels.push(labels[i]);
            }
            chart.data.datasets.forEach((dataset) => {
                for (let i = 0; i < data.length; i++) {
                    dataset.data.push(data[i]);
                }
            });
            // Update chart
            chart.update();
        }
    };
}];
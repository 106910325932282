import cssUtil from 'gw-portals-util-js/CssUtil';
import template from 'text!./PaginationNavigation.html';
import styles from './PaginationNavigation-hashed.scss';
import controller from './PaginationNavigationCtrl';

export default {
    template: cssUtil.hashTemplate(template, styles),
    controller,
    bindings: {
        loadByOffset: '&',
        onPaginationNavigation: '&',
        pageSize: '<',
        reset: '<',
        label: '<',
        externalDisplayPagination: '<',
        sharedPageNum: '<'
    }
};

import AuthenticationService from 'plugin?auth-type!gw-portals-auth-js/AuthenticationService';
import appFeatures from 'effective-features!';

const authFeatures = appFeatures.auth;
const authRoutes = {
    'auth': {
        name: 'auth',
        abstract: true,
        url: '/auth',
        template: '<and-auth-base />',
        data: {
            isAuthPage: true
        }
    },
    'authLogin': {
        name: 'authLogin',
        parent: 'auth',
        url: '/login?username',
        template: '<and-lock-login gw-auth-component/>', // ANDIE
        data: {
            errorMsg: null
        },
        params: {
            username: null
        },
        resolve: {
            proceedToAuthPage: ['oAuth', (oAuth) => {
                const authenticationService = AuthenticationService(oAuth);
                // we don't care if the login request failed or succeeded return a resolved promise
                return authenticationService.waitForLoginRequestComplete.catch(()=> true);
            }]
        }
    },
    'authActivateAccount': {
        name: 'authActivateAccount',
        parent: 'auth',
        url: '/activateaccount?{token}',
        template: '<div>activate account template</div>',
        data: {
            errorMsg: null
        }
    },
    'authSessionLogout': {
        name: 'authSessionLogout',
        parent: 'auth',
        url: '/authSessionLogout',
        template: '<gw-session-logout gw-auth-component/>',
        params: {
            origin: null
        },
        data: {
            errorMsg: null
        }
    }
};

if (authFeatures && authFeatures.signUpAllowed) {
    authRoutes.authSignUp = {
        name: 'authSignUp',
        parent: 'auth',
        url: '/signup',
        template: '<gw-sign-up gw-auth-component/>',
        data: {
            errorMsg: null
        }
    };
}

if (authFeatures && authFeatures.forgotPasswordAllowed) {
    authRoutes.authForgotPassword = {
        name: 'authForgotPassword',
        parent: 'auth',
        url: '/forgotpassword',
        template: '<and-forgot-password gw-auth-component/>', // ANDIE
        data: {
            errorMsg: null
        }
    };

    authRoutes.authResetPassword = {
        name: 'authResetPassword',
        parent: 'auth',
        url: '/resetpassword?{token}',
        template: '<and-reset-password gw-auth-component/>', // ANDIE
        data: {
            errorMsg: null
        }
    };
}


export default () => {
    return authRoutes;
};
import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, $filter, $translate, claimViewModel, ModalService) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.claimVm = claimViewModel();
        let policyType = $scope.claimVm.get().policy.policyType;
        policyType = policyType.charAt(0).toLowerCase() + policyType.slice(1);
        $scope.lobExtension = $scope.claimVm.lobs[policyType];
    };

    ctrl.next = (nextFn) => {
        nextFn($scope.claimVm);
    };

    ctrl.addInjuryIncident = () => {
        $scope.lobExtension.value.addInjuryIncident();
    };

    ctrl.removeInjuryIncident = (injuryIncident, index) => {
        ModalService.showWarning(
            'fnol.general.views.what.modal.Remove Injured',
            $translate('fnol.general.views.what.modal.Are you sure you want to remove Injured #{{injuredIndex}}?', {
                injuredIndex: index
            }),
            null,
            $filter('translate')('fnol.general.views.what.modal.Remove')
        ).result.then(() => $scope.lobExtension.value.removeInjuryIncident(injuryIncident.value));
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$filter', '$translate', 'claimViewModel', 'ModalService'];

export default fn;

import Contact from 'edge/fnol/common/models/Contact';
import EntityUtil from 'gw-portals-util-js/EntityUtil';

export default class GeneralClaimExtension {
    constructor(_claim, lob) {
        Object.defineProperty(this, '_claim', {
            enumerable: false,
            value: _claim
        });

        const lobSection = this._claim.lobs[lob];
        this.fixedPropertyIncident = (lobSection && lobSection.fixedPropertyIncident)
            ? lobSection.fixedPropertyIncident
            : {propertyDescription: undefined};
        this.injuryIncidents = (lobSection && lobSection.injuryIncidents) ? lobSection.injuryIncidents : [];
    }

    addInjuryIncident() {
        const injured = new Contact({tempID: EntityUtil.nextId()});

        this.injuryIncidents.push({
            tempID: EntityUtil.nextId(),
            description: null,
            injured
        });
    }

    removeInjuryIncident(incidentToRemove) {
        const indexToRemove = this.injuryIncidents.findIndex(incident => {
            const tempIDMatches = incidentToRemove.tempID && incident.tempID === incidentToRemove.tempID;
            const publicIDMatches = incidentToRemove.publicID && incident.publicID === incidentToRemove.publicID;
            return tempIDMatches || publicIDMatches;
        });
        if (indexToRemove !== -1) {
            this.injuryIncidents.splice(indexToRemove, 1);
        }
    }
}

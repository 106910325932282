import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';

export default ['$q',
    'JsonRPCService', ($q, JsonRPCService) => {
        const capabilityPrefix = ApplicationFeatures.getAllFeatures().gatewayendorsement === 'true' ? 'gateway' : '';

        return {
            loadEndorsement: (policyNumber) => {
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'load', [policyNumber]);
            },

            loadEndorsementWithEffectiveDate: (policyNumber, effectiveDate) => {
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'loadWithEffectiveDate', [policyNumber, effectiveDate]);
            },

            loadEndorsementWithEffectiveDateTime: (policyNumber, effectiveDate, effectiveTime) => { // ANDIE
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'loadWithEffectiveDateTime', [policyNumber, effectiveDate, effectiveTime]);
            },

            saveEndorsement: (endorsement) => {
                endorsement.beforeSave();
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'save', [endorsement]);
            },

            updateCoverages: (jobID, lobCoverages) => {
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'updateCoverages', [jobID, lobCoverages]);
            },

            quoteEndorsement: (endorsement) => {
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'saveAndQuote', [endorsement]);
            },

            isSavedCardValid_AND: (jobNumber) => {
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'isSavedCardValid', [jobNumber]);
            },

            validatePCLAndGetRemainingInstalments_AND: (jobNumber) => {
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'validatePCLAndGetRemainingInstalments', [jobNumber]);
            },

            preBind_AND: (jobNumber, bindData) => {
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'preBind', [jobNumber, bindData]);
            },

            bind: (jobNumber, paymentDetails) => {
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'bind', [jobNumber, paymentDetails]);
            },

            withdrawEndorsement: (jobNumber) => {
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'withdraw', [jobNumber]);
            },

            getAvailablePolicy: (policyNumber) => {
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'getAvailablePolicy', [policyNumber]);
            },

            getAvailablePolicies: () => {
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'getAvailablePolicies', []);
            },

            getPolicyBillingSummary: (policyNumber) => {
                return JsonRPCService.send('bc', `${capabilityPrefix}billing/billingData`, 'getPolicyBillingSummary', [policyNumber]);
            },

            checkEffectiveDateIsValid: (policyNumber, date) => {
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'checkEffectiveDateIsValid', [policyNumber, date]);
            },

            isQuoted: (jobNumber) => {
                return JsonRPCService.send('pc', `${capabilityPrefix}policychange/policychange`, 'isQuoted', [jobNumber]);
            }
        };
    }
];

import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($window, $scope, PolicyService, $q, $stateParams) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.policyNumber = $stateParams.policyNumber;
        const policyDetails = PolicyService.getAccountPolicyDetails($stateParams.policyNumber);
        $scope.policyDetailsPromise = policyDetails;
        $scope.ownDocuments = [];
        $scope.publicDocuments = [];

        $q.when(policyDetails, (policyData) => {
            $scope.policyData = policyData.currentPeriod;
            $scope.policyDataRenewal = policyData.renewedPeriod;
            $scope.periods = [{
                title: 'policy.common.views.account-policy-details.Current',
                data: $scope.policyData,
                buttonText: 'policy.common.views.account-policy-details.Change Current Policy',
            }];
            $scope.policyData.contacts = transformContacts($scope.policyData.contacts);
            if ($scope.policyDataRenewal) {
                // Create renewal period and munge the contacts if they exist
                $scope.periods.push({
                    title: 'policy.common.views.account-policy-details.Renewing',
                    data: $scope.policyDataRenewal,
                    buttonText: 'policy.common.views.account-policy-details.Change Renewal Policy',
                });
                $scope.policyDataRenewal.contacts = transformContacts($scope.policyDataRenewal.contacts);
            }

            policyData.currentPeriod.documentDTOs.forEach(doc => {
                if (doc.isAuthor) {
                    $scope.ownDocuments.push(doc);
                    return;
                }
                $scope.publicDocuments.push(doc);
            });
        });
    };

    ctrl.print = () => {
        $window.print();
    };

    ctrl.goBack = () => {
        $window.history.back();
    };

    return ctrl.create($scope);

    function transformContacts(contacts) {
        return contacts.reduce((prev, curr) => {
            const oldContact = prev.find((contact) => contact.contact.publicID === curr.contact.publicID);
            if (oldContact) {
                oldContact.contactRole.push(curr.contactRole);
            } else {
                prev.push({
                    contact: curr.contact,
                    contactRole: [curr.contactRole]
                });
            }
            return prev;
        }, []);
    }
};

fn.$inject = ['$window', '$scope', 'policy.PolicyService', '$q', '$stateParams'];
export default fn;

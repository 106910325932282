import templateStr from 'has-text?role,financials!./templates/claim-summary_AND.html'; // ANDIE
import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import PolicySearchUtil from 'gw-portals-util-angular/PolicySearchUtil';
import _ from 'underscore';

export default ['$filter', ($filter) => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {},
        controller: ['$scope', '$state', '$q', 'claim.ClaimService', 'TranslateService', 'InsuranceSuiteMetadata',
            ($scope, $state, $q, ClaimService, TranslateService, InsuranceSuiteMetadata) => {
                const claimStateTypelist = InsuranceSuiteMetadata.get('cc').types.getTypelist('ClaimState');
                $scope.selectedLOBOptionIndex = 0;

                $scope.$filter = $filter; // for l10n

                /** All claims loaded so far. */
                $scope.allClaims = [];
                $scope.failToGetClaims = false;
                $scope.loadCount = 0;
                $scope.getPolicyIconName = PolicySearchUtil.getPolicyTypeIconName;
                $scope.getPolicyIconPath = PolicySearchUtil.getPolicyTypeIconPath;

                $scope.lobNormalizationMap = {
                    'PersonalAutoLine': 'Personal Auto',
                    'auto': 'Personal Auto',
                    'AUTO': 'Personal Auto',
                    'HomeownersLine_HOE': 'Homeowners',
                    'PR': 'Homeowners',
                    'pr': 'Homeowners',
                    'GL': 'GLLine',
                    'WC': 'Workers Compensation',
                    'TRAV': 'travel',
                    'BOPLine': 'Business Owners',
                    'GLLine': 'General Liability',
                    'IMLine': 'Inland Marine',
                    'WorkersCompLine': 'Workers\' Compensation'
                };

                $scope.vendorTypesForProducerByLob = {
                    'Personal Auto': ['AutoTowingAgcy', 'AutoRepairShop'],
                    'Homeowners': ['PersonVendor', 'CompanyVendor', 'Doctor', 'Attorney', 'LawFirm', 'MedicalCareOrg', 'AutoTowingAgcy', 'AutoRepairShop']
                };

                $scope.vendorTypesForPolicyHolderByLob = {
                    'Personal Auto': ['AutoTowingAgcy', 'AutoRepairShop', 'PersonVendor', 'CompanyVendor', 'Attorney', 'Doctor'],
                    'Homeowners': ['PersonVendor', 'CompanyVendor', 'Attorney', 'Doctor']
                };

                $scope.tableConfig = {
                    defaultPaginationSize: 25,
                    /**
                     * L10n
                     * the following lexemes are processing in *.html file with
                     * $filter('translate')('PREFIX'+option)
                     */
                    LOB: 'All Claims',
                    defaultLOB: 'All Claims',
                    LOBOptions: [
                        {key: 'AllClaims', frontendKey: 'All Claims'},
                        {key: 'BusinessOwners', frontendKey: 'Business Owners'},
                        {key: 'BusinessAuto', frontendKey: 'Commercial Auto'},
                        {key: 'CommercialProperty', frontendKey: 'Commercial Property'},
                        {key: 'GeneralLiability', frontendKey: 'General Liability'},
                        {key: 'HOPHomeowners', frontendKey: 'Homeowners'},
                        {key: 'InlandMarine', frontendKey: 'Inland Marine'},
                        {key: 'PersonalAuto', frontendKey: 'Personal Auto'},
                        {key: 'WorkersComp', frontendKey: 'Workers\' Compensation'}
                    ],
                    claimQuery: '',
                    showClosed: false
                };

                $scope.tableConfig.LOBOptions = $scope.tableConfig.LOBOptions.map(option => {
                    const res = Object.assign({}, option, {name: ''});
                    const displayKey = `claim.directives.ClaimSummary.${res.frontendKey}`;

                    TranslateService.translate({
                        object: res,
                        propName: 'name',
                        displayKey: displayKey,
                        scope: $scope
                    });
                    return Object.assign({}, res, {label: $filter('translate')(displayKey)});
                });

                $scope.tableConfig.LOB = _.findWhere($scope.tableConfig.LOBOptions, {
                    frontendKey: $scope.tableConfig.LOB
                });

                $scope.setLOB = (option, index) => {
                    $scope.tableConfig.LOB = option;
                    $scope.selectedLOBOptionIndex = index;
                };

                $scope.keepDropdownOpen = (event) => {
                    event.stopPropagation();
                };

                $scope.getStateLabel = (code) => {
                    const typeKey = claimStateTypelist.getCode(code);
                    return typeKey ? typeKey.name : null;
                };

                $scope.resetPagination = false;

                $scope.loadClaimsInStates = (offsetStart, offsetEnd) => {
                    const states = ['open', 'draft'];
                    if ($scope.tableConfig.showClosed) {
                        states.push('closed');
                    }

                    $scope.loadCount += 1;

                    const tableConfig = $scope.tableConfig;

                    const query = {
                        claimStates: states,
                        queryText: tableConfig.claimQuery.length > 0 ? tableConfig.claimQuery : undefined,
                        policyType: tableConfig.LOB.frontendKey !== tableConfig.defaultLOB ? tableConfig.LOB.key : undefined
                    };

                    const pagination = {
                        offsetStart,
                        offsetEnd
                    };

                    $scope.claimSummariesPromise = ClaimService
                        .getClaimSummaries(query, pagination)
                        .then($scope.transformClaimSummaries)
                        .catch($scope.handleLoadFailure);

                    return $scope.claimSummariesPromise;
                };

                /**
                 * Claim summaries data transformation.
                 * @param {Object} paginatedData
                 * @returns {Object}
                 */
                $scope.transformClaimSummaries = (paginatedData) => {
                    $scope.loadCount -= 1;

                    const newClaims = paginatedData.items;

                    newClaims.forEach((claim) => {
                        claim.normalizedLobCode = $scope.lobNormalizationMap[claim.lineOfBusinessCode] || claim.lineOfBusinessCode;
                    });

                    /* Update vendor contacts and make them producer-friendly. */
                    if (ApplicationFeatures.hasFeature('role.producer')) {
                        newClaims.forEach($scope.updateProducerVendors);
                    } else if (ApplicationFeatures.hasFeature('role.policyholder')) {
                        newClaims.forEach($scope.updatePolicyHolderVendors);
                    }

                    $scope.allClaims = newClaims;

                    return paginatedData;
                };

                /**
                 * Handles claim loading failure.
                 */
                $scope.handleLoadFailure = () => {
                    $scope.loadCount -= 1;
                    $scope.failToGetClaims = true;
                };

                $scope.applyWatches = () => {
                    let firstRun = true;

                    $scope.$watchGroup(
                        ['tableConfig.claimQuery', 'tableConfig.LOB', 'tableConfig.showClosed'],
                        _.debounce(() => {
                            $scope.$apply(() => {
                                if (!firstRun) {
                                    $scope.resetPagination = !$scope.resetPagination;
                                }
                                firstRun = false;
                            });
                        }, 700) // ms
                    );
                };

                /**
                 * Filters/updates vendors for producers.
                 * @param {Object} claim
                 */
                $scope.updateProducerVendors = (claim) => {
                    if (!claim.vendors) {
                        return;
                    }

                    const usedVendorTypes = $scope.vendorTypesForProducerByLob[claim.normalizedLobCode] || [];
                    claim.producerVendors = claim.vendors.filter((vendor) => {
                        return usedVendorTypes.indexOf(vendor.contactDTO.subtype) >= 0;
                    });
                };

                /**
                 * Filters/updates vendors for policyHolder.
                 * @param {Object} claim
                 */
                $scope.updatePolicyHolderVendors = (claim) => {
                    if (!claim.vendors) {
                        return;
                    }

                    const usedVendorTypes = $scope.vendorTypesForPolicyHolderByLob[claim.normalizedLobCode] || [];
                    claim.policyHolderVendors = claim.vendors.filter((vendor) => {
                        let type = vendor.contactDTO.subtype;
                        if (claim.normalizedLobCode === 'Homeowners') {
                            type = vendor.contactDTO.contactType;
                        }
                        return usedVendorTypes.indexOf(type) >= 0;
                    });
                };

                $scope.loadByOffset = (offsetStart, offsetEnd) => {
                    return $scope.loadClaimsInStates(offsetStart, offsetEnd);
                };

                $scope.onPaginationNavigation = (paginatedData) => {
                    $scope.paginatedClaims = paginatedData.items;
                };

                $scope.navigateTo = (claimSummary) => {
                    let state;

                    if (ApplicationFeatures.hasFeature('role.vendor')) {
                        $state.go('claimDetails', {
                            claimNumber: claimSummary.claimNumber
                        });
                    } else if (claimSummary.claimState !== 'draft') {
                        $state.go('claimDetails', {
                            claimNumber: claimSummary.claimNumber
                        });
                    } else {
                        state = $state.get('fnol');
                        $state.go(state, {
                            claimNumber: claimSummary.claimNumber
                        });
                    }
                };

                $scope.applyWatches();
            }]
    };
}];

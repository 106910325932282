import _ from 'underscore';
import PolicyChangeExtensionConfiguration from 'integration/endorsement/PolicyChangeExtensionConfiguration';
import EntityUtil from 'gw-portals-util-js/EntityUtil';

/** Check that the current action would not revert the actions of a previous action (i.e. adding a new
 * vehicle and then removing it would leave the model in the same state that it was in beforehand.
 * @param {String} action The action that has been performed ('added','removed','changed'
 * @param {*} type The entity type
 * @param {Object} obj The object in question
 * @param {Object} objModel the PolicyChange model
 * @returns {boolean}
 * @private
 */
function _checkForRevertantChanges(action, type, obj, objModel) {
    const historyItem = _.findWhere(objModel.history, {
        entity: obj
    });
    if (angular.isDefined(historyItem)) {
        const oldAction = historyItem.action;

        if (action === 'removed') {
            if (oldAction === 'added') { // Effectively reverts older action
                objModel.history = _.without(objModel.history, historyItem);
                objModel.saveRequired = !(objModel.history.length < 1 && angular.isDefined(obj.isNew));
            } else if (oldAction === 'changed') {
                historyItem.action = action; // Just record the removal
                historyItem.itemName = historyItem.entity.getDisplayName();
            }
            return true;
        } else if (action === 'changed') { // Do nothing, but don't add new history item
            return true;
        }
    }

    return false;
}


function _buildHistory(policyChange) {
    _.each(policyChange.history, (historyItem) => {
        if (historyItem.action === 'changed' && historyItem.entityType === 'address') {
            historyItem.entity = policyChange.baseData.policyAddress;
        }
    });
}

function PolicyChange(data) {
    if (data) {
        _.extend(this, data);
        this.saveRequired = false;
        if (angular.isDefined(this.history)) {
            _buildHistory(this);
        } else {
            this.history = [];
        }
        PolicyChangeExtensionConfiguration.mixinLobDraftDataExtensions(this);

    } else {
        this.policyNumber = '';
        this.baseData = {
            policyAddress: {
                postalCode: ''
            }
        };
        this.isSubmitAgent = false;
        this.lobData = {};
        this.history = [];
    }

    this.validate();
}

PolicyChange.prototype.createId = () => {
    return EntityUtil.nextId();
};

PolicyChange.prototype.nextId = () => {
    return EntityUtil.nextId();
};

PolicyChange.prototype.addToHistory = function (action, type, obj) {
    if (!_checkForRevertantChanges(action, type, obj, this)) { // Action doesn't revert anything
        if (action === 'removed') {
            this.history.push({
                action, entityType: type, itemName: obj.getDisplayName(), entity: obj
            });
        } else {
            this.history.push({
                action, entityType: type, entity: obj
            });
        }
    }
    this.saveRequired = true;
};

PolicyChange.prototype.validate = function () {
    let result = true;
    if (this.lobData) {
        result = _.every(this.lobData, (lob) => {
            return _.isFunction(lob.validate) ? lob.validate() : true;
        });
    }
    this.isValid = result;
    return this.isValid;
};

PolicyChange.prototype.beforeSave = function () {
    if (this.lobData) {
        _.every(this.lobData, (lob) => {
            return _.isFunction(lob.beforeSave) ? lob.beforeSave() : true;
        });
    }
};


export default PolicyChange;

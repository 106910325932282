import templateStr from 'text!./auto-renewal-preference.html';

export default [() => {
    return {
        restrict: 'A',
        template: templateStr,
        scope: {
            policyDetails: '=andAutoRenewalPreference',
        },
        controller: ['$scope', '$filter', '$rootScope', 'policy.PolicyService', 'ViewModelService', '$timeout', '$q', 'ModalService', ($scope, $filter, $rootScope, PolicyService, ViewModelService, $timeout, $q, ModalService) => {
            const API_ERROR = $filter('translate')('and.directives.change-docs-pref.APIError');
            const setAPIError = (message) => {
                $scope.apiError = message;
            };
            const monthlyPlans = ["Installment New Business Payment", "Installment Renewal Payment", "New Business Monthly Payment Plan", "Renewal Monthly Payment Plan"];
            $scope.clearAPIMessage = () => setAPIError(null);

            $scope.clearSuccessMsg = () => {
                $scope.isSuccessVisible = false;
            };

            $scope.isMonthlyPlan = () => {
                return monthlyPlans.includes($scope.policyDetails.paymentPlan.value)
            }

            $scope.$watch('policyDetails', () => {
                $scope.policyDetails.initialAutoRenewValue = $scope.policyDetails.autoRenew_itb.value;
            });

            $scope.hasAutoRenewValueChanged = () => {
                return $scope.policyDetails.initialAutoRenewValue !== $scope.policyDetails.autoRenew_itb.value;
            };
            
            $scope.autoRenewSetToTrue = () => {
                return $scope.hasAutoRenewValueChanged() === true && $scope.policyDetails.autoRenew_itb.value === true;
            };

            $scope.submit = (form) => {
                form.submitted = true;
                if ($scope.autoRenewSetToTrue()) {
                    $scope.showAutoRenewPopup = ModalService.showAutoRenewalInfo('fnol.common.controllers.AutoRenewalPreference.Modal Title', 'fnol.common.controllers.AutoRenewalPreference.Modal Content');
                }
                $scope.clearAPIMessage();
                if (form.valid === false) {
                    return;
                }
                $scope.inFlight = true;
                return PolicyService.newAutoRenewalChoice($scope.policyDetails.autoRenew_itb.value, $scope.policyDetails.currentPeriod.policyNumber.value)
                    .then((data) => {
                        $scope.policyDetails = ViewModelService.create(data, 'pc', 'edge.capabilities.policy.dto.PolicyDetailsDTO', null);
                        $scope.isSuccessVisible = true;
                        $timeout(() => { // Clear success message after 10 seconds
                            $scope.clearSuccessMsg();
                        }, 10000);
                    })
                    .catch(() => {
                        setAPIError(API_ERROR);
                    })
                    .finally(() => {
                        $scope.inFlight = false;
                        $scope.location.reload(true);
                    });
            };
        }]
    };
}];

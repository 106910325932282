import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, $state, $stateParams) => {
    const ctrl = Object.create(BaseCtrl);

    ctrl.cancel = () => {
        if ($stateParams.accountNumber) {
            $state.transitionTo($state.$current.data.actions.cancel, {
                accountNumber: $stateParams.accountNumber
            });
        } else {
            $state.transitionTo($state.$current.data.actions.cancel);
        }
    };

    return ctrl.create($scope);
};
fn.$inject = ['$scope', '$state', '$stateParams'];

export default fn;
import template from 'text!./templates/pm-flow.html';
import EndorsementStateUtils from 'edge/endorsement/common/utils/EndorsementStateUtils';
import EndorsementSubflowUtils from 'edge/endorsement/common/utils/EndorsementSubflowUtils';

export default [() => ({
    template,
    'scope': {
        'wizard': '=',
        'flow': '=',
        'isStateVisible': '='
    },
    'controller': ['$rootScope', '$scope', '$state', 'endorsement.EndorsementService', ($rootScope, $scope, $state, EndorsementService) => {
        const stateMappings = {
            PmAddress: 'address',
            PmVehicle: 'pmVehicles',
            PmDriver: 'pmDrivers',
            PmCoverages: 'coverages'
        };
        const updateCoverages = EndorsementSubflowUtils.updateCoverages($scope, $state, EndorsementService);

        $scope.toState = EndorsementStateUtils.toState($scope, stateMappings);
        $scope.hasState = EndorsementStateUtils.hasState($scope);
        $scope.tileState = EndorsementStateUtils.tileState($scope);
        $scope.showOnState = EndorsementStateUtils.showOnState($scope);
        $scope.showEndorsementChangeArea = showEndorsementChangeArea;
        $scope.next = EndorsementStateUtils.next($scope);
        $scope.quote = EndorsementSubflowUtils.quote($rootScope, $scope.next);
        $scope.onBaseCovChange = EndorsementSubflowUtils.onBaseCovChange($scope, 'personalMotor', updateCoverages, 'pmLineCoverages');
        $scope.onAdditionalCovChange = EndorsementSubflowUtils.onAdditionalCovChange($scope, 'personalMotor', updateCoverages, 'pmVehicleCoverages');

        /**
         * Determine whether one state of the group should be displayed
         * @returns {boolean}
         */
        function showEndorsementChangeArea() {
            const states = Object.keys(stateMappings);
            return states.some(state => state === $scope.wizard.state.name);
        }


    }]
})];
import template from 'text!./templates/pm-drivers-edit.html';
import PolicyChange from 'edge/endorsement/common/models/PolicyChange';
import _ from 'lodash';

export default [() => ({
    template,
    'restrict': 'E',
    'scope': {
        model: '=',
        view: '=',
        next: '&'
    },
    'controller': ['$scope', '$translate', '$filter', 'ModalService', 'ViewModelService', 'endorsement.EndorsementService',
        function ($scope, $translate, $filter, ModalService, ViewModelService, EndorsementService) {
            resetFlags();
            $scope.selectedEntry = -1;

            $scope.toggleDriverAssignment = (driver, vehicle) => {
                if ($scope.model.policyChange.lobData.personalMotor.coverables.isDriverAssigned(driver, vehicle)) {
                    $scope.model.policyChange.lobData.personalMotor.coverables.removeDriverAssignment(driver, vehicle);
                } else {
                    $scope.model.policyChange.lobData.personalMotor.coverables.assignDriver(driver, vehicle);
                }
                $scope.model.policyChange.validate();
            };

            $scope.isDriverAssigned = (driver, vehicle) => {
                return $scope.model.policyChange.lobData.personalMotor.coverables.isDriverAssigned(driver, vehicle);
            };

            /**
             * Begin choosing an existing (non-policy) driver to add
             */
            $scope.onChooseExisting = () => {
                $scope.isChoosingExisting = true;
            };

            /**
             * Cancel choosing an existing (non-policy) driver to add
             */
            $scope.cancelChoosingExisting = () => {
                resetFlags();
            };

            /**
             * Begin adding a new entry
             * @param {Number} indexExisting index of an available driver if one is to be used
             */
            $scope.onAddNew = (indexExisting) => {
                cleanAdd();
                setDefaultNewEntry(indexExisting);
                resetFlags();
                $scope.isAddingNew = true;
            };

            /**
             * Cancel the new entry form
             */
            $scope.cancelNewEntry = () => {
                const driver = $scope.model.policyChange.lobData.personalMotor.coverables.pmDrivers[$scope.model.policyChange.lobData.personalMotor.coverables.pmDrivers.length - 1];
                $scope.model.policyChange.lobData.personalMotor.coverables.removeDriver(driver);
                $scope.isAddingNew = false;
            };

            /**
             * Submit the new entry form
             */
            $scope.submitNewEntry = () => {
                if (allLicensesUnique()) {
                    $scope.isAddingNew = false;
                    $scope.model.policyChange.validate();
                    saveChanges();
                }
            };

            /**
             * Begin editing an entry
             */
            $scope.onEdit = () => {
                cleanAdd();
                resetFlags();
                $scope.isEditing = true;
                $scope.backupAssignments = $scope.model.policyChange.lobData.personalMotor.coverables.pmVehicleDrivers.slice();
            };

            /**
             * Update an entry
             */
            $scope.updateEntry = () => {
                if (allLicensesUnique()) {
                    $scope.isEditing = false;
                    $scope.selectedEntry = -1;
                    $scope.model.policyChange.validate();
                    saveChanges();
                }
            };

            /**
             * Stop editing an entry
             */
            $scope.cancelEdit = () => {
                // Restore the model and assignments to their previous state
                if ($scope.selectedEntry !== -1) {
                    const selectedDriver = $scope.view.lobData.personalMotor.coverables.pmDrivers.value[$scope.selectedEntry];
                    Object.assign(selectedDriver, $scope.modelBackup);
                }
                $scope.model.policyChange.lobData.personalMotor.coverables.pmVehicleDrivers = $scope.backupAssignments;
                $scope.model.policyChange.validate();
                $scope.selectedEntry = -1;
                resetFlags();
            };

            /**
             * Begin removing entries
             */
            $scope.onRemove = () => {
                cleanAdd();
                resetFlags();
                $scope.isRemoving = true;
            };

            /**
             * Delete an entry
             * @param {number} index
             */
            $scope.deleteEntry = (index) => {
                if (index > -1) {
                    const driver = $scope.model.policyChange.lobData.personalMotor.coverables.pmDrivers[index];
                    $scope.model.policyChange.lobData.personalMotor.coverables.removeDriver(driver);
                    $scope.selectedEntry = -1;

                    if ($scope.view.lobData.personalMotor.coverables.pmDrivers.value.length <= 1) {
                        $scope.cancelRemove();
                    }
                    $scope.model.policyChange.validate();
                    saveChanges();
                }
            };

            /**
             * Stop removing entries
             */
            $scope.cancelRemove = () => {
                resetFlags();
            };

            /**
             * Select an entry
             * @param {Number} index
             */
            $scope.selectEntry = (index) => {

                if (!$scope.isReplacing) {
                    const driver = $scope.view.lobData.personalMotor.coverables.pmDrivers.value[index];
                    $scope.selectedDriver = $scope.model.policyChange.lobData.personalMotor.coverables.pmDrivers[index];
                    $scope.driverView = $scope.view.lobData.personalMotor.coverables.pmDrivers.findFirstElement(driver);
                }
                $scope.selectedEntry = index;
                $scope.modelBackup = angular.copy($scope.view.lobData.personalMotor.coverables.pmDrivers.value[$scope.selectedEntry]);
            };

            /**
             * Begin replacing an entry
             */
            $scope.onReplace = () => {
                cleanAdd();
                resetFlags();
                $scope.isReplacing = true;
                setDefaultNewEntry();
            };

            /**
             * Replace an entry by removing one and adding a new one
             */
            $scope.replaceEntry = () => {
                if (allLicensesUnique()) {
                    if ($scope.selectedEntry > -1) {
                        const driver = $scope.model.policyChange.lobData.personalMotor.coverables.pmDrivers[$scope.selectedEntry];
                        $scope.model.policyChange.lobData.personalMotor.coverables.removeDriver(driver);
                        $scope.selectedEntry = -1;
                    }
                    $scope.isReplacing = false;
                    $scope.model.policyChange.validate();
                    saveChanges();
                }
            };

            /**
             * Cancel replacing an entry
             */
            $scope.cancelReplace = () => {
                $scope.isReplacing = false;
                const driverIdx = $scope.model.policyChange.lobData.personalMotor.coverables.pmDrivers.length - 1;
                const driver = $scope.model.policyChange.lobData.personalMotor.coverables.pmDrivers[driverIdx];
                $scope.model.policyChange.lobData.personalMotor.coverables.removeDriver(driver);
            };

            /**
             * Next button is clicked
             */
            $scope.onNext = () => {
                if ($scope.canProgress()) {
                    cleanAdd();
                    // Call next method on parent
                    $scope.next();
                }
            };

            /**
             * Determine whether a driver is currently being edited using the replace or add function
             * @param {Object} driver
             * @returns {boolean}
             */
            $scope.notBeingCreated = (driver) => {
                return (!$scope.isAddingNew && !$scope.isReplacing) || $scope.selectedDriver !== driver;
            };

            /**
             * Check if the user has a driver form active but not saved
             * @returns {boolean}
             */
            $scope.inPreCommitStage = () => $scope.isAddingNew || (($scope.isReplacing || $scope.isEditing || $scope.isRemoving) && $scope.selectedEntry > -1);

            /**
             * Check whether drivers exist
             * @returns {boolean}
             */
            $scope.driversExist = () => $scope.view.lobData.personalMotor.coverables.pmDrivers && !($scope.view.lobData.personalMotor.coverables.pmDrivers.length === 0);

            /**
             * Check whether one vehicles exist
             * @returns {boolean}
             */
            $scope.onlyOneDriverExists = () => $scope.view.lobData.personalMotor.coverables.pmDrivers && $scope.view.lobData.personalMotor.coverables.pmDrivers.length === 1;

            /**
             * Check whether the user can progress. Cannot progress if there are unassigned vehicles or drivers
             * @returns {boolean}
             */
            $scope.canProgress = () => {
                const vehiclesAssigned = !$scope.model.policyChange.lobData.personalMotor.coverables.unassignedVehicles || $scope.model.policyChange.lobData.personalMotor.coverables.unassignedVehicles.length === 0;
                const driversAssigned = !$scope.model.policyChange.lobData.personalMotor.coverables.unassignedDrivers || $scope.model.policyChange.lobData.personalMotor.coverables.unassignedDrivers.length === 0;
                return vehiclesAssigned && driversAssigned;
            };

            /**
             * Populates a new entry with default values for use in the new entry form
             * @param {Number} indexExisting
             */
            function setDefaultNewEntry(indexExisting) {
                let driver = null;

                if (indexExisting > -1) {
                    driver = $scope.model.policyChange.lobData.personalMotor.coverables.availableDrivers[indexExisting];
                    $scope.model.policyChange.lobData.personalMotor.coverables.pmDrivers.push(driver);
                } else {
                    driver = $scope.model.policyChange.lobData.personalMotor.coverables.createDriver();
                }

                $scope.driverView = $scope.view.lobData.personalMotor.coverables.pmDrivers.findFirstElement(driver);
                $scope.selectedDriver = driver;
                $scope.replaceDriver = driver;
            }

            /**
             * Cancels replace or add operation if they are active
             */
            function cleanAdd() {
                if ($scope.isReplacing) {
                    $scope.cancelReplace();
                }
                if ($scope.isAddingNew) {
                    $scope.cancelNewEntry();
                }
            }

            /**
             * Save pending policy changes in PC, shows error dialog on failure
             * @returns {Promise}
             */
            function saveChanges() {
                $scope.savePromise = EndorsementService.saveEndorsement($scope.model.policyChange).then(
                    (pc) => {
                        $scope.model.policyChange = new PolicyChange(pc);
                        $scope.view = ViewModelService.create($scope.model.policyChange, 'pc', 'edge.capabilities.policychange.dto.PolicyChangeDataDTO');
                    },
                    () => {
                        ModalService.showError(
                            'endorsement.pa.config.Unable to save draft policy change',
                            'endorsement.pa.config.An error occurred while attempting to save the policy change.'
                        );
                    }
                );

                return $scope.savePromise;
            }

            /**
             * Ensure all the driver licenses are unique
             * @returns {boolean}
             */
            function allLicensesUnique() {
                let licenceMatchedDriver = ensureUniqueLicenseNumber($scope.selectedDriver);
                if ($scope.isReplacing) {
                    const driver = $scope.model.policyChange.lobData.personalMotor.coverables.pmDrivers[$scope.selectedEntry];
                    licenceMatchedDriver = licenceMatchedDriver === driver;
                }
                if (licenceMatchedDriver) {
                    const defaultDriverName = $filter('translate')('endorsement.controllers.Unnamed');
                    const driverName = licenceMatchedDriver.getDisplayName(defaultDriverName);
                    const msg = $translate('endorsement.pa.controllers.PADriverDetailsCtrl.LicenceMatchMsg', {
                        driver: driverName
                    });
                    ModalService.showError(
                        'endorsement.pa.controllers.PADriverDetailsCtrl.Licence number is not unique',
                        msg
                    );
                    return false;
                }
                return true;
            }

            /**
             * Searches for duplicated license numbers in the policy change.
             *
             * @param {Object} driver the current driver.
             * @returns {*} the list of drivers with the same license number as the current driver.
             */
            function ensureUniqueLicenseNumber(driver) {
                const policyChange = $scope.model.policyChange;
                const driverMatch = _.where(policyChange.lobData.personalMotor.coverables.pmDrivers, {
                    licenseNumber: driver.licenseNumber
                });

                if (!driverMatch) {
                    return undefined;
                }
                return _.find(driverMatch, (d) => {
                    return d !== driver;
                });
            }

            /**
             * Resets all of the flags to their default false state
             */
            function resetFlags() {
                $scope.isRemoving = false;
                $scope.isReplacing = false;
                $scope.isEditing = false;
                $scope.isChoosingExisting = false;
                $scope.isAddingNew = false;
                $scope.selectedEntry = -1;
            }
        }
    ]
})];

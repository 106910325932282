import BaseCtrl from 'edge/platform/base/BaseCtrl';
import _ from 'underscore';
/**
 * Marks an invoice as selected.
 * @param {Object} invoice
 */
function selectInvoice(invoice) {
    invoice.selected = true;
}

/**
 * Checks that invoice has any of the available states.
 * @param {Object} invoice
 * @param {Object} statuses
 * @returns {boolean}
 */
function isInStatus(invoice, statuses) {
    return statuses.indexOf(invoice.invoiceStatus) >= 0;
}

/**
 * Selects invoices having a specific status.
 * @param {Array} invoices
 * @param {*} statuses
 */
function selectInStatus(invoices, statuses) {
    invoices
        .filter(_.partial(isInStatus, _, statuses))
        .forEach(selectInvoice);
}


const fn = function (
    $scope, $state, PaymentIntakeService, AccountBillingDetailsService,
    ModalService, $stateParams, $window
) {
    const ctrl = Object.create(BaseCtrl);

    ctrl.init = () => {
        $scope.billingSummaryPromise = ctrl.getBillingSummary().then((summary) => {
            $scope.summary = summary;

            ctrl.preselectInvoices(summary.activeInvoices);

            if (summary.activeInvoices.length === 1) {
                summary.activeInvoices[0].showInfo = true;
            }
        });
    };


    ctrl.preselectInvoices = (invoices) => {
        switch ($stateParams.payMode) {
            case 'overdue':
                selectInStatus(invoices, ['due']);
                break;
            case 'billed':
                selectInStatus(invoices, ['due', 'billed']);
                break;
            default:
                break;
        }


        if ($stateParams.streamId) {
            _.where(invoices, {
                paymentGroupId: $stateParams.streamId
            }).forEach(selectInvoice);
        }
    };


    ctrl.getBillingSummary = () => {
        if ($stateParams.accountNumber) {
            return AccountBillingDetailsService.getAccountBillingSummary($stateParams.accountNumber);
        }

        return AccountBillingDetailsService.getAccountBillingSummary();
    };


    ctrl.cancel = () => {
        $state.transitionTo($state.$current.data.actions.cancel);
    };


    ctrl.makePaymentRequest = (invoiceIds, amount, instrument) => {
        if ($stateParams.accountNumber) {
            return PaymentIntakeService.makeDirectPayment($stateParams.accountNumber, invoiceIds, amount, instrument);
        }
        return PaymentIntakeService.makeDirectPayment(invoiceIds, amount, instrument);
    };


    ctrl.transitAfterPayment = (stateSpec) => {
        if (angular.isString(stateSpec)) {
            $state.transitionTo(stateSpec);
        } else {
            $state.transitionTo(stateSpec.state, stateSpec.stateParams);
        }
    };


    ctrl.pay = (invoiceIds, amount, instrument) => {
        const submitBillPaymentPromise = ctrl.makePaymentRequest(invoiceIds, amount, instrument);
        $scope.submitBillPaymentPromise = submitBillPaymentPromise;

        submitBillPaymentPromise.then(ctrl.transitAfterPayment, (error) => {
            console.error('make payment failed', error);
            ModalService.showError(
                'billing.controllers.InvoicePaymentCtrl.Payment request failed',
                'billing.controllers.InvoicePaymentCtrl.Sorry your payment could not be processed at this time'
            );
        });
    };

    ctrl.goBack = () => {
        $window.history.back();
    };

    return ctrl.create($scope);
};

fn.$inject = ['$scope', '$state', 'integration.PaymentIntakeService', 'billing.AccountBillingDetailsService',
    'ModalService', '$stateParams', '$window'
];

export default fn;
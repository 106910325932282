let inactivityTimerId;

/**
 * ANDIE
 * This service handles the frontend session. If the user does not interact with the Portal for an x amount of time,
 * the journey is interrupted and the user must start a new one.
 * The length of the session is defined by the QNBSessionLengthInMin_itb script parameter in PC
 */

export default ['StateUtils', '$state', (StateUtils, $state) => {
    let inactivityInterval;

    function init(inactivityIntervalMins) {
        if (angular.isDefined(inactivityInterval)) { // Only initialise once
            return;
        }
        inactivityInterval = inactivityIntervalMins * 1000 * 60;
        initializeInActivityTimer();
    }

    function initializeInActivityTimer() {
        inactivityTimerId = window.setTimeout(() => {
            const flowModel = StateUtils.activeDataForState(true).flowModel;
            if (flowModel) { // Attempt a graceful wizard exit if possible
                flowModel.jumpToExit('sessionTimedOut_AND');
            } else { // Go direct otherwise
                $state.go('sessionTimedOut_AND');
            }
        }, inactivityInterval);
    }

    function resetInactivityTimer() {
        if (angular.isDefined(inactivityInterval)) { // Reset only if initialised
            deactivateTimers();
            initializeInActivityTimer();
        }
    }

    function deactivateTimers() {
        if (inactivityTimerId) {
            window.clearTimeout(inactivityTimerId);
            inactivityTimerId = null;
        }
    }

    return {
        init,
        // This method should be called anytime the frontend session has to be refreshed.
        // Currently on every XHR call and state change
        resetInactivityTimer
    };
}];
import templateStr from 'text!./pm-edit-mods.html';

export default [() => {
    return {
        restrict: 'E',
        scope: {
            vehicle: '=',
            afterMarketOptions: '=',
            bodyworkModifications: '=',
            engineModifications: '=',
            wheelsOrTyresModifications: '=',
            conversionModifications: '=',
            otherModifications: '=',
            readOnlyMode: '='
        },
        template: templateStr,
        controller: ['$scope', 'brandingData', ($scope, brandingData) => {
            $scope.modificationToolTip = {
                content: 'and.quoteandbind.pm.directives.templates.pm-edit-vehicle.editmods.toolTip',
                trigger: 'click'
            };

            $scope.afterMarketArray = [];
            $scope.bodyMods = [];
            $scope.engineMods = [];
            $scope.wheelsTyresMods = [];
            $scope.convMods = [];
            $scope.otherMods = [];

            // Modifications. The 'no' is a separate question in PC so need to add that manually.
            const noModifications = { code: 'no', name: 'No' };
            const yesModifications = { code: 'yes', name: 'Yes' };
            $scope.modificationsVM = {
                value: null,
                aspects: $scope.vehicle.hasBeenModified.aspects
            };
            if ($scope.vehicle.hasBeenModified.value === true) {
                $scope.modificationsVM.value = $scope.vehicle.modification.value;
                $scope.isModified = yesModifications.name
            } else if ($scope.vehicle.hasBeenModified.value === false) {
                $scope.modificationsVM.value = noModifications;
                $scope.isModified = noModifications.name;
            }

            $scope.modificationsAvailableValuesPersonalMotor = [noModifications].concat($scope.vehicle.modification.aspects.availableValues);
            $scope.$watch('modificationsVM.value', (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    if (newValue === noModifications) {
                        $scope.vehicle.hasBeenModified.value = false;
                        $scope.vehicle.modification.value = null;
                    } else {
                        $scope.vehicle.hasBeenModified.value = true;
                        $scope.vehicle.modification.value = newValue;
                    }
                }
            });

            if ($scope.vehicle.generalAfterMarketMods.length > 0) {
                for (const mod of $scope.vehicle.generalAfterMarketMods.value) {
                    $scope.afterMarketArray.push(mapExistingModObject(Math.floor(Math.random() * 100), mod));
                }
            }

            if ($scope.vehicle.bodyWorkMods.length > 0) {
                for (const mod of $scope.vehicle.bodyWorkMods.value) {
                    $scope.bodyMods.push(mapExistingModObject(Math.floor(Math.random() * 100), mod));
                }
            }

            if ($scope.vehicle.engineMods.length > 0) {
                for (const mod of $scope.vehicle.engineMods.value) {
                    $scope.engineMods.push(mapExistingModObject(Math.floor(Math.random() * 100), mod));
                }
            }
            if ($scope.vehicle.wheelsOrTyresMods.length > 0) {
                for (const mod of $scope.vehicle.wheelsOrTyresMods.value) {
                    $scope.wheelsTyresMods.push(mapExistingModObject(Math.floor(Math.random() * 100), mod));
                }
            }
            if ($scope.vehicle.conversionMods.length > 0) {
                for (const mod of $scope.vehicle.conversionMods.value) {
                    $scope.convMods.push(mapExistingModObject(Math.floor(Math.random() * 100), mod));
                }
            }
            if ($scope.vehicle.otherMods.length > 0) {
                for (const mod of $scope.vehicle.otherMods.value) {
                    $scope.otherMods.push(mapExistingModObject(Math.floor(Math.random() * 100), mod));
                }
            }

            function mapExistingModObject(id, modDescription) {
                return $scope.exisitingObject = {
                    id: `${modDescription}-${id}`,
                    value: modDescription
                };
            }

            $scope.$watch(() => $scope.vehicle.hasBeenModified.value, (newValue) => {

                // creates new object which will be used for each repeated input
                $scope.addNewModObject = (Options, modOption, id) => {
                    return $scope.myObject = {
                        id: `${modOption}${id}`,
                        value: null,
                        aspects: Options
                            .map((code) => {
                                return code;
                            })
                    };
                };
                // when modified is true push an object into initial array
                if (newValue === true) {
                    $scope.modificationsTM = true;
                    if (brandingData === 'kfi'){
                        $scope.vehicle.generalAfterMarketMods.value = [''];
                        $scope.vehicle.bodyWorkMods.value = [''];
                        $scope.vehicle.engineMods.value = [''];
                        $scope.vehicle.wheelsOrTyresMods.value = [''];
                        $scope.vehicle.conversionMods.value = [''];
                        $scope.vehicle.otherMods.value = [''];
                    }
                } else {
                    $scope.modificationsTM = false;
                    $scope.afterMarketArray = [];
                    $scope.bodyMods = [];
                    $scope.engineMods = [];
                    $scope.wheelsTyresMods = [];
                    $scope.convMods = [];
                    $scope.otherMods = [];
                    $scope.vehicle.generalAfterMarketMods.value = null;
                    $scope.vehicle.bodyWorkMods.value = null;
                    $scope.vehicle.engineMods.value = null;
                    $scope.vehicle.wheelsOrTyresMods.value = null;
                    $scope.vehicle.conversionMods.value = null;
                    $scope.vehicle.otherMods.value = null;
                }
            });

            $scope.$watch(() => $scope.afterMarketOptions, (newValue) => {
                if (newValue !== undefined) {
                    $scope.arrayOfAfterMarketValues = $scope.afterMarketOptions.map((mods) => {
                        return mods;
                    });
                    $scope.afterMarketVM = {
                        value: null,
                        aspects: $scope.arrayOfAfterMarketValues,
                    };
                }
            });

            $scope.$watch(() => $scope.bodyworkModifications, (newValue) => {
                if (newValue !== undefined) {
                    $scope.arrayOfBodyWorkValues = $scope.bodyworkModifications.map((mods) => {
                        return mods;
                    });
                    $scope.bodyWorkModsVM = {
                        value: null,
                        aspects: $scope.arrayOfBodyWorkValues,
                    };
                }
            });

            $scope.$watch(() => $scope.engineModifications, (newValue) => {
                if (newValue !== undefined) {
                    $scope.arrayOfEngineModsValues = $scope.engineModifications.map((mods) => {
                        return mods;
                    });
                    $scope.engineModsVM = {
                        value: null,
                        aspects: $scope.arrayOfEngineModsValues,
                    };
                }
            });

            $scope.$watch(() => $scope.wheelsOrTyresModifications, (newValue) => {
                if (newValue !== undefined) {
                    $scope.arrayOfWheelModsValues = $scope.wheelsOrTyresModifications.map((mods) => {
                        return mods;
                    });
                    $scope.WheelTyreModsVM = {
                        value: null,
                        aspects: $scope.arrayOfWheelModsValues,
                    };
                }
            });

            $scope.$watch(() => $scope.conversionModifications, (newValue) => {
                if (newValue !== undefined) {
                    $scope.arrayOfConvModValues = $scope.conversionModifications.map((mods) => {
                        return mods;
                    });
                    $scope.convModsVM = {
                        value: null,
                        aspects: $scope.arrayOfConvModValues,
                    };
                }
            });

            $scope.$watch(() => $scope.otherModifications, (newValue) => {
                if (newValue !== undefined) {
                    $scope.arrayOfOtherModsValues = $scope.otherModifications.map((mods) => {
                        return mods;
                    });
                    $scope.otherModsVM = {
                        value: null,
                        aspects: $scope.arrayOfOtherModsValues,
                    };
                }
            });

            $scope.resetModArrays = () => {
                if ($scope.vehicle.generalAfterMarketMods.value.length === 0) {
                    $scope.vehicle.generalAfterMarketMods.value = null;
                }
                if ($scope.vehicle.bodyWorkMods.value.length === 0) {
                    $scope.vehicle.bodyWorkMods.value = null;
                }
                if ($scope.vehicle.engineMods.value.length === 0) {
                    $scope.vehicle.engineMods.value = null;
                }
                if ($scope.vehicle.wheelsOrTyresMods.value.length === 0) {
                    $scope.vehicle.wheelsOrTyresMods.value = null;
                }
                if ($scope.vehicle.conversionMods.value.length === 0) {
                    $scope.vehicle.conversionMods.value = null;
                }
                if ($scope.vehicle.otherMods.value.length === 0) {
                    $scope.vehicle.otherMods.value = null;
                }
            };

            $scope.getModValueToAdd = (duplicateValues, ddArrayValues, dtoArrayValues) => {
                let otherValues = ddArrayValues.filter(code =>  !duplicateValues.includes(code.value)).map((mods) => {
                    return mods.value;
                });
                let previousValue = dtoArrayValues.filter(code =>  !duplicateValues.includes(code) && !otherValues.includes(code));

                return previousValue[0];
            };

            $scope.getDuplicateMods = (index, ddArrayValues) => {
                let duplicateValues = ddArrayValues.filter(code =>
                    code.value === ddArrayValues[index].value && code.id !== ddArrayValues[index].id)
                    .map((mods) => {
                        return mods.value;
                    });
                return duplicateValues;
            };

            $scope.getModIndexValue = (modToAdd, ddArrayValues) => {
                let ModValuesStringArr = ddArrayValues.map((mods) => {
                    return mods.id;
                });
                const indexID = ModValuesStringArr.indexOf(modToAdd.id);
                return indexID;
            };

            $scope.addAfterMarketMod = (mod) => {
                if (mod.value !== null) {
                    const indexID = $scope.getModIndexValue(mod, $scope.afterMarketArray);

                    let duplicateMods = $scope.getDuplicateMods(indexID, $scope.afterMarketArray);

                    if(duplicateMods.length > 0) {
                        let modValueToAdd = $scope.getModValueToAdd(duplicateMods, $scope.afterMarketArray, $scope.vehicle.generalAfterMarketMods.value);

                        $scope.afterMarketArray[indexID].value = modValueToAdd;
                    }
                    else {
                        $scope.vehicle.generalAfterMarketMods.value = $scope.afterMarketArray.map((code) => {
                            return code.value;
                        });
                    }
                }
            };

            $scope.addAfterMarketModSelect = () => {
                console.log($scope)
                let nullMods = $scope.afterMarketArray.filter(code => code.value == null);
                if(nullMods == null || nullMods.length === 0) {
                    $scope.afterMarketArray.push($scope.addNewModObject($scope.afterMarketOptions, 'afterMarketOption', Math.floor(Math.random() * 100)));
                }
            };

            $scope.removeAfterMarketMod = (mod) => {
                const index = $scope.afterMarketArray.indexOf(mod);
                $scope.afterMarketArray.splice(index, 1);
                const indexDTO = $scope.vehicle.generalAfterMarketMods.value.indexOf(mod.value);
                $scope.vehicle.generalAfterMarketMods.value.splice(indexDTO, 1);
                $scope.resetModArrays();
            };

            $scope.addBodyworkMod = (mod) => {
                if (mod.value !== null) {
                    const indexID = $scope.getModIndexValue(mod, $scope.bodyMods);

                    let duplicateMods = $scope.getDuplicateMods(indexID, $scope.bodyMods);

                    if(duplicateMods.length > 0) {
                        let modValueToAdd = $scope.getModValueToAdd(duplicateMods, $scope.bodyMods, $scope.vehicle.bodyWorkMods.value);

                        $scope.bodyMods[indexID].value = modValueToAdd;
                    }
                    else {
                        $scope.vehicle.bodyWorkMods.value = $scope.bodyMods.map((code) => {
                            return code.value;
                        });
                    }
                }
            };

            $scope.addBodyworkModSelect = () => {
                let nullMods = $scope.bodyMods.filter(code => code.value == null);
                if(nullMods == null || nullMods.length === 0) {
                    $scope.bodyMods.push($scope.addNewModObject($scope.bodyworkModifications, 'bodyModsOption', Math.floor(Math.random() * 100)));
                }
            };

            $scope.removeBodyworkMod = (mod) => {
                const index = $scope.bodyMods.indexOf(mod);
                $scope.bodyMods.splice(index, 1);
                const indexDTO = $scope.vehicle.bodyWorkMods.value.indexOf(mod.value);
                $scope.vehicle.bodyWorkMods.value.splice(indexDTO, 1);
                $scope.resetModArrays();
            };

            $scope.addEngineMod = (mod) => {
                if (mod.value !== null) {
                    const indexID = $scope.getModIndexValue(mod, $scope.engineMods);

                    let duplicateMods = $scope.getDuplicateMods(indexID, $scope.engineMods);

                    if(duplicateMods.length > 0) {
                        let modValueToAdd = $scope.getModValueToAdd(duplicateMods, $scope.engineMods, $scope.vehicle.engineMods.value);

                        $scope.engineMods[indexID].value = modValueToAdd;
                    }
                    else {
                        $scope.vehicle.engineMods.value = $scope.engineMods.map((code) => {
                            return code.value;
                        });
                    }
                }
            };

            $scope.addEngineModSelect = () => {
                let nullMods = $scope.engineMods.filter(code => code.value == null);
                if(nullMods == null || nullMods.length === 0) {
                    $scope.engineMods.push($scope.addNewModObject($scope.engineModifications, 'engineModsOption', Math.floor(Math.random() * 100)));
                }
            };

            $scope.removeEngineMod = (mod) => {
                const index = $scope.engineMods.indexOf(mod);
                $scope.engineMods.splice(index, 1);
                const indexDTO = $scope.vehicle.engineMods.value.indexOf(mod.value);
                $scope.vehicle.engineMods.value.splice(indexDTO, 1);
                $scope.resetModArrays();
            };

            $scope.addWheelsTyresMod = (mod) => {
                if (mod.value !== null) {
                    const indexID = $scope.getModIndexValue(mod, $scope.wheelsTyresMods);

                    let duplicateMods = $scope.getDuplicateMods(indexID, $scope.wheelsTyresMods);

                    if(duplicateMods.length > 0) {
                        let modValueToAdd = $scope.getModValueToAdd(duplicateMods, $scope.wheelsTyresMods, $scope.vehicle.wheelsOrTyresMods.value);

                        $scope.wheelsTyresMods[indexID].value = modValueToAdd;
                    }
                    else {
                        $scope.vehicle.wheelsOrTyresMods.value = $scope.wheelsTyresMods.map((code) => {
                            return code.value;
                        });
                    }
                }
            };

            $scope.addWheelsTyresModSelect = () => {
                let nullMods = $scope.wheelsTyresMods.filter(code => code.value == null);
                if(nullMods == null || nullMods.length === 0) {
                    $scope.wheelsTyresMods.push($scope.addNewModObject($scope.wheelsOrTyresModifications, 'wheelsTyresModsOption', Math.floor(Math.random() * 100)));
                }
            };

            $scope.removeWheelsTyresMod = (mod) => {
                const index = $scope.wheelsTyresMods.indexOf(mod);
                $scope.wheelsTyresMods.splice(index, 1);
                const indexDTO = $scope.vehicle.wheelsOrTyresMods.value.indexOf(mod.value);
                $scope.vehicle.wheelsOrTyresMods.value.splice(indexDTO, 1);
                $scope.resetModArrays();
            };

            $scope.addConvMod = (mod) => {
                if (mod.value !== null) {
                    const indexID = $scope.getModIndexValue(mod, $scope.convMods);

                    let duplicateMods = $scope.getDuplicateMods(indexID, $scope.convMods);

                    if(duplicateMods.length > 0) {
                        let modValueToAdd = $scope.getModValueToAdd(duplicateMods, $scope.convMods, $scope.vehicle.conversionMods.value);

                        $scope.convMods[indexID].value = modValueToAdd;
                    }
                    else {
                        $scope.vehicle.conversionMods.value = $scope.convMods.map((code) => {
                            return code.value;
                        });
                    }
                }
            };

            $scope.addConvModSelect = () => {
                let nullMods = $scope.convMods.filter(code => code.value == null);
                if(nullMods == null || nullMods.length === 0) {
                    $scope.convMods.push($scope.addNewModObject($scope.conversionModifications, 'convModsOption', Math.floor(Math.random() * 100)));
                }
            };

            $scope.removeConvMod = (mod) => {
                const index = $scope.convMods.indexOf(mod);
                $scope.convMods.splice(index, 1);
                const indexDTO = $scope.vehicle.conversionMods.value.indexOf(mod.value);
                $scope.vehicle.conversionMods.value.splice(indexDTO, 1);
                $scope.resetModArrays();
            };

            $scope.addOtherMod = (mod) => {
                if (mod.value !== null) {
                    const indexID = $scope.getModIndexValue(mod, $scope.otherMods);

                    let duplicateMods = $scope.getDuplicateMods(indexID, $scope.otherMods);

                    if(duplicateMods.length > 0) {
                        let modValueToAdd = $scope.getModValueToAdd(duplicateMods, $scope.otherMods, $scope.vehicle.otherMods.value);

                        $scope.otherMods[indexID].value = modValueToAdd;
                    }
                    else {
                        $scope.vehicle.otherMods.value = $scope.otherMods.map((code) => {
                            return code.value;
                        });
                    }
                }
            };

            $scope.addOtherModSelect = () => {
                let nullMods = $scope.otherMods.filter(code => code.value == null);
                if(nullMods == null || nullMods.length === 0) {
                    $scope.otherMods.push($scope.addNewModObject($scope.otherModifications, 'otherModsOption', Math.floor(Math.random() * 100)));
                }
            };

            $scope.removeOtherMod = (mod) => {
                const index = $scope.otherMods.indexOf(mod);
                $scope.otherMods.splice(index, 1);
                const indexDTO = $scope.vehicle.otherMods.value.indexOf(mod.value);
                $scope.vehicle.otherMods.value.splice(indexDTO, 1);
                $scope.resetModArrays();
            };

        }]
    };
}];
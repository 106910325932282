import BaseCtrl from 'edge/platform/base/BaseCtrl';

const fn = ($scope, claimViewModel, StateUtils) => {
    const ctrl = Object.create(BaseCtrl);
    const flowModel = StateUtils.activeDataForState(true).flowModel;

    ctrl.init = () => {
        flowModel.next(claimViewModel()); // Runs next function on screen load
    };
    return ctrl.create($scope);
};
fn.$inject = ['$scope', 'claimViewModel', 'StateUtils'];

export default fn;